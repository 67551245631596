/* eslint-disable */
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Form, Col, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import axios from '../../../config/axiosMaquina';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../../hooks/Toast';
import { getCookieSessionData } from '../../../services/cookieService';

export type ClienteServicosContratadosDTO = {
  cadastroConsulta: number;
  clienteId?: number | null;
  cronogramaViagem: number;
  mapaRastreamento: number;
  monitoramentoVeiculo: number;
  rotaPontos: number;
  controleTemperatura: number;
  controleVelocidade: number;
  smsViagem: number;
  wsPosicao: number;
  painelLsc: number;
};

type Props = {
  razaoSocial: string;
  idCliente: number;
  cadastroRealizado: boolean;
  idParticularidades?: number | null;
  idServicos?: number | null;
  idClienteByPost?: number | null;
  canEdit?: boolean;
  fetchServicosContratados: () => Promise<void>;
  setServicosContratados: (servicosContratados: ClienteServicosContratadosDTO) => void;
};

const TabServicosContratados: React.FC<Props> = ({
  razaoSocial,
  idCliente,
  cadastroRealizado,
  idParticularidades,
  idServicos,
  idClienteByPost,
  canEdit,
  fetchServicosContratados,
  setServicosContratados,
}) => {
  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();

  const [cliente, setCliente] = useState({} as ClienteServicosContratadosDTO);
  const [wasCadastred, setWasCadastred] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [foto, setFoto] = useState('');
  const [error, setError] = useState(false);
  const [isDialogVisibleCadEfetuadoServCont, setIsDialogVisibleCadEfetuadoServCont] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const usuario = getCookieSessionData().usuarioVO.usuarioId;

  const [cadastroRealizadoServ, setCadastroRealizadoServ] = useState(false);

  const [modelServ, setModelServ] = useState<ClienteServicosContratadosDTO>({
    cadastroConsulta: 0,
    clienteId: idCliente === undefined ? idClienteByPost : idCliente,
    cronogramaViagem: 0,
    mapaRastreamento: 0,
    monitoramentoVeiculo: 0,
    rotaPontos: 0,
    smsViagem: 0,
    wsPosicao: 0,
    controleTemperatura: 0,
    controleVelocidade: 0,
    painelLsc: 0,
  });

  useEffect(() => {
    const loadServicosById = async () => {
      if (!idServicos) {
        return;
      } else {
        try {
          const response = await axios.get(`/cliente-servico-contratado/getById/${idServicos}`);
          setModelServ(response.data);
          console.log(response.data);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    loadServicosById();
  }, [idServicos]);

  const handleRegisterServicosContratados = async (event: any) => {
    event.preventDefault();
    console.log(modelServ);
    setServicosContratados(modelServ);
    const data = {
      ...modelServ,
      clienteId: idCliente === undefined ? idClienteByPost : idCliente,
    };

    try {
      setLoading(true);
      if (!idCliente && idServicos === null) {
        const response = await axios.post('/cliente-servico-contratado/salvar', data);
        setLoading(false);
        setIsDialogVisibleCadEfetuadoServCont(true);
        setCadastroRealizadoServ(true);
        setWasCadastred(true);
      } else {
        if (idServicos === null) {
          const response = await axios.post('/cliente-servico-contratado/salvar', data);
          setLoading(false);
          setIsDialogVisibleCadEfetuadoServCont(true);
          setCadastroRealizadoServ(true);
          setWasCadastred(true);
          // await fetchServicosContratados();
        } else {
          console.log(data);
          const response = await axios.put(
            `/cliente-servico-contratado/atualizar/${idServicos}`,
            { ...data, idUsuario: usuario || null}
          );
          setLoading(false);
          setIsDialogVisibleCadEfetuadoServCont(true);
          setWasCadastred(true);
          // await fetchServicosContratados();
        }
      }
    } catch (err: any) {
      setLoading(false);
      // alert('Erro ao cadastrar ');
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar.',
        type: 'error',
      });
    }
  };

  const goNextPage = () => {
    setIsDialogVisibleCadEfetuadoServCont(false);
    if (!id) {
      history.push(
        modelServ.controleTemperatura === 1
          ? '/cadastrar-clientes/controleTemperatura'
          : modelServ.controleVelocidade === 1
          ? '/cadastrar-clientes/controleVelocidade'
          : '/clientes/listar/1'
      );
      // history.push('/clientes/listar/1');
    }
  };

  return (
    <>
      <Dialog
        header={
          idCliente
            ? 'Alteração realizada com sucesso!'
            : 'Serviços Contratados do Cliente foi cadastrado com sucesso!'
        }
        footer={
          <>
            <Button label="OK" onClick={goNextPage} />
          </>
        }
        visible={isDialogVisibleCadEfetuadoServCont}
        style={{ width: '50vw' }}
        modal
        onHide={goNextPage}
      />
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <Form noValidate validated={isFormInvalid} onSubmit={handleRegisterServicosContratados}>
          <Form.Row>
            <Form.Group as={Col} className="mt-3">
              <Form.Label>Cliente</Form.Label>

              <Form.Control
                value={razaoSocial}
                id="razaoSocial"
                name="razaoSocial"
                placeholder=""
                style={{ width: '49.8 %' }}
                maxLength={64}
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Monitoramento de Veículo</Form.Label>

              <Form.Control
                value={modelServ.monitoramentoVeiculo}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    monitoramentoVeiculo: parseInt(e.target.value, 10),
                  });
                }}
                id="monitoramentoVeiculo"
                name="monitoramentoVeiculo"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Análise de Perfil</Form.Label>

              <Form.Control
                value={modelServ.cadastroConsulta}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    cadastroConsulta: parseInt(e.target.value, 10),
                  });
                }}
                id="cadastroConsulta"
                name="cadastroConsulta"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Mapa de Rastreamento</Form.Label>

              <Form.Control
                value={modelServ.mapaRastreamento}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    mapaRastreamento: parseInt(e.target.value, 10),
                  });
                }}
                id="mapaRastreamento"
                name="mapaRastreamento"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>WS da Posição</Form.Label>

              <Form.Control
                value={modelServ.wsPosicao}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    wsPosicao: parseInt(e.target.value, 10),
                  });
                }}
                id="wsPosicao"
                name="wsPosicao"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>SMS da Viagem</Form.Label>

              <Form.Control
                value={modelServ.smsViagem}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    smsViagem: parseInt(e.target.value, 10),
                  });
                }}
                id="smsViagem"
                name="smsViagem"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Cronograma de Viagem</Form.Label>

              <Form.Control
                value={modelServ.cronogramaViagem}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    cronogramaViagem: parseInt(e.target.value, 10),
                  });
                }}
                id="cronogramaViagem"
                name="cronogramaViagem"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não gerar previsão</option>
                <option value="1">Gerar previsão(API)</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Rota por Pontos</Form.Label>

              <Form.Control
                value={modelServ.rotaPontos}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    rotaPontos: parseInt(e.target.value, 10),
                  });
                }}
                id="rotaPontos"
                name="rotaPontos"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Controle de temperatura</Form.Label>
              <Form.Control
                value={modelServ.controleTemperatura}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    controleTemperatura: parseInt(e.target.value, 10),
                  });
                }}
                id="controleTemperatura"
                name="controleTemperatura"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Controle de velocidade</Form.Label>
              <Form.Control
                value={modelServ.controleVelocidade}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    controleVelocidade: parseInt(e.target.value, 10),
                  });
                }}
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Painel LSC</Form.Label>
              <Form.Control
                value={modelServ.painelLsc}
                onChange={(e: any) => {
                  setModelServ({
                    ...modelServ,
                    painelLsc: parseInt(e.target.value, 10),
                  });
                }}
                id="painelLsc"
                name="painelLsc"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Cálculo Km Percorrido?</Form.Label>

              <Form.Control
                id="kmPercorrido"
                name="kmPercorrido"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não Contratado</option>
                <option value="1">Contratado</option>
                <option value="2">Bloqueado</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          {idCliente || cadastroRealizado || idClienteByPost ? (
            <ButtonsForm canEdit={canEdit} />
          ) : null}
        </Form>
      )}
    </>
  );
};

export default TabServicosContratados;
