/* eslint-disable */
import { format } from 'date-fns';
import moment from 'moment';

export const isDateValid = (date: any): boolean => {
  return !Number.isNaN(new Date(date).getTime());
};

export const dataAtualFormatoAnoMesDia = (): string => {
  const current = new Date();
  const maxDate = new Date(current.getTime());
  const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
  return maxDateFormatado;
};

export const dataMinParaMaioridade = (): Date => {
  const current = new Date();
  const maxDate = new Date(
    current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
  );

  return maxDate;
};

export const dataMaxParaMaioridadeFormatada = (): string => {
  const current = new Date();
  const maxDate = new Date(
    current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
  );
  const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
  return maxDateFormatado;
};

export const dataMinParaDataNascimentoFormatada = (): string => {
  const current = new Date();
  const minDate = new Date(current.getTime() - 1000 * 60 * 60 * 24 * 365 * 100);

  const minDateFormatado = `${minDate.getFullYear().toString()}-${(minDate.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${minDate.getDate().toString().padStart(2, '0')}`;
  return minDateFormatado;
};

export const isDataMaioridade = (value: string): boolean => {
  const nasc = new Date(value.replaceAll('-', '/'));
  const dataAtual = dataMinParaMaioridade();

  if (dataAtual.getTime() - nasc.getTime() < 0) {
    return false;
  }

  return true;
};

export function dataConverter(dataHora: string): Date {
  const data = format(new Date(dataHora), 'yyyy-MM-dd');
  const hora = format(new Date(dataHora), 'HH:mm:ss');
  const dataArr = data?.split('-');
  const horaArr = hora?.split(':');
  const year = dataArr[0];
  const month = parseInt(dataArr[1], 10) - 1;

  const dataAtt = new Date();
  dataAtt.setFullYear(parseInt(year, 10));
  dataAtt.setMonth(month);
  dataAtt.setDate(parseInt(dataArr[2], 10));
  dataAtt.setHours(parseInt(horaArr[0], 10));
  dataAtt.setMinutes(parseInt(horaArr[1], 10));
  dataAtt.setSeconds(0);
  dataAtt.setMilliseconds(0);
  return dataAtt;
}

export function dataHoraConverter(data: string, hora: string): Date {
  const dataArr = data?.split('-');
  const horaArr = hora?.split(':');
  const year = dataArr[0];
  const month = parseInt(dataArr[1], 10) - 1;

  const dataAtt = new Date();
  dataAtt.setFullYear(parseInt(year, 10));
  dataAtt.setMonth(month);
  dataAtt.setDate(parseInt(dataArr[2], 10));
  dataAtt.setHours(parseInt(horaArr[0], 10));
  dataAtt.setMinutes(parseInt(horaArr[1], 10));
  dataAtt.setSeconds(0);
  dataAtt.setMilliseconds(0);
  return dataAtt;
}

export function dataAtualBr(param?: number): string {
  const date = format(new Date(), 'yyyy-MM-dd HH:mm');

  if (param === 1) {
    return date.substring(0, 10);
  }
  if (param === 2) {
    return date.substring(11, 16);
  }

  return date;
}

export const obterDataHora = (data: string | Date, noSeconds?: boolean): string => {
  if (!data) return '';

  let date = typeof data === 'string' && !data.includes(':') ? `${data} 00:00` : data;

  if (typeof date === 'string' && date.includes('/')) {
    const day = date.substring(0, 2);
    const month = date.substring(3, 5);
    const year = date.substring(6, 10);
    const others = date.substring(10);

    date = `${year}-${month}-${day}${others}`;
  }

  if (noSeconds) return format(new Date(date), 'dd/MM/yyyy HH:mm');

  return format(new Date(date), 'dd/MM/yyyy HH:mm:ss');
};

export const obterData = (data: string | Date | undefined, formato?: string): string => {
  if (!data) return '';

  let date = typeof data === 'string' && !data.includes(':') ? `${data} 00:00` : data;

  if (typeof date === 'string' && date.includes('/')) {
    const day = date.substring(0, 2);
    const month = date.substring(3, 5);
    const year = date.substring(6, 10);
    const others = date.substring(10);

    date = `${year}-${month}-${day}${others}`;
  }

  if (!isDateValid(date)) return '';

  if (!formato) {
    return format(new Date(date), 'dd/MM/yyyy');
  }
  if (formato === 'en') {
    return format(new Date(date), 'yyyy/MM/dd');
  }
  if (formato === 'en2') {
    return format(new Date(date), 'yyyy-MM-dd');
  }
  return '';
};

export const formatarData = (date: string): string => {
  const day = Number(date.substring(8, 10));
  const month = Number(date.substring(5, 7));
  const year = Number(date.substring(0, 4));

  return format(new Date(year, month - 1, day), 'dd/MM/yyyy');
};

export const obterDataAtual = (ptBr?: boolean, hasTime?: boolean): string => {
  if (!ptBr) {
    if (hasTime) {
      return format(new Date(), 'yyyy-MM-dd HH:mm');
    }
    return format(new Date(), 'yyyy-MM-dd');
  }
  if (hasTime) return format(new Date(), 'dd-MM-yyyy HH:mm');
  return format(new Date(), 'dd-MM-yyyy');
};

export const obterHora = (hora?: string, withSeconds?: boolean): string => {
  if (!hora) {
    return format(new Date(), 'HH:mm');
  }
  if (withSeconds) {
    return format(new Date(hora), 'HH:mm:ss');
  }
  return format(new Date(hora), 'HH:mm');
};

export const subtractThreeHours = (timestamp: string) => {
  const date = moment(timestamp);
  const subtractedDate = date.subtract(3, 'hours');
  return subtractedDate.format('HH:mm');
};

// Retorna a data (ou data e hora) passada no parâmetro em formato string aceito por ZondedDateTime.
// Caso 'atStartofDay' seja informado como 'false', retornará a data passada no parâmetro
// em seu último instante: yyyy-MM-ddT23:59:59.999
export const toZoned = (date: string | undefined, atStartOfDay = true): string => {
  if (typeof date === 'undefined' || date === '' || date === null) return '';

  const data = new Date(date);
  const offset = data.getTimezoneOffset() / 60;
  const zero = offset < 10 ? '0' : '';

  if (date.includes(':')) {
    return offset === 0
      ? `${format(data, 'yyyy-MM-dd')}T${format(data, 'HH:mm:ss.SSS')}Z`
      : Math.sign(offset) !== -1
      ? `${format(data, 'yyyy-MM-dd')}T${format(data, 'HH:mm:ss.SSS')}-${zero}${offset}:00`
      : `${format(data, 'yyyy-MM-dd')}T${format(data, 'HH:mm:ss.SSS')}+${zero}${offset}:00`;
  }
  if (atStartOfDay) {
    return offset === 0
      ? `${date}T00:00:00.000Z`
      : Math.sign(offset) !== -1
      ? `${date}T00:00:00.000-${zero}${offset}:00`
      : `${date}T00:00:00.000+${zero}${offset}:00`;
  }

  return offset === 0
    ? `${date}T23:59:59.999Z`
    : Math.sign(offset) !== -1
    ? `${date}T23:59:59.999-${zero}${offset}:00`
    : `${date}T23:59:59.999+${zero}${offset}:00`;
};

// Retorna a data e hora atual em formato string aceito por ZondedDateTime.
export const nowToZoned = (): string => {
  const data = new Date();
  const offset = data.getTimezoneOffset() / 60;
  const zero = offset < 10 ? '0' : '';

  return offset === 0
    ? `${format(data, 'yyyy-MM-dd')}T${format(data, 'HH:mm:ss.SSS')}Z`
    : Math.sign(offset) !== -1
    ? `${format(data, 'yyyy-MM-dd')}T${format(data, 'HH:mm:ss.SSS')}-${zero}${offset}:00`
    : `${format(data, 'yyyy-MM-dd')}T${format(data, 'HH:mm:ss.SSS')}+${zero}${offset}:00`;
};
