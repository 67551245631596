export const errorsMessages = {
  input: {
    nullOrEmpty: 'Este campo não pode ser vazio.',
    invalidName: 'Nome inválido, inserir nome e sobrenome.',
    dataNascimentoNaoMaioridade: 'Ajudante não pode ter idade menor a 18 anos.',
    nomeESobrenome: 'Deve conter nome e pelo menos um sobrenome.',
    cpfInvalid: 'CPF inválido',
    dataFinalMenorInicial: 'Data final não pode ser menor que a data inicial.',
    dataValidadeMenorAtual: 'Data validade não pode ser menor do que a data atual.',
    cnhInvalid: 'CNH deve possuir 11 dígitos.',
    cnhNumSegurancaInvalid: 'N° deve possuir 11 dígitos.',
    renavamInvalid: 'Renavam deve possuir de 8 a 11 dígitos.',
    chassiInvalid: 'Chassi deve possuir 17 dígitos.',
    validadeCnh: 'CNH Vencida.',
  },
};
