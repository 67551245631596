import React from 'react';
import { AuthResponse } from '../api/model/AuthResponse';

export interface AuthContext {
  auth?: AuthResponse;
  setAuth: (currentAuth: AuthResponse) => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const DEFAULT_VALUE = { setAuth: () => {} };

export const authContext = React.createContext<AuthContext>(DEFAULT_VALUE);
