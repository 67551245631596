import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { TecnologiaRastreamentoIdNome } from '../../api/model/TecnologiaRastreamentoIdNomeType';
import { SASCAR } from '../../api/model/tecnologiasSascar';
import axios from '../../config/axiosMaquina';

type Props = {
  canEdit?: boolean;
};

export const ComponentSascar = ({ canEdit }: Props) => {
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [mensagen, setMensagen] = useState('');
  const [tecnologiaSascar, setTecnologiaSascar] = useState<SASCAR>({
    id: 0,
    botaoPanico: 'MENSAGEM, SIRENE E BLOQUEIO',
    desvioRota: 'MENSAGEM, SIRENE E BLOQUEIO',
    deteccaoJammer: 'MENSAGEM, SIRENE E BLOQUEIO',
    perdaSinal: 'MENSAGEM, SIRENE E BLOQUEIO',
    paradaIndevida: 'MENSAGEM, SIRENE E BLOQUEIO',
    violacaoBau: 'MENSAGEM, SIRENE E BLOQUEIO',
    violacaoEngate: 'MENSAGEM, SIRENE E BLOQUEIO',
    violacaoCabine: 'MENSAGEM, SIRENE E BLOQUEIO',
    violacaoPainelAntena: 'MENSAGEM, SIRENE E BLOQUEIO',
  });
  const [tecnologiaRastreamentoIdNome, setTecnologiaRastreamentoIdNome] =
    useState<TecnologiaRastreamentoIdNome>({
      id: 0,
      nome: '',
    });
  useEffect(() => {
    const loadDadosTecnologiaRastreamentoIdNome = async () => {
      try {
        const response = await axios.get('/listarIdNome');
        const sascar = response.data.find(
          (filt: any) => filt.nome.toLowerCase().indexOf('sascar'.toLowerCase()) !== -1
        );
        setTecnologiaRastreamentoIdNome({
          id: sascar.id,
          nome: sascar.nome,
        });
      } catch (err: any) {
        console.log(err);
      }
    };

    loadDadosTecnologiaRastreamentoIdNome();
  }, [setTecnologiaRastreamentoIdNome]);
  const sistemGerencial = async (e: any) => {
    e.preventDefault();
    const params = {
      botaoPanico: tecnologiaSascar.botaoPanico,
      desvioRota: tecnologiaSascar.desvioRota,
      deteccaoJammer: tecnologiaSascar.deteccaoJammer,
      id: tecnologiaSascar.id,
      mensagem: mensagen,
      paradaIndevida: tecnologiaSascar.paradaIndevida,
      perdaSinal: tecnologiaSascar.perdaSinal,
      tecnologia: [tecnologiaRastreamentoIdNome.id],
      violacaoBau: tecnologiaSascar.violacaoBau,
      violacaoCabine: tecnologiaSascar.violacaoCabine,
      violacaoEngate: tecnologiaSascar.violacaoEngate,
      violacaoPainelAntena: tecnologiaSascar.violacaoPainelAntena,
    };
    try {
      await axios.post('/cadastrar', params);
      // alert('Enviado com Sucesso');
      setIsDialogCadOK(true);
      setMensagen('');
      setTecnologiaSascar({
        ...tecnologiaSascar,
        botaoPanico: 'MENSAGEM, SIRENE E BLOQUEIO',
        desvioRota: 'MENSAGEM, SIRENE E BLOQUEIO',
        deteccaoJammer: 'MENSAGEM, SIRENE E BLOQUEIO',
        perdaSinal: 'MENSAGEM, SIRENE E BLOQUEIO',
        paradaIndevida: 'MENSAGEM, SIRENE E BLOQUEIO',
        violacaoBau: 'MENSAGEM, SIRENE E BLOQUEIO',
        violacaoEngate: 'MENSAGEM, SIRENE E BLOQUEIO',
        violacaoCabine: 'MENSAGEM, SIRENE E BLOQUEIO',
        violacaoPainelAntena: 'MENSAGEM, SIRENE E BLOQUEIO',
      });
    } catch (error: any) {
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar!',
        type: 'error',
      });
      // alert('Erro ao enviar');
    }
  };

  const recebe = [
    'SELECIONE',
    'APENAS MENSAGEM',
    'MENSAGEM E SIRENE',
    'MENSAGEM, SIRENE E BLOQUEIO',
  ];

  return (
    <>
      <Dialog
        header="Parâmetro cadastrado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/avisos-operacionais');
        }}
      />
      <Form onSubmit={(e: any) => sistemGerencial(e)}>
        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Botão de Pânico
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.botaoPanico}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  botaoPanico: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Desvio de Rota
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.desvioRota}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  desvioRota: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Detecção de Jammer
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.deteccaoJammer}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  deteccaoJammer: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Perda de Sinal
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.perdaSinal}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  perdaSinal: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Parada Indevida
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.paradaIndevida}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  paradaIndevida: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Violação de Baú
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.violacaoBau}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  violacaoBau: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Violação de Engate
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.violacaoEngate}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  violacaoEngate: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Violação de Cabine
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.violacaoCabine}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  violacaoCabine: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="formGridState">
          <Form.Label column sm="3">
            Violação de Painel/Antena
          </Form.Label>
          <Col sm="9">
            <Form.Control
              value={tecnologiaSascar.violacaoPainelAntena}
              onChange={(event: any) => {
                setTecnologiaSascar({
                  ...tecnologiaSascar,
                  violacaoPainelAntena: event.target.value,
                });
              }}
              as="select"
            >
              {recebe.map((filtros) => (
                <option key={filtros} value={filtros}>
                  {filtros}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm="3">
            Mensagem
          </Form.Label>
          <Col sm="9">
            <Form.Control
              as="textarea"
              style={{ resize: 'none', height: '5rem' }}
              rows={3}
              value={mensagen}
              onChange={(e: any) => {
                setMensagen(e.target.value);
              }}
              id="emailAvisoServicoInoperante"
              name="emailAvisoServicoInoperante"
            />
          </Col>
        </Form.Group>
        <Form.Row className="container-buttons justify-content-end">
          <Form.Group as={Col} md={2}>
            {canEdit ? (
              <Button className="btn-enviar" type="submit">
                Enviar
              </Button>
            ) : (
              <Button
                className="btn-enviar"
                type="button"
                onClick={() => history.push('/acessonegado')}
              >
                Enviar
              </Button>
            )}
          </Form.Group>
        </Form.Row>
      </Form>
    </>
  );
};
