/* eslint-disable */
import React from 'react';
import ReactExport from 'react-data-export';
import { useHistory } from 'react-router-dom';
import { Spinner, Dropdown, DropdownButton } from 'react-bootstrap';
import MenuLateral from '../MenuLateral/MenuLateral';
import Search from '../Search/Search';
import Pagination from '../Pagination/Pagination';
import { ButtonTooltip } from '../ButtonTooltipo';
import { ContainerButtons, SecundaryButton } from '../ButtonsForm/styles';
import { BreadcrumbsContainer, Breadcrumb, Separator } from '../Breadcrumbs/styles';
import { StatusDminer } from '../StatusDminer';

type breadcrumbType = {
  label: string;
  url?: string;
};

type TelaProps = {
  dminerInactive?: boolean;
  mensagemDminer?: boolean;
  children?: React.ReactNode;
  nome?: string;
  caminho?: string;
  linkToAdd?: string;
  loading?: boolean;
  home?: boolean;
  searchValue?: string;
  setSearchValue?: (searchValue: string) => void;
  search?: string;
  setSearch?: (searchValue: string) => void;
  setLoading?: (loading: boolean) => void;
  setLoadingSearch?: (loading: boolean) => void;
  onClickDelete?: (del: boolean) => void;
  currentPage?: any;
  itensPerPage?: any;
  setItensPerPage?: (itensPerPage: string) => void;
  totalPosts?: number;
  paginate?: (page: number) => void;
  excelGeneration?: any;
  filename?: any;
  createDisabled?: boolean;
  canDelete?: boolean;
  pdf?: boolean;
  onClickPdf?: () => void;
  onClickFilter?: () => void;
  onClickVoltar?: () => void;
  filter?: boolean;
  itemsBreadcrumbs?: breadcrumbType[];
  disabledExport?: boolean;
};

export const Tela = ({
  children,
  nome,
  caminho,
  linkToAdd,
  dminerInactive,
  mensagemDminer = false,
  loading,
  setLoading,
  setLoadingSearch,
  searchValue,
  setSearchValue,
  search,
  setSearch,
  currentPage,
  itensPerPage,
  setItensPerPage,
  totalPosts,
  paginate,
  excelGeneration,
  filename,
  home,
  onClickDelete,
  createDisabled,
  canDelete,
  pdf,
  onClickPdf,
  filter,
  onClickFilter,
  itemsBreadcrumbs,
  onClickVoltar,
  disabledExport,
}: TelaProps) => {
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const history = useHistory();

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card" style={{ overflow: 'hidden' }}>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="titulo-container">
              {nome && <h2 className="titulo">{nome}</h2>}
              {caminho ? (
                <h6 className="subtitulo">{caminho}</h6>
              ) : (
                itemsBreadcrumbs && (
                  <BreadcrumbsContainer>
                    {itemsBreadcrumbs.map((each: any, index) => (
                      <>
                        <Breadcrumb key={index} href={each?.url} disabled={!each.url}>
                          {each.label}
                        </Breadcrumb>
                        {index === itemsBreadcrumbs.length - 1 ? null : (
                          <Separator>{`>`}</Separator>
                        )}
                      </>
                    ))}
                  </BreadcrumbsContainer>
                )
              )}
              {home && (
                <h6 style={{ fontSize: '14px', marginTop: '5px', color: '#a4a4a4' }}>Home</h6>
              )}
            </div>
            <div style={filter ? { display: 'flex', justifyContent: 'space-between' } : {}}>
              {filter && <ButtonTooltip type="Filtro" onClick={onClickFilter} />}
              {setSearch && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: mensagemDminer ? 'space-between' : 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  {mensagemDminer && <StatusDminer dminerInactive={dminerInactive || false} />}
                  <Search
                    setLoading={setLoadingSearch!}
                    setSearch={setSearch!}
                    setSearchValue={setSearchValue!}
                    search={search!}
                    linkToAdd={linkToAdd}
                    createDisabled={createDisabled}
                  />
                </div>
              )}
            </div>
            {onClickDelete && (
              <div className="titulo-container">
                <button
                  className="btn-desativar"
                  style={{
                    backgroundColor: 'transparent',
                    borderColor: 'transparent',
                    position: 'absolute',
                    right: '2%',
                  }}
                  type="button"
                  onClick={() => {
                    if (canDelete) {
                      onClickDelete(true);
                    } else {
                      history.push('/acessonegado');
                    }
                  }}
                >
                  <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
                </button>
              </div>
            )}
            {children}
            <ContainerButtons>
              {(excelGeneration || pdf) && (
                <>
                  {onClickVoltar ? (
                    <SecundaryButton size="sm" onClick={onClickVoltar}>
                      Voltar
                    </SecundaryButton>
                  ) : (
                    <SecundaryButton size="sm" onClick={() => history.goBack()}>
                      Voltar
                    </SecundaryButton>
                  )}
                  <DropdownButton
                    id="dropdown-item-button"
                    variant="#FFDA53"
                    style={{ background: '#FFDA53', borderRadius: '4px' }}
                    title="Exportar"
                  >
                    {excelGeneration &&
                      (disabledExport ? (
                        <Dropdown.Item disabled eventKey="1">
                          XLS
                        </Dropdown.Item>
                      ) : (
                        <ExcelFile
                          element={
                            <Dropdown.Item disabled={disabledExport} eventKey="1">
                              XLS
                            </Dropdown.Item>
                          }
                          filename={filename}
                        >
                          <ExcelSheet dataSet={excelGeneration} name={filename} />
                        </ExcelFile>
                      ))}
                    {pdf && (
                      <Dropdown.Item
                        disabled={disabledExport}
                        eventKey="2"
                        as="button"
                        onClick={onClickPdf}
                      >
                        PDF
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                </>
              )}
            </ContainerButtons>
            {currentPage && (
              <Pagination
                itensPerPage={itensPerPage}
                setItensPerPage={setItensPerPage!}
                totalPosts={totalPosts!}
                paginate={paginate!}
                currentPage={currentPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};
