/* eslint-disable */
import React, { ChangeEvent, FC, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button, Form, Modal } from 'react-bootstrap';
import { PendentesObj } from '../../types';
import { FormataStringData } from '../../util/Validacoes/Validacoes';
import './styles.css';
import { useToast } from '../../hooks/Toast';
import instanceMaquina from '../../config/axiosMaquina';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  motivo: string;
  setMotivo: (motivo: string) => void;
  selectedItem: PendentesObj | undefined;
  usuarioId: any
  clienteNome: any
  setRefresh?: any
  setIsDialogAtualizandoOK?: any
};

const ModalCancelamentoMonitoramento: FC<Props> = ({
  show,
  setShow,
  selectedItem,
  setMotivo,
  motivo,
  usuarioId,
  clienteNome,
  setRefresh,
  setIsDialogAtualizandoOK
}) => {
  const [isDialogVisible, setIsDialogVisible] =  useState(false)
  const [isDialogErrorVisible, setIsDialogErrorVisible] =  useState(false)
  const [loading, setLoading] = useState(false)

  const { addToast } = useToast();
   if (!selectedItem) {
    return null;
  }

  const handleCancelar = () => {
    setLoading(true)

    if (!selectedItem) {
      return;
    }
    try {
      instanceMaquina.put(
        `/solicitacao-monitoramento/cancelarSM-cliente?solicitacaoMonitoramento=${selectedItem.id}&idUsuCancelamento=${usuarioId}&motivoCancelamento=${motivo}&solicitante=${clienteNome}`
      )
      .then((res: any) => setIsDialogVisible(true))
      .catch(() => {
        setIsDialogErrorVisible(true)
      }).finally(() => {
        setShow(false);
        setLoading(false)
      })
    } catch (err: any) {
      console.log(err);
    } finally {
      setRefresh(true)
      setIsDialogAtualizandoOK(true)
    }
  };

  return (
    <>
    <Dialog
        header={`Não foi possivel cancelar, pois a SM não está pendente`}
        footer={<Button onClick={() => setIsDialogErrorVisible(false)}>Ok</Button>}
        visible={isDialogErrorVisible}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogErrorVisible(false)}
      />

    <Dialog
        header={`Solicitação de cancelamento da SM nº ${selectedItem.id} enviada com sucesso. Aguarde confirmação do cancelamento pela gerenciadora`}
        footer={<Button onClick={() => setIsDialogVisible(false)}>Ok</Button>}
        visible={isDialogVisible}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisible(false)}
      />


    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >

      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cancelar Monitoramento</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Nº SM</Form.Label>
            <Form.Control
              value={selectedItem.id}
              id="numSM"
              name="numSM"
              type="number"
              placeholder=""
              disabled
            />
          </Form.Group>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Embarcador</Form.Label>
              <Form.Control
                value={selectedItem.numEmbarcador}
                id="numEmbarcador"
                name="numEmbarcador"
                placeholder=""
                disabled
              />
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={selectedItem.embarcador}
                id="embarcador"
                name="embarcador"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Início da Viagem</Form.Label>
              <Form.Control
                value={
                  FormataStringData(
                    selectedItem.dataInicio.toString().split('T')[0].replaceAll('-', '/')
                  ) +
                  ' ' +
                  selectedItem.dataInicio.toString().split(/[T,.]/)[1]
                }
                id="inicioViagem"
                name="inicioViagem"
                placeholder=""
                disabled
              />
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label>Término da viagem</Form.Label>
              <Form.Control
                value={
                  FormataStringData(
                    selectedItem.dataTermino.toString().split('T')[0].replaceAll('-', '/')
                  ) +
                  ' ' +
                  selectedItem.dataTermino.toString().split(/[T,.]/)[1]
                }
                id="terminoViagem"
                name="terminoViagem"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Rota</Form.Label>
            <Form.Control
              value={selectedItem.rota === null ? selectedItem.origem : selectedItem.rota}
              id="rota"
              name="rota"
              placeholder=""
              disabled
            />
          </Form.Group>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Veículo</Form.Label>
              <Form.Control
                value={selectedItem.placaVeiculo}
                id="veiculoPlaca"
                name="veiculoPlaca"
                placeholder=""
                disabled
              />
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={selectedItem.veiculo}
                id="veiculoMarca"
                name="veiculoMarca"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Condutor</Form.Label>
              <Form.Control
                value={selectedItem.condutor}
                id="condutorNome"
                name="condutorNome"
                placeholder=""
                disabled
              />
            </Form.Group>

            <Form.Group className="row-modal">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                value={selectedItem.condutorCpf}
                id="condutorCpf"
                name="condutorCpf"
                placeholder=""
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>Valor da Carga</Form.Label>
            <Form.Control
              value={
                selectedItem.valorCarga
                  ? selectedItem.valorCarga.toLocaleString('pt-br', {
                      style: 'currency',
                      currency: 'BRL',
                    })
                  : ''
              }
              id="valorCarga"
              name="valorCarga"
              placeholder=""
              disabled
            />
          </Form.Group>

          <hr />

          <Form.Group id="pontoOrigemContainer">
            <Form.Label>Motivo</Form.Label>
            <Form.Control
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setMotivo(e.target.value);
              }}
              value={motivo}
              as="select"
              id="ponto"
              name="ponto"
              placeholder="Ponto de Origem"
              required
            >
              <option></option>
              {MOTIVOS.map((motivo: { id: number; motivoName: string }, index: number) => (
                <option key={index} value={motivo.id}>
                  {motivo.motivoName}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          onClick={() => {
            if (!motivo) {
              // alert('Selecione um motivo para realizar o cancelamento');
              addToast({
                title: 'Info',
                description: 'Selecione um motivo para realizar o cancelamento.',
                type: 'info',
              });
              return;
            }
            handleCancelar()
          }}
        >
          {loading? 'Salvando...' : 'Salvar'}
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
};

const MOTIVOS = [
  { id: 1, motivoName: 'Checklist reprovado' },
  { id: 2, motivoName: 'Dado(s) Incorreto(s)' },
  { id: 3, motivoName: 'Equipamento incompatível para viagem' },
  { id: 4, motivoName: 'Veículo não espelhado para GS' },
];

export default ModalCancelamentoMonitoramento;
