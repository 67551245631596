import React, { useEffect, useState, memo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';

type GrupoMacroVeiculosType = {
  vgmCdId: number;
  vgmTxNome: string;
  vgmBlAtivo?: boolean;
};

const ListagemGrupoMacroVeiculos: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState<GrupoMacroVeiculosType[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const titles = ['Nome'];

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Grupo macro de veículos',
          widthPx: 1200,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      columns: [],
      data: [],
    },
    {
      columns: [
        { title: 'Nome', width: { wch: 37 } },
        { title: 'Status', width: { wch: 15 } },
      ],
      data: data?.map((each) => [
        { value: each.vgmTxNome },
        { value: each.vgmBlAtivo ? 'ATIVO' : 'INATIVO' },
      ]),
    },
  ];

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get('/grupo-macro-veiculo');
      setData(response.data);
      const array = response.data
        .filter((each: any) => each.vgmBlAtivo)
        .map((each: GrupoMacroVeiculosType) => {
          return [each.vgmTxNome];
        });
      setDataTabela(array);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Tela
      nome="Grupo Macro de Veículos"
      caminho="Cadastro > Veículos > Grupo Macro de Veículos"
      loading={loading}
      setLoading={setLoading}
      excelGeneration={multiDataSet}
      filename="Grupo Macro de Veículos"
    >
      {loadingSearch ? <Spinner /> : <Tabela data={dataTabela} titles={titles} />}
    </Tela>
  );
};

export default ListagemGrupoMacroVeiculos;
