/* eslint-disable */
import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { IoCloseSharp } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import ModalCancelamentoMonitoramento from './ModalCancelamentoMonitoramento';
import instanceMaquina from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { PendentesObj } from '../../types';
import { Spinner } from 'react-bootstrap';
import { Tela } from '../../components/Tela';
import { obterDataHora } from '../../util/date';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { Dialog } from 'primereact/dialog';

type Props = {
  canDelete?: boolean;
};

const CancelamentoMonitoramento = () => {
  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canDelete } = useRolePermission();
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState('');
  const [searchResults, setSearchResults] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [selectedItem, setSelectedItem] = useState<PendentesObj | undefined>();
  const [show, setShow] = useState(false);
  const [motivo, setMotivo] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const history = useHistory();
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;
  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const clienteNome = getCookieSessionData().usuarioVO.nome;
  const [isDialogAtualizandoOK, setIsDialogAtualizandoOK] = useState(false)
  const [firstRequest, setFirstRequest] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);


  const paginate = (pageNumber: any) => {
    history.push(`/cancelamento-monitoramento/${pageNumber}`);
    setCurrentPage(pageNumber);
  }
  const [refresh, setRefresh] = useState(false)

  const currentData =
  searchData.trim() !== ''
    ? searchResults.slice(indexOfFirstPost, indexOfLastPost)
    : data.slice(indexOfFirstPost, indexOfLastPost);

  useEffect(() => {
    rolePermission([{TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.SOLICITACAO.CANCELAMENTO_MONITORAMENTO]}], 'excluir');
  }, [TRANSPORTADOR, rolePermission]);

  var interval: any;

  const handleRequest = async () => {
    setIsDialogAtualizandoOK(true);

    try {
      const response = await instanceMaquina.get('/solicitacao-monitoramento/bytype', {
        params: {
          idUsuario: usuarioId,
          type: 'PENDENTE',
          pagina: 1,
          qtdRegistros: itensPerPage,
        },
      });
      setFirstRequest(true)
      setData(response.data);
      setSearchResults(response.data);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    } finally {
      setIsDialogAtualizandoOK(false);
      return () => clearInterval(interval);
    }
  };

  useEffect(() => {
    if (!refresh) return

    handleRequest()

    setRefresh(false)
  }, [refresh]);

  useEffect(() => {
    handleRequest();
    interval = setInterval(async () => {
      handleRequest();
    }, 120000);

    return () => clearInterval(interval);
  }, [usuarioId]);


  useEffect(() => {
    const results = data?.filter(
      (data: any) =>
        data.id.toString().includes(searchData) ||
        data.placa.toLowerCase().includes(searchData.toLowerCase()) ||
        data.condutor.toLowerCase().includes(searchData.toLowerCase()) ||
        data.embarcador.toLowerCase().includes(searchData.toLowerCase())
    );

    setSearchResults(results);

    setCurrentPage(1)

    history.push(`/cancelamento-monitoramento/1`);
  }, [searchData]);

  const handleSearch = (event: any) => {
    event.preventDefault();
    setSearchData(event.target.value);
  };

  return (
    <Tela
      nome="Cancelamento de Monitoramento"
      caminho="Operacional > Solicitações > Cancelamento de Monitoramento"
    >

      <Dialog
        header="Atualizando..."
        visible={isDialogAtualizandoOK}
        style={{ width: '20vw' }}
        modal
        onHide={() => {
          if (setIsDialogAtualizandoOK) {
            setIsDialogAtualizandoOK(false);
          }
        }}
      />

      <ModalCancelamentoMonitoramento
        usuarioId={usuarioId}
        clienteNome={clienteNome}
        show={show}
        setShow={setShow}
        motivo={motivo}
        setMotivo={setMotivo}
        selectedItem={selectedItem}
        setRefresh={setRefresh}
        setIsDialogAtualizandoOK={setIsDialogAtualizandoOK}
      />

      <div className="search-create mb-4">
        <span className="p-input-icon-left">
          <input
            type="text"
            placeholder="Pesquise"
            className="p-inputtext p-component"
            id="search-input"
            style={{ width: '240px' }}
            value={searchData}
            onChange={handleSearch}
          />
          <i className="pi pi-search"></i>
        </span>
      </div>
      <div className="table-responsive" style={{ flex: 1 }}>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Nº SM</th>
                <th>Placa do Veículo</th>
                <th>Embarcador</th>
                <th>Condutor</th>
                <th>Data</th>
                <th style={{ width: 80 }}></th>
              </tr>
            </thead>
            <tbody>
              {currentData.map((each: any, index: any) => {
                  return (
                    <>
                      <tr key={index}>
                        <td>{each.id}</td>
                        <td>{each.placa}</td>
                        <td>{each.embarcador}</td>
                        <td>{each.condutor}</td>
                        <td>{obterDataHora(each.dataInicio, true)}</td>
                        <td>
                          <Button
                            tooltip="Cancelar SM"
                            tooltipOptions={{ position: 'bottom' }}
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              padding: 0,
                              paddingRight: 5,
                              marginBottom: 2,
                            }}
                            onClick={() => {
                              if (canDelete) {
                                setShow(true);
                                setSelectedItem(each);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          >
                            <IoCloseSharp size={22} color="#9F9F9F" />
                          </Button>
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        )}
      </div>
      <Pagination
        itensPerPage={itensPerPage}
        setItensPerPage={setItensPerPage}
        totalPosts={searchData.trim() !== '' ? searchResults.length : data.length}
        paginate={paginate}
        currentPage={String(currentPage)}
      />
    </Tela>
  );
};

export default CancelamentoMonitoramento;
