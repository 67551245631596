/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { getCookieSessionData } from '../../services/cookieService';
import instanceMaquina from '../../config/axiosMaquina';
import { useHistory } from 'react-router';

export const itemsUser = () => {
  const usuario = getCookieSessionData().usuarioVO;
  const [mapaRastreamento, setMapaRastreamento] = useState(false);
  const [rotaPorPontos, setRotaPorPontos] = useState(false);
  const [cadastroConsulta, setCadastroConsulta] = useState(false);
  const [controleVelocidade, setControleVelocidade] = useState(false);
  const [controleTemperatura, setControleTemperatura] = useState(false);
  const [monitoramentoVeiculos, setMonitoramentoVeiculos] = useState(false);

  const useNavigation = (path: string) => {
    const history = useHistory();
    return () => {
      history.push(path);
    };
  };

  useEffect(() => {
    function getServicosContratadosCliente(clienteId: number) {
      instanceMaquina
        .get(`/cliente/getServicosContratadosCliente?cliente=${clienteId}`)
        .then((response) => {
          if (response.data.cscNuMapaRastreamento === 0) {
            setMapaRastreamento(false);
          } else {
            setMapaRastreamento(true);
          }
          if (response.data.cscNuRotaPorPontos === 0) {
            setRotaPorPontos(false);
          } else {
            setRotaPorPontos(true);
          }
          if (response.data.cscNuCadastroConsulta === 0) {
            setCadastroConsulta(false);
          } else {
            setCadastroConsulta(true);
          }
          if (response.data.cscNuMonitoramentoVeiculos === 0) {
            setMonitoramentoVeiculos(false);
          } else {
            setMonitoramentoVeiculos(true);
          }
          if (response.data.controleVelocidade === 0) {
            setControleVelocidade(false);
          } else {
            setControleVelocidade(true);
          }
          if (response.data.controleTemperatura === 0) {
            setControleTemperatura(false);
          } else {
            setControleTemperatura(true);
          }
        });
    }
    getServicosContratadosCliente(usuario.clienteId);
  }, [usuario.clienteId]);

  const verificaConsulta = (i: number, url: string, servicoCon?: boolean) => {





    if (!servicoCon) {
      return url;
    } else if (servicoCon === true) {
      return url;
    } else {
      return '/erroservico';
    }




    // if (permissoes[i] && permissoes[i]?.consultar) {
    //   if (servicoCon === true || servicoCon === null) {
    //     return url;
    //   } else if (servicoCon === false) {
    //     return '/erroservico';
    //   }
    //   return url;
    // } else {
    //   return '/acessonegado';
    // }
  };

  const menuItens = [
    {
      label: 'Home',
      command: useNavigation('/home'),
    },
    {
      label: 'Cadastro',
      items: [
        {
          label: 'Proprietário',
          command: useNavigation(verificaConsulta(65, '/cliente/listagem/proprietario/1')),
        },
        {
          label: 'Condutor',
          command: useNavigation(verificaConsulta(67, '/condutores/listar/1')),
        },
        {
          label: 'Ajudantes/Funcionários (não motoristas)',
          command: useNavigation(verificaConsulta(68, '/ajudantes/listar/1')),
        },
        {
          label: 'Veículos',
          command: useNavigation(verificaConsulta(69, '/cadastro/veiculo')),
        },
        {
          label: 'Carretas',
          command: useNavigation(verificaConsulta(70, '/cadastro/carretas/1')),
        },
        {
          label: 'Baú Intercambiável',
          command: useNavigation(verificaConsulta(71, '/bau-intercambiavel/1')),
        },
        {
          label: 'Rota',
          command: useNavigation(verificaConsulta(72, '/rota/1')),
        },
        {
          label: 'Pontos',
          command: useNavigation(verificaConsulta(73, '/pontos/1', rotaPorPontos)),
        },
        {
          label: 'Regras de Apólice',
          command: useNavigation(verificaConsulta(74, '/admin/listar/embarcadores/1')),
        },
      ],
    },
    {
      label: 'Operacional',
      items: [
        {
          label: 'Solicitações',
          items: [
            {
              label: 'Solicitação de Monitoramento (XML)',
              command: useNavigation(
                verificaConsulta(80, '/solicitacao-monitoramento-xml')
              ),
            },
            {
              label: 'Solicitação de Monitoramento',
              command: useNavigation(
                verificaConsulta(80, '/solicitacao-monitoramento/0', monitoramentoVeiculos)
              ),
            },
            {
              label: 'Cancelamento de Monitoramento',
              command: useNavigation(verificaConsulta(81, '/cancelamento-monitoramento/1')),
            },
            {
              label: 'Confirmação Pré-SM',
              command: useNavigation(verificaConsulta(82, '/confirmacao-presm/1')),
            },
          ],
        },
        {
          label: 'Consultas e Relatórios',
          items: [
            {
              label: 'SM Lançadas',
              command: useNavigation(verificaConsulta(84, '/consultar-sm-lancadas')),
            },
            {
              label: 'Checklist',
              command: useNavigation(verificaConsulta(86, '/checklist/filtro-checklist')),
            },
            {
              label: 'Resumo SAT',
              command: useNavigation(verificaConsulta(87, '/consultar-sat')),
            },
            {
              label: 'Não Conformidades',
              command: useNavigation(verificaConsulta(88, '/nao-conformidade/consulta')),
            },
            {
              label: 'Rastreamento',
              items: [
                {
                  label: 'Histórico de Posições',
                  command: useNavigation(
                    verificaConsulta(90, '/rastreamento/historico-posicoes/1')
                  ),
                },
                {
                  label: 'Tempo Parado',
                  command: useNavigation(verificaConsulta(91, '/rastreamento/tempo-parado')),
                },
                // {
                //   label: 'Jornada de trabalho',
                //   command: () => {
                //     window.location.href = '/listar/macros-jornada/1';
                //   },
                // },
              ],
            },
          ],
        },
      ],
    },

    {
      label: 'Análise de Perfil',
      items: [
        {
          label: 'Enviar Pesquisa',
          command: useNavigation(
            verificaConsulta(93, '/enviar-pesquisa/condutor', cadastroConsulta)
          ),
        },
        {
          label: 'Histórico de Pesquisas',
          command: useNavigation(verificaConsulta(94, '/historico-pesquisas', cadastroConsulta)),
        },
      ],
    },
    {
      label: 'Controle Logístico',
      items: [
        {
          label: 'Prevenção de Acidentes',
          command: useNavigation(verificaConsulta(134, '/prevencao-de-acidentes')),
        },
        {
          label: 'Controle de Velocidade',
          command: useNavigation(
            verificaConsulta(96, '/controle-velocidade-por-veiculo', controleVelocidade)
          ),
        },
        {
          label: 'Controle de Temperatura',
          command: useNavigation(
            verificaConsulta(97, '/controle-temperatura-por-veiculo', controleTemperatura)
          ),
        },
        {
          label: 'Progressão de viagens',
          command: useNavigation(verificaConsulta(76, '/progressao-viagens', mapaRastreamento)),
        },
        {
          label: 'Mapa',
          command: useNavigation(verificaConsulta(77, '/mapa', mapaRastreamento)),
        },
      ],
    },

    {
      label: 'Faturamento / Frota',
      command: useNavigation(verificaConsulta(98, '/cliente/faturamentofrota/1')),
    },
    {
      label: 'Usuários',
      items: [
        {
          label: 'Inclusão/Alteração',
          command: useNavigation(verificaConsulta(100, '/listar-usuarios-clientes/1')),
        },
        {
          label: 'Perfis de Usuários',
          command: useNavigation(verificaConsulta(133, '/listar-perfis-usuarios/1')),
        },
      ],
    },

    {
      label: 'Fazer Logout',
      icon: 'pi pi-fw pi-user-minus',
      command: useNavigation('/logout'),
    },
  ];

  const menuEmbarcadorItens = [
    {
      label: 'Home',
      command: () => {
        window.location.href = '/home';
      },
    },
    {
      label: 'Cadastro',
      items: [
        {
          label: 'Faixas de Cubagem',
          command: useNavigation(verificaConsulta(102, '/listar-faixas-de-cubagem/1')),
        },
        {
          label: 'Sazonalidade de Carga',
          command: useNavigation(verificaConsulta(103, '/listar-sazonalidade-da-carga/1')),
        },
        {
          label: 'Capacidade de Carga',
          command: useNavigation(verificaConsulta(104, '/capacidade-de-carga/listar/1')),
        },
        {
          label: 'Ranking-faixas',
          items: [
            {
              label: 'Ajudantes/Funcionários (não motoristas)',
              command: useNavigation(
                verificaConsulta(106, '/ranking/ajudante-funcionario/listar/1')
              ),
            },
            {
              label: 'Condutor',
              command: useNavigation(verificaConsulta(107, '/ranking/condutor/listar/1')),
            },
            {
              label: 'Veículos',
              command: useNavigation(verificaConsulta(108, '/ranking/veiculo/listar/1')),
            },
          ],
        },
        {
          label: 'Pontos',
          command: useNavigation(verificaConsulta(109, '/pontos/1', rotaPorPontos)),
        },
        {
          label: 'CD',
          command: useNavigation(verificaConsulta(110, '/centro-de-distribuicao/1')),
        },

        {
          label: 'Regional',
          command: useNavigation(verificaConsulta(111, '/regional/1')),
        },
        {
          label: 'Não Conformidades',
          command: useNavigation(verificaConsulta(112, '/listar-naoconformidades/1')),
        },
      ],
    },
    {
      label: 'Operacional',
      items: [
        {
          label: 'Reciclagem',
          command: useNavigation(verificaConsulta(118, '/cliente/listar-reciclagem/1')),
        },
        {
          label: 'Pre-SM',
          command: useNavigation(verificaConsulta(119, '/pre-agendamento-viagens/1')),
        },
        {
          label: 'Importar CSV',
          command: useNavigation(verificaConsulta(119, '/importar-pre-sm')),
        },
        {
          label: 'Consultas e Relatórios',
          items: [
            {
              label: 'SM Lançadas',
              command: useNavigation(verificaConsulta(121, '/consultar-sm-lancadas')),
            },
            {
              label: 'Acompanhamento de Remessas',
              command: useNavigation(
                verificaConsulta(122, '/consultas-relatorios/acompanhamento-remessas/1')
              ),
            },
            {
              label: 'Não Conformidades',
              command: useNavigation(verificaConsulta(123, '/nao-conformidade/consulta')),
            },
            {
              label: 'SMS enviados',
              command: useNavigation('/listar/sms-enviados/1'),
            },
          ],
        },
      ],
    },
    {
      label: 'Controle Logístico',
      items: [
        {
          label: 'Prevenção de Acidentes',
          command: useNavigation(verificaConsulta(138, '/prevencao-de-acidentes')),
        },
        // {
        //   label: 'Controle de Velocidade',
        //   command: () => {
        //     window.location.href = verificaConsulta(
        //       139,
        //       '/controle-velocidade-por-veiculo',
        //       controleVelocidade
        //     );
        //   },
        // },
        // {
        //   label: 'Controle de Temperatura',
        //   command: () => {
        //     window.location.href = verificaConsulta(
        //       140,
        //       '/controle-temperatura-por-veiculo',
        //       controleTemperatura
        //     );
        //   },
        // },
        {
          label: 'Progressão de viagens',
          command: useNavigation(verificaConsulta(114, '/progressao-viagens', mapaRastreamento)),
        },
        {
          label: 'Mapa (Geral)',
          command: useNavigation(verificaConsulta(115, '/mapa', mapaRastreamento)),
        },
        {
          label: 'Mapa (Por Operação)',
          command: useNavigation(verificaConsulta(115, '/mapa-operacao', mapaRastreamento)),
        },
      ],
    },
    {
      label: 'Portaria',
      command: useNavigation(verificaConsulta(124, '/portaria')),
    },
    {
      label: 'Checklist',
      command: useNavigation(verificaConsulta(135, '/embarcador/checklist')),
    },

    {
      label: 'Usuários',
      items: [
        {
          label: 'Inclusão/Alteração',
          command: useNavigation(verificaConsulta(126, '/listar-usuarios-clientes/1')),
        },
        {
          label: 'Perfis de Usuários',
          command: useNavigation(verificaConsulta(136, '/listar-perfis-usuarios/1')),
        },
      ],
    },

    {
      label: 'Fazer Logout',
      icon: 'pi pi-fw pi-user-minus',
      command: useNavigation('/logout'),
    },
  ];

  return usuario.isEmbarcador ? menuEmbarcadorItens : menuItens;
};
