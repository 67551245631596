/* eslint-disable*/
import React, { FC } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import './styles.css';
import MenuLateral from '../../components/MenuLateral/MenuLateral';

type SatDTO = {
  veiculoPlaca: string;
  veiculoId: number;
  carretaPlaca: string;
  carretaId: number;
  carreta2Placa: string;
  carreta2Id: number;
  bauNumero: string;
  bauId: number;
  clienteId: number;
  tab: string;
  idSATVeiculo: number;
  idSATBau: number;
  idSATCarreta: number;
  idSATCarreta2: number;
  dataInicializacaoSM: string;
};

const CadastroSat: FC = () => {
  const location: { state: SatDTO } = useLocation();
  const { state } = location;
  const history = useHistory();

  const handleGoToSat = (id: number, type: 'carreta' | 'veiculo' | 'bau') => {
    history.push({
      pathname: '/cliente/operacional/cadastrosat',
      state: {
        id,
        idSATVeiculo: state.idSATVeiculo,
        idSATBau: state.idSATBau,
        idSATCarreta: state.idSATCarreta,
        idSATCarreta2: state.idSATCarreta2,
        dataInicializacaoSM: state.dataInicializacaoSM,
        type,
        clienteId: state.clienteId,
        tab: state.tab,
      },
    });
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'row' }}>
        <MenuLateral />
        <div className="card">
          <div className="titulo-container-cadastroSat">
            <h2 className="titulo-cadastroSat">Solicitação de Assistência Técnica</h2>
          </div>
          <div
            style={{
              flex: 1,
              justifyContent: 'space-between',
              flexDirection: 'column',
              display: 'flex',
            }}
          >
            <div className="tables-sat-container">
              <table className="table table-sat">
                <thead className="thead">
                  <tr>Veículos</tr>
                </thead>
                <tbody>
                  {state.veiculoId && state.veiculoPlaca && (
                    <tr
                      key={state.veiculoId}
                      className="table-sat-row"
                      onClick={() => handleGoToSat(state.veiculoId, 'veiculo')}
                    >
                      <td>{state.veiculoPlaca}</td>
                    </tr>
                  )}
                  {state.carretaId && state.carretaPlaca && (
                    <tr
                      key={state.carretaId}
                      className="table-sat-row"
                      onClick={() => handleGoToSat(state.carretaId, 'carreta')}
                    >
                      <td>{state.carretaPlaca}</td>
                    </tr>
                  )}
                  {state.carreta2Id && state.carreta2Placa && (
                    <tr
                      key={state.carreta2Id}
                      className="table-sat-row"
                      onClick={() => handleGoToSat(state.carreta2Id, 'carreta')}
                    >
                      <td>{state.carreta2Placa}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              {state.bauId && state.bauNumero && (
                <table className="table table-sat">
                  <thead className="thead">
                    <tr>Baú</tr>
                  </thead>
                  <tbody>
                    {state.bauId && state.bauNumero && (
                      <tr
                        key={state.bauId}
                        className="table-sat-row"
                        onClick={() => handleGoToSat(state.bauId, 'bau')}
                      >
                        <td>{state.bauNumero}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
            <div>
              <Form.Row className="container-buttons justify-content-end ">
                <Form.Group>
                  <Button
                    className="btn-cancelar"
                    onClick={() => {
                      state.tab === 'PENDENTE'
                        ? history.push('/admin/painel-operacional/pendentes/1')
                        : history.push('/admin/painel-operacional/em-andamento/1');
                    }}
                  >
                    Voltar
                  </Button>
                </Form.Group>
              </Form.Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CadastroSat;
