/*eslint-disable */
import { memo, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { listTemperature } from '../../../services/temperature';

type Props = {
  clienteId: number;
  setRefreshList: any;
};

const ModalListarLsc = ({ clienteId, setRefreshList }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [veiculos, setVeiculos] = useState<any>([]);
  const [veiculosLista, setVeiculosLista] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [searchLicensePlate, setsearchLicensePlate] = useState('');
  const [dialogVincularVeiculo, setDialogVincularVeiculo] = useState<boolean>(false);
  const [dialogVincularVeiculoPlaca, setDialogVincularVeiculoPlaca] = useState<string>('');
  const [dialogVincularVeiculoID, setDialogVincularVeiculoID] = useState<number>(0);
  const [loadingLink, setLoadingLink] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState('');

  const handleClose = () => {
    setsearchLicensePlate('');
    setShow(false);
  };
  const handleShow = () => {
    setsearchLicensePlate('');
    setShow(true);
  };

  useEffect(() => {
    const veiculosPorVeiculo = veiculosLista.filter(({ placa }: { placa: string }) =>
      placa.includes(searchLicensePlate.toUpperCase())
    );
    setVeiculos(veiculosPorVeiculo);
  }, [searchLicensePlate]);

  useEffect(() => {
    if (!refresh) return;

    setLoading(true);

    const loadTemperature = async () => {
      try {
        const res: any = await listTemperature({
          clienteId,
          pageSize: 99999,
          temperature: false,
          page: 1,
        });

        const getTrucksByTpe = res.data.results.filter(({ veiculo_tipo_nome }: { veiculo_tipo_nome: string }) => veiculo_tipo_nome.includes('REFRIGERADO'))

        setVeiculosLista(getTrucksByTpe);
        setVeiculos(getTrucksByTpe);
        setLoading(false);
        setRefresh(false);
      } catch (e) {
        setVeiculos([]);
        setVeiculosLista([]);
        setLoading(false);
      }
    };

    loadTemperature();
  }, [refresh]);

  useEffect(() => {
    setLoading(true);
    const loadTemperature = async () => {
      try {
        const res: any = await listTemperature({
          clienteId,
          pageSize: 99999,
          temperature: false,
          page: 1,
        });

        // veiculo_tipo_nome

        const getTrucksByTpe = res.data.results.filter(({ veiculo_tipo_nome }: { veiculo_tipo_nome: string }) => veiculo_tipo_nome.includes('REFRIGERADO'))

        setVeiculosLista(getTrucksByTpe);
        setVeiculos(getTrucksByTpe);
        setLoading(false);
        setRefresh(false);
      } catch (e) {
        setVeiculos([]);
        setVeiculosLista([]);
        setLoading(false);
      }
    };

    loadTemperature();
  }, [show]);

  const handleLink = (id: number) => {
    setLoadingLink(true);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/update-vehicle-temperature-monitoring/`, [
        {
          cliente: clienteId,
          id,
          monitoring: true,
        },
      ])
      .then((res) => {
        setRefreshList(true);
        setRefresh(true);

        setDialogVincularVeiculo(false);
        setDialogVincularVeiculoPlaca('');
        setDialogVincularVeiculoID(0);
        setShowResponseMessage(res.data.detail);
      })
      .catch(() => {})
      .finally(() => setLoadingLink(false));
  };

  return (
    <>
      <Button
        style={{
          backgroundColor: '#746dee',
          borderColor: 'red',
          color: 'white',
          border: 'none',
          width: '400px',
          maxHeight: 38,
          padding: '5px 20px',
        }}
        onClick={() => {
          handleShow();
        }}
      >
        Vincular veículos
      </Button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header
          style={{
            alignSelf: 'center',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            enforceFocus
            show={!!showResponseMessage.length}
            onHide={() => {
              setShowResponseMessage('');
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Sucesso!</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center' }}>{showResponseMessage}</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setShowResponseMessage('');
                }}
              >
                Voltar
              </Button>
            </Modal.Footer>
          </Modal>

          <Dialog
            header={`Tem certeza que deseja vincular o veículo (${dialogVincularVeiculoPlaca})?`}
            footer={
              <>
                <Button
                  style={{
                    background: '#df5252',
                    color: '#fff',
                    border: 'none',
                    height: '40px',
                  }}
                  onClick={() => {
                    setDialogVincularVeiculo(false);
                    setDialogVincularVeiculoPlaca('');
                    setDialogVincularVeiculoID(0);
                  }}
                >
                  Não
                </Button>
                <Button
                  style={{
                    background: '#5294df',
                    color: '#fff',
                    border: 'none',
                    height: '40px',
                  }}
                  onClick={() => {
                    handleLink(dialogVincularVeiculoID);
                  }}
                >
                  {loadingLink ? 'Vinculando...' : 'Sim'}
                </Button>
              </>
            }
            visible={dialogVincularVeiculo}
            style={{ width: '50vw' }}
            modal
            closable={false}
            onHide={() => {
              setDialogVincularVeiculo(false);
            }}
          />

          <h2 className="titulo">Vincular veículos</h2>

          {veiculosLista.length > 0 && !loading && (
            <>
              <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <p>
                  <strong style={{ marginRight: '10px', marginLeft: '10px' }}>
                    Total de veículos desvínculados:
                  </strong>
                  {veiculos.length}
                </p>
              </div>

              <div className="search-create mb-4" style={{ width: '100%', marginTop: 25 }}>
                <span className="p-input-icon-left">
                  <input
                    type="text"
                    placeholder="Pesquise"
                    className="p-inputtext p-component"
                    id="search-input"
                    style={{ width: '240px' }}
                    value={searchLicensePlate}
                    onChange={(e: any) => setsearchLicensePlate(e.target.value)}
                  />
                  <i className="pi pi-search" />
                </span>
              </div>
            </>
          )}
        </Modal.Header>
        <Modal.Body
          style={{
            fontSize: 20,
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '80vh',
            padding: 20,
          }}
        >
          {!loading && veiculosLista.length === 0 && (
            <p style={{ textAlign: 'center', alignSelf: 'center' }}>
              Não há dados para serem mostrados
            </p>
          )}

          {loading ? (
            <p style={{ textAlign: 'center', alignSelf: 'center' }}>Buscando veículos...</p>
          ) : (
            <>
              {veiculosLista.length > 0 && (
                <div style={{ maxHeight: '70vh', overflowY: 'auto', width: '100%' }}>
                  <table style={{ width: '100%' }}>
                    <tr>
                      <th>PLACA</th>
                      <th>TIPO</th>
                      <th> </th>
                    </tr>

                    {veiculos.map(({ id, placa, veiculo_tipo_nome }: { id: number; placa: string; veiculo_tipo_nome: string }) => (
                      <tr key={id}>
                        <td>{placa}</td>
                        <td>{veiculo_tipo_nome}</td>

                        <td style={{ textAlign: 'right' }}>
                          <Button
                            onClick={() => {
                              setDialogVincularVeiculo(true);
                              setDialogVincularVeiculoPlaca(placa);
                              setDialogVincularVeiculoID(id);
                            }}
                            style={{ background: '#5294df', color: '#fff', border: 'none' }}
                          >
                            VINCULAR
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </table>
                </div>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ModalListarLsc);
