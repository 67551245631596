/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../../hooks/Toast';
import axios from '../../../config/axiosMaquina';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import { ListagemCliente } from '../../../api/Types/listagemResumoTypes';
import { soNumeros } from '../../../util/Validacoes/Validacoes';
import { dataConverter } from '../../../util/date';

function CadastroAvisos() {
  const { addToast } = useToast();
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const [clientes, setClientes] = useState<ListagemCliente[]>([]);
  const [mensagem, setMensagem] = useState('');
  const [prioridade, setPrioridade] = useState('');
  const [diasPermanencia, setDiasPermanencia] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);
  const [ativo, setAtivo] = useState(true);
  const [dataCadastro, setDataCadastro] = useState(new Date());
  const [isNull, setIsNull] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);

  const history = useHistory();

  const meusClientes = () => {
    if (clientes.length === 0 || clientes.length === listaClientes.length) return null;
    else {
      return clientes.map((each: any) => {
        return each.id;
      });
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    let temp: any = meusClientes();

    if ((diasPermanencia && diasPermanencia <= 0) || !diasPermanencia) {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Dias de permanência deve ser maior que zero',
      });
      return;
    }
    setLoading(true);
    const dados = {
      mensagem,
      prioridade,
      dataCadastro: dataConverter(new Date().toISOString()),
      clientes: temp,
      diasPermanencia,
      ativo,
    };
    try {
      await axios.post('/aviso-operacional', dados);
      setIsDialogCadOK(true);
    } catch (error: any) {
      console.log(error);
      setIsDialogCadOK(false);
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar!',
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        setListaClientes(response.data);
      });
    };
    loadData();
  }, []);

  const validaCampoNull = (event: any) => {
    event.preventDefault();
    if (event.target.value.length > 0) {
      setMensagem(event.target.value);
      setIsNull(false);
    }
    setMensagem(event.target.value);
    setIsNull(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row', minWidth: 667 }}>
      <MenuLateral />

      <Dialog
        header="Aviso cadastrado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/avisos-operacionais');
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Avisos Operacional</h2>
          <h6 className="subtitulo">{'Avisos > Aviso Operacional > Cadastro'}</h6>
        </div>
        <Form validated={isNull} onSubmit={handleSubmit}>
          <h6 style={{ color: 'red', fontSize: 12 }}>* Campos Obrigatórios</h6>
          <Form.Row>
            <Form.Group className="row-modal">
              <Form.Label className="requiredField">CLIENTES</Form.Label>

              <MultiSelect
                style={{ width: '100%' }}
                value={clientes}
                options={listaClientes}
                onChange={(event: any) => setClientes(event.target.value)}
                optionLabel="razaoSocial"
                placeholder="Selecione"
                filter
                className="multiselect-custom"
                tooltip="Selecione um ou mais clientes"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="row-modal">
              <Form.Label className="requiredField">MENSAGEM</Form.Label>
              <Form.Control
                value={mensagem}
                onChange={validaCampoNull}
                required
                as="textarea"
                rows={3}
                title="Campo Obrigatório"
                maxLength={500}
              />
              <Form.Control.Feedback type="invalid">
                Campo mensagem não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group className="row-modal">
              <Form.Label className="requiredField">DATA DE CADASTRO</Form.Label>
              <Form.Control
                onChange={(event: any) => setDataCadastro(event.target.value)}
                id="dataCadastro"
                name="dataCadastro"
                title="Se não selecionada uma data, será definida como padrão a data de hoje."
                type="date"
              />
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Campo data de cadastro não pode ser nulo.
            </Form.Control.Feedback>
            <Form.Group className="row-modal">
              <Form.Label className="requiredField">PRIORIDADE</Form.Label>
              <Form.Control
                value={prioridade}
                onChange={(event: any) => setPrioridade(event.target.value)}
                as="select"
                defaultValue={prioridade}
                title="Campo Obrigatório"
                required
              >
                <option></option>
                <option defaultValue="Normal">Normal</option>
                <option defaultValue="Média">Média</option>
                <option defaultValue="Alta">Alta</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                Campo prioridade não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label className="requiredField">DIAS DE PERMANÊNCIA</Form.Label>
              <Form.Control
                value={diasPermanencia}
                id="diasPermanencia"
                name="diasPermanencia"
                type="text"
                maxLength={3}
                required
                title="Campo Obrigatório"
                isInvalid={diasPermanencia && diasPermanencia <= 0 ? true : false}
                onChange={(event: any) => {
                  setDiasPermanencia(soNumeros(event.target.value));
                }}
              />
              <Form.Control.Feedback type="invalid">
                Campo não pode ser igual a 0.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col} md={2}>
              <Button
                className="btn-cancelar"
                onClick={() => {
                  history.goBack();
                }}
              >
                Cancelar
              </Button>
            </Form.Group>
            <Form.Group as={Col} md={2}>
              <Button className="btn-enviar" type="submit">
                {loading ? <Spinner animation="border" variant="dark" size="sm" /> : 'Confirmar'}
              </Button>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    </div>
  );
}

export default CadastroAvisos;
