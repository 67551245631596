import { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { NaoConformidadeType } from '../../api/model/NaoConformidadeType';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { Tabela } from '../../components/Tabela';
import { Spinner } from '../../components/Spinner';
import { pesquisar } from '../../util/format';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

type InfracaoType = {
  id: number;
  descricao: string;
  qtdePonto: number;
};

type PenalidadeType = {
  id: number;
  descricao: string;
};

function NaoConformidades() {
  const { EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const { clienteId } = getCookieSessionData().usuarioVO;
  const [itensPerPage, setItensPerPage] = useState('10');
  const [data, setData] = useState<any[]>([]);
  const [fullData, setFullData] = useState<any[]>([]);
  const history = useHistory();
  const [historicosPerPage, setHistoricosPerPage] = useState(Number(itensPerPage));
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [infracaoList, setInfracaoList] = useState<InfracaoType[]>([]);
  const [penalidadeList, setPenalidadeList] = useState<PenalidadeType[]>([]);
  const { page }: any = useParams();
  const indexOfLastPost = page * historicosPerPage;
  const [searchValue, setSearchValue] = useState('');
  const indexOfFirstPost = indexOfLastPost - historicosPerPage;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const titles = ['+id', 'Natureza do Evento', 'Causa Raiz', 'Penalidade', 'Infração', 'Pontos'];
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/listar-naoconformidades/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ EMBARCADOR: [EMBARCADOR.CADASTRO.NAO_CONFORMIDADE] }], 'inserir');
  }, [EMBARCADOR, rolePermission]);

  useEffect(() => {
    const pageInt = parseInt(page, 10);
    if (pageInt <= 0 || !pageInt) {
      history.goBack();
    }
  }, [page]);

  const getInfracao = useCallback(
    (infracaoId: number) => {
      return infracaoList.find((i) => i.id === infracaoId)?.descricao;
    },
    [infracaoList]
  );

  const getPenalidade = useCallback(
    (penalidadeId: number) => {
      return penalidadeList.find((i) => i.id === penalidadeId)?.descricao;
    },
    [penalidadeList]
  );

  const getPontos = useCallback(
    (infracaoId: number) => {
      return infracaoList.find((i) => i.id === infracaoId)?.qtdePonto;
    },
    [infracaoList]
  );

  const converteArray = (dt: any[]) => {
    console.log(dt);
    const array = dt.map((each: NaoConformidadeType) => {
      return [
        each.id,
        each.naturezaEvento,
        each.causaRaiz,
        getPenalidade(each.penalidadeId),
        getInfracao(each.infracaoId),
        getPontos(each.infracaoId),
      ];
    });
    return array;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/nao-conformidade/cliente`, {
        params: {
          clienteId,
        },
      });
      setFullData(response.data);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchPenalidadeInfracao = async () => {
    if (!penalidadeList.length && !infracaoList.length) {
      const penalidade = await axios.get(`/penalidade-nao-conformidade`);
      setPenalidadeList(penalidade.data);
      const infracao = await axios.get(`/infracao-nao-conformidade`);
      setInfracaoList(infracao.data);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPenalidadeInfracao();
  }, []);

  useEffect(() => {
    setData(converteArray(fullData));
  }, [penalidadeList, infracaoList, fullData]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar-naoconformidades/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Não Conformidades"
      caminho="Cadastro > Não Conformidades"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/cadastro-naoconformidades"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/editar-naoconformidades/${id}`)}
        />
      )}
    </Tela>
  );
}

export default NaoConformidades;
