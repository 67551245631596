/* eslint-disable */
import React, {
  useState,
  ChangeEvent,
  useEffect,
  KeyboardEvent,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import api from '../../config/axiosMaquina';
import Input from '../Input';
import { getCookieSessionData } from '../../services/cookieService';
import { getFullDate } from '../../util/format';
import {
  cpfMask,
  maskCep,
  soNumeros,
  soLetras,
  validaDataMaxRG,
  maxDateFormatadoMaior18,
  validaDataAtualVencimento,
  validaDataMaxCNH,
} from '../../util/Validacoes/Validacoes';
import { CATEGORIA_CNH, TIPOS_CONDUTOR_AJUDANTE } from '../../constants/Condutor';
import { useToast } from '../../hooks/Toast';
import condutorFieldsValidation from './condutorValidation';
import Select from '../Select';
import getUfEndereco from '../../util/getUfEndereco';
import { nowToZoned, toZoned } from '../../util/date';
import { ErrosDataPesquisa } from '../../pages/EnviarPesquisa';
import { MessageError } from '../Input/styles';

type Ufs = {
  id: number;
  sigla: string;
};

type Municipios = {
  id: number;
  nomeMunicipio: string;
};

export type Condutor = {
  atualizarPesquisaAutomaticamente: boolean;
  bairro: string;
  bloqueado: boolean;
  sexo: string;
  categoriaCnh: string;
  cep: string;
  cidade: number;
  cnhRegistro: string;
  complementoLogradouro: string;
  cpf: string;
  dataNascimento: string;
  id: number;
  idUfEmissorCnh: number;
  idUfEmissorRg: number;
  logradouro: string;
  logradouroNumero: number;
  nome: string;
  nomeCidade: string;
  nomeMae: string;
  nomePai: string;
  nomeUfEndereco: string;
  rg: string;
  segurancaCnh: string;
  tipo: string;
  validadeCnh: string;
  uf: number;
};

type InputErrors = {
  condutor: string;
  cpf: string;
  nome: string;
  rg: string;
  tipo: string;
  cnhRegistro: string;
  categoriaCnh: string;
  dataNascimento: string;
  validadeCnh: string;
  segurancaCnh: string;
  idUfEmissorRg: string;
  idUfEmissorCnh: string;
  nomePai: string;
  nomeMae: string;
  cep: string;
  logradouro: string;
  logradouroNumero: string;
  complementoLogradouro: string;
  bairro: string;
  cidade: string;
  uf: number;
};

export const ContainerPesquisarCondutores = forwardRef((props?: any, ref?: any) => {
  const [loading, setLoading] = useState(false)

  const [dataErros, setDataErros] = useState<ErrosDataPesquisa | undefined>({
    mensagemErro: '',
    listaErros: [],
  });

  const [dialogConsultaOuPesquisa, setDialogConsultaOuPesquisa] = useState(false);
  const [itensPesquisasEnviadas, setItensPesquisasEnviadas] = useState<string[]>([]);
  const [idPesquisaEnviada, setIdPesquisaEnviada] = useState();
  const [condutor, setCondutor] = useState<Condutor>();
  const [listaCondutores, setListaCondutores] = useState<Condutor[]>([]);
  const [listaUfs, setListaUfs] = useState<Ufs[]>([]);
  const [listaMunicipios, setListaMunicipios] = useState<Municipios[]>([]);
  const [inputErrors, setInputErrors] = useState({} as InputErrors);
  const { addToast } = useToast();
  const [viaCepUf, setViaCepUf] = useState('');
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [cepMudou, setCepMudou] = useState(false);
  const [error, setError] = useState(false);
  const [cnhVencida, setCnhVencida] = useState(false);
  const [inProgress, setInProgress] = useState(false)
  const history = useHistory();
  const { tabAtual } = useParams<any>();
  const [isDialogCadErro, setIsDialogCadErro] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;
  const [tipoPesquisa, setTipoPesquisa] = useState('');
  const [itensVigentes, setItensVigentes] = useState<string[]>([]);

  const toggleSwitchAtualizacaoAutomatica = () => {
    if (!condutor) {
      return;
    }
    setCondutor({
      ...condutor,
      atualizarPesquisaAutomaticamente: !condutor?.atualizarPesquisaAutomaticamente,
    });
  };

  const validaCnh = async (id: number, validade: string, idUfAtual: number) => {
    try {
      const response = await api.get(
        `/validacaoCNH/cnhValida?dataValidadeCnhAtual=${validade}&id=${id}&idUfAtual=${idUfAtual}`
      );
      setCnhVencida(!response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  const loadDadosMunicipioPorUf = async (idUf: number) => {
    if (!idUf) {
      return;
    }
    try {
      const response = await api.get(`/municipio-ibge/listarPorUf/${idUf}`);
      setListaMunicipios(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };


  useEffect(() => {
    axios
      .all([
        api.get(`/condutor/listarCondutorNome`, {
          params: {
            cliente: clienteId,
          },
        }),
        api.get('/uf/ListarIdSiglaEstadoPais/1'),
      ])
      .then(
        axios.spread((condutoresResponse, ufSiglasResponse) => {
          setListaCondutores(condutoresResponse.data);
          setListaUfs(ufSiglasResponse.data);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, [clienteId]);

  const loadDadosCondutor = async (idCondutor: number) => {
    if (!idCondutor) {
      if (tabAtual !== 'condutor-veiculo-carreta') {
        history.replace(`/enviar-pesquisa/condutor`);
      }
      setCnhVencida(false);
      setCondutor(undefined);
      return;
    }

    if (tabAtual !== 'condutor-veiculo-carreta') {
      history.replace(`/enviar-pesquisa/condutor/${idCondutor}`);
    }

    setDialogHelperData(true)

    try {
      const response = await api.get(`/condutor/${idCondutor}`);
      setCondutor({
        ...response.data,
        validadeCnh: getFullDate(response.data.validadeCnh),
        dataNascimento: getFullDate(response.data.dataNascimento),
        atualizarPesquisaAutomaticamente:
          response.data.atualizarPesquisaAutomaticamente === null
            ? false
            : response.data.atualizarPesquisaAutomaticamente,
      });
      validaCnh(idCondutor, response.data.validadeCnh, response.data.idUfEmissorCnh);
      loadDadosMunicipioPorUf(response.data.uf);


      const checkValidity = await api.get('/pesquisa/verificar-vigencia', {
        params: {
          dataInicio: nowToZoned(),
          idCliente: clienteId,
          idCondutor: idCondutor,
        },
      });

      if (checkValidity.data === "Pesquisa em andamento") {
        setInProgress(true)
      }

      if (checkValidity.data === 'Nao pode enviar') {
        const vigentes = [];

        vigentes.push(
          response.data?.nome != undefined ? `${response.data?.nome} ${response.data?.cpf}` : ''
        );

        setItensVigentes(vigentes);

        setDialogConsultaOuPesquisa(true);
      }

    } catch (err: any) {
      console.log(err);
    } finally {
      setDialogHelperData(false)
    }
  };

  function updateCondutor(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!condutor) {
      return;
    }

    setCondutor({
      ...condutor,
      [e.target.name]: e.target.value,
    });
  }

  function onKeyPress(event: KeyboardEvent<HTMLInputElement>) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-|\./.test(keyValue)) event.preventDefault();
  }

  const tipoAjudante = TIPOS_CONDUTOR_AJUDANTE.find(
    (each) => each.id?.toString() === condutor?.tipo
  )?.name;
  const formattedData = {
    atualizarPesquisaAutomaticamente: condutor?.atualizarPesquisaAutomaticamente,
    bairro: condutor?.bairro,
    bloqueado: condutor?.bloqueado,
    categoriaCnh: condutor?.categoriaCnh,
    cep: condutor?.cep,
    cidade: condutor?.cidade,
    cidadeNome: condutor?.nomeCidade,
    cnhRegistro: condutor?.cnhRegistro,
    complementoLogradouro: condutor?.complementoLogradouro,
    cpf: condutor?.cpf,
    dataNascimento: condutor?.dataNascimento,
    id: condutor?.id,
    sexo: condutor?.sexo,
    idUfEmissorCnh: condutor?.idUfEmissorCnh,
    idUfEmissorRg: condutor?.idUfEmissorRg,
    logradouro: condutor?.logradouro,
    logradouroNumero: condutor?.logradouroNumero,
    nome: condutor?.nome,
    nomeMae: condutor?.nomeMae,
    nomePai: condutor?.nomePai,
    rg: condutor?.rg,
    segurancaCnh: condutor?.segurancaCnh,
    siglaUFEndereco: condutor?.nomeUfEndereco,
    siglaUfEmissorCNH: condutor?.idUfEmissorCnh && getUfEndereco(+condutor?.idUfEmissorCnh),
    siglaUfEmissorRG: condutor?.idUfEmissorRg && getUfEndereco(+condutor?.idUfEmissorRg),
    tipo: condutor?.tipo,
    tipoEnum: tipoAjudante,
    uf: condutor?.uf,
    validadeCnh: condutor?.validadeCnh,
  };

  useImperativeHandle(
    ref,
    () => ({
      validation() {
        const isValid = condutorFieldsValidation({ condutor, setInputErrors });
        if (!isValid || cnhVencida || inProgress) {
          return false;
        }
        return true;
      },
      condutor: condutor?.id ? formattedData : null,
      reset() {
        setCondutor(undefined)
        setInputErrors({} as InputErrors)
        setInProgress(false)
        setCnhVencida(false)
      }
    }),
    [addToast, condutor, inputErrors, cnhVencida, inProgress]
  );

  useEffect(() => {
    if (!condutor) {
      setInputErrors({} as InputErrors);
    }
  }, [condutor]);

  const buscarCep = (value: string) => {
    if (cepMudou) {
      setError(false);
      fetch(`https://viacep.com.br/ws/${value}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }

          setViaCepCidade(data.localidade);
          const codIbgeFloat = data.ibge / 100000;
          const codIbgeFormatado = Math.trunc(codIbgeFloat);
          setViaCepIbgeCod(codIbgeFormatado);
          if (condutor) {
            setCondutor({
              ...condutor,
              cep: data.cep,
              logradouro: data.logradouro,
              bairro: data.bairro,
              cidade: data.ibge,
              uf: codIbgeFormatado,
            });
          }
        })

        .catch((erro) => {
          setError(true);
        });
    }
  };

  const handleSubmit = async (event: any, novaPesquisa?: boolean, tipoPesquisa = 'Pesquisa') => {
    event.preventDefault();

    const itensEnviados = [];

    itensEnviados.push(
      condutor?.nome != undefined ? `${condutor?.nome} ${condutor?.cpf}` : ''
    );

    setItensPesquisasEnviadas(itensEnviados);

    const tipoAjudante = TIPOS_CONDUTOR_AJUDANTE.find(
      (each) => each.id?.toString() === condutor?.tipo
    )?.name;

    const formattedData = {
      atualizarPesquisaAutomaticamente: condutor?.atualizarPesquisaAutomaticamente,
      bairro: condutor?.bairro,
      bloqueado: condutor?.bloqueado,
      categoriaCnh: condutor?.categoriaCnh,
      cep: condutor?.cep,
      cidade: condutor?.cidade,
      cidadeNome: condutor?.nomeCidade,
      cnhRegistro: condutor?.cnhRegistro,
      complementoLogradouro: condutor?.complementoLogradouro,
      cpf: condutor?.cpf,
      dataNascimento: condutor?.dataNascimento,
      id: condutor?.id,
      sexo: condutor?.sexo,
      idUfEmissorCnh: condutor?.idUfEmissorCnh,
      idUfEmissorRg: condutor?.idUfEmissorRg,
      logradouro: condutor?.logradouro,
      logradouroNumero: condutor?.logradouroNumero,
      nome: condutor?.nome,
      nomeMae: condutor?.nomeMae,
      nomePai: condutor?.nomePai,
      rg: condutor?.rg,
      segurancaCnh: condutor?.segurancaCnh,
      siglaUFEndereco: condutor?.nomeUfEndereco,
      siglaUfEmissorCNH: condutor?.idUfEmissorCnh && getUfEndereco(+condutor?.idUfEmissorCnh),
      siglaUfEmissorRG: condutor?.idUfEmissorRg && getUfEndereco(+condutor?.idUfEmissorRg),
      tipo: condutor?.tipo,
      tipoEnum: tipoAjudante,
      uf: condutor?.uf,
      validadeCnh: condutor?.validadeCnh,
    };

    handleRequest(
      {
        idCliente: clienteId,
        idUsuario: usuarioId,
        condutor: formattedData,
        cadastrarPesquisaNova: novaPesquisa || false,
      },
      'este condutor',
      tipoPesquisa
    );
  };

  const handleRequest = async (data: any, message: string, tipoPesquisa: string = 'Pesquisa') => {
    setLoading(true);

    try {
      const response = await api.post('/pesquisa/integrada', {
        ...data,
        pesquisaManual: false,
        tipoPesquisa
      });


      if (response.data.statusCode !== 200 ) {
        setLoading(false);
        setIsDialogCadErro(true);

        setDataErros({
          mensagemErro: response.data.message,
          listaErros: response.data.dminerErrors
        });
      } else {
        if (
          response.data.mensagem?.includes('Consulta') ||
          response.data.mensagemAjudante?.includes('Consulta') ||
          response.data.mensagemCondutor?.includes('Consulta') ||
          response.data.mensagemVeiculo?.includes('Consulta') ||
          response.data.mensagemCarreta?.includes('Consulta') ||
          response.data.mensagemCarreta2?.includes('Consulta')
        ) {
          setTipoPesquisa('Consulta');
        } else {
          setTipoPesquisa('Pesquisa');
        }

        setIdPesquisaEnviada(response.data.solicitationCode);
        setIsDialogCadOK(true);
      }
    } catch (error: any) {
      if (
        error.response.data.message?.includes('processamento') ||
        error.response.data.message?.includes('Constam resultados')
      ) {
        setIsDialogCadErro(true);
        setDataErros({
          mensagemErro: `já existe uma pesquisa em andamento para ${message}`,
          listaErros: null as any,
        });
      } else {
        const message = error.response.data.message?.split('[')[2].split(']')[0];
        const msg = message.split(',')[1].split('=')[1];

        addToast({
          title: 'Erro!',
          description: error.response.data.message.includes('DminerErroPesquisaVO')
            ? `Atenção: "${msg}"`
            : error.response.data.message,
          type: 'error',
        });
      }
    } finally {
      setCondutor(undefined)

      if(props.habilitarPesquisaIntegrada) {
        history.replace('/enviar-pesquisa/condutor-veiculo-carreta');
      } else {
        history.replace('/enviar-pesquisa/condutor');
      }
      setDialogConsultaOuPesquisa(false);
      setLoading(false);
      return;
    }
  };

  const [dialogHelperData, setDialogHelperData] = useState(false)


  return (
    <>

    <Dialog
        header={
          <>
          <p>Aguarde enquanto buscamos os dados..</p>
            <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
          </>
        }
        closable={false}

        visible={dialogHelperData}
        modal
        onHide={() => {
          setDialogHelperData(false);
        }}
      />



      <Dialog
        header={
          <>
            <p>{`Não foi possível enviar pesquisa. `}</p>
            {dataErros?.listaErros ? (
              <>
                {dataErros.listaErros.map((each) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        closable={false}
        footer={
          <>
            <Button
              onClick={() => {
                setIsDialogCadErro(false);
              }}
            >
              OK
            </Button>
          </>
        }
        visible={isDialogCadErro}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
        }}
      />

      <Dialog
        header={
          <>
            <p>{tipoPesquisa} enviada com sucesso para:</p>
            {itensPesquisasEnviadas ? (
              <>
                {itensPesquisasEnviadas.map((each: string) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Número: {idPesquisaEnviada}</p>
            <Button
            type="button"
              onClick={() => {
                setIsDialogCadOK(false);
                history.go(0)

              }}
            >
              OK
            </Button>
          </div>
        }
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
          history.go(0)
        }}
      />

      <Dialog
        header={
          <div>
            <p>Já existe pesquisa vigente para:</p>
            <br />
            {itensVigentes.map((each: string) => (
              <li>{each}</li>
            ))}
            <br />
          </div>
        }
        closable={false}
        footer={
          <>
            {props.habilitarPesquisaVigente ? (
              <>
                 {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <>
                <p>O que deseja fazer?</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    style={{ color: 'black', fontSize: '12px' }}
                    onClick={(e: any) => {
                      handleSubmit(e, true, 'Pesquisa');

                    }}
                  >
                    Nova Pesquisa
                  </Button>
                  <Button
                  type="button"
                    style={{ color: 'black', fontSize: '12px' }}
                    onClick={(e: any) => {
                      handleSubmit(e, false, 'Consulta');
                    }}
                  >
                    Consulta
                  </Button>
                  <Button
                  type="button"
                    style={{
                      background: 'white',
                      border: '1px solid black',
                      color: 'black',
                      fontSize: '12px',
                    }}
                    onClick={() => {
                      setDialogConsultaOuPesquisa(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            ) }


              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  type="button"
                  style={{
                    background: 'white',
                    border: '1px solid black',
                    color: 'black',
                    fontSize: '11px',
                  }}
                  onClick={() => {
                    setDialogConsultaOuPesquisa(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </>
        }
        visible={dialogConsultaOuPesquisa}
        style={{ width: '40vw' }}
        modal
        onHide={() => {
          setDialogConsultaOuPesquisa(false);
        }}
      />

      <h2 className="titulo">Dados da Pesquisa Condutor</h2>

      <>
         <Form.Row>
        <Form.Group as={Col} sm={12}>
          <div className="containerLimpar">
            <Form.Label>Condutor:</Form.Label>
            <p
              className="limparButton"
              onClick={() => {
                if (tabAtual !== 'condutor-veiculo-carreta') {
                  history.replace(`/enviar-pesquisa/condutor`);
                }
                setCondutor(undefined);
              }}
            >
              Limpar
            </p>
          </div>
          <Dropdown
            value={condutor ? condutor.id : ''}
            options={listaCondutores}
            onChange={(e) => {
              setInProgress(false)
              loadDadosCondutor(parseInt(e.target.value, 10));
            }}
            onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
            optionLabel="nome"
            optionValue="id"
            filter
            filterBy="nome"
            placeholder="Selecione"
            className="p-dropdown"
            showClear
            style={{
              width: '100%',
              borderColor: inProgress ? '#ff0000' : '#ced4da',
            }}
          />
          <p style={{ fontSize: '14px', color: '#ff0000' }}>{inProgress ? 'Condutor possui pesquisa em andamento' : ''}</p>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Input
          disabled
          required
          value={condutor ? cpfMask(condutor.cpf) : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateCondutor(e);
          }}
          id="cpf"
          name="cpf"
          textInputTitle="CPF nº:"
          error={inputErrors.cpf}
        />
        <Input
          disabled={!condutor}
          required
          value={condutor ? condutor.nome : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (condutor) {
              setCondutor({
                ...condutor,
                nome: soLetras(e.target.value),
              });
            }
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="nomeCondutor"
          name="nome"
          textInputTitle="Nome:"
          error={inputErrors.nome}
          maxLength={256}
        />
        <Select
          disabled={!condutor}
          name="tipo"
          required
          textInputTitle="Tipo:"
          value={condutor ? condutor.tipo : ''}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          error={inputErrors.tipo}
        >
          <option value={undefined}>Selecione</option>
          {TIPOS_CONDUTOR_AJUDANTE.map((t) => (
            <option value={t.id} key={t.id}>
              {t.displayName}
            </option>
          ))}
        </Select>
      </Form.Row>
      <Form.Row>
        <Input
          disabled={!condutor}
          min="1"
          step="1"
          required
          onKeyPress={onKeyPress}
          type="number"
          value={condutor ? soNumeros(condutor.cnhRegistro) : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length > 11) {
              return;
            }
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="cnhRegistro"
          name="cnhRegistro"
          textInputTitle="CNH Registro nº:"
          error={inputErrors.cnhRegistro}
          maxLength={11}
          minLength={11}
        />
        <Input
          disabled={!condutor}
          required
          onKeyPress={onKeyPress}
          type="number"
          maxLength={11}
          value={condutor ? condutor.segurancaCnh : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length > 11) {
              return;
            }
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="segurancaCnh"
          name="segurancaCnh"
          textInputTitle="CNH Segurança nº:"
          error={inputErrors.segurancaCnh}
        />
        <Select
          name="idUfEmissorCnh"
          disabled={!condutor}
          required
          value={condutor ? condutor.idUfEmissorCnh : ''}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            updateCondutor(e);
            if (e.target.value !== '0') {
              validaCnh(
                condutor?.id || 0,
                condutor?.validadeCnh || '',
                parseInt(e.target.value, 10)
              );
            }
          }}
          textInputTitle="UF Emissor:"
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          error={inputErrors.idUfEmissorCnh}
        >
          <option value="0">Selecione</option>
          {listaUfs.map((i) => (
            <option value={i.id} key={i.id}>
              {i.sigla}
            </option>
          ))}
        </Select>
        <Select
          disabled={!condutor}
          name="categoriaCnh"
          required
          value={condutor ? (condutor.categoriaCnh != null ? condutor.categoriaCnh : '') : ''}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          textInputTitle="Categoria:"
          error={inputErrors.categoriaCnh}
        >
          <option value={undefined}>Selecione</option>
          {CATEGORIA_CNH.map((t) => (
            <option value={t.id} key={t.id}>
              {t.name}
            </option>
          ))}
        </Select>
        <Input
          disabled={!condutor}
          required
          id="validadeCnh"
          name="validadeCnh"
          type="date"
          value={condutor ? (condutor.validadeCnh as string) : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length > 10) {
              return;
            }

            validaCnh(condutor?.id || 0, e.target.value, condutor?.idUfEmissorCnh || 0);
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          error={cnhVencida ? 'CNH Vencida.' : ''}
          textInputTitle="Validade:"
          min={validaDataAtualVencimento()}
          max={validaDataMaxCNH()}
        />
      </Form.Row>
      <Form.Row>
        <Input
          disabled={!condutor}
          required
          type="date"
          value={condutor ? condutor.dataNascimento : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (e.target.value.length > 10) {
              return;
            }
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="dataNascimento"
          name="dataNascimento"
          textInputTitle="Nascimento:"
          error={inputErrors.dataNascimento}
          max={maxDateFormatadoMaior18()}
          min={validaDataMaxRG()}
        />
        <Input
          disabled={!condutor}
          required
          value={condutor ? condutor.rg : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="rg"
          name="rg"
          textInputTitle="RG nº:"
          error={inputErrors.rg}
          maxLength={15}
        />
        <Select
          disabled={!condutor}
          name="idUfEmissorRg"
          required
          value={condutor ? condutor.idUfEmissorRg : ''}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          textInputTitle="UF Emissor:"
          error={inputErrors.idUfEmissorRg}
        >
          <option value="0">Selecione</option>
          {listaUfs.map((i) => (
            <option value={i.id} key={i.id}>
              {i.sigla}
            </option>
          ))}
        </Select>
      </Form.Row>
      <Form.Row>
        <Input
          disabled={!condutor}
          required
          value={condutor ? maskCep(condutor.cep) : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateCondutor(e);
            setCepMudou(true);
            if (e.target.value.length === 9) {
              buscarCep(e.target.value);
            }
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="cep-condutor"
          name="cep"
          textInputTitle="CEP:"
          error={inputErrors.cep}
          maxLength={9}
        />
        <Input
          disabled={!condutor}
          required
          value={condutor ? condutor.logradouro : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="logradouro-condutor"
          name="logradouro"
          textInputTitle="Logradouro:"
          error={inputErrors.logradouro}
          maxLength={32}
        />
        <Input
          disabled={!condutor}
          required
          type="number"
          value={condutor ? condutor.logradouroNumero : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="logradouroNumero"
          name="logradouroNumero"
          textInputTitle="Número:"
          error={inputErrors.logradouroNumero}
          maxLength={8}
        />
      </Form.Row>
      <Form.Row>
        <Input
          disabled={!condutor}
          value={condutor ? condutor.complementoLogradouro : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateCondutor(e);
          }}
          id="complementoLogradouro"
          name="complementoLogradouro"
          textInputTitle="Complemento:"
          maxLength={32}
        />
        <Input
          disabled={!condutor}
          required
          value={condutor ? condutor.bairro : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="bairro-condutor"
          name="bairro"
          textInputTitle="Bairro:"
          error={inputErrors.bairro}
          maxLength={32}
        />
        <Select
          name="cidade"
          disabled={!condutor}
          required
          value={condutor ? condutor.cidade : ''}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          textInputTitle="Cidade:"
          error={inputErrors.cidade}
        >
          <option value="0">Selecione</option>
          {listaMunicipios.map((i) => (
            <option value={i.id} key={i.id}>
              {i.nomeMunicipio}
            </option>
          ))}
          {viaCepCidade && <option value={condutor?.cidade}>{viaCepCidade}</option>}
        </Select>
        <Select
          name="uf"
          disabled={!condutor}
          required
          value={condutor ? condutor.uf : ''}
          onChange={(e: ChangeEvent<HTMLSelectElement>) => {
            updateCondutor(e);
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          textInputTitle="UF:"
          error={inputErrors.uf}
        >
          <option value="0">Selecione</option>
          {listaUfs.map((i) => (
            <option value={i.id} key={i.id}>
              {i.sigla}
            </option>
          ))}
        </Select>
      </Form.Row>
      <Form.Row>
        <Input
          disabled={!condutor}
          required
          value={condutor ? condutor.nomePai : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (condutor) {
              setCondutor({
                ...condutor,
                nomePai: soLetras(e.target.value),
              });
            }
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="nomePai"
          name="nomePai"
          textInputTitle="Nome Pai:"
          error={inputErrors.nomePai}
          maxLength={256}
        />
        <Input
          disabled={!condutor}
          required
          value={condutor ? condutor.nomeMae : ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (condutor) {
              setCondutor({
                ...condutor,
                nomeMae: soLetras(e.target.value),
              });
            }
          }}
          onBlur={() => condutorFieldsValidation({ condutor, setInputErrors })}
          id="nomeMae"
          name="nomeMae"
          textInputTitle="Nome Mãe:"
          error={inputErrors.nomeMae}
          maxLength={256}
        />
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} controlId="formBasicCheckbox">
          <Switch
            disabled={!condutor}
            value={condutor?.atualizarPesquisaAutomaticamente ? 1 : 0}
            checked={condutor ? condutor.atualizarPesquisaAutomaticamente : false}
            onChange={() => toggleSwitchAtualizacaoAutomatica()}
            name="atualizarPesquisaAutomaticamente"
            id="atualizarPesquisaAutomaticamente"
            type="switch"
            label="Atualizar pesquisa automaticamente"
          />
        </Form.Group>
      </Form.Row>
        </>


    </>
  );
});
