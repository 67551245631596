/* eslint-disable */
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Form, Col, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import axios from '../../../config/axiosMaquina';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../../hooks/Toast';
import { semCaracterEspecial } from '../../../util/Validacoes/Validacoes';
import { ClienteServicosContratadosDTO } from './TabServicosContratados';
import { getCookieSessionData } from '../../../services/cookieService';

type ViolacaoTemperatura = {
  enviaComandoSirene: boolean;
  enviaEmail: boolean;
  enviaMensagemVeiculo: boolean;
  enviaSms: boolean;
  enviaWhatsapp: boolean;
  idCliente: number;
  idViolacao: number;
  emailsViolacaoVelocidade: string;
  numerosTelefone: string;
  mensagemEmailSmsWapp: string;
  mensagemTeclado: string;
  moduloTipo: string;
};

type Props = {
  razaoSocial: string;
  idCliente: number;
  cadastroRealizado: boolean;
  idParticularidades?: number | null;
  idServicos?: number | null;
  idClienteByPost?: number | null;
  canEdit?: boolean;
  servicosContratados: ClienteServicosContratadosDTO;
};

const TabControleTemperatura: React.FC<Props> = ({
  razaoSocial,
  idCliente,
  cadastroRealizado,
  idClienteByPost,
  canEdit,
  servicosContratados,
}) => {
  const history = useHistory();
  const { addToast } = useToast();

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const initialState = {
    enviaComandoSirene: false,
    enviaEmail: false,
    enviaMensagemVeiculo: false,
    enviaSms: false,
    enviaWhatsapp: false,
    idCliente: idCliente,
    idViolacao: 0,
    emailsViolacaoVelocidade: '',
    numerosTelefone: '',
    mensagemEmailSmsWapp: '',
    mensagemTeclado: '',
    moduloTipo: '',
  };
  const [violacao, setViolacao] = useState<ViolacaoTemperatura>(initialState);
  const { id }: any = useParams();
  const usuario = getCookieSessionData().usuarioVO.usuarioId;
  const [modalCadastro, setModalCadastro] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    enviaComandoSirene,
    enviaEmail,
    enviaMensagemVeiculo,
    enviaSms,
    enviaWhatsapp,
    idViolacao,
    emailsViolacaoVelocidade,
    numerosTelefone,
    mensagemEmailSmsWapp,
    mensagemTeclado,
    moduloTipo,
  } = violacao;

  const getViolacao = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `/violacao-temperatura/idCliente?idCliente=${idCliente || idClienteByPost}`
      );
      if (response.data.idViolacao) {
        setViolacao(response.data);
      } else {
        setViolacao(initialState);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    idViolacao === 0 && getViolacao();
  }, [idCliente, idClienteByPost]);

  const handleRegisterControleTemperatura = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    try {
      setLoading(true);
      if (idViolacao === 0) {
        const response = await axios.post('/violacao-temperatura/salvar', {
          ...violacao,
          idCliente: idCliente || idClienteByPost,
        });
        setModalCadastro(true);
      } else {
        const response = await axios.put(
          `/violacao-temperatura/atualizar?idTemperaturaViolacao=${idViolacao}`,
          {
            ...violacao,
            idUsuario: usuario || null
          }
        );
        setModalCadastro(true);
      }
    } catch (err: any) {
      console.log(err.response);
      addToast({
        title: 'Erro!',
        description: `${idViolacao === 0 ? 'Erro ao cadastrar' : 'Erro ao atualizar'}`,
        type: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  function getModuloContratado(moduloContratado: string) {
    if (moduloContratado == '') {
      return;
    }
    if (moduloContratado == 'BASICO') {
      return;
    }
    if (moduloContratado == 'AVANCADO') {
      return (
        <fieldset style={{ paddingLeft: 0 }}>
          <Form.Label style={{ paddingBottom: 5 }}>Configurações módulo avançado</Form.Label>
          <Form.Row>
            <Form.Group className="m-3 my-1" as={Col} md={3} sm={12}>
              <Form.Label>Interações com o veículo</Form.Label>
              <Form.Check
                type="checkbox"
                id="veiculoMensagem"
                className="mb-2"
                checked={enviaMensagemVeiculo}
                onChange={() => {
                  setViolacao({
                    ...violacao,
                    enviaMensagemVeiculo: !enviaMensagemVeiculo,
                  });
                }}
                label="Enviar mensagem para o teclado"
              />
              <Form.Check
                type="checkbox"
                id="veiculoSirene"
                className="mb-2"
                checked={enviaComandoSirene}
                onChange={() => {
                  setViolacao({
                    ...violacao,
                    enviaComandoSirene: !enviaComandoSirene,
                  });
                }}
                label="Enviar comando de sirene"
              />
            </Form.Group>
            <Form.Group className="m-3 my-1" as={Col} md={3} sm={12}>
              <Form.Label>Interações com o cliente</Form.Label>
              <Form.Check
                type="checkbox"
                id="clienteEmail"
                className="mb-2"
                checked={enviaEmail}
                onChange={() => {
                  setViolacao({
                    ...violacao,
                    enviaEmail: !enviaEmail,
                  });
                }}
                label="Enviar e-mail"
              />
              <Form.Check
                type="checkbox"
                id="clienteSMS"
                className="mb-2"
                checked={enviaSms}
                onChange={() => {
                  setViolacao({
                    ...violacao,
                    enviaSms: !enviaSms,
                  });
                }}
                label="Enviar SMS"
              />
              <Form.Check
                type="checkbox"
                id="clienteWhatsApp"
                className="mb-2"
                checked={enviaWhatsapp}
                onChange={() => {
                  setViolacao({
                    ...violacao,
                    enviaWhatsapp: !enviaWhatsapp,
                  });
                }}
                label="Enviar mensagem WhatsApp"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Label>
                E-mails violação temperatura
                <br />
                separar emails com &quot;;&quot;
              </Form.Label>
              <Form.Control
                id="textoMensagemTeclado"
                as="textarea"
                placeholder="Ex:. email@email.com;email2@email2.com"
                value={emailsViolacaoVelocidade}
                onChange={(e) =>
                  setViolacao({ ...violacao, emailsViolacaoVelocidade: e.target.value })
                }
                rows={4}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Label className="mt-3">Texto mensagem teclado</Form.Label>
              <Form.Control
                id="textoMensagemTeclado"
                as="textarea"
                value={mensagemTeclado}
                onChange={(e) =>
                  setViolacao({ ...violacao, mensagemTeclado: semCaracterEspecial(e.target.value) })
                }
                rows={4}
              />
            </Form.Group>
            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Label className="mt-3">Texto mensagem e-mail, SMS, WhatsApp</Form.Label>
              <Form.Control
                id="textoMensagemTeclado"
                as="textarea"
                value={mensagemEmailSmsWapp}
                onChange={(e) => setViolacao({ ...violacao, mensagemEmailSmsWapp: e.target.value })}
                rows={4}
              />
            </Form.Group>
            <Form.Group as={Col} className="mt-3 ml-3">
              <Form.Label className="mt-3">
                Números telefone violação temperatura
                <br />
                separar telefones com &quot;;&quot;
              </Form.Label>
              <Form.Control
                id="textoMensagemTeclado"
                as="textarea"
                placeholder="Ex:. 2122334455;2166778899"
                value={numerosTelefone}
                onChange={(e) => setViolacao({ ...violacao, numerosTelefone: e.target.value })}
                rows={4}
              />
            </Form.Group>
          </Form.Row>
        </fieldset>
      );
    }
  }

  const goControleVelocidade = () => {
    setModalCadastro(false);
    if (!id) {
      history.push(
        servicosContratados.controleVelocidade === 1
          ? '/cadastrar-clientes/controleVelocidade'
          : '/clientes/listar/1'
      );
    }
  };

  return (
    <>
      <Dialog
        header={
          idCliente
            ? 'Alteração realizada com sucesso!'
            : 'Controle de temperatura foi cadastrado com sucesso!'
        }
        footer={
          <>
            <Button label="OK" onClick={goControleVelocidade} />
          </>
        }
        visible={modalCadastro}
        style={{ width: '50vw' }}
        modal
        onHide={goControleVelocidade}
      />
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <Form noValidate validated={isFormInvalid} onSubmit={handleRegisterControleTemperatura}>
          <Form.Row>
            <Form.Group as={Col} className="mt-3">
              <Form.Label>Cliente</Form.Label>
              <Form.Control
                value={razaoSocial}
                id="razaoSocial"
                name="razaoSocial"
                placeholder=""
                style={{ width: '49.8 %' }}
                maxLength={64}
                disabled
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} style={{ marginBottom: 0 }}>
              <hr />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} lg={4}>
              <Form.Label>Módulo contratado</Form.Label>
              <Form.Control
                value={moduloTipo}
                onChange={(e: any) => {
                  setViolacao({
                    ...violacao,
                    moduloTipo: e.target.value,
                  });
                }}
                id="moduloTipo"
                name="moduloTipo"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="">Selecione uma opção</option>
                <option value="BASICO">Básico</option>
                <option value="AVANCADO">Avançado</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} style={{ marginBottom: 0 }}>
              <hr />
            </Form.Group>
          </Form.Row>
          {getModuloContratado(moduloTipo)}

          {idCliente || cadastroRealizado || idClienteByPost ? (
            <ButtonsForm canEdit={canEdit} />
          ) : null}
        </Form>
      )}
    </>
  );
};

export default TabControleTemperatura;
