/* eslint-disable */

import { Button } from 'primereact/button';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import React, { FC, useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { TIPO_BUSCA } from '../../../constants/HistoricoPesquisa';
import DadosPesquisaCnh from './DadosPesquisaCnh';
import DadosPesquisaCondutor from './DadosPesquisaCondudor';
import DadosPesquisaVeiculo from './DadosPesquisaVeiculo';
import DadosSolicitacao from './DadosSolicitacao';
import api from '../../../config/axiosMaquina';
import Logo from '../../../assets/go.png';
import DadosPesquisaFuncionario from './DadosPesquisaFuncionario';
import { getCookieSessionData } from '../../../services/cookieService';
import {
  AjudantePesquisa,
  CondutorPesquisa,
  PesquisaCongeladaType,
  VeiculoPesquisa,
  CnhPesquisa,
} from '../../../Types/PesquisaCongeladaType';
import { Tela } from '../../../components/Tela';
import { SecundaryButton } from '../../../components/ButtonsForm/styles';
import axios from '../../../config/axiosMaquina';

// import {  PesquisaCongeladaType, Veiculo, Condutor } from '../../../Types/PesquisaCongeladaType';

export type Condutor = {
  bairro: string;
  categoriaCnh: string;
  cnhRegistro: string;
  complementoLogradouro: string;
  cpf: string;
  dataNascimento: string;
  emissaoRg: string;
  id: number;
  logradouro: string;
  logradouroNumero: number;
  nome: string;
  nomeCidade: string;
  nomeMae: string;
  nomePai: string;
  nomeUfEndereco: string;
  orgaoEmissor: number;
  rg: string;
  segurancaCnh: string;
  sexo: string;
  telefone: string;
  tipo: string;
  uf: number;
  validadeCnh: string;
  idUfEmissorRg: number;
  cep: string;
  idUfEmissorCnh: number;
};

export type Ajudante = {
  bairro: string;
  cep: string;
  cidade: number;
  cidadeNascimento: number;
  cpf: string;
  dataEmissao: string;
  dataNascimento: string;
  id: number;
  idOrgaoEmissor: number;
  logradouro: string;
  logradouroComplemento: string;
  logradouroNumero: number;
  nextelContato: string;
  nome: string;
  nomeCidade: string;
  nomeCidadeNascimento: string;
  nomeMae: string;
  nomePai: string;
  nomeUfEmissor: string;
  nomeUfEndereco: string;
  nomeUfNascimento: string;
  rg: string;
  tipo: string;
};

type Veiculo = {
  anttRntrc: string;
  carroceriaTipo: number;
  chassi: string;
  emplacamentoUf: number;
  id: number;
  placa: string;
  proprietarioId: number;
  renavam: string;
  tipoFrota: string;
};

export type ListagemHistoricoPesquisa = {
  idCliente: number;
  dataLancamento: string;
  empresa: string;
  itens: {
    dataRetorno: string;
    descricao: string;
    idPesquisaEntidade: number;
    itemPesquisado: string;
    tipo: string;
    validade: string;
    validadePesquisaEntidade: string;
    statusPesquisa?: string;
    statusPesquisaCondutorCnh?: string;
  }[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  isAdmin: boolean;
};

export type Item = {
  dataRetorno: string;
  descricao: string;
  idPesquisaEntidade: number;
  itemPesquisado: string;
  tipo: string;
  validade: string;
  validadePesquisaEntidade: string;
  statusPesquisa?: string;
  statusPesquisaCondutorCnh?: string;
};

export const DetalhePesquisa: FC = () => {
  const [condutor, setCondutor] = useState<CondutorPesquisa | undefined>();
  const [cnh, setCnh] = useState<CnhPesquisa | undefined>();
  const [ajudante, setAjudante] = useState<AjudantePesquisa | undefined>();
  const [veiculo, setVeiculo] = useState<VeiculoPesquisa | undefined>();
  const [carreta1, setCarreta1] = useState<VeiculoPesquisa | undefined>();
  const [carreta2, setCarreta2] = useState<VeiculoPesquisa | undefined>();
  const [data, setData] = useState<PesquisaCongeladaType>();
  const [state, setState] = useState<ListagemHistoricoPesquisa>();
  const history = useHistory();
  const { id, idCliente }: { id: string; idCliente: string } = useParams();

  useEffect(() => {
    console.log('--/--id', id);
    const body = {
      numeroLiberacao: id,
    };

    const fetchData = async () => {
      try {
        const response = await axios.post(`/pesquisa/historico-gr`, body);

        const itensMap: any = [];

        response.data.items.map((it: any) => {
          itensMap.push(it.itens[0]);
        });

        const item = { ...response.data.items[0], itens: itensMap };

        setState(item);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  // PESQUISAS CONGELADAS
  useEffect(() => {
    const loadDados = async () => {
      console.log('state', state);
      try {
        const response = await api.get<PesquisaCongeladaType>(
          `/dminer-pesquisa-congelamento/buscar-por-id-dp?idDp=${id}`
        );
        if (!response.data) {
          handleItens();
        }
        setData(response.data);

        setVeiculo({
          anttRntrc: response.data.veiTxRntrc,
          chassi: response.data.veiTxChassi,
          emplacamentoUf: response.data.veiTxUf,
          placa: response.data.veiTxPlaca,
          proprietarioId: response.data.proTxNomeVei,
          renavam: response.data.veiTxRenavam,
          tipoFrota: response.data.veiTxTipo,
          proprietarioCpfCnpj: response.data.cnpjCpfProprietarioVeiculo,
        });

        setCarreta1({
          anttRntrc: response.data.carTxRntrc,
          chassi: response.data.carTxChassi,
          emplacamentoUf: response.data.carTxUf,
          placa: response.data.carTxPlaca,
          proprietarioId: response.data.proTxNomeCar,
          renavam: response.data.carTxRenavam,
          tipoFrota: response.data.carTxTipo,
          proprietarioCpfCnpj: response.data.cnpjCpfProprietarioCarreta,
        });

        setCarreta2({
          anttRntrc: response.data.car2TxRntrc,
          chassi: response.data.car2TxChassi,
          emplacamentoUf: response.data.car2TxUf,
          placa: response.data.car2TxPlaca,
          proprietarioId: response.data.proTxNomeCar2,
          renavam: response.data.car2TxRenavam,
          tipoFrota: response.data.car2TxTipo,
          proprietarioCpfCnpj: response.data.cnpjCpfProprietarioCarreta2,
        });

        setAjudante({
          bairro: response.data.afuTxBairro,
          cep: response.data.afuTxCep,
          cidade: response.data.afuTxCidade,
          cpf: response.data.afuTxCpf,
          dataNascimento: response.data.afuDtNascimento,
          logradouro: response.data.afuTxLogradouro,
          logradouroNumero: response.data.afuNuNumeroLogradouro,
          nome: response.data.afuTxNome,
          nomeMae: response.data.afuTxMae,
          nomePai: response.data.afuTxPai,
          nomeUfEndereco: response.data.afuTxUfCidade,
          nomeUfEmissor: response.data.afuTxUfNascimento,
          rg: response.data.afuTxRg,
          tipo: response.data.afuTxTipo,
        });

        setCondutor({
          bairro: response.data.conTxBairro,
          cidade: response.data.conTxCidade,
          cpf: response.data.conTxCpf,
          dataNascimento: response.data.conDtNascimento,
          logradouro: response.data.conTxLogradouro,
          logradouroNumero: response.data.conNuNumeroLogradouro,
          nome: response.data.conTxNome,
          nomeMae: response.data.conTxNomeMae,
          nomePai: response.data.conTxNomePai,
          rg: response.data.conTxRg,
          cidadeNascimento: response.data.conTxUfCidade,
          tipo: response.data.conTxTipo,
          uf: response.data.conTxUfNascimento,
          ufEmissor: response.data.conTxUfNascimento,
          cep: response.data.conTxCep,
        });
        setCnh({
          cpf: response.data.conTxCpf,
          tipo: response.data.conTxTipo,
          dataNascimento: response.data.conDtNascimento,
          nome: response.data.conTxNome,
          cnhNumero: response.data.conTxNumeroCnh,
          cnhNumSeguranca: response.data.conTxSegurancaCnh,
          ufCnh: response.data.conTxUfCnh,
          categoria: response.data.conTxCategoriaCnh,
          validade: response.data.conDtValidadeCnh,
          nomePai: response.data.conTxNomePai,
          nomeMae: response.data.conTxNomeMae,
        });
      } catch (err: any) {
        handleItens();
        console.log(err.response);
      }
    };
    loadDados();
  }, [state]);

  // SUPORTE PARA PESQUISAS ANTIGAS

  const handleItens = async () => {
    if (state?.operacao === 'Pesquisa Cadastral Ajudante/Funcionário') {
      loadDadosAjudante();
    } else if (state?.operacao === 'Pesquisa Cadastral Veículo') {
      loadDadosVeiculos();
    } else if (state?.operacao === 'Pesquisa Cadastral Condutor') {
      loadDadosCondutor();
    } else if (state?.operacao.toLowerCase().includes('integrada')) {
      loadDadosVeiculos();
      loadDadosCondutor();
    }
  };

  const loadDadosAjudante = async () => {
    if (state?.operacao.includes('Ajudante')) {
      try {
        const response = await api.get(`/ajudante-funcionario/ajudante-por-cpf`, {
          params: {
            cpf: state?.itens[0].itemPesquisado,
            idCliente: state?.idCliente,
          },
        });

        setAjudante({
          bairro: response.data.bairro,
          cep: response.data.cep,
          cidade: response.data.nomeCidade,
          cpf: response.data.cpf,
          dataNascimento: response.data.dataNascimento,
          logradouro: response.data.logradouro,
          logradouroNumero: response.data.logradouroNumero,
          nome: response.data.nome,
          nomeMae: response.data.nomeMae,
          nomePai: response.data.nomePai,
          nomeUfEndereco: response.data.nomeUfEndereco,
          nomeUfEmissor: response.data.nomeUfEmissor,
          rg: response.data.rg,
          tipo: response.data.tipo,
        });
      } catch (err: any) {
        console.log(err.response);
      }
    }
  };

  const loadDadosVeiculos = async () => {
    const listaItens = state?.itens
      .filter((each) => each.itemPesquisado.length < 8)
      .map((each) => each.itemPesquisado);

    listaItens?.map(async (each: any, index: number) => {
      try {
        const response = await api.get(`/veiculo/veiculo-por-placa-cliente`, {
          params: {
            placa: each,
            idCliente: state?.idCliente,
          },
        });

        if (index === 0) {
          setVeiculo({
            anttRntrc: response.data.anttRntrc,
            chassi: response.data.chassi,
            emplacamentoUf: response.data.emplacamentoUf,
            placa: response.data.placa,
            proprietarioId: response.data.proprietarioId.toString(),
            renavam: response.data.renavam,
            tipoFrota: response.data.tipoFrota,
            proprietarioCpfCnpj: response.data.cnpjCpfProprietarioVeiculo,
          });
        }
        if (index === 1) {
          setCarreta1({
            anttRntrc: response.data.anttRntrc,
            chassi: response.data.chassi,
            emplacamentoUf: response.data.emplacamentoUf,
            placa: response.data.placa,
            proprietarioId: response.data.proprietarioId.toString(),
            renavam: response.data.renavam,
            tipoFrota: response.data.tipoFrota,
            proprietarioCpfCnpj: response.data.cnpjCpfProprietarioCarreta,
          });
        }
        if (index === 2) {
          setCarreta2({
            anttRntrc: response.data.anttRntrc,
            chassi: response.data.chassi,
            emplacamentoUf: response.data.emplacamentoUf,
            placa: response.data.placa,
            proprietarioId: response.data.proprietarioId.toString(),
            renavam: response.data.renavam,
            tipoFrota: response.data.tipoFrota,
            proprietarioCpfCnpj: response.data.cnpjCpfProprietarioCarreta2,
          });
        }
      } catch (err: any) {
        console.log(err.response);
      }
    });
  };

  const loadDadosCondutor = async () => {
    if (state?.itens.find((item: any) => item.codigoPesquisaDminerProcesso)) return;

    const listaItens = state?.itens.map((each) => each.itemPesquisado);

    try {
      const response = await api.get(`/condutor/condutor-por-cpf`, {
        params: {
          cpf:
            listaItens?.length === 1
              ? listaItens[0]
              : listaItens?.find((each: any) => each.length > 7),
          idCliente: state?.idCliente,
        },
      });

      setCondutor({
        bairro: response.data.bairro,
        cidade: response.data.nomeCidade,
        cpf: response.data.cpf,
        dataNascimento: response.data.dataNascimento,
        logradouro: response.data.logradouro,
        logradouroNumero: response.data.logradouroNumero,
        nome: response.data.nome,
        nomeMae: response.data.nomeMae,
        nomePai: response.data.nomePai,
        rg: response.data.rg,
        cidadeNascimento: response.data.cidadeNascimento,
        tipo: response.data.tipo,
        uf: response.data.uf.toString(),
        ufEmissor: response.data.idUfEmissorRg.toString(),
        cep: response.data.cep,
      });
      setCnh({
        cpf: response.data.cpf,
        tipo: response.data.tipo,
        dataNascimento: response.data.dataNascimento,
        nome: response.data.nome,
        cnhNumero: response.data.cnhRegistro,
        cnhNumSeguranca: response.data.segurancaCnh,
        ufCnh: response.data.idUfEmissorCnh,
        categoria: response.data.categoriaCnh,
        validade: response.data.validadeCnh,
        nomePai: response.data.nomePai,
        nomeMae: response.data.nomeMae,
      });
    } catch (err: any) {
      console.log(err.response);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <div className="card">
        <div className="titulo-container">
          <div className="title-route">
            <h2 className="titulo">Histórico de pesquisas lançadas</h2>
            <h6 className="subtitulo">{'Cadastro/Consulta > Histórico de Pesquisas'}</h6>
          </div>
          <img src={Logo} className="logo-image-media-screen" alt="Logo" />
        </div>
        {data || condutor || veiculo || ajudante || carreta1 || carreta2 ? (
          <Form noValidate>
            {state && (
              <>
                <DadosSolicitacao solicitacao={state} />
                <hr />
                {condutor?.cpf != null && (
                  <>
                    <DadosPesquisaCondutor condutor={condutor} solicitacao={state} />
                    <hr />
                  </>
                )}
                {cnh?.cnhNumero != null && (
                  <>
                    <DadosPesquisaCnh dadosCnh={cnh} solicitacao={state} />
                    <hr />
                  </>
                )}
                {veiculo?.placa && (
                  <>
                    <DadosPesquisaVeiculo veiculo={veiculo} solicitacao={state} />
                    <hr />
                  </>
                )}
                {carreta1?.placa && (
                  <>
                    <DadosPesquisaVeiculo veiculo={carreta1} solicitacao={state} />
                    <hr />
                  </>
                )}
                {carreta2?.placa && (
                  <>
                    <DadosPesquisaVeiculo veiculo={carreta2} solicitacao={state} />
                    <hr />
                  </>
                )}
                {ajudante?.cpf != null && (
                  <>
                    <DadosPesquisaFuncionario ajudante={ajudante} solicitacao={state} />
                    <hr />
                  </>
                )}
                <Form.Row
                  className="container-buttons justify-content-end"
                  style={{ marginRight: 10 }}
                >
                  <SecundaryButton
                    size="sm"
                    onClick={() => {
                      window.close();
                    }}
                  >
                    Voltar
                  </SecundaryButton>
                </Form.Row>
              </>
            )}
          </Form>
        ) : (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        )}
      </div>
    </div>
  );
};
