import React, { FC, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { ContainerOperacional } from '../../components/ComponentOperacional';
import { ContainerLogistico } from '../../components/ComponentLogistico';
import { ContainerGerencial } from '../../components/ComponentGerencial';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

export const ParametrosSistema: FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.PARAMETRO.SISTEMA] }], 'editar');
  }, [ADMIN, rolePermission]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Sistema</h2>
          <h6 className="subtitulo">{'Módulo Gerenciador -> Parâmetros -> Sistema'}</h6>
        </div>
        <div className="painel">
          <Tabs defaultActiveKey="operacional" id="uncontrolled-tab-example">
            <Tab eventKey="operacional" title="Operacional">
              <div className="container" style={{ marginTop: 12 }}>
                <ContainerOperacional canEdit={canEdit} />
              </div>
            </Tab>
            <Tab eventKey="logistico" title="Logístico">
              <div className="container" style={{ marginTop: 12 }}>
                <ContainerLogistico canEdit={canEdit} />
              </div>
            </Tab>
            <Tab eventKey="gerencial" title="Gerencial">
              <div className="container" style={{ marginTop: 12 }}>
                <ContainerGerencial canEdit={canEdit} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
