/* eslint-disable */
import '../GridEditavelTipoComunicacao/styles.css';
import { useHistory } from 'react-router-dom';
import { ImCancelCircle } from 'react-icons/im';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useToast } from '../../hooks/Toast';
import api from '../../config/axiosMaquina';
import { ComunicacaoType } from '../../api/model/Veiculos/ComunicacaoType';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoPencilSharp } from 'react-icons/io5';
import { ButtonTooltip } from '../../components/ButtonTooltipo';

type LineProps = {
  tiposComunicacao: ComunicacaoType;
  setOpenModal: (openModal: boolean) => void;
  setCloseModal: (closeModal: boolean) => void;
  setIdDelete: (idDelete: number) => void;
  setIdEdit: (idEdit: number) => void;
  idAtual: number;
  canEdit?: boolean;
  canDelete?: boolean;
};

export const ComunicacaoRow = ({
  tiposComunicacao,
  setOpenModal,
  setCloseModal,
  setIdDelete,
  setIdEdit,
  idAtual,
  canEdit,
  canDelete,
}: LineProps) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const [model, setModel] = useState<ComunicacaoType>(tiposComunicacao);
  const { addToast } = useToast();
  const [nomeOriginal, setNomeOriginal] = useState(tiposComunicacao.nome);
  const [descricaoOriginal, setDescricaoOriginal] = useState(tiposComunicacao.descricao);

  const handleEdit = (id: number) => {
    setDisabled(false);
    setIdEdit(id);
  };

  const handleCancel = () => {
    setDisabled(true);
    setIdEdit(0);
  };

  useEffect(() => {
    setModel(tiposComunicacao);
    setNomeOriginal(tiposComunicacao.nome);
    setDescricaoOriginal(tiposComunicacao.descricao);
  }, [tiposComunicacao, disabled, idAtual]);

  async function handleSubmit() {
    console.log(model.nome, nomeOriginal);
    if (
      model.nome.trim() != '' &&
      (model.nome != nomeOriginal || model.descricao != descricaoOriginal)
    ) {
      try {
        const data = {
          descricao: model.descricao,
          id: model.id,
          nome: model.nome,
          ordem: model.ordem,
          statusAtivo: model.statusAtivo,
        };
        await api.put(`/tipo-comunicacao/atualizar?idTipoComunicao=${model.id}`, data);
        addToast({
          title: 'Sucesso!',
          description: 'Tipo Comunicação atualizado com sucesso!',
          type: 'success',
        });
        setDisabled(true);
        setTimeout(function () {
          window.location.reload();
        }, 2000);
      } catch (err: any) {
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao editar Tipo Comunicação',
          type: 'error',
        });
      }
    } else {
      addToast({
        title: 'Info',
        description: 'Para realizar a edição, atualize o nome e/ou descrição.',
        type: 'info',
      });
    }
  }

  return (
    <div
      className="lineGridTipoComunicacao2"
      style={{
        filter: idAtual != model.id ? '' : 'drop-shadow(0 0 0.5rem #d8d8d8)',
      }}
    >
      <input
        disabled={idAtual != model.id}
        value={model.nome}
        maxLength={64}
        onChange={(e: any) => {
          setModel({
            ...model,
            nome: e.target.value,
          });
        }}
        type="text"
      />
      <input
        disabled={idAtual != model.id}
        value={model.descricao}
        maxLength={64}
        onChange={(e: any) => {
          setModel({
            ...model,
            descricao: e.target.value,
          });
        }}
        type="text"
      />
      {!disabled && idAtual === model.id && idAtual != 0 ? (
        <ButtonTooltip onClick={handleCancel} type="Cancelar" />
      ) : (
        <ButtonTooltip
          onClick={() => {
            if (canEdit) {
              handleEdit(model.id);
            } else {
              history.push('/acessonegado');
            }
          }}
          type="Editar"
        />
      )}
      {!disabled && idAtual === model.id && idAtual != 0 ? (
        <ButtonTooltip onClick={handleSubmit} type="Enviar" />
      ) : (
        <ButtonTooltip
          onClick={() => {
            if (canDelete) {
              setOpenModal(true);
              if (model.id != undefined) {
                setIdDelete(model.id);
              }
            } else {
              history.push('/acessonegado');
            }
          }}
          type="Excluir"
        />
      )}
    </div>
  );
};
