import './style.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as SucessIcon } from '../../assets/success.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ReactComponent as DangerIcon } from '../../assets/danger.svg';
import { obterDataHora } from '../../util/date';

type Travel = {
  dataChegadaPlanejada: string;
  dataReplanejada: string;
  distanciaProxPonto: number;
  dtHrCalculadaChegadaPonto: string;
  dtHrCalculadaProxPonto: string;
  dtHrChegadaPonto: string;
  dtHrInicioRealSM: string;
  dtHrInicioSM: string;
  dtHrPlanejadaViagemPonto: string;
  dtHrUltimoEvento: string;
  enderecoPonto: string;
  hintPonto: string;
  idLsc: number;
  idPlanoViagem: number;
  idPontoPlanejamentoSmo: number;
  idPontoSmo: number;
  idPontoViagem: number;
  idPontoViagemReplanejada: number;
  idSm: number;
  latitudePonto: number;
  longitudePonto: number;
  nomePonto: string;
  numSeqPonto: number;
  origemDestinoPonto: string;
  percPercorridoPonto: number;
  previsao: string;
  previsaoComparadas: string;
  remessa: any;
  statusConcluidoPonto: boolean;
  statusPonto: string;
  tempoProxPonto: number;
};

type Point = {
  ponto: Travel;
  index: number;
};

export const StepperPointTravelProgress = ({ ponto, index }: Point) => {
  const getHintPonto = () => {
    if (ponto.origemDestinoPonto === 'O') {
      return (
        <div className="d-flex flex-column align-items-start">
          <span>{ponto.nomePonto}</span>
          <span>Previsão de Saída: {obterDataHora(ponto.dtHrInicioSM, true)}</span>
          {ponto.dtHrInicioRealSM !== null && (
            <span>Saída: {obterDataHora(ponto.dtHrInicioRealSM, true)}</span>
          )}
        </div>
      );
    }

    return (
      <div className="d-flex flex-column align-items-start">
        <span>{ponto.nomePonto} </span>
        <span>
          {ponto.dtHrPlanejadaViagemPonto === null
            ? ''
            : `Agendada: ${obterDataHora(ponto.dtHrPlanejadaViagemPonto, true)} `}
        </span>
        <span>
          {ponto.dtHrInicioRealSM !== null &&
            (ponto.dtHrCalculadaProxPonto === null
              ? ''
              : `Previsão de Chegada: ${obterDataHora(ponto.dtHrCalculadaProxPonto, true)}`)}
        </span>
        <span>
          {ponto.dtHrChegadaPonto === null
            ? ''
            : `Chegada: ${obterDataHora(ponto.dtHrChegadaPonto, true)}`}
        </span>
      </div>
    );
  };

  const iconProgresstatus = () => {
    switch (ponto.statusPonto) {
      case 'A':
        return <DangerIcon className="icon" />;
      case 'B':
        return <SucessIcon className="icon" />;
      case 'D':
        return <WarningIcon className="icon" />;
      default:
        return null;
    }
  };

  return (
    <OverlayTrigger
      key={Number(ponto.idPontoViagem)}
      overlay={
        <Tooltip className="tooltipTravel" id={ponto.idPontoViagem.toString()}>
          {getHintPonto()}
        </Tooltip>
      }
    >
      {ponto.percPercorridoPonto === 0 ? (
        <div
          className="progresso-ponto"
          style={{
            backgroundColor: '#fff',
            border: '2px dashed #bbb',
            opacity: 0.3,
          }}
        >
          <span>{index + 1}</span>
          <div className="progress-name-container">
            <span>{ponto.nomePonto}</span>
          </div>
        </div>
      ) : (
        <div className="progresso-ponto">
          {iconProgresstatus()}
          <div className="progress-name-container">
            <span style={{ opacity: 0.5 }}>{ponto.nomePonto}</span>
          </div>
        </div>
      )}
    </OverlayTrigger>
  );
};
