/* eslint-disable */
import Cookies from 'universal-cookie';
import pako from 'pako';
import api from '../config/axiosMaquina';

const cookies = new Cookies();

const decodeCookie = (data: string[]) => {
  let decoded = {};
  let atobText = '';

  data.forEach((d: string) => {
    atobText += d;
    return atobText;
  });

  try {
    const charData = atob(atobText)
      .split('')
      .map((x) => x.charCodeAt(0));
    const binData = new Uint8Array(charData);
    const decompressedData = pako.inflate(binData);

    decoded = JSON.parse(
      String.fromCharCode.apply(null, Array.from(new Uint16Array(decompressedData)))
    );

    return decoded;
  } catch (err) {
    return decoded;
  }
};

export const getCookiePermissoesData = (): any => {
  const cook: any[] = [];
  for (let i = 1; i <= 3; i += 1) {
    if (cookies.get(`permissao${i}`)) {
      cook.push(cookies.get(`permissao${i}`));
    }
  }

  if (cook.length) {
    return decodeCookie(cook);
  }
  return '';
};

const encodeCookie = (data: any) => {
  // pako package will compress the data in order to not exceed the header
  const compressedData = pako.deflate(JSON.stringify(data));
  const encodeText = btoa(
    String.fromCharCode.apply(null, Array.from(new Uint16Array(compressedData)))
  );
  const chunkSize = 8000;
  const size = Math.ceil(encodeText.length / chunkSize);
  for (let i = 1; i <= 5; i += 1) {
    if (cookies.get(`permissao${i}`)) {
      cookies.remove(`permissao${i}`);
    }
  }
  for (let i = 1; i <= size; i += 1) {
    const last = i * chunkSize;
    const first = last - chunkSize;
    cookies.set(`permissao${i}`, encodeText.slice(first, last), {
      path: '/',
    });
  }
};

export const permissionamento = async (idUsuario: number) => {
  const response = await api.get(`/menu-perfil/list-funcionalidades/${idUsuario}`);

  let permissoes = {};
  if (response.data && response.data[0]?.listaMenuPerfilFuncionalidade) {
    const lista = response.data[0].listaMenuPerfilFuncionalidade;
    for (let i = 0; i < lista.length; i += 1) {
      permissoes = {
        ...permissoes,

        [lista[i].idMenuGrupo]: {
          idFuncionalidade: lista[i].id,
          idMenuGrupo: lista[i].idMenuGrupo,
          inserir: lista[i].insert,
          editar: lista[i].update,
          excluir: lista[i].delete,
          consultar: lista[i].consulta,
        },
      };
    }
  }

  encodeCookie(permissoes);
  return permissoes;
};
