import { format } from 'date-fns';
import { GridViolacao } from '..';

export const generateExcelData = (gridData: GridViolacao[], controlType: number) => {
  if (controlType === 0) {
    return gridData.map((item) => ({
      data: item.data ? format(new Date(item.data), 'dd/MM HH:mm') : '',
      placa: item.placa,
      temperatura: item.temperatura === null ? '' : ` ${item.temperatura} °C`,
      temperaturaMinMax: `${item.temperaturaMinima} °C / ${item.temperaturaMaxima} °C`,
      localizacao: item.localizacao,
      violacao: item.tipoViolacao,
    }));
  }
  if (controlType === 1) {
    return gridData.map((item) => ({
      data: item.data ? format(new Date(item.data), 'dd/MM HH:mm') : '',
      placa: item.placa,
      velocidade: item.velocidade === null ? 'Velocidade Padrão' : `${item.velocidade} km/h`,
      velocidadeMaxima:
        item.velocidadeMaxima === null ? 'Velocidade Máx' : `${item.velocidadeMaxima} km/h`,
      localizacao: item.localizacao,
      violacao: item.tipoViolacao,
    }));
  }
  return gridData.map((item) => ({
    data: item.data ? format(new Date(item.data), 'dd/MM HH:mm') : '',
    placa: item.placa,
    temperatura: item.temperatura === null ? '' : ` ${item.temperatura} °C`,
    temperaturaMinMax: `${item.temperaturaMinima} °C / ${item.temperaturaMaxima} °C`,
    velocidade: item.velocidade === null ? 'Velocidade Padrão' : `${item.velocidade} km/h`,
    velocidadeMaxima:
      item.velocidadeMaxima === null ? 'Velocidade Máx' : `${item.velocidadeMaxima} km/h`,
    localizacao: item.localizacao,
    violacao: item.tipoViolacao,
  }));
};
