/* eslint-disable */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Form, Col, Row, Alert, Navbar, FormControl } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import { ParadaType } from '../../api/model/ParadaType';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import './styles.css';
import { maskCep, soNumeros } from '../../util/Validacoes/Validacoes';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { validaNumero } from '../../util/Validacoes/Validacoes';
import { useToast } from '../../hooks/Toast';
import { Spinner } from 'react-bootstrap';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

const CadastroParada = () => {
  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const { addToast } = useToast();
  const [sequencia, setSequencia] = useState('');
  const [motivo, setMotivo] = useState('');
  const [local, setLocal] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [idCodigoIbge, setIdCodigoIbge] = useState('');
  const [cep, setCep] = useState('');
  const [viaCepUf, setViaCepUf] = useState('');
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [error, setError] = useState(false);

  const { id }: any = useParams();

  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [paradas, setParadas] = useState<ParadaType[]>([]);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  useEffect(() => {
    rolePermission([{TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.ROTA]}], 'inserir');
  }, [TRANSPORTADOR, rolePermission]);

  useEffect(() => {
    const loadEstadosPorPais = async () => {
      try {
        const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/1`);
        setUfs(response.data);
      } catch (err: any) {
        console.log(err.response);
      }
    };
    loadEstadosPorPais();
  }, []);

  const loadMunicipiosPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipios(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/rota/parada-por-rota?idRota=${id}`);
      setParadas(response.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    const buscarCep = () => {
      if (cep.length < 9) {
        setCidade('');
        setLogradouro('');
        setBairro('');
        setIdCodigoIbge('');
        return;
      }
      setError(false);
      fetch(`https://viacep.com.br/ws/${cep}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }
          setLogradouro(data.logradouro);
          setBairro(data.bairro);
          setViaCepCidade(data.localidade);
          setViaCepUf(data.uf);
          const ufId = ufs
            .filter((uf) => uf.sigla === data.uf)
            .map((filteredUfId) => filteredUfId.id);
          setIdCodigoIbge(ufId.toString());
          setCidade(data.localidade);
          const codIbgeFloat = data.ibge / 100000;
          const codIbgeFormatado = Math.trunc(codIbgeFloat);
          setViaCepIbgeCod(codIbgeFormatado);
          let listaMunPorEstado = municipios
            .filter((municipio) => municipio.idCodigoIbge === viaCepIbgeCod)
            .map((filteredMunicipio) => filteredMunicipio.nomeMunicipio);
        })
        .catch((erro) => {
          console.log('error', erro);
          setError(true);
        });
    };
    buscarCep();
  }, [cep]);

  useEffect(() => {
    const validaSequencia = () => {
      if (paradas.length) {
        const listaSequencia = paradas.map((filteredSequencia) => filteredSequencia.sequencia);

        var maior = 0;
        for (var i = 0; i < listaSequencia.length; i++) {
          if (listaSequencia[i] > maior) {
            maior = listaSequencia[i];
          }
        }
        var proxNumero = maior + 1;
        var proxNumeroStr = proxNumero.toString();
        setSequencia(proxNumeroStr);
      } else {
        setSequencia('1');
      }
    };
    validaSequencia();
  }, [paradas]);
  const handleRegister = async (event: any) => {
    event.preventDefault();
    if (
      !motivo ||
      !local ||
      !numero ||
      !cep ||
      !logradouro ||
      !bairro ||
      !cidade ||
      !idCodigoIbge
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const data = {
      sequencia: sequencia,
      motivo: motivo,
      local: local,
      logradouro: logradouro,
      rotaId: id,
      numero: numero,
      complemento: complemento,
      bairro: bairro,
      cidade: cidade,
      idCodigoIbge: idCodigoIbge,
      ativo: true,
      cep: cep,
    };
    try {
      await axios.post('/parada', data);

      //alert('Cadastro efetuada com sucesso.');
      setIsDialogVisibleCadEfetuado(true);
    } catch {
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar.',
        type: 'error',
      });

      return;
    }
  };

  function reloadPage() {
    window.location.reload();
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header="Parada foi cadastrada com sucesso!"
        footer={
          <>
            <Button label="OK" onClick={() => reloadPage()} />
          </>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleCadEfetuado(false)}
      />
      <div className="card" style={{ backgroundColor: '#f2f2f2', borderColor: '#f2f2f2' }}>
        <Container fluid style={{ marginTop: '0px' }}>
          <Navbar style={{ width: '100%', marginTop: '10px' }}>
            <h2 className="titulo">Parada</h2>
            <h6 className="subtitulo">{'Cadastro > Parada'}</h6>
          </Navbar>
          {sequencia === '' ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <Form noValidate validated={isFormInvalid} onSubmit={handleRegister}>
              <Form.Row>
                <Form.Group as={Col} className="mt-4 ml-3">
                  <Form.Label className="required">Sequencia:</Form.Label>

                  <Form.Control
                    value={sequencia}
                    key={id}
                    // onChange={(event) => validaSequencia(event.target.value)}
                    id="sequencia"
                    name="sequencia"
                    placeholder=""
                    required
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col} className="mt-4">
                  <Form.Label className="required">Motivo:</Form.Label>

                  <Form.Control
                    value={motivo}
                    onChange={(event) => {
                      setMotivo(event.target.value);
                    }}
                    id="motivo"
                    name="motivo"
                    placeholder=""
                    required
                    as="select"
                    style={{ width: '98%' }}
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="1">Alimentação</option>
                    <option value="2">Descanso</option>
                    <option value="3">Entrega</option>
                    <option value="4">Coleta</option>
                    <option value="5">Fiscalização</option>
                    <option value="9">Outros</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Group as={Col}>
                <Form.Label className="required">Local:</Form.Label>

                <Form.Control
                  value={local}
                  onChange={(event) => {
                    setLocal(event.target.value);
                  }}
                  id="local"
                  name="local"
                  placeholder=""
                  required
                  style={{ width: '100.5%' }}
                  maxLength={64}
                />
              </Form.Group>

              <Form.Row>
                <Form.Group as={Col} className=" ml-3">
                  <Form.Label className="required">CEP:</Form.Label>

                  <Form.Control
                    value={cep}
                    onChange={(event: any) => {
                      setCep(maskCep(soNumeros(event.target.value)));
                    }}
                    id="cep"
                    name="cep"
                    placeholder=""
                    required
                    maxLength={9}
                    minLength={9}
                    style={{ width: '100%' }}
                  />
                  {error && (
                    <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>
                  )}
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label className="required">Logradouro:</Form.Label>

                  <Form.Control
                    value={logradouro}
                    onChange={(event: any) => {
                      setLogradouro(event.target.value);
                    }}
                    id="logradouro"
                    name="logradouro"
                    required
                    style={{ width: '98%' }}
                    maxLength={64}
                  ></Form.Control>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} className=" ml-3">
                  <Form.Label className="required">Número:</Form.Label>

                  <Form.Control
                    value={numero}
                    onChange={(e) => {
                      if (validaNumero(e.target.value) || e.target.value.length === 0) {
                        setNumero(e.target.value);
                      } else {
                        return;
                      }
                    }}
                    id="numero"
                    name="numero"
                    placeholder=""
                    required
                    style={{ width: '20%' }}
                    maxLength={4}
                  />
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label>Complemento:</Form.Label>

                  <Form.Control
                    value={complemento}
                    onChange={(event) => {
                      setComplemento(event.target.value);
                    }}
                    id="complemento"
                    name="complemento"
                    placeholder=""
                    style={{ width: '98%' }}
                    maxLength={64}
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group as={Col}>
                <Form.Label className="required">Bairro:</Form.Label>

                <Form.Control
                  value={bairro}
                  onChange={(event) => {
                    setBairro(event.target.value);
                  }}
                  id="bairro"
                  name="bairro"
                  placeholder=""
                  required
                  style={{ width: '50%' }}
                  maxLength={64}
                />
              </Form.Group>
              <Form.Row>
                <Form.Group as={Col} className="ml-3">
                  <Form.Label className="required">UF:</Form.Label>

                  <Form.Control
                    value={idCodigoIbge}
                    onChange={(event: any) => {
                      setIdCodigoIbge(event.target.value);
                      loadMunicipiosPorEstado(event.target.value);
                    }}
                    id="idCodigoIbge"
                    name="idCodigoIbge"
                    required
                    as="select"
                    style={{ width: '40%' }}
                  >
                    <option value={0}></option>
                    {ufs.map((uf) => (
                      <option value={uf.id} key={uf.id}>
                        {uf.sigla}
                      </option>
                    ))}
                    <option value={idCodigoIbge} key={idCodigoIbge}>
                      {viaCepUf}
                    </option>
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col}>
                  <Form.Label className="required">Municipio:</Form.Label>

                  <Form.Control
                    value={cidade}
                    onChange={(event: any) => {
                      setCidade(event.target.value);
                    }}
                    id="cidade"
                    name="cidade"
                    placeholder=""
                    required
                    as="select"
                    disabled={idCodigoIbge ? false : true}
                    style={{ width: '98%' }}
                  >
                    <option value={0}>Selecione um municipio</option>

                    {viaCepCidade && (
                      <option key={cidade} value={cidade}>
                        {viaCepCidade}
                      </option>
                    )}

                    {viaCepCidade &&
                      municipios
                        .filter((municipio) => municipio.idCodigoIbge == viaCepIbgeCod)
                        .map((filteredMunicipio) => (
                          <option
                            key={filteredMunicipio.nomeMunicipio}
                            value={filteredMunicipio.nomeMunicipio}
                          >
                            {filteredMunicipio.nomeMunicipio}
                          </option>
                        ))}

                    {municipios.map((filteredMunicipio) => (
                      <option
                        key={filteredMunicipio.nomeMunicipio}
                        value={filteredMunicipio.nomeMunicipio}
                      >
                        {filteredMunicipio.nomeMunicipio}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>
              <ButtonsForm canInsert={canInsert} />
            </Form>
          )}
        </Container>
      </div>
    </div>
  );
};

export default CadastroParada;
