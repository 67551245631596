/* eslint-disable */
import { memo, useState } from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  details: any;
};

const ModalVelocidadeVeiculo = ({ details }: Props) => {
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        type="button"
        style={{
          background: '#ffda53',
          borderColor: 'none',
          border: 'none',
          padding: '10px',
          fontSize: 13,
          borderRadius: 5
        }}
        onClick={() => {
          handleShow();
        }}
      >
        Detalhes
      </button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header style={{ alignSelf: 'center' }}>
          <h2 className="titulo">Detalhes</h2>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center', width: '90%' }}>
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Tipo</th>
                <th>Log</th>
              </tr>
            </thead>
            <tbody>
              {details.map((detail: any) => {
                return (
                  <tr key={detail.ixl_tx_log}>
                    <td>{detail.ixld_tx_type}</td>
                    <td>{detail.ixld_tx_log}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ModalVelocidadeVeiculo);
