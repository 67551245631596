/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';
import axios from '../../config/axiosMaquina';
import { VeiculoTypeGrid } from '../../Types/Veiculos/VeiculoTypeGrid';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { getCookieSessionData } from '../../services/cookieService';
import ModalVelocidadeVeiculo from './modal';
import Pagination from '../Home/Pagination/Pagination';
import UploadT from './upload';
import moment from 'moment';

const VelocidadePorVeiculo: React.FC = () => {
  const [document, setDocument] = useState('');
  const [lastPage, setLastPage] = useState(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [refresh, setRefresh] = useState(0);
  const [refreshitensPerPage, setRefreshitensPerPage] = useState(false);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [veiculos, setVeiculos] = useState<VeiculoTypeGrid[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [itensPerPage, setItensPerPage] = useState('10');

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const [refreshList, setRefreshList] = useState<boolean>(false);

  useEffect(() => setSearchTerm(null), []);

  useEffect(() => {
    if (!refreshList) return;
    loadDatas({
      page: currentPage,
      clientId: clienteId,
      page_size: itensPerPage,
    });
  }, [refreshList]);

  useEffect(() => {
    if (!refresh) return;

    loadDatas({
      page: refresh,
      clientId: clienteId,
      page_size: itensPerPage,
    });
  }, [refresh]);

  useEffect(() => {
    if (!refreshitensPerPage) return;

    loadDatas({
      page: currentPage,
      clientId: clienteId,
      page_size: itensPerPage,
    });

    setRefreshitensPerPage(false);
  }, [refreshitensPerPage]);

  const loadVeiculos = async ({
    page_size,
    placa,
    page,
    clientId,
  }: {
    page_size: string;
    placa?: string;
    page?: string;
    clientId: string;
  }) => {
    setLoading(true);
    setVeiculos([]);
    if (!clientId) {
      return;
    }
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/get-xml-import-log/`, {
          params: {
            page,
            page_size,
            idCliente: clientId,
          },
        })
        .then((res) => {
          setTotalItems(res.data.count);

          setLastPage(Math.ceil(res.data.count / Number(page_size)));
          setVeiculos(res.data.results);
          setLoading(false);
          setRefreshList(false);
        })
        .catch(() => {
          setRefreshList(false);
          setVeiculos([]);
          setLoading(false);
        });
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  };

  const loadDatas = ({
    page_size,
    clientId,
    page,
    placa,
  }: {
    page_size: string;
    clientId: any;
    page: any;
    placa?: string;
  }) => {
    loadVeiculos({ page_size, clientId, page, placa });
  };

  useEffect(() => {
    if (clienteId) {
      loadDatas({ page_size: itensPerPage, clientId: clienteId, page: currentPage });
    }
  }, [clienteId]);

  useEffect(() => {
    if (searchTerm === null) return;

    const debounceTimeout = setTimeout(() => {
      const fetchData = async () => {
        if (searchTerm?.trim() === '') {
          loadDatas({
            page: currentPage,
            clientId: clienteId,
            page_size: itensPerPage,
          });
          return;
        }
        loadDatas({
          page: currentPage,
          clientId: clienteId,
          page_size: itensPerPage,
          placa: searchTerm,
        });
      };

      fetchData();
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação de Monitoramento (XML)</h2>
          <h6 className="subtitulo">
            {'Operacional > Solicitações > Solicitação de Monitoramento (XML)'}
          </h6>
        </div>

        <UploadT document={document} setDocument={setDocument} setRefreshList={setRefreshList}/>

        <h2 className="titulo">Histórico</h2>

        <div className="content-container" style={{ width: '100%' }}>
          <div className="painel">
            <div className="table-responsive" style={{ flex: 1 }}>
              {loading ? (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{
                    display: 'flex',
                    marginLeft: '47.5%',
                    marginTop: '5%',
                    marginBottom: '5%',
                  }}
                />
              ) : (
                <>
                  {veiculos.length > 0 && (
                    <table className="table">
                      <thead className="thead">
                        <tr>
                          <th>SM</th>
                          <th>Data de envio</th>
                          <th>Status</th>
                          <th>Tipo de envio</th>
                          <th> </th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody>
                        {veiculos.map((veiculo: any) => {
                          return (
                            <tr key={veiculo.ixl_cd_id}>
                              <td>{veiculo.som_cd_id}</td>
                              <td>{moment(veiculo.ixl_dt_date).format('DD/MM/YYYY HH:mm')}</td>
                              <td>{veiculo.ixl_tx_status}</td>
                              <td>{veiculo.ixl_tx_origin}</td>
                              <td>
                                <div className="icons">
                                  <ModalVelocidadeVeiculo details={veiculo.details} />
                                </div>
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    window.location.href = `${process.env.REACT_APP_API_URL}/download-xml/?uuid=${veiculo.ixl_tx_uuid}`;
                                  }}
                                  type="button"
                                  style={{
                                    background: '#ffda53',
                                    borderColor: 'none',
                                    border: 'none',
                                    padding: '10px',
                                    width: 150,
                                    fontSize: 13,
                                    borderRadius: 5,
                                  }}
                                >
                                  Baixar (XML)
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  )}
                </>
              )}
            </div>
          </div>

          {veiculos.length > 0 && (
            <Pagination
              lastPage={lastPage}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={totalItems}
              paginate={paginate}
              currentPage={String(currentPage)}
              setRefresh={setRefresh}
              setData={setVeiculos}
              setRefreshitensPerPage={setRefreshitensPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VelocidadePorVeiculo;
