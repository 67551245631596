import { useState, useEffect, ChangeEvent, MouseEvent, FC } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import './styles.css';

type Props = {
  canEdit?: boolean;
};

export const ContainerLogistico: FC<Props> = ({ canEdit }) => {
  const history = useHistory();
  const [idChaveApi, setIdChaveApi] = useState('');
  const [raioDeAproximacaoVeiculosEmMetros, setRaioDeAproximacaoVeiculosEmMetros] = useState(0);
  const [tempoAtualizacaoDadosEstaticosEmMinutos, setTempoAtualizacaoDadosEstaticosEmMinutos] =
    useState(0);
  const [tempoAtualizacaoMapaEmMinutos, setTempoAtualizacaoMapaEmMinutos] = useState(0);
  const [tempoDeToleranciaAtrasado, setTempoDeToleranciaAtrasado] = useState(0);
  const [tempoDeToleranciaOnTime, setTempoDeToleranciaOnTime] = useState(0);
  const { addToast } = useToast();

  const pegarLogistico = async () => {
    const response = await axios.get('/parametro-logistico');
    const lg = response.data;

    setIdChaveApi(lg.idChaveApi);
    setRaioDeAproximacaoVeiculosEmMetros(lg.raioDeAproximacaoVeiculosEmMetros);
    setTempoAtualizacaoDadosEstaticosEmMinutos(lg.tempoAtualizacaoDadosEstaticosEmMinutos);
    setTempoAtualizacaoMapaEmMinutos(lg.tempoAtualizacaoMapaEmMinutos);
    setTempoDeToleranciaAtrasado(lg.tempoDeToleranciaAtrasado);
    setTempoDeToleranciaOnTime(lg.tempoDeToleranciaOnTime);
  };

  useEffect(() => {
    pegarLogistico();
  }, []);

  const atualizarLogistico = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const data = {
      idChaveApi,
      tempoAtualizacaoDadosEstaticosEmMinutos,
      tempoAtualizacaoMapaEmMinutos,
      raioDeAproximacaoVeiculosEmMetros,
      tempoDeToleranciaOnTime,
      tempoDeToleranciaAtrasado,
    };
    try {
      await axios.post(`/parametro-logistico/cadastrar`, data);
      addToast({
        title: 'Sucesso!',
        description: 'Os parametros foram atualizados com sucesso!',
        type: 'success',
      });
      pegarLogistico();
    } catch (error: any) {
      addToast({
        title: 'Erro',
        description: 'Houve algum problema ao atualizar os parametros.',
        type: 'error',
      });
    }
  };
  return (
    <>
      <Form>
        <Form.Group as={Row}>
          <Form.Label>ID – Integração: </Form.Label>
          <Form.Control
            value={idChaveApi}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setIdChaveApi(e.target.value)}
            id="idGoogle"
            name="idGoogle"
          />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label>Tempo de Requisições API DistanceMatrix: </Form.Label>
          <Form.Control
            type="number"
            value={tempoAtualizacaoDadosEstaticosEmMinutos}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTempoAtualizacaoDadosEstaticosEmMinutos(parseInt(e.target.value, 10))
            }
            id="tempoAtualizacaoDadosEstaticosEmMinutos"
            name="tempoAtualizacaoDadosEstaticosEmMinutos"
          />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label>Tempo de Atualização dos Mapas (Posições): </Form.Label>
          <Form.Control
            type="number"
            value={tempoAtualizacaoMapaEmMinutos}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTempoAtualizacaoMapaEmMinutos(parseInt(e.target.value, 10))
            }
            id="tempoAtualizacaoMapaEmMinutos"
            name="tempoAtualizacaoMapaEmMinutos"
          />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label>Raio de aproximação de veículos (em metros) </Form.Label>
          <Form.Control
            type="number"
            value={raioDeAproximacaoVeiculosEmMetros}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setRaioDeAproximacaoVeiculosEmMetros(parseInt(e.target.value, 10))
            }
            id="raioDeAproximacaoVeiculosEmMetros"
            name="raioDeAproximacaoVeiculosEmMetros"
          />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label>Tempo de tolerância on-Time: </Form.Label>
          <Form.Control
            type="number"
            value={tempoDeToleranciaOnTime}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTempoDeToleranciaOnTime(parseInt(e.target.value, 10))
            }
            id="tempoDeToleranciaOnTime"
            name="tempoDeToleranciaOnTime"
          />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label>Tempo de tolerância Atrasado: </Form.Label>
          <Form.Control
            type="number"
            value={tempoDeToleranciaAtrasado}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTempoDeToleranciaAtrasado(parseInt(e.target.value, 10))
            }
            id="tempoDeToleranciaAtrasado"
            name="tempoDeToleranciaAtrasado"
          />
        </Form.Group>
      </Form>

      <Form.Row className="container-buttons justify-content-end">
        <Form.Group as={Col} md={2}>
          {canEdit ? (
            <Button onClick={atualizarLogistico} type="submit" className="btn-enviar">
              Atualizar
            </Button>
          ) : (
            <Button
              onClick={() => history.push('/acessonegado')}
              type="button"
              className="btn-enviar"
            >
              Atualizar
            </Button>
          )}
        </Form.Group>
      </Form.Row>
    </>
  );
};
