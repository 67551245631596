export const TIPO_FATURAMENTO_VEICULO = [
  {
    value: 'NAO INFORMADO',
    label: 'Não informado',
  },
  {
    value: 'MENSALISTA',
    label: 'Mensalista',
  },

  {
    value: 'VIAGEM AVULSA',
    label: 'Viagem avulsa',
  },
  {
    value: 'NAO RASTREADO',
    label: 'Não rastreado',
  },
  {
    value: 'DESATIVADO',
    label: 'Desativado',
  },
];
