/* eslint-disable */

import React, { useEffect } from 'react';

interface CnhType {
  dadosCNH: any;
  cnhErrors?: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Cnh({ dadosCNH, cnhErrors }: CnhType) {
  const handleValidacaoCPF = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 0: {
        return 'Cnh com dados';
      }
      case 1: {
        return 'Confirmado';
      }
      case 3: {
        return 'Cpf não pertence a cnh informada';
      }
      case 4: {
        return 'Cnh/Condutor não encontrado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoNumeroSeguranca = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Número de segurança: não possível validar';
      }
      case 0: {
        return 'Número de segurança da cnh divergente';
      }
      case 1: {
        return 'Número de segurança da cnh confirmado';
      }
      case 2: {
        return 'Existe uma cnh emitida mais recente do que a cnh que está sendo validada';
      }
      case 3: {
        return 'Cnh com data de validade vencida';
      }
      case 5: {
        return 'Cnh válida para identificação do condutor, porém vencida para condução de veículos';
      }
      case 6: {
        return 'Cnh válida para identificação do condutor e condução de veículos conforme termos da deliberação cotran nº 185 de 19/03/2020';
      }
      default: {
        return '';
      }
    }
  };

  const handleValidade = (n: string) => {
    switch (n) {
      case '1': {
        return 'Confirmado';
      }
      case '0': {
        return 'Divergente do informado';
      }
      case '-1': {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoUFCNH = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Pontuação não disponível';
      }
      case 0: {
        return 'Inválido';
      }
      case 1: {
        return 'Confirmado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleCategoria = (n: string) => {
    switch (n) {
      case '1': {
        return 'Confirmado';
      }
      case '0': {
        return 'Divergente do informado';
      }
      case '-1': {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  return (
    <>
      <ul style={{ listStyleType: 'none' }}>
        <>
          {cnhErrors && cnhErrors.length > 0 && (
            <>
              {cnhErrors.map((each: any) => {
                return (
                  <li style={{ padding: '5px', borderBottom: '1px solid #b5aeae' }}>
                    <p>{each}</p>
                  </li>
                );
              })}
            </>
          )}
        </>
      </ul>

      {cnhErrors.length === 0 && dadosCNH && (
        <>
          <div className="resumoCard">
            <table cellPadding="5">
              <tbody>
                <tr>
                  <td>
                    <table cellPadding="5">
                      <tbody>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>DADOS CNH:</span>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>CPF: </span>
                          </td>
                          <td>
                            {dadosCNH.cpf} ({handleValidacaoCPF(dadosCNH.validacaoCondutor)})
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Nome: </span>
                          </td>
                          <td>{dadosCNH.nome}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Nascimento: </span>
                          </td>
                          <td>{dadosCNH.dataNascimento}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Nome da mãe: </span>
                          </td>
                          <td>{dadosCNH.motherName}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Nome do pai: </span>
                          </td>
                          <td>{dadosCNH.fatherName}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>RG: </span>
                          </td>
                          <td>{dadosCNH.numeroRegistro}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Renach: </span>
                          </td>
                          <td>{dadosCNH.renachNumber}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Número do Espelho: </span>
                          </td>
                          <td>{dadosCNH.mirrorNumber}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                  <td>
                    <table cellPadding="5">
                      <tbody>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Número Registro (CNH): </span>
                          </td>
                          <td>{dadosCNH.numeroRegistro}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Número Segurança: </span>
                          </td>
                          <td>
                            {dadosCNH.numeroSeguranca} (
                            {handleValidacaoNumeroSeguranca(dadosCNH.validadaoNumeroSeguranca)})
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Data Primeira Habilitação: </span>
                          </td>
                          <td>{dadosCNH.firstLicenseDate}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Data Emissão Habilitação: </span>
                          </td>
                          <td>{dadosCNH.issuanceDate}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Data Validade: </span>
                          </td>
                          <td>
                            {dadosCNH.dataValidade} ({handleValidade(dadosCNH.validacaoValidade)})
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>UF CNH: </span>
                          </td>
                          <td>
                            {dadosCNH.ufCnh} ({handleValidacaoUFCNH(dadosCNH.validacaoUfCnh)})
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Categoria: </span>
                          </td>
                          <td>
                            {dadosCNH.categoriaAtual} (
                            {handleCategoria(dadosCNH.validacaoCategoria)})
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            <br />

            <table cellPadding="5">
              <tbody>
                <tr>
                  <td>
                    <table cellPadding="5">
                      <tbody>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>RESTRIÇÕES:</span>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Portaria:</span>
                          </td>
                          <td>{dadosCNH.portaria}</td>
                        </tr>
                        <tr>
                          <td>
                            <span style={{ fontWeight: 'bold' }}>Impedimentos: </span>
                          </td>
                          <td>{dadosCNH.impedimento}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
}
