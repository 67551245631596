/* eslint-disable */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from '../../assets/logo_golden.jpg';
import { obterDataAtual } from '../../util/date';
import { FormataStringData2 } from '../../util/Validacoes/Validacoes';

const GeneratePDF = (dadosTabela: any, placaVeiculo: string | undefined, periodo: string) => {
  const doc = new jsPDF();

  const tableColumn = ['Referência', 'Tempo Parado', 'Tempo Viagem'];

  const tableRows: any = [];

  let end: boolean = false;

  // called date-fns to format the date on the dado
  // format(new Date(dado.updated_at), "yyyy-MM-dd")

  dadosTabela.forEach((value: any, index: any) => {
    for (let j = 0; j < value.length; j++) {
      const tableData = [value[j].dataTempoParada, value[j].tempoParado, value[j].tempoViagem];
      tableRows.push(tableData);
    }
    if (dadosTabela.length === index + 1) end = true;
    page();
    tableRows.length = 0;
  });

  function page() {
    // @ts-ignore
    doc.autoTable(tableColumn, tableRows, {
      startY: 50,
      theme: 'plain',

      styles: {
        overflow: 'linebreak',
        fontSize: 12,
        valign: 'middle',
      },
      columnStyles: {
        0: { valign: 'top', halign: 'left' },
      },
      bodyStyles: {
        lineWidth: 0.2,
        lineColor: [204, 204, 204],
      },
      headerStyles: {
        lineWidth: 0.2,
        lineColor: [204, 204, 204],
      },
    });

    doc.addImage(Logo, 'jpg', 10, 10, 80, 20);

    doc.setFontSize(10);
    doc.text(`Página ${doc.internal.getNumberOfPages()}/${dadosTabela.length}`, 260, 30);
    doc.text(obterDataAtual(), 180, 35);
    doc.line(10, 38, 200, 38);
    doc.setFontSize(15);
    doc.text('TEMPO PARADO', 80, 35);

    doc.setFontSize(10);
    doc.text(`VEICULO: ${placaVeiculo}`, 15, 45);
    doc.setFontSize(10);
    doc.text(periodo, 142, 45);

    if (!end) doc.addPage();
  }
  const date = new Date().toLocaleDateString('pt-BR').split(' ');
  const dateStr = date[0];

  doc.save(`tempo_parado_${dateStr}.pdf`);
};

export default GeneratePDF;
