import styled from 'styled-components';

export const PrimaryButton = styled.button`
  background: #ffda53 0% 0% no-repeat padding-box;
  border: 2px solid #ffda53;
  color: #0b141f;
  border-radius: 5px;
  font-size: 16px;
  height: ${(props: StyleProps) =>
    props.size === 'sm' ? '40px' : props.size === 'lg' ? '50px' : '50px'};
  width: ${(props: StyleProps) =>
    props.size === 'sm' ? '90px' : props.size === 'lg' ? '170px' : '170px'};
  margin: 0px 10px;

  &:hover {
    filter: brightness(96%);
  }
`;

type StyleProps = {
  size?: 'sm' | 'lg';
};

export const SecundaryButton = styled.button<StyleProps>`
  border: 2px solid #0b141f;
  background: #fff;
  color: #0b141f;
  border-radius: 5px;
  margin: 0px 10px;
  height: ${(props: StyleProps) =>
    props.size === 'sm' ? '40px' : props.size === 'lg' ? '50px' : '50px'};
  width: ${(props: StyleProps) =>
    props.size === 'sm' ? '90px' : props.size === 'lg' ? '170px' : '170px'};
  font-size: 16px;

  &:hover {
    filter: brightness(96%);
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;
