/* eslint-disable */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { Button, Form, Spinner } from 'react-bootstrap';
import { MultiSelect } from 'primereact/multiselect';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import { RiTruckFill } from 'react-icons/ri';
import { FaClipboardList, FaTemperatureHigh } from 'react-icons/fa';
import { IoMdSpeedometer } from 'react-icons/io';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Tela } from '../../components/Tela';
import { CardInformativo } from '../../components/CardInformativo';
import api from '../../config/axiosMaquina';
import { LatLng } from '../../components/PopUpMapa';
import { Chart } from './components/Chart/index.js';
import { getCookieSessionData } from '../../services/cookieService';
import { CardRow, Table, Select, FilterBtn, TableMapContainer } from './styles';
import { useToast } from '../../hooks/Toast';
import MainTable from './components/MainTable';
import { Mapa } from './components/Mapa';
import { handleListVehicleControl } from '../../services/accidentPrevention';
import ReactExport from 'react-data-export';
import { format, parse } from 'date-fns';

import {
  initialCenter,
  mainTableColumn,
  opcoesControles,
  renderScreenName,
  tiposControles,
} from './util';
import { toZoned } from '../../util/date';
import { renderTemperaturaMinMax, renderVelocidadeMax } from './utilsPrevecaoAcidentes';
import { ExportExcel } from './components/ExportExcel';

export type GridViolacao = {
  data: string;
  localizacao: string;
  placa: string;
  temperatura: number;
  qtdSmViagemCliente: number;
  latidude: string;
  longitude: string;
  temperaturaMaxima: number;
  temperaturaMinima: number;
  velocidade: number;
  velocidadeMaxima: number;
  tipoViolacao?: string;
  qtd?: number;
};

type CardDataType = {
  veiculos: number;
  violacaoVelocidade: number;
  velocidadeMedia: number;
  violacaoTemperatura: number;
  temperaturaMedia: number;
  velocidadeFrota: number;
  emViagem: number;
};

type ChartDataType = { date: number; value: number; bullet: boolean; tooltip: string }[];

const handleLast7Days = () => {
  const _startDate = moment().subtract(1, 'days');
  const _endDate = moment();
  return {
    startDate: _startDate.format('YYYY-MM-DD'),
    endDate: _endDate.format('YYYY-MM-DD'),
  };
};

const { startDate, endDate } = handleLast7Days();

export const PrevencaoAcidentes = () => {
  const { addToast } = useToast();
  const history = useHistory();
  const [veiculos, setVeiculos] = useState<any[]>([]);
  const [shouldGetServices, setShouldGetServices] = useState(true);
  const [excelData, setExcelData] = useState<GridViolacao[]>([]);
  const [selectedVeiculo, setSelectedVeiculo] = useState<number[] | string[]>([]);
  const [tipoControle, setTipoControle] = useState<number>(3);
  const [controlesContratados, setControlesContratados] = useState<number>(3);
  const [controle, setControle] = useState<number>(3);
  const [data, setData] = useState<GridViolacao[]>([]);
  const [mainData, setMainData] = useState<GridViolacao[]>([]);
  const [mainTable, setMainTable] = useState<string[]>([]);
  const [dataInicio, setDataInicio] = useState<string>(startDate);
  const [minDate, setMinDate] = useState<string>('');
  const [dataFim, setDataFim] = useState<string>(endDate);
  const [loading, setLoading] = useState(false);
  const [sideTableData, setSideTableData] = useState<any[]>([]);
  const [canGetSideTableData, setCanGetSideTableData] = useState(false);
  const [speedChart, setSpeedChart] = useState<ChartDataType>([]);
  const [temperatureChart, setTemperatureChart] = useState<ChartDataType>([]);
  const { clienteId, usuarioId, isEmbarcador, cnpj } = getCookieSessionData().usuarioVO;
  // eslint-disable-next-line no-undef
  const [map, setMap] = useState<google.maps.Map>();
  const [currentZoom, setCurrentZoom] = useState<number>();
  const [center, setCenter] = useState<LatLng | undefined>({ lat: 0, lng: 0 });
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [selectedMarker, setSelectedMarker] = useState<LatLng>();
  const [velocidadeFrota, setVelocidadeFrota] = useState(0);
  const [cardData, setCardData] = useState<CardDataType>({
    veiculos: 0,
    violacaoVelocidade: 0,
    velocidadeMedia: 0,
    violacaoTemperatura: 0,
    temperaturaMedia: 0,
    velocidadeFrota: 0,
    emViagem: 0,
  });

  const [veiculos2, setVeiculos2] = useState(0);

  const veiculos2Ref = useRef(veiculos2);
  veiculos2Ref.current = veiculos2;

  const tipoControleRef = useRef(tipoControle);
  tipoControleRef.current = tipoControle;

  const dataInicioRef = useRef(dataInicio);
  dataInicioRef.current = dataInicio;

  const dataFimRef = useRef(dataFim);
  dataFimRef.current = dataFim;

  const openMap = async (lat: string, lng: string) => {
    const location = { lat: Number(lat), lng: Number(lng) };
    setCenter(location);
    setCurrentZoom(14);
  };

  const renderChartData = useCallback((type: string, modifiedData: GridViolacao[]) => {
    if (
      !modifiedData.length ||
      (type === 'temperatura' && !modifiedData.some((d: GridViolacao) => d.temperatura)) ||
      (type === 'velocidade' && !modifiedData.some((d: GridViolacao) => d.velocidade))
    ) {
      return [];
    }

    const chartData: ChartDataType = [];

    uniqBy(modifiedData, 'data').forEach((item: GridViolacao) => {
      // Ajuste o formato esperado da data. Supondo que seja 'MM/dd/yyyy HH:mm'
      const parsedDate = parse(item.data, 'MM/dd/yyyy HH:mm', new Date());
      const timestamp = parsedDate.getTime();

      if (Number.isNaN(timestamp)) return;

      const groupedByDate = modifiedData.filter((d) => d.data === item.data);

      let media =
        groupedByDate.reduce(
          (acc, curr: GridViolacao) =>
            acc + (type === 'velocidade' ? curr.velocidade : curr.temperatura),
          0
        ) / groupedByDate.length;

      media = Math.round(media);

      const tooltipArr: string[] = [];
      if (groupedByDate.length > 1) {
        tooltipArr.push(
          `${
            type === 'velocidade'
              ? `Velocidade Média: ${media} km/h`
              : `Temperatura Média: ${media} ºC`
          }\n`
        );
      }

      let hasBullet = false;
      groupedByDate.forEach((d: GridViolacao, index: number) => {
        const tempOrSpeed = (_type: string) => {
          if (_type === 'velocidade' && d.velocidade) {
            return `${d.velocidade} km/h`;
          }
          if (_type === 'temperatura' && d.temperatura) {
            return `${d.temperatura} ºC`;
          }
          return false;
        };

        if (!tempOrSpeed(type)) return;

        tooltipArr.push(`${d.placa} - ${tempOrSpeed(type)}${groupedByDate[index + 1] ? '\n' : ''}`);

        if (!d.tipoViolacao) {
          return;
        }
        hasBullet = d.tipoViolacao.toLowerCase() === type.toLowerCase();
      });

      if (media === 0) return;

      chartData.push({
        date: timestamp,
        value: media,
        tooltip: tooltipArr.join(''),
        bullet: hasBullet,
      });
    });

    return orderBy(chartData, 'date', 'asc');
  }, []);

  const getChartData = useCallback(
    async (modifiedData: GridViolacao[], _controle?: number) => {
      const _temperature = renderChartData('temperatura', modifiedData);
      const _speed = renderChartData('velocidade', modifiedData);
      if (tipoControle === 0 || _controle === 0) {
        setTemperatureChart(_temperature);
        setSpeedChart([]);
      } else if (tipoControle === 1 || _controle === 1) {
        setSpeedChart(_speed);
        setTemperatureChart([]);
      } else if (tipoControle === 2 || _controle === 2) {
        setTemperatureChart(_temperature);
        setSpeedChart(_speed);
      }
    },
    [renderChartData, tipoControle]
  );

  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const formattedDate = oneMonthAgo.toISOString().split('T')[0];
    setMinDate(formattedDate);
  }, []);

  useEffect(() => {
    const handleCount = async () => {
      const res = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/vehicle-control-speed/`, {
          params: {
            cliente: clienteId,
            page_size: 99999,
            speed: true,
          },
        }),
        axios.get(`${process.env.REACT_APP_API_URL}/vehicle-control-temperature`, {
          params: {
            cliente: clienteId,
            page_size: 99999,
            temperature: true,
          },
        }),
      ]);

      const [speed, temperature] = res as any;

      const speedCount = speed.data.results.map(({ placa }: { placa: string }) => placa);
      const temperatureCount = temperature.data.results.map(
        ({ placa }: { placa: string }) => placa
      );

      const count = [...new Set([...speedCount, ...temperatureCount])];

      setVeiculos2(count.length);
    };

    handleCount();
  }, []);

  const handleCount = async () => {
    let res;

    if (tipoControleRef.current === 0) {
      res = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/vehicle-control-temperature`, {
          params: {
            cliente: clienteId,
            page_size: 99999,
            temperature: true,
          },
        }),
      ]);

      const [temperature] = res as any;

      const temperatureCount = temperature.data.results.length;

      setVeiculos2(temperatureCount);
      return;
    }

    if (tipoControleRef.current === 1) {
      res = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/vehicle-control-speed/`, {
          params: {
            cliente: clienteId,
            page_size: 99999,
            speed: true,
          },
        }),
      ]);

      const [speed] = res as any;

      const speedCount = speed.data.results.length;

      setVeiculos2(speedCount);
      return;
    }

    if (tipoControleRef.current === 2) {
      res = await Promise.all([
        axios.get(`${process.env.REACT_APP_API_URL}/vehicle-control-speed/`, {
          params: {
            cliente: clienteId,
            page_size: 99999,
            speed: true,
          },
        }),
        axios.get(`${process.env.REACT_APP_API_URL}/vehicle-control-temperature`, {
          params: {
            cliente: clienteId,
            page_size: 99999,
            temperature: true,
          },
        }),
      ]);

      const [speed, temperature] = res as any;

      const speedCount = speed.data.results.map(({ placa }: { placa: string }) => placa);
      const temperatureCount = temperature.data.results.map(
        ({ placa }: { placa: string }) => placa
      );

      const count = [...new Set([...speedCount, ...temperatureCount])];

      setVeiculos2(count.length);
    }
  };

  const cardArrData = useMemo(
    () => [
      {
        name: 'Veículos Cadastrados',
        data: veiculos2Ref.current,
        icon: <FaClipboardList color="#3865A3" size="35px" />,
        color: '#3865A3',
        onClick: () => {
          setCenter(initialCenter);
          setCurrentZoom(4);
          setMainData(data);
          setExcelData(data);
          getChartData(data);
        },
        show: !isEmbarcador,
      },
      {
        name: 'Em Viagem',
        data: cardData.emViagem,
        icon: <RiTruckFill color="#64A338" size="40px" />,
        color: '#64A338',
        show: cardData.emViagem !== 0,
      },
      {
        name: 'Violação de Velocidade',
        data: cardData.violacaoVelocidade,
        icon: <IoMdSpeedometer color="#E03B24" size="40px" />,
        color: '#E03B24',
        onClick: () => {
          setCenter(initialCenter);
          setCurrentZoom(4);
          const _data = data?.filter((item) => item.tipoViolacao === 'Velocidade');
          setMainData(_data);
          setExcelData(data);
          getChartData(_data);
        },
        show: controle === 1 || controle === 2,
      },
      {
        name: 'Tempo Máximo de Trânsito Excedido',
        data: 12,
        height: 45,
        color: Math.sign(12) === 1 ? '#E03B24' : '#64A338',
        show: true,
      },
      {
        name: 'Tempo mínimo de Parada Não Realizada',
        data: 3,
        height: 45,
        color: Math.sign(3) === 1 ? '#E03B24' : '#64A338',
        show: true,
      },
      {
        name: 'Velocidade Média',
        data: cardData.velocidadeMedia,
        icon: <IoMdSpeedometer color="#F57C00" size="40px" />,
        color: '#F57C00',
        show: controle === 1 || controle === 2,
      },
      {
        name: `Velocidade Máxima Permitida${
          selectedVeiculo.length === 1 && cardData.velocidadeFrota !== velocidadeFrota
            ? ' (veículo)'
            : ' (frota)'
        }`,
        icon: <IoMdSpeedometer color="#F57C00" size="40px" />,
        data: cardData.velocidadeFrota,
        color: '#F57C00',
        show: controle === 1 || controle === 2,
      },
      {
        name: 'Violação Temperatura',
        data: cardData.violacaoTemperatura,
        icon: <FaTemperatureHigh color="#F57C00" size="35px" />,
        color: '#F57C00',
        onClick: () => {
          setCenter(initialCenter);
          setCurrentZoom(4);
          const _data = data?.filter((item) => item.tipoViolacao === 'Temperatura');
          getChartData(_data);
        },
        show: controle === 0 || controle === 2,
      },
      {
        name: 'Temperatura Média',
        data: cardData.temperaturaMedia,
        icon: <FaTemperatureHigh color="#F57C00" size="35px" />,
        color: '#F57C00',
        show: controle === 0 || controle === 2,
      },
    ],
    [
      cardData.temperaturaMedia,
      cardData.veiculos,
      cardData.velocidadeFrota,
      cardData.velocidadeMedia,
      cardData.violacaoTemperatura,
      cardData.violacaoVelocidade,
      controle,
      data,
      getChartData,
      selectedVeiculo.length,
      velocidadeFrota,
      veiculos2,
    ]
  );

  const modifyData = useCallback(
    (violacao: GridViolacao[], velocidadeMaxFrota: number, _controle?: number) => {
      setLoading(true);

      let modifiedData: GridViolacao[] = violacao;
      const props = {
        velocidadeTotal: 0,
        temperaturaTotal: 0,
        qtdTemperatura: 0,
        violacaoTemperatura: 0,
        qtd: 0,
        velocidadeGTZero: 0,
        velocidadeMaxima: 0,
        emViagem: 0,
      };
      props.emViagem = modifiedData[0].qtdSmViagemCliente;
      modifiedData = modifiedData.map((d: GridViolacao) => {
        let tipoViolacao = ''; // Variável para armazenar o tipo de violação

        props.velocidadeMaxima =
          selectedVeiculo?.length === 1 && d.velocidadeMaxima
            ? d.velocidadeMaxima
            : velocidadeMaxFrota;
        props.velocidadeTotal += d.velocidade;
        props.velocidadeGTZero += d.velocidade > 0 ? 1 : 0;

        if (d.velocidadeMaxima || velocidadeMaxFrota) {
          const velocidadeMaxima = d.velocidadeMaxima ? d.velocidadeMaxima : velocidadeMaxFrota;
          if (velocidadeMaxima < d.velocidade) {
            const qtd = d.velocidade - velocidadeMaxima;
            props.qtd += 1;
            tipoViolacao = 'Velocidade'; // Define o tipo de violação como 'Velocidade'
          }
        }

        if (d.temperatura) {
          props.temperaturaTotal += d.temperatura;
          props.qtdTemperatura += d.temperatura ? 1 : 0;
          if (d.temperaturaMaxima) {
            if (d.temperatura > d.temperaturaMaxima) {
              props.violacaoTemperatura += 1;
              const qtd = d.temperatura - d.temperaturaMaxima;
              tipoViolacao =
                tipoViolacao === 'Velocidade' ? 'Velocidade/Temperatura' : 'Temperatura'; // Atualiza para 'Velocidade/Temperatura' se necessário
              return {
                ...d,
                tipoViolacao,
                qtd,
              };
            }
            if (d.temperatura < d.temperaturaMinima) {
              props.violacaoTemperatura += 1;
              const qtd = d.temperaturaMinima - d.temperatura;
              tipoViolacao =
                tipoViolacao === 'Velocidade' ? 'Velocidade/Temperatura' : 'Temperatura'; // Atualiza para 'Velocidade/Temperatura' se necessário
              return {
                ...d,
                tipoViolacao,
                qtd,
              };
            }
          }
        }

        // Se a temperatura ou a velocidade não excederem os limites, retorna o item original
        return tipoViolacao ? { ...d, tipoViolacao } : d;
      });

      setCardData({
        emViagem: props.emViagem,
        veiculos: veiculos.length,
        violacaoVelocidade: props.qtd,
        velocidadeMedia: Math.round(props.velocidadeTotal / props.velocidadeGTZero),
        violacaoTemperatura: props.violacaoTemperatura,
        temperaturaMedia:
          props.temperaturaTotal > 0
            ? Math.round(props.temperaturaTotal / props.qtdTemperatura)
            : 0,
        velocidadeFrota: props.velocidadeMaxima,
      });
      setData(modifiedData);
      setMainData(modifiedData);
      setExcelData(modifiedData);
      setCanGetSideTableData(true);
      getChartData(modifiedData, _controle);
      setControle(_controle || tipoControle);
      setLoading(false);
    },
    [getChartData, selectedVeiculo?.length, tipoControle, veiculos.length]
  );

  const getData = useCallback(
    async (velocidadeMaxFrota: number, _controle?: number) => {
      setCenter(initialCenter);
      setCurrentZoom(4);
      let dtInicio = dataInicioRef ? `${dataInicioRef.current} 00:00` : '';
      let dtFim = dataFimRef ? `${dataFimRef.current} 23:59` : '';
      const placas: string[] = [];

      if (new Date(dtInicio) < new Date(minDate)) {
        addToast({
          title: 'Erro',
          description:
            'Data Início não pode ser maior que a data mínima permitida que é um mês no máximo',
          type: 'error',
        });
        return;
      }

      if (selectedVeiculo?.length && !isEmbarcador) {
        selectedVeiculo?.forEach((v) => {
          return placas.push(veiculos.find((veiculo) => veiculo.id === v)?.placa);
        });
      }
      if (isEmbarcador && !selectedVeiculo?.length) {
        veiculos.forEach((v) => {
          return placas.push(v.placa);
        });
      }
      if (isEmbarcador && selectedVeiculo?.length) {
        selectedVeiculo?.forEach((v) => {
          if (typeof v === 'string') {
            placas.push(v);
          }
        });
      }
      const query = placas
        .map((placa, index) => {
          return `${index === 0 ? '?' : ''}placa=${placa}`;
        })
        .join('&');
      setLoading(true);
      try {
        if (!query.length && !dtInicio && !dtFim && !selectedVeiculo.length) {
          dtInicio = `${startDate} 00:00`;
          dtFim = `${endDate} 23:59`;
        }

        console.log(selectedVeiculo.length);

        const response = await handleListVehicleControl({
          dataFim: toZoned(dtFim, false),
          dataInicial: toZoned(dtInicio),
          idCliente: !isEmbarcador ? clienteId : '',
          tipoControle: opcoesControles[_controle || tipoControleRef.current].name,
          veiculos: selectedVeiculo.length ? selectedVeiculo.join(',') : null,
        });

        if (!response.length) {
          addToast({
            title: 'Erro',
            description: 'Nenhum dado encontrado para os filtros selecionados',
            type: 'error',
          });
          return;
        }

        const _data = response.map((item: any) => {
          return {
            ...item,
            velocidade: item.velocidade && Number(item.velocidade),
            temperatura: item.temperatura && Number(item.temperatura),
            velocidadeMaxima: item.velocidadeMaxima && Number(item.velocidadeMaxima),
            data: format(new Date(item.data), 'MM/dd/yyyy HH:mm'),
          };
        });

        const arr: string[] = [];
        mainTableColumn.forEach((c: any) => {
          if (c.value.includes(tipoControle === 3 ? _controle : tipoControle)) {
            arr.push(c.label);
          }
        });

        setMainTable(arr);
        handleCount();
        modifyData(_data, velocidadeMaxFrota, _controle);
        setExcelData(_data); // Add this line to set the data for ExportExcel
      } catch (error: any) {
        addToast({
          title: 'Erro',
          description: 'Ocorreu um erro ao buscar os dados',
          type: 'error',
        });
        setData([]);
        setMainData([]);
        setExcelData([]);
        setSideTableData([]);
      } finally {
        setLoading(false);
      }
    },
    [
      addToast,
      clienteId,
      dataFim,
      dataInicio,
      isEmbarcador,
      modifyData,
      selectedVeiculo,
      tipoControle,
      veiculos,
    ]
  );

  const getServicosContratados = async () => {
    try {
      if (veiculos[0].id === 0) return;

      const response = await api.get('/cliente/getServicosContratadosCliente', {
        params: {
          cliente: clienteId,
        },
      });
      const res = await api.get(`/violacao-velocidade/idCliente?idCliente=${clienteId}`);
      setVelocidadeFrota(res.data.velocidadeMaximaFrota);

      // Verificar se `tipoControle` já está correto antes de modificar
      if (response.data.controleTemperatura === 1 && response.data.controleVelocidade === 1) {
        if (tipoControle !== 2) {
          setTipoControle(2);
          setControlesContratados(2);
          getData(res.data.velocidadeMaximaFrota, 2);
        }
      } else if (response.data.controleVelocidade === 1) {
        if (tipoControle !== 1) {
          setTipoControle(1);
          setControlesContratados(1);
          getData(res.data.velocidadeMaximaFrota, 1);
        }
      } else if (response.data.controleTemperatura === 1) {
        if (tipoControle !== 0) {
          setTipoControle(0);
          setControlesContratados(0);
          getData(res.data.velocidadeMaximaFrota, 0);
        }
      } else {
        history.push('/erroservico');
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    const oneMinute = 1000 * 10 * 60;
    const timer = setInterval(() => {
      const _controle = tipoControleRef.current;

      getData(velocidadeFrota, _controle);
    }, oneMinute);

    return () => {
      clearTimeout(timer);
    };
  }, [velocidadeFrota]);

  const hasVehicles = (value: any) => {
    if (!value.length) {
      setVeiculos([{ id: 0, placa: 'Nenhum veículo encontrado' }]);

      if (isEmbarcador) {
        addToast({
          title: 'Erro',
          description: 'Não há dados a serem carregados, pois não há SM em andamento',
          type: 'error',
        });
      }

      return;
    }
    let vei: any[] = [];
    if (isEmbarcador) {
      value.forEach((v: any) => {
        vei.push({ placa: v.split(' ')[0] });
        if (v.split(' ')[1].trim().length) {
          vei.push({ placa: v.split(' ')[1] });
        }
        vei = uniqBy(vei, 'placa');
      });
    } else {
      vei = uniqBy(value, 'placa');
    }

    setVeiculos(vei);
  };

  const getVeiculos = async () => {
    try {
      if (!isEmbarcador) {
        setLoading(true);
        const [responseSpeed, responseTemperature] = await Promise.all([
          api.get('/vehicle-control-speed/', {
            params: {
              cliente: clienteId,
              page_size: 99999,
              speed: true,
            },
          }),
          api.get('/vehicle-control-temperature/', {
            params: {
              cliente: clienteId,
              page_size: 99999,
              page: 1,
              temperature: true,
            },
          }),
        ]);

        // Combine os resultados das duas requisições
        const combinedResults = [
          ...responseSpeed.data.results,
          ...responseTemperature.data.results,
        ];

        const combinedData = {
          count: responseSpeed.data.count + responseTemperature.data.count, // Ajuste conforme necessário
          next: null, // Ajuste conforme necessário
          previous: null, // Ajuste conforme necessário
          results: combinedResults,
        };

        hasVehicles(combinedData.results);
        setLoading(false);
      }

      if (isEmbarcador) {
        setLoading(true);
        const response = await api.get('/veiculo/placa-por-cliente-embarcador', {
          params: {
            cnpjEmbarcador: cnpj,
          },
        });

        hasVehicles(response.data);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getVeiculosTemperatura = async () => {
    try {
      if (!isEmbarcador) {
        const response = await api.get('/vehicle-control-speed', {
          params: {
            cliente: clienteId,
            page_size: 99999,
            speed: true,
          },
        });

        hasVehicles(response.data.results);
      }
      if (isEmbarcador) {
        setLoading(true);
        const response = await api.get('/veiculo/placa-por-cliente-embarcador', {
          params: {
            cnpjEmbarcador: cnpj,
          },
        });

        hasVehicles(response.data.results);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getVeiculosVelocidade = async () => {
    try {
      if (!isEmbarcador) {
        const response = await api.get('/vehicle-control-temperature', {
          params: {
            cliente: clienteId,
            page_size: 99999,
            temperature: true,
          },
        });

        hasVehicles(response.data.results);
      }
      if (isEmbarcador) {
        setLoading(true);
        const response = await api.get('/veiculo/placa-por-cliente-embarcador', {
          params: {
            cnpjEmbarcador: cnpj,
          },
        });

        hasVehicles(response.data);
        setLoading(false);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getSideTableData = useCallback(() => {
    const arr: any[] = [];
    const _data = data.filter((item) => {
      if (tipoControleRef.current === 0) {
        return item.tipoViolacao === 'Temperatura';
      }

      if (tipoControleRef.current === 1) {
        return item.tipoViolacao === 'Velocidade';
      }

      return item.tipoViolacao;
    });
    uniqBy(_data, 'placa').forEach((item) => {
      const qtd = _data.filter((item2) => item.placa === item2.placa);
      arr.push({
        placa: item.placa,
        qtd: qtd.length,
      });
    });
    if (arr.length) {
      setSideTableData(orderBy(arr, 'qtd', 'desc'));
    } else {
      setSideTableData([]);
    }
    setCanGetSideTableData(false);
  }, [data]);

  useEffect(() => {
    if (veiculos[0]?.placa === 'Nenhum veículo encontrado') return;

    if (veiculos.length) {
      if (shouldGetServices) {
        getServicosContratados();
        setShouldGetServices(false); // Desabilita futuras chamadas até que necessário
      }
    } else {
      veiculosTipo(tipoControle);
      setShouldGetServices(true); // Reabilita chamada quando `veiculos` estiver vazio
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [veiculos]);

  useEffect(() => {
    if (canGetSideTableData) {
      getSideTableData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canGetSideTableData]);

  const veiculosTipo = (tipoControle: number) => {
    if (tipoControle === 1) {
      getVeiculosTemperatura();
    } else if (tipoControle === 0) {
      getVeiculosVelocidade();
    } else {
      getVeiculos();
    }
  };

  const handleClickViolacao = (placa: any) => {
    const _data = data.filter((item2) => placa === item2.placa);
    const dataFilter = _data.filter((item) => {
      if (tipoControleRef.current === 0) {
        return item.tipoViolacao === 'Temperatura';
      }

      if (tipoControleRef.current === 1) {
        return item.tipoViolacao === 'Velocidade';
      }

      return item.tipoViolacao;
    });

    setMainData(dataFilter);
    setExcelData(dataFilter);
    setExcelData(_data);
    getChartData(_data);
  };

  return (
    <Tela nome="Prevenção de Acidentes" caminho="Controle Logístico > Prevenção de Acidentes">
      <div className="painel" style={{ width: '100%' }}>
        <div className="table-responsive" style={{ width: '100%' }}>
          <div>
            <div>
              <CardRow>
                {cardArrData.map((item, index) => {
                  return (
                    item.show && (
                      <CardInformativo
                        key={index}
                        name={item.name}
                        data={item.data}
                        icon={item.icon}
                        color={item.color}
                        onClick={
                          item?.onClick && item.data !== 0 ? () => item.onClick() : undefined
                        }
                      />
                    )
                  );
                })}
              </CardRow>
              <div>
                <div className="filtros">
                  <Form.Row
                    className="ml-2"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Form.Group className="row-modal">
                      <Form.Label>Tipo de Controle</Form.Label>
                      <Select
                        value={tipoControle}
                        options={tiposControles(controlesContratados)}
                        onChange={(e: any) => {
                          setTipoControle(e.target.value);
                          veiculosTipo(e.target.value);
                        }}
                        optionLabel="name"
                        optionValue="id"
                        placeholder={
                          !tiposControles(controlesContratados).length && loading
                            ? 'Carregando...'
                            : 'Selecione'
                        }
                        className="dropdown-controle"
                        showClear
                      />
                    </Form.Group>
                    <Form.Group className="row-modal">
                      <Form.Label>Veículos</Form.Label>
                      <MultiSelect
                        value={selectedVeiculo}
                        options={veiculos}
                        style={{ width: '250px' }}
                        onChange={(e: any) => {
                          setSelectedVeiculo(e.target.value);
                        }}
                        optionValue={`${isEmbarcador ? 'placa' : 'id'}`}
                        optionLabel="placa"
                        className="multiselect-custom"
                        filter
                        selectedItemsLabel={`${selectedVeiculo.length} itens selecionados`}
                        required
                        placeholder={!veiculos.length && loading ? 'Carregando...' : 'Selecione'}
                        emptyFilterMessage="Nenhum item encontrado"
                        tooltip="Selecione um ou mais veículos"
                      />
                    </Form.Group>
                    <Form.Group className="row-modal">
                      <Form.Label>Data Início</Form.Label>
                      <Form.Control
                        value={dataInicio}
                        onChange={(e: any) => {
                          setDataInicio(e.target.value);
                        }}
                        type="date"
                        id="date"
                        name="date"
                        placeholder=""
                        min={minDate}
                        max={dataFim}
                      />
                    </Form.Group>
                    <Form.Group className="row-modal">
                      <Form.Label>Data Fim</Form.Label>
                      <Form.Control
                        value={dataFim}
                        onChange={(e: any) => {
                          setDataFim(e.target.value);
                        }}
                        type="date"
                        id="dataFim"
                        name="dataFim"
                        placeholder=""
                        max={dataFim}
                        min={minDate}
                      />
                    </Form.Group>

                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        marginTop: '10px',
                      }}
                    >
                      <span style={{ marginBottom: '-30px' }}>
                        <FilterBtn
                          disabled={loading}
                          size="sm"
                          color="dark"
                          onClick={() => (!loading ? getData(velocidadeFrota) : null)}
                        >
                          {loading ? (
                            <Spinner animation="border" variant="dark" size="sm" />
                          ) : (
                            'Filtrar'
                          )}
                        </FilterBtn>
                      </span>

                      <>
                        {mainData.length > 0 && (
                          <ExportExcel data={mainData} tipoControle={tipoControle} />
                        )}
                      </>
                    </div>
                  </Form.Row>
                </div>
                <div style={{ display: 'flex', justifyContent: 'row' }}>
                  {temperatureChart.length || speedChart.length ? (
                    <Chart
                      type={tipoControle}
                      speedChart={speedChart}
                      temperatureChart={temperatureChart}
                    />
                  ) : null}
                  {sideTableData.length ? (
                    <Table width="20%">
                      <div className="table-header">
                        <p>Posição</p>
                        <p>Placa</p>
                        <p>Violação</p>
                      </div>
                      <div className="table-body">
                        {sideTableData.map((each: any, index: number) => (
                          <div className="table-body-row" key={index}>
                            <p>{index + 1}</p>
                            <p>
                              <Button
                                variant="Link"
                                size="sm"
                                onClick={() => handleClickViolacao(each.placa)}
                              >
                                {each.placa}
                              </Button>
                            </p>
                            <p>{each.qtd}</p>
                          </div>
                        ))}
                      </div>
                    </Table>
                  ) : null}
                </div>
              </div>
            </div>
            <TableMapContainer>
              <MainTable
                data={mainData}
                columns={mainTable}
                openMap={openMap}
                controle={controle}
                velocidadeFrota={velocidadeFrota}
              />

              {mainData.filter((item) => {
                return item.tipoViolacao;
              }).length ? (
                <Mapa
                  currentZoom={currentZoom}
                  setMap={setMap}
                  setCenter={setCenter}
                  initialCenter={initialCenter}
                  center={center}
                  mainData={mainData.filter((item) => {
                    return item.tipoViolacao;
                  })}
                  setSelectedMarker={setSelectedMarker}
                  setShowInfo={setShowInfo}
                  showInfo={showInfo}
                  selectedMarker={selectedMarker}
                  controle={controle}
                />
              ) : null}
            </TableMapContainer>
          </div>
        </div>
      </div>
    </Tela>
  );
};
