/* eslint-disable */
import './styles.css';
import { ImCancelCircle } from 'react-icons/im';
import { AiOutlineCheck } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { IoPencilSharp } from 'react-icons/io5';
import { ButtonTooltip } from '../ButtonTooltipo';
import { useHistory } from 'react-router-dom';

type LineProps = {
  each: any;
  setOpenModal: (openModal: boolean) => void;
  setIdDelete: (idDelete: number) => void;
  setIdAtual: (idEdit: number) => void;
  handlePut: (model: any, nomeOriginal: string) => void;
  idAtual: number;
  canEdit?: boolean;
  canDelete: boolean;
};

export const Linha = ({
  each,
  setOpenModal,
  setIdDelete,
  setIdAtual,
  handlePut,
  idAtual,
  canEdit,
  canDelete,
}: LineProps) => {
  const history = useHistory();
  const [disabled, setDisabled] = useState(true);
  const [model, setModel] = useState<any>(each);
  const [nomeOriginal, setNomeOriginal] = useState('');

  const handleEdit = (id: number) => {
    setDisabled(false);
    setIdAtual(id);
  };

  const handleCancel = () => {
    setDisabled(true);
    setIdAtual(0);
  };

  useEffect(() => {
    setModel(each);
  }, [each, disabled, idAtual]);

  useEffect(() => {
    setNomeOriginal(each.nome);
  }, []);

  useEffect(() => {
    const listener = (event: globalThis.KeyboardEvent) => {
      if (event.keyCode === 27) {
        handleCancel();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [model]);

  return (
    <div>
      <div
        className="lineGrid2"
        style={{ filter: idAtual != model.id ? '' : 'drop-shadow(0 0 0.5rem #d8d8d8)' }}
      >

        <input
          disabled={idAtual != model.id}
          value={model.nome}
          onChange={(e: any) => {
            setModel({
              ...model,
              nome: e.target.value,
            });
          }}
          type="text"
          maxLength={64}
          onKeyPress={(e: any) => {
            if (e.key === 'Enter') {
              handlePut(model, nomeOriginal);
            }
            if (e.key === 'Escape') {
              handleCancel();
            }
          }}
        />
        {!disabled && idAtual === model.id && idAtual != 0 ? (
          <ButtonTooltip onClick={handleCancel} type="Cancelar" />
        ) : (
          <ButtonTooltip
            onClick={() => {
              if (canEdit) {
                handleEdit(model.id);
              } else {
                history.push('/acessonegado');
              }
            }}
            type="Editar"
          />
        )}
        {!disabled && idAtual === model.id && idAtual != 0 ? (
          <ButtonTooltip
            onClick={() => {
              handlePut(model, nomeOriginal);
            }}
            type="Enviar"
          />
        ) : (
          <ButtonTooltip
            onClick={() => {
              if (canDelete) {
                setOpenModal(true);
                if (model.id != undefined) {
                  setIdDelete(model.id);
                }
              } else {
                history.push('/acessonegado');
              }
            }}
            type="Excluir"
          />
        )}
      </div>
    </div>
  );
};

type NewLineProps = {
  setNewLine: (newLine: boolean) => void;
  handlePost: (nome: string) => void;
};

export const NovaLinha = ({ setNewLine, handlePost }: NewLineProps) => {
  const [disabled, setDisabled] = useState(false);
  const [nome, setNome] = useState('');

  const handleCancel = () => {
    setDisabled(true);
    setNewLine(false);
  };

  useEffect(() => {
    const listener = (event: globalThis.KeyboardEvent) => {
      if (event.keyCode === 27) {
        handleCancel();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [nome]);

  return (
    <div className="lineGrid2" style={{ filter: 'drop-shadow(0 0 0.5rem #d8d8d8)' }}>
      <input
        placeholder="Novo registro"
        type="text"
        value={nome}
        maxLength={64}
        onChange={(e: any) => {
          setNome(e.target.value);
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            handlePost(nome);
          }
          if (e.key === 'Escape') {
            handleCancel();
          }
        }}
      />
      <ButtonTooltip onClick={handleCancel} type="Cancelar" />
      <ButtonTooltip
        onClick={() => {
          handlePost(nome);
        }}
        type="Enviar"
      />
    </div>
  );
};
