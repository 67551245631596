/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import axios from '../../../config/axiosMaquina';
import { UfIbgeType } from '../../../api/model/UfIbgeType';
import { OrgaoEmissorTypes } from '../../../api/Types/orgaoEmissorTypes';
import { MunicipiosTypes } from '../../../api/Types/municipiosTypes';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import checaCPF from '../../../util/checaCpf';
import {
  maskTel,
  maskCep,
  soLetras,
  soNumeros,
  cpfMask,
  validaNome,
} from '../../../util/Validacoes/Validacoes';
import './styles.css';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { getCookieSessionData, isAllowedByRole } from '../../../services/cookieService';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { obterDataHora, nowToZoned } from '../../../util/date';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

function AtualizarCondutor() {
  const { ADMIN, TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [cliente, setCliente] = useState({});
  const [cpf, setCpf] = useState('');
  const [atualizarPesquisaAutomaticamente, setAtualizarPesquisaAutomaticamente] = useState(false);
  const [nome, setNome] = useState('');
  const [foto, setFoto] = useState('');
  const [tipo, setTipo] = useState('');
  const [sexo, setSexo] = useState('');
  const [rg, setRg] = useState('');
  const [rgOriginal, setRgOriginal] = useState('');
  const [emissaoRg, setEmissaoRg] = useState('');
  const [cnhRegistro, setCnhRegistro] = useState(0);
  const [categoriaCnh, setCategoriaCnh] = useState('');
  const [validadeCnh, setValidadeCnh] = useState('');
  const [segurancaCnh, setSegurancaCnh] = useState(0);
  const [dataNascimento, setDataNascimento] = useState('');
  const [cidadeNascimento, setCidadeNascimento] = useState(0);
  const [nomePai, setNomePai] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [bloqueado, setBloqueado] = useState(false);
  const [segundoTelefone, setSegundoTelefone] = useState('');
  const [referenciaTelefone, setReferenciaTelefone] = useState('');
  const [referenciaNome, setReferenciaNome] = useState('');
  const [grauParentesco, setGrauParentesco] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [logradouroNumero, setLogradouroNumero] = useState(0);
  const [complementoLogradouro, setComplementoLogradouro] = useState('');
  const [bairro, setBairro] = useState('');
  const [uf, setUf] = useState<number | undefined>();
  const [cidade, setCidade] = useState<number | undefined>();
  const [orgaoEmissor, setOrgaoEmissor] = useState();
  const [orgaosEmissores, setOrgaosEmissores] = useState<OrgaoEmissorTypes[]>([]);
  const [dataCadastro, setDataCadastro] = useState('');
  const [validacaoRg, setValidacaoRg] = useState(false);
  const [rgMudou, setRgMudou] = useState(false);
  const [idUfEmissorRg, setIdUfEmissorRg] = useState(0);
  const [idUfEmissorCnh, setIdUfEmissorCnh] = useState();
  const [idUfNascimento, setIdUfNascimento] = useState(0);
  const [erroValidadeCnh, setErroValidadeCnh] = useState(false);
  const [erroDataEmissaoRg, setErroDataEmissaoRg] = useState(false);
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [error, setError] = useState(false);
  const [municipiosPorEstado, setMunicipiosPorEstado] = useState<MunicipiosTypes[]>([]);
  const [cidadesNascimentoPorEstado, setCidadesNascimentoPorEstado] = useState<MunicipiosTypes[]>(
    []
  );
  const [ufSiglas, setUfSiglas] = useState<UfIbgeType[]>([]);
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [isNomeValid, setIsNomeValid] = useState(true);
  const [isNomeMaeValid, setIsNomeMaeValid] = useState(true);
  const [isNomePaiValid, setIsNomePaiValid] = useState(true);
  const [isGrauParentValid, setIsGrauParentValid] = useState(true);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const history = useHistory();
  const { id }: any = useParams();

  // @ts-ignore
  const ref = useRef<boolean | any>(null);
  // @ts-ignore
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      validaRgExistente();
    }
  };

  useEffect(() => {
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CONDUTOR], ADMIN: [ADMIN.CADASTRO.CONDUTOR] }],
      'editar'
    );
  }, [TRANSPORTADOR, ADMIN, rolePermission]);

  useEffect(() => {
    // @ts-ignore
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      // @ts-ignore
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const validaRgExistente = async () => {
    if (rg.length >= 4 && rgMudou) {
      if (rg !== rgOriginal) {
        const data = {
          rgCondutor: rg,
        };
        try {
          const response = await axios.post(`/condutor/existeRG/${clienteId || cliente}`, data);
          setValidacaoRg(response.data);
          if (response.data === true) {
            // const alerta = alert('O RG deste condutor já está cadastrado');
            addToast({
              title: 'Erro',
              description: 'O RG deste condutor já está cadastrado',
              type: 'error',
            });
            return;
          }
          setRg(rg);
        } catch (err: any) {
          console.log(err);
        }
      } else {
        setValidacaoRg(false);
      }
    }
  };

  useEffect(() => {
    axios.get('/uf/ListarIdSiglaEstadoPais/1').then((response: any) => {
      setUfSiglas(response.data);
    });
    uf && loadMunicipiosPorEstado(uf);
  }, [uf]);

  const loadMunicipiosPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const loadCidadesNascimentoPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setCidadesNascimentoPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const loadData = () => {
      axios.get('/orgao-emissor/getIdNome').then((response: any) => {
        setOrgaosEmissores(response.data);
      });
    };
    loadData();
  }, []);

  const buscarCep = () => {
    if (cep.length < 9) {
      setCidade(0);
      setLogradouro('');
      setBairro('');
      return;
    }
    setError(false);
    fetch(`https://viacep.com.br/ws/${cep}/json`)
      .then((res) => res.json())
      .then((data) => {
        if (data.erro) {
          setError(true);
          return;
        }
        setLogradouro(data.logradouro);
        setBairro(data.bairro);
        setCidade(parseInt(data.ibge));
        setViaCepCidade(data.localidade);
        setUf(ufSiglas.find((each: any) => each.sigla === data.uf)?.id);
        loadMunicipiosPorEstado(ufSiglas.find((each: any) => each.sigla === data.uf)?.id);
      })
      .catch((erro) => {
        console.log('error', erro);
        setError(true);
      });
  };

  const onChangeOrgaoEmissor = (event: any) => {
    setOrgaoEmissor(event.target.value);
  };

  useEffect(() => {
    const loadData = () => {
      axios
        .get(`/condutor/${id}`)
        .then((response: any) => {
          setBloqueado(response.data.bloqueado);
          setCpf(response.data.cpf);
          setNome(response.data.nome);
          setFoto(response.data.foto);
          setTipo(response.data.tipo);
          setDataNascimento(response.data.dataNascimento);
          setSexo(response.data.sexo);
          setRg(response.data.rg);
          setRgOriginal(response.data.rg);
          setEmissaoRg(response.data.emissaoRg);
          setIdUfEmissorRg(response.data.idUfEmissorRg);
          setIdUfNascimento(response.data.idUfNascimento);
          loadCidadesNascimentoPorEstado(response.data.idUfNascimento);
          setIdUfEmissorCnh(response.data.idUfEmissorCnh);
          setCliente(response.data.cliente);
          setAtualizarPesquisaAutomaticamente(response.data.atualizarPesquisaAutomaticamente);
          setCnhRegistro(response.data.cnhRegistro);
          setCategoriaCnh(response.data.categoriaCnh);
          setValidadeCnh(response.data.validadeCnh);
          setSegurancaCnh(response.data.segurancaCnh);
          setOrgaoEmissor(response.data.orgaoEmissor);
          setCidadeNascimento(response.data.cidadeNascimento);
          setNomePai(response.data.nomePai);
          setNomeMae(response.data.nomeMae);
          setSegundoTelefone(response.data.segundoTelefone);
          setReferenciaTelefone(response.data.referenciaTelefone);
          setReferenciaNome(response.data.referenciaNome);
          setGrauParentesco(response.data.grauParentesco);
          setTelefone(response.data.telefone);
          setCep(response.data.cep);
          setLogradouro(response.data.logradouro);
          setLogradouroNumero(response.data.logradouroNumero);
          setComplementoLogradouro(response.data.complementoLogradouro);
          setBairro(response.data.bairro);
          setUf(response.data.uf);
          setAtivo(response.data.ativo);
          setCidade(response.data.cidade);
          {
            response.data.dataCadastro
              ? setDataCadastro(response.data.dataCadastro)
              : setDataCadastro('');
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    };
    loadData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (
      !cpf ||
      !nome ||
      rg.length < 4 ||
      rg.length > 16 ||
      !idUfEmissorRg ||
      !cnhRegistro ||
      !categoriaCnh ||
      !segurancaCnh ||
      segurancaCnh.toString().length != 11 ||
      !cep ||
      !logradouro ||
      !bairro ||
      !cidade ||
      logradouroNumero === null ||
      !uf ||
      uf === 0 ||
      !dataNascimento ||
      erroDataNascimento ||
      !idUfNascimento ||
      !cidadeNascimento ||
      !nomePai ||
      !nomeMae ||
      !isValid ||
      !isNomePaiValid ||
      !isNomeValid ||
      !isNomeMaeValid ||
      !isGrauParentValid ||
      !idUfEmissorCnh ||
      !telefone ||
      !tipo ||
      !validadeCnh
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    if (validacaoRg) {
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    const dados = {
      id,
      cpf,
      atualizarPesquisaAutomaticamente,
      nome,
      foto,
      tipo,
      sexo,
      rg,
      emissaoRg,
      idUfEmissorRg,
      cnhRegistro,
      categoriaCnh,
      validadeCnh,
      segurancaCnh,
      idUfEmissorCnh,
      cliente: clienteId,
      ativo,
      dataNascimento,
      orgaoEmissor,
      cidadeNascimento,
      idUfNascimento,
      nomePai,
      nomeMae,
      segundoTelefone,
      referenciaTelefone,
      referenciaNome,
      grauParentesco,
      telefone,
      cep,
      logradouro,
      logradouroNumero,
      dataUltimaAlteracao: nowToZoned(),
      complementoLogradouro,
      bairro,
      uf,
      cidade,
      bloqueado,
    };

    await axios
      .put(`/condutor/${id}`, dados)
      .then(() => {
        setIsDialogCadOK(true);
      })
      .catch((err: any) => {
        if (err.response.data.message === 'Insira uma CNH valida') {
          addToast({
            title: 'CNH Vencida',
            description: 'Insira uma CNH válida!',
            type: 'error',
          });
          setErroValidadeCnh(true);
        } else {
          addToast({
            title: 'Erro',
            description: 'Erro ao atualizar!',
            type: 'error',
          });
        }
      });
  };

  const dataLimiteMaiorIdade = () => {
    const current = new Date();
    const maxDate = new Date(
      current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
    );

    return maxDate;
  };

  const maxDateFormatadoMaior18 = () => {
    const current = new Date();
    const maxDate = new Date(
      current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
    );
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const validaDataNascimento = (value: any) => {
    const nasc = new Date(value);
    if (nasc.getTime() < dataLimiteMaiorIdade().getTime()) {
      setDataNascimento(value);
      setErroDataNascimento(false);
    } else {
      setDataNascimento(value);
      setErroDataNascimento(true);
    }
  };

  const validaDataMaxCNH = () => {
    const current = new Date();
    const maxDate = new Date(
      current.getTime() + 1000 * 60 * 60 * 24 * 365 * 10 + 1000 * 60 * 60 * 24 * 120
    );
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const validaDataAtual = () => {
    const current = new Date();
    const maxDate = new Date(current.getTime());
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const validaDataMax = (value: any) => {
    const dataAtual = new Date(new Date().setHours(23, 59, 59, 999));
    const dataValidade = new Date(value.concat(' 23:59:59.999'));
    if (dataAtual > dataValidade) {
      setValidadeCnh(value);
      setErroValidadeCnh(true);
    } else {
      setValidadeCnh(value);
      setErroValidadeCnh(false);
    }
  };

  const validaDataMin = (value: any) => {
    const dataAtual = new Date();
    if (dataAtual.getTime() <= new Date(value).getTime()) {
      setEmissaoRg(value);
      setErroDataEmissaoRg(true);
    } else {
      setEmissaoRg(value);
      setErroDataEmissaoRg(false);
    }
  };

  const validaDataAtualRegistrado = () => {
    const current = new Date();
    const maxDate = new Date(current.getTime());
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const validaDataMaxRG = () => {
    const current = new Date();
    const maxDate = new Date(current.getTime() - 1000 * 60 * 60 * 24 * 365 * 100);
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');

  const handleDelete = (id: number) => {
    axios
      .put(`/condutor/delete-logico/${id}`)
      .then(() => {
        setExclusaoOK(true);
        history.push('/condutores/listar/1');
      })
      .catch((err: any) => {
        setExclusaoErro(true);
        setMensagemErro(err.response.data.message);
      });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Condutor foi alterado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.goBack();
        }}
      />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusaoOK && !exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro "{`${nome}`}"?
          </Modal.Body>
        )}

        {exclusaoOK && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            "Condutor "{`${nome}`}" excluído com sucesso."
          </Modal.Body>
        )}

        {exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{`${mensagemErro}`}</Modal.Body>
        )}

        {!exclusaoOK && !exclusaoErro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
            >
              Excluir
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <div className="card">
        <div className="titulo-container" style={{ marginBottom: '-20px' }}>
          <h2 className="titulo">Condutor</h2>
          <h6 className="subtitulo">{'Atualização > Condutor'}</h6>
          <button
            className="btn-desativar"
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
              position: 'absolute',
              right: '2%',
            }}
            onClick={() => {
              setExclusaoErro(false);
              setExclusaoOK(false);
              handleShow();
            }}
            // disabled={!permissoes[67] || !permissoes[67].excluir}
          >
            <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
          </button>
        </div>

        <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
        <Form noValidate validated={isFormInvalid} onSubmit={handleSubmit} className="form-sm">
          <Form.Row>
            <ProfilePicture foto={foto} setFoto={setFoto} name={cpf}>
              <>
                <Form.Switch style={{ marginLeft: '-30px' }}>
                  <Form.Group>
                    <Form.Check
                      checked={atualizarPesquisaAutomaticamente}
                      onChange={() => {
                        setAtualizarPesquisaAutomaticamente(!atualizarPesquisaAutomaticamente);
                      }}
                      name="atualizarPesquisaAutomaticamente"
                      type="switch"
                      id="custom-switch1"
                      label="Atualizar Pesquisa Automaticamente"
                    />
                  </Form.Group>
                </Form.Switch>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label>DATA CADASTRO</Form.Label>
                    <Form.Control
                      value={obterDataHora(dataCadastro)}
                      id="dataCadastro"
                      name="dataCadastro"
                      disabled
                    />
                  </Form.Group>
                </Form.Row>
              </>
            </ProfilePicture>
            {isAllowedByRole(['admin']) && (
              <Form.Group style={{ marginLeft: '29%' }}>
                <Form.Check
                  checked={bloqueado}
                  onChange={(event) => {
                    setBloqueado(!bloqueado);
                  }}
                  name="bloqueado"
                  type="switch"
                  id="bloqueado"
                  label="Condutor Bloqueado"
                />
              </Form.Group>
            )}
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">CPF</Form.Label>
              <Form.Control
                value={cpf}
                onChange={(event: any) => {
                  if (checaCPF(event.target.value) === false) {
                    setCpf(cpfMask(event.target.value));
                    setIsValid(false);
                  } else {
                    setCpf(cpfMask(event.target.value));
                    setIsValid(true);
                  }
                }}
                id="cpf"
                name="cpf"
                placeholder=""
                required
                title="Cpf inválido"
                maxLength={14}
                minLength={14}
                disabled
              />
              {!isValid && cpf.length > 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Cpf inválido</p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label className="requiredField">NOME</Form.Label>
              <Form.Control
                value={nome}
                onChange={(event) => {
                  event.preventDefault();
                  setNome(soLetras(event.target.value));
                  if (validaNome(soLetras(event.target.value))) {
                    setIsNomeValid(true);
                  } else {
                    setIsNomeValid(false);
                  }
                }}
                name="nome"
                placeholder=""
                required
                type="text"
                title="Preencha com dois nomes ou mais"
                maxLength={256}
              />
              {!isNomeValid && nome.length > 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome inválido, inserir nome e sobrenome.
                </p>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">TIPO CONDUTOR</Form.Label>
              <Form.Control
                value={tipo}
                onChange={(event: any) => {
                  setTipo(event.target.value);
                }}
                id="tipo"
                as="select"
                required
                title="Campo necessário"
              >
                <option value="">Selecione</option>
                <option value="1">Agregado</option>
                <option value="2">Autônomo</option>
                <option value="3">Funcionário</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>SEXO</Form.Label>
              <Form.Control
                value={sexo}
                onChange={(event: any) => {
                  setSexo(event.target.value);
                }}
                id="sexo"
                as="select"
              >
                <option value="" />
                <option value="F">Feminino</option>
                <option value="M">Masculino</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">RG</Form.Label>
              <Form.Control
                value={rg}
                onChange={(event) => {
                  setRg(event.target.value);
                  setRgMudou(true);
                }}
                id="rg"
                name="rg"
                type="text"
                isInvalid={validacaoRg}
                maxLength={16}
                minLength={4}
                required
                ref={ref}
              />
              {validacaoRg ? (
                <Form.Control.Feedback type="invalid">
                  O RG deste condutor já está cadastrado
                </Form.Control.Feedback>
              ) : (
                <Form.Control.Feedback type="invalid">
                  Campo RG deve conter entre 4 e 16 dígitos
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>ORGÃO EMISSOR RG</Form.Label>
              <Form.Control
                value={orgaoEmissor}
                onChange={onChangeOrgaoEmissor}
                as="select"
                title="Campo Obrigatório"
              >
                <option>Selecione</option>
                {orgaosEmissores.map((oE, index) => (
                  <option value={oE.id} key={index}>
                    {oE.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">UF ORGÃO EMISSOR RG</Form.Label>
              <Form.Control
                value={idUfEmissorRg}
                onChange={(event: any) => {
                  setIdUfEmissorRg(event.target.value);
                }}
                as="select"
                title="Campo Obrigatório"
                required
              >
                <option>Selecione</option>
                {ufSiglas.map((uf, index) => (
                  <option value={uf.id} key={index}>
                    {uf.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>DATA EMISSÃO RG</Form.Label>
              <Form.Control
                onChange={(event: any) => validaDataMin(event.target.value)}
                id="dataInicio"
                name="dataInicio"
                type="date"
                min={validaDataMaxRG()}
                max={validaDataAtualRegistrado()}
                value={emissaoRg === null ? '' : emissaoRg}
              />
              {erroDataEmissaoRg && (
                <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                  Data de Emissão não pode ser maior do que a data atual.
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} sm={6} className="ml-2">
              <Form.Label>Status</Form.Label>
              <Form.Check
                checked={!bloqueado}
                onChange={() => {
                  setBloqueado(!bloqueado);
                }}
                name="statusApto"
                type="switch"
                id="statusApto"
                label="Apto para viagem"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">CNH</Form.Label>
              <Form.Control
                value={cnhRegistro}
                onChange={(event: any) => {
                  setCnhRegistro(soNumeros(event.target.value));
                }}
                id="cnh"
                name="cnh"
                type="text"
                maxLength={11}
                minLength={11}
                required
                title="CNH Inválido"
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">CATEGORIA CNH</Form.Label>
              <Form.Control
                value={categoriaCnh}
                onChange={(event: any) => {
                  setCategoriaCnh(event.target.value);
                }}
                as="select"
                required
              >
                <option value="">Selecione</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="D">D</option>
                <option value="E">E</option>
                <option value="AB">AB</option>
                <option value="AC">AC</option>
                <option value="AD">AD</option>
                <option value="AE">AE</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">UF EMISSOR CNH</Form.Label>
              <Form.Control
                value={idUfEmissorCnh}
                onChange={(event: any) => {
                  setIdUfEmissorCnh(event.target.value);
                }}
                as="select"
                required
              >
                <option>Selecione</option>
                {ufSiglas.map((event, index) => (
                  <option value={event.id} key={index}>
                    {event.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label className="requiredField">VALIDADE CNH</Form.Label>
              <Form.Control
                onChange={(event: any) => validaDataMax(event.target.value)}
                id="dataInicio"
                name="dataInicio"
                type="date"
                min={validaDataAtual()}
                max={validaDataMaxCNH()}
                value={validadeCnh}
                required
                isInvalid={erroValidadeCnh}
              />
              {erroValidadeCnh && (
                <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                  Não é possivel cadastrar uma CNH vencida.
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">SEGURANÇA CNH</Form.Label>
              <Form.Control
                value={segurancaCnh}
                onChange={(event: any) => {
                  setSegurancaCnh(soNumeros(event.target.value));
                }}
                id="segurancaCnh"
                name="cnh"
                type="text"
                maxLength={11}
                minLength={11}
                isInvalid={
                  !!(segurancaCnh.toString().length != 11 && segurancaCnh.toString().trim() != '')
                }
                title="Campo Obrigatório"
                required
              />
              {segurancaCnh.toString().trim() != '' && segurancaCnh.toString().length != 11 && (
                <p style={{ fontSize: 11, color: 'red', marginTop: 5 }}>
                  Segurança CNH deve conter 11 dígitos.
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">CEP</Form.Label>
              <Form.Control
                value={cep}
                onChange={(event) => {
                  setCep(maskCep(event.target.value));
                }}
                onBlur={() => buscarCep()}
                id="cep"
                name="cep"
                type="text"
                maxLength={9}
                minLength={9}
                required
              />
              {error && <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">UF</Form.Label>
              <Form.Control
                value={uf}
                type="number"
                onChange={(event: any) => {
                  setUf(event.target.value);
                  loadMunicipiosPorEstado(event.target.value);
                }}
                id="uf"
                disabled={!!viaCepCidade}
                defaultValue={0}
                name="uf"
                required
                as="select"
              >
                <option value={0}>Selecione uma opção</option>
                {ufSiglas.map((uf: any, index) => (
                  <option value={uf.id} key={index}>
                    {uf.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">CIDADE</Form.Label>
              <Form.Control
                as="select"
                name="cidade"
                placeholder=""
                defaultValue=""
                disabled={!!viaCepCidade}
                value={cidade}
                onChange={(event: any) => {
                  setCidade(event.target.value);
                }}
                required
              >
                <option value="">Selecione uma opção</option>
                {municipiosPorEstado.map((filteredMunicipio, index) => (
                  <option key={index} value={filteredMunicipio.id}>
                    {filteredMunicipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">BAIRRO</Form.Label>
              <Form.Control
                value={bairro}
                onChange={(event) => {
                  setBairro(event.target.value);
                }}
                name="bairro"
                type="text"
                placeholder=""
                required
                title="Campo Obrigatório"
                maxLength={32}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">LOGRADOURO</Form.Label>
              <Form.Control
                value={logradouro}
                onChange={(event) => {
                  setLogradouro(event.target.value);
                }}
                name="logradouro"
                type="text"
                placeholder=""
                required
                title="Campo Obrigatório"
                maxLength={64}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">NÚMERO LOGRADOURO</Form.Label>
              <Form.Control
                value={logradouroNumero}
                onChange={(e: any) => {
                  setLogradouroNumero(soNumeros(e.target.value));
                }}
                id="logradouroNumero"
                name="logradouroNumero"
                type="text"
                placeholder=""
                required
                title="Campo Obrigatório"
                maxLength={8}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>COMPLEMENTO</Form.Label>
              <Form.Control
                value={complementoLogradouro}
                onChange={(event) => {
                  setComplementoLogradouro(event.target.value);
                }}
                id="logradouroComplemento"
                name="logradouroComplemento"
                type="text"
                placeholder=""
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">DATA NASCIMENTO</Form.Label>
              <Form.Control
                onChange={(event: any) => validaDataNascimento(event.target.value)}
                id="dataInicio"
                name="dataInicio"
                type="date"
                max={maxDateFormatadoMaior18()}
                value={dataNascimento}
                required
              />
              {erroDataNascimento && (
                <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                  Não é possivel cadastrar um condutor com idade menor a 18 anos.
                </p>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>UF NASCIMENTO</Form.Label>
              <Form.Control
                value={idUfNascimento}
                onChange={(event: any) => {
                  setIdUfNascimento(parseInt(event.target.value));
                  loadCidadesNascimentoPorEstado(parseInt(event.target.value));
                }}
                as="select"
              >
                <option>Selecione</option>
                {ufSiglas.map((uf, index) => (
                  <option value={uf.id} key={index}>
                    {uf.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>CIDADE NASCIMENTO</Form.Label>
              <Form.Control
                as="select"
                value={cidadeNascimento}
                name="cidadeNascimento"
                onChange={(event: any) => {
                  setCidadeNascimento(parseInt(event.target.value));
                }}
                required
              >
                {cidadesNascimentoPorEstado.map((municipio, index) => (
                  <option key={index} value={municipio.id}>
                    {municipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">NOME PAI</Form.Label>
              <Form.Control
                value={nomePai}
                onChange={(event) => {
                  event.preventDefault();
                  setNomePai(soLetras(event.target.value));
                  if (validaNome(soLetras(event.target.value))) {
                    setIsNomePaiValid(true);
                  } else {
                    setIsNomePaiValid(false);
                  }
                }}
                id="nomePai"
                name="nomePai"
                maxLength={256}
                type="text"
                title="São necessárias pelo menos duas palavras"
                placeholder=""
                required
              />
              {!isNomePaiValid && nomePai.length > 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome inválido, inserir nome e sobrenome. Caso não seja declarado, preencher como
                  "Não Declarado".
                </p>
              )}
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">NOME MÃE</Form.Label>
              <Form.Control
                value={nomeMae}
                onChange={(event) => {
                  event.preventDefault();
                  setNomeMae(soLetras(event.target.value));
                  if (validaNome(soLetras(event.target.value))) {
                    setIsNomeMaeValid(true);
                  } else {
                    setIsNomeMaeValid(false);
                  }
                }}
                id="nomeMae"
                maxLength={256}
                name="nomeMae"
                type="text"
                title="São necessárias pelo menos duas palavras"
                required
              />
              {!isNomeMaeValid && nomeMae.length > 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome inválido. Insira nome e sobrenome.
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">TELEFONE</Form.Label>
              <Form.Control
                value={maskTel(telefone)}
                onChange={(event) => {
                  setTelefone(maskTel(event.target.value));
                }}
                id="telefone"
                name="telefone"
                type="text"
                required
                maxLength={14}
                minLength={13}
                title="Telefone deve ter no minímo 13 dígitos e no máximo 14"
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>SEGUNDO TELEFONE</Form.Label>
              <Form.Control
                value={maskTel(segundoTelefone)}
                onChange={(event) => {
                  setSegundoTelefone(maskTel(event.target.value));
                }}
                id="segundoTelefone"
                name="segundoTelefone"
                type="text"
                maxLength={14}
                minLength={13}
                title="Telefone deve ter no minímo 13 dígitos e no máximo 14"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>NOME REFERÊNCIA</Form.Label>
              <Form.Control
                value={referenciaNome}
                onChange={(event) => {
                  setReferenciaNome(soLetras(event.target.value));
                }}
                id="referenciaNome"
                maxLength={64}
                name="referenciaNome"
                type="text"
              />
            </Form.Group>
            <Form.Group as={Col} md={6}>
              <Form.Label>REFERÊNCIA TELEFONE</Form.Label>
              <Form.Control
                value={maskTel(referenciaTelefone)}
                onChange={(event) => {
                  setReferenciaTelefone(maskTel(event.target.value));
                }}
                id="referenciaTelefone"
                name="referenciaTelefone"
                type="text"
                maxLength={14}
                minLength={13}
                title="Telefone deve ter no minímo 13 dígitos e no máximo 14"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>GRAU PARENTESCO</Form.Label>
              <Form.Control
                value={grauParentesco}
                onChange={(event) => {
                  event.preventDefault();
                  setGrauParentesco(soLetras(event.target.value));
                  if (soLetras(event.target.value)) {
                    setIsGrauParentValid(true);
                  } else {
                    setIsGrauParentValid(false);
                  }
                }}
                name="grauParentesco"
                maxLength={32}
                type="text"
                placeholder=""
                title="Não são aceitos números ou caracteres especiais"
              />
              {!isGrauParentValid && grauParentesco.length >= 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Grau de Parentesco inválido
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
              <hr />
            </Form.Group>
          </Form.Row>

          <ButtonsForm canEdit={canEdit} />
        </Form>
      </div>
    </div>
  );
}

export default AtualizarCondutor;
