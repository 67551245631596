/* eslint-disable */
import { useEffect, useState } from 'react';
import { Form, Row, Button, Modal, Col, Table } from 'react-bootstrap';
import { getCookieSessionData } from '../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import axios from '../../config/axiosMaquina';
import GridEditavelPontos from './gridEditavelPontos';
import { Progressao } from '../../pages/ProgressaoViagens/ProgressaoViagens';
import { ViagemTransitTime } from '../../Types/PainelLSCTypes';
import orderBy from 'lodash/orderBy';
import { isArrayBindingPattern } from 'typescript';

type ModalProps = {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  idSm: number;
};

const ModalReagendamento = ({ showModal, setShowModal, idSm }: ModalProps) => {
  // const { monitSmProgresViagPontoVO } = data;
  const dataNow = new Date().toLocaleString();
  const [mensagem, setMensagem] = useState('');
  const [pontos, setPontos] = useState<any>();
  const [monitSmProgresViagPontoVO, setMonitSmProgresViagPontoVO] = useState<any[]>([]);
  const [dataAgendamento, setDataAgendamento] = useState([]);
  const { nome, usuarioId } = getCookieSessionData().usuarioVO;
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogCadOK2, setIsDialogCadOK2] = useState(false);
  const [blockUpDown, setBlockUpDown] = useState(false);
  const [currentPontos, setCurrentPontos] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);

  const loadPontos = async () => {
    try {
      const response = await axios.get(
        `/solicitacao-monitoramento/lsc-lista-pontos-reagendamento?idSm=${idSm}&idUsuario=${usuarioId}`
      );
      if (response.data[0].monitSmProgresViagPontoReagendadosVO.length) {
        setMonitSmProgresViagPontoVO(
          orderBy(response.data[0].monitSmProgresViagPontoReagendadosVO, 'numSeqPonto')
        );
        setIsUpdate(true);
      } else {
        setMonitSmProgresViagPontoVO(
          orderBy(response.data[0].monitSmProgresViagPontoVO, 'numSeqPonto')
        );
        setIsUpdate(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (showModal) {
      loadPontos();
    }
  }, [showModal]);

  const diminuir = async (index: number) => {
    const pontosAtualizados = currentPontos.map((cp: any, i: number) => {
      if (index === i) {
        return { ...cp, numSeqPonto: cp.numSeqPonto + 1 };
      }
      if (index + 1 === i) {
        return { ...cp, numSeqPonto: cp.numSeqPonto - 1 };
      } else {
        return cp;
      }
    });
    setCurrentPontos(orderBy(pontosAtualizados, 'numSeqPonto'));
  };

  const acrescentar = (index: number) => {
    console.log('currentPontos', currentPontos);
    const pontosAtualizados = currentPontos.map((cp: any, i: number) => {
      if (index === i) {
        return { ...cp, numSeqPonto: cp.numSeqPonto - 1 };
      }
      if (index - 1 === i) {
        return { ...cp, numSeqPonto: cp.numSeqPonto + 1 };
      } else {
        return cp;
      }
    });
    setCurrentPontos(orderBy(pontosAtualizados, 'numSeqPonto'));
  };

  const lancarAgendamentoViagem = async () => {
    if (!data.length) return;

    if (!isUpdate) {
      try {
        await axios.post('/solicitacao-monitoramento/lsc-reagendar', data);
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        await axios.put('/solicitacao-monitoramento/lsc-reagendar-atualizar', data);
      } catch (err) {
        console.error(err);
      }
    }
    window.location.reload();
  };

  useEffect(() => {
    let arr: any[] = [];
    if (!isUpdate) {
      currentPontos.forEach((d: any) => {
        arr.push({
          dataPlanejada: d.dataHora.length ? d.dataHora : null,
          idPlanoViagem: d.idPontoViagem,
          sequencia: d.numSeqPonto,
        });
      });
    } else {
      currentPontos.forEach((d: any) => {
        arr.push({
          dataPlanejada: d.dataHora.length ? d.dataHora : null,
          idPlanoViagem: d.idPontoViagem,
          sequencia: d.numSeqPonto,
          idPontoReagendado: d.idPontoViagemReplanejada,
        });
      });
    }
    setData(arr);
  }, [currentPontos, isUpdate]);

  const onPartidaHoraChange = (
    idPlanoViagem: number,
    data: string,
    hora: string,
    dataHora: string
  ) => {
    const reagendado = currentPontos.map((d: any) => {
      if (idPlanoViagem === d.idPontoViagem) {
        return { ...d, data: data, hora: hora, dataHora: dataHora };
      }
      return d;
    });
    setCurrentPontos(reagendado);
  };

  useEffect(() => {
    !currentPontos.length &&
      setCurrentPontos(
        monitSmProgresViagPontoVO.map((m: any) => {
          return { ...m, data: '', hora: '', dataHora: '' };
        })
      );
  }, [monitSmProgresViagPontoVO]);

  const findFirstIndex = () => {
    let i = 0;
    currentPontos.find((d: any, index) => {
      if (d.percPercorridoPonto === 0) {
        return (i = index);
      }
    });
    return i;
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Reagendamento de entregas</Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ paddingLeft: '50px', paddingRight: '50px' }}>
        <Dialog
          header={mensagem}
          footer={
            <Button
              onClick={() => {
                setIsDialogCadOK(false);
                setShowModal(false);
              }}
            >
              Ok
            </Button>
          }
          visible={isDialogCadOK}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK(false);
            setShowModal(false);
          }}
        />
        <Dialog
          header={mensagem}
          footer={
            <Button
              onClick={() => {
                setIsDialogCadOK2(false);
              }}
            >
              Ok
            </Button>
          }
          visible={isDialogCadOK2}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK2(false);
          }}
        />

        <Table style={{ marginTop: '30px' }}>
          <thead>
            <tr>
              <th>Ponto</th>
              <th>Número Remessa</th>
              <th>Agendamento</th>
              <th>Chegada</th>
              <th>Hora</th>
            </tr>
          </thead>
          <tbody>
            {currentPontos &&
              currentPontos.map((each: any, index: number) => (
                <GridEditavelPontos
                  key={index}
                  each={each}
                  diminuir={diminuir}
                  onPartidaHoraChange={onPartidaHoraChange}
                  acrescentar={acrescentar}
                  index={index}
                  findFirstIndex={findFirstIndex}
                  lastIndex={currentPontos.length - 1}
                />
              ))}
            {/* {data && data.map((each) => (
                      <tr>
                        <td>{each.nomePonto}</td>
                        <td>{each.numeroRemessa}</td>
                        <td>{each.dataAgendamento}</td>
                        <td> {each.partida} </td>
                        <td> {each.horaPartida} </td>
                        <td>
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              width: 20,
                              height: 20,
                              padding: 0,
                              marginRight: 15,
                              marginBottom: 2,
                            }}
                          >
                            <i
                              title="Subir posição"
                              onClick={() => {
                                // atualizacaoSeq1(
                                //   filteredParada.sequencia - 1,
                                //   filteredParada.sequencia,
                                //   filteredParada.id
                                // );
                                // subtrairPosicao(filteredParada.sequencia, filteredParada.id);
                              }}
                              className="pi pi-chevron-up"
                            ></i>
                          </button>
                        </td>
                        <td>
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              width: 20,
                              height: 20,
                              padding: 0,
                              marginRight: 15,
                              marginBottom: 2,
                            }}
                          >
                            <i
                              title="Descer posição"
                              onClick={() => {
                                // atualizacaoSeq2(
                                //   filteredParada.sequencia + 1,
                                //   filteredParada.sequencia,
                                //   filteredParada.id
                                // );

                                somarPosicao(each.numSeqPonto, each.idPontoViagem);
                              }}
                              className="pi pi-chevron-down"
                            ></i>
                          </button>
                        </td>
                        <td>
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              width: 20,
                              height: 20,
                              padding: 0,
                              marginRight: 15,
                              marginBottom: 2,
                            }}
                          >
                            <i
                              title="Deletar parada"
                              key={each.idPontoViagem}
                              onClick={() => {
                                // setParada(filteredParada.id);
                                // setShow(true);
                                // setNomeParada(filteredParada.local);
                              }}
                              className="pi pi-trash"
                            ></i>
                          </button>
                        </td>
                        <td>
                          <button
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              width: 20,
                              height: 20,
                              padding: 0,
                              marginRight: 15,
                              marginBottom: 2,
                            }}
                          >
                            <i
                              title="Editar parada"
                              key={each.idPontoViagem}
                              // onClick={() => paradaEditar(filteredParada.id)}
                              className="pi pi-pencil"
                            ></i>
                          </button>
                        </td>
                      </tr>
                    ))} */}
          </tbody>
        </Table>
      </Modal.Body>

      <Form.Row className="container-buttons justify-content-end">
        <Form.Group as={Col} md={2}>
          <Button
            className="btn-cancelar"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Button
            className="btn-enviar"
            onClick={() => lancarAgendamentoViagem()}
            style={{ fontSize: '15px' }}
          >
            Salvar Reagendamento
          </Button>
        </Form.Group>
      </Form.Row>
    </Modal>
  );
};
export default ModalReagendamento;
