import React from 'react';
import ReactExport from 'react-data-export';
import { Button } from 'react-bootstrap';
import { GridViolacao } from '../../..';
import { generateExcelData } from '../../../util/excel';

interface PropsExportExcel {
  tipoControle: number;
  data: GridViolacao[];
}

export const ExportExcelVelocidade = ({ tipoControle, data }: PropsExportExcel) => {
  const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

  const transformedData = generateExcelData(data, tipoControle);

  return (
    <ExcelFile
      element={<Button style={{ height: '40px' }}>Exportar Excel</Button>}
      filename="PrevencaoAcidentesVelocidade"
    >
      <ExcelSheet data={transformedData} name="Prevenção_Acidentes_Ambos">
        <ExcelColumn label="Data" value="data" />
        <ExcelColumn label="Placa" value="placa" />
        <ExcelColumn label="Velocidade Apurada" value="velocidade" />
        <ExcelColumn label="Velocidade Configurada (Máx)" value="velocidadeMaxima" />
        <ExcelColumn label="Localização" value="localizacao" />
        <ExcelColumn label="Violação" value="violacao" />
      </ExcelSheet>
    </ExcelFile>
  );
};
