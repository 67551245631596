import React, { FC, useEffect, useState } from 'react';
import { Col, Form, Button as Button2, Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import LimiteCarga from './LimiteCarga';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import { EmbarcadorPiso } from '../../../api/model/EmbarcadorDadosSMType';
import { isAllowedByRole } from '../../../services/cookieService';

interface ViewProps {
  id: number;
  showEdit: boolean;
  setShowView: (show: boolean) => void;
  canEdit: boolean;
  canInsert: boolean;
}

interface Data {
  idEmbarcador: number | null;
  transferencia: any;
  distribuicao: any;
  coleta: any;
  exportacao: any;
  devolucao: any;
}

const initialTransferencia: any = {
  valorLimiteCarga: 0,
  criticarMarcasIguais: false,
  pisos: Array(5).fill({
    valorPiso: 0,
    primeiroValor: '',
    primeiraCondicao: '',
    segundoValor: '',
    segundaCondicao: '',
    terceiroValor: '',
    terceiraCondicao: '',
    quartoValor: '',
  }),
};

export const EmbarcadorViewNew: FC<ViewProps> = ({
  id,
  showEdit,
  setShowView,
  canEdit,
  canInsert,
}) => {
  const [embarcadorSemCadastroDeValores, setEmbarcadorSemCadastroDeValores] = useState(false);
  const [parameters, setParameters] = useState<Data>({
    idEmbarcador: null,
    transferencia: initialTransferencia,
    distribuicao: initialTransferencia,
    coleta: initialTransferencia,
    exportacao: initialTransferencia,
    devolucao: initialTransferencia,
  });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    setParameters((prevState) => ({
      ...prevState,
      idEmbarcador: id,
    }));

    async function loadData() {
      try {
        const response = await axios.get(`/embarcador/parametros/find/${id}`);
        console.log('-------------', response.data);
        setParameters(response.data);
        console.log(parameters);

        setEmbarcadorSemCadastroDeValores(false);
      } catch (err: any) {
        console.log(err.response);
        if (err.response.status === 424) {
          setEmbarcadorSemCadastroDeValores(true);
        } else {
          setEmbarcadorSemCadastroDeValores(false);
        }
      }
    }
    loadData();
  }, [id]);

  const handlePisosChange = (type: keyof Data, pisos: EmbarcadorPiso[]) => {
    setParameters((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], pisos },
    }));
  };

  const handleValorLimiteCargaChange = (type: keyof Data, valor: number) => {
    setParameters((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], valorLimiteCarga: valor },
    }));
  };

  const handleCriticarMarcasIguaisChange = (type: keyof Data, value: boolean) => {
    setParameters((prevState) => ({
      ...prevState,
      [type]: { ...prevState[type], criticarMarcasIguais: value },
    }));
  };

  const isEmptyPiso = (piso: EmbarcadorPiso) => {
    return (
      piso.valorPiso === 0 &&
      piso.primeiroValor === '' &&
      piso.primeiraCondicao === '' &&
      piso.segundoValor === '' &&
      piso.segundaCondicao === '' &&
      piso.terceiroValor === '' &&
      piso.terceiraCondicao === '' &&
      piso.quartoValor === ''
    );
  };

  const cleanEmptyPisos = (data: Data) => {
    const types = ['transferencia', 'distribuicao', 'coleta', 'exportacao', 'devolucao'] as const;
    types.forEach((type) => {
      // eslint-disable-next-line no-param-reassign
      data[type].pisos = data[type].pisos.filter((piso: EmbarcadorPiso) => !isEmptyPiso(piso));
    });
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const data = { ...parameters };
    cleanEmptyPisos(data);

    console.log('-----------------', data, id);
    console.log(embarcadorSemCadastroDeValores);

    try {
      if (embarcadorSemCadastroDeValores) {
        await axios.post('/embarcador-limite-transporte/salvar', data);
      } else {
        await axios.put('/embarcador/parametros/save', data);
      }
      setIsDialogCadOK(true);
    } catch (err: any) {
      console.log(err.response);
      addToast({
        title: 'Erro',
        description: 'Erro ao editar!',
        type: 'error',
      });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header={
          embarcadorSemCadastroDeValores
            ? 'Limites de Transporte cadastrado com sucesso!'
            : 'Limites de Transporte alterados com sucesso!'
        }
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setShowView(false);
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Limites de Transporte</h2>
          {isAllowedByRole(['user']) && (
            <h6 className="subtitulo">{'Cadastro > Regras de Apólice'}</h6>
          )}
          {isAllowedByRole(['admin']) && (
            <h6 className="subtitulo">
              {'Cadastro > Gerenciamento de Clientes > Regras de Apólice'}
            </h6>
          )}
        </div>

        <Form className="form-responsive" onSubmit={(e) => onSubmit(e)}>
          <LimiteCarga
            disabled={isAllowedByRole(['user'])}
            type="transferencia"
            pisos={parameters.transferencia.pisos}
            setPisos={(pisos) => handlePisosChange('transferencia', pisos)}
            valorLimiteCarga={parameters.transferencia.valorLimiteCarga}
            setValorLimiteCarga={(valor) => handleValorLimiteCargaChange('transferencia', valor)}
            criticarMarcasIguais={parameters.transferencia.criticarMarcasIguais}
            setCriticarMarcasIguais={(value) =>
              handleCriticarMarcasIguaisChange('transferencia', value)
            }
          />
          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>
          <LimiteCarga
            disabled={isAllowedByRole(['user'])}
            type="distribuicao"
            pisos={parameters.distribuicao.pisos}
            setPisos={(pisos) => handlePisosChange('distribuicao', pisos)}
            valorLimiteCarga={parameters.distribuicao.valorLimiteCarga}
            setValorLimiteCarga={(valor) => handleValorLimiteCargaChange('distribuicao', valor)}
            criticarMarcasIguais={parameters.distribuicao.criticarMarcasIguais}
            setCriticarMarcasIguais={(value) =>
              handleCriticarMarcasIguaisChange('distribuicao', value)
            }
          />
          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>
          <LimiteCarga
            disabled={isAllowedByRole(['user'])}
            type="coleta"
            pisos={parameters.coleta.pisos}
            setPisos={(pisos) => handlePisosChange('coleta', pisos)}
            valorLimiteCarga={parameters.coleta.valorLimiteCarga}
            setValorLimiteCarga={(valor) => handleValorLimiteCargaChange('coleta', valor)}
            criticarMarcasIguais={parameters.coleta.criticarMarcasIguais}
            setCriticarMarcasIguais={(value) => handleCriticarMarcasIguaisChange('coleta', value)}
          />
          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>
          <LimiteCarga
            disabled={isAllowedByRole(['user'])}
            type="exportacao"
            pisos={parameters.exportacao.pisos}
            setPisos={(pisos) => handlePisosChange('exportacao', pisos)}
            valorLimiteCarga={parameters.exportacao.valorLimiteCarga}
            setValorLimiteCarga={(valor) => handleValorLimiteCargaChange('exportacao', valor)}
            criticarMarcasIguais={parameters.exportacao.criticarMarcasIguais}
            setCriticarMarcasIguais={(value) =>
              handleCriticarMarcasIguaisChange('exportacao', value)
            }
          />
          <Form.Row>
            <Form.Group as={Col} controlId="line">
              <hr />
            </Form.Group>
          </Form.Row>
          <LimiteCarga
            disabled={isAllowedByRole(['user'])}
            type="devolucao"
            pisos={parameters.devolucao.pisos}
            setPisos={(pisos) => handlePisosChange('devolucao', pisos)}
            valorLimiteCarga={parameters.devolucao.valorLimiteCarga}
            setValorLimiteCarga={(valor) => handleValorLimiteCargaChange('devolucao', valor)}
            criticarMarcasIguais={parameters.devolucao.criticarMarcasIguais}
            setCriticarMarcasIguais={(value) =>
              handleCriticarMarcasIguaisChange('devolucao', value)
            }
          />

          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col} md={2}>
              <Button2
                className="btn-cancelar"
                onClick={() => {
                  setShowView(false);
                }}
              >
                Cancelar
              </Button2>
            </Form.Group>
            {isAllowedByRole(['admin']) && (
              <Form.Group as={Col} md={2}>
                <Button2 className="btn-enviar" type="submit">
                  Confirmar
                </Button2>
              </Form.Group>
            )}
          </Form.Row>
          {/* <ButtonsForm canEdit={canEdit || canInsert} /> */}
        </Form>
      </div>
    </div>
  );
};

export default EmbarcadorViewNew;
