/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { GoInfo } from 'react-icons/go';
import { useToast } from '../../../hooks/Toast';
import axios from '../../../config/axiosMaquina';
import { UfIbgeType } from '../../../api/model/UfIbgeType';
import { OrgaoEmissorTypes } from '../../../api/Types/orgaoEmissorTypes';
import { MunicipiosTypes } from '../../../api/Types/municipiosTypes';
import checaCPF from '../../../util/checaCpf';
import {
  maskTel,
  maskCep,
  soLetras,
  soNumeros,
  cpfMask,
  validaNome,
} from '../../../util/Validacoes/Validacoes';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { getCookieSessionData } from '../../../services/cookieService';
import CadCondutor from '../../scriptCase/CadCondutor';
import { CondutorTypes } from '../../../api/Types/condutorTypes';
import CnhFrente from '../../../assets/CnhFrente.jpg';
import CnhVerso from '../../../assets/CnhVerso.jpg';
import { ProfilePicture } from '../../../components/ProfilePicture';
import { Tela } from '../../../components/Tela';
import { obterDataHora, nowToZoned } from '../../../util/date';

function CadastroCondutor() {
  const { addToast } = useToast();
  const [cpf, setCpf] = useState('');
  const [atualizarPesquisaAutomaticamente, setAtualizarPesquisaAutomaticamente] = useState(false);
  const [nome, setNome] = useState('');
  const [foto, setFoto] = useState('');
  const [tipo, setTipo] = useState('');
  const [sexo, setSexo] = useState('');
  const [rg, setRg] = useState('');
  const [emissaoRg, setEmissaoRg] = useState('');
  const [cnhRegistro, setCnhRegistro] = useState();
  const [categoriaCnh, setCategoriaCnh] = useState('');
  const [validadeCnh, setValidadeCnh] = useState('');
  const [segurancaCnh, setSegurancaCnh] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [cidadeNascimento, setCidadeNascimento] = useState(0);
  const [nomePai, setNomePai] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [segundoTelefone, setSegundoTelefone] = useState('');
  const [referenciaTelefone, setReferenciaTelefone] = useState('');
  const [referenciaNome, setReferenciaNome] = useState('');
  const [grauParentesco, setGrauParentesco] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [logradouroNumero, setLogradouroNumero] = useState();
  const [complementoLogradouro, setComplementoLogradouro] = useState('');
  const [bairro, setBairro] = useState('');
  const [uf, setUf] = useState<number | undefined>();
  const [cidade, setCidade] = useState<number | undefined>();
  const [dataCadastro, setDataCadastro] = useState(obterDataHora(new Date().toISOString()));
  const [municipiosPorEstado, setMunicipiosPorEstado] = useState<MunicipiosTypes[]>([]);
  const [cidadesNascimentoPorEstado, setCidadesNascimentoPorEstado] = useState<MunicipiosTypes[]>(
    []
  );
  const [ufSiglas, setUfSiglas] = useState<UfIbgeType[]>([]);
  const [statusApto, setStatusApto] = useState(true);
  const [orgaoEmissor, setOrgaoEmissor] = useState(0);
  const [orgaosEmissores, setOrgaosEmissores] = useState<OrgaoEmissorTypes[]>([]);
  const [idUfEmissorRg, setIdUfEmissorRg] = useState(0);
  const [idUfEmissorCnh, setIdUfEmissorCnh] = useState(0);
  const [idUfNascimento, setIdUfNascimento] = useState(0);
  const [error, setError] = useState(false);
  const [erroValidadeCnh, setErroValidadeCnh] = useState(false);
  const [erroDataEmissaoRg, setErroDataEmissaoRg] = useState(false);
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [cpfErro, setCpfErro] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isNomeValid, setIsNomeValid] = useState(false);
  const [isNomeMaeValid, setIsNomeMaeValid] = useState(false);
  const [isNomePaiValid, setIsNomePaiValid] = useState(false);
  const [isGrauParentValid, setIsGrauParentValid] = useState(true);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [validacaoRg, setValidacaoRg] = useState(false);
  const [cnhJaUsado, setCnhJaUsado] = useState(false);
  const [cnhInvalido, setCnhInvalido] = useState(false);
  const [cpfInativo, setCpfInativo] = useState(false);
  const history = useHistory();
  const { fromSMCadastro }: any = useParams();
  const [model, setModel] = useState<CondutorTypes>({
    id: '',
    cpf: '',
    atualizarPesquisaAutomaticamente: false,
    nome: '',
    foto: '',
    tipo: '',
    sexo: '',
    rg: '',
    emissaoRg,
    idUfEmissorRg: 0,
    cnhRegistro: 0,
    categoriaCnh: '',
    validadeCnh,
    segurancaCnh: 0,
    idUfEmissorCnh: 0,
    idUfNascimento: 0,
    ativo: false,
    cliente: {
      id: clienteId,
      razaoSocial: '',
    },
    dataNascimento,
    orgaoEmissor: {
      id: 0,
      documentoTipo: '',
      nome: '',
      ativo: false,
    },
    cidadeNascimento: '',
    nomePai: '',
    nomeMae: '',
    segundoTelefone: '',
    referenciaTelefone: '',
    referenciaNome: '',
    grauParentesco: '',
    telefone: '',
    cep: 0,
    logradouro: '',
    logradouroNumero: 0,
    complementoLogradouro: '',
    bairro: '',
    uf: '',
    cidade: '',
    dataAtual: new Date(),
  });

  const [isDialogVisibleRecuperaDados, setIsDialogVisibleRecuperaDados] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [dadosRecuperados, setDadosRecuperados] = useState(false);
  const [dataNascimentoMudou, setDataNascimentoMudou] = useState(true);
  const [dataEmissaoMudou, setDataEmissaoMudou] = useState(true);
  const [dataValidadeCnhMudou, setDataValidadeCnhMudou] = useState(true);

  function updateModel(response: any) {
    setModel({
      id: response.id,
      cpf: response.cpf,
      atualizarPesquisaAutomaticamente: response.atualizarPesquisaAutomaticamente,
      nome: response.nome,
      foto: response.foto,
      tipo: response.tipo,
      sexo: response.sexo,
      rg: response.rg,
      emissaoRg: response.emissaoRg,
      idUfEmissorRg: response.idUfEmissorRg,
      cnhRegistro: response.cnhRegistro,
      categoriaCnh: response.categoriaCnh,
      validadeCnh: response.validadeCnh,
      segurancaCnh: response.segurancaCnh,
      idUfEmissorCnh: response.idUfEmissorCnh,
      cliente: response.cliente,
      ativo: response.ativo,
      dataNascimento: response.dataNascimento,
      orgaoEmissor: {
        ...model.orgaoEmissor,
        id: response.orgaoEmissor,
      },
      cidadeNascimento: response.cidadeNascimento,
      idUfNascimento: response.idUfNascimento,
      nomePai: response.nomePai,
      nomeMae: response.nomeMae,
      nomeCidade: response.nomeCidade,
      segundoTelefone: response.segundoTelefone,
      referenciaTelefone: response.referenciaTelefone,
      referenciaNome: response.referenciaNome,
      grauParentesco: response.grauParentesco,
      telefone: response.telefone,
      cep: response.cep,
      logradouro: response.logradouro,
      logradouroNumero: response.logradouroNumero,
      complementoLogradouro: response.complementoLogradouro,
      bairro: response.bairro,
      uf: response.uf,
      cidade: response.cidade,
      dataAtual: response.dataAtual,
    });
    loadCidadesNascimentoPorEstado(response.idUfNascimento);
  }

  useEffect(() => {
    if (cpfInativo) {
      return;
    }
    if (cpf.length === 14) {
      const validaCpfExistente = async () => {
        const data = {
          cpfCondutor: cpf,
        };

        try {
          const response = await axios.post(`/condutor/existeCPF/${clienteId}`, data);

          if (response.data === true) {
            addToast({
              title: 'Erro',
              description: 'O CPF deste condutor já está cadastrado!',
              type: 'error',
            });
            return;
          }
          setCpf(cpf);
        } catch (err: any) {
          console.log(err);
        }
      };
      validaCpfExistente();
    }
  }, [cpf]); // Valida CPF Existente

  useEffect(() => {
    const validaCnhExistente = async () => {
      try {
        const response = await axios.get(`/condutor/verificar-condutor-por-cnh`, {
          params: {
            idCliente: clienteId,
            cnh: cnhRegistro,
          },
        });
        setCnhJaUsado(response.data);
        if (response.data === true) {
          addToast({
            title: 'Erro',
            description: 'O CNH deste condutor já está cadastrado!',
            type: 'error',
          });
          return;
        }
      } catch (err: any) {
        console.log(err);
      }
    };
    validaCnhExistente();
  }, [cnhRegistro]); // Valida CNH Existente

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(`/condutor/verificaCPF/${clienteId}?cpfCondutor=${cpf}`);
      if (response.data === false) {
        setCpfInativo(true);
      } else {
        setCpfInativo(false);
      }
    };
    fetchData();
  }, [cpf]); // Verifica CPF Ativo

  useEffect(() => {
    const recuperaDadosPeloCpf = async () => {
      const response = await axios.get(
        `/condutor/reutilizarPorCpf?clienteId=${clienteId}&cpfCondutor=${cpf}`
      );
      updateModel(response.data);

      if (response.data.cpf) {
        setIsDialogVisibleRecuperaDados(true);
      } else {
        setIsDialogVisibleRecuperaDados(false);
        const recuperaDadosPeloRg = async () => {
          const response2 = await axios.get(
            `/condutor/reutilizarPorRg?clienteId=${clienteId}&rgCondutor=${rg}`
          );
          updateModel(response2.data);
          if (response2.data.rg) {
            setIsDialogVisibleRecuperaDados(true);
          } else {
            setIsDialogVisibleRecuperaDados(false);
          }
        };
        recuperaDadosPeloRg();
      }
    };
    recuperaDadosPeloCpf();
  }, [cpf, rg]); // Recuperar Dados Pelo CPF

  const verificaCpfBlackList = async () => {
    if (cpf.length < 14) {
      return;
    }
    try {
      const response = await axios.get('/blacklist/verificarCpf', {
        params: {
          cpf,
        },
      });

      if (response.data) {
        setCpfErro(true);
      } else {
        setCpfErro(false);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const validaRgExistente = async (value: any) => {
    const data = {
      rgCondutor: value,
    };

    try {
      const response = await axios.post(`/condutor/existeRG/${clienteId}`, data);

      if (response.data === true) {
        setValidacaoRg(true);
        addToast({
          title: 'Erro',
          description: 'O RG deste condutor já está cadastrado!',
          type: 'error',
        });
        return;
      }
      setValidacaoRg(false);
      setRg(rg);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (dadosRecuperados === false) {
      if (erroValidadeCnh) {
        addToast({
          title: 'CNH Inválida',
          description: 'Insira uma CNH válida!',
          type: 'error',
        });
        setErroValidadeCnh(true);
        event.stopPropagation();
        return;
      }
      if (
        !cpf ||
        !nome ||
        !CadCondutor ||
        rg.length < 4 ||
        rg.length > 16 ||
        !idUfEmissorRg ||
        !cnhRegistro ||
        !categoriaCnh ||
        !segurancaCnh ||
        segurancaCnh.toString().length != 11 ||
        !cep ||
        !logradouro ||
        !bairro ||
        !cidade ||
        logradouroNumero === null ||
        !uf ||
        !dataNascimento ||
        erroDataNascimento ||
        !idUfNascimento ||
        !cidadeNascimento ||
        !nomePai ||
        !nomeMae ||
        !isValid ||
        !isNomePaiValid ||
        !isNomeValid ||
        !isNomeMaeValid ||
        !idUfEmissorCnh ||
        !telefone ||
        !tipo ||
        !(telefone.length >= 13 && telefone.length <= 14) ||
        cnhJaUsado ||
        !validadeCnh ||
        cnhInvalido
      ) {
        addToast({
          title: 'Erro',
          description: 'Verifique os dados preenchidos!',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    } else if (
      !model.cpf ||
      !model.nome ||
      !model.rg ||
      !model.idUfEmissorRg ||
      !model.cnhRegistro ||
      !model.categoriaCnh ||
      !model.segurancaCnh ||
      model.segurancaCnh.toString().length !== 11 ||
      !model.cep ||
      !model.logradouro ||
      !model.bairro ||
      !model.cidade ||
      model.logradouroNumero === null ||
      !model.uf ||
      !model.dataNascimento ||
      !model.idUfNascimento ||
      !model.cidadeNascimento ||
      !model.nomePai ||
      !model.nomeMae ||
      !model.idUfEmissorCnh ||
      !model.telefone
    ) {
      // alert('Verifique os dados preenchidos.');
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    if (validacaoRg === true) {
      // alert('O Rg desse condutor já existe.');
      addToast({
        title: 'Erro',
        description: 'O RG deste condutor já existe!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    const dados = {
      cpf: cpf || model.cpf,
      atualizarPesquisaAutomaticamente,
      nome: nome || model.nome,
      foto: foto || model.foto,
      tipo: tipo || model.tipo,
      sexo: sexo || model.sexo,
      rg: rg || model.rg,
      emissaoRg: emissaoRg || model.emissaoRg,
      idUfEmissorRg: idUfEmissorRg || model.idUfEmissorRg,
      cnhRegistro: cnhRegistro || model.cnhRegistro,
      categoriaCnh: categoriaCnh || model.categoriaCnh,
      validadeCnh: validadeCnh || model.validadeCnh,
      segurancaCnh: segurancaCnh || model.segurancaCnh,
      idUfEmissorCnh: idUfEmissorCnh || model.idUfEmissorCnh,
      cliente: clienteId,
      ativo: ativo || model.ativo,
      dataNascimento: dataNascimento || model.dataNascimento,
      orgaoEmissor: orgaoEmissor || model.orgaoEmissor.id,
      cidadeNascimento: cidadeNascimento || model.cidadeNascimento,
      idUfNascimento: idUfNascimento || model.idUfNascimento,
      nomePai: nomePai || model.nomePai,
      nomeMae: nomeMae || model.nomeMae,
      segundoTelefone: segundoTelefone
        ? segundoTelefone.replace('(', '').replace(')', '').replace('-', '')
        : model.segundoTelefone
        ? model.segundoTelefone
        : '',
      referenciaTelefone: referenciaTelefone
        ? referenciaTelefone.replace('(', '').replace(')', '').replace('-', '')
        : model.referenciaTelefone
        ? model.referenciaTelefone
        : '',
      referenciaNome: referenciaNome || model.referenciaNome,
      grauParentesco: grauParentesco || model.grauParentesco,
      telefone: telefone
        ? telefone.replace('(', '').replace(')', '').replace('-', '')
        : model.telefone
        ? model.telefone
        : '',
      cep: cep || model.cep,
      logradouro: logradouro || model.logradouro,
      logradouroNumero: logradouroNumero || model.logradouroNumero,
      complementoLogradouro: complementoLogradouro || model.complementoLogradouro,
      bairro: bairro || model.bairro,
      uf: uf || model.uf,
      cidade: cidade || model.cidade,
      dataCadastro: nowToZoned(),
      dataUltimaAlteracao: nowToZoned(),
      bloqueado: statusApto,
    };

    try {
      if (dadosRecuperados) {
        await axios.put(`/condutor/${model.id}`, dados);
        setIsDialogVisibleCadEfetuado(true);
      } else {
        await axios.post('/condutor', dados).then(() => {
          setIsDialogVisibleCadEfetuado(true);
          if (fromSMCadastro) {
            window.close();
          }
        });
      }
    } catch (err: any) {
      console.log(err);
      // if (
      //   err.response.data.message ===
      //     'could not execute statement; SQL [n/a]; constraint [null]; nested exception is org.hibernate.exception.ConstraintViolationException: could not execute statement' ||
      //   err.response.data.message.includes(
      //     'could not execute statement; SQL [n/a]; constraint [ak_condutor]; nested exception is org.hibernate.exception.ConstraintViolationException: could not execute statement'
      //   )
      // ) {
      //   // alert('Cpf do condutor já está cadastrado.');
      //   addToast({
      //     title: 'Erro',
      //     description: 'O CPF deste condutor já está cadastrado!',
      //     type: 'error',
      //   });
      //   return;
      // }
      if (err.response.data.message === 'O CPF informado está cadastrado na BlackList') {
        // alert('Condutor cadastrado na Blacklist');
        addToast({
          title: 'Erro',
          description: 'Condutor cadastrado na Blacklist!',
          type: 'error',
        });
        return;
      }
      if (err.response.data.message === 'Insira uma CNH valida') {
        addToast({
          title: 'Validade da CNH expirou',
          description: 'Não é possível cadastrar condutor com CNH vencida!',
          type: 'error',
        });
        return;
      }
      if (err.response.data.message === 'O CPF do condutor já está cadastrado!') {
        addToast({
          title: 'Erro',
          description: 'O CPF deste condutor já está cadastrado!',
          type: 'error',
        });
        return;
      }
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar!',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    axios.get('/uf/ListarIdSiglaEstadoPais/1').then((response: any) => {
      setUfSiglas(response.data);
    });
  }, []); // setUfSiglas

  const loadMunicipiosPorEstado = async (estado: any) => {
    try {
      if (estado === '' || !estado) return;

      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const loadCidadesNascimentoPorEstado = async (estado: any) => {
    try {
      if (estado === '' || !estado) return;

      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setCidadesNascimentoPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const loadData = () => {
      axios.get('/orgao-emissor/getIdNome').then((response: any) => {
        setOrgaosEmissores(response.data);
      });
    };
    loadData();
  }, []); // setOrgaosEmissores

  const dataLimiteMaiorIdade = () => {
    const current = new Date();
    const maxDate = new Date(
      current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
    );
    return maxDate;
  };

  const maxDateFormatadoMaior18 = () => {
    const current = new Date();
    const maxDate = new Date(
      current.getTime() - 1000 * 60 * 60 * 24 * 365 * 18 - 1000 * 60 * 60 * 24 * 5
    );
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const validaDataNascimento = (value: any) => {
    const nasc = new Date(value);
    if (nasc.getTime() < dataLimiteMaiorIdade().getTime()) {
      setDataNascimento(value);
      setErroDataNascimento(false);
    } else {
      setDataNascimento(value);
      setErroDataNascimento(true);
    }
  };

  const validaDataMaxRG = () => {
    const current = new Date();
    const maxDate = new Date(current.getTime() - 1000 * 60 * 60 * 24 * 365 * 100);
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const validaDataAtualVencimento = () => {
    const current = new Date();
    const maxDate = new Date(current.getTime());
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const validaDataAtualRegistrado = () => {
    const current = new Date();
    const maxDate = new Date(current.getTime());
    const maxDateFormatado = `${maxDate.getFullYear().toString()}-${(maxDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${maxDate.getDate().toString().padStart(2, '0')}`;
    return maxDateFormatado;
  };

  const validaDataMax = (value: any) => {
    const dataAtual = new Date(new Date().setHours(23, 59, 59, 999));
    const dataValidade = new Date(value.concat(' 23:59:59.999'));
    if (dataAtual > dataValidade) {
      setValidadeCnh(value);
      setErroValidadeCnh(true);
    } else {
      setValidadeCnh(value);
      setErroValidadeCnh(false);
    }
  };

  const validaDataMin = (value: any) => {
    const dataAtual = new Date();
    if (dataAtual.getTime() <= new Date(value).getTime()) {
      setEmissaoRg(value);
      setErroDataEmissaoRg(true);
    } else {
      setEmissaoRg(value);
      setErroDataEmissaoRg(false);
    }
  };

  useEffect(() => {
    const buscarCep = () => {
      if (cep.length < 9) {
        setCidade(0);
        setLogradouro('');
        setBairro('');
        return;
      }
      setError(false);
      fetch(`https://viacep.com.br/ws/${cep}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }
          setLogradouro(data.logradouro);
          setBairro(data.bairro);
          setCidade(parseInt(data.ibge));
          setUf(ufSiglas.find((each: any) => each.sigla === data.uf)?.id);
          loadMunicipiosPorEstado(ufSiglas.find((each: any) => each.sigla === data.uf)?.id);
        })
        .catch((erro) => {
          console.log('error', erro);
          setError(true);
        });
    };
    buscarCep();
  }, [cep]); // buscarCep

  return (
    <Tela nome="Condutor" caminho="Cadastro > Condutor">
      <Dialog
        header="Este condutor foi excluído anteriormente. Tem certeza que deseja reativar o cadastro dele?"
        footer={
          <>
            <Button label="Não" onClick={() => setIsDialogVisibleRecuperaDados(false)} />
            <Button
              label="Sim"
              onClick={() => {
                setDadosRecuperados(true);

                setDataNascimentoMudou(false);
                setDataValidadeCnhMudou(false);
                setDataEmissaoMudou(false);

                setIsDialogVisibleRecuperaDados(false);
              }}
            />
          </>
        }
        visible={isDialogVisibleRecuperaDados}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleRecuperaDados(false)}
      />

      <Dialog
        header="Condutor foi cadastrado com sucesso!"
        footer={
          <>
            <Button label="OK" onClick={() => history.push('/condutores/listar/1')} />
          </>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleCadEfetuado(false)}
      />

      <h1 className="info-obrigatorio">*Informações obrigatórias</h1>

      <Form noValidate validated={isFormInvalid} onSubmit={handleSubmit} className="form-sm">
        <ProfilePicture cadastro setFoto={setFoto} foto={foto} name={cpf}>
          <Form.Switch style={{ marginLeft: '-30px' }}>
            <Form.Group>
              <Form.Check
                checked={atualizarPesquisaAutomaticamente}
                onChange={() => {
                  setAtualizarPesquisaAutomaticamente(!atualizarPesquisaAutomaticamente);
                }}
                name="atualizarPesquisaAutomaticamente"
                type="switch"
                id="custom-switch1"
                label="Atualizar Pesquisa Automaticamente"
              />
            </Form.Group>
          </Form.Switch>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>DATA CADASTRO</Form.Label>
              <Form.Control value={dataCadastro} id="dataCadastro" name="dataCadastro" disabled />
            </Form.Group>
          </Form.Row>
        </ProfilePicture>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">CPF</Form.Label>

            <Form.Control
              value={cpf}
              onChange={(event: any) => {
                if (checaCPF(event.target.value) === false) {
                  setCpf(cpfMask(event.target.value));
                  setIsValid(false);
                } else {
                  setCpf(cpfMask(event.target.value));
                  setIsValid(true);
                }
              }}
              id="cpf"
              name="cpf"
              placeholder=""
              required
              isInvalid={!!((cpf && !isValid) || (cpf && cpfErro))}
              title="Cpf inválido"
              maxLength={14}
              minLength={14}
              onBlur={verificaCpfBlackList}
            />
            {cpfErro && (
              <p style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>
                CPF cadastrado na blacklist
              </p>
            )}
            {!isValid && cpf.length > 1 && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Cpf inválido</p>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className="requiredField">NOME</Form.Label>
            <Form.Control
              value={nome || (dadosRecuperados ? model.nome : nome)}
              onChange={(event) => {
                event.preventDefault();
                setNome(soLetras(event.target.value));
                if (validaNome(soLetras(event.target.value))) {
                  setIsNomeValid(true);
                } else {
                  setIsNomeValid(false);
                }
              }}
              name="nome"
              placeholder=""
              required
              isInvalid={!!(nome && !isNomeValid)}
              type="text"
              title="Preencha com dois nomes ou mais"
              maxLength={256}
            />
            {!isNomeValid && nome.length > 0 && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, inserir nome e sobrenome.
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">TIPO CONDUTOR</Form.Label>
            <Form.Control
              value={tipo || (dadosRecuperados ? model.tipo : tipo)}
              onChange={(event: any) => {
                setTipo(event.target.value);
              }}
              id="tipo"
              as="select"
              required
              title="Campo necessário"
            >
              <option value="">Selecione uma opção</option>
              <option value="1">Agregado</option>
              <option value="2">Autônomo</option>
              <option value="3">Funcionário</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} md={6}>
            <Form.Label>SEXO</Form.Label>
            <Form.Control
              value={sexo || (dadosRecuperados ? model.sexo : sexo)}
              onChange={(event: any) => {
                setSexo(event.target.value);
              }}
              id="sexo"
              as="select"
            >
              {' '}
              <option value="">Selecione uma opção</option>
              <option value="F">Feminino</option>
              <option value="M">Masculino</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">RG</Form.Label>
            <Form.Control
              value={rg || (dadosRecuperados ? model.rg : rg)}
              onChange={(event) => {
                setRg(event.target.value);
              }}
              onBlur={() => validaRgExistente(rg)}
              id="rg"
              name="rg"
              type="text"
              placeholder=""
              maxLength={16}
              minLength={4}
              required
              title="Campo Obrigatório"
            />
            {validacaoRg && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                O RG deste condutor já está cadastrado!
              </p>
            )}
            <Form.Control.Feedback type="invalid">RG deve conter 9 números</Form.Control.Feedback>
          </Form.Group>

          <Form.Group as={Col} md={6}>
            <Form.Label>ORGÃO EMISSOR RG</Form.Label>
            <Form.Control
              onChange={(event: any) => {
                setOrgaoEmissor(parseInt(event.target.value));
              }}
              as="select"
              value={orgaoEmissor || (dadosRecuperados ? model.orgaoEmissor.id : orgaoEmissor)}
              title="Campo Obrigatório"
            >
              <option value="">Selecione uma opção</option>
              {orgaosEmissores.map((oE) => (
                <option value={oE.id} key={oE.id}>
                  {oE.nome}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">UF ORGÃO EMISSOR RG</Form.Label>
            <Form.Control
              value={idUfEmissorRg || (dadosRecuperados ? model.idUfEmissorRg : idUfEmissorRg)}
              onChange={(event: any) => {
                setIdUfEmissorRg(parseInt(event.target.value));
              }}
              as="select"
              required
              title="Campo Obrigatório"
            >
              <option value="">Selecione uma opção</option>
              {ufSiglas.map((uf) => (
                <option value={uf.id} key={uf.id}>
                  {uf.sigla}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label>DATA EMISSÃO RG</Form.Label>
            <Form.Control
              onChange={(event: any) => {
                setDataEmissaoMudou(true);
                validaDataMin(event.target.value);
              }}
              value={
                dataEmissaoMudou
                  ? emissaoRg
                  : dadosRecuperados && model.emissaoRg
                  ? model.emissaoRg
                  : emissaoRg
              }
              id="dataInicio"
              name="dataInicio"
              type="date"
              min={validaDataMaxRG()}
              max={validaDataAtualRegistrado()}
              isInvalid={erroDataEmissaoRg}
            />
            {erroDataEmissaoRg && (
              <p style={{ fontSize: 11, color: 'red', marginTop: 5 }}>
                Data de Emissão não pode ser maior do que a data atual.
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6} className=" ml-2">
            <Form.Label>Status</Form.Label>
            <Form.Check
              checked={!statusApto}
              onChange={() => {
                setStatusApto(!statusApto);
              }}
              name="statusApto"
              type="switch"
              id="custom-switch2"
              label="Apto para viagem"
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
            <hr />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">N REGISTRO CNH</Form.Label>
            <Tooltip target=".frente">
              <img alt="logo" src={CnhFrente} height="130px" />
            </Tooltip>
            <Button
              className="frente"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                padding: 0,
                paddingRight: 5,
                marginBottom: 5,
              }}
              type="button"
            >
              <GoInfo color="#737373" size="13px" />
            </Button>
            <Form.Control
              value={cnhRegistro || (dadosRecuperados ? model.cnhRegistro : cnhRegistro)}
              onChange={(event: any) => {
                setCnhRegistro(soNumeros(event.target.value));
                if (event.target.value.length < 11) {
                  setCnhInvalido(true);
                } else {
                  setCnhInvalido(false);
                }
              }}
              id="cnh"
              name="cnh"
              type="text"
              placeholder=""
              maxLength={11}
              minLength={11}
              title="CNH Inválida"
              isInvalid={!!(cnhRegistro && (cnhJaUsado || cnhInvalido))}
              required
            />
            {cnhJaUsado && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                O CNH deste condutor já está cadastrado
              </p>
            )}
            {!cnhJaUsado && cnhInvalido && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>CNH inválido.</p>
            )}
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">CATEGORIA CNH</Form.Label>
            <Form.Control
              value={categoriaCnh || (dadosRecuperados ? model.categoriaCnh : categoriaCnh)}
              onChange={(event: any) => {
                setCategoriaCnh(event.target.value);
              }}
              as="select"
              required
            >
              <option value="">Selecione</option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
              <option value="AB">AB</option>
              <option value="AC">AC</option>
              <option value="AD">AD</option>
              <option value="AE">AE</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">UF EMISSOR CNH</Form.Label>
            <Form.Control
              value={idUfEmissorCnh || (dadosRecuperados ? model.idUfEmissorCnh : idUfEmissorCnh)}
              onChange={(event: any) => {
                setIdUfEmissorCnh(parseInt(event.target.value));
              }}
              as="select"
              required
            >
              <option value="">Selecione uma opção</option>
              {ufSiglas.map((event) => (
                <option value={event.id} key={event.id}>
                  {event.sigla}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label className="requiredField">VALIDADE CNH</Form.Label>
            <Form.Control
              onChange={(event: any) => {
                setDataValidadeCnhMudou(true);
                validaDataMax(event.target.value);
              }}
              value={
                dataValidadeCnhMudou
                  ? validadeCnh
                  : dadosRecuperados
                  ? model.validadeCnh
                  : validadeCnh
              }
              id="dataInicio"
              name="dataInicio"
              type="date"
              min={validaDataAtualVencimento()}
              required
              isInvalid={erroValidadeCnh}
            />
            {erroValidadeCnh && (
              <p style={{ fontSize: 11, color: 'red', marginTop: 5 }}>
                Não é possivel cadastrar uma CNH vencida.
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">SEGURANÇA CNH</Form.Label>
            <Tooltip target=".verso">
              <img alt="logo" src={CnhVerso} height="130px" />
            </Tooltip>
            <Button
              className="verso"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                padding: 0,
                paddingRight: 5,
                marginBottom: 5,
              }}
              type="button"
            >
              <GoInfo color="#737373" size="13px" />
            </Button>
            <Form.Control
              value={segurancaCnh || (dadosRecuperados ? model.segurancaCnh : segurancaCnh)}
              onChange={(event: any) => {
                setSegurancaCnh(soNumeros(event.target.value));
              }}
              id="segurancaCnh"
              name="cnh"
              type="text"
              maxLength={11}
              minLength={11}
              placeholder=""
              required
              isInvalid={!!(segurancaCnh && segurancaCnh.length != 11)}
              title="Campo Obrigatório"
            />
            {segurancaCnh && segurancaCnh.length != 11 && (
              <p style={{ fontSize: 11, color: 'red', marginTop: 5 }}>
                Segurança CNH deve conter 11 dígitos.
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
            <hr />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">CEP</Form.Label>
            <Form.Control
              value={cep || (dadosRecuperados ? model.cep : cep)}
              onChange={(event) => {
                setCep(maskCep(event.target.value));
              }}
              id="cep"
              name="cep"
              type="text"
              maxLength={9}
              minLength={9}
              required
              style={{ width: '49.5%' }}
            />
            {error && <p style={{ fontSize: 11, color: 'red', marginTop: 5 }}>CEP Inválido</p>}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">UF</Form.Label>
            <Form.Control
              value={uf || (dadosRecuperados ? model.uf : uf)}
              onChange={(event: any) => {
                setUf(parseInt(event.target.value));
                loadMunicipiosPorEstado(parseInt(event.target.value));
              }}
              id="uf"
              name="uf"
              required
              as="select"
            >
              <option value="">Selecione uma opção</option>

              {ufSiglas.map((uf: any) => (
                <option value={uf.id} key={uf.id}>
                  {uf.sigla}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">CIDADE</Form.Label>
            <Form.Control
              value={cidade || (dadosRecuperados ? model.cidade : cidade)}
              onChange={(event: any) => {
                setCidade(event.target.value);
              }}
              name="cidade"
              placeholder=""
              required
              as="select"
            >
              {model.nomeCidade && dadosRecuperados && (
                <option value={model.nomeCidade}>{model.nomeCidade}</option>
              )}

              <option value="">Selecione uma opção</option>

              {municipiosPorEstado.map((filteredMunicipio) => (
                <option key={filteredMunicipio.nomeMunicipio} value={filteredMunicipio.id}>
                  {filteredMunicipio.nomeMunicipio}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">BAIRRO</Form.Label>
            <Form.Control
              value={bairro || (dadosRecuperados ? model.bairro : bairro)}
              onChange={(event) => {
                setBairro(event.target.value);
              }}
              name="bairro"
              type="text"
              placeholder=""
              required
              title="Campo Obrigatório"
              maxLength={256}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">LOGRADOURO</Form.Label>
            <Form.Control
              value={logradouro || (dadosRecuperados ? model.logradouro : logradouro)}
              onChange={(event) => {
                setLogradouro(event.target.value);
              }}
              name="logradouro"
              type="text"
              placeholder=""
              required
              title="Campo Obrigatório"
              maxLength={256}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">NÚMERO LOGRADOURO</Form.Label>
            <Form.Control
              value={
                logradouroNumero || (dadosRecuperados ? model.logradouroNumero : logradouroNumero)
              }
              onChange={(e: any) => {
                setLogradouroNumero(soNumeros(e.target.value));
              }}
              id="logradouroNumero"
              name="logradouroNumero"
              type="text"
              placeholder=""
              required
              title="Campo Obrigatório"
              maxLength={8}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>COMPLEMENTO</Form.Label>
            <Form.Control
              value={
                complementoLogradouro ||
                (dadosRecuperados ? model.complementoLogradouro : complementoLogradouro)
              }
              onChange={(event) => {
                setComplementoLogradouro(event.target.value);
              }}
              id="logradouroComplemento"
              name="logradouroComplemento"
              type="text"
              placeholder=""
              maxLength={32}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
            <hr />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="requiredField">DATA NASCIMENTO</Form.Label>
            <Form.Control
              onChange={(event: any) => {
                setDataNascimentoMudou(true);
                validaDataNascimento(event.target.value);
              }}
              id="dataInicio"
              name="dataInicio"
              type="date"
              value={
                dataNascimentoMudou
                  ? dataNascimento
                  : dadosRecuperados
                  ? model.dataNascimento
                  : dataNascimento
              }
              max={maxDateFormatadoMaior18()}
              min={validaDataMaxRG()}
              required
            />
            {erroDataNascimento && (
              <p style={{ fontSize: 11, color: 'red', marginTop: 5 }}>
                {' '}
                Não é possivel cadastrar um condutor com idade menor a 18 anos.
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6} className="requiredField">
            <Form.Label>UF NASCIMENTO</Form.Label>
            <Form.Control
              value={idUfNascimento || (dadosRecuperados ? model.idUfNascimento : idUfNascimento)}
              onChange={(event: any) => {
                setIdUfNascimento(parseInt(event.target.value));
                loadCidadesNascimentoPorEstado(parseInt(event.target.value));
              }}
              as="select"
              required
            >
              <option value="">Selecione</option>
              {ufSiglas.map((uf) => (
                <option value={uf.id} key={uf.id}>
                  {uf.sigla}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} md={6} className="requiredField">
            <Form.Label>CIDADE NASCIMENTO</Form.Label>
            <Form.Control
              as="select"
              disabled={!cidadesNascimentoPorEstado}
              value={
                cidadeNascimento || (dadosRecuperados ? model.cidadeNascimento : cidadeNascimento)
              }
              name="cidadeNascimento"
              onChange={(event: any) => {
                setCidadeNascimento(parseInt(event.target.value, 10));
              }}
              required
            >
              <option value="">Selecione uma opção</option>
              {cidadesNascimentoPorEstado.map((municipio) => (
                <option key={municipio.id} value={municipio.id}>
                  {municipio.nomeMunicipio}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">NOME PAI</Form.Label>
            <Button
              tooltip="Caso inexistente escreva no campo não declarado"
              tooltipOptions={{ position: 'bottom' }}
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                padding: 0,
                paddingRight: 5,
                marginBottom: 5,
              }}
              type="button"
            >
              <GoInfo color="#737373" size="13px" />
            </Button>
            <Form.Control
              value={nomePai || (dadosRecuperados ? model.nomePai : nomePai)}
              onChange={(event) => {
                event.preventDefault();
                setNomePai(soLetras(event.target.value));
                if (validaNome(soLetras(event.target.value))) {
                  setIsNomePaiValid(true);
                } else {
                  setIsNomePaiValid(false);
                }
              }}
              id="nomePai"
              name="nomePai"
              maxLength={256}
              type="text"
              title="São necessárias pelo menos duas palavras"
              placeholder=""
              required
              isInvalid={!!(nomePai && !isNomePaiValid)}
            />
            {!isNomePaiValid && nomePai.length > 0 && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, inserir nome e sobrenome. Caso não seja declarado, preencher como
                "Não Declarado".
              </p>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">NOME MÃE</Form.Label>
            <Button
              tooltip="Caso inexistente escreva no campo não declarado"
              tooltipOptions={{ position: 'bottom' }}
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                padding: 0,
                paddingRight: 5,
                marginBottom: 5,
              }}
              type="button"
            >
              <GoInfo color="#737373" size="13px" />
            </Button>
            <Form.Control
              value={nomeMae || (dadosRecuperados ? model.nomeMae : nomeMae)}
              onChange={(event) => {
                event.preventDefault();
                setNomeMae(soLetras(event.target.value));
                if (validaNome(soLetras(event.target.value))) {
                  setIsNomeMaeValid(true);
                } else {
                  setIsNomeMaeValid(false);
                }
              }}
              id="nomeMae"
              name="nomeMae"
              maxLength={256}
              type="text"
              title="São necessárias pelo menos duas palavras"
              required
              isInvalid={!!(nomeMae && !isNomeMaeValid)}
            />
            {!isNomeMaeValid && nomeMae.length > 0 && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Nome inválido, inserir nome e sobrenome. Caso não seja declarado, preencher como
                "Não Declarado".
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label className="requiredField">TELEFONE</Form.Label>
            <Form.Control
              value={telefone || (dadosRecuperados ? model.telefone : telefone)}
              onChange={(event) => {
                setTelefone(maskTel(event.target.value));
              }}
              id="telefone"
              name="telefone"
              type="text"
              maxLength={14}
              minLength={13}
              title="Telefone deve ter no minímo 13 dígitos e no máximo 14"
              required
              isInvalid={!!(telefone && !(telefone.length >= 13 && telefone.length <= 14))}
            />
            {telefone && !(telefone.length >= 13 && telefone.length <= 14) && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Telefone deve ter no minímo 13 dígitos e no máximo 14.
              </p>
            )}
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>SEGUNDO TELEFONE</Form.Label>
            <Form.Control
              value={
                segundoTelefone || (dadosRecuperados ? model.segundoTelefone : segundoTelefone)
              }
              onChange={(event) => {
                setSegundoTelefone(maskTel(event.target.value));
              }}
              id="segundoTelefone"
              name="segundoTelefone"
              type="text"
              maxLength={14}
              minLength={13}
              title="Telefone deve ter no minímo 13 dígitos e no máximo 14"
              isInvalid={
                !!(
                  segundoTelefone && !(segundoTelefone.length >= 13 && segundoTelefone.length <= 14)
                )
              }
            />
            {segundoTelefone && !(segundoTelefone.length >= 13 && segundoTelefone.length <= 14) && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Telefone deve ter no minímo 13 dígitos e no máximo 14.
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>NOME REFERÊNCIA</Form.Label>
            <Form.Control
              value={referenciaNome || (dadosRecuperados ? model.referenciaNome : referenciaNome)}
              onChange={(event) => {
                setReferenciaNome(soLetras(event.target.value));
              }}
              id="referenciaNome"
              name="referenciaNome"
              maxLength={256}
              type="text"
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Label>REFERÊNCIA TELEFONE</Form.Label>
            <Form.Control
              value={
                referenciaTelefone ||
                (dadosRecuperados ? model.referenciaTelefone : referenciaTelefone)
              }
              onChange={(event) => {
                setReferenciaTelefone(maskTel(event.target.value));
              }}
              id="referenciaTelefone"
              name="referenciaTelefone"
              type="text"
              maxLength={14}
              minLength={13}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label>GRAU PARENTESCO</Form.Label>
            <Form.Control
              value={grauParentesco || (dadosRecuperados ? model.grauParentesco : grauParentesco)}
              onChange={(event) => {
                event.preventDefault();
                setGrauParentesco(soLetras(event.target.value));
                if (soLetras(event.target.value)) {
                  setIsGrauParentValid(true);
                } else {
                  setIsGrauParentValid(false);
                }
              }}
              name="grauParentesco"
              maxLength={50}
              type="text"
              placeholder=""
              title="Não são aceitos números ou caracteres especiais"
            />
            {!isGrauParentValid && grauParentesco.length > 0 && (
              <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                Grau de Parentesco inválido
              </p>
            )}
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
            <hr />
          </Form.Group>
        </Form.Row>

        <ButtonsForm />
      </Form>
    </Tela>
  );
}

export default CadastroCondutor;
