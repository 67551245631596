import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { SeguradoraType } from '../../Types/CorretoraType';
import api from '../../config/axiosMaquina';
import { Tabela } from '../../components/Tabela';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { pesquisar } from '../../util/format';
import { ExcelGeneration } from '../../Types/ExcelType';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

type Props = {
  canInsert?: boolean;
};

const ConsultarSeguradora: FC<Props> = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [seguradoras, setSeguradoras] = useState<SeguradoraType[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentSeguradoras =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const titles = ['+id', 'Código', 'Nome', 'Descrição', 'Status'];

  function compare(a: any, b: any) {
    if (a.segTxNome.toLowerCase() < b.segTxNome.toLowerCase()) return -1;
    if (a.segTxNome.toLowerCase() > b.segTxNome.toLowerCase()) return 1;
    return 0;
  }

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.SEGURADORA] }], 'inserir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const loadDadosSeguradora = async () => {
      try {
        const response = await api.get('/seguradora');
        console.log(response.data);
        const array = response.data.sort(compare).map((each: SeguradoraType) => {
          return [
            each.segCdId,
            each.segCdId,
            each.segTxNome,
            each.segTxDescricao,
            each.segBlAtivo ? 'Ativo' : 'Inativo',
          ];
        });
        setSeguradoras(response.data.sort(compare));
        setData(array);
      } catch (err: any) {
        console.log(err);
      }
    };
    loadDadosSeguradora();
  }, []);

  useEffect(() => {
    if (!seguradoras) {
      return;
    }

    setExcelGeneration([
      {
        xSteps: 2,
        ySteps: 0,
        columns: [
          {
            title: 'Seguradoras',
            widthPx: 1060,
            style: { font: { sz: '24', bold: true } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          { title: 'Código', width: { wch: 7 } },
          { title: 'Nome', width: { wch: 25 } },
          { title: 'Descrição', width: { wch: 25 } },
          { title: 'Status', width: { wch: 10 } },
        ],
        data: seguradoras.map((each) => [
          { value: each.segCdId.toString() },
          { value: each.segTxNome },
          { value: each.segTxDescricao },
          { value: each.segBlAtivo ? 'Ativo' : 'Inativo' },
        ]),
      },
    ]);
  }, [seguradoras]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(data, searchTerm));
    }
  }, [searchTerm]);

  return (
    <Tela
      nome="Seguradora"
      caminho="Cadastro > Seguradora"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchTerm}
      search={search}
      linkToAdd="/cadastro-seguradora"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate!}
      currentPage={currentPage.toString()}
      excelGeneration={excelGeneration}
      filename="Seguradoras"
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentSeguradoras}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/edicao-seguradora/${id}`)}
        />
      )}
    </Tela>
  );
};

export default ConsultarSeguradora;
