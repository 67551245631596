/* eslint-disable */

import { useState } from 'react';
import filesize from 'filesize';

import { Button } from 'primereact/button';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { Container, FileInfo } from './styles';
import { getCookieSessionData } from '../../../services/cookieService';
import { useToast } from '../../../hooks/Toast';

type Props = {
  document?: any;
  setDocument?: any;
  setRefreshList?: any
  setShow?: any
};

const FileList = ({ document, setDocument, setRefreshList, setShow }: Props) => {
  const { addToast } = useToast();
  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO || {};
  const [loading, setLoading] = useState(false);

  const handleSendArchive = () => {
    const data = new FormData();

    data.append('sm', document);
    data.append('idCliente', clienteId.toString());
    data.append('onGlog', 'True')
    data.append('idUsuario', usuarioId)

    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/upload-sm/`, data)
      .then((res: any) => {
        if (res.data.detail) {
          addToast({
            title: 'Erro',
            description: res.data.detail,
            type: 'error',
          });

          return;
        }

        setDocument('')

        setRefreshList(true)
        setShow(false)

        addToast({
          title: '',
          description: 'Arquivo enviado com sucesso!',
          type: 'success',
        });
      })
      .catch((e) => {
        if (e.response.data.detail) {
          addToast({
            title: 'Erro',
            description: e.response.data.detail,
            type: 'error',
          });
        }

        setDocument('')

        setRefreshList(true)
      })
      .finally(() => {



        setLoading(false)
      });
  };

  return (
    document && (
      <Container>
        <li>
          <FileInfo>
            <div>
              <small>
                Nome: <strong>{document.name}</strong>
              </small>
              <small>
                Tamanho do arquivo: <strong>{filesize(document.size)}</strong>
              </small>
            </div>
          </FileInfo>

          <div style={{ display: 'flex' }}>
            <Button
              type="button"
              style={{
                background: '#e73832',
                borderColor: '#e73832',
                fontWeight: 'bold',
                width: 100,
                color: '#f3f2f2',
              }}
              label="Remover"
              className="login-button"
              onClick={(e) => setDocument('')}
            />

            {loading ? (
              <div style={{ width: 100 }}>
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{
                    display: 'flex',
                    marginLeft: '47.5%',
                    marginTop: '5%',
                    marginBottom: '5%',
                  }}
                />
              </div>
            ) : (
              <Button
                type="button"
                style={{
                  marginLeft: 10,
                  background: '#FFC800',
                  borderColor: '#FFC800',
                  color: '#0B141F',
                  fontWeight: 'bold',
                  width: 100,
                }}
                label="Enviar"
                className="login-button"
                onClick={handleSendArchive}
              />
            )}
          </div>
        </li>
      </Container>
    )
  );
};

export default FileList;
