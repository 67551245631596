import styled from 'styled-components';

export const Ul = styled.ul`
  font-size: 12px;
`;

export const P = styled.p`
  font-size: 14px;
  margin-left: 16px;
`;
