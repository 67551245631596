/* eslint-disable */
import { ButtonTooltip } from '../ButtonTooltipo';
import './styles.css';

type PropsType = {
  titles: string[];
  data: any[][];
  onClickEditar?: (id: any) => void;
  onClickVisualizar?: (id: any) => void;
  onClickCancelar?: (id: any) => void;
  onClickPapel?: (id: any) => void;
  onClickExcluir?: (id: any) => void;
  onClickExcluirNc?: (id: any) => void;
  onClickCancelarSm?: (id: any) => void;
  onClickDetalhes?: (id: any) => void;
  style?: any;
  left?: boolean;
  avisos?: boolean;
  canDelete?: boolean;
};

export const Tabela = ({
  titles,
  data,
  onClickEditar,
  onClickVisualizar,
  onClickCancelar,
  onClickPapel,
  onClickExcluir,
  onClickExcluirNc,
  style,
  left,
  onClickCancelarSm,
  onClickDetalhes,
  avisos,
  canDelete,
}: PropsType) => {
  return (
    <div className="table-responsive" style={{ flex: 1 }}>
      <div className="painel">
        <table className="table" {...style}>
          <thead className="thead">
            <tr>
              {onClickEditar && left && <th> </th>}
              {onClickExcluir && left && <th> </th>}
              {titles.slice(titles[0] === '+id' ? 1 : 0, titles.length).map((title: string) => (
                <th>{title}</th>
              ))}
              {onClickExcluir && !left && <th> </th>}
              {onClickEditar && !left && <th> </th>}
              {onClickVisualizar && <th> </th>}
              {onClickPapel && <th> </th>}
              {onClickCancelar && <th> </th>}
            </tr>
          </thead>
          <tbody>
            {data.map((d: string[], index: number) => (
              <>
                <tr key={index}>
                  {onClickEditar && left && (
                    <td align="left">
                      <ButtonTooltip type="Editar" onClick={() => onClickEditar(d[0])} />
                    </td>
                  )}
                  {onClickExcluir && left && (
                    <td align="left">
                      <ButtonTooltip type="Excluir" onClick={() => onClickExcluir(d[0])} />
                    </td>
                  )}
                  {d.slice(titles[0] === '+id' ? 1 : 0, d.length).map((e: string) => (
                    <td
                      style={
                        avisos
                          ? {
                              color:
                                e === 'Alta'
                                  ? 'red'
                                  : e === 'Média'
                                  ? '#FFDA53'
                                  : e === 'Normal'
                                  ? 'grey'
                                  : 'black',
                            }
                          : {}
                      }
                    >
                      {e}
                    </td>
                  ))}

                  {onClickEditar && !left && (
                    <td align="right">
                      <ButtonTooltip type="Editar" onClick={() => onClickEditar(d[0])} />
                    </td>
                  )}
                  {onClickExcluir && !left && (
                    <td align="right">
                      <ButtonTooltip type="Excluir" onClick={() => onClickExcluir(d[0])} />
                    </td>
                  )}
                  {onClickExcluirNc && !left && canDelete && (
                    <td align="right">
                      <ButtonTooltip type="Excluir" onClick={() => onClickExcluirNc(d[0])} />
                    </td>
                  )}
                  {onClickPapel && (
                    <td align="right">
                      <ButtonTooltip type="Editar" onClick={onClickPapel} />
                    </td>
                  )}
                  {onClickCancelar && (
                    <td align="right">
                      <ButtonTooltip type="Editar" onClick={onClickCancelar} />
                    </td>
                  )}
                  {onClickCancelarSm && (
                    <td align="right">
                      <ButtonTooltip type="Cancelar SM" onClick={() => onClickCancelarSm(d[0])} />
                    </td>
                  )}
                  {onClickVisualizar && (
                    <td align="right">
                      <ButtonTooltip type="Visualizar" onClick={() => onClickVisualizar(d[0])} />
                    </td>
                  )}
                  {onClickDetalhes && (
                    <td align="right">
                      <ButtonTooltip type="Detalhes" onClick={() => onClickDetalhes(d[0])} />
                    </td>
                  )}
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
