import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

type GrupoVeiculoType = {
  vtgBlAtivo: boolean;
  vtgTxNome: string;
  vtgCdId: number;
  vgmNome: string;
};

const ListagemGrupoVeiculos: React.FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const { page }: any = useParams();
  const [fullData, setFullData] = useState<GrupoVeiculoType[]>([]);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [historicosPerPage, setHistoricosPerPage] = useState(Number(itensPerPage));
  const indexOfLastPost = page * historicosPerPage;
  const indexOfFirstPost = indexOfLastPost - historicosPerPage;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchValue.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const titles = ['+id', 'Nome', 'Grupo macro'];

  const paginate = (pageNumber: number) => {
    history.push(`/listar/grupos-de-veiculos/${pageNumber}`);
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Grupo de veículos',
          widthPx: 1200,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      columns: [],
      data: [],
    },
    {
      columns: [
        { title: 'Nome', width: { wch: 30 } },
        { title: 'Grupo macro', width: { wch: 25 } },
        { title: 'Status', width: { wch: 15 } },
      ],
      data: fullData?.map((each) => [
        { value: each.vtgTxNome ? each.vtgTxNome : '' },
        { value: each.vgmNome ? each.vgmNome : '' },
        { value: each.vtgBlAtivo ? 'ATIVO' : 'INATIVO' },
      ]),
    },
  ];

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.GRUPO_VEICULO] }], 'inserir');
  }, [ADMIN, rolePermission]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/grupo-tipo-veiculo/grid`);
      const array = response.data.map((each: GrupoVeiculoType) => {
        return [each.vtgCdId, each.vtgTxNome, each.vgmNome];
      });
      setDataTabela(array);
      setFullData(response.data);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar/grupos-de-veiculos/1');
      setDataSearch(pesquisar(dataTabela, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Grupo de Veículos"
      caminho="Cadastro > Veículos > Grupo de Veículos"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchValue}
      search={search}
      linkToAdd="/cadastrar-grupos-de-veiculos"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchValue.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={page}
      excelGeneration={multiDataSet}
      filename="Grupo de veículos"
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/editar-grupos-de-veiculos/${id}`)}
        />
      )}
    </Tela>
  );
};

export default ListagemGrupoVeiculos;
