/* eslint-disable */

import React, { FC, useState, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Switch from 'react-bootstrap/esm/Switch';
// import { parse } from 'node:path';
import { Spinner } from 'react-bootstrap';
import axios from '../../../config/axiosMaquina';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { useToast } from '../../../hooks/Toast';
import { getCookieSessionData } from '../../../services/cookieService';

export type ClienteParticularidadesDTO = {
  cliCdId?: number | null;
  cppBlBloqueioSmSat: number;
  cppBlClienteEmbarcador: boolean;
  cppBlCriticarAnaliseNaoMotoristaAgregado: boolean;
  cppBlCriticarAnaliseNaoMotoristaAutonomo: boolean;
  cppBlCriticarAnaliseNaoMotoristaFuncionario: boolean;
  cppBlCriticarAnaliseSmAgregado: boolean;
  cppBlCriticarAnaliseSmCondutorAgregado: boolean;
  cppBlCriticarAnaliseSmCondutorAutonomo: boolean;
  cppBlCriticarAnaliseSmCondutorFuncionario: boolean;
  cppBlCriticarAnaliseSmFrotaPropria: boolean;
  cppBlCriticarAnaliseSmTerceiro: boolean;
  cppBlExecutarRotaInternacional: boolean;
  cppBlHabilitarPesquisaAnttRntrc: boolean;
  cppBlIncluirCdCadPonto: boolean;
  cppBlIncluirRegionalCadPonto: boolean;
  cppBlPermitirEdicao: boolean;
  cppBlSolicitarFrota: boolean;
  cppBlSolicitarNumeroIdentificador: boolean;
  cppBlSolicitarNumeroRemessa: boolean;
  cppBlSolicitarOperacao: boolean;
  cppBlUtilizaApolicePropria: boolean;
  cppBlUtilizarRankingAjudanteFuncionario: boolean;
  cppBlUtilizarRankingCondutor: boolean;
  cppBlUtilizarRankingVeiculo: boolean;
  cppNuValidadeAnaliseCondutorAgregado: number;
  cppNuValidadeAnaliseCondutorAutonomo: number;
  cppNuValidadeAnaliseCondutorFuncionario: number;
  cppNuValidadeAnaliseNaoMotoristaAgregado: number;
  cppNuValidadeAnaliseNaoMotoristaAutonomo: number;
  cppNuValidadeAnaliseNaoMotoristaFuncionario: number;
  cppNuValidadeAnaliseVeiculoAgregado: number;
  cppNuValidadeAnaliseVeiculoFrotaPropria: number;
  cppNuValidadeAnaliseVeiculoTerceiro: number;
  cppNuValidarChecklistPendente: number;
  cppNuVeiculoAgregado: number;
  cppNuVeiculoFrotaEspecial: number;
  cppNuVeiculoFrotaPropria: number;
  cppNuVeiculoTerceiro: number;
  cppBlDadosEnderecoObrigatorio: boolean;
  cppBlPermitirPesquisaOutraEmpresa: boolean;
  cppBlHabilitaPesquisasConsultasVigentes: boolean;
  cppBlHabilitarPesquisaIntegrada: boolean;
  cppBlUtilizarRankingBau: boolean;
  cppBlUtilizarRankingCarreta: boolean;
};

type Props = {
  razaoSocial: string;
  idCliente: number;
  cadastroRealizado: boolean;
  idParticularidades?: number | null;
  idServicos?: number | null;
  idClienteByPost?: number | null;
  setCadastroRealizado: React.Dispatch<React.SetStateAction<boolean>>;
  canEdit?: boolean;
};

const TabParticularidades: React.FC<Props> = ({
  razaoSocial,
  idCliente,
  cadastroRealizado,
  idParticularidades,
  // idServicos,
  idClienteByPost,
  setCadastroRealizado,
  canEdit,
}) => {
  const history = useHistory();
  const { addToast } = useToast();
  // const { fromSMCadastro }: any = useParams();

  // const [cliente, setCliente] = useState({} as ClienteParticularidadesDTO);
  // const [wasCadastred, setWasCadastred] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogVisibleCadEfetuadoPart, setIsDialogVisibleCadEfetuadoPart] = useState(false);
  const [loading, setLoading] = useState(false);
  const usuario = getCookieSessionData().usuarioVO.usuarioId;

  // const [cadastroRealizadoPart, setCadastroRealizadoPart] = useState(false);
  const { id }: any = useParams();

  const [modelPart, setModelPart] = useState<ClienteParticularidadesDTO>({
    cliCdId: idCliente === undefined ? idClienteByPost : idCliente,
    cppBlBloqueioSmSat: 2,
    cppBlClienteEmbarcador: false,
    cppBlCriticarAnaliseNaoMotoristaAgregado: true,
    cppBlCriticarAnaliseNaoMotoristaAutonomo: true,
    cppBlCriticarAnaliseNaoMotoristaFuncionario: true,
    cppBlCriticarAnaliseSmAgregado: true,
    cppBlCriticarAnaliseSmCondutorAgregado: true,
    cppBlCriticarAnaliseSmCondutorAutonomo: true,
    cppBlCriticarAnaliseSmCondutorFuncionario: true,
    cppBlCriticarAnaliseSmFrotaPropria: true,
    cppBlCriticarAnaliseSmTerceiro: true,
    cppBlExecutarRotaInternacional: false,
    cppBlHabilitarPesquisaAnttRntrc: false,
    cppBlIncluirCdCadPonto: false,
    cppBlIncluirRegionalCadPonto: false,
    cppBlPermitirEdicao: false,
    cppBlSolicitarFrota: false,
    cppBlSolicitarNumeroIdentificador: false,
    cppBlSolicitarNumeroRemessa: false,
    cppBlSolicitarOperacao: false,
    cppBlUtilizaApolicePropria: false,
    cppBlUtilizarRankingAjudanteFuncionario: false,
    cppBlUtilizarRankingCondutor: false,
    cppBlUtilizarRankingVeiculo: false,
    cppNuValidadeAnaliseCondutorAgregado: 180,
    cppNuValidadeAnaliseCondutorAutonomo: 0,
    cppNuValidadeAnaliseCondutorFuncionario: 180,
    cppNuValidadeAnaliseNaoMotoristaAgregado: 180,
    cppNuValidadeAnaliseNaoMotoristaAutonomo: 0,
    cppNuValidadeAnaliseNaoMotoristaFuncionario: 180,
    cppNuValidadeAnaliseVeiculoAgregado: 180,
    cppNuValidadeAnaliseVeiculoFrotaPropria: 180,
    cppNuValidadeAnaliseVeiculoTerceiro: 0,
    cppNuValidarChecklistPendente: 2,
    cppNuVeiculoAgregado: 0,
    cppNuVeiculoFrotaEspecial: 0,
    cppNuVeiculoFrotaPropria: 0,
    cppNuVeiculoTerceiro: 0,
    cppBlDadosEnderecoObrigatorio: false,
    cppBlPermitirPesquisaOutraEmpresa: false,
    cppBlHabilitaPesquisasConsultasVigentes: true,
    cppBlHabilitarPesquisaIntegrada: false,
    cppBlUtilizarRankingBau: false,
    cppBlUtilizarRankingCarreta: false,
  });

  useEffect(() => {
    const loadParticularidadesById = async () => {
      if (!idParticularidades) {
      } else {
        try {
          const response = await axios.get(
            `/cliente-particularidades/getById/${idParticularidades}`
          );
          setModelPart(response.data);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    loadParticularidadesById();
  }, [idParticularidades]);

  const handleRegisterParticularidades = async (event: any) => {
    event.preventDefault();

    const data = {
      ...modelPart,
      cliCdId: idCliente === undefined ? idClienteByPost : idCliente,
    };

    try {
      setLoading(true);
      if (!idCliente && idParticularidades === null) {
        const response = await axios.post(`/cliente-particularidades/salvar`, data);
        setIsDialogVisibleCadEfetuadoPart(true);
        setLoading(false);
      } else if (idParticularidades === null) {
        const response = await axios.post(`/cliente-particularidades/salvar`, data);
        setIsDialogVisibleCadEfetuadoPart(true);
        setLoading(false);
      } else {
        await axios.put(`/cliente-particularidades/atualizar/${idParticularidades}`, {
          ...data,
          idUsuario: usuario || null
        });
        setLoading(false);
        setIsDialogVisibleCadEfetuadoPart(true);
      }
    } catch (err: any) {
      setLoading(false);
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar.',
        type: 'error',
      });
    }
  };

  const goServicosContratados = () => {
    setIsDialogVisibleCadEfetuadoPart(false);
    if (!id) {
      history.push('/cadastrar-clientes/servicosContratados');
    }
  };

  console.log(modelPart.cppNuValidadeAnaliseCondutorAgregado);

  return (
    <>
      <Dialog
        header={
          idCliente
            ? 'Alteração realizada com sucesso!'
            : 'Cliente Particularidades foi cadastrado com sucesso!'
        }
        footer={
          <>
            <Button label="OK" onClick={goServicosContratados} />
          </>
        }
        visible={isDialogVisibleCadEfetuadoPart}
        style={{ width: '50vw' }}
        modal
        onHide={goServicosContratados}
      />
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <Form noValidate validated={isFormInvalid} onSubmit={handleRegisterParticularidades}>
          <Form.Row>
            <Form.Group as={Col} className="mt-3">
              <Form.Label>Cliente</Form.Label>

              <Form.Control
                value={razaoSocial}
                id="razaoSocial"
                name="razaoSocial"
                placeholder=""
                style={{ width: '49.8 %' }}
                maxLength={64}
                disabled
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlClienteEmbarcador)}
                checked={modelPart.cppBlClienteEmbarcador}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlClienteEmbarcador: !modelPart.cppBlClienteEmbarcador,
                  })
                }
                name="clienteEmbarcador"
                id="clienteEmbarcador"
                type="switch"
                label="Cliente Embarcador?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlPermitirEdicao)}
                checked={modelPart.cppBlPermitirEdicao}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlPermitirEdicao: !modelPart.cppBlPermitirEdicao,
                  })
                }
                name="cppBlPermitirEdicao"
                id="cppBlPermitirEdicao"
                type="switch"
                label="Permitir Edição?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlSolicitarOperacao)}
                checked={modelPart.cppBlSolicitarOperacao}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlSolicitarOperacao: !modelPart.cppBlSolicitarOperacao,
                  })
                }
                name="cppBlSolicitarOperacao"
                id="cppBlSolicitarOperacao"
                type="switch"
                label="Solicitar Operação?"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlSolicitarFrota)}
                checked={modelPart.cppBlSolicitarFrota}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlSolicitarFrota: !modelPart.cppBlSolicitarFrota,
                  })
                }
                name="cppBlSolicitarFrota"
                id="cppBlSolicitarFrota"
                type="switch"
                label="Solicitar Frota?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlSolicitarNumeroIdentificador)}
                checked={modelPart.cppBlSolicitarNumeroIdentificador}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlSolicitarNumeroIdentificador: !modelPart.cppBlSolicitarNumeroIdentificador,
                  })
                }
                name="cppBlSolicitarNumeroIdentificador"
                id="cppBlSolicitarNumeroIdentificador"
                type="switch"
                label="Solicitar Nº Identificador?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlSolicitarNumeroRemessa)}
                checked={modelPart.cppBlSolicitarNumeroRemessa}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlSolicitarNumeroRemessa: !modelPart.cppBlSolicitarNumeroRemessa,
                  })
                }
                name="cppBlSolicitarNumeroRemessa"
                id="cppBlSolicitarNumeroRemessa"
                type="switch"
                label="Solicitar Nº Remessa?"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlUtilizaApolicePropria)}
                checked={modelPart.cppBlUtilizaApolicePropria}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlUtilizaApolicePropria: !modelPart.cppBlUtilizaApolicePropria,
                  })
                }
                name="cppBlUtilizaApolicePropria"
                id="cppBlUtilizaApolicePropria"
                type="switch"
                label="Utiliza apólice própria?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlDadosEnderecoObrigatorio)}
                checked={modelPart.cppBlDadosEnderecoObrigatorio}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlDadosEnderecoObrigatorio: !modelPart.cppBlDadosEnderecoObrigatorio,
                  })
                }
                name="cppBlDadosEnderecoObrigatorio"
                id="cppBlDadosEnderecoObrigatorio"
                type="switch"
                label="Dados do Endereço Obrigatório?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlPermitirPesquisaOutraEmpresa)}
                checked={modelPart.cppBlPermitirPesquisaOutraEmpresa}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlPermitirPesquisaOutraEmpresa: !modelPart.cppBlPermitirPesquisaOutraEmpresa,
                  })
                }
                name="cppBlPermitirPesquisaOutraEmpresa"
                id="cppBlPermitirPesquisaOutraEmpresa"
                type="switch"
                label="Permitir Pesquisa de outra Empresa?"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlHabilitaPesquisasConsultasVigentes)}
                checked={modelPart.cppBlHabilitaPesquisasConsultasVigentes}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlHabilitaPesquisasConsultasVigentes:
                      !modelPart.cppBlHabilitaPesquisasConsultasVigentes,
                  })
                }
                name="cppBlHabilitaPesquisasConsultasVigentes"
                id="cppBlHabilitaPesquisasConsultasVigentes"
                type="switch"
                label="Habilitar Pesquisas de consultas vigentes?"
              />
            </Form.Group>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlExecutarRotaInternacional)}
                checked={modelPart.cppBlExecutarRotaInternacional}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlExecutarRotaInternacional: !modelPart.cppBlExecutarRotaInternacional,
                  })
                }
                name="cppBlExecutarRotaInternacional"
                id="cppBlExecutarRotaInternacional"
                type="switch"
                label="Faz Rota Internacional?"
              />
            </Form.Group>
            {/* <Form.Group as={Col} className="mt-2 ml-2"></Form.Group> */}
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlHabilitarPesquisaIntegrada)}
                checked={modelPart.cppBlHabilitarPesquisaIntegrada}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlHabilitarPesquisaIntegrada: !modelPart.cppBlHabilitarPesquisaIntegrada,
                  })
                }
                name="cppBlHabilitarPesquisaIntegrada"
                id="cppBlHabilitarPesquisaIntegrada"
                type="switch"
                label="Permite Pesquisa Integrada?"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Bloqueio SM com SAT? </Form.Label>

              <Form.Control
                value={modelPart.cppBlBloqueioSmSat}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppBlBloqueioSmSat: parseInt(e.target.value, 10),
                  });
                }}
                id="cppBlBloqueioSmSat"
                name="cppBlBloqueioSmSat"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não valida</option>
                <option value="1">Avisa e Bloqueia</option>
                <option value="2">Avisa e Não Bloqueia</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Validar Checklist pendente?</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidarChecklistPendente}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidarChecklistPendente: parseInt(e.target.value, 10),
                  });
                }}
                id="checklistPendente"
                name="checklistPendente"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">Não valida</option>
                <option value="1">Avisa e Bloqueia</option>
                <option value="2">Avisa e Não Bloqueia</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Veículo Frota Própria</Form.Label>

              <Form.Control
                value={modelPart.cppNuVeiculoFrotaPropria}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuVeiculoFrotaPropria: parseInt(e.target.value, 10),
                  });
                }}
                id="frotaPropria"
                name="frotaPropria"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>
                <option value="7">7 dias</option>
                <option value="15">15 dias</option>
                <option value="30">30 dias</option>
                <option value="45">45 dias</option>
                <option value="60">60 dias</option>
                <option value="90">90 dias</option>
                <option value="120">120 dias</option>
                <option value="180">180 dias</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Veículo Agregado</Form.Label>

              <Form.Control
                value={modelPart.cppNuVeiculoAgregado}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuVeiculoAgregado: parseInt(e.target.value, 10),
                  });
                }}
                id="frotaAgregado"
                name="frotaAgregado"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>
                <option value="7">7 dias</option>
                <option value="15">15 dias</option>
                <option value="30">30 dias</option>
                <option value="45">45 dias</option>
                <option value="60">60 dias</option>
                <option value="90">90 dias</option>
                <option value="120">120 dias</option>
                <option value="180">180 dias</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Veículo Terceiro</Form.Label>

              <Form.Control
                value={modelPart.cppNuVeiculoTerceiro}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuVeiculoTerceiro: parseInt(e.target.value, 10),
                  });
                }}
                id="frotaTerceiro"
                name="frotaTerceiro"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>
                <option value="7">7 dias</option>
                <option value="15">15 dias</option>
                <option value="30">30 dias</option>
                <option value="45">45 dias</option>
                <option value="60">60 dias</option>
                <option value="90">90 dias</option>
                <option value="120">120 dias</option>
                <option value="180">180 dias</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlUtilizarRankingAjudanteFuncionario)}
                checked={modelPart.cppBlUtilizarRankingAjudanteFuncionario}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlUtilizarRankingAjudanteFuncionario:
                      !modelPart.cppBlUtilizarRankingAjudanteFuncionario,
                  })
                }
                name="cppBlUtilizarRankingAjudanteFuncionario"
                id="cppBlUtilizarRankingAjudanteFuncionario"
                type="switch"
                label="Utilizar Ranking de Ajudante/Func. Não Motorista na SM?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlUtilizarRankingBau)}
                checked={modelPart.cppBlUtilizarRankingBau}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlUtilizarRankingBau: !modelPart.cppBlUtilizarRankingBau,
                  })
                }
                name="cppBlUtilizarRankingBau"
                id="cppBlUtilizarRankingBau"
                type="switch"
                label="Utilizar Ranking de Baú na SM?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlUtilizarRankingCarreta)}
                checked={modelPart.cppBlUtilizarRankingCarreta}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlUtilizarRankingCarreta: !modelPart.cppBlUtilizarRankingCarreta,
                  })
                }
                name="cppBlUtilizarRankingCarreta"
                id="cppBlUtilizarRankingCarreta"
                type="switch"
                label="Utilizar Ranking de Carreta na SM?"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlUtilizarRankingCondutor)}
                checked={modelPart.cppBlUtilizarRankingCondutor}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlUtilizarRankingCondutor: !modelPart.cppBlUtilizarRankingCondutor,
                  })
                }
                name="cppBlUtilizarRankingCondutor"
                id="cppBlUtilizarRankingCondutor"
                type="switch"
                label="Utilizar Ranking de Condutor na SM?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlUtilizarRankingVeiculo)}
                checked={modelPart.cppBlUtilizarRankingVeiculo}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlUtilizarRankingVeiculo: !modelPart.cppBlUtilizarRankingVeiculo,
                  })
                }
                name="cppBlUtilizarRankingVeiculo"
                id="cppBlUtilizarRankingVeiculo"
                type="switch"
                label="Utilizar Ranking de Veículo na SM?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2" />
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlIncluirCdCadPonto)}
                checked={modelPart.cppBlIncluirCdCadPonto}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlIncluirCdCadPonto: !modelPart.cppBlIncluirCdCadPonto,
                  })
                }
                name="cppBlIncluirCdCadPonto"
                id="cppBlIncluirCdCadPonto"
                type="switch"
                label="Incluir CD no cadastro de Pontos?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlIncluirRegionalCadPonto)}
                checked={modelPart.cppBlIncluirRegionalCadPonto}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlIncluirRegionalCadPonto: !modelPart.cppBlIncluirRegionalCadPonto,
                  })
                }
                name="cppBlIncluirRegionalCadPonto"
                id="cppBlIncluirRegionalCadPonto"
                type="switch"
                label="Incluir Regional no cadastro de Pontos?"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2" />
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlHabilitarPesquisaAnttRntrc)}
                checked={modelPart.cppBlHabilitarPesquisaAnttRntrc}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlHabilitarPesquisaAnttRntrc: !modelPart.cppBlHabilitarPesquisaAnttRntrc,
                  })
                }
                name="cppBlHabilitarPesquisaAnttRntrc"
                id="cppBlHabilitarPesquisaAnttRntrc"
                type="switch"
                label="Habilitar Pesquisa ANTT/RNTRC"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2">
              <Form.Label>Critica Análise na SM para Veículos:</Form.Label>
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseSmFrotaPropria)}
                checked={modelPart.cppBlCriticarAnaliseSmFrotaPropria}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseSmFrotaPropria:
                      !modelPart.cppBlCriticarAnaliseSmFrotaPropria,
                  })
                }
                name="cppBlCriticarAnaliseSmFrotaPropria"
                id="cppBlCriticarAnaliseSmFrotaPropria"
                type="switch"
                label="Frota Própria"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseSmAgregado)}
                checked={modelPart.cppBlCriticarAnaliseSmAgregado}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseSmAgregado: !modelPart.cppBlCriticarAnaliseSmAgregado,
                  })
                }
                name="cppBlCriticarAnaliseSmAgregado"
                id="cppBlCriticarAnaliseSmAgregado"
                type="switch"
                label="Agregado"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseSmTerceiro)}
                checked={modelPart.cppBlCriticarAnaliseSmTerceiro}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseSmTerceiro: !modelPart.cppBlCriticarAnaliseSmTerceiro,
                  })
                }
                name="cppBlCriticarAnaliseSmTerceiro"
                id="cppBlCriticarAnaliseSmTerceiro"
                type="switch"
                label="Terceiro"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-4">
              <Form.Label>Validade da Análise para Veículos:</Form.Label>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Frota Própria</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseVeiculoFrotaPropria}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseVeiculoFrotaPropria: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseVeiculoFrotaPropria"
                name="cppNuValidadeAnaliseVeiculoFrotaPropria"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Agregado</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseVeiculoAgregado}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseVeiculoAgregado: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseVeiculoAgregado"
                name="cppNuValidadeAnaliseVeiculoAgregado"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Terceiro</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseVeiculoTerceiro}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseVeiculoTerceiro: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseVeiculoTerceiro"
                name="cppNuValidadeAnaliseVeiculoTerceiro"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2">
              <Form.Label>Critica Análise na SM para Condutores:</Form.Label>
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseSmCondutorFuncionario)}
                checked={modelPart.cppBlCriticarAnaliseSmCondutorFuncionario}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseSmCondutorFuncionario:
                      !modelPart.cppBlCriticarAnaliseSmCondutorFuncionario,
                  })
                }
                name="cppBlCriticarAnaliseSmCondutorFuncionario"
                id="cppBlCriticarAnaliseSmCondutorFuncionario"
                type="switch"
                label="Funcionário"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseSmCondutorAgregado)}
                checked={modelPart.cppBlCriticarAnaliseSmCondutorAgregado}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseSmCondutorAgregado:
                      !modelPart.cppBlCriticarAnaliseSmCondutorAgregado,
                  })
                }
                name="cppBlCriticarAnaliseSmCondutorAgregado"
                id="cppBlCriticarAnaliseSmCondutorAgregado"
                type="switch"
                label="Agregado"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseSmCondutorAutonomo)}
                checked={modelPart.cppBlCriticarAnaliseSmCondutorAutonomo}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseSmCondutorAutonomo:
                      !modelPart.cppBlCriticarAnaliseSmCondutorAutonomo,
                  })
                }
                name="cppBlCriticarAnaliseSmCondutorAutonomo"
                id="cppBlCriticarAnaliseSmCondutorAutonomo"
                type="switch"
                label="Autônomo"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-4">
              <Form.Label>Validade da Análise para Condutores:</Form.Label>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Funcionário</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseCondutorFuncionario}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseCondutorFuncionario: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseCondutorFuncionario"
                name="cppNuValidadeAnaliseCondutorFuncionario"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Agregado</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseCondutorAgregado}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseCondutorAgregado: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseCondutorAgregado"
                name="cppNuValidadeAnaliseCondutorAgregado"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Autônomo</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseCondutorAutonomo}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseCondutorAutonomo: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseCondutorAutonomo"
                name="cppNuValidadeAnaliseCondutorAutonomo"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-2">
              <Form.Label>
                Critica Análise na SM para Ajudante/Funcionário (Não Motorista):
              </Form.Label>
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseNaoMotoristaFuncionario)}
                checked={modelPart.cppBlCriticarAnaliseNaoMotoristaFuncionario}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseNaoMotoristaFuncionario:
                      !modelPart.cppBlCriticarAnaliseNaoMotoristaFuncionario,
                  })
                }
                name="cppBlCriticarAnaliseNaoMotoristaFuncionario"
                id="cppBlCriticarAnaliseNaoMotoristaFuncionario"
                type="switch"
                label="Funcionário"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseNaoMotoristaAgregado)}
                checked={modelPart.cppBlCriticarAnaliseNaoMotoristaAgregado}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseNaoMotoristaAgregado:
                      !modelPart.cppBlCriticarAnaliseNaoMotoristaAgregado,
                  })
                }
                name="cppBlCriticarAnaliseNaoMotoristaAgregado"
                id="cppBlCriticarAnaliseNaoMotoristaAgregado"
                type="switch"
                label="Agregado"
              />
            </Form.Group>

            <Form.Group as={Col} className="mt-2 ml-2">
              <Switch
                value={Number(modelPart.cppBlCriticarAnaliseNaoMotoristaAutonomo)}
                checked={modelPart.cppBlCriticarAnaliseNaoMotoristaAutonomo}
                onChange={() =>
                  setModelPart({
                    ...modelPart,
                    cppBlCriticarAnaliseNaoMotoristaAutonomo:
                      !modelPart.cppBlCriticarAnaliseNaoMotoristaAutonomo,
                  })
                }
                name="cppBlCriticarAnaliseNaoMotoristaAutonomo"
                id="cppBlCriticarAnaliseNaoMotoristaAutonomo"
                type="switch"
                label="Autônomo"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} className="mt-4">
              <Form.Label>
                Validade da Análise para Ajudante/Funcionário (Não Motorista):
              </Form.Label>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Funcionário</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseNaoMotoristaFuncionario}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseNaoMotoristaFuncionario: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseNaoMotoristaFuncionario"
                name="cppNuValidadeAnaliseNaoMotoristaFuncionario"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Agregado</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseNaoMotoristaAgregado}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseNaoMotoristaAgregado: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseNaoMotoristaAgregado"
                name="cppNuValidadeAnaliseNaoMotoristaAgregado"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Autônomo</Form.Label>

              <Form.Control
                value={modelPart.cppNuValidadeAnaliseNaoMotoristaAutonomo}
                onChange={(e: any) => {
                  setModelPart({
                    ...modelPart,
                    cppNuValidadeAnaliseNaoMotoristaAutonomo: parseInt(e.target.value, 10),
                  });
                }}
                id="cppNuValidadeAnaliseNaoMotoristaAutonomo"
                name="cppNuValidadeAnaliseNaoMotoristaAutonomo"
                placeholder=""
                as="select"
                style={{ width: '100%' }}
              >
                <option value="0">No Embarque</option>
                <option value="1">1 dia</option>

                <option value="7">7 dias</option>
                <option value="30">30 dias</option>
                <option value="180">180 dias</option>
                <option value="365">365 dias</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <ButtonsForm canEdit={canEdit} />
        </Form>
      )}
    </>
  );
};

export default TabParticularidades;
