/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { Spinner } from 'react-bootstrap';
import api from '../../config/axiosMaquina';
import Pagination from '../../components/Pagination/Pagination';
import { ModalDelete } from '../../components/ModalDelete';
import { CDRow } from './editableGrid';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { ImCancelCircle } from 'react-icons/im';
import { AiOutlineCheck } from 'react-icons/ai';
import { getCookieSessionData } from '../../services/cookieService';
import { CdType } from '../../Types/RegionalCdTypes';
import { Linha } from '../../components/GridEditavel';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

type Props = {
  canEdit: boolean;
  canDelete: boolean;
  canInsert: boolean;
};

const ConsultarCD: FC = () => {
  const { EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const [idEdit, setIdEdit] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [newLine, setNewLine] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [jaAberto, setJaAberto] = useState(false);
  const [cds, setCds] = useState<CdType[]>([]);
  const [searchResults, setSearchResults] = useState<CdType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [nome, setNome] = useState('');
  const { page }: any = useParams();
  const [totalItems, setTotalItems] = useState(0);
  const [itensPerPage, setItensPerPage] = useState('10');
  const paginate = (pageNumber: any) => history.push(`/centro-de-distribuicao/${pageNumber}`);

  const [mensagem, setMensagem] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const { clienteId } = getCookieSessionData().usuarioVO;

  useEffect(() => {
    rolePermission([{EMBARCADOR: [EMBARCADOR.CADASTRO.CD]}], 'inserir');
    rolePermission([{EMBARCADOR: [EMBARCADOR.CADASTRO.CD]}], 'editar');
    rolePermission([{EMBARCADOR: [EMBARCADOR.CADASTRO.CD]}], 'excluir');
  }, [EMBARCADOR, rolePermission]);

  useEffect(() => {
    setLoading(true);
    const loadDadosCd = async () => {
      try {
        const params = {
          idCliente: clienteId,
          pagina: page - 1,
          qtdRegistro: itensPerPage,
        };
        const response = await api.get('/centro-distribuicao/grid', { params });
        setLoading(false);
        setCds(response.data);
        console.log(response.data);
      } catch (err) {
        setLoading(false);
        console.log(err);
      }
    };
    loadDadosCd();
  }, [page, itensPerPage]);

  useEffect(() => {
    const handleSearch = async () => {
      if (!cds) {
        return;
      }
      if (searchTerm && searchTerm.trim() != '') {
        history.push(`/centro-de-distribuicao/1`);
        const params = {
          keyword: searchTerm,
          idCliente: clienteId,
        };
        const response = await api.get(`/centro-distribuicao/search-grid`, { params });
        setSearchResults(response.data);
      } else {
        setSearchResults(cds);
      }
    };
    handleSearch();
  }, [searchTerm, cds]);

  useEffect(() => {
    const loadCount = async () => {
      try {
        const response = await api.get(`/centro-distribuicao/count?idCliente=${clienteId}`);
        console.log(response.data);
        setTotalItems(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    loadCount();
  }, []);

  const handleDelete = async () => {
    try {
      const data = {
        id: idDelete,
        ativo: false,
      };
      const response = await api.put(`/centro-distribuicao/atualizar?idCd=${idDelete}`, data);
      console.log(response.data);
      setOpenModal(false);
      setIsDialogCadOK(true);
      setMensagem('Registro excluída com suceso!');
    } catch (error) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir registro.',
        type: 'error',
      });
    }
  };

  async function handleSubmit() {
    setDisabled(false);
    if (nome.trim() != '') {
      try {
        const data = {
          nome: nome,
          ativo: true,
          idCliente: clienteId,
        };
        await api.post(`/centro-distribuicao/save`, data);
        setMensagem('Centro de distribuição cadastrado com sucesso!');
        setIsDialogCadOK(true);
      } catch (err) {
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar centro de distribuição.',
          type: 'error',
        });
      }
    } else {
      addToast({
        title: 'Erro',
        description: 'Verifique os campos obrigatórios.',
        type: 'error',
      });
    }
  }

  const handleCancel = () => {
    setDisabled(true);
    setNewLine(false);
  };

  async function handlePut(model: any) {
    if (model.nome.trim() != '') {
      try {
        const data = {
          ativo: true,
          id: model.id,
          idCliente: clienteId,
          nome: model.nome,
        };
        await api.put(`/centro-distribuicao/atualizar?idCd=${model.id}`, data);
        addToast({
          title: 'Sucesso!',
          description: 'Editado com sucesso!',
          type: 'success',
        });
        setTimeout(function () {
          window.location.reload();
        }, 2000);
        return true;
      } catch (err: any) {
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao editar.',
          type: 'error',
        });
        return false;
      }
    } else {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
    }
    return false;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <ModalDelete
          show={openModal}
          onHide={() => setOpenModal(false)}
          handleDelete={handleDelete}
          handleClose={() => setOpenModal(false)}
          message="Deseja excluir este registro?"
        />
        <Dialog
          header={mensagem}
          footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
          visible={isDialogCadOK}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK(false);
            window.location.reload();
          }}
        />
        <div className="titulo-container">
          <h2 className="titulo">Centro de Distribuicao</h2>
          <h6 className="subtitulo">{'Cadastro > CD'}</h6>
        </div>
        <div className="search-create mb-4" style={{ width: '100%' }}>
          <div className="btn-create" style={{ cursor: 'pointer' }}>
            <i
              style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
              className="pi pi-plus"
              onClick={() => {
                if (canInsert) {
                  setNewLine(true);
                } else {
                  history.push('/acessonegado');
                }
              }}
            />
          </div>
          <span className="p-input-icon-left">
            <input
              type="text"
              placeholder="Pesquise"
              className="p-inputtext p-component"
              id="search-input"
              style={{ width: '240px' }}
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            <i className="pi pi-search"></i>
          </span>
        </div>
        <div className="content-container" style={{ width: '100%' }}>
          <div className="painel">
            <div className="table-responsive" style={{ flex: 1 }}>
              <table className="table">
                <thead className="titleLine">
                  <tr>
                    <th id="th">Nome</th>
                  </tr>
                </thead>
                {newLine && canInsert ? (
                  <div className="lineGrid2" style={{ filter: 'drop-shadow(0 0 0.5rem #d8d8d8)' }}>
                    <input
                      placeholder="Novo centro de distribuição"
                      type="text"
                      value={nome}
                      maxLength={64}
                      onChange={(e: any) => {
                        setNome(e.target.value);
                      }}
                    />
                    <ImCancelCircle size="18px" id="icon" onClick={handleCancel} />
                    <AiOutlineCheck size="20px" id="icon" onClick={handleSubmit} />
                  </div>
                ) : null}
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="warning"
                    style={{
                      display: 'flex',
                      marginLeft: '47.5%',
                      marginTop: '5%',
                      marginBottom: '5%',
                    }}
                  />
                ) : (
                  <tbody>
                    {searchTerm && searchTerm.trim() != ''
                      ? searchResults.map((each) => {
                          return (
                            <div>
                              <Linha
                                each={each}
                                handlePut={handlePut}
                                setOpenModal={setOpenModal}
                                setIdDelete={setIdDelete}
                                setIdAtual={setIdEdit}
                                canDelete={canDelete}
                                canEdit={canEdit}
                                idAtual={idEdit}
                              />
                            </div>
                          );
                        })
                      : cds.map((each) => {
                          return (
                            <div>
                              <Linha
                                each={each}
                                handlePut={handlePut}
                                setOpenModal={setOpenModal}
                                canDelete={canDelete}
                                canEdit={canEdit}
                                setIdDelete={setIdDelete}
                                setIdAtual={setIdEdit}
                                idAtual={idEdit}
                              />
                            </div>
                          );
                        })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        <Pagination
          totalPosts={searchTerm ? searchTerm.length : totalItems}
          paginate={paginate}
          currentPage={page.toString()}
          itensPerPage={itensPerPage}
          setItensPerPage={setItensPerPage}
        />
      </div>
    </div>
  );
};

export default ConsultarCD;
