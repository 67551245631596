export const TIPO_FROTA_VEICULO = [
  {
    value: 'PROPRIO',
    label: 'Veiculo Próprio',
  },
  {
    value: 'AGREGADO',
    label: 'Veículo agregado',
  },

  {
    value: 'TERCEIRO',
    label: 'Veículos de Terceiro',
  },
];
