/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Modal, Spinner } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { getCookieSessionData } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import checaCPF from '../../util/checaCpf';
import { cpfMask, maskCpnj, soNumeros } from '../../util/Validacoes/Validacoes';
import '../ConsultarPesquisasLancadas/styles.css';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Tela } from '../../components/Tela';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

type DadosProprietario = {
  proBlAtivo: boolean;
  proCdId: number;
  proNuCodigo: number;
  proTxCpfCnpj: string;
  proTxNomeRazaoSocial: string;
  proTxTipoPessoa: string;
  idCliente: number;
};

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const EditarProprietario = () => {
  const { ADMIN, TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canDelete } = useRolePermission();
  const { doesHavePermission: canEdit } = useRolePermission();

  const listaTipos = [
    { value: 'f', nome: 'Física' },
    { value: 'j', nome: 'Jurídica' },
  ];

  const { id }: any = useParams();
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [cpfOriginal, setCpfOriginal] = useState('');
  const [cnpjOriginal, setCnpjOriginal] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [model, setModel] = useState<DadosProprietario>({} as DadosProprietario);
  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [isCpfValid, setIsCpfValid] = useState(true);
  const [isCnpjValid, setIsCnpjValid] = useState(true);
  const [isNomeInvalid, setIsNomeInvalid] = useState(false);
  const [jaCadastrado, setJaCadastrado] = useState(false);
  const [reutilizar, setReutilizar] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async (id: number) => {
    try {
      await axios.post(`/proprietario/delete-logico/${id}`);
      setExclusaoOK(true);
    } catch (err: any) {
      console.log(err.response);
      setExclusaoErro(true);
      setMensagemErro(err.response.data.message);
    }
  };

  useEffect(() => {
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PROPRIETARIO],
          ADMIN: [ADMIN.CADASTRO.PROPRIETARIO],
        },
      ],
      'editar'
    );
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PROPRIETARIO],
          ADMIN: [ADMIN.CADASTRO.PROPRIETARIO],
        },
      ],
      'excluir'
    );
  }, [TRANSPORTADOR, ADMIN]);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        return;
      }
      const response = await axios.get(`/proprietario/proprietario/${id}`);
      setModel(response.data);

      setAtivo(response.data.proBlAtivo);
      if (response.data.proTxCpfCnpj.length === 11) {
        setCpf(response.data.proTxCpfCnpj);
        setCpfOriginal(response.data.proTxCpfCnpj);
      } else {
        setCnpj(response.data.proTxCpfCnpj);
        setCnpjOriginal(response.data.proTxCpfCnpj);
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    constaCPF(cpf ? cpf : cnpj ? cnpj : '');
  }, [cpf, cnpj]);

  const constaCPF = (cpfOuCnpj: string) => {
    const cpfCnpj = soNumeros(cpfOuCnpj);

    if (cpfCnpj === soNumeros(cpfOriginal) || cpfCnpj === soNumeros(cnpjOriginal)) {
      setIsCnpjValid(true);
      setIsCpfValid(true);
    } else {
      axios
        .get(
          `/proprietario/search-proprietario?idCliente=${
            getCookieSessionData().usuarioVO.clienteId
          }&keyword=${cpfCnpj}`
        )
        .then((response) => {
          if (response.data.length > 0) {
            setJaCadastrado(true);
            setHide(true);
          } else {
            setJaCadastrado(false);
          }
        })
        .catch((error) => {
          console.log('error ' + error);
        });
    }
  };

  const handleGetUserInfo = async (cpfCnpj: string) => {
    cpfCnpj = soNumeros(cpfCnpj);
    setIsLoading(true);

    axios
      .get(
        `/proprietario/search-proprietario?idCliente=${
          getCookieSessionData().usuarioVO.clienteId
        }&keyword=${cpfCnpj}`
      )
      .then((response) => {
        setModel((prev) => ({
          ...prev,
          proTxNomeRazaoSocial: response.data[0].razaoSocial,
          proTxCpfCnpj: response.data[0].cnpjCpf,
          proTxTipoPessoa: response.data[0].tipo,
        }));
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('error ' + error);
        setIsLoading(false);
      });
  };

  const handleEdit = async (event: any) => {
    event.preventDefault();

    if (
      !isCpfValid ||
      !model.proTxCpfCnpj ||
      !model.proTxNomeRazaoSocial ||
      !model.proTxTipoPessoa ||
      !isCnpjValid ||
      (model.proTxTipoPessoa == 'f' && cpf.length < 11) ||
      (model.proTxTipoPessoa == 'j' && cnpj.length < 14) ||
      isNomeInvalid
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique os campos obrigatórios.',
        type: 'error',
      });

      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    if (!reutilizar && jaCadastrado && (cpf !== cpfOriginal || cnpj !== cnpjOriginal)) {
      setIsFormInvalid(true);

      addToast({
        title: 'Erro',
        description: 'CPF ou CNPJ já cadastrado!',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    const data = {
      ...model,
      proTxCpfCnpj: soNumeros(model.proTxCpfCnpj),
      idCliente: getCookieSessionData().usuarioVO.clienteId,
      proBlAtivo: ativo,
    };

    if (!id) {
      try {
        await axios.post(`/proprietario`, data);
        setCadastroRealizado(true);
      } catch (err: any) {
        console.log(err.response);
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      }
    } else {
      try {
        await axios.put(`/proprietario/atualizar/${id}`, data);
        setCadastroRealizado(true);
      } catch (err: any) {
        console.log(err.response);
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      }
    }
  };

  const [hide, setHide] = useState(false);

  return (
    <Tela nome="Proprietário" caminho={id ? 'Editar > Proprietário' : 'Cadastro > Proprietário'}>
      <Spinner animation="border" variant="warning" hidden={!isLoading} />
      <Dialog
        header="Este proprietário já está cadastrado. Deseja preencher os dados automaticamente?"
        footer={
          <>
            <Button
              label="Não"
              onClick={() => {
                setHide(false);
                setJaCadastrado(true);
              }}
            />
            <Button
              label="Sim"
              onClick={() => {
                handleGetUserInfo(cpf ? cpf : cnpj ? cnpj : '');
                setJaCadastrado(true);
                setHide(false);
                setReutilizar(true);
              }}
            />
          </>
        }
        visible={hide && !id}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setHide(false);
          setJaCadastrado(true);
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusaoOK && !exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro "{`${model.proTxNomeRazaoSocial}`}"?
          </Modal.Body>
        )}

        {exclusaoOK && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Proprietário "{`${model.proTxNomeRazaoSocial}`}" excluído com sucesso.
          </Modal.Body>
        )}

        {exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{`${mensagemErro}`}</Modal.Body>
        )}

        {!exclusaoErro && !exclusaoOK ? (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '13px' }}
              onClick={handleClose}
              label="Não"
            ></Button>
            <Button
              style={{ width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              label="Sim"
            ></Button>
          </Modal.Footer>
        ) : (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '13px' }}
              onClick={() => history.goBack()}
              label="OK"
            ></Button>
          </Modal.Footer>
        )}
      </Modal>

      <Dialog
        header={id ? 'Proprietário editado com sucesso!' : 'Proprietário cadastrado com sucesso!'}
        footer={<Button onClick={() => setCadastroRealizado(false)}>Ok</Button>}
        visible={cadastroRealizado}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.goBack();
        }}
      />

      {id ? (
        <button
          className="btn-desativar"
          style={{
            backgroundColor: 'transparent',
            borderColor: 'transparent',
            position: 'absolute',
            right: '2%',
          }}
          onClick={() => {
            if (canDelete) {
              setExclusaoErro(false);
              setExclusaoOK(false);
              handleShow();
            } else {
              history.push('/acessonegado');
            }
          }}
          // disabled={!permissoes[66] || !permissoes[66].excluir}
        >
          <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash"></i>
        </button>
      ) : null}
      <Form noValidate validated={isFormInvalid} onSubmit={handleEdit}>
        <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
          <Form.Label>
            <span className="txt-obrigatorio">*</span>Tipo de Pessoa
          </Form.Label>

          <Dropdown
            style={{ width: '100.4%' }}
            value={model.proTxTipoPessoa?.toLowerCase()}
            options={listaTipos}
            placeholder="Selecione"
            onChange={(e: any) => {
              setIsCpfValid(true);
              setIsCnpjValid(true);
              setIsNomeInvalid(false);
              setIsFormInvalid(false);
              setCpf('');
              setCnpj('');
              setModel({
                ...model,
                proTxTipoPessoa: e.target.value,
              });
            }}
            optionLabel="nome"
            optionValue="value"
            className="multiselect-custom"
            id="tipo"
          />
        </Form.Group>

        {model.proTxTipoPessoa === 'f' ? (
          <Form.Group>
            <Form.Label>
              <span className="txt-obrigatorio">*</span>CPF
            </Form.Label>
            <Form.Control
              value={cpfMask(cpf)}
              id="cpf"
              name="cpf"
              placeholder=""
              maxLength={14}
              required
              onChange={(event: any) => {
                if (checaCPF(event.target.value) === false) {
                  setCpf(cpfMask(event.target.value));
                  setIsCpfValid(false);
                } else {
                  setCpf(cpfMask(event.target.value));
                  setIsCpfValid(true);
                  setModel({
                    ...model,
                    proTxCpfCnpj: event.target.value,
                  });
                }
              }}
            />
            {!isCpfValid && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>CPF inválido.</p>
            )}
            {isCpfValid && jaCadastrado && soNumeros(cpf) !== cpfOriginal && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>CPF já cadastrado.</p>
            )}
          </Form.Group>
        ) : (
          <Form.Group>
            <Form.Label>
              <span className="txt-obrigatorio">*</span>CNPJ
            </Form.Label>
            <Form.Control
              value={maskCpnj(cnpj)}
              id="cnpj"
              name="cnpj"
              placeholder=""
              maxLength={18}
              required
              onChange={(event: any) => {
                setCnpj(event.target.value);
                setModel({
                  ...model,
                  proTxCpfCnpj: event.target.value,
                });
              }}
              onBlur={() => {
                if (soNumeros(cnpj).length === 14 || cnpj === '') {
                  setIsCnpjValid(true);
                } else {
                  setIsCnpjValid(false);
                }
              }}
            />
            {!isCnpjValid && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>CNPJ inválido.</p>
            )}
            {isCnpjValid && jaCadastrado && soNumeros(cnpj) !== cnpjOriginal && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>CNPJ já cadastrado.</p>
            )}
          </Form.Group>
        )}

        <Form.Row className="row-container-modal">
          <Form.Group className="row-modal">
            <Form.Label>
              {' '}
              <span className="txt-obrigatorio">*</span>Nome / Razão Social
            </Form.Label>
            <Form.Control
              onChange={(e) => {
                if (e.target.value === undefined || e.target.value == '') {
                  setIsNomeInvalid(true);
                } else {
                  setIsNomeInvalid(false);
                }
                setModel({
                  ...model,
                  proTxNomeRazaoSocial: e.target.value,
                });
              }}
              value={model?.proTxNomeRazaoSocial}
              type="text"
              id="razaoSocialProprietario"
              name="razaoSocialProprietario"
              placeholder=""
              style={{ width: '100%' }}
              required
            />
            {isNomeInvalid && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>
                Nome / Razão Social inválido(a).
              </p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="mt-2 ml-2">
            <Form.Check
              checked={ativo}
              onChange={(event) => {
                setAtivo(!ativo);
              }}
              name="ativo"
              type="switch"
              id="custom-switch1"
              label="Ativo"
            />
          </Form.Group>
        </Form.Row>

        <ButtonsForm canEdit={canEdit} />
      </Form>
    </Tela>
  );
};

export default EditarProprietario;
