/* eslint-disable */

import { useState, useEffect, ChangeEvent } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Form, Col, Button } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { useToast } from '../../../hooks/Toast';
import Input from '../../../components/Input';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import { updateMinerResult } from '../../../services/profileAnalysis';
import { GerenteType } from '../../../Types/Gerente';
import { orderBy } from 'lodash';
import { OPTIONS_LEGAL_CONTACT } from '../../../constants/optionsLegalContact';

export type AtualizarPesquisaType = {
  dataVencimento?: string;
  diasVencimento?: number;
  idDpe: number;
  idUsuarioLogado: number;
  resposta: string;
  tipoProcesso?: string;
  motivoAlteracao?: string;
  contatoJuridico: boolean;
  autorizadoPor: number;
};

export interface Pesquisa {
  numLiberacao: number;
  empresa: string;
  operacao: string;
  usuario: string;
  dataLancamento: string;
  resultado: string;
  tipo: string;
  idCliente: number;
  validadeAnaliseNaoMotoristaAutonomo: number;
  validadeAnaliseNaoMotoristaAgregado: number;
  validadeAnaliseNaoMotoristaFuncionario: number;
  validadeAnaliseCondutorAutonomo: number;
  validadeAnaliseVeiculoTerceiro: number;
  empresaAnalista: string;
  itens: [
    {
      statusPesquisaIntegrada: string;
      statusPesquisa: string;
      statusPesquisaCondutorCnh: string;
      statusPesquisaEntidade: boolean;
      codigoPesquisaDminerProcesso: string;
      codigoPesquisaDminerCnh: string;
      codigoPesquisaDminerVeiculo: null;
      itemPesquisado: string;
      descricao: string;
      tipo: string;
      idPesquisaEntidade: number;
      idPesquisaEntidadeCnh: number;
      dataRetorno: string;
      validade: string;
      validadePesquisaEntidade: string;
      tipoEnvioPesquisa: string;
      tipoPesquisa: string;
    }
  ];
}

const EditarAnalisePerfil = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { id }: { id: string } = useParams();

  const history = useHistory();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [mensagem, setMensagem] = useState<any>([]);
  const [pesquisaCond, setPesquisaCond] = useState<AtualizarPesquisaType | any>();
  const [pesquisaCnh, setPesquisaCnh] = useState<AtualizarPesquisaType | any>();
  const [loading, setLoading] = useState(false);

  const [pesquisaVeiculos, setPesquisaVeiculos] = useState<any>([]);

  const [pesquisa, setPesquisa] = useState<Pesquisa>();
  const { usuarioId } = getCookieSessionData().usuarioVO;
  const [listaGerente, setListaGerente] = useState<GerenteType[]>([]);

  useEffect(() => {
    rolePermission(
      [{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.PAINEL_ANALISE_PERFIL] }],
      'editar'
    );
  }, [ADMIN, rolePermission]);

  const body = {
    numeroLiberacao: id,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`/pesquisa/historico-gr`, body);

        const itensMap: any = [];

        response.data.items.map((it: any) => {
          itensMap.push(it.itens[0]);
        });

        const item = { ...response.data.items[0], itens: itensMap };

        setPesquisa(item);
      } catch (err: any) {
        console.log(err);
      }
    };

    const loadDataGerentes = async () => {
      const response = await axios.get('/gerente/listarGerentes');
      console.log(response);
      setListaGerente(orderBy(response.data, 'nome'));
    };

    loadDataGerentes();
    fetchData();
  }, [id]);

  useEffect(() => {
    if (!pesquisa?.itens) return;
    if (pesquisa?.itens?.length > 0) {
      setPesquisaCond({
        idDpe: pesquisa.itens[0]?.idPesquisaEntidade || null,
        tipoProcesso: 'PROCESSO',
        idUsuarioLogado: usuarioId,
        dataVencimento: pesquisa.itens[0]?.validade || null,
        itemPesquisado: pesquisa.itens[0]?.itemPesquisado || '',
      });
      setPesquisaCnh({
        idDpe: pesquisa.itens[0]?.idPesquisaEntidadeCnh || null,
        tipoProcesso: 'CNH',
        idUsuarioLogado: usuarioId,
        dataVencimento: pesquisa.itens[0]?.validade || null,
        itemPesquisado: pesquisa.itens[0]?.itemPesquisado || '',
      });

      const getPesquisaVeiculo = pesquisa.itens
        .filter(({ itemPesquisado }: any) => !itemPesquisado.includes('.'))
        .map((item: any) => ({
          idDpe: item.idPesquisaEntidade,
          tipoProcesso: 'VEICULO',
          idUsuarioLogado: usuarioId,
          dataVencimento: item.validade || '',
          itemPesquisado: item.itemPesquisado || '',
          tipo: item.tipo || '',
          statusPesquisa: item.statusPesquisa || '',
          motivoAlteracao: '',
        }));

      setPesquisaVeiculos(getPesquisaVeiculo);
    }
  }, [pesquisa, usuarioId]);

  const handleEdit = async (event: any) => {
    event.preventDefault();

    setLoading(true);

    const pesquisas = [];

    if (pesquisaCond.resposta && pesquisaCond.resposta !== 'Selecione') {
      pesquisas.push({
        idDpe: pesquisaCond.idDpe,
        idUsuarioAlteracao: usuarioId,
        idUsuarioLogado: usuarioId,
        motivoAlteracao: pesquisaCond.motivoAlteracao,
        resposta: pesquisaCond.resposta,
        tipoProcesso: pesquisaCond.tipoProcesso,
        contatoJuridico:
          pesquisaCond.contatoJuridico != null
            ? pesquisaCond.contatoJuridico
              ? 'True'
              : 'False'
            : null,
        autorizadoPor: pesquisaCond.autorizadoPor != null ? pesquisaCond.autorizadoPor : null,
      });
    }

    if (pesquisaCnh.resposta && pesquisaCnh.resposta !== 'Selecione') {
      pesquisas.push({
        idDpe: pesquisaCnh.idDpe,
        idUsuarioAlteracao: usuarioId,
        idUsuarioLogado: usuarioId,
        motivoAlteracao: pesquisaCnh.motivoAlteracao,
        resposta: pesquisaCnh.resposta,
        tipoProcesso: pesquisaCnh.tipoProcesso,
        contatoJuridico:
          pesquisaCnh.contatoJuridico != null
            ? pesquisaCnh.contatoJuridico
              ? 'True'
              : 'False'
            : null,
        autorizadoPor: pesquisaCnh.autorizadoPor != null ? pesquisaCnh.autorizadoPor : null,
      });
    }

    const veiculosAtualizados = pesquisaVeiculos.filter(
      ({ newStatusPesquisa }: any) => newStatusPesquisa
    );

    veiculosAtualizados.forEach((veic: any) => {
      pesquisas.push({
        idDpe: veic.idDpe,
        idUsuarioAlteracao: usuarioId,
        idUsuarioLogado: usuarioId,
        motivoAlteracao: veic.newMotivoAlteracao,
        resposta: veic.newStatusPesquisa,
        tipoProcesso: veic.tipoProcesso,
        contatoJuridico:
          veic.contatoJuridico != null ? (veic.contatoJuridico ? 'True' : 'False') : null,
        autorizadoPor: veic.autorizadoPor != null ? veic.autorizadoPor : null,
      });
    });

    try {
      const response = await axios.post('/pesquisa/atualizar-dados-pesquisa', { pesquisas });

      setMensagem(response.data.detail);
    } catch (error) {
      console.log(error);

      const dataResponses = veiculosAtualizados
        .concat([pesquisaCond, pesquisaCnh])
        .map((item: any) => {
          return `${item.itemPesquisado} - erro ao atualizar pesquisa`;
        });
      setMensagem(dataResponses);
    }

    setIsDialogCadOK(true);
    setLoading(false);
  };

  const handleBack = () => {
    window.close();
  };

  const retornaResultado = (result: string) => {
    switch (result) {
      case 'EM_ANDAMENTO':
        return 'Em Andamento';
      case 'EM_ACORDO':
        return 'Em Acordo';
      case 'PENDENTE_DE_DOCUMENTACAO':
        return 'Pendente de Documentação';
      default:
        return '';
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Análise de Perfil</h2>
          <h6 className="subtitulo">{'Análise de Perfil  > Atualização '}</h6>
        </div>

        {pesquisa && (
          <Form
            onSubmit={(e: any) => {
              if (canEdit) {
                handleEdit(e);
              } else {
                history.push('/acessonegado');
              }
            }}
          >
            <Form.Row>
              <Input
                required
                value={pesquisa.numLiberacao}
                onChange={() => false}
                id="cpf"
                name="cpf"
                textInputTitle="Número:"
                disabled
              />
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <hr />
              </Form.Group>
            </Form.Row>

            {pesquisa.operacao?.includes('Integrada') || pesquisa.operacao?.includes('Condutor') ? (
              <>
                <Form.Label className="SMLancadasTitle">Dados da pesquisa Condutor</Form.Label>
                <Form.Row style={{ marginTop: 20 }}>
                  <Input
                    required
                    value={pesquisa.itens[0].itemPesquisado}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="CPF:"
                    disabled
                  />
                  <Input
                    required
                    value={pesquisa.itens[0].descricao}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="NOME:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    required
                    value={retornaResultado(pesquisa.itens[0].statusPesquisa)}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="SITUAÇÃO:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Nova Situação</Form.Label>
                    <Form.Control
                      value={pesquisaCond ? pesquisaCond.resposta : null}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCond({
                          ...pesquisaCond,
                          resposta: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      <option value="EM_ANDAMENTO">Em andamento</option>
                      <option value="EM_ACORDO">Em acordo</option>
                      <option value="PENDENTE_DE_DOCUMENTACAO">Pendente de documentação</option>
                    </Form.Control>
                  </Form.Group>
                  <Input
                    value={pesquisaCond ? pesquisaCond.motivoAlteracao : null}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPesquisaCond({
                        ...pesquisaCond,
                        motivoAlteracao: e.target.value,
                      })
                    }
                    textInputTitle="MOTIVO:"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Contato Jurídico?</Form.Label>
                    <Form.Control
                      value={
                        pesquisaCond && pesquisaCond.contatoJuridico !== undefined
                          ? String(pesquisaCond.contatoJuridico)
                          : ''
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCond({
                          ...pesquisaCond,
                          contatoJuridico:
                            e.target.value === '' ? undefined : e.target.value === 'true',
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      {OPTIONS_LEGAL_CONTACT.map((option: { label: string; value: boolean }) => (
                        <option key={option.label} value={String(option.value)}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Autorizado por:</Form.Label>
                    <Form.Control
                      value={pesquisaCond ? pesquisaCond.autorizadoPor || '' : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCond({
                          ...pesquisaCond,
                          autorizadoPor: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>

                      {listaGerente.map((gerente: GerenteType) => (
                        <option key={gerente.id} value={gerente.id}>
                          {gerente.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  <Form.Group as={Col}>
                    <hr />
                  </Form.Group>
                </Form.Row>

                <Form.Label className="SMLancadasTitle">Dados da pesquisa CNH</Form.Label>

                <Form.Row style={{ marginTop: 20 }}>
                  <Input
                    required
                    value={pesquisa.itens[0].itemPesquisado}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="CPF:"
                    disabled
                  />
                  <Input
                    required
                    value={pesquisa.itens[0].descricao}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="NOME:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    required
                    value={retornaResultado(pesquisa.itens[0].statusPesquisaCondutorCnh)}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="SITUAÇÃO:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6}>
                    <Form.Label style={{ marginTop: '10px' }}>Nova Situação</Form.Label>
                    <Form.Control
                      value={pesquisaCnh ? pesquisaCnh.resposta : 0}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCnh({
                          ...pesquisaCnh,
                          resposta: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      <option value="EM_ANDAMENTO">Em andamento</option>
                      <option value="EM_ACORDO">Em acordo</option>
                      <option value="PENDENTE_DE_DOCUMENTACAO">Pendente de documentação</option>
                    </Form.Control>
                  </Form.Group>

                  <Input
                    value={pesquisaCnh ? pesquisaCnh.motivoAlteracao : null}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPesquisaCnh({
                        ...pesquisaCnh,
                        motivoAlteracao: e.target.value,
                      })
                    }
                    textInputTitle="MOTIVO:"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Contato Jurídico?</Form.Label>
                    <Form.Control
                      value={
                        pesquisaCnh && pesquisaCnh.contatoJuridico !== undefined
                          ? String(pesquisaCnh.contatoJuridico)
                          : ''
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCnh({
                          ...pesquisaCnh,
                          contatoJuridico:
                            e.target.value === '' ? undefined : e.target.value === 'true',
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      {OPTIONS_LEGAL_CONTACT.map((option: { label: string; value: boolean }) => (
                        <option key={option.label} value={String(option.value)}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Autorizado por:</Form.Label>
                    <Form.Control
                      value={pesquisaCnh ? pesquisaCnh.autorizadoPor || '' : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCnh({
                          ...pesquisaCnh,
                          autorizadoPor: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>

                      {listaGerente.map((gerente: GerenteType) => (
                        <option key={gerente.id} value={gerente.id}>
                          {gerente.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col}>
                    <hr />
                  </Form.Group>
                </Form.Row>
              </>
            ) : null}
            <Dialog
              header={''}
              footer={<Button onClick={() => handleBack()}>Ok</Button>}
              visible={isDialogCadOK}
              style={{ width: '50vw' }}
              modal
              onHide={() => {
                history.goBack();
              }}
            >
              <ul>
                {mensagem.map((msg: any, i: any) => (
                  <li key={i}>{msg}</li>
                ))}
              </ul>
            </Dialog>

            {pesquisa.operacao?.includes('Integrada') || pesquisa.operacao?.includes('Veículo') ? (
              <>
                {pesquisaVeiculos.length > 0 &&
                  pesquisaVeiculos.map((veiculo: any, i: any) => (
                    <>
                      <Form.Label className="SMLancadasTitle">Dados da pesquisa Veículo</Form.Label>
                      <Form.Row style={{ marginTop: 20 }}>
                        <Input
                          required
                          value={veiculo.itemPesquisado}
                          onChange={() => false}
                          textInputTitle="PLACA:"
                          disabled
                        />
                        <Input
                          required
                          value={veiculo.tipo}
                          onChange={() => false}
                          textInputTitle="TIPO:"
                          disabled
                        />
                      </Form.Row>
                      <Form.Row>
                        <Input
                          required
                          value={retornaResultado(veiculo.statusPesquisa)}
                          onChange={() => false}
                          textInputTitle="SITUAÇÃO:"
                          disabled
                        />
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md={6}>
                          <Form.Label style={{ marginTop: '10px' }}>Nova Situação</Form.Label>
                          <Form.Control
                            value={pesquisaVeiculos ? pesquisaVeiculos[i].newStatusPesquisa : 0}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const veiculosAtualizado = pesquisaVeiculos.map((car: any) =>
                                car.idDpe === veiculo.idDpe
                                  ? { ...car, newStatusPesquisa: e.target.value }
                                  : car
                              );
                              setPesquisaVeiculos(veiculosAtualizado);
                            }}
                            as="select"
                          >
                            <option value="">Selecione</option>
                            <option value="EM_ANDAMENTO">Em andamento</option>
                            <option value="EM_ACORDO">Em acordo</option>
                            <option value="PENDENTE_DE_DOCUMENTACAO">
                              Pendente de documentação
                            </option>
                          </Form.Control>
                        </Form.Group>

                        <Input
                          value={pesquisaVeiculos ? pesquisaVeiculos[i].newMotivoAlteracao : null}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const veiculosAtualizado = pesquisaVeiculos.map((car: any) =>
                              car.idDpe === veiculo.idDpe
                                ? { ...car, newMotivoAlteracao: e.target.value }
                                : car
                            );
                            setPesquisaVeiculos(veiculosAtualizado);
                          }}
                          textInputTitle="MOTIVO:"
                        />
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                          <Form.Label>Contato Jurídico?</Form.Label>
                          <Form.Control
                            value={
                              pesquisaVeiculos && veiculo.contatoJuridico !== undefined
                                ? String(veiculo.contatoJuridico)
                                : ''
                            }
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const veiculosAtualizado = pesquisaVeiculos.map((car: any) =>
                                car.idDpe === veiculo.idDpe
                                  ? {
                                      ...car,
                                      contatoJuridico:
                                        e.target.value === ''
                                          ? undefined
                                          : e.target.value === 'true',
                                    }
                                  : car
                              );

                              setPesquisaVeiculos(veiculosAtualizado);
                            }}
                            as="select"
                          >
                            <option value="">Selecione</option>
                            {OPTIONS_LEGAL_CONTACT.map(
                              (option: { label: string; value: boolean }) => (
                                <option key={option.label} value={String(option.value)}>
                                  {option.label}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                          <Form.Label>Autorizado por:</Form.Label>
                          <Form.Control
                            value={veiculo.autorizadoPor || ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              const veiculosAtualizado = pesquisaVeiculos.map((car: any) =>
                                car.idDpe === veiculo.idDpe
                                  ? {
                                      ...car,
                                      autorizadoPor:
                                        e.target.value === '' ? undefined : e.target.value,
                                    }
                                  : car
                              );

                              setPesquisaVeiculos(veiculosAtualizado);
                            }}
                            as="select"
                          >
                            <option value="">Selecione</option>
                            {listaGerente.map((gerente: GerenteType) => (
                              <option key={gerente.id} value={gerente.id}>
                                {gerente.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col}>
                          <hr />
                        </Form.Group>
                      </Form.Row>
                    </>
                  ))}
              </>
            ) : null}
            {pesquisa.operacao?.includes('Ajudante') && (
              <>
                <Form.Label className="SMLancadasTitle">Dados da pesquisa Ajudante</Form.Label>
                <Form.Row style={{ marginTop: 20 }}>
                  <Input
                    required
                    value={pesquisa.itens[0].itemPesquisado}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="CPF:"
                    disabled
                  />
                  <Input
                    required
                    value={pesquisa.itens[0].descricao}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="NOME:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Input
                    required
                    value={retornaResultado(pesquisa.itens[0].statusPesquisa)}
                    onChange={() => false}
                    id="cpf"
                    name="cpf"
                    textInputTitle="SITUAÇÃO:"
                    disabled
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Nova Situação</Form.Label>
                    <Form.Control
                      value={pesquisaCond ? pesquisaCond.resposta : null}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCond({
                          ...pesquisaCond,
                          resposta: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      <option value="EM_ANDAMENTO">Em andamento</option>
                      <option value="EM_ACORDO">Em acordo</option>
                      <option value="PENDENTE_DE_DOCUMENTACAO">Pendente de documentação</option>
                    </Form.Control>
                  </Form.Group>
                  <Input
                    value={pesquisaCond ? pesquisaCond.motivoAlteracao : null}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setPesquisaCond({
                        ...pesquisaCond,
                        motivoAlteracao: e.target.value,
                      })
                    }
                    textInputTitle="MOTIVO:"
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Contato Jurídico?</Form.Label>
                    <Form.Control
                      value={
                        pesquisaCond && pesquisaCond.contatoJuridico !== undefined
                          ? String(pesquisaCond.contatoJuridico)
                          : ''
                      }
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCond({
                          ...pesquisaCond,
                          contatoJuridico:
                            e.target.value === '' ? undefined : e.target.value === 'true',
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>
                      {OPTIONS_LEGAL_CONTACT.map((option: { label: string; value: boolean }) => (
                        <option key={option.label} value={String(option.value)}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group as={Col} md={6} style={{ marginTop: '6px' }}>
                    <Form.Label>Autorizado por:</Form.Label>
                    <Form.Control
                      value={pesquisaCond ? pesquisaCond.autorizadoPor || '' : ''}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setPesquisaCond({
                          ...pesquisaCond,
                          autorizadoPor: e.target.value,
                        })
                      }
                      as="select"
                    >
                      <option value="">Selecione</option>

                      {listaGerente.map((gerente: GerenteType) => (
                        <option key={gerente.id} value={gerente.id}>
                          {gerente.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              </>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }} />
            <Form.Row className="container-buttons justify-content-end">
              <Form.Group as={Col} md={2}>
                <Button className="btn-cancelar" onClick={handleBack}>
                  Cancelar
                </Button>
              </Form.Group>
              <Form.Group as={Col} md={2}>
                <Button className="btn-enviar" type="submit">
                  {loading ? 'Atualizando...' : 'Confirmar'}
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
        )}
      </div>
    </div>
  );
};

export default EditarAnalisePerfil;
