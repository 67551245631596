/* eslint-disable */

function dataExpirou(validade: any, validadePE: any, statusPE: boolean) {
  if (validade === 'No Embarque') {
    if (!statusPE) return true;

    const now = new Date().toISOString();
    const dataAtual = new Date(`${now.split('T')[0]} 00:00`);
    const dataValidade = new Date(`${validadePE} 00:00`);

    return dataValidade < dataAtual;
  }

  const now = new Date().toISOString();
  const dataAtual = new Date(`${now.split('T')[0]} 00:00`);
  const dataValidade = new Date(`${validade} 00:00`);

  return dataValidade < dataAtual;
}

/* eslint-disable */
export const handleResultado = (pesquisas: any) => {

  const verifyIntegratedSearch = pesquisas.find((pesquisa: { statusPesquisaIntegrada: string }) => pesquisa.statusPesquisaIntegrada)

  if (verifyIntegratedSearch) {
    const { statusPesquisaIntegrada } = verifyIntegratedSearch

    if (statusPesquisaIntegrada === 'ANALISE_VENCIDA') {
      return 'Análise Vencida'
    } else if (statusPesquisaIntegrada === 'EM_ANDAMENTO') {
      return 'Em Andamento';
    } else if (statusPesquisaIntegrada === 'PENDENTE_DE_DOCUMENTACAO') {
      return 'Pendente de Documentação';
    } else if (statusPesquisaIntegrada === 'EM_ACORDO') {
      return 'Em Acordo'
    } else {
      return ''
    }
  }

  var pesquisa3 = '';
  var pesquisa2 = '';
  var pesquisa1 = '';
  var pesquisa0 = '';

  if (pesquisas[3]) {
    if (
      dataExpirou(
        pesquisas[3].validade,
        pesquisas[3].validadePesquisaEntidade,
        pesquisas[3].statusPesquisaEntidade
      )
    ) {
      pesquisa3 = 'ANALISE_VENCIDA';
    }
    else if (
      (pesquisas[3].validade && pesquisas[3].validade.includes('Analíse Vencida'))
    ) {
      pesquisa3 = 'ANALISE_VENCIDA';
    }
    else if (
      pesquisas[3].statusPesquisa === 'EM_ANDAMENTO' ||
      pesquisas[3].statusPesquisaCondutorCnh === 'EM_ANDAMENTO'
    ) {
      pesquisa3 = 'EM_ANDAMENTO';
    } else if (
      pesquisas[3].statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisas[3].statusPesquisaCondutorCnh === 'PENDENTE_DE_DOCUMENTACAO'
    ) {
      pesquisa3 = 'PENDENTE_DE_DOCUMENTACAO';
    } else if (
      (pesquisas[3].statusPesquisa === 'EM_ACORDO' &&
        pesquisas[3].statusPesquisaCondutorCnh === 'EM_ACORDO') ||
      (pesquisas[3].statusPesquisa === 'EM_ACORDO' &&
        pesquisas[3].statusPesquisaCondutorCnh === null)
    ) {
      pesquisa3 = 'EM_ACORDO';
    }
  }
  if (pesquisas[2]) {
    if (
      dataExpirou(
        pesquisas[2].validade,
        pesquisas[2].validadePesquisaEntidade,
        pesquisas[2].statusPesquisaEntidade
      )
    ) {
      pesquisa2 = 'ANALISE_VENCIDA';
    }
    else if (pesquisas[2].validade && pesquisas[2].validade.includes('Analíse Vencida')) {
      pesquisa3 = 'ANALISE_VENCIDA';
    }
    else if (
      pesquisas[2].statusPesquisa === 'EM_ANDAMENTO' ||
      pesquisas[2].statusPesquisaCondutorCnh === 'EM_ANDAMENTO'
    ) {
      pesquisa2 = 'EM_ANDAMENTO';
    } else if (
      pesquisas[2].statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisas[2].statusPesquisaCondutorCnh === 'PENDENTE_DE_DOCUMENTACAO'
    ) {
      pesquisa2 = 'PENDENTE_DE_DOCUMENTACAO';
    } else if (
      (pesquisas[2].statusPesquisa === 'EM_ACORDO' &&
        pesquisas[2].statusPesquisaCondutorCnh === 'EM_ACORDO') ||
      (pesquisas[2].statusPesquisa === 'EM_ACORDO' &&
        pesquisas[2].statusPesquisaCondutorCnh === null)
    ) {
      pesquisa2 = 'EM_ACORDO';
    }
  }
  if (pesquisas[1]) {
    if (
      dataExpirou(
        pesquisas[1].validade,
        pesquisas[1].validadePesquisaEntidade,
        pesquisas[1].statusPesquisaEntidade
      )
    ) {
      pesquisa1 = 'ANALISE_VENCIDA';
    }
    else if (pesquisas[1].validade && pesquisas[1].validade.includes('Analíse Vencida')) {
      pesquisa3 = 'ANALISE_VENCIDA';
    }
    else if (
      pesquisas[1].statusPesquisa === 'EM_ANDAMENTO' ||
      pesquisas[1].statusPesquisaCondutorCnh === 'EM_ANDAMENTO'
    ) {
      pesquisa1 = 'EM_ANDAMENTO';
    } else if (
      pesquisas[1].statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisas[1].statusPesquisaCondutorCnh === 'PENDENTE_DE_DOCUMENTACAO'
    ) {
      pesquisa1 = 'PENDENTE_DE_DOCUMENTACAO';
    } else if (
      (pesquisas[1].statusPesquisa === 'EM_ACORDO' &&
        pesquisas[1].statusPesquisaCondutorCnh === 'EM_ACORDO') ||
      (pesquisas[1].statusPesquisa === 'EM_ACORDO' &&
        pesquisas[1].statusPesquisaCondutorCnh === null)
    ) {
      pesquisa1 = 'EM_ACORDO';
    }
  }
  if (pesquisas[0]) {
    if (
      dataExpirou(
        pesquisas[0].validade,
        pesquisas[0].validadePesquisaEntidade,
        pesquisas[0].statusPesquisaEntidade
      )
    ) {
      pesquisa0 = 'ANALISE_VENCIDA';
    }
    else if (pesquisas[0].validade && pesquisas[0].validade.includes('Analíse Vencida')) {
      pesquisa0 = 'ANALISE_VENCIDA';
    }
    else if (
      pesquisas[0].statusPesquisa === 'EM_ANDAMENTO' ||
      pesquisas[0].statusPesquisaCondutorCnh === 'EM_ANDAMENTO'
    ) {
      pesquisa0 = 'EM_ANDAMENTO';
    } else if (
      pesquisas[0].statusPesquisa === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisas[0].statusPesquisaCondutorCnh === 'PENDENTE_DE_DOCUMENTACAO'
    ) {
      pesquisa0 = 'PENDENTE_DE_DOCUMENTACAO';
    } else if (
      (pesquisas[0].statusPesquisa === 'EM_ACORDO' &&
        pesquisas[0].statusPesquisaCondutorCnh === 'EM_ACORDO') ||
      (pesquisas[0].statusPesquisa === 'EM_ACORDO' &&
        pesquisas[0].statusPesquisaCondutorCnh === null)
    ) {
      pesquisa0 = 'EM_ACORDO';
    }
  }
  if (pesquisas.length === 4) {
    if (
      pesquisa3 === 'ANALISE_VENCIDA' ||
      pesquisa2 === 'ANALISE_VENCIDA' ||
      pesquisa1 === 'ANALISE_VENCIDA' ||
      pesquisa0 === 'ANALISE_VENCIDA'
    ) {
      return 'Análise Vencida';
    } else if (
      pesquisa3 === 'EM_ANDAMENTO' ||
      pesquisa2 === 'EM_ANDAMENTO' ||
      pesquisa1 === 'EM_ANDAMENTO' ||
      pesquisa0 === 'EM_ANDAMENTO'
    ) {
      return 'Em Andamento';
    } else if (
      pesquisa3 === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisa2 === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisa1 === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisa0 === 'PENDENTE_DE_DOCUMENTACAO'
    ) {
      return 'Pendente de Documentação';
    } else if (
      pesquisa3 === 'EM_ACORDO' &&
      pesquisa2 === 'EM_ACORDO' &&
      pesquisa1 === 'EM_ACORDO' &&
      pesquisa0 === 'EM_ACORDO'
    ) {
      return 'Em Acordo';
    }
  }
  if (pesquisas.length === 3) {
    if (
      pesquisa2 === 'ANALISE_VENCIDA' ||
      pesquisa1 === 'ANALISE_VENCIDA' ||
      pesquisa0 === 'ANALISE_VENCIDA'
    ) {
      return 'Análise Vencida';
    } else if (
      pesquisa2 === 'EM_ANDAMENTO' ||
      pesquisa1 === 'EM_ANDAMENTO' ||
      pesquisa0 === 'EM_ANDAMENTO'
    ) {
      return 'Em Andamento';
    } else if (
      pesquisa2 === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisa1 === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisa0 === 'PENDENTE_DE_DOCUMENTACAO'
    ) {
      return 'Pendente de Documentação';
    } else if (
      pesquisa2 === 'EM_ACORDO' &&
      pesquisa1 === 'EM_ACORDO' &&
      pesquisa0 === 'EM_ACORDO'
    ) {
      return 'Em Acordo';
    }
  }
  if (pesquisas.length === 2) {
    if (pesquisa1 === 'ANALISE_VENCIDA' || pesquisa0 === 'ANALISE_VENCIDA') {
      return 'Análise Vencida';
    } else if (pesquisa1 === 'EM_ANDAMENTO' || pesquisa0 === 'EM_ANDAMENTO') {
      return 'Em Andamento';
    } else if (
      pesquisa1 === 'PENDENTE_DE_DOCUMENTACAO' ||
      pesquisa0 === 'PENDENTE_DE_DOCUMENTACAO'
    ) {
      return 'Pendente de Documentação';
    } else if (pesquisa1 === 'EM_ACORDO' && pesquisa0 === 'EM_ACORDO') {
      return 'Em Acordo';
    }
  }
  if (pesquisas.length === 1) {
    if (pesquisa0 === 'ANALISE_VENCIDA') {
      return 'Análise Vencida';
    } else if (pesquisa0 === 'EM_ANDAMENTO') {
      return 'Em Andamento';
    } else if (pesquisa0 === 'PENDENTE_DE_DOCUMENTACAO') {
      return 'Pendente de Documentação';
    } else if (pesquisa0 === 'EM_ACORDO') {
      return 'Em Acordo';
    }
  } else {
    return '';
  }
};
