/* eslint-disable */
import { obterDataHora } from './../../util/date';

type ExcelGeneration2 = {
  ySteps?: number;
  xSteps?: number;
  columns: { title: any | undefined; widthPx?: any; style?: any; width?: any }[];
  data: { value: any | undefined; style?: any }[][];
};

const RelatorioXLSCliente = (state: any, setExcelGenerationCliente: any) => {
  const borders = {
    top: { style: 'medium' },
    bottom: { style: 'medium' },
    left: { style: 'medium' },
    right: { style: 'medium' },
  };

  const alignment = {
    horizontal: 'center',
    wrapText: true,
  };

  const borders2 = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  let excel: ExcelGeneration2[] = [
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Relatório SAT',
          widthPx: 1060,
          style: {
            alignment: alignment,
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 1,
      ySteps: 0,
      columns: [],
      data: [],
    },
  ];

  const mergeNomesDispositivos = (checklistComDefeito: any): string => {
    let nomesDispositivo: string = '';
    checklistComDefeito.forEach((dispositivo: any) => {
      nomesDispositivo +=
        nomesDispositivo === '' ? dispositivo.descricao : ',' + dispositivo.descricao;
    });
    return nomesDispositivo;
  };

  const loadDados = () => {
    state.listagem.map((veiculo: any, i: any) => {
      if (veiculo.listaDispositivo.length > 0) {
        var tableData = {
          xSteps: 0,
          ySteps: 0,
          columns: [
            {
              title: 'Placa',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Dispositivos com Defeito',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Responsável',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Nº SAT',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Lançamento',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
          ],

          data: [
            [
              {
                value: `${
                  veiculo.placaVeiculo !== null
                    ? veiculo.placaVeiculo
                    : veiculo.placaCarreta !== null
                    ? veiculo.placaCarreta
                    : veiculo.numeroBau !== null
                    ? veiculo.numeroBau
                    : ''
                }`,
                style: { border: borders2, alignment: alignment },
              },
              {
                value: `${mergeNomesDispositivos(veiculo.listaDispositivo)}`,
                style: { border: borders2 },
              },
              {
                value: veiculo.responsavel ? veiculo.responsavel : '',
                style: { border: borders2, alignment: alignment },
              },
              {
                value: veiculo.numeroSat !== null ? veiculo.numeroSat : '',
                style: { border: borders2, alignment: alignment },
              },
              {
                value: `${veiculo.dataInclusao ? obterDataHora(veiculo.dataInclusao, false) : ''} ${
                  veiculo.usuarioLancamento ? veiculo.usuarioLancamento : ''
                }`,
                style: { border: borders2, alignment: alignment },
              },
            ],
          ],
        };

        var linhaEntreVeiculos = {
          xSteps: 2,
          ySteps: 0,
          columns: [],
          data: [],
        };

        excel.push(tableData);
        excel.push(linhaEntreVeiculos);
      }
    });
  };

  loadDados();
  setExcelGenerationCliente(excel);
};

export default RelatorioXLSCliente;
