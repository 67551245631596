/* eslint-disable */
import getTipoFrota from '../../util/Validacoes/getTipoFrota';
import { obterData, obterDataHora } from '../../util/date';

type ExcelGeneration2 = {
  ySteps?: number;
  xSteps?: number;
  columns: { title: any | undefined; widthPx?: any; style?: any; width?: any }[];
  data: { value: any | undefined; style?: any }[][];
};

const RelatorioXLSAdmin = (state: any, setExcelGenerationAdmin: any) => {
  const borders = {
    top: { style: 'medium' },
    bottom: { style: 'medium' },
    left: { style: 'medium' },
    right: { style: 'medium' },
  };

  const alignment = {
    horizontal: 'center',
    wrapText: true,
  };

  // style linhas
  const borders2 = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  const borderTitle = {
    bottom: { style: 'medium' },
  };

  // pegar dados

  let excel: ExcelGeneration2[] = [
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Relatório SAT',
          widthPx: 1060,
          style: {
            alignment: alignment,
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 2,
      ySteps: 2,
      columns: [
        {
          title: `Empresa: ${state.listagem ? state.listagem[0].idCliente : ''} ${
            state.listagem ? state.listagem[0].razaoSocialCliente : ''
          }`,
          width: { wpx: 2000 },
          style: { alignment: alignment, font: { sz: '12' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: `Lançadas entre: ${
            state.model.dataInicio ? obterData(state.model.dataInicio) : '/ /'
          } e ${state.model.dataFim ? obterData(state.model.dataFim) : '/ /'}`,
          width: { wpx: 2000 },
          style: { alignment: alignment, font: { sz: '12' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 1,
      ySteps: 0,
      columns: [],
      data: [],
    },
  ];

  const loadTitle = (veiculo: any) => {
    var title = {
      ySteps: 0,
      columns: [
        {
          title:
            veiculo.placaCarreta != null && veiculo.placaVeiculo != null
              ? `Carreta: ${veiculo.placaCarreta}`
              : veiculo.placaVeiculo != null && veiculo.placaCarreta === null
              ? `Veículo: ${veiculo.placaVeiculo}`
              : veiculo.numeroBau != null
              ? `Báu: ${veiculo.numeroBau}`
              : '',
          width: { wch: 40 },
          style: { border: borderTitle, font: { sz: '12', bold: true } },
        },
        {
          title: veiculo.tipoFrotaCarreta
            ? getTipoFrota(veiculo.tipoFrotaCarreta)
            : veiculo.tipoFrotaVeiculo
            ? getTipoFrota(veiculo.tipoFrotaVeiculo)
            : '',
          width: { wch: 20 },
          style: { border: borderTitle, font: { sz: '12', bold: true } },
        },
        {
          title: `Nº SAT: ${veiculo.numeroSat}`,
          width: { wch: 40 },
          style: { border: borderTitle, font: { sz: '12', bold: true } },
        },
        {
          title: '',
          width: { wch: 40 },
          style: { border: borderTitle },
        },
      ],
      data: [],
    };
    excel.push(title);
  };

  const loadDados = () => {
    const length = state.listagem.length;
    state.listagem.map((veiculo: any, i: any) => {
      const arrayListaDispositivos = veiculo.listaDispositivo;
      if (arrayListaDispositivos.length > 0) {
        loadTitle(veiculo);
        var tableData = {
          xSteps: 0,
          ySteps: 0,
          columns: [
            {
              title: 'Lançamentos SAT',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Situação',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Lançamento',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
            {
              title: 'Finalização',
              width: { wch: 40 },
              style: { border: borders, font: { sz: '12' }, alignment: alignment },
            },
          ],

          data: arrayListaDispositivos.map((each: any, idx: any) => [
            {
              value: each.descricao !== null ? each.descricao : '',
              style: { border: borders2 },
            },
            {
              value: each.situacao === true ? 'Defeito' : 'Ok',
              style: { border: borders2 },
            },
            {
              value: `${veiculo.dataInclusao ? obterDataHora(veiculo.dataInclusao) : ''} ${
                veiculo.usuarioLancamento ? veiculo.usuarioLancamento : ''
              }`,
              style: { border: borders2 },
            },
            {
              value: `${veiculo.dataFinalizacao ? obterDataHora(veiculo.dataFinalizacao) : ''} ${
                veiculo.usuarioFinalizacao && veiculo.dataFinalizacao != null
                  ? veiculo.usuarioFinalizacao
                  : ''
              }`,
              style: { border: borders2 },
            },
          ]),
        };

        var linhaEntreVeiculos = {
          xSteps: 2,
          ySteps: 0,
          columns: [],
          data: [],
        };

        excel.push(tableData);
        excel.push(linhaEntreVeiculos);
      }
    });
  };

  loadDados();
  setExcelGenerationAdmin(excel);
};
export default RelatorioXLSAdmin;
