import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { cpfMask, maskCpnj } from '../../util/Validacoes/Validacoes';
import { FiltroProprietario } from './ConsultarProprietario';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

type ListagemConsulta = {
  idProprietario: number;
  cliente: string;
  cnpjProprietario: string;
  razaoSocialProprietario: string;
  tipoPessoa: string;
};

const ListagemConsultaProprietario = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canDelete } = useRolePermission();
  const history = useHistory();
  const [nomeProprietario, setNomeProprietario] = useState<string | undefined>();
  const [idProprietario, setIdProprietario] = useState<number | undefined>();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [edicaoAcessada, setEdicaoAcessada] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const location: {
    state: { listagem: ListagemConsulta[]; model: FiltroProprietario };
  } = useLocation();
  const { state } = location;
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const titles = ['+id', 'Cliente', 'CPF/CNPJ', 'Nome / Razão Social'];
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>([
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Proprietários',
          widthPx: 1060,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        { title: 'Empresa', width: { wch: 30 } },
        { title: 'CPF / CNPJ', width: { wch: 20 } },
        { title: 'Nome / Razão Social', width: { wch: 30 } },
        { title: 'Tipo Pessoa', width: { wch: 10 } },
      ],
      data: state.listagem.map((each) => [
        { value: each.cliente ? each.cliente : '' },
        {
          value: each.cnpjProprietario
            ? each.cnpjProprietario.length === 11
              ? cpfMask(each.cnpjProprietario)
              : maskCpnj(each.cnpjProprietario)
            : '',
        },
        { value: each.razaoSocialProprietario ? each.razaoSocialProprietario : '' },
        { value: each.tipoPessoa === 'f' ? 'Física' : each.tipoPessoa === 'J' ? 'Jurídica' : '' },
      ]),
    },
  ]);

  const handleDelete = async (id: number | undefined) => {
    try {
      const response = await axios.post(`/proprietario/delete-logico/${id}`);
      console.log(response);
      setExclusaoOK(true);
    } catch (err: any) {
      console.log(err.response);
      setExclusaoErro(true);
      setMensagemErro('Erro ao excluir!');
    }
  };

  const handleObject = (listagem: any[]) => {
    const array = listagem.map((each: ListagemConsulta) => {
      return [
        each.idProprietario,
        each.cliente,
        each.cnpjProprietario.length === 11
          ? cpfMask(each.cnpjProprietario)
          : maskCpnj(each.cnpjProprietario),
        each.razaoSocialProprietario,
      ];
    });
    return array;
  };

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.PROPRIETARIO] }], 'excluir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    setDataTabela(handleObject(state.listagem));
    const ajudante = state.listagem
      ?.filter((aju) => aju.idProprietario === idProprietario)
      .find((each) => each.razaoSocialProprietario);
    setNomeProprietario(ajudante?.razaoSocialProprietario);
  }, [state, idProprietario]);

  useEffect(() => {
    const reloadDados = async () => {
      try {
        const response = await axios.get(`/proprietario/gerenciar-proprietario`, {
          params: {
            ...state.model,
          },
        });
        setDataTabela(handleObject(response.data));
      } catch (err: any) {
        console.log(err.response);
      }
    };
    reloadDados();
  }, [edicaoAcessada]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(dataTabela, searchTerm));
    }
  }, [searchTerm]);

  return (
    <Tela
      nome="Proprietário"
      caminho="Cadastro > Gerenciamento de Clientes > Proprietário"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchTerm}
      search={search}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={currentPage.toString()}
      excelGeneration={excelGeneration}
      filename="Proprietário"
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          left
          onClickEditar={(id: any) => {
            setEdicaoAcessada(true);
            history.push({
              pathname: `/editar-proprietario/${id}`,
            });
          }}
          onClickExcluir={(id: any) => {
            if (canDelete) {
              setExclusaoErro(false);
              setExclusaoOK(false);
              handleShow();
              setIdProprietario(id);
            } else {
              history.push('/acessonegado');
            }
          }}
        />
      )}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          {!exclusaoOK && !exclusaoErro && (
            <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
          )}
          {exclusaoOK && <Modal.Title id="contained-modal-title-vcenter">Sucesso!</Modal.Title>}
        </Modal.Header>

        {!exclusaoOK && !exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro: {`${nomeProprietario}`}?
          </Modal.Body>
        )}

        {exclusaoOK && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Registro excluído com sucesso.
          </Modal.Body>
        )}

        {exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{`${mensagemErro}`}</Modal.Body>
        )}

        {!exclusaoErro && !exclusaoErro && !exclusaoOK && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '2%' }}
              onClick={handleClose}
              label="Não"
            />
            <Button
              style={{ width: '120px', height: '50px' }}
              variant="secondary"
              onClick={() => handleDelete(idProprietario)}
              label="Sim"
            />
          </Modal.Footer>
        )}
        {exclusaoOK && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '2%' }}
              onClick={() => {
                window.location.reload();
              }}
              label="OK"
            />
          </Modal.Footer>
        )}
      </Modal>
    </Tela>
  );
};

export default ListagemConsultaProprietario;
