import { DatePicker } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import Table from '../../components/TableComponent';
import { Tela } from '../../components/Tela';
import axios from '../../config/axiosMaquina';
import Input from '../../components/Input';

interface LogResultVehicle {
  occurrence: string;
  anttResponse: {
    city: string;
    ufRntrc: string;
    document: string;
    typeRntrc: string;
    resultFound: string;
    rntrcNumber: string;
    anttSituation: string;
    vehicleMessage: string;
    transporterName: string;
    registrationDate: string;
    vehicleAnttSituation: string;
  };

  indexVehicle: string;
  plateVehicle: string;
  renajudDetail: {
    restrictions: [];
  };
  infractionDetail: {
    infractions: [];
  };
  occurrenceDetail: {
    occurrences: [];
  };
  validationStatusUf: number;
  validationStatusOwner: number;
  validationStatusChassi: number;
  validationStatusRenavam: number;
  validationStatusDocumentImage: number;
}

interface LogRaw {
  solicitationID: number;
  resultsDVeiculos: LogResultVehicle[];
}

interface LogType {
  id: number;
  createdAt: Date;
  raw: LogRaw;
  rawJson: string;
}

export const ParametrosLogs = () => {
  const { page }: any = useParams();
  const { pathname } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = data.slice(indexOfFirstPost, indexOfLastPost);

  const currentDate = new Date().toISOString().slice(0, 10);

  const [dataInicio, setDataInicio] = useState(currentDate);
  const [dataFim, setDataFim] = useState(currentDate);

  const listLogs = async () => {
    if (!dataInicio || !dataFim) return;

    const startDate = `${dataInicio}T00:00:00.000-03:00`;
    const endDate = `${dataFim}T23:59:59.999-03:00`;
    setLoading(true);
    try {
      const response = await axios.get(
        `pesquisa/dminer-log?dataInicio=${startDate}&dataFim=${endDate}`
      );

      const logsDminerParsed = response.data.map((log: LogType) => {
        const date = moment(log.createdAt).format('DD/MM/YYYY HH:mm:ss');

        return {
          id: log.id,
          raw: log.raw,
          createdAt: date,
        };
      });

      setData(logsDminerParsed);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listLogs();
  }, [dataInicio, dataFim]);

  const paginate = (pageNumber: number) => {
    history.push(`/parametros/logs/integracao-dminer/${pageNumber}`);
  };

  const renderName = () => {
    if (pathname.includes('webservice-sm')) {
      return { name: 'WebService S.M', path: 'Parâmetros > LOGs > WebService S.M' };
    }
    if (pathname.includes('webservice-pamcary')) {
      return { name: 'WebService Pamcary', path: 'Parâmetros > LOGs > WebService Pamcary' };
    }
    if (pathname.includes('integracao-dminer')) {
      return { name: 'Integração Dminer', path: 'Parâmetros > Logs > Log da pesquisa' };
    }
    return { name: '', path: '' };
  };

  const { name, path } = renderName();

  const handleDownloadLogTxt = (item: LogType) => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(item.raw)], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);

    const rawName = item.createdAt;

    element.download = `${rawName}.txt`;
    document.body.appendChild(element);
    element.click();
  };

  return (
    <Tela
      nome={name}
      caminho={path}
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={data.length}
      paginate={paginate}
      currentPage={page}
    >
      <div style={{ maxWidth: 400, width: '100%', display: 'flex', alignItems: 'center' }}>
        <Input
          style={{ width: 300 }}
          type="date"
          value={dataInicio}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value as string;
            setDataInicio(value);
          }}
          defaultValue={dataInicio}
          id="dataInicio"
          name="dataInicio"
          textInputTitle="Data de início"
        />

        <Input
          style={{ width: 300 }}
          type="date"
          value={dataFim}
          onChange={async (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value as string;
            setDataFim(value);
          }}
          defaultValue={dataFim}
          id="dataFim"
          name="dataFim"
          textInputTitle="Data de fim"
        />
      </div>
      {currentData.length && (
        <Table
          headers={{ id: 'Id', raw: 'Arquivo', createdAt: 'Enviado em' }}
          items={currentData}
          handleEdit={() => null}
          customRenderers={{
            raw: (item: LogType) => {
              const rawName = item.createdAt;
              return (
                <Button variant="link" size="sm" onClick={() => handleDownloadLogTxt(item)}>
                  {`Log-${rawName}.log`}
                </Button>
              );
            },
          }}
        />
      )}
    </Tela>
  );
};
