import { memo, useEffect, useLayoutEffect, useRef } from 'react';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_pt_BR from '@amcharts/amcharts5/locales/pt_BR';

export const Chart = ({ type, speedChart, temperatureChart }) => {
  const chart = useRef();

  useLayoutEffect(() => {
    let root = am5.Root.new('chartdiv');

    root.locale = am5locales_pt_BR;

    let indicator = root.container.children.push(
      am5.Container.new(root, {
        width: am5.p100,
        height: am5.p100,
        layer: 1000,
        background: am5.Rectangle.new(root, {
          fill: am5.color(0xffffff),
          fillOpacity: 1,
        }),
      })
    );

    indicator.children.push(
      am5.Label.new(root, {
        text: 'Carregando...',
        fontSize: 25,
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
      })
    );

    var hourglass = indicator.children.push(
      am5.Graphics.new(root, {
        width: 32,
        height: 32,
        fill: am5.color(0x000000),
        x: am5.p50,
        y: am5.p50,
        centerX: am5.p50,
        centerY: am5.p50,
        dy: -45,
        svgPath:
          'M12 5v10l9 9-9 9v10h24V33l-9-9 9-9V5H12zm20 29v5H16v-5l8-8 8 8zm-8-12-8-8V9h16v5l-8 8z',
      })
    );

    var hourglassanimation = hourglass.animate({
      key: 'rotation',
      to: 180,
      loops: Infinity,
      duration: 2000,
      easing: am5.ease.inOut(am5.ease.cubic),
    });

    async function show() {
      hourglassanimation.play();
      await indicator.show();
    }
    async function hide() {
      hourglassanimation.pause();
      await indicator.hide();
    }
    show();

    let _chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        focusable: true,
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        layout: root.verticalLayout,
      })
    );

    _chart.get('colors').set('step', 3);

    let xAxis = _chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 0.1,
        groupData: true,
        extraMax: 0.1, // this adds some space in front
        extraMin: 0.1, // this removes some space from the beginning so that the line would not be cut off
        baseInterval: {
          timeUnit: 'minute',
          count: 1,
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 50,
          grid: {
            location: 0,
            strokeOpacity: 1,
            stroke: am5.color(0x000000),
            strokeDasharray: '3,3',
          },
        }),
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    root.numberFormatter.set('numberFormat', '#. ');

    function createAxisAndSeries(opposite, name, _type, chartData, min, max) {
      if (!chartData.length) {

        return;
      }
      let _data = chartData;

      let yRenderer = am5xy.AxisRendererY.new(root, {
        opposite: opposite,
      });
      let yAxis = _chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          ...(min && { min }),
          ...(max && { max }),
          maxDeviation: 0.1,
          renderer: yRenderer,
        })
      );

      if (_chart.yAxes.indexOf(yAxis) > 0) {
        yAxis.set('syncWithAxis', _chart.yAxes.getIndex(0));
      }

      let series = _chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: '{tooltip}',
          }),
        })
      );

      // series.fills.template.setAll({ fillOpacity: 0.2, visible: true });
      series.strokes.template.setAll({ strokeWidth: 1 });

      // yRenderer.grid.template.set('strokeOpacity', 0.05);
      yRenderer.labels.template.set('fill', series.get('fill'));
      yRenderer.setAll({
        stroke: series.get('fill'),
        strokeOpacity: 1,
        opacity: 1,
      });

      series.bullets.push(function (root, series, dataItem) {
        // only create sprite if bullet == true in data context
        if (dataItem.dataContext.bullet) {
          let container = am5.Container.new(root, {});
          let circle0 = container.children.push(
            am5.Circle.new(root, {
              radius: 5,
              fill: am5.color(0xff0000),
            })
          );
          let circle1 = container.children.push(
            am5.Circle.new(root, {
              radius: 5,
              fill: am5.color(0xff0000),
            })
          );

          circle0.animate({
            key: 'radius',
            to: 10,
            duration: 2500,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity,
          });
          circle0.animate({
            key: 'opacity',
            to: 0,
            from: 1,
            duration: 2500,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity,
          });
          circle1.animate({
            key: 'radius',
            to: 10,
            duration: 2500,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity,
          });
          circle1.animate({
            key: 'opacity',
            to: 0,
            from: 1,
            duration: 2500,
            easing: am5.ease.out(am5.ease.cubic),
            loops: Infinity,
          });

          return am5.Bullet.new(root, {
            locationX: undefined,
            sprite: container,
          });
        }
      });
      series.data.setAll(_data);
    }
    if (type === 2) {
      createAxisAndSeries(false, 'Velocidade', 'velocidade', speedChart);
      createAxisAndSeries(true, 'Temperatura', 'temperatura', temperatureChart, -40 , 60);
    } else if (type === 1) {
      createAxisAndSeries(false, 'Velocidade', 'velocidade', speedChart);
    } else if (type === 0) {
      createAxisAndSeries(false, 'Temperatura', 'temperatura', temperatureChart, -40 ,60);
    }
    let cursor = _chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
      })
    );
    cursor.lineY.set('visible', false);

    let legend = _chart.children.push(
      am5.Legend.new(root, {
        x: am5.percent(10),
        centerX: am5.percent(50),
        layout: root.verticalLayout,
      })
    );
    legend.data.setAll(_chart.series.values);

    hide();
    _chart.appear(1000, 100);
    chart.current = _chart;

    return () => {
      root.dispose();
    };
  }, [speedChart]);

  return <div id="chartdiv" style={{ width: '100%', height: '350px' }}></div>;
};
