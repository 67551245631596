/* eslint-disable */
import axios from 'axios';

interface LoginProps {
  userSession: {
    access_token: string;
    expires_in?: number;
    refresh_expires_in?: number;
    refresh_token: string;
    token_type?: string;
    session_state?: string;
    scope?: string;
    usuarioVO: {
      ativo: boolean;
      clienteId: number;
      clienteRazaoSocial: string;
      isEmbarcador: boolean;
      corretoraId: number;
      cnpj: string;
      corretoraNome: string;
      dataHoraCadastro: Date;
      email: string;
      login: string;
      nome: string;
      perfil: string;
      role: string;
      seguradoraId: number;
      seguradoraNome: string;
      senha: string;
      usuarioId: number;
      usuarioIdKeycloak: number;
    };
  };
  path: string;
}

export const login = async (data: unknown): Promise<LoginProps> => {
  try {
    // const response = await axios.post<LoginProps>('https://apigoldenlog.gservice.com.br/core/login-user/', data);

    const response = await axios.post<LoginProps>(`${process.env.REACT_APP_API_URL}/login-user/`, data);

    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data.detail;
    }
    return error;
  }
};
