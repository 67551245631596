/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable */
// @ts-ignore
import React, { useEffect, useRef, useState } from 'react';
import ReactExport from 'react-data-export';
import { Button } from 'primereact/button';
import { Col, Form, Spinner } from 'react-bootstrap';
import { IoMailOpenSharp, IoEllipse } from 'react-icons/io5';
import { ExcelGeneration } from '../../ListagemSMLancadas/ListagemSMLancadas';
import { useToast } from '../../../hooks/Toast';
import { handleResultado } from '../../../util/validaResultadoPesquisa';
import { validaVigencia } from '../../../util/validaVigenciaPesquisa';
import { obterData, obterDataHora } from '../../../util/date';
import { AiOutlineSortAscending, AiOutlineSortDescending } from 'react-icons/ai';
import api from '../../../config/axiosMaquina';
import Pagination from '../Pagination/Pagination';

import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';

type ListagemHistoricoPesquisa = {
  dataLancamento: string;
  empresa: string;
  itens: Itens[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  validadeAnaliseCondutorAutonomo: number;
  validadeAnaliseNaoMotoristaAgregado: number;
  validadeAnaliseNaoMotoristaAutonomo: number;
  validadeAnaliseNaoMotoristaFuncionario: number;
  validadeAnaliseVeiculoTerceiro: number;
};

export type FilterHistory = {
  idCliente: number;
  pagina: number;
  qtdRegistros: number;
  tipoPesquisa: string;
  dataIni: string;
  dataFim: string;
  keyword: string;
};

type Props = {
  filters?: FilterHistory;
};

type Itens = {
  codigoPesquisaDminerCnh: string;
  codigoPesquisaDminerProcesso: string;
  codigoPesquisaDminerVeiculo: string;
  dataRetorno: string;
  descricao: string;
  idPesquisaEntidade: number;
  idPesquisaEntidadeCnh: number;
  itemPesquisado: string;
  statusPesquisa: string;
  statusPesquisaCondutorCnh: string;
  statusPesquisaEntidade: true;
  tipo: string;
  tipoEnvioPesquisa: string;
  tipoPesquisa: string;
  validade: string;
  validadePesquisaEntidade: string;
};

const XlsxGeneration = async (
  search: ListagemHistoricoPesquisa[],
  filters: {
    dataFim: string;
    dataIni: string;
  }
) => {
  console.log(search);

  const workbook = new ExcelJS.Workbook();

  searchTable(search, workbook, filters);

  workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, 'pesquisa.xlsx');
  });
};

const searchTable = (
  searches: ListagemHistoricoPesquisa[],
  workbook: ExcelJS.Workbook,
  filters: {
    dataFim: string;
    dataIni: string;
  }
) => {
  const worksheet = workbook.addWorksheet('PESQUISAS');

  worksheet.mergeCells('A1', 'B1');
  worksheet.getCell('A1').value = filters.dataIni
    ? `Pesquisa ${obterData(filters.dataIni)} a ${
        filters.dataFim ? obterData(filters.dataFim) : '-/-'
      }`
    : '';

  worksheet.getRow(2).values = [
    'Número',
    'Item Pesquisado',
    'Descrição',
    'Tipo',
    'Operação',
    'Data/Hora Lançamento',
    'Data/Hora Retorno',
    'Usuário',
    'Vigência',
    'Status',
  ];

  worksheet.columns = [
    { key: 'numLiberacao', width: 15 },
    { key: 'itemPesquisado', width: 30 },
    { key: 'descricao', width: 30 },
    { key: 'tipo', width: 30 },
    { key: 'operacao', width: 30 },
    { key: 'dataLancamento', width: 30 },
    { key: 'dataRetorno', width: 30 },
    { key: 'usuario', width: 30 },
    { key: 'vigencia', width: 30 },
    { key: 'status', width: 30 },
  ];

  worksheet.getRow(2).eachCell((cell: any) => {
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'D3D3D3' },
    };

    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    };

    cell.font = {
      bold: true,
    };
  });

  worksheet.eachRow({ includeEmpty: true }, (row: any) => {
    row.height = 25;
  });

  searches.forEach((pesquisa: ListagemHistoricoPesquisa) => {
    pesquisa.itens.forEach((each, idx) => {
      worksheet.addRow({
        numLiberacao: pesquisa.numLiberacao,
        itemPesquisado: each.itemPesquisado,
        descricao: each.descricao,
        tipo: each.tipo,
        operacao: pesquisa.operacao.includes('Integrada')
          ? `${pesquisa.operacao}(${idx + 1})`
          : pesquisa.operacao,
        dataLancamento: obterDataHora(pesquisa.dataLancamento, true),
        dataRetorno: obterDataHora(each.dataRetorno, true),
        usuario: pesquisa.usuario ? pesquisa.usuario : '',
        vigencia: handleResultado(pesquisa.itens) === 'Em Acordo' ? validaVigencia(pesquisa) : '',
        status: pesquisa.itens ? handleResultado(pesquisa.itens) : '',
      });
    });
  });
};

const ListagemHistoricoPesquisas: React.FC<Props> = ({ filters }) => {
  const [dataResults, setDataResults] = useState<ListagemHistoricoPesquisa[]>();
  const [dataResultsExcel, setDataResultsExcel] = useState<ListagemHistoricoPesquisa[]>();
  const { addToast } = useToast();
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingExportExcel, setLoadingExportExcel] = useState(false);
  const [totalItems, setTotalItems] = useState(10);
  const [refresh, setRefresh] = useState(0);
  const [refreshitensPerPage, setRefreshitensPerPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [isReadyForDownload, setIsReadyForDownload] = useState(false);
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[]>([]);
  const [excelGenetionSuccess, setExcelGenetionSuccess] = useState(false);

  const excelRef = useRef<any>(null);

  // style cabeçalho
  const borders = {
    top: { style: 'medium' },
    bottom: { style: 'medium' },
    left: { style: 'medium' },
    right: { style: 'medium' },
  };

  const alignment = {
    horizontal: 'center',
    wrapText: true,
  };

  // style linhas
  const borders2 = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  useEffect(() => {
    if (filters?.idCliente) {
      fetchData({ pagina: refresh, qtdRegistros: itensPerPage });
      setExcelGenetionSuccess(false);
    }
  }, [filters]);

  useEffect(() => {
    if (!refreshitensPerPage) return;
    fetchData({ pagina: refresh, qtdRegistros: itensPerPage });
  }, [refreshitensPerPage]);

  useEffect(() => {
    if (filters?.idCliente) {
      fetchData({ pagina: refresh, qtdRegistros: itensPerPage });
    }
  }, [refresh]);

  const fetchData = async ({ pagina, qtdRegistros }: { pagina: number; qtdRegistros: string }) => {
    setLoading(true);

    try {
      const response = await api.post(`/pesquisa/historico?idCliente=${filters?.idCliente}`, {
        ...filters,
        qtdRegistros,
        pagina: refresh ? refresh : 1,
      });
      setLoading(false);
      setDataResults(response.data.items);
      setTotalItems(response.data.count);
      setLastPage(response.data.pages);
    } catch (e) {
      console.error(e);
      addToast({
        title: 'Erro',
        description: 'Erro na comunicação com o servidor.',
        type: 'error',
      });
    } finally {
      setRefreshitensPerPage(false);
    }
  };

  const fetchDataExcel = async () => {
    setLoadingExportExcel(true);

    try {
      const response = await api.post(`/pesquisa/historico?idCliente=${filters?.idCliente}`, {
        ...filters,
        qtdRegistros: 1000000,
      });

      XlsxGeneration(response.data.items, filters as any);
    } catch (e) {
      console.error(e);
      addToast({
        title: 'Erro',
        description: 'Erro na comunicação com o servidor.',
        type: 'error',
      });
      setLoadingExportExcel(false);
    } finally {
      setLoadingExportExcel(false);
    }
  };

  const arrayCompletoDados: any = [];

  const loadDados = () => {
    if (!dataResults) return;
    dataResults.map((pesquisa: any, i: any) => {
      const arrayListaItens = pesquisa.itens;

      if (arrayListaItens.length > 0) {
        let tableDados = arrayListaItens.map((each: any, idx: any) => [
          {
            value: pesquisa.numLiberacao ? pesquisa.numLiberacao : '',
            style: { border: borders2 },
          },
          {
            value: each.itemPesquisado ? each.itemPesquisado : '',
            style: { border: borders2 },
          },
          {
            value: each.descricao ? each.descricao : '',
            style: { border: borders2 },
          },
          {
            value: pesquisa.operacao.includes('Integrada')
              ? `${pesquisa.operacao}(${idx + 1})`
              : pesquisa.operacao,
            style: { border: borders2 },
          },
          {
            value: pesquisa.dataLancamento ? obterData(each.dataLancamento) : '',
            style: { border: borders2 },
          },
          { value: pesquisa.usuario ? pesquisa.usuario : '', style: { border: borders2 } },
          {
            value: handleResultado(pesquisa.itens) === 'Em Acordo' ? validaVigencia(pesquisa) : '',
            style: { border: borders2 },
          },
          {
            value: handleResultado(pesquisa.itens),
            style: { border: borders2 },
          },
        ]);
        arrayCompletoDados.push(...tableDados);
      }
    });
  };

  loadDados();

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;

  const handleGoToDetails = (each: any) => {
    window.open(
      `/detalhe-pesquisa/${each.numLiberacao}/${each.idCliente}`,
      'janela',
      'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
    );
  };

  const sortData = (order: 'asc' | 'desc') => {
    const sortedData = [...(dataResults || [])].sort((a, b) => {
      if (order === 'asc') {
        return a.numLiberacao - b.numLiberacao;
      }
      return b.numLiberacao - a.numLiberacao;
    });
    setDataResults(sortedData);
  };

  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
    sortData(newSortOrder);
  };

  return (
    <div className="content-container">
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        dataResults && (
          <>
            <div className="painel">
              <div className="table-responsive" style={{ flex: 1 }}>
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          cursor: 'pointer',
                          border: 'none',
                        }}
                        onClick={handleSort}
                      >
                        Número
                        {sortOrder === 'asc' ? (
                          <AiOutlineSortAscending size={30} />
                        ) : (
                          <AiOutlineSortDescending size={30} />
                        )}
                      </th>
                      <th style={{ width: 150 }}>Item pesquisado</th>
                      <th style={{ width: 250 }} />
                      <th>Empresa</th>
                      <th style={{ width: 125 }}>Tipo</th>
                      <th>Lançamento</th>
                      <th>Usuário</th>
                      <th>Vigência</th>
                      <th>Status</th>
                      <th style={{ width: 125 }} />
                    </tr>
                  </thead>
                  <tbody>
                    {dataResults &&
                      dataResults.map((each, index) => (
                        <tr>
                          <td>{each.numLiberacao}</td>
                          {each.operacao.includes('Integrada') ? (
                            <td
                              style={{
                                fontSize: each.itens[3] ? '11px' : each.itens[2] ? '12px' : '',
                              }}
                            >
                              {each.itens[0] ? (
                                <>
                                  {each.itens[0].itemPesquisado}
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                              {each.itens[1] ? (
                                <>
                                  {each.itens[1].itemPesquisado}
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                              {each.itens[2] ? (
                                <>
                                  {each.itens[2].itemPesquisado}
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                              {each.itens[3] ? (
                                <>
                                  {each.itens[3].itemPesquisado}
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                          ) : (
                            <td>{each.itens[0].itemPesquisado}</td>
                          )}
                          <td
                            style={{
                              wordWrap: 'break-word',
                              fontSize: each.itens[3] ? '11px' : each.itens[2] ? '12px' : '',
                            }}
                          >
                            {each.itens[0] ? (
                              <>
                                {each.itens[0].descricao}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[1] ? (
                              <>
                                {each.itens[1].descricao}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[2] ? (
                              <>
                                {each.itens[2].descricao}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                            {each.itens[3] ? (
                              <>
                                {each.itens[3].descricao}
                                <br />
                              </>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>{each.empresa}</td>
                          <td>{each.operacao}</td>
                          <td>
                            {each.dataLancamento === null
                              ? ''
                              : obterDataHora(each.dataLancamento, true)}
                          </td>
                          <td>{each.usuario}</td>
                          <td>
                            {handleResultado(each.itens) === 'Em Acordo'
                              ? validaVigencia(each)
                              : ''}
                          </td>
                          <td>{handleResultado(each.itens)}</td>
                          {handleResultado(each.itens) === 'Em Andamento' ? (
                            <>
                              <td />
                            </>
                          ) : (
                            <td style={{ width: '5%' }}>
                              <IoEllipse
                                size={15}
                                color={
                                  handleResultado(each.itens) === 'Pendente de Documentação' ||
                                  handleResultado(each.itens) === 'Análise Vencida'
                                    ? 'red'
                                    : 'green'
                                }
                              />
                            </td>
                          )}
                          {handleResultado(each.itens) === 'Pendente de Documentação' ? (
                            <td />
                          ) : (
                            <td style={{ width: '5%' }}>
                              <Button
                                tooltip="Detalhamento pesquisa"
                                tooltipOptions={{ position: 'bottom' }}
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                  padding: 0,
                                  paddingRight: 5,
                                  marginBottom: 2,
                                }}
                                onClick={() => handleGoToDetails(each)}
                              >
                                <IoMailOpenSharp size={22} color="#9F9F9F" />
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div id="gerar-xls">
                <Form.Group as={Col} md={2} style={{ paddingLeft: '70px' }}>
                  <Button
                    className="btn-cancelar"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    Voltar
                  </Button>
                </Form.Group>

                <Form.Group as={Col} md={2} style={{ paddingLeft: '70px' }}>
                  <Button onClick={fetchDataExcel} disabled={loadingExportExcel}>
                    {loadingExportExcel ? 'Exportando...' : 'Exportar'}
                  </Button>
                </Form.Group>
              </div>
            </div>
            <Pagination
              lastPage={lastPage}
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              paginate={paginate}
              currentPage={String(currentPage)}
              setRefresh={setRefresh}
              setData={setDataResults}
              setRefreshitensPerPage={setRefreshitensPerPage}
              totalPosts={totalItems}
            />
          </>
        )
      )}
    </div>
  );
};

export default ListagemHistoricoPesquisas;
