/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import api from '../../config/axiosMaquina';
import { CorretoraType } from '../../Types/CorretoraType';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

const CadastroCorretora: FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();

  const { id }: any = useParams();
  const history = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [corretora, setCorretora] = useState<CorretoraType>({
    corBlAtivo: true,
  } as CorretoraType);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.CORRETORA]}], 'editar');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const loadDadosCorretora = async () => {
      try {
        const response = await api.get(`/corretora/corretora/${id}`);
        setCorretora(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    loadDadosCorretora();
  }, []);

  const handleCadastroEdit = async (event: any) => {
    event.preventDefault();
    if (
      !corretora.corTxDescricao ||
      !corretora.corTxNome ||
      corretora.corTxDescricao.trim().length == 0 ||
      corretora.corTxNome.trim().length == 0
    ) {
      setIsFormInvalid(true);
      addToast({
        title: 'Erro',
        description: 'Preencha os campos',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }
    if (!id) {
      //CADASTRO
      try {
        const response = await api.post('/corretora/corretora', corretora);
        setLoading(false);
        setMensagem('Corretora cadastrada com sucesso!');
        setIsDialogCadOK(true);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
        addToast({
          title: 'Erro',
          description: 'Erro na comunicação com o servidor.',
          type: 'error',
        });
      }
    }
    if (id) {
      // EDIT
      try {
        const response = await api.put(`/corretora/atualizar/${id}`, corretora);
        setLoading(false);
        setMensagem('Corretora atualizada com sucesso!');
        setIsDialogCadOK(true);
      } catch (error: any) {
        console.log(error);
        setLoading(false);
        addToast({
          title: 'Erro',
          description: 'Erro na comunicação com o servidor.',
          type: 'error',
        });
      }
    }
  };

  const valida = (event: any) => {
    if (!event || event.trim().length == 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={mensagem}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.goBack();
        }}
      />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Corretora</h2>
          <h6 className="subtitulo">
            {'Cadastro > Corretora > '} {id ? 'Edição' : 'Cadastro'}
          </h6>
        </div>
        <Form noValidate validated={isFormInvalid} onSubmit={handleCadastroEdit}>
          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Nome</Form.Label>
              <Form.Control
                value={corretora?.corTxNome}
                onChange={(e) => {
                  setCorretora({
                    ...corretora,
                    corTxNome: e.target.value,
                  });
                }}
                onBlur={() => valida(corretora?.corTxNome)}
                type="text"
                placeholder=""
                maxLength={128}
                required
                title="Campo Obrigatório"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="container-buttons justify-content-end">
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Descrição</Form.Label>
              <Form.Control
                value={corretora?.corTxDescricao}
                onChange={(e) => {
                  setCorretora({
                    ...corretora,
                    corTxDescricao: e.target.value,
                  });
                }}
                onBlur={() => valida(corretora?.corTxDescricao)}
                type="text"
                placeholder=""
                maxLength={128}
                required
                title="Campo Obrigatório"
                // ref={ref}
              />
            </Form.Group>
          </Form.Row>
          <Form.Switch style={{ marginLeft: '-30px' }}>
            <Form.Group>
              <Form.Check
                checked={corretora?.corBlAtivo}
                onChange={() => {
                  setCorretora({
                    ...corretora,
                    corBlAtivo: !corretora.corBlAtivo,
                  });
                }}
                type="switch"
                id="custom-switch1"
                label="Ativo"
              />
            </Form.Group>
          </Form.Switch>
          <ButtonsForm canEdit={canEdit} />
        </Form>
      </div>
    </div>
  );
};

export default CadastroCorretora;
