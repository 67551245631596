/* eslint-disable */
import axios from '../../../config/axiosMaquina';
import React, { useState, ChangeEvent, useEffect } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { BinResponse } from '../../../api/model/BauIntercambiavelType';
import { noArroba, semCaracterEspecial, soNumeros } from '../../../util/Validacoes/Validacoes';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import './styles.css';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { getCookieSessionData } from '../../../services/cookieService';
import { useToast } from '../../../hooks/Toast';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

const CadastroBau: React.FC = () => {
  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();

  const { addToast } = useToast();
  const history = useHistory();
  const { id, fromSMCadastro }: any = useParams();
  const [isCubagem, setIsCubagem] = useState(true);

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  const [model, setModel] = useState<BinResponse>({
    id: 0,
    numeroBau: '',
    modelo: '',
    statusAtivo: true,
    capacidadeCarga: 0,
    cubagem: 0,
    qtdeViagens: 0,
    valorMedioTransportado: 0,
    clienteId: clienteId,
    tipoBau: '',
  });

  useEffect(() => {
    rolePermission([{TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.BAU]}], 'editar');
  }, [TRANSPORTADOR, rolePermission]);

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!model.numeroBau || !model.modelo || !model.tipoBau) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      e.stopPropagation();
      return;
    }
    try {
      const response = await axios.post('/bau-intercambiavel/', model);
      setIsDialogVisibleCadEfetuado(true);
      //alert('Cadastro Realizado com Sucesso');
      if (fromSMCadastro) {
        window.close();
      }
    } catch (err: any) {
      console.log(err);
    }
  }

  function back() {
    history.goBack();
  }

  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    setModel({
      ...model,
      [e.target.name]: parseInt(soNumeros(e.target.value)),
    });
  }

  useEffect(() => {}, [model]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header="Baú foi cadastrado com sucesso!"
        footer={
          <>
            <Button label="OK" onClick={() => setIsDialogVisibleCadEfetuado(false)} />
          </>
        }
        visible={isDialogVisibleCadEfetuado}
        style={{ width: '50vw' }}
        modal
        onHide={() => history.goBack()}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Baú Intercambiável</h2>
          <h6 className="subtitulo">{'Cadastro > Baú Intercambiável'}</h6>
        </div>
        <div>
          <h1 className="info-obrigatoria">*Informações obrigatórias</h1>
        </div>
        <div className="painel">
          <Form
            noValidate
            validated={isFormInvalid}
            className="form-responsive"
            onSubmit={onSubmit}
          >
            <Form.Row>
              <Form.Group as={Col} controlId="formGridbinTxNumeroBau">
                <Form.Label className="required">NÚMERO BAÚ</Form.Label>
                <Form.Control
                  value={model.numeroBau}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: semCaracterEspecial(e.target.value),
                    });
                  }}
                  name="numeroBau"
                  maxLength={64}
                  type="text"
                  required
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridbinTxTipoFrota">
                <Form.Label className="require">TIPO BAÚ</Form.Label>
                <Form.Control
                  value={model.tipoBau}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  name="tipoBau"
                  as="select"
                  placeholder=""
                  required
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">PRÓPRIO</option>
                  <option value="2">AGREGADO</option>
                  <option value="3">TERCEIRO</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridbinTxModelo">
                <Form.Label className="required">MODELO</Form.Label>
                <Form.Control
                  value={model.modelo}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: semCaracterEspecial(e.target.value),
                    });
                  }}
                  name="modelo"
                  maxLength={60}
                  type="text"
                  required
                  // pattern="/[^a-zA-Z\s]/g"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridbinNuCapacidadeCarga">
                <Form.Label>CAPACIDADE DE CARGA</Form.Label>
                <Form.Control
                  value={model.capacidadeCarga === 0 ? '' : model.capacidadeCarga}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: soNumeros(e.target.value),
                    });
                  }}
                  name="capacidadeCarga"
                  maxLength={10}
                  type="text"
                  title="Este campo aceita apenas numeros"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridbinNuCubagem">
                <Form.Label>CUBAGEM</Form.Label>
                <Form.Control
                  value={model.cubagem === 0 ? '' : model.cubagem}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: soNumeros(e.target.value),
                    });
                    if (soNumeros(e.target.value)) {
                      setIsCubagem(true);
                    } else {
                      setIsCubagem(false);
                    }
                  }}
                  name="cubagem"
                  type="text"
                  maxLength={10}
                  title="Este campo aceita apenas numeros"
                />
                {!isCubagem && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Apenas Números</p>
                )}
              </Form.Group>
            </Form.Row>
            <ButtonsForm canEdit={canEdit} />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CadastroBau;
