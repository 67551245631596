/* eslint-disable */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from '../../assets/logo_golden.jpg';

const GeneratePDF = (data: any, periodo: string) => {
  const doc = new jsPDF('l', 'mm', 'a4');

  const headerTextColor = [54, 55, 56];
  const headerFillColor = [255, 255, 255];
  const line = (y: number) => {
    doc.setDrawColor('#494a49');
    return doc.line(15, y, doc.internal.pageSize.getWidth() - 15, y);
  };

  const Top = [
    {
      content: '',
      colSpan: 1,
      styles: { halign: 'center', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: '',
      colSpan: 1,
      styles: { halign: 'center', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: '',
      colSpan: 1,
      styles: { halign: 'center', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Agendamento',
      colSpan: 2,
      styles: { halign: 'center', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Previsão Sistema',
      colSpan: 2,
      styles: { halign: 'center', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Chegada',
      colSpan: 2,
      styles: { halign: 'center', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Saída',
      colSpan: 2,
      styles: { halign: 'center', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: '',
      colSpan: 1,
      styles: { halign: 'center', fillColor: headerFillColor, textColor: headerTextColor },
    },
  ];

  const Header = [
    {
      content: '#',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Ponto',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Nº Remessa',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Data',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Hora',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Data',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Hora',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Data',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Hora',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Data',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Hora',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
    {
      content: 'Status',
      colSpan: 1,
      styles: { halign: 'left', fillColor: headerFillColor, textColor: headerTextColor },
    },
  ];

  /////////////////////////////////////////////////////////////////////////////
  doc.addImage(Logo, 'jpg', 10, 10, 60, 17);
  doc.setFontSize(13);
  doc.setFont('helvetica', 'bold');
  doc.setTextColor('#3f3f3f');
  doc.text('ACOMPANHAMENTO DE REMESSAS', 100, 27);
  /////////////////////////////////////////////////////////////////////////////
  line(28);
  /////////////////////////////////////////////////////////////////////////////
  doc.setFontSize(10);
  doc.setFont('helvetica', 'normal');
  doc.text(`Embarcador: ${data.embarcador}`, 17, 34);
  doc.setFontSize(10);
  doc.text(`${periodo}`, 223, 34);
  /////////////////////////////////////////////////////////////////////////////
  line(36);
  /////////////////////////////////////////////////////////////////////////////
  let currentY = 44;

  data.sms.map((smData: any, index: any) => {
    /////////////////////////////////////////////////////////////////////////////
    doc.text(`Transportador: ${smData.transportador}`, 17, currentY);
    doc.text(`S.M nº: ${smData.sm}`, 17, currentY + 6);
    doc.text(`Placas: ${smData.placas}`, 47, currentY + 6);
    doc.text(`Início Viagem: ${smData.inicioViagem}`, 150, currentY + 6);

    /////////////////////////////////////////////////////////////////////////////
    line(currentY + 8);
    /////////////////////////////////////////////////////////////////////////////

    //@ts-ignore
    doc.autoTable({
      startY: currentY + 11,
      head: [Top, Header],
      body: smData.data,
      styles: { halign: 'left' },
    });

    let posY = (doc as any).lastAutoTable.finalY;

    if (posY + 50 > doc.internal.pageSize.getHeight()) {
      if (index !== data.sms.length - 1) {
        doc.addPage();
        currentY = 20;
      }
    } else {
      currentY = posY + 10;
    }
  });

  /////////////////////////////////////////////////////////////////////////////

  doc.save('remessa.pdf');
};

export default GeneratePDF;
