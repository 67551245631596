import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { Tela } from '../../../components/Tela';
import { Spinner } from '../../../components/Spinner';
import { Tabela } from '../../../components/Tabela';
import { pesquisar } from '../../../util/format';
import { toCurrency } from '../../../util/Validacoes/Validacoes';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

type RankingVeiculoType = {
  frvCdId: number;
  quantidadeViagemRealizadaInicio: number;
  quantidadeViagemRealizadaFim: number;
  valorMedioTransportadoInicio: number;
  valorMedioTransportadoFim: number;
  valorMedioTransportado: number;
  cliCdId: number;
};

const RankingVeiculoList: React.FC = () => {
  const { EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const { page }: any = useParams();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const titles = [
    '+id',
    'Qtde. Viagens Realizadas - Valor Inicial',
    'Qtde. Viagens Realizadas - Valor Final',
    'Valor Médio Transportado - Valor Inicial',
    'Valor Médio Transportado - Valor Final',
    'Valor Permitido para Transporte',
  ];
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: number) => {
    history.push(`/ranking/veiculo/listar/${pageNumber}`);
  };

  const converteArray = (dt: any[]) => {
    const array = dt.map((each: any) => {
      return [
        each.frvCdId,
        each.quantidadeViagemRealizadaInicio,
        each.quantidadeViagemRealizadaFim,
        toCurrency(each.valorMedioTransportadoInicio),
        toCurrency(each.valorMedioTransportadoFim),
        toCurrency(each.valorMedioTransportado),
      ];
    });
    return array;
  };

  useEffect(() => {
    rolePermission([{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.VEICULO] }], 'inserir');
  }, [EMBARCADOR, rolePermission]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/faixa-ranking-veiculo/find-all`, {
        params: {
          idCliente: clienteId,
        },
      });
      setData(converteArray(response.data));
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/ranking/veiculo/listar/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Tela
      nome="Ranking de Veículo"
      caminho="Cadastro > Ranking de Veículo"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/ranking/veiculo/cadastrar/"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/ranking/veiculo/editar/${id}`)}
        />
      )}
    </Tela>
  );
};

export default RankingVeiculoList;
