import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { Spinner } from '../../../components/Spinner';
import { Tabela } from '../../../components/Tabela';
import { Tela } from '../../../components/Tela';
import { pesquisar } from '../../../util/format';
import { toCurrency } from '../../../util/Validacoes/Validacoes';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

type RankingCondutorType = {
  frcCdId: number;
  quantidadeViagemRealizadaInicio: number;
  quantidadeViagemRealizadaFim: number;
  valorMedioTransportadoInicio: number;
  valorMedioTransportadoFim: number;
  valorMedioTransportado: number;
  cliCdId: number;
};

const RankingCondutorList: React.FC = () => {
  const { EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const { page }: any = useParams();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [historicosPerPage, setHistoricosPerPage] = useState(Number(itensPerPage));
  const indexOfLastPost = page * historicosPerPage;
  const indexOfFirstPost = indexOfLastPost - historicosPerPage;
  const titles = [
    '+id',
    'Qtde. Viagens Realizadas - Valor Inicial',
    'Qtde. Viagens Realizadas - Valor Final',
    'Valor Médio Transportado - Valor Inicial',
    'Valor Médio Transportado - Valor Final',
    'Valor Permitido para Transporte',
  ];
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/ranking/condutor/listar/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ EMBARCADOR: [EMBARCADOR.CADASTRO.RANKING_FAIXA.CONDUTOR] }], 'inserir');
  }, [EMBARCADOR, rolePermission]);

  const converteArray = (dt: any[]) => {
    const array = dt.map((each: any) => {
      return [
        each.frcCdId,
        each.quantidadeViagemRealizadaInicio,
        each.quantidadeViagemRealizadaFim,
        toCurrency(each.valorMedioTransportadoInicio),
        toCurrency(each.valorMedioTransportadoFim),
        toCurrency(each.valorMedioTransportado),
      ];
    });
    return array;
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/faixa-ranking-condutor/find-all`, {
        params: {
          idCliente: clienteId,
        },
      });
      setData(converteArray(response.data));
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/ranking/condutor/listar/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Tela
      nome="Ranking de Condutor"
      caminho="Cadastro > Ranking de Condutor"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/ranking/condutor/cadastrar/"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/ranking/condutor/editar/${id}`)}
        />
      )}
    </Tela>
  );
};

export default RankingCondutorList;
