export const PERMISSION = {
  ADMIN: {
    CADASTRO: {
      CLIENTE: 3,
      TIPO_COMUNICACAO: 5,
      TECNOLOGIA: 6,
      DISPOSITIVO: 7,
      GRUPO_MACRO_VEICULO: 9,
      GRUPO_VEICULO: 10,
      TIPO_VEICULO: 11,
      COR: 12,
      MARCA: 13,
      RASTREAMENTO: 14,
      BLACKLIST_SEGURADORA: 27,
      MOTIVO: 15,
      NAO_CONFORMIDADE: 16,
      ANALISTA_PERFIL: 17,
      CORRETORA: 18,
      SEGURADORA: 19,
      AJUDANTE: 21,
      CARRETA: 22,
      CONDUTOR: 23,
      EMBARCADOR: 24,
      PROPRIETARIO: 25,
      VEICULO: 26,
      VALIDACAO_CNH: 142,
      GERENTE: 143,
    },
    OPERACIONAL: {
      SOLICITACAO: {
        ANALISE_PERFIL: {
          LANCAMENTO: 31,
          PAINEL_ANALISE_PERFIL: 32,
        },
        ASSISTENCIA_TECNICA: 33,
        CHECKLIST_VEICULO: 34,
      },
      PAINEL_OPERACIONAL: 35,
      TECNOLOGIA: 36,
      FATURAMENTO_FROTA: 37,
      PAINEL_LSC: 38,
    },
    AVISO: {
      CLIENTE: 40,
      OPERACIONAL: 41,
    },
    CONSULTA_RELATORIO: {
      ANALISE_PERFIL: 43,
      CHECKLIST: 44,
      HISTORICO_POSICAO: 47,
      REQUISICAO_API: 46,
      TEMPO_PARADO: 48,
      RESUMO_SAT: 50,
      SM_LANCADA: 52,
      NAO_CONFORMIDADE: 53,
    },
    PARAMETRO: {
      EMAIL: 55,
      INTEGRACAO: 56,
      SISTEMA: 57,
      AUTOMATIZACAO: 58,
    },
    USUARIO: {
      GERENCIADOR: 60,
      CORRETORA: 61,
      SEGURADORA: 62,
      ASSOCIAR_CLIENTE: 63,
      CLIENTE: 64,
      PERFIL_USUARIO: 137,
    },
  },
  TRANSPORTADOR: {
    CADASTRO: {
      PROPRIETARIO: 66,
      CONDUTOR: 67,
      AJUDANTE: 68,
      VEICULO: 69,
      CARRETA: 70,
      BAU: 71,
      ROTA: 72,
      PONTO: 73,
      REGRAS_DE_APOLICE: 74,
    },
    OPERACIONAL: {
      SOLICITACAO: {
        SOLICITACAO_MONITORAMENTO: 80,
        CANCELAMENTO_MONITORAMENTO: 81,
        CONFIRMACAO_PRE_SM: 82,
      },
      CONSULTA_E_RELATORIO: {
        SM_LANCADA: 84,
        CHECKLIST: 86,
        RESUMO_SAT: 87,
        NAO_CONFORMIDADE: 88,
        RASTREAMENTO: {
          HISTORICO_POSICAO: 90,
          TEMPO_PARADO: 91,
        },
      },
    },
    ANALISE_DE_PERFIL: {
      ENVIAR_PESQUISA: 93,
      HISTORICO_DE_PESQUISA: 94,
    },
    CONTROLE_LOGISTICO: {
      PREVENCAO_DE_ACIDENTE: 134,
      CONTROLE_DE_VELOCIDADE: 96,
      CONTROLE_DE_TEMPERATURA: 97,
      PROGRESSAO_DE_VIAGEM: 76,
      MAPA: 77,
    },
    FATURAMENTO_FROTA: 98,
    USUARIO: {
      INCLUSAO_ALTERACAO: 100,
      PERFIS_DE_USUARIOS: 133,
    },
  },
  EMBARCADOR: {
    CADASTRO: {
      FAIXA_DE_CUBAGEM: 102,
      SAZONALIDADE_DE_CARGA: 103,
      CAPACIDADE_DE_CARGA: 104,
      RANKING_FAIXA: {
        AJUDANTE: 106,
        CONDUTOR: 107,
        VEICULO: 108,
      },
      PONTO: 109,
      CD: 110,
      REGIONAL: 111,
      NAO_CONFORMIDADE: 112,
    },
    OPERACIONAL: {
      RECICLAGEM: 118,
      PRE_SM: 119,
      IMPORTAR_CSV: 141,
      CONSULTA_E_RELATORIO: {
        SM_LANCADAS: 121,
        ACOMPANHAMENTO_DE_REMESSA: 122,
        NAO_CONFORMIDADE: 123,
      },
    },
    CONTROLE_LOGISTICO: {
      PREVENCAO_ACIDENTE: 138,
      PROGRESSAO_DE_VIAGENS: 114,
      MAPA: 115,
    },
    PORTARIA: 124,
    CHECKLIST: 135,
    USUARIO: {
      INCLUSAO_ALTERACAO: 126,
      PERFIL_DE_USUARIO: 136,
    },
  },
};
