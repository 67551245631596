import React from 'react';
import { DatePicker, Space } from 'antd';

const { RangePicker } = DatePicker;

const dateFormat = 'DD/MM/YYYY';

const RangeDate = ({ setValue }: { setValue: React.Dispatch<any> }) => {
  return (
    <Space direction="vertical" size={12}>
      <RangePicker
        format={dateFormat}
        placeholder={['DATA INICIAL', 'DATA FINAL']}
        onChange={(_, dateString) => {
          if (dateString[0] === '') {
            setValue([]);
            return;
          }

          setValue(dateString);
        }}
        style={{ marginRight: '10px', padding: '9px', borderRadius: '1px' }}
      />
    </Space>
  );
};

export default RangeDate;
