/* eslint-disable */
import axios from '../../../config/axiosMaquina';
import React, { useState, useEffect, ChangeEvent, FC } from 'react';
import { Form, Col, Modal, Button } from 'react-bootstrap';
import { VeiculoCadastro } from '../../../../src/Types/Veiculos/VeiculoCadastroType';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { ProprietarioList } from '../../../../src/Types/Veiculos/ProprietarioType';
import { Cor } from '../../../../src/Types/Veiculos/CorType';
import { VeiculoMarca } from '../../../../src/Types/Veiculos/VeiculoMarcaType';
import { VeiculoTipo, CarretaTipo } from '../../../../src/Types/Veiculos/VeiculoTipoType';
import { TecnologiaRastreamento } from '../../../../src/Types/Veiculos/TecnologiaRastreamentoType';
import { TipoComunicacaoIdNome } from '../../../../src/api/model/TipoComunicacaoIdNomeType';
import { useToast } from '../../../hooks/Toast';
import './styles.css';
import Switch from 'react-bootstrap/esm/Switch';
import { UfIbgeType } from '../../../api/model/UfIbgeType';
import { MunicipiosTypes } from '../../../api/Types/municipiosTypes';
import { MunicipiosType } from '../../../api/model/MunicipiosType';
import { PaisType } from '../../../api/model/PaisType';
import { validarPlaca, validaNumero, soNumeros } from '../../../util/Validacoes/Validacoes';
import { VeiculoTypeGrid } from '../../../Types/Veiculos/VeiculoTypeGrid';
import { getCookieSessionData, isAllowedByRole } from '../../../services/cookieService';
import { Dialog } from 'primereact/dialog';

type Props = {
  showModalCadastro: boolean;
  setShowModalCadastro: (bool: boolean) => void;
  loadVeiculos: () => void;
};

const ModalCadastroVeiculoPorSM: FC<Props> = ({
  showModalCadastro,
  setShowModalCadastro,
  loadVeiculos,
}) => {
  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  const [cores, setCores] = useState<Cor[]>([]);
  const [marca, setMarca] = useState<VeiculoMarca[]>([]);
  const [veiTipo, setVeiTipo] = useState<VeiculoTipo[]>([]);
  const [tipoCarreta, setTipoCarreta] = useState<CarretaTipo[]>([]);
  const [veiTer, setVeiTer] = useState<TecnologiaRastreamento[]>([]);
  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [pais, setPais] = useState<PaisType[]>([]);
  const [municipioFilter, setMunicipioFilter] = useState<MunicipiosType[] | null>(null);
  const [tipoComunicacao, setTipoComunicacao] = useState<TipoComunicacaoIdNome[]>([]);
  const [proprietario, setProprietario] = useState<ProprietarioList[]>([]);
  const [mapa, setMapa] = useState(true);
  const [veiculosCliente, setVeiculosCliente] = useState<VeiculoTypeGrid[]>([]);
  const [carretasCliente, setCarretasCliente] = useState<VeiculoTypeGrid[]>([]);
  const [municipiosPorEstado, setMunicipiosPorEstado] = useState<MunicipiosTypes[]>([]);
  const [ufSigla, setUfSigla] = useState<UfIbgeType[]>([]);

  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const [isPlacaValid, setIsPlacaValid] = useState(false);
  const [previousPlaca, setPreviousPlaca] = useState('');
  const [isPlacaRegistered, setIsPlacaRegistered] = useState(false);
  const [isValidAnoFabricacao, setIsValidAnoFabricacao] = useState(true);
  const [isValidAnoModelo, setIsValidAnoModelo] = useState(true);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const DATA_ATUAL = new Date().toLocaleString('pt-BR');
  const DATA_ATUAL_ISO = new Date();
  const ANO_ATUAL = new Date().getFullYear() + 1;

  const { addToast } = useToast();

  const history = useHistory();
  const { id, isCarreta }: any = useParams();

  const [model, setModel] = useState<VeiculoCadastro>({
    id: 0,
    placa: '',
    modelo: '',
    proprietarioId: 0,
    veiculoMarca: 0,
    renavam: '',
    chassi: '',
    cor: 0,
    veiculoTipo: 0,
    tipoFrota: '',
    frota: '',
    anttRntrc: 0,
    cubagem: 0,
    modeloAno: 0,
    fabricacaoAno: 0,
    tecnologiaRastreamento: undefined,
    comunicacao: null,
    numeroEquipamento: '',
    emplacamentoPais: null,
    emplacamentoCidade: null,
    emplacamentoUf: null,
    tipoFaturamento: '',
    obs: '',
    bloqueado: false,
    carroceriaTipo: 0,
    dataUltimaAlteracao: new Date().toISOString(),
    qtdeViagens: 0,
    cadastro: new Date(DATA_ATUAL_ISO).toISOString(),
    valorMedioTransportado: 0,
    tempoCadastro: '',
    mapa: false,
    atualizacaoAutomatica: false,
    ativo: true,
    veiculoMarcaNome: '',
    veiculoTipoNome: '',
    tecnologiaRastreamentoNome: '',
  });

  useEffect(() => {
    if (showModalCadastro) {
      if (id) {
        findVei(id);
      }
      loadProprietarios();
      loadCor();
      loadVeiculoMarca();
      loadVeiculoTipo();
      loadVeiculoTr();
    }
  }, [showModalCadastro, id]);

  useEffect(() => {
    const loadVeiculos = async () => {
      if (isCarreta) {
        try {
          const response = await axios.get(
            '/veiculo/listarCarretasPorCliente?cliente=' + clienteId
          );
          setCarretasCliente(response.data);
        } catch (err: any) {
          console.log(err);
        }
      } else {
        try {
          const response = await axios.get(
            '/veiculo/listarVeiculosPorCliente?cliente=' + clienteId
          );
          setVeiculosCliente(response.data);
        } catch (err: any) {
          console.log(err);
        }
      }
    };
    if (showModalCadastro) {
      loadVeiculos();
    }
  }, [showModalCadastro]);

  useEffect(() => {
    const loadEstadosPorPais = async () => {
      if (model.emplacamentoPais) {
        try {
          const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/${model.emplacamentoPais}`);
          setUfSigla(response.data);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    loadEstadosPorPais();
  }, [model.emplacamentoPais]);

  useEffect(() => {
    const loadMunicipiosPorEstado = async () => {
      if (model.emplacamentoUf) {
        try {
          const response = await axios.get(`/municipio-ibge/listarPorUf/${model.emplacamentoUf}`);
          setMunicipiosPorEstado(response.data);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    loadMunicipiosPorEstado();
  }, [model.emplacamentoUf]);

  const verifyIfPlacaIsRegistered = (value: string) => {
    if (isCarreta) {
      if (value.toUpperCase() === previousPlaca.toUpperCase()) {
        setIsPlacaRegistered(false);
      } else if (carretasCliente.map((each) => each.placa).includes(value.toUpperCase())) {
        setIsPlacaRegistered(true);
      } else {
        setIsPlacaRegistered(false);
      }
    } else {
      if (value.toUpperCase() === previousPlaca.toUpperCase()) {
        setIsPlacaRegistered(false);
      } else if (veiculosCliente.map((each) => each.placa).includes(value.toUpperCase())) {
        setIsPlacaRegistered(true);
      } else {
        setIsPlacaRegistered(false);
      }
    }
  };

  const handlePlacaFocusOut = (placa: string) => {
    if (placa) {
      if (placa.length < 7) setIsPlacaValid(false);

      if (validarPlaca(placa)) {
        setIsPlacaValid(true);
      } else {
        setIsPlacaValid(false);
      }

      verifyIfPlacaIsRegistered(placa);
    }
  };

  const location: { state: number } = useLocation();
  const { state } = location;

  async function loadProprietarios() {
    if (!clienteId) {
      const response = await axios.get(`/proprietario/cliente/${state}`);
      setProprietario(response.data);
    } else {
      const response = await axios.get(`/proprietario/cliente/${clienteId}`);
      setProprietario(response.data);
    }
  }

  async function loadCor() {
    const response = await axios.get('/cor');
    setCores(response.data);
  }

  async function loadVeiculoMarca() {
    const response = await axios.get('/marca-veiculo');
    setMarca(response.data);
  }

  async function loadVeiculoTipo() {
    const response = await axios.get('/tipo-veiculo');
    setVeiTipo(response.data);
  }

  async function loadVeiculoTr() {
    const response = await axios.get('/tecnologia-rastreamento');
    setVeiTer(response.data);
  }

  useEffect(() => {
    const loadUf = async () => {
      const response = await axios.get('/uf');
      setUfs(response.data);
    };
    if (showModalCadastro && !ufs.length) {
      loadUf();
    }
  }, [showModalCadastro]);

  useEffect(() => {
    const loadMunicipio = async () => {
      try {
        const response = await axios.get('/municipio-ibge');
        setMunicipios(response.data);
      } catch (err: any) {
        console.error(err);
      }
    };
    if (showModalCadastro && !municipios.length) {
      loadMunicipio();
    }
  }, [showModalCadastro]);

  useEffect(() => {
    const loadPais = async () => {
      const response = await axios.get('/pais');
      setPais(response.data);
    };
    if (showModalCadastro && !pais.length) {
      loadPais();
    }
  }, [showModalCadastro]);

  useEffect(() => {
    const loadCarretaTipo = async () => {
      const response = await axios.get('/tipo-veiculo/carreta');
      setTipoCarreta(response.data);
    };
    if (showModalCadastro && !tipoCarreta.length) {
      loadCarretaTipo();
    }
  }, [showModalCadastro]);

  useEffect(() => {
    if (!model.emplacamentoUf || !municipios) {
      return;
    }
    const estado = ufs.filter((each) => each.id === model.emplacamentoUf);
    const municipio = municipios.filter((each) => each.idCodigoIbge === estado[0].codigoIbge);
    setMunicipioFilter(municipio);
  }, [model, municipios]);

  useEffect(() => {
    if (!model.tecnologiaRastreamento) {
      return;
    }
    const loadDadosTecnologiaComunicacaoPorTecnologia = async () => {
      try {
        const response = await axios.get(
          `/tipo-comunicacao/listarIdNomePorTecnologia?tecnologia=${model.tecnologiaRastreamento}`
        );
        setTipoComunicacao(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    loadDadosTecnologiaComunicacaoPorTecnologia();
  }, [model.tecnologiaRastreamento]);

  function handleTecnologia(tecnologia: ChangeEvent<HTMLInputElement>) {
    setModel((prevState) => {
      return {
        ...prevState,
        [tecnologia.target.name]: parseInt(tecnologia.target.value),
      };
    });
  }

  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    setModel((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }

  const [loadingCadastro, setLoadingCadastro] = useState(false);

  async function onSubmit(e: any) {
    e.preventDefault();

    if (model.placa.length < 7) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      e.stopPropagation();
      return;
    }

    if (isCarreta) {
      if (flagDadosEnderecoObrigatorio === false) {
        if (
          !model.proprietarioId ||
          !model.placa ||
          !model.renavam ||
          !model.chassi ||
          !model.veiculoMarca ||
          !model.modelo ||
          !model.veiculoTipo ||
          !model.tipoFrota ||
          !isPlacaValid
        ) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setIsFormInvalid(true);

          e.stopPropagation();
          return;
        }
        if (!isValidAnoModelo || !isValidAnoFabricacao) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setIsFormInvalid(true);

          e.stopPropagation();
          return;
        } else {
          setLoadingCadastro(true);
          try {
            if (isPlacaRegistered) {
              throw new Error('Placa já registrada.');
            }
            await axios.post('/veiculo', model);
            setLoadingCadastro(false);
            setIsDialogVisibleCadEfetuado(true);
            setTimeout(() => {
              setShowModalCadastro(false);
              limparFormulario();
              loadVeiculos();
              setIsDialogVisibleCadEfetuado(false);
              setIsFormInvalid(false);
            }, 1000);
          } catch (err: any) {
            addToast({
              title: 'Erro!',
              description: 'Erro ao cadastrar!',
              type: 'error',
            });
          }
        }
      }

      if (flagDadosEnderecoObrigatorio === true) {
        if (
          !model.proprietarioId ||
          !model.placa ||
          !model.renavam ||
          !model.chassi ||
          !model.cor ||
          !model.veiculoMarca ||
          !model.modelo ||
          !model.veiculoTipo ||
          !model.tipoFrota ||
          !model.modeloAno ||
          !model.fabricacaoAno ||
          !model.emplacamentoPais ||
          !model.emplacamentoUf ||
          !model.emplacamentoCidade ||
          !isValidAnoModelo ||
          !isValidAnoFabricacao ||
          !isPlacaValid
        ) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setIsFormInvalid(true);
          e.stopPropagation();
          return;
        } else {
          setLoadingCadastro(true);
          try {
            if (isPlacaRegistered) {
              throw new Error('Placa já registrada.');
            }

            await axios.post('/veiculo', model);

            setLoadingCadastro(false);
            setIsDialogVisibleCadEfetuado(true);
            setTimeout(() => {
              setShowModalCadastro(false);
              limparFormulario();
              loadVeiculos();
              setIsDialogVisibleCadEfetuado(false);
              setIsFormInvalid(false);
            }, 1000);
          } catch (err: any) {
            // alert('Erro ao cadastrar Carreta - Verifique os campos');
            addToast({
              title: 'Erro!',
              description: 'Verifique os dados preenchidos.',
              type: 'error',
            });
          }
        }
      }
    } else {
      if (flagDadosEnderecoObrigatorio === false) {
        if (
          !model.proprietarioId ||
          !model.placa ||
          !model.renavam ||
          !model.chassi ||
          !model.veiculoMarca ||
          !model.modelo ||
          !model.veiculoTipo ||
          !model.tipoFrota ||
          !model.tecnologiaRastreamento ||
          !model.comunicacao ||
          !isPlacaValid
        ) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setIsFormInvalid(true);
          e.stopPropagation();
          return;
        }
        if (!isValidAnoModelo || !isValidAnoFabricacao) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setIsFormInvalid(true);
          e.stopPropagation();
          return;
        } else {
          setLoadingCadastro(true);
          try {
            if (isPlacaRegistered) {
              throw new Error('Placa já registrada.');
            }
            await axios.post('/veiculo', model);

            setLoadingCadastro(false);
            setIsDialogVisibleCadEfetuado(true);
            setTimeout(() => {
              setShowModalCadastro(false);
              limparFormulario();
              loadVeiculos();
              setIsDialogVisibleCadEfetuado(false);
              setIsFormInvalid(false);
            }, 1000);
          } catch (err: any) {
            // alert('Erro ao cadastrar - Verifique os campos');
            addToast({
              title: 'Erro!',
              description: 'Verifique os dados preenchidos.',
              type: 'error',
            });
          }
        }
      }

      if (flagDadosEnderecoObrigatorio === true) {
        if (
          !model.proprietarioId ||
          !model.placa ||
          !model.renavam ||
          !model.chassi ||
          !model.cor ||
          !model.veiculoMarca ||
          !model.modelo ||
          !model.veiculoTipo ||
          !model.tipoFrota ||
          !model.modeloAno ||
          !model.fabricacaoAno ||
          !model.emplacamentoPais ||
          !model.emplacamentoUf ||
          !model.emplacamentoCidade ||
          !model.comunicacao ||
          !model.tecnologiaRastreamento ||
          !isPlacaValid ||
          !isValidAnoModelo ||
          !isValidAnoFabricacao
        ) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setIsFormInvalid(true);
          e.stopPropagation();
          return;
        } else {
          setLoadingCadastro(true);
          try {
            if (isPlacaRegistered) {
              throw new Error('Placa já registrada.');
            }

            await axios.post('/veiculo', model);

            setLoadingCadastro(false);
            setIsDialogVisibleCadEfetuado(true);
            setTimeout(() => {
              setShowModalCadastro(false);
              limparFormulario();
              loadVeiculos();
              setIsDialogVisibleCadEfetuado(false);
              setIsFormInvalid(false);
            }, 1000);
          } catch (err: any) {
            addToast({
              title: 'Erro!',
              description: 'Erro ao cadastrar',
              type: 'error',
            });
          }
        }
      }
    }
  }

  const limparFormulario = () => {
    setModel({
      ...model,
      placa: '',
      modelo: '',
      proprietarioId: 0,
      veiculoMarca: 0,
      renavam: '',
      chassi: '',
      cor: 0,
      veiculoTipo: 0,
      tipoFrota: '',
      frota: '',
      anttRntrc: 0,
      cubagem: 0,
      modeloAno: 0,
      fabricacaoAno: 0,
      tecnologiaRastreamento: undefined,
      comunicacao: null,
      numeroEquipamento: '',
      emplacamentoPais: null,
      emplacamentoCidade: null,
      emplacamentoUf: null,
      tipoFaturamento: '',
      obs: '',
      bloqueado: false,
      carroceriaTipo: 0,
      dataUltimaAlteracao: new Date().toISOString(),
      qtdeViagens: 0,
      cadastro: new Date(DATA_ATUAL_ISO).toISOString(),
      valorMedioTransportado: 0,
      tempoCadastro: '',
      mapa: false,
      atualizacaoAutomatica: false,
      ativo: true,
      veiculoMarcaNome: '',
      veiculoTipoNome: '',
      tecnologiaRastreamentoNome: '',
    });
  };

  const toggleSwitchMapa = () => {
    setMapa((previousState) => !previousState);
    setModel({
      ...model,
      mapa: !mapa,
    });
  };

  const toggleSwitchAtualizacaoAutomatica = () => {
    setModel({
      ...model,
      atualizacaoAutomatica: !model.atualizacaoAutomatica,
    });
  };

  async function findVei(id: number) {
    try {
      const response = await axios.get(`veiculo/${id}`);
      setModel({
        ...response.data,
        comunicacao: response.data.comunicacao,
        tempoCadastro: '',
      });
      setPreviousPlaca(response.data.placa);
      setIsPlacaValid(true);
    } catch (err: any) {
      console.error(err);
    }
  }

  const [flagDadosEnderecoObrigatorio, setFlagDadosEnderecoObrigatorio] = useState(false);
  useEffect(() => {
    const getParticularidades = async () => {
      if (clienteId === null) {
        try {
          const response = await axios.get(`/cliente/getParticularidadesCliente?cliente=${state}`);
          setFlagDadosEnderecoObrigatorio(response.data.flagDadosEnderecoObrigatorio);
        } catch (err: any) {
          console.log(err);
        }
      } else {
        try {
          const response = await axios.get(
            `/cliente/getParticularidadesCliente?cliente=${clienteId}`
          );
          setFlagDadosEnderecoObrigatorio(response.data.flagDadosEnderecoObrigatorio);
        } catch (err: any) {
          console.log(err);
        }
      }
    };
    getParticularidades();
  }, [clienteId, state]);

  const [isDialogVisibleRecuperaDados, setIsDialogVisibleRecuperaDados] = useState(false);

  async function handlePlacaInativa(placa: string) {
    if (isCarreta) {
      return;
    }
    const response = await axios.get(
      `/veiculo/reutilizarVeiculoPorClientePlaca?idCliente=${clienteId}&placa=${placa}`
    );
    if (response.data) {
      setIsDialogVisibleRecuperaDados(true);
    }
  }

  function validaAnoModelo(modeloAno: any) {
    if (
      (modeloAno != undefined && modeloAno != 0 && modeloAno < 1960) ||
      (modeloAno != undefined && modeloAno != 0 && modeloAno > ANO_ATUAL)
    ) {
      setIsValidAnoModelo(false);
    } else {
      setIsValidAnoModelo(true);
    }
  }

  function validaAnoFabricacao(fabricacaoAno: any) {
    if (
      (fabricacaoAno != undefined && fabricacaoAno != 0 && fabricacaoAno < 1960) ||
      (fabricacaoAno != undefined && fabricacaoAno != 0 && fabricacaoAno > ANO_ATUAL)
    ) {
      setIsValidAnoFabricacao(false);
    } else {
      setIsValidAnoFabricacao(true);
    }
  }

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={showModalCadastro}
      onHide={() => {
        setShowModalCadastro(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cadastrar Veículo</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'row' }}>
          <Dialog
            header={
              isCarreta
                ? 'Carreta foi cadastrada com sucesso!'
                : 'Veículo foi cadastrado com sucesso!'
            }
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCadEfetuado(false)}>Ok</Button>
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadEfetuado(false)}
          />

          <div className="painel">
            <Form
              className="form-sm"
              onSubmit={onSubmit}
              noValidate
              validated={isFormInvalid}
              style={{ marginTop: '0px' }}
            >
              {isAllowedByRole(['admin']) && (
                <Form>
                  <Form.Row style={{ width: '50%' }}>
                    <Form.Group as={Col}>
                      <Form.Label>FATURAR COMO</Form.Label>
                      <Form.Control
                        as="select"
                        name="faturarComo"
                        placeholder="faturarComo"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setModel({
                            ...model,
                            tipoFaturamento: e.target.value,
                          });
                        }}
                        value={model.tipoFaturamento}
                        style={{ width: '50%', height: '50%', fontSize: '12px' }}
                      >
                        <option value="NAO INFORMADO">Não Informado</option>
                        <option value="MENSALISTA">Mensalista</option>
                        <option value="VIAGEM AVULSA">Viagem avulsa</option>
                        <option value="NAO RASTREADO">Não Rastreado</option>
                        <option value="DESABILITADO">Desabilitado</option>
                      </Form.Control>
                    </Form.Group>

                    <Form.Group className="mt-4">
                      <Form.Check
                        checked={model.bloqueado}
                        onChange={(event) => {
                          setModel({
                            ...model,
                            bloqueado: !model.bloqueado,
                          });
                        }}
                        name="bloqueado"
                        type="switch"
                        id="custom-switch2"
                        label={isCarreta ? 'Carreta Bloqueada' : 'Veículo Bloqueado'}
                      />
                    </Form.Group>
                  </Form.Row>
                </Form>
              )}

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label className="require">PROPRIETÁRIO</Form.Label>
                  <Form.Control
                    as="select"
                    name="proprietarioId"
                    placeholder="Proprietario"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: parseInt(e.target.value),
                      });
                    }}
                    required
                    value={model.proprietarioId}
                  >
                    <option value="">Selecione uma opção</option>
                    {proprietario.map((proprietario) => (
                      <option key={proprietario.id} value={proprietario.id}>
                        {proprietario.razaoSocial}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridPlaca">
                  <Form.Label className="require">PLACA</Form.Label>
                  <Form.Control
                    value={model.placa.toUpperCase()}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (validarPlaca(e.target.value) || e.target.value.length == 0) {
                        updateModel(e);
                      }

                      if (e.target.value.length === 7) {
                        if (validarPlaca(e.target.value) === true) {
                          updateModel(e);
                          setIsPlacaValid(true);
                        }
                      }
                    }}
                    onBlur={() => {
                      handlePlacaFocusOut(model.placa);
                      handlePlacaInativa(model.placa);
                    }}
                    maxLength={7}
                    minLength={7}
                    pattern="[A-Z]{3}[0-9][0-9A-Z][0-9]{2}"
                    name="placa"
                    type="text"
                    placeholder="LLLNNNN ou LLLNLNN"
                    required
                    title="Formato do campo deve ser LLLNNNN ou LLLNLNN "
                    //disabled={id}
                  />
                  {isPlacaRegistered && model.placa.length > 0 && (
                    <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Esta placa já foi registrada.
                    </p>
                  )}
                  {!isPlacaValid && model.placa.length > 0 && model.placa.length < 7 && (
                    <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Esta placa é inválida.
                    </p>
                  )}
                </Form.Group>

                <Form.Group as={Col} controlId="formGridRenavam">
                  <Form.Label className="require">RENAVAM</Form.Label>
                  <Form.Control
                    value={model.renavam}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (validaNumero(e.target.value) || e.target.value.length == 0) {
                        updateModel(e);
                      }
                    }}
                    name="renavam"
                    type="text"
                    minLength={11}
                    maxLength={11}
                    pattern="[0-9]+$"
                    title="Deve conter 11 números"
                    required
                  />{' '}
                  {model.renavam.length < 11 && model.renavam.length > 0 && (
                    <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Renavam inválido, 11 números necessários.
                    </p>
                  )}
                </Form.Group>

                <Form.Group as={Col} controlId="formGridChassi">
                  <Form.Label className="require">CHASSI</Form.Label>
                  <Form.Control
                    value={model.chassi}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                    name="chassi"
                    type="text"
                    placeholder=""
                    maxLength={17}
                    minLength={17}
                    required
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} controlId="formGridCor" required>
                  <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                    COR
                  </Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Cor"
                    name="cor"
                    value={model.cor}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: parseInt(e.target.value),
                      });
                    }}
                    required={flagDadosEnderecoObrigatorio}
                  >
                    <option value="">Selecione uma opção</option>
                    {cores.map((cor) => (
                      <option aria-required={true} key={cor.id} value={cor.id}>
                        {cor.corNome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridMarca">
                  <Form.Label className="require">MARCA</Form.Label>
                  <Form.Control
                    as="select"
                    name="veiculoMarca"
                    value={model.veiculoMarca}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      marca.map((marca) => {
                        if (marca.vmaCdId === parseInt(e.target.value)) {
                          setModel({
                            ...model,
                            veiculoMarcaNome: marca.vmaTxNome,
                          });
                        }
                      });
                      setModel({
                        ...model,
                        [e.target.name]: parseInt(e.target.value),
                      });
                    }}
                    required
                  >
                    <option value="">Selecione uma opção</option>
                    {marca.map((marca) => (
                      <option key={marca.vmaCdId} value={marca.vmaCdId}>
                        {marca.vmaTxNome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridModelo">
                  <Form.Label className="require">MODELO</Form.Label>
                  <Form.Control
                    value={model.modelo}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                    name="modelo"
                    maxLength={64}
                    type="text"
                    placeholder=""
                    required
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                {isCarreta ? (
                  <Form.Group as={Col} controlId="formGridTipo">
                    <Form.Label className="require">TIPO DE VEICULO</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Tipo de Veiculo"
                      name="veiculoTipo"
                      value={model.veiculoTipo}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        tipoCarreta.map((tipoCarreta) => {
                          if (tipoCarreta.vtpCdId === parseInt(e.target.value)) {
                            setModel({
                              ...model,
                              veiculoTipoNome: tipoCarreta.vtpTxNome,
                            });
                          }
                        });
                        setModel({
                          ...model,
                          [e.target.name]: parseInt(e.target.value),
                        });
                      }}
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      {tipoCarreta.map((carreta, index) => (
                        <option key={index} value={carreta.vtpCdId}>
                          {carreta.vtpTxNome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                ) : (
                  <Form.Group as={Col} controlId="formGridTipo">
                    <Form.Label className="require">TIPO DE VEICULO</Form.Label>
                    <Form.Control
                      as="select"
                      placeholder="Tipo de Veiculo"
                      name="veiculoTipo"
                      value={model.veiculoTipo}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        veiTipo.map((tipo) => {
                          if (tipo.vtpCdId === parseInt(e.target.value)) {
                            setModel({
                              ...model,
                              veiculoTipoNome: tipo.vtpTxNome,
                            });
                          }
                        });
                        setModel({
                          ...model,
                          [e.target.name]: parseInt(e.target.value),
                        });
                      }}
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      {veiTipo.map((veiculo, index) => (
                        <option key={index} value={veiculo.vtpCdId}>
                          {veiculo.vtpTxNome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                )}

                <Form.Group as={Col} controlId="formGridFrota">
                  <Form.Label className="require">TIPO FROTA</Form.Label>
                  <Form.Control
                    value={model.tipoFrota}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                    name="tipoFrota"
                    as="select"
                    placeholder=""
                    required
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="1">PRÓPRIO</option>
                    <option value="2">AGREGADO</option>
                    <option value="3">TERCEIRO</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridAntt">
                  <Form.Label>ANTT \ RNTRC</Form.Label>
                  <Form.Control
                    value={model.anttRntrc === 0 ? '' : model.anttRntrc}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (validaNumero(e.target.value) || e.target.value.length == 0) {
                        updateModel(e);
                      }
                    }}
                    name="anttRntrc"
                    type="text"
                    placeholder=""
                    maxLength={14}
                    pattern="[0-9]+$"
                    title="Digite apenas numeros"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridCubagem">
                  <Form.Label>CUBAGEM</Form.Label>
                  <Form.Control
                    value={model.cubagem === 0 ? '' : model.cubagem}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: soNumeros(e.target.value),
                      });
                    }}
                    name="cubagem"
                    maxLength={4}
                    minLength={1}
                    type="text"
                    placeholder=""
                    title="Este campo aceita apenas numeros"
                  />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridAno" required>
                  <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                    ANO MODELO
                  </Form.Label>
                  <Form.Control
                    value={model.modeloAno === 0 ? undefined : model.modeloAno}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]:
                          e.target.value.length === 0 ? undefined : soNumeros(e.target.value),
                      });
                    }}
                    onBlur={() => validaAnoModelo(model.modeloAno)}
                    placeholder=""
                    data-mask="0000"
                    maxLength={4}
                    minLength={4}
                    name="modeloAno"
                    pattern="[0-9]+$"
                    type="text"
                    required={flagDadosEnderecoObrigatorio}
                    title="Este campo aceita apenas numeros"
                  />{' '}
                  {!isValidAnoModelo ? (
                    <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Ano inválido, favor escolher um ano entre 1960 e {ANO_ATUAL}.
                    </p>
                  ) : (
                    ''
                  )}
                </Form.Group>

                <Form.Group as={Col} controlId="formGridFabricacao">
                  <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                    ANO FABRICAÇÃO
                  </Form.Label>
                  <Form.Control
                    value={model.fabricacaoAno === 0 ? undefined : model.fabricacaoAno}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]:
                          e.target.value.length === 0 ? undefined : soNumeros(e.target.value),
                      });
                    }}
                    onBlur={() => validaAnoFabricacao(model.fabricacaoAno)}
                    placeholder=""
                    data-mask="0000"
                    min={1960}
                    max={ANO_ATUAL}
                    maxLength={4}
                    minLength={4}
                    name="fabricacaoAno"
                    pattern="[0-9]+$"
                    type="text"
                    required={flagDadosEnderecoObrigatorio}
                    title="Este campo aceita apenas numeros"
                  />{' '}
                  {!isValidAnoFabricacao ? (
                    <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Ano inválido, favor escolher um ano entre 1960 e {ANO_ATUAL}.
                    </p>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </Form.Row>

              {isCarreta ? (
                <></>
              ) : (
                <Form.Row>
                  <Form.Group as={Col} controlId="formGridTecnologia">
                    <Form.Label className="require">TEC. RASTREAMENTO</Form.Label>
                    <Form.Control
                      value={model.tecnologiaRastreamento!}
                      as="select"
                      name="tecnologiaRastreamento"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => handleTecnologia(e)}
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      {veiTer.map((veiTer) => (
                        <option key={veiTer.terCdId} value={veiTer.terCdId}>
                          {veiTer.terTxNome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridComunicacao">
                    <Form.Label className="required">TIPO COMUNICAÇÃO</Form.Label>
                    <Form.Control
                      value={model.comunicacao! ? model.comunicacao! : ''}
                      as="select"
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setModel((prevState) => {
                          return {
                            ...prevState,
                            [e.target.name]: e.target.value,
                          };
                        })
                      }
                      name="comunicacao"
                      required
                    >
                      <option value="">Selecione uma opção</option>

                      {tipoComunicacao.map((ttc) => (
                        <option key={ttc.id} value={ttc.nome}>
                          {ttc.nome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} controlId="formGridEqpto">
                    <Form.Label>Nº EQUIPAMENTO</Form.Label>
                    <Form.Control
                      value={model.numeroEquipamento}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setModel({
                          ...model,
                          [e.target.name]: soNumeros(e.target.value),
                        })
                      }
                      name="numeroEquipamento"
                      type="text"
                      maxLength={32}
                      placeholder=""
                      title="Este campo aceita apenas numeros"
                    />
                  </Form.Group>
                </Form.Row>
              )}
              <Form.Row>
                <Form.Group as={Col} md={6}>
                  <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                    PAÍS EMPLACAMENTO
                  </Form.Label>
                  <Form.Control
                    value={!model.emplacamentoPais ? '' : model.emplacamentoPais}
                    as="select"
                    name="emplacamentoPais"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: parseInt(e.target.value ?? null),
                      });
                    }}
                    required={flagDadosEnderecoObrigatorio}
                  >
                    <option value="">Selecione uma opção</option>
                    {pais.map((pai) => (
                      <option key={pai.id} value={pai.id}>
                        {pai.paisDescricao}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridUF">
                  <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                    UF EMPLACAMENTO
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="emplacamentoUf"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: parseInt(e.target.value, 10 ?? null),
                        };
                      });
                    }}
                    required={flagDadosEnderecoObrigatorio}
                    value={!model.emplacamentoUf ? '' : model.emplacamentoUf}
                  >
                    <option value="">Selecione uma opção</option>
                    {ufSigla.map((uf, index) => (
                      <option key={index} value={uf.id}>
                        {uf.sigla}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridCidade">
                  <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                    CIDADE EMPLACAMENTO
                  </Form.Label>
                  <Form.Control
                    as="select"
                    disabled={municipioFilter ? false : true}
                    name="emplacamentoCidade"
                    value={!model.emplacamentoCidade ? '' : model.emplacamentoCidade}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setModel((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: parseInt(e.target.value, 10 ?? null),
                        };
                      })
                    }
                    required={flagDadosEnderecoObrigatorio}
                  >
                    <option value="">Selecione uma opção</option>
                    {municipiosPorEstado.map((municipio) => (
                      <option key={municipio.id} value={municipio.id}>
                        {municipio.nomeMunicipio}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridObs">
                  <Form.Label>OBSERVAÇÕES</Form.Label>
                  <Form.Control
                    value={model.obs}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                    name="obs"
                    maxLength={256}
                    as="textarea"
                    placeholder="Observação"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridData">
                  <Form.Label>DATA DE CADASTRO</Form.Label>
                  <Form.Control
                    value={DATA_ATUAL}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                    name="cadastro"
                    type="text"
                    disabled
                  />
                </Form.Group>
              </Form.Row>

              <Form.Group className="group-swicth">
                <Switch
                  value={Number(model.atualizacaoAutomatica)}
                  checked={model.atualizacaoAutomatica}
                  onChange={toggleSwitchAtualizacaoAutomatica}
                  name="atualizacaoAutomatica"
                  id="pesquisa"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />

                <Switch
                  checked={model.mapa}
                  onChange={toggleSwitchMapa}
                  value={Number(mapa)}
                  name="mapa"
                  id="mapa"
                  type="switch"
                  label="Exibir no mapa"
                />
              </Form.Group>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          onClick={(e: any) => onSubmit(e)}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCadastroVeiculoPorSM;
