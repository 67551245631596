/* eslint-disable */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Logo from '../../assets/logo_golden.jpg';
import { getCookieSessionData } from '../../services/cookieService';
import { obterData, obterDataHora } from '../../util/date';

const GeneratePDFSat = (dadosTabela: any, model: any, totalData: any) => {
  const doc = new jsPDF();

  var tableColumn: any = [];
  var tableRows: any = [];

  let end: boolean = false;

  const empresa = model.razaoSocialCliente
    ? model.razaoSocialCliente
    : getCookieSessionData().usuarioVO.clienteRazaoSocial;

  dadosTabela.map((veiculo: any, i: any) => {
    title(veiculo);
    let tableTitle = ['Lançamentos da SAT', 'Situação', 'Lançamento', 'Finalização'];

    tableColumn = tableTitle;

    veiculo.listaDispositivo.map((each: any, idx: any) => {
      let tableDataRow = [
        each.descricao,
        each.situacao === true ? 'Defeito' : 'Ok',
        `${veiculo.dataInclusao ? obterDataHora(veiculo.dataInclusao, false) : ''} ${
          veiculo.usuarioLancamento ? veiculo.usuarioLancamento : ''
        }`,
        `${veiculo.dataFinalizacao ? obterDataHora(veiculo.dataFinalizacao, false) : ''} ${
          veiculo.dataFinalizacao !== null && veiculo.usuarioFinalizacao
            ? veiculo.usuarioFinalizacao
            : ''
        }`,
      ];

      tableRows.push(tableDataRow);

      if (dadosTabela.length === i + 1) end = true;

      if (veiculo.listaDispositivo.length === idx + 1) {
        page();
        tableRows.length = 0;
      }
    });
  });

  function title(veiculo: any) {
    doc.setFontSize(10);
    doc.text(
      `Veículo: ${
        veiculo.placaVeiculo !== null && veiculo.placaCarreta === null
          ? veiculo.placaVeiculo
          : veiculo.placaCarreta !== null && veiculo.placaVeiculo === null
          ? veiculo.placaCarreta
          : veiculo.placaVeiculo !== null && veiculo.placaCarreta !== null
          ? veiculo.placaCarreta
          : veiculo.numeroBau
          ? veiculo.numeroBau
          : ''
      }`,
      15,
      60
    );
    doc.setFontSize(10);
    doc.text(`Nº SAT: ${veiculo.numeroSat ? veiculo.numeroSat : ''}`, 80, 60);
  }

  function page() {
    // @ts-ignore

    doc.autoTable(tableColumn, tableRows, {
      startY: 65,
      theme: 'plain',
      styles: {
        overflow: 'linebreak',
        fontSize: 8,
        valign: 'middle',
      },
      columnStyles: {
        0: { valign: 'top' },
      },
      margin: { right: 20 },
      pageBreak: 'avoid',
      tableWidth: 'auto',
    });
    doc.addImage(Logo, 'jpg', 10, 10, 80, 20);

    doc.setFontSize(10);
    doc.text(`Página ${doc.internal.getNumberOfPages()}/${totalData}`, 180, 30);
    doc.text(`${new Date().toLocaleDateString('pt-BR')}`, 180, 35);
    doc.line(10, 38, 200, 38);
    doc.setFontSize(15);
    doc.text('Relatório SAT', 80, 35);

    doc.setFontSize(10);
    doc.text(`Empresa: ${empresa} `, 15, 45);
    doc.setFontSize(10);
    doc.text(
      `PERÍODO:${model.dataInicio ? obterData(model.dataInicio) : '/ /'} e ${
        model.dataFim ? obterData(model.dataFim) : '/ /'
      }`,
      142,
      45
    );
    doc.line(10, 50, 200, 50);

    if (!end) doc.addPage();
  }
  const date = new Date().toLocaleDateString('pt-BR').split(' ');
  const dateStr = date[0];

  doc.save(`relatorio_sat_${dateStr}.pdf`);
};

export default GeneratePDFSat;

