import styled from 'styled-components';

export const TableHead = styled.th`
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
`;

type TableRowProps = {
  isDialogAtualizandoOK: boolean;
};

export const TableRow = styled.tr<TableRowProps>`
  font-size: 8px;
  position: sticky;
  top: 0;
  height: 45px;
  padding-top: 5px;
  background: #ffffff;
  z-index: ${(props) => (props.isDialogAtualizandoOK ? '1' : '1000')};
`;

export const TableData = styled.td`
  padding: 0.3rem !important;
  border-bottom: 1px solid #eaeaea;
  border-top: none !important;
  font-size: 0.8rem;
  vertical-align: middle !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  button {
    padding: 0;
  }
`;

type Percentage = {
  percentage: number;
  color: string;
};

export const PercentageDiv = styled.div<Percentage>`
  height: 20px;
  background-color: #e0e0e0;
  width: 85%;
  margin: 0 auto;
  position: relative;
  .percentage-wrapper {
    display: block;
    height: 100%;
    width: 100%;
    z-index: -1;
    .text-div {
      height: 100%;
      background-color: ${(props: Percentage) => props.color};
      width: ${(props: Percentage) => props.percentage}%;
      position: inherit;
      p {
        font-weight: bold;
        font-size: 0.8rem;
        color: ${(props: Percentage) => (props.percentage > 50 ? '#fff' : '#010101')};
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`;
