/* eslint-disable */

import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col } from 'react-bootstrap';
import { GoInfo } from 'react-icons/go';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { OrgaoEmissorType } from '../../api/model/OrgaoEmissorType';
import axios from '../../config/axiosMaquina';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import { MunicipiosTypes } from '../../api/Types/municipiosTypes';
import checaCPF from '../../util/checaCpf';
import {
  cpfMask,
  soNumeros,
  validaNome,
  validaRG,
  maskTel,
  maskCep,
  soLetras,
} from '../../util/Validacoes/Validacoes';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';

import { getCookieSessionData } from '../../services/cookieService';
import {
  dataMaxParaMaioridadeFormatada,
  dataMinParaDataNascimentoFormatada,
  dataMinParaMaioridade,
  dataAtualFormatoAnoMesDia,
  obterDataHora,
  nowToZoned,
} from '../../util/date';
import { AjudanteType } from '../../api/model/AjudanteType';
import { useToast } from '../../hooks/Toast';
import { ProfilePicture } from '../../components/ProfilePicture';

function AjudanteCadastro() {
  const history = useHistory();
  const { fromSMCadastro }: any = useParams();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const { addToast } = useToast();

  const [cpf, setCpf] = useState('');
  const [nome, setNome] = useState('');
  const [rg, setRg] = useState('');
  const [statusApto, setStatusApto] = useState(true);
  const [dataEmissao, setDataEmissao] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [logradouroNumero, setLogradouroNumero] = useState<number>();
  const [logradouroComplemento, setLogradouroComplemento] = useState('');
  const [cidade, setCidade] = useState(0);
  const [dataNascimento, setDataNascimento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidadeNascimento, setCidadeNascimento] = useState(0);
  const [nomeMae, setNomeMae] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [referenciaNome, setReferenciaNome] = useState('');
  const [referenciaTelefone, setReferenciaTelefone] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nextelContato, setNextelContato] = useState('');
  const [grauParentesco, setGrauParentesco] = useState('');
  const [atualizarPesquisa, setAtualizarPesquisa] = useState(false);
  const [ativo, setAtivo] = useState(true);
  const [tipo, setTipo] = useState('');
  const [sexo, setSexo] = useState();
  const [orgaosEmissores, setOrgaosEmissores] = useState<number | undefined>();
  const [idUfEmissor, setIdUfEmissor] = useState<number>();
  const [idUfEndereco, setIdUfEndereco] = useState<number | undefined>();
  const [idUfNascimento, setIdUfNascimento] = useState<number>();
  const [dataCadastro, setDataCadastro] = useState(obterDataHora(new Date().toISOString()));
  const [foto, setFoto] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [isNomeValid, setIsNomeValid] = useState(false);
  const [isNomeMaeValid, setIsNomeMaeValid] = useState(false);
  const [isNomePaiValid, setIsNomePaiValid] = useState(false);
  const [error, setError] = useState(false);
  const [erroDataEmissaoRg, setErroDataEmissaoRg] = useState(false);
  const [erroDataNascimento, setErroDataNascimento] = useState(false);
  const [isGrauParentValid, setIsGrauParentValid] = useState(true);
  const [municipioFilter, setMunicipioFilter] = useState<MunicipiosType[] | null>(null);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const [isDialogVisibleRecuperaDados, setIsDialogVisibleRecuperaDados] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [isDialogVisibleCpfBlacklist, setIsDialogVisibleCpfBlacklist] = useState(false);
  const [dadosRecuperados, setDadosRecuperados] = useState(false);

  const [municipiosPorEstado, setMunicipiosPorEstado] = useState<MunicipiosTypes[]>([]);
  const [cidadesNascimentoPorEstado, setCidadesNascimentoPorEstado] = useState<MunicipiosTypes[]>(
    []
  );
  const [ufSiglas, setUfSiglas] = useState<UfIbgeType[]>([]);
  // lista de orgaos emissores vinda do banco de dados
  const [orgaoEmissor, setOrgaoEmissor] = useState<OrgaoEmissorType[]>([]);
  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [nomeUfEmissor, setNomeUfEmissor] = useState('');
  const [nomeUfEndereco, setNomeUfEndereco] = useState('');
  const [nomeUfNascimento, setNomeUfNascimento] = useState('');
  const [qtdeViagens, setQtdeViagens] = useState(0);
  const [tempoCadastro, setTempoCadastro] = useState('');
  const [valorMedioTransportado, setValorMedioTransportado] = useState(0);
  const [validacaoRg, setValidacaoRg] = useState(false);
  const [cpfErro, setCpfErro] = useState(false);
  const [cpfInativo, setCpfInativo] = useState(false);

  const [dataEmissaoMudou, setDataEmissaoMudou] = useState(true);
  const [dataNascimentoMudou, setDataNascimentoMudou] = useState(true);

  const [isRgFocused, setIsRgFocused] = useState(false);
  const [model, setModel] = useState<AjudanteType>({
    id: 0,
    cpf: '',
    nome: '',
    foto: '',
    tipo: '',
    sexo: '',
    rg: '',
    cep: '',
    logradouro: '',
    logradouroNumero: 0,
    logradouroComplemento: '',
    bairro: '',
    cidade: '',
    nomeCidade: '',
    dataNascimento: `${dataNascimento}`,
    cidadeNascimento: '',
    nomeCidadeNascimento: '',
    nomePai: '',
    nomeMae: '',
    nextelContato: '',
    telefone: '',
    referenciaTelefone: '',
    referenciaNome: '',
    grauParentesco: '',
    ativo: false,
    idUfEndereco: '',
    nomeUfEndereco: '',
    idOrgaoEmissor: 0,
    idUfEmissor: 0,
    nomeUfEmissor: '',
    idUfNascimento: 0,
    nomeUfNascimento: '',
    valorMedioTransportado: 0,
    tempoCadastro: '',
    qtdeViagens: '',
    dataEmissao: `${dataEmissao}`,
    siglaIbge: '',
    clienteId: { id: clienteId },
    atualizarPesquisa: false,
  });

  function updateModel(response: any) {
    setModel({
      id: response.id,
      cpf: response.cpf,
      nome: response.nome,
      foto: response.foto,
      tipo: response.tipo,
      sexo: response.sexo,
      rg: response.rg,
      cep: response.cep,
      logradouro: response.logradouro,
      logradouroNumero: response.logradouroNumero,
      logradouroComplemento: response.logradouroComplemento,
      bairro: response.bairro,
      cidade: response.cidade,
      nomeCidade: response.nomeCidade,
      dataNascimento: response.dataNascimento,
      cidadeNascimento: response.cidadeNascimento,
      nomeCidadeNascimento: response.nomeCidadeNascimento,
      nomePai: response.nomePai,
      nomeMae: response.nomeMae,
      nextelContato: response.nextelContato,
      telefone: response.telefone,
      referenciaTelefone: response.referenciaTelefone,
      referenciaNome: response.referenciaNome,
      grauParentesco: response.grauParentesco,
      ativo: response.ativo,
      idUfEndereco: response.idUfEndereco,
      nomeUfEndereco: response.nomeUfEndereco,
      idOrgaoEmissor: response.idOrgaoEmissor,
      idUfEmissor: response.idUfEmissor,
      nomeUfEmissor: response.nomeUfEmissor,
      idUfNascimento: response.idUfNascimento,
      nomeUfNascimento: response.nomeUfNascimento,
      valorMedioTransportado: response.valorMedioTransportado,
      tempoCadastro: response.tempoCadastro,
      qtdeViagens: response.qtdeViagens,
      dataEmissao: response.dataEmissao,
      siglaIbge: '',
      clienteId: { id: clienteId },
      atualizarPesquisa: response.atualizarPesquisa,
    });
  }

  useEffect(() => {
    const recuperarDadosPeloCpf = async () => {
      const response = await axios.get(
        `/ajudante-funcionario/reutilizarAjudantePorCpf?clienteId=${clienteId}&cpf=${cpf}`
      );
      if (response.data.cpf) {
        setIsDialogVisibleRecuperaDados(true);
        updateModel(response.data);
      } else {
        setIsDialogVisibleRecuperaDados(false);
      }
    };

    if (cpf) {
      recuperarDadosPeloCpf();
    }
  }, [cpf]); // Recuperar Dados Pelo CPF

  useEffect(() => {
    const recuperaDadosPeloRg = async () => {
      const response2 = await axios.get(
        `/ajudante-funcionario/reutilizarAjudantePorRg?clienteId=${clienteId}&rg=${rg}`
      );

      if (response2.data.rg) {
        setIsDialogVisibleRecuperaDados(true);
        updateModel(response2.data);
      } else {
        setIsDialogVisibleRecuperaDados(false);
      }
    };
    if (rg) {
      recuperaDadosPeloRg();
    }
  }, [rg]); // Recuperar Dados Pelo RG

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get('/orgao-emissor/getIdNome');
      setOrgaoEmissor(response.data);
    };
    fetchData();
  }, []); // setOrgaoEmissor

  const verificaCpfBlackList = async () => {
    if (cpf.length < 14) {
      return;
    }
    try {
      const response = await axios.get('/blacklist/verificarCpf', {
        params: {
          cpf,
        },
      });

      if (response.data) {
        setCpfErro(true);
      } else {
        setCpfErro(false);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `/ajudante-funcionario/verificarCpfAtivo/${clienteId}?keyword=${cpf}`
      );
      if (response.data === false) {
        setCpfInativo(true);
      } else {
        setCpfInativo(false);
      }
    };
    fetchData();
  }, [cpf]); // Verifica CPF Ativo

  useEffect(() => {
    if (cpfInativo) {
      return;
    }
    if (cpf.length === 14) {
      const validaCpfExistente = async () => {
        const data = {
          cpfAjudante: cpf,
        };
        try {
          const response = await axios.post(`/ajudante-funcionario/existeCPF/${clienteId}`, data);
          if (response.data === true) {
            addToast({
              title: 'Erro!',
              description: 'O CPF deste ajudante já está cadastrado.',
              type: 'error',
            });

            return;
          }
          setCpf(cpf);
        } catch (err: any) {
          console.log(err);
        }
      };
      validaCpfExistente();
    }
  }, [cpf]); // Valida CPF Existente

  const validaRgExistente = async () => {
    const data = {
      rgAjudante: rg,
    };
    try {
      const response = await axios.post(`/ajudante-funcionario/existeRG/${clienteId}`, data);
      if (response.data === true) {
        setValidacaoRg(true);
        addToast({
          title: 'Erro!',
          description: 'O RG deste ajudante já está cadastrado',
          type: 'error',
        });
        return;
      }
      setRg(rg);
      setValidacaoRg(false);
    } catch (err: any) {
      console.log(err);
      setValidacaoRg(false);
    }
  };

  useEffect(() => {
    const buscarCep = () => {
      if (cep.length < 9) {
        setCidade(0);
        setLogradouro('');
        setBairro('');
        return;
      }
      setError(false);
      fetch(`https://viacep.com.br/ws/${cep}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }
          setLogradouro(data.logradouro);
          setBairro(data.bairro);
          setCidade(parseInt(data.ibge));
          setIdUfEndereco(ufSiglas.find((each) => each.sigla === data.uf)?.id);
          loadMunicipiosPorEstado(ufSiglas.find((each: any) => each.sigla === data.uf)?.id);
        })
        .catch((erro) => {
          console.log('error', erro);
          setError(true);
        });
    };
    buscarCep();
  }, [cep]); // buscarCep

  useEffect(() => {
    axios.get('/uf/ListarIdSiglaEstadoPais/1').then((response: any) => {
      setUfSiglas(response.data);
    });
  }, []); // setUfSiglas

  const loadMunicipiosPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipiosPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const loadCidadesNascimentoPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setCidadesNascimentoPorEstado(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const handleRegister = async (event: any) => {
    event.preventDefault();
    if (dadosRecuperados === false) {
      if (
        !cpf ||
        !nome ||
        !rg ||
        !idUfEmissor ||
        !cep ||
        !logradouro ||
        !bairro ||
        !cidade ||
        logradouroNumero === null ||
        !idUfEndereco ||
        !dataNascimento ||
        !idUfNascimento ||
        !cidadeNascimento ||
        !nomePai ||
        !tipo ||
        !nomeMae ||
        !isValid ||
        !isNomePaiValid ||
        !isNomeValid ||
        !isNomeMaeValid ||
        !isGrauParentValid ||
        !(telefone.length >= 13 && telefone.length <= 14) ||
        (nextelContato && nextelContato.length < 13) ||
        (referenciaTelefone && referenciaTelefone.length < 13) ||
        erroDataNascimento 
      ) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os campos obrigatórios.',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    } else if (
      !model.cpf ||
      !model.nome ||
      !model.rg ||
      !model.idUfEmissor ||
      !model.cep ||
      !model.logradouro ||
      !model.bairro ||
      !model.cidade ||
      model.logradouroNumero === null ||
      !model.idUfEndereco ||
      !model.dataNascimento ||
      !model.idUfNascimento ||
      !model.cidadeNascimento ||
      !model.nomePai ||
      !model.nomeMae ||
      !model.tipo
    ) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os campos obrigatórios',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    if (validacaoRg === true) {
      addToast({
        title: 'Erro',
        description: 'O RG deste ajudante já está cadastrado.',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    const data = {
      cpf: cpf || model.cpf,
      nome: nome || model.nome,
      tipo: tipo || model.tipo,
      sexo: sexo || model.sexo,
      rg: rg || model.rg,
      idOrgaoEmissor:
        model.idOrgaoEmissor === 0
          ? model.idOrgaoEmissor.toString().replace('0', '')
          : orgaosEmissores || model.idOrgaoEmissor,
      idUfEmissor: idUfEmissor || model.idUfEmissor,
      dataEmissao: dataEmissaoMudou ? dataEmissao : model.dataEmissao,
      cep: cep || model.cep,
      logradouro: logradouro || model.logradouro,
      logradouroNumero: logradouroNumero || model.logradouroNumero,
      logradouroComplemento: logradouroComplemento || model.logradouroComplemento,
      cidade: cidade || model.cidade,
      idUfEndereco: idUfEndereco || model.idUfEndereco,
      dataNascimento: dataNascimentoMudou ? dataNascimento : model.dataNascimento,
      bairro: bairro || model.bairro,
      cidadeNascimento: cidadeNascimento || model.cidadeNascimento,
      nomeMae: nomeMae || model.nomeMae,
      nomePai: nomePai || model.nomePai,
      referenciaNome: referenciaNome || model.referenciaNome,
      referenciaTelefone: referenciaTelefone
        ? soNumeros(referenciaTelefone)
        : model.referenciaTelefone
        ? model.referenciaTelefone
        : '',
      telefone: telefone ? soNumeros(telefone) : model.telefone,
      nextelContato: nextelContato
        ? soNumeros(nextelContato)
        : model.nextelContato
        ? model.nextelContato
        : '',
      grauParentesco: grauParentesco || model.grauParentesco,
      atualizarPesquisa: atualizarPesquisa || model.atualizarPesquisa,
      ativo: dadosRecuperados ? true : ativo,
      idUfNascimento: idUfNascimento || model.idUfNascimento,
      foto: foto || model.foto,
      idCliente: clienteId,
      // CRIAR CAMPOS NO FORM PARA PREENCHER ESSES PARAMETROS
      bloqueado: statusApto,
      dataCadastro: nowToZoned(),
      dataUltimaAlteracao: nowToZoned(),
      idSM: null,
      nomeUfEmissor: nomeUfEmissor || model.nomeUfEmissor,
      nomeUfEndereco: nomeUfEndereco || model.nomeUfEndereco,
      nomeUfNascimento: nomeUfNascimento || model.nomeUfNascimento,
      qtdeViagens: qtdeViagens || model.qtdeViagens,
      tempoCadastro: tempoCadastro || model.tempoCadastro,
      valorMedioTransportado: valorMedioTransportado || model.valorMedioTransportado,
    };

    try {
      if (dadosRecuperados) {
        await axios.put(`/ajudante-funcionario/atualizar/${model.id}`, data);
        setIsDialogVisibleCadEfetuado(true);
      } else {
        await axios.post('/ajudante-funcionario', data);
        setIsDialogVisibleCadEfetuado(true);
      }
      if (fromSMCadastro) {
        window.close();
      }
      // history.push('/ajudantes/listar/1');
    } catch (err: any) {
      if (err.response.status === 409 && cpfInativo === false) {
        addToast({
          title: 'Erro!',
          description: 'O CPF deste ajudante já está cadastrado!',
          type: 'error',
        });
        return;
      }
      if (err.response.data.message === 'O CPF informado está cadastrado na BlackList') {
        setIsDialogVisibleCpfBlacklist(true);
        // alert('Ajudante cadastrado na Blacklist');
      } else {
        // alert('Erro ao cadastrar');
        addToast({
          title: 'Erro!',
          description: 'Erro ao cadastrar.',
          type: 'error',
        });
      }
    }
  };

  const validaDataNascimento = (value: any) => {
    const nasc = new Date(value);
    if (nasc.getTime() < dataMinParaMaioridade().getTime()) {
      setDataNascimento(value);
      setErroDataNascimento(false);
    } else {
      setDataNascimento(value);
      setErroDataNascimento(true);
    }
  };

  const validaDataMin = (value: any) => {
    const dataAtual = new Date();
    if (dataAtual.getTime() <= new Date(value).getTime()) {
      setDataEmissao(value);
      setErroDataEmissaoRg(true);
    } else {
      setDataEmissao(value);
      setErroDataEmissaoRg(false);
    }
  };

  function handleRgFocus() {
    setIsRgFocused(true);
  }

  function handleRgBlur() {
    setIsRgFocused(false);
    validaRgExistente();
  }

  useEffect(() => {
    if (!idUfNascimento || !municipios) {
      return;
    }

    const estado = ufs.filter((each) => each.id == idUfNascimento);

    const municipio = municipios.filter((each) => each.idCodigoIbge === estado[0].codigoIbge);
    setMunicipioFilter(municipio);
  }, [idUfNascimento, municipios]); // setMunicipioFilter

  useEffect(() => {}, [model]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container" style={{ marginBottom: '-20px' }}>
          <h2 className="titulo">Ajudantes</h2>
          <h6 className="subtitulo">{'Cadastro > Ajudante'}</h6>
        </div>

        <Dialog
          header="Este ajudante foi excluído anteriormente. Tem certeza que deseja reativar o cadastro dele?"
          footer={
            <>
              <Button
                label="Não"
                onClick={() => {
                  setIsDialogVisibleRecuperaDados(false);
                }}
              />
              <Button
                label="Sim"
                onClick={() => {
                  setDadosRecuperados(true);
                  setDataEmissaoMudou(false);
                  setDataNascimentoMudou(false);
                  setIsDialogVisibleRecuperaDados(false);
                }}
              />
            </>
          }
          visible={isDialogVisibleRecuperaDados}
          style={{ width: '50vw' }}
          modal
          closable={false}
          onHide={() => {
            setIsDialogVisibleRecuperaDados(false);
          }}
        />

        <Dialog
          header="Ajudante foi cadastrado com sucesso!"
          footer={
            <>
              <Button label="OK" onClick={() => history.push('/ajudantes/listar/1')} />
            </>
          }
          visible={isDialogVisibleCadEfetuado}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleCadEfetuado(false)}
        />

        <Dialog
          header="Ajudante foi cadastrado na Blacklist!"
          footer={
            <>
              <Button label="OK" onClick={() => setIsDialogVisibleCpfBlacklist(false)} />
            </>
          }
          visible={isDialogVisibleCpfBlacklist}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleCpfBlacklist(false)}
        />
        <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
        <Form noValidate validated={isFormInvalid} onSubmit={handleRegister} className="form-sm">
          <ProfilePicture
            cadastro
            setFoto={setFoto}
            foto={foto}
            name={cpf || (dadosRecuperados ? model.cpf : cpf)}
          >
            <Form.Group>
              <Form.Check
                checked={atualizarPesquisa}
                onChange={(event) => {
                  setAtualizarPesquisa(!atualizarPesquisa);
                }}
                name="atualizarPesquisa"
                type="switch"
                id="custom-switch1"
                label="Atualizar Pesquisa automaticamente"
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label>Data de cadastro</Form.Label>
                <Form.Control value={dataCadastro} id="dataCadastro" name="dataCadastro" disabled />
              </Form.Group>
            </Form.Row>
          </ProfilePicture>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">CPF</Form.Label>
              <Form.Control
                value={cpf || (dadosRecuperados ? model.cpf : cpf)}
                onChange={(event: any) => {
                  if (checaCPF(event.target.value) === false) {
                    setCpf(cpfMask(event.target.value));
                    setIsValid(false);
                  } else {
                    setCpf(cpfMask(event.target.value));
                    setIsValid(true);
                  }

                  if (dadosRecuperados) {
                    setModel({ ...model, cpf: event.target.value });
                  }
                }}
                name="cpf"
                required
                title="Cpf inválido"
                maxLength={14}
                minLength={14}
                onBlur={verificaCpfBlackList}
              />
              {cpfErro && (
                <p style={{ fontSize: '14px', color: 'red', marginTop: '5px' }}>
                  CPF cadastrado na blacklist
                </p>
              )}
              {!isValid && cpf.length > 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>Cpf inválido</p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label className="requiredField">Nome</Form.Label>
              <Form.Control
                value={nome || (dadosRecuperados ? model.nome : nome)}
                onChange={(event) => {
                  event.preventDefault();
                  setNome(soLetras(event.target.value));
                  if (validaNome(soLetras(event.target.value))) {
                    setIsNomeValid(true);
                  } else {
                    setIsNomeValid(false);
                  }
                }}
                name="nome"
                maxLength={256}
                required
                type="text"
                title="Preencha com dois nomes"
              />
              {!isNomeValid && nome.length > 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome inválido, inserir nome e sobrenome.
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Tipo</Form.Label>
              <Form.Control
                value={tipo || (dadosRecuperados ? model.tipo : tipo)}
                onChange={(event: any) => {
                  setTipo(event.target.value);
                }}
                id="tipo"
                as="select"
                required
              >
                <option value="">Selecione uma opção</option>
                <option value="1">Agregado</option>
                <option value="2">Autônomo</option>
                <option value="3">Funcionário</option>
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Sexo</Form.Label>
              <Form.Control
                value={sexo || (dadosRecuperados ? model.sexo : sexo)}
                onChange={(event: any) => {
                  setSexo(event.target.value);
                }}
                id="sexo"
                as="select"
              >
                <option value="">Selecione uma opção</option>
                <option value="F">Feminino</option>
                <option value="M">Masculino</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">RG</Form.Label>
              <Form.Control
                value={rg || (dadosRecuperados ? model.rg : rg)}
                onChange={(event) => {
                  setRg(validaRG(event.target.value));

                  if (dadosRecuperados) {
                    setModel({ ...model, rg: event.target.value });
                  }
                }}
                id="rg"
                name="rg"
                type="text"
                required
                maxLength={15}
                onFocus={handleRgFocus}
                onBlur={handleRgBlur}
              />
              {isRgFocused && !validacaoRg && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Inserir apenas letras e números.
                </p>
              )}
              {validacaoRg && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  O RG deste condutor já está cadastrado!
                </p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Orgão Emissor</Form.Label>
              <Form.Control
                value={
                  orgaosEmissores || (dadosRecuperados ? model.idOrgaoEmissor : orgaosEmissores)
                }
                onChange={(event: any) => {
                  setOrgaosEmissores(parseInt(event.target.value));
                }}
                as="select"
              >
                <option>Selecione uma opção</option>
                {orgaoEmissor.map((oE, index) => (
                  <option value={oE.id} key={index}>
                    {oE.nome}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">UF Emissor</Form.Label>
              <Form.Control
                value={idUfEmissor || (dadosRecuperados ? model.idUfEmissor : idUfEmissor)}
                onChange={(event: any) => {
                  setIdUfEmissor(parseInt(event.target.value));
                }}
                as="select"
                required
              >
                <option value="">Selecione uma opção</option>
                {ufSiglas.map((uf, index) => (
                  <option value={uf.id} key={index}>
                    {uf.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Data Emissão</Form.Label>
              <Form.Control
                onChange={(event: any) => {
                  setDataEmissaoMudou(true);
                  validaDataMin(event.target.value);
                }}
                id="dataEmissao"
                name="dataEmissao"
                type="date"
                min={dataMinParaDataNascimentoFormatada()}
                max={dataAtualFormatoAnoMesDia()}
                value={
                  dataEmissaoMudou
                    ? dataEmissao
                    : dadosRecuperados && model.dataEmissao
                    ? model.dataEmissao
                    : dataEmissao
                }
              />
              {erroDataEmissaoRg && (
                <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>
                  Data de Emissão não pode ser maior do que a data atual
                </p>
              )}
            </Form.Group>
          </Form.Row>

          {/* <Content>
            <FileProvider done={wasCadastred} foto={foto} setFoto={setFoto}>
              <Upload />
              <FileList />
            </FileProvider>
          </Content> */}

          <Form.Row>
            <Form.Group as={Col} md={6} className=" ml-2">
              <Form.Label>Status</Form.Label>
              <Form.Check
                checked={!statusApto}
                onChange={() => {
                  setStatusApto(!statusApto);
                }}
                name="statusApto"
                type="switch"
                id="custom-switch2"
                label="Apto para viagem"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
              <hr />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label className="requiredField">CEP</Form.Label>
              <Form.Control
                value={cep || (dadosRecuperados ? model.cep : cep)}
                onChange={(event) => {
                  setCep(maskCep(event.target.value));
                }}
                name="cep"
                type="text"
                required
                maxLength={9}
                minLength={9}
                style={{ width: '40%' }}
              />
              {error && <p style={{ fontSize: 15, color: 'red', marginTop: 5 }}>CEP Inválido</p>}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">UF</Form.Label>
              <Form.Control
                required
                value={idUfEndereco || (dadosRecuperados ? model.idUfEndereco : idUfEndereco)}
                onChange={(event: any) => {
                  setIdUfEndereco(parseInt(event.target.value));
                  loadMunicipiosPorEstado(parseInt(event.target.value));
                }}
                as="select"
              >
                <option value="">Selecione uma opção</option>
                {ufSiglas.map((uf, index) => (
                  <option value={uf.id} key={index}>
                    {uf.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Cidade</Form.Label>
              <Form.Control
                value={cidade || (dadosRecuperados ? model.nomeCidade : cidade)}
                onChange={(event: any) => {
                  setCidade(event.target.value);
                }}
                name="cidade"
                required
                as="select"
              >
                {model.nomeCidade && dadosRecuperados && (
                  <option value={model.cidade}>{model.nomeCidade}</option>
                )}
                <option value="">Selecione uma opção</option>
                {municipiosPorEstado.map((filteredMunicipio, index) => (
                  <option key={index} value={filteredMunicipio.id}>
                    {filteredMunicipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Bairro</Form.Label>
              <Form.Control
                value={bairro || (dadosRecuperados ? model.bairro : bairro)}
                onChange={(event) => {
                  setBairro(event.target.value);
                }}
                name="bairro"
                type="text"
                required
                maxLength={56}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Logradouro</Form.Label>
              <Form.Control
                value={logradouro || (dadosRecuperados ? model.logradouro : logradouro)}
                onChange={(event) => {
                  setLogradouro(event.target.value);
                }}
                name="logradouro"
                type="text"
                required
                maxLength={64}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Número Logradouro</Form.Label>
              <Form.Control
                value={
                  logradouroNumero ||
                  (model.logradouroNumero === 0
                    ? ''
                    : dadosRecuperados
                    ? model.logradouroNumero
                    : logradouroNumero)
                }
                onChange={(event: any) => {
                  setLogradouroNumero(soNumeros(event.target.value));
                }}
                id="logradouroNumero"
                name="logradouroNumero"
                required
                maxLength={8}
              />
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label>Complemento</Form.Label>
              <Form.Control
                value={
                  logradouroComplemento ||
                  (dadosRecuperados ? model.logradouroComplemento : logradouroComplemento)
                }
                onChange={(event) => {
                  setLogradouroComplemento(event.target.value);
                }}
                id="logradouroComplemento"
                name="logradouroComplemento"
                type="text"
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Data Nascimento</Form.Label>
              <br />
              <Form.Control
                value={
                  dataNascimentoMudou
                    ? dataNascimento
                    : dadosRecuperados && model.dataNascimento
                    ? model.dataNascimento
                    : dataNascimento
                }
                onChange={(event: any) => {
                  setDataNascimentoMudou(true);
                  validaDataNascimento(event.target.value);
                }}
                id="dataNascimento"
                name="dataNascimento"
                type="date"
                max={dataMaxParaMaioridadeFormatada()}
                min={dataMinParaDataNascimentoFormatada()}
                required
                style={{ width: '20%' }}
              />
              {erroDataNascimento && (
                <p style={{ fontSize: 11, color: 'red', marginTop: 5 }}>
                  Não é possivel cadastrar um ajudante com idade menor a 18 anos.
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">UF</Form.Label>
              <Form.Control
                value={idUfNascimento || (dadosRecuperados ? model.idUfNascimento : idUfNascimento)}
                onChange={(event: any) => {
                  setIdUfNascimento(parseInt(event.target.value));
                  loadCidadesNascimentoPorEstado(parseInt(event.target.value));
                }}
                as="select"
                required
              >
                <option value="">Selecione uma opção</option>
                {ufSiglas.map((uf, index) => (
                  <option value={uf.id} key={index}>
                    {uf.sigla}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Cidade Nascimento</Form.Label>
              <Form.Control
                as="select"
                disabled={!municipioFilter}
                value={
                  cidadeNascimento ||
                  (dadosRecuperados ? model.nomeCidadeNascimento : cidadeNascimento)
                }
                name="cidadeNascimento"
                onChange={(event: any) => {
                  setCidadeNascimento(parseInt(event.target.value));
                }}
                required
              >
                {model.nomeCidadeNascimento && dadosRecuperados && (
                  <option value={model.cidadeNascimento}>{model.nomeCidadeNascimento}</option>
                )}
                <option value="">Selecione uma opção</option>
                {cidadesNascimentoPorEstado.map((municipio, index) => (
                  <option key={index} value={municipio.id}>
                    {municipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Nome Mãe</Form.Label>
              <Button
                tooltip="Caso inexistente escreva no campo não declarado"
                tooltipOptions={{ position: 'bottom' }}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  padding: 0,
                  paddingRight: 5,
                  marginBottom: 5,
                }}
                type="button"
              >
                <GoInfo color="#737373" size="13px" />
              </Button>
              <Form.Control
                value={nomeMae || (dadosRecuperados ? model.nomeMae : nomeMae)}
                onChange={(event) => {
                  event.preventDefault();
                  setNomeMae(soLetras(event.target.value));
                  if (validaNome(soLetras(event.target.value))) {
                    setIsNomeMaeValid(true);
                  } else {
                    setIsNomeMaeValid(false);
                  }
                }}
                id="nomeMae"
                name="nomeMae"
                type="text"
                maxLength={256}
                required
                title="Preencha com dois nomes"
              />{' '}
              {!isNomeMaeValid && nomeMae.length > 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome inválido, inserir nome e sobrenome.
                </p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label className="requiredField">Nome Pai</Form.Label>
              <Button
                tooltip="Caso inexistente escreva no campo não declarado"
                tooltipOptions={{ position: 'bottom' }}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  padding: 0,
                  paddingRight: 5,
                  marginBottom: 5,
                }}
                type="button"
              >
                <GoInfo color="#737373" size="13px" />
              </Button>
              <Form.Control
                value={nomePai || (dadosRecuperados ? model.nomePai : nomePai)}
                onChange={(event) => {
                  event.preventDefault();
                  setNomePai(soLetras(event.target.value));
                  if (validaNome(soLetras(event.target.value))) {
                    setIsNomePaiValid(true);
                  } else {
                    setIsNomePaiValid(false);
                  }
                }}
                id="nomePai"
                maxLength={256}
                name="nomePai"
                type="text"
                title="Preencha com dois nomes"
                required
              />{' '}
              {!isNomePaiValid && nomePai.length > 1 && (
                <>
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Nome inválido, inserir nome e sobrenome. Caso não seja declarado, preencher como
                    "Não Declarado".
                  </p>
                  {/* <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Caso não seja declarado, preencher como "Não Declarado".
                    </p> */}
                </>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: 20, marginBottom: 20 }}>
              <hr />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label className="requiredField">Telefone</Form.Label>
              <Form.Control
                value={telefone || (dadosRecuperados ? model.telefone : telefone)}
                onChange={(event) => {
                  setTelefone(maskTel(event.target.value));
                }}
                id="telefone"
                name="telefone"
                type="text"
                maxLength={14}
                minLength={13}
                required
              />
              {!(telefone.length >= 13 && telefone.length <= 14) && telefone.length > 0 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Telefone deve ter entre 10 e 11 dígitos
                </p>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Segundo Telefone</Form.Label>
              <Form.Control
                value={nextelContato || (dadosRecuperados ? model.nextelContato : nextelContato)}
                onChange={(event) => {
                  setNextelContato(maskTel(event.target.value));
                }}
                id="nextelContato"
                name="nextelContato"
                type="text"
                minLength={14}
                maxLength={14}
              />
              {nextelContato && !(nextelContato.length >= 13 && nextelContato.length <= 14) && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Telefone deve ter entre 10 e 11 números.
                </p>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Referência Nome</Form.Label>
              <Form.Control
                value={referenciaNome || (dadosRecuperados ? model.referenciaNome : referenciaNome)}
                onChange={(event) => {
                  setReferenciaNome(soLetras(event.target.value));
                }}
                maxLength={64}
                id="referenciaNome"
                name="referenciaNome"
                type="text"
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Referência Telefone</Form.Label>
              <Form.Control
                value={
                  referenciaTelefone ||
                  (dadosRecuperados ? model.referenciaTelefone : referenciaTelefone)
                }
                onChange={(event) => {
                  setReferenciaTelefone(maskTel(event.target.value));
                }}
                id="referenciaTelefone"
                name="referenciaTelefone"
                type="text"
                maxLength={14}
                minLength={13}
              />
              {referenciaTelefone &&
                !(referenciaTelefone.length >= 13 && referenciaTelefone.length <= 14) && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Telefone deve ter entre 10 e 11 números.
                  </p>
                )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>Grau Parentesco</Form.Label>
              <Form.Control
                value={grauParentesco || (dadosRecuperados ? model.grauParentesco : grauParentesco)}
                onChange={(event) => {
                  event.preventDefault();
                  setGrauParentesco(soLetras(event.target.value));
                  if (soLetras(event.target.value)) {
                    setIsGrauParentValid(true);
                  } else {
                    setIsGrauParentValid(false);
                  }
                }}
                maxLength={32}
                id="grauParentesco"
                name="grauParentesco"
                type="text"
              />
              {!isGrauParentValid && grauParentesco.length >= 1 && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Grau de Parentesco inválido
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} style={{ marginTop: '-10px', marginBottom: 5 }}>
              <hr />
            </Form.Group>
          </Form.Row>

          <ButtonsForm />
        </Form>
      </div>
    </div>
  );
}

export default AjudanteCadastro;
