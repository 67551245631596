/* eslint-disable */
import { FC, useEffect, useState } from 'react';
import { Form, Row, Button, FormControl, Modal, Col } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { CorretoraType } from '../../Types/CorretoraType';
import api from '../../config/axiosMaquina';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';

type ModalCorretoraProps = {
  showModalCorretora: boolean;
  setShowModalCorretora: (bool: boolean) => void;
};

const ModalAddCorretora: FC<ModalCorretoraProps> = ({
  showModalCorretora,
  setShowModalCorretora,
}) => {
  const history = useHistory();
  // const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [corretora, setCorretora] = useState<CorretoraType>({
    corBlAtivo: true,
  } as CorretoraType);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogCadOK2, setIsDialogCadOK2] = useState(false);
  const { addToast } = useToast();

  const handleCadastro = async (event: any) => {
    event.preventDefault();

    if (
      !corretora.corTxDescricao ||
      !corretora.corTxNome ||
      corretora.corTxDescricao.trim().length == 0 ||
      corretora.corTxNome.trim().length == 0
    ) {
      setIsFormInvalid(true);
      setMensagem('Preencha os campos obrigatórios!');
      setIsDialogCadOK2(true);
      event.stopPropagation();
      return;
    }

    try {
      const response = await api.post('/corretora/corretora', corretora);
      setLoading(false);
      setMensagem('Corretora cadastrada com sucesso!');
      setIsDialogCadOK(true);
      setCorretora({
        ...corretora,
        corTxNome: '',
        corTxDescricao: '',
      });
    } catch (error: any) {
      console.log(error);
      setMensagem('Erro na comunicação com o servidor!');
      setIsDialogCadOK2(true);
      event.stopPropagation();
      return;
      setLoading(false);
    }
  };

  const valida = (event: any) => {
    if (!event || event.trim().length == 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        animation
        show={showModalCorretora}
        onHide={() => {
          setShowModalCorretora(false);
          setCorretora({
            ...corretora,
            corTxNome: '',
            corTxDescricao: '',
          });
          // setIsFormInvalid(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Cadastro Corretora</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Dialog
            header={mensagem}
            footer={
              <Button
                onClick={() => {
                  setIsDialogCadOK(false);
                  setShowModalCorretora(false);
                }}
              >
                Ok
              </Button>
            }
            visible={isDialogCadOK}
            style={{ width: '50vw' }}
            modal
            onHide={() => {
              setIsDialogCadOK(false);
              setShowModalCorretora(false);
            }}
          />
          <Dialog
            header={mensagem}
            footer={
              <Button
                onClick={() => {
                  setIsDialogCadOK2(false);
                }}
              >
                Ok
              </Button>
            }
            visible={isDialogCadOK2}
            style={{ width: '50vw' }}
            modal
            onHide={() => {
              setIsDialogCadOK2(false);
            }}
          />
          {/* <div style={{ display: 'flex', justifyContent: 'row' }}> */}
          <Form noValidate validated={isFormInvalid} onSubmit={handleCadastro}>
            <Form.Row className="container-buttons justify-content-end">
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Nome</Form.Label>
                <Form.Control
                  value={corretora?.corTxNome}
                  onChange={(e) => {
                    setCorretora({
                      ...corretora,
                      corTxNome: e.target.value,
                    });
                  }}
                  onBlur={() => valida(corretora?.corTxNome)}
                  type="text"
                  placeholder=""
                  maxLength={128}
                  required
                  title="Campo Obrigatório"
                />
              </Form.Group>
            </Form.Row>
            <Form.Row className="container-buttons justify-content-end">
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Descrição</Form.Label>
                <Form.Control
                  value={corretora?.corTxDescricao}
                  onChange={(e) => {
                    setCorretora({
                      ...corretora,
                      corTxDescricao: e.target.value,
                    });
                  }}
                  onBlur={() => valida(corretora?.corTxDescricao)}
                  type="text"
                  placeholder=""
                  maxLength={128}
                  required
                  title="Campo Obrigatório"
                  // ref={ref}
                />
              </Form.Group>
            </Form.Row>
            <Form.Switch style={{ marginLeft: '-30px' }}>
              <Form.Group>
                <Form.Check
                  checked={corretora?.corBlAtivo}
                  onChange={() => {
                    setCorretora({
                      ...corretora,
                      corBlAtivo: !corretora.corBlAtivo,
                    });
                  }}
                  type="switch"
                  id="custom-switch1"
                  label="Ativo"
                />
              </Form.Group>
            </Form.Switch>
            {/* <ButtonsForm /> */}
          </Form>
          {/* </div> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ color: '#000', fontWeight: 'bold' }}
            variant="primary"
            onClick={handleCadastro}
            // type="submit"
          >
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModalAddCorretora;
