/* eslint-disable */
import React from 'react';
import { ButtonTooltip } from '../ButtonTooltipo';
import { Spinner } from '../Spinner';

function objectValues<T extends {}>(obj: T) {
  return Object.keys(obj).map((objKey) => obj[objKey as keyof T]);
}

function objectKeys<T extends {}>(obj: T) {
  return Object.keys(obj).map((objKey) => objKey as keyof T);
}

type PrimitiveType = string | number | boolean;

function isPrimitive(value: any): value is PrimitiveType {
  if (value === null) return true;
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

interface MinTableItem {
  id: PrimitiveType;
}

type TableHeaders<T extends MinTableItem> = Record<keyof T, string>;

type CustomRenderers<T extends MinTableItem> = Partial<Record<keyof T, (it: T) => React.ReactNode>>;

interface TableProps<T extends MinTableItem> {
  items: T[];
  headers: TableHeaders<T>;
  customRenderers?: CustomRenderers<T>;
  handleEdit?: (id: PrimitiveType) => void;
  handleDelete?: (id: PrimitiveType) => void;
  hasId?: boolean;
  left?: boolean;
  loading?: boolean;
}

interface ButtonType {
  handleEdit?: (id: PrimitiveType) => void;
  handleDelete?: (id: PrimitiveType) => void;
  item?: any;
  left?: boolean;
}

const renderTableData = (item: any, itemProperty: any, index: number, hasId?: boolean) => {
  const randomKey = (index + Math.random()).toString();
  if (isPrimitive(item[itemProperty]) && hasId) {
    return <td key={randomKey}>{item[itemProperty]}</td>;
  }
  if (isPrimitive(item[itemProperty])) {
    if (itemProperty === 'id') {
      return null;
    }
    return <td key={randomKey}>{item[itemProperty]}</td>;
  }
  return '';
};

const renderEditDeleteButtons = (props: ButtonType) => {
  const { handleEdit, handleDelete, item, left } = props;
  if (handleEdit && handleEdit) {
    return (
      <td align={left ? 'left' : 'right'}>
        <ButtonTooltip type="Editar" onClick={() => handleEdit(item.id)} />
      </td>
    );
  }

  if (handleDelete && handleDelete) {
    return (
      <td align={left ? 'left' : 'right'}>
        <ButtonTooltip type="Excluir" onClick={() => handleDelete(item.id)} />
      </td>
    );
  }
  return null;
};

export default function Table<T extends MinTableItem>(props: TableProps<T>) {
  const { headers, items, handleEdit, handleDelete, hasId, left, loading, customRenderers } = props;
  function renderRow(item: T, index: number) {
    return (
      <tr key={index}>
        {left && renderEditDeleteButtons({ handleEdit, handleDelete, item, left })}
        {objectKeys(item).map((itemProperty) => {
          const customRenderer = customRenderers?.[itemProperty];
          if (customRenderer) {
            return <td key={index}>{customRenderer(item)}</td>;
          }
          return renderTableData(item, itemProperty, index, hasId);
        })}
        {!left && renderEditDeleteButtons({ handleEdit, handleDelete, item, left })}
      </tr>
    );
  }

  return (
    <div className="table-responsive">
      {loading ? (
        <Spinner />
      ) : (
        <table className="table">
          <thead>
            <tr>
              {handleEdit && left && <th> </th>}
              {handleDelete && left && <th> </th>}
              {objectValues(headers).map((headerValue, index) => {
                if (headerValue === 'Id' && !hasId) {
                  return null;
                }
                return <th key={index}>{headerValue}</th>;
              })}
              {handleEdit && !left && <th> </th>}
              {handleDelete && !left && <th> </th>}
            </tr>
          </thead>
          <tbody>{items.map(renderRow)}</tbody>
        </table>
      )}
    </div>
  );
}
