/* eslint-disable */
import axios from 'axios';

export const profileMenu = async (idCliente: string | number) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profile-menu/?idCliente=${idCliente}`
    );

    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error;
    }
    return Error('A problem has occurred');
  }
};

export const profileMenuGr = async (idUser?: any) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/profile-menu/`, {
      params: {
        ...(idUser && { idUsuario: idUser }),
      },
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error;
    }
    return Error('A problem has occurred');
  }
};
