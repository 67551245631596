import React from 'react';
import { Button } from 'primereact/button';
import {
  IoMailUnread,
  IoCloseSharp,
  IoMailOpenSharp,
  IoCheckmarkSharp,
  IoFunnelOutline,
  IoDownloadOutline,
} from 'react-icons/io5';
import { BsThreeDotsVertical } from 'react-icons/bs';

type PropsType = {
  onClick: any;
  type:
    | 'Excluir'
    | 'Cancelar'
    | 'Enviar'
    | 'Editar'
    | 'Visualizar'
    | 'Visualizar SM'
    | 'Cancelar SM'
    | 'Detalhes'
    | 'Check'
    | 'Filtro'
    | 'Opções'
    | 'Baixar'
    | 'Aceitar'
    | 'Recusar';
  color?: string;
  disabled?: boolean;
  check?: boolean;
  tooltip?: string;
};

export const ButtonTooltip = ({ onClick, type, color, disabled, check, tooltip }: PropsType) => {
  return (
    <Button
      tooltip={check ? tooltip : type}
      tooltipOptions={{ position: 'bottom' }}
      type="button"
      style={{
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        padding: 0,
        paddingRight: 5,
        marginBottom: 2,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {type === 'Excluir' && (
        <i className="pi pi-trash" id="buttonPointer" style={{ color: color || 'red' }} />
      )}
      {type === 'Editar' && (
        <i className="pi pi-pencil" id="buttonPointer" style={{ color: color || 'black' }} />
      )}
      {type === 'Enviar' && (
        <i className="pi pi-check" id="buttonPointer" style={{ color: color || 'black' }} />
      )}
      {type === 'Cancelar' && (
        <i className="pi pi-times" id="buttonPointer" style={{ color: color || 'red' }} />
      )}
      {type === 'Visualizar' && <i className="pi pi-eye" style={{ color: color || 'black' }} />}
      {type === 'Visualizar SM' && <IoMailUnread size={18} color="#9F9F9F" />}
      {type === 'Cancelar SM' && <IoCloseSharp size={22} color="red" />}
      {type === 'Detalhes' && <IoMailOpenSharp size={22} color="#9F9F9F" />}
      {type === 'Check' && <IoCheckmarkSharp size={22} color={color} />}
      {type === 'Filtro' && <IoFunnelOutline size={22} color={color || 'black'} />}
      {type === 'Opções' && <BsThreeDotsVertical size={22} color={color || 'black'} />}
      {type === 'Baixar' && <IoDownloadOutline size={22} color={color || 'black'} />}
      {type === 'Recusar' && <i className="pi pi-times" style={{ color: color || 'red' }} />}
      {type === 'Aceitar' && <i className="pi pi-check" style={{ color: color || 'green' }} />}
    </Button>
  );
};
