const getUfEndereco = (idUfEndereco: number): string => {
  if (idUfEndereco === 12) {
    return 'AC';
  }
  if (idUfEndereco === 27) {
    return 'AL';
  }
  if (idUfEndereco === 13) {
    return 'AM';
  }
  if (idUfEndereco === 16) {
    return 'AP';
  }
  if (idUfEndereco === 2210) {
    return 'AR-A';
  }
  if (idUfEndereco === 222) {
    return 'AR-B';
  }
  if (idUfEndereco === 221) {
    return 'AR-C';
  }
  if (idUfEndereco === 2212) {
    return 'AR-D';
  }
  if (idUfEndereco === 226) {
    return 'AR-E';
  }
  if (idUfEndereco === 229) {
    return 'AR-F';
  }
  if (idUfEndereco === 2214) {
    return 'AR-G';
  }
  if (idUfEndereco === 2216) {
    return 'AR-H';
  }
  if (idUfEndereco === 2211) {
    return 'AR-J';
  }
  if (idUfEndereco === 223) {
    return 'AR-K';
  }
  if (idUfEndereco === 2221) {
    return 'AR-L';
  }
  if (idUfEndereco === 228) {
    return 'AR-M';
  }
  if (idUfEndereco === 2219) {
    return 'AR-N';
  }
  if (idUfEndereco === 2220) {
    return 'AR-Q';
  }
  if (idUfEndereco === 2222) {
    return 'AR-R';
  }
  if (idUfEndereco === 2213) {
    return 'AR-S';
  }
  if (idUfEndereco === 2215) {
    return 'AR-T';
  }
  if (idUfEndereco === 2217) {
    return 'AR-U';
  }
  if (idUfEndereco === 2224) {
    return 'AR-V';
  }
  if (idUfEndereco === 225) {
    return 'AR-W';
  }
  if (idUfEndereco === 224) {
    return 'AR-X';
  }
  if (idUfEndereco === 227) {
    return 'AR-Y';
  }
  if (idUfEndereco === 2223) {
    return 'AR-Z';
  }
  if (idUfEndereco === 29) {
    return 'BA';
  }
  if (idUfEndereco === 23) {
    return 'CE';
  }
  if (idUfEndereco === 53) {
    return 'DF';
  }
  if (idUfEndereco === 32) {
    return 'ES';
  }
  if (idUfEndereco === 52) {
    return 'GO';
  }
  if (idUfEndereco === 21) {
    return 'MA';
  }
  if (idUfEndereco === 31) {
    return 'MG';
  }
  if (idUfEndereco === 50) {
    return 'MS';
  }
  if (idUfEndereco === 21) {
    return 'MT';
  }
  if (idUfEndereco === 15) {
    return 'PA';
  }
  if (idUfEndereco === 25) {
    return 'PB';
  }
  if (idUfEndereco === 26) {
    return 'PE';
  }
  if (idUfEndereco === 22) {
    return 'PI';
  }
  if (idUfEndereco === 41) {
    return 'PR';
  }
  if (idUfEndereco === 33) {
    return 'RJ';
  }
  if (idUfEndereco === 24) {
    return 'RN';
  }
  if (idUfEndereco === 11) {
    return 'RO';
  }
  if (idUfEndereco === 14) {
    return 'RR';
  }
  if (idUfEndereco === 43) {
    return 'RS';
  }
  if (idUfEndereco === 42) {
    return 'SC';
  }
  if (idUfEndereco === 28) {
    return 'SE';
  }
  if (idUfEndereco === 35) {
    return 'SP';
  }
  if (idUfEndereco === 17) {
    return 'TO';
  }
  return '';
};

export default getUfEndereco;
