/* eslint-disable */
import axios from '../config/axiosMaquina';
import { AuthResponse } from './model/AuthResponse';

//import { saveSessionData } from '../services/authService';
import { saveCookieSessionData } from '../services/cookieService';

export const postLoginUser = async (
  login: string,
  senha: string,
  role: string,
  codigo: number | null
) => {
  try {
    const response = await axios.post<AuthResponse>('auth/logar', {
      login,
      senha,
      role,
      codigo,
    });





    if (response.status === 200) {
      if (response.data.usuarioVO.role === 'user' && typeof codigo !== 'number') {
        throw new Error('Tente logar como cliente');
      } else {
        saveCookieSessionData(response.data);
        return response.data;
      }
    }
    throw new Error(`Erro ao acessar API: ${response.status} -  ${response.statusText}`);
  } catch (error: any) {
    throw new Error(`Erro ao acessar API: ${error.message}`);
  }
};

export const confirmLoginUser = async (
  login: string,
  senha: string,
  role: string,
  codigo: number | null
) => {
  try {
    const response = await axios.post<AuthResponse>('auth/logar', {
      login,
      senha,
      role,
      codigo,
    });
    if (response.status === 200) {
      return response.data;
    }
    throw new Error(`Erro ao acessar API: ${response.status} -  ${response.statusText}`);
  } catch (error: any) {
    throw new Error(`Erro ao acessar API: ${error.message}`);
  }
};

export const getRefreshToken = async (refreshToken: string) => {
  try {
    const response = await axios.get<string>('auth/refresh', {
      headers: { 'refresh-token': refreshToken },
    });
    if (response.status === 200) {
      return response.headers['access-token'];
    }
    throw new Error(`Erro ao acessar API: ${response.status} -  ${response.statusText}`);
  } catch (error: any) {
    throw new Error(`Erro ao acessar API: ${error.message}`);
  }
};

export const getLogout = async (refreshToken: string) => {
  try {
    const response = await axios.get<string>('auth/logout', {
      headers: { 'refresh-token': refreshToken },
    });
    if (response.status === 200) {
      return true;
    }
    throw new Error(`Erro ao acessar API: ${response.status} -  ${response.statusText}`);
  } catch (error: any) {
    throw new Error(`Erro ao acessar API: ${error.message}`);
  }
};

export const other = () => {};
