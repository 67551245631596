/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Form } from 'react-bootstrap';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData } from '../../services/cookieService';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import checaCPF from '../../util/checaCpf';
import { cpfMask, soNumeros } from '../../util/Validacoes/Validacoes';
import '../ConsultarPesquisasLancadas/styles.css';
import ListagemConsultaCondutor from './ListagemConsultaCondutor';

type ListagemConsulta = {
  atualizaDadosCondutor: boolean;
  cpfCondutor: string;
  idCondutor: number;
  nomeCondutor: string;
  razaoSocialCliente: string;
  tipoCondutor: string;
  bloqueado: boolean;
};

export type FiltroCondutor = {
  cpfCondutor: string | null;
  idCliente: number;
  nomeCondutor: string | null;
  pagina: number;
  qtdRegistros: number;
  tipo: string | null;
};

const ConsultarCondutor = () => {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const listaTipos = [
    { value: 'Agregado', nome: 'Agregado' },
    { value: 'Autonomo', nome: 'Autônomo' },
    { value: 'Funcionario', nome: 'Funcionário' },
  ];

  const [empresa, setEmpresa] = useState('');
  const [tipo, setTipo] = useState('');
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [placa, setPlaca] = useState('');
  const [cpf, setCpf] = useState('');
  const [numLiberacao, setNumLiberacao] = useState('');
  const [nomeCondutor, setNomeCondutor] = useState('');
  const [model, setModel] = useState<FiltroCondutor>({} as FiltroCondutor);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [dataResults, setDataResults] = useState<ListagemConsulta[]>();
  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [itensPerPage, setItensPerPage] = useState('10');

  const [isCpfValid, setIsCpfValid] = useState(true);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaClientes(listaAtivos);
        console.log(listaAtivos);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    console.log('useeffect model: ', model);
  }, [model]);

  const buscar = async (event: any) => {
    event.preventDefault();
    if (!isCpfValid && cpf.length > 0) {
      // alert('Verifique os dados preenchidos.');
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    try {
      const response = await api.get(`/condutor/consulta-condutor-gerenciar`, {
        params: {
          ...model,
        },
      });
      console.log(response);
      if (response.status === 200) {
        setCadastroRealizado(true);
        setDataResults(response.data);
        if (response.data.length === 0) {
          addToast({
            title: 'Erro',
            description: 'Não existe resultado para esse filtro!',
            type: 'error',
          });
          event.stopPropagation();
          return;
        } else {
          history.push({
            pathname: '/admin/listar/condutores',
            state: { listagem: response.data, model: model },
          });
          return;
        }
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Condutor</h2>
          <h6 className="subtitulo">{'Cadastro > Gerenciamento de Clientes > Condutor'}</h6>
        </div>

        {/* {cadastroRealizado ? (
          <ListagemConsultaCondutor
            dataResults={dataResults}
            setDataResults={setDataResults}
            model={model}
          />
        ) : ( */}
        <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
          <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
            <Form.Label>Cliente</Form.Label>

            <Dropdown
              style={{ width: '100.4%' }}
              value={empresa}
              options={listaClientes}
              placeholder="Selecione"
              onChange={(e: any) => {
                setModel({
                  ...model,
                  idCliente: e.target.value,
                });

                setEmpresa(e.target.value);
              }}
              optionLabel="razaoSocial"
              optionValue="id"
              showClear
              filter
              className="multiselect-custom"
              id="empresa"
            />
          </Form.Group>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                onChange={(e) => {
                  if (e.target.value === '') {
                    setModel({
                      ...model,
                      nomeCondutor: null,
                    });
                  } else {
                    setModel({
                      ...model,
                      nomeCondutor: e.target.value,
                    });
                  }
                }}
                value={model?.nomeCondutor ? model?.nomeCondutor : ''}
                type="text"
                id="nome"
                name="nome"
                placeholder=""
                style={{ width: '100%' }}
              />
            </Form.Group>
          </Form.Row>

          <Form.Group>
            <Form.Label>CPF</Form.Label>
            <Form.Control
              value={cpf}
              isInvalid={!isCpfValid && cpf.length > 0}
              id="cpf"
              name="cpf"
              placeholder=""
              onChange={(event: any) => {
                if (event.target.value === '') {
                  setModel({
                    ...model,
                    cpfCondutor: null,
                  });
                  setCpf(cpfMask(event.target.value));
                } else {
                  if (checaCPF(event.target.value) === false) {
                    setCpf(cpfMask(event.target.value));
                    setIsCpfValid(false);
                  } else {
                    setCpf(cpfMask(event.target.value));
                    setIsCpfValid(true);
                    setModel({
                      ...model,
                      cpfCondutor: event.target.value,
                    });
                  }
                }
              }}
            />
            {!isCpfValid && cpf.length > 0 && (
              <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>Cpf inválido.</p>
            )}
          </Form.Group>

          <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
            <Form.Label>Tipo</Form.Label>

            <Dropdown
              style={{ width: '100.4%' }}
              value={tipo}
              options={listaTipos}
              placeholder="Selecione"
              onChange={(e: any) => {
                setModel({
                  ...model,
                  tipo: e.target.value,
                });
                setTipo(e.target.value);
              }}
              optionLabel="nome"
              optionValue="value"
              showClear
              className="multiselect-custom"
              id="tipo"
            />
          </Form.Group>

          <ButtonsForm />
        </Form>
      </div>
    </div>
  );
};

export default ConsultarCondutor;
