/* eslint-disable */
import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './styles.css';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  onPress: () => void;
  setHandleAllItens: (bool: boolean) => void;
  loading: boolean
};

const ModalDesassocia: FC<Props> = ({ show, setShow, onPress, setHandleAllItens,loading }) => {
  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
        setHandleAllItens(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="associar-modal-text">
          <span>Você tem certeza que deseja fazer esta desassociação?</span>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          onClick={() => {
            setShow(false)
            onPress();
            setHandleAllItens(false);
          }}
        >
          {loading ? 'Dessasociando...' : 'Sim'}
        </Button>
        <Button
          style={{ color: '#000', fontWeight: 'bold' }}
          variant="primary"
          onClick={() => {
            setShow(false);
            setHandleAllItens(false);
          }}
        >
          Não
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDesassocia;
