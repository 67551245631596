import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { Tela } from '../../../components/Tela';
import { Spinner } from '../../../components/Spinner';
import { Tabela } from '../../../components/Tabela';
import { pesquisar } from '../../../util/format';
import { FormatTimezoneToGrid } from '../../../util/Validacoes/Validacoes';
import { obterData } from '../../../util/date';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

type SazonalidadeCargaType = {
  szcCdId: number;
  szcTxNome: string;
  dataInicio: Date;
  dataFim: Date;
  idCliente: number;
};

const SazonalidadeCargaList: React.FC = () => {
  const { EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();
  const { page }: any = useParams();
  const idCliente = getCookieSessionData().usuarioVO.clienteId;
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [data, setData] = useState<SazonalidadeCargaType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const titles = ['+id', 'Nome', 'Data início', 'Data Fim'];
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/listar-sazonalidade-da-carga/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ EMBARCADOR: [EMBARCADOR.CADASTRO.SAZONALIDADE_DE_CARGA] }], 'inserir');
  }, [EMBARCADOR, rolePermission]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/sazonalidade-carga/find-all`, {
        params: {
          idCliente,
        },
      });
      const array = response.data.map((each: SazonalidadeCargaType) => {
        return [each.szcCdId, each.szcTxNome, obterData(each.dataInicio), obterData(each.dataFim)];
      });
      setData(array);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar-sazonalidade-da-carga/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Tela
      nome="Sazonalidade da Carga"
      caminho="Cadastro > Sazonalidade da Carga"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/cadastrar-sazonalidade-da-carga/"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/editar-sazonalidade-da-carga/${id}`)}
        />
      )}
    </Tela>
  );
};

export default SazonalidadeCargaList;
