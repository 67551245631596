/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Form } from 'react-bootstrap';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData } from '../../services/cookieService';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import checaCPF from '../../util/checaCpf';
import { cpfMask, soNumeros } from '../../util/Validacoes/Validacoes';
import '../ConsultarPesquisasLancadas/styles.css';
import { VeiculoTypeGrid } from '../../Types/Veiculos/VeiculoTypeGrid';
import { Tela } from '../../components/Tela';

type ListagemConsulta = {
  atualizacao: boolean;
  contrato: string;
  empresa: string;
  idCarreta: number;
  placaCarreta: string;
  tipoFrota: string;
};

export type FiltroCarreta = {
  idCliente: number;
  tipoFrota: string | null;
  pagina: number;
  placaCarreta: string | null;
  qtdRegistros: number;
};

const ConsultarCarreta = () => {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const listaTipos = [
    { value: 'Veiculo Agregado', nome: 'Agregado' },
    { value: 'Veiculo Proprio', nome: 'Próprio' },
    { value: 'Veiculo Terceiros', nome: 'Terceiro' },
  ];
  const [empresa, setEmpresa] = useState('');
  const [tecnologia, setTecnologia] = useState('');
  const [usuario, setUsuario] = useState('');
  const [tipo, setTipo] = useState('');
  const [model, setModel] = useState<FiltroCarreta>({} as FiltroCarreta);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [dataResults, setDataResults] = useState<ListagemConsulta[]>();
  const [cadastroRealizado, setCadastroRealizado] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isPlacaRegistered, setIsPlacaRegistered] = useState(true);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaClientes(listaAtivos);
      });
    };
    loadData();
  }, []);


  const buscar = async (event: any) => {
    event.preventDefault();
    if (model.placaCarreta) {
      if (isPlacaRegistered === false) {
        addToast({
          title: 'Erro',
          description: 'Placa não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    try {
      const response = await api.get(`/veiculo/consulta-gerenciar-carreta`, {
        params: {
          ...model,
        },
      });
      if (response.status === 200) {
        setCadastroRealizado(true);
        setDataResults(response.data);
        if (response.data.length === 0) {
          addToast({
            title: 'Erro',
            description: 'Não existe resultado para esse filtro!',
            type: 'error',
          });
          event.stopPropagation();
          return;
        } else {
          history.push({
            pathname: '/admin/listar/carretas',
            state: { listagem: response.data, model: model },
          });
          return;
        }
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };

  const verifyIfPlacaIsRegistered = async (value: string) => {
    console.log(value);
    if (model.idCliente) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-cliente-carreta?idCliente=${model.idCliente}&placaCarreta=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegistered(true);
        } else {
          setIsPlacaRegistered(false);
        }
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    } else {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-cliente-carreta?placaCarreta=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegistered(true);
        } else {
          setIsPlacaRegistered(false);
        }
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  return (
    <Tela nome="Carreta" caminho="Cadastro > Gerenciamento de Clientes > Carreta">
      <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
        <Form.Group className="row-modal">
          <Form.Label>Cliente</Form.Label>

          <Dropdown
            style={{ width: '100%' }}
            value={empresa}
            options={listaClientes}
            placeholder="Selecione"
            onChange={(e: any) => {
              setModel({
                ...model,
                idCliente: e.target.value,
              });

              setEmpresa(e.target.value);
            }}
            optionLabel="razaoSocial"
            optionValue="id"
            showClear
            filter
            className="multiselect-custom"
            id="empresa"
          />
        </Form.Group>

        {/* Validação se a placa existe no sistema, não é dinamico, é no post  */}

        <Form.Group className="row-modal">
          <Form.Label>Placa</Form.Label>

          <Form.Control
            onChange={(e) => {
              if (e.target.value === '') {
                setModel({
                  ...model,
                  placaCarreta: null,
                });
              } else {
                setModel({
                  ...model,
                  placaCarreta: e.target.value,
                });
                // verifyIfPlacaIsRegistered(e.target.value);
              }
            }}
            onBlur={() => {
              if (!model.placaCarreta) {
                return;
              }
              verifyIfPlacaIsRegistered(model.placaCarreta.toUpperCase());
            }}
            value={model?.placaCarreta ? model?.placaCarreta : ''}
            type="text"
            id="placaVeiculo"
            name="placaVeiculo"
            placeholder=""
          />
        </Form.Group>

        <Form.Group className="row-modal">
          <Form.Label>Tipo</Form.Label>

          <Dropdown
            style={{ width: '100%' }}
            value={tipo}
            options={listaTipos}
            placeholder="Selecione"
            onChange={(e: any) => {
              setModel({
                ...model,
                tipoFrota: e.target.value,
              });
              setTipo(e.target.value);
            }}
            optionLabel="nome"
            optionValue="value"
            showClear
            className="multiselect-custom"
            id="tipo"
          />
        </Form.Group>

        <ButtonsForm />
      </Form>
    </Tela>
  );
};

export default ConsultarCarreta;
