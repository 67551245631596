import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SvgComponent from '../../components/Animation';
import { getCookieSessionData } from '../../services/cookieService';
import { getCookiePermissoesData, permissionamento } from '../../services/permissionamento';
import './styles.css';

export const CarregandoPermissoes = () => {
  const { usuarioId, role } = getCookieSessionData().usuarioVO;
  const history = useHistory();
  const permissions = getCookiePermissoesData();
  const [time, setTime] = useState(false);

  const savePermissoes = async () => {
    await permissionamento(usuarioId);
    setTimeout(function () {
      setTime(true);
    }, 1100);
  };

  useEffect(() => {
    if (permissions && time) {
      history.push(role === 'admin' ? '/home' : '/notificacao');
    }
  }, [permissions, time, role]);

  useEffect(() => {
    savePermissoes();
  }, [usuarioId]);

  return (
    <div className="containerPermissoes">
      <SvgComponent />
    </div>
  );
};
