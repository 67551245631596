/* eslint-disable */
import axios from '../../../config/axiosMaquina';
import React, { useState, ChangeEvent, FC } from 'react';
import { Form, Col, Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { BinResponse } from '../../../api/model/BauIntercambiavelType';
import { semCaracterEspecial, soNumeros } from '../../../util/Validacoes/Validacoes';
import { Dialog } from 'primereact/dialog';
import './styles.css';
import { getCookieSessionData } from '../../../services/cookieService';
import { useToast } from '../../../hooks/Toast';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  loadBau: () => void;
};

const ModalCadastroBauPorSM: FC<Props> = ({ show, setShow, loadBau }) => {
  const { addToast } = useToast();

  const history = useHistory();
  const [isCubagem, setIsCubagem] = useState(true);

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);

  const clienteId = getCookieSessionData().usuarioVO.clienteId;

  const [model, setModel] = useState<BinResponse>({
    id: 0,
    numeroBau: '',
    modelo: '',
    statusAtivo: true,
    capacidadeCarga: 0,
    cubagem: 0,
    qtdeViagens: 0,
    valorMedioTransportado: 0,
    clienteId: clienteId,
    tipoBau: '',
  });

  async function onSubmit(e: any) {
    e.preventDefault();
    if (!model.numeroBau || !model.modelo || !model.tipoBau) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);

      e.stopPropagation();
      return;
    }
    try {
      const response = await axios.post('/bau-intercambiavel/', model);
      setIsDialogVisibleCadEfetuado(true);
      setTimeout(() => {
        setShow(false);
        limparFormulario();
        loadBau();
        setIsDialogVisibleCadEfetuado(false);
        setIsFormInvalid(false);
      }, 1000);
    } catch (err: any) {
      console.log(err);
    }
  }

  const limparFormulario = () => {
    setModel({
      ...model,
      numeroBau: '',
      modelo: '',
      statusAtivo: true,
      capacidadeCarga: 0,
      cubagem: 0,
      qtdeViagens: 0,
      valorMedioTransportado: 0,
      clienteId: clienteId,
      tipoBau: '',
    });
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Cadastrar Baú Intercambiável</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'row' }}>
          <Dialog
            header="Baú foi cadastrado com sucesso!"
            footer={
              <>
                <Button onClick={() => setIsDialogVisibleCadEfetuado(false)}>Ok</Button>
              </>
            }
            visible={isDialogVisibleCadEfetuado}
            style={{ width: '50vw' }}
            modal
            onHide={() => setIsDialogVisibleCadEfetuado(false)}
          />

          <div className="painel">
            <Form
              noValidate
              validated={isFormInvalid}
              className="form-responsive"
              onSubmit={onSubmit}
            >
              <Form.Row>
                <Form.Group as={Col} controlId="formGridbinTxNumeroBau">
                  <Form.Label className="required">NÚMERO BAÚ</Form.Label>
                  <Form.Control
                    value={model.numeroBau}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: semCaracterEspecial(e.target.value),
                      });
                    }}
                    name="numeroBau"
                    maxLength={64}
                    type="text"
                    required
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridbinTxTipoFrota">
                  <Form.Label className="require">TIPO BAÚ</Form.Label>
                  <Form.Control
                    value={model.tipoBau}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: e.target.value,
                      });
                    }}
                    name="tipoBau"
                    as="select"
                    placeholder=""
                    required
                  >
                    <option value="">Selecione uma opção</option>
                    <option value="1">PRÓPRIO</option>
                    <option value="2">AGREGADO</option>
                    <option value="3">TERCEIRO</option>
                  </Form.Control>
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} controlId="formGridbinTxModelo">
                  <Form.Label className="required">MODELO</Form.Label>
                  <Form.Control
                    value={model.modelo}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: semCaracterEspecial(e.target.value),
                      });
                    }}
                    name="modelo"
                    maxLength={60}
                    type="text"
                    required
                    // pattern="/[^a-zA-Z\s]/g"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridbinNuCapacidadeCarga">
                  <Form.Label>CAPACIDADE DE CARGA</Form.Label>
                  <Form.Control
                    value={model.capacidadeCarga === 0 ? '' : model.capacidadeCarga}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: soNumeros(e.target.value),
                      });
                    }}
                    name="capacidadeCarga"
                    maxLength={10}
                    type="text"
                    title="Este campo aceita apenas numeros"
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridbinNuCubagem">
                  <Form.Label>CUBAGEM</Form.Label>
                  <Form.Control
                    value={model.cubagem === 0 ? '' : model.cubagem}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: soNumeros(e.target.value),
                      });
                      if (soNumeros(e.target.value)) {
                        setIsCubagem(true);
                      } else {
                        setIsCubagem(false);
                      }
                    }}
                    name="cubagem"
                    type="text"
                    maxLength={10}
                    title="Este campo aceita apenas numeros"
                  />
                  {!isCubagem && (
                    <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                      Apenas Números
                    </p>
                  )}
                </Form.Group>
              </Form.Row>
            </Form>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button style={{ color: '#000', fontWeight: 'bold' }} variant="primary" onClick={onSubmit}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCadastroBauPorSM;
