/* eslint-disable */

import '../GridEditavelMarca/styles.css';
import { ImCancelCircle } from 'react-icons/im';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { useToast } from '../../hooks/Toast';
import { MarcaType } from '../../Types/MarcaCorTypes';
import api from '../../config/axiosMaquina';
import { AiOutlineCheck } from 'react-icons/ai';
import { IoPencilSharp, IoMailOpenSharp } from 'react-icons/io5';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { useHistory } from 'react-router-dom';

type LineProps = {
  marca: MarcaType;
  setOpenModal: (openModal: boolean) => void;
  setCloseModal: (closeModal: boolean) => void;
  setIdDelete: (idDelete: number) => void;
  setIdEdit: (idEdit: number) => void;
  idAtual: number;
  canDelete?: boolean;
  canEdit?: boolean;
};

export const MarcaRow = ({
  marca,
  setOpenModal,
  setCloseModal,
  setIdDelete,
  setIdEdit,
  idAtual,
  canDelete,
  canEdit,
}: LineProps) => {
  const [disabled, setDisabled] = useState(true);
  const [model, setModel] = useState<MarcaType>(marca);
  const { addToast } = useToast();
  const [nomeOriginal, setNomeOriginal] = useState(marca.vmaTxNome);
  const history = useHistory();
  const handleEdit = (id: number) => {
    setDisabled(false);
    setIdEdit(id);
  };

  const handleCancel = () => {
    setDisabled(true);
    setIdEdit(0);
  };

  useEffect(() => {
    setModel(marca);
  }, [marca]);

  async function handleSubmit() {
    try {
      await api.put(`/marca-veiculo?idVm=${model.vmaCdId}`, model);
      addToast({
        title: 'Sucesso!',
        description: 'Marca atualizada com sucesso!',
        type: 'success',
      });
      setDisabled(true);
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } catch (err: any) {
      console.log(err);
      addToast({
        title: 'Erro',
        description: 'Erro ao editar registro',
        type: 'error',
      });
    }
  }

  return (
    <tr>
      <td>
        <input
          style={
            idAtual !== model.vmaCdId ? { background: 'transparent', border: 'none' } : undefined
          }
          disabled={idAtual != model.vmaCdId}
          value={model.vmaTxNome}
          maxLength={64}
          onChange={(e: any) => {
            setModel({
              ...model,
              vmaTxNome: e.target.value,
            });
          }}
          type="text"
        />
      </td>
      <td>
        <input
          style={
            idAtual !== model.vmaCdId ? { background: 'transparent', border: 'none' } : undefined
          }
          placeholder={
            idAtual !== model.vmaCdId
              ? model.vmaCodIntegracao
                ? 'Cód.Integração'
                : '-/-'
              : 'Cód.Integração'
          }
          disabled={idAtual != model.vmaCdId}
          value={model.vmaCodIntegracao ?? ''}
          maxLength={64}
          onChange={(e: any) => {
            const value = e.target.value;
            setModel({
              ...model,
              vmaCodIntegracao: value === '' ? null : parseInt(value, 10),
            });
          }}
          type="text"
        />
      </td>

      <td>
        {!disabled && idAtual === model.vmaCdId && idAtual != 0 ? (
          <ButtonTooltip onClick={handleCancel} type="Cancelar" />
        ) : (
          <ButtonTooltip
            onClick={() => {
              if (canEdit) {
                handleEdit(model.vmaCdId);
              } else {
                history.push('/acessonegado');
              }
            }}
            type="Editar"
          />
        )}
        {!disabled && idAtual === model.vmaCdId && idAtual != 0 ? (
          <ButtonTooltip onClick={handleSubmit} type="Enviar" />
        ) : (
          <ButtonTooltip
            onClick={() => {
              if (canDelete) {
                setOpenModal(true);
                if (model.vmaCdId != undefined) {
                  setIdDelete(model.vmaCdId);
                }
              } else {
                history.push('/acessonegado');
              }
            }}
            type="Excluir"
          />
        )}
      </td>
    </tr>
  );
};
