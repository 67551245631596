/* eslint-disable */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

type GrupoMacroVeiculosType = {
  vgmCdId: number;
  vgmTxNome: string;
  vgmBlAtivo?: boolean;
};

type GrupoVeiculoType = {
  vtgCdId: number;
  vgmCdId: {
    vgmCdId: number;
    vgmTxNome?: string;
  };
  vtgTxNome: string;
  vtgBlAtivo: boolean;
  tipoVeiculo?: any;
};

const GrupoVeiculoForm: React.FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [tipoVeiculoId, setTipoVeiculoId] = useState<any>([]);
  const [model, setModel] = useState<GrupoVeiculoType>({
    vtgCdId: 0,
    vtgTxNome: '',
    vtgBlAtivo: true,
    vgmCdId: {
      vgmCdId: 0,
      vgmTxNome: '',
    },
    tipoVeiculo: tipoVeiculoId.map((d: any) => d.code),
  });
  const [tipoVeiculoList, setTiposVeiculoList] = useState<any[]>([]);
  const [grupoMacro, setGrupoMacro] = useState<GrupoMacroVeiculosType[]>([]);
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { vtgTxNome, vgmCdId, tipoVeiculo } = model;

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.GRUPO_VEICULO]}], 'editar');
    rolePermission([{ADMIN: [ADMIN.CADASTRO.GRUPO_VEICULO]}], 'excluir');
  }, [ADMIN, rolePermission]);

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const response = await axios.get(`/grupo-tipo-veiculo/veiculotipogrupo/${id}`);
        setModel(response.data);
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
    if (!grupoMacro.length && !tipoVeiculoList.length) {
      setLoading(true);
      try {
        const _grupoMacro = await axios.get('/grupo-macro-veiculo');
        setGrupoMacro(_grupoMacro.data.filter((d: any) => d.vgmBlAtivo));
        const _tipoVeiculo = await axios.get('/tipo-veiculo/grid-tipo-veiculo');
        setTiposVeiculoList(_tipoVeiculo.data);
        if (id) {
          const arr: any[] = [];
          const filterTipoVeiculo = _tipoVeiculo.data
            .filter((tv: any) => tv.vtgCdId === parseInt(id, 10))
            .map((d: any) => arr.push({ name: d.vtpTxNome, code: d.vtpCdId }));
          setTipoVeiculoId(arr);
        }
      } catch (err: any) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!vgmCdId.vgmCdId || !vtgTxNome) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const array: any[] = [];
    tipoVeiculoId.map((t: any) => array.push(t.code));
    if (id) {
      setLoading(true);
      try {
        await axios.put(`/grupo-tipo-veiculo/atualizar?id=${id}`, {
          nome: vtgTxNome,
          veiculoGrupoMacro: vgmCdId.vgmCdId,
          tipoVeiculo: array.length ? array : null,
        });
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      setLoading(true);
      try {
        await axios.post('/grupo-tipo-veiculo/veiculotipogrupo/', {
          nome: vtgTxNome,
          veiculoGrupoMacro: vgmCdId.vgmCdId,
          tipoVeiculo: array,
        });
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleDelete = async (id: number) => {
    try {
      await axios.put(`/grupo-tipo-veiculo/delete-logico?id=${id}`);
      setExclusao((prev) => ({ ...prev, ok: true }));
      history.push('/listar/grupos-de-veiculos/1');
    } catch (err: any) {
      let errorMessage = 'Ocorreu um erro';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setExclusao((prev) => ({ ...prev, erro: true, mensagem: errorMessage }));
    }
  };

  const selectTipos = tipoVeiculoList.map((tipo) => ({ name: tipo.vtpTxNome, code: tipo.vtpCdId }));

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={`Grupo de veículo ${id ? 'alterado' : 'cadastrado'} com sucesso!`}
        footer={
          <Button style={{ color: 'black', width: 150 }} onClick={() => setIsDialogCadOK(false)}>
            Ok
          </Button>
        }
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/grupos-de-veiculos/1');
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Grupo de veículo excluído com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>
      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Grupo de veículos</h2>
          <h6 className="subtitulo">{`Cadastro > Grupo de veículos > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="painel">
              <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
              <Form
                className="form-sm"
                noValidate
                validated={isFormInvalid}
                onSubmit={handleSubmit}
              >
                <Form.Row>
                  <Form.Group as={Col} sm={12} md={5} lg={6}>
                    <Form.Label className="requiredField">Nome</Form.Label>
                    <Form.Control
                      value={vtgTxNome}
                      onChange={(e) => {
                        setModel({ ...model, vtgTxNome: e.target.value });
                      }}
                      type="text"
                      required
                      maxLength={32}
                      minLength={1}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={5} lg={6} className="requiredField">
                    <Form.Label>Grupo macro</Form.Label>
                    <Form.Control
                      value={vgmCdId.vgmCdId}
                      onChange={(e) => {
                        setModel({ ...model, vgmCdId: { vgmCdId: +e.target.value } });
                      }}
                      as="select"
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      {grupoMacro.map((t) => (
                        <option value={t.vgmCdId} key={t.vgmCdId}>
                          {t.vgmTxNome}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    as={Col}
                    sm={12}
                    lg={6}
                    style={{ display: 'flex', flexDirection: 'column' }}
                  >
                    <Form.Label>Tipo de Veículo</Form.Label>
                    <MultiSelect
                      style={{
                        maxWidth: '36em',
                        width: '100%',
                        lineHeight: 1,
                        borderColor: tipoVeiculoId.length <= 0 && isFormInvalid ? '#dc3545' : '',
                      }}
                      emptyFilterMessage={<Button />}
                      display="chip"
                      optionLabel="name"
                      value={tipoVeiculoId}
                      options={selectTipos}
                      onChange={(e: any) => setTipoVeiculoId(e.target.value)}
                      filter
                    />
                  </Form.Group>
                </Form.Row>
                <ButtonsForm canEdit={canEdit} />
              </Form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default GrupoVeiculoForm;
