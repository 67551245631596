import styled from 'styled-components';

export const SituacaoVigenciaContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;

  div:nth-child(2) {
    margin-left: 50px;
  }

  span {
    span {
      font-weight: bold;
      margin-left: 10px;
    }
  }
`;
