export type ValidacaoCnhType = {
  id: number;
  ativo: boolean;
  dataInicio: string;
  dataFim: string;
  dataRenovacao: string;
  idUf: number;
};

export type ValidacaoCnhListType = {
  id: number;
  ativo: boolean;
  idUf: number;
  uf: string;
  situacoes: {
    dataInicio: string;
    dataFim: string;
    dataRenovacao: string;
  }[];
};

export const defaultModelValidacao = {
  ativo: true,
  id: 0,
  dataInicio: '',
  dataFim: '',
  dataRenovacao: '',
  idUf: 0,
};
