import { differenceInHours, parseISO } from 'date-fns';
import axios from '../config/axiosMaquina';

interface IUltimaRespostaDminer {
  codLiberacaoUltimaPesquisaCadastrada: number;
  codLiberacaoUltimaRespostaDminer: number;
  dataUltimaResposta: string;
}

export const getUltimaRespostaDminer = async (): Promise<boolean> => {
  try {
    const response = await axios.get<IUltimaRespostaDminer>('pesquisa/dminer-ultima-resposta');

    if (response.status === 200) {
      const {
        codLiberacaoUltimaPesquisaCadastrada,
        codLiberacaoUltimaRespostaDminer,
        dataUltimaResposta,
      } = response.data;

      const ultimaRespostaDate = parseISO(dataUltimaResposta);
      const now = new Date();

      const isMoreThanOneHour = differenceInHours(now, ultimaRespostaDate) > 1;

      const isDifferentCode =
        codLiberacaoUltimaRespostaDminer !== codLiberacaoUltimaPesquisaCadastrada;

      console.log('ultimaRespostaDate', ultimaRespostaDate);
      console.log('now', now);
      console.log('isMoreThanOneHour', isMoreThanOneHour);
      console.log('isDifferentCode', isDifferentCode);

      return isMoreThanOneHour && isDifferentCode;
    }

    throw new Error(`Erro ao acessar API: ${response.status} - ${response.statusText}`);
  } catch (error: any) {
    console.error(`Erro ao acessar API: ${error.message}`);
    return false;
  }
};
