/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { CardInformativo } from '../../components/CardInformativo';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import './styles.css';
import instanceMaquina from '../../config/axiosMaquina';
import { GridExpansivel } from './gridExpansivel';
import { getCookieSessionData } from '../../services/cookieService';
import { Form, Modal } from 'react-bootstrap';
import Pagination from '../../components/Pagination/Pagination';
import { PainelLSCType, ValuesCardsType } from '../../Types/PainelLSCTypes';
import { Slider } from 'primereact/slider';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner } from '../../components/Spinner';
import Search from '../../components/Search/Search';
import { FaClipboardList, FaTemperatureHigh } from 'react-icons/fa';
import { RiAlertFill, RiTruckFill } from 'react-icons/ri';
import { IoMdSpeedometer } from 'react-icons/io';
import { MdOutlineArrowDropUp } from 'react-icons/md';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { ActiveSM } from '../../types';
import { handleListActiveSM } from '../../services/transitTime';
import { matchSmId } from '../../util/transitTime';
import axiosRequest from 'axios';
import { format, parse, subHours } from 'date-fns';
import { AiFillClockCircle } from 'react-icons/ai';
import { Button as Button2 } from 'primereact/button';
import api from '../../config/axiosMaquina';
import ModalLSC from './modalTratativasVelocidade';
type RangeType = [number, number];

type CanShowViolacaoType = {
  idCliente: any;
  velocidade: boolean;
  temperatura: boolean;
};

export function LSC() {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();

  const [searchTerm, setSearchTerm] = useState('');
  const [rangeDistancia, setRangeDistancia] = useState<any>({ max: 500, min: 0 });
  const [valueRange, setValueRange] = useState<RangeType>([0, 500]);
  const [filtro, setFiltro] = useState('Total de Viagens');
  const [filtroBusca, setFiltroBusca] = useState('Todos');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [valuesCards, setValuesCards] = useState<ValuesCardsType[]>([]);
  const [filtrado, setFiltrado] = useState<any>();
  const [objetos, setObjetos] = useState<any>([]);
  const usuarioLogado = getCookieSessionData().usuarioVO.usuarioId;
  const { page }: any = useParams();
  const [dialogTratativaErro, setDialogTratativaErro] = useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);
  const history = useHistory();
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = filtrado?.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: any) => history.push(`/painel-lsc/${pageNumber}`);
  const idUsuario = getCookieSessionData().usuarioVO.usuarioId;
  const [sliderDisabled, setSliderDisabled] = useState(false);
  const [dialogConcluirTratativas, setDialogConcluirTratativas] = useState(false);
  const [dialogTratativaConcluida, setDialogTratativaConcluida] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [canShowViolacao, setCanShowViolacao] = useState<CanShowViolacaoType[]>([]);
  const [smActive, setSMActive] = useState<ActiveSM[]>([]);
  const [vehicleLatestPosition, setVehicleLatestPosition] = useState<PainelLSCType[]>([]);

  const getData = async (data: PainelLSCType[]) => {
    const promises = data.map((obj: PainelLSCType) => {
      return obj.idcliente;
    });
    const ids = uniq(promises);

    const arr: CanShowViolacaoType[] = [];
    ids.forEach(async (eachId: number) => {
      const object = {
        idCliente: eachId,
        velocidade: false,
        temperatura: false,
      };
      try {
        const velocidadeResponse = await instanceMaquina.get(
          `/violacao-velocidade/idCliente?idCliente=${eachId}`
        );
        if (velocidadeResponse.data.moduloTipo === 'AVANCADO') {
          object.velocidade = true;
        }
        const temperaturaResponse = await instanceMaquina.get(
          `/violacao-temperatura/idCliente?idCliente=${eachId}`
        );
        if (temperaturaResponse.data.moduloTipo === 'AVANCADO') {
          object.temperatura = true;
        }
        arr.push(object);
        const _uniq = uniqBy(arr.concat(canShowViolacao), 'idCliente');
        setCanShowViolacao(_uniq);
      } catch (error) {
        console.log(error);
      }
    });
  };

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.OPERACIONAL.PAINEL_LSC] }], 'editar');
  }, [ADMIN, rolePermission]);

  const getViolacaoCards = async () => {
    let velocidadeLength = 0;
    let temperaturaLength = 0;
    objetos.forEach((obj: PainelLSCType) => {
      velocidadeLength += canShowViolacao.some((obj2: any) => {
        return obj2.idCliente === obj.idcliente && obj.violouvelocidade;
      })
        ? 1
        : 0;
      temperaturaLength += canShowViolacao.some((obj2: any) => {
        return obj2.idCliente === obj.idcliente && obj.violoutemperatura;
      })
        ? 1
        : 0;
    });

    setValuesCards([
      ...valuesCards,
      {
        nome: 'Violação de Temperatura',
        value: temperaturaLength,
        icon: <FaTemperatureHigh color="#F57C00" size="35px" />,
        color: '#F57C00',
        show: canShowViolacao.some((obj: any) => {
          return obj.temperatura;
        }),
      },
    ]);
  };

  useEffect(() => {
    if (canShowViolacao.length) {
      getViolacaoCards();
    }
  }, [canShowViolacao]);

  const [visible, setVisible] = useState(false);

  const expandir = () => {
    setVisible(!visible);
  };

  const fetchData = async () => {
    try {
      setShowModal(false);
      setFiltrado(null);

      const response: any = await axiosRequest.get(
        `${process.env.REACT_APP_API_URL}/list-painel-lsc-cards/`
      );

      try {
        setObjetos(response.data[0]);

        setValuesCards([
          {
            nome: 'Total de Viagens',
            value: response.data[0].total_sm,
            icon: <FaClipboardList color="#3865A3" size="35px" />,
            color: '#3865A3',
            show: true,
          },
          {
            nome: 'Em Atraso',
            value: response.data[0].delay,
            icon: <RiAlertFill color="#E03B24" size="40px" />,
            color: '#E03B24',
            show: true,
          },
          {
            nome: 'Parcialmente Atrasada',
            value: response.data[0].partial_delay,
            icon: <RiAlertFill color="#F57C00" size="40px" />,
            color: '#F57C00',
            show: true,
          },
          {
            nome: 'Em Tratamento',
            value: response.data[0].treatment,
            icon: <RiTruckFill color="#64A338" size="40px" />,
            color: '#64A338',
            show: true,
          },
          {
            nome: 'Pendente',
            value: response.data[0].pending,
            icon: <RiAlertFill color="#FFCC00" size="40px" />,
            color: '#FFCC00',
            show: true,
          },
          {
            nome: 'Excesso de Velocidade',
            value: response.data[0].count_violation_speed,
            icon: <IoMdSpeedometer color="#E03B24" size="40px" />,
            color: '#E03B24',
            show: true,
          },
          {
            nome: 'Violação de Temperatura',
            value: response.data[0].count_violation_temperature,
            icon: <FaTemperatureHigh color="#F57C00" size="35px" />,
            color: '#F57C00',
            show: true,
          },
          {
            nome: 'Tempo Máximo de Trânsito Excedido',
            value: 12,
            color: Math.sign(12) === 1 ? '#E03B24' : '#64A338',
            icon: <MdOutlineArrowDropUp color="#64A338" size="40px" />,
            show: false,
          },
          {
            nome: 'Tempo mínimo de Parada Não Realizada',
            value: 3,
            color: Math.sign(3) === 1 ? '#E03B24' : '#64A338',
            icon: <MdOutlineArrowDropUp color="#64A338" size="40px" />,
            show: false,
          },
        ]);
      } catch (err: any) {
        console.log(err);
        setSliderDisabled(true);
      }
    } catch (error: any) {
      console.log(error);
      setSliderDisabled(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const concluirTratativas = async (each: any) => {
    try {
      await api.put(
        `/solicitacao-monitoramento/concluir-tratamento?idLsc=${each.idlsc}&idUsuarioConclusao=${idUsuario}`
      );
      setDialogTratativaConcluida(true);
    } catch (error) {
      setDialogTratativaErro(true);
    }
  };

  const handleListLateSM = async (smList: PainelLSCType[], statusLate: string) => {
    const smListLate = smList.filter((vehicle) => vehicle.statusSm == statusLate);
    return smListLate;
  };

  useEffect(() => {
    const handleFiltro = async () => {
      if (objetos) {
        paginate(1);
        if (filtro === 'Total de Viagens') {
          const all = objetos.sm;

          const filters: any = {
            SM: ({ idsm }: any) => idsm === Number(searchTerm),
            Cliente: ({ razaosocialcli }: any) =>
              razaosocialcli.toLowerCase().includes(searchTerm.toLowerCase()),
            Embarcador: ({ nomeembarcador }: any) =>
              nomeembarcador.toLowerCase().includes(searchTerm.toLowerCase()),
            Placa: ({ placas }: any) => placas.toLowerCase().includes(searchTerm.toLowerCase()),
            'Próximo ponto': ({ proxponto }: any) =>
              proxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Previsão: ({ previsaoproxponto }: any) =>
              previsaoproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            'Tempo de chegada': ({ tempochegadaproxponto }: any) =>
              tempochegadaproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Motivo: ({ motivoatraso }: any) =>
              motivoatraso.toLowerCase().includes(searchTerm.toLowerCase()),
          };

          if (filtroBusca in filters && searchTerm.trim() !== '') {
            setFiltrado(all.filter(filters[filtroBusca]));
          } else {
            setFiltrado(all);
          }
        } else if (filtro === 'Pendente') {
          const pendent = objetos.sm.filter(
            ({ statustratamentosm }: any) => statustratamentosm === 'Pendente'
          );

          const filters: any = {
            SM: ({ idsm }: any) => idsm === Number(searchTerm),
            Cliente: ({ razaosocialcli }: any) =>
              razaosocialcli.toLowerCase().includes(searchTerm.toLowerCase()),
            Embarcador: ({ nomeembarcador }: any) =>
              nomeembarcador.toLowerCase().includes(searchTerm.toLowerCase()),
            Placa: ({ placas }: any) => placas.toLowerCase().includes(searchTerm.toLowerCase()),
            'Próximo ponto': ({ proxponto }: any) =>
              proxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Previsão: ({ previsaoproxponto }: any) =>
              previsaoproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            'Tempo de chegada': ({ tempochegadaproxponto }: any) =>
              tempochegadaproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Motivo: ({ motivoatraso }: any) =>
              motivoatraso.toLowerCase().includes(searchTerm.toLowerCase()),
          };

          if (filtroBusca in filters && searchTerm.trim() !== '') {
            setFiltrado(pendent.filter(filters[filtroBusca]));
          } else {
            setFiltrado(pendent);
          }
        } else if (filtro === 'Em Tratamento') {
          const treatment = objetos.sm.filter(
            ({ statustratamentosm }: { statustratamentosm: string }) =>
              statustratamentosm === 'Em tratamento'
          );

          const filters: any = {
            SM: ({ idsm }: any) => idsm === Number(searchTerm),
            Cliente: ({ razaosocialcli }: any) =>
              razaosocialcli.toLowerCase().includes(searchTerm.toLowerCase()),
            Embarcador: ({ nomeembarcador }: any) =>
              nomeembarcador.toLowerCase().includes(searchTerm.toLowerCase()),
            Placa: ({ placas }: any) => placas.toLowerCase().includes(searchTerm.toLowerCase()),
            'Próximo ponto': ({ proxponto }: any) =>
              proxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Previsão: ({ previsaoproxponto }: any) =>
              previsaoproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            'Tempo de chegada': ({ tempochegadaproxponto }: any) =>
              tempochegadaproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Motivo: ({ motivoatraso }: any) =>
              motivoatraso.toLowerCase().includes(searchTerm.toLowerCase()),
          };

          if (filtroBusca in filters && searchTerm.trim() !== '') {
            setFiltrado(treatment.filter(filters[filtroBusca]));
          } else {
            setFiltrado(treatment);
          }
        } else if (filtro === 'Parcialmente Atrasada') {
          const parciallyDelay = objetos.sm.filter(
            ({ statusviagemsm }: { statusviagemsm: string }) =>
              statusviagemsm === 'Parcialmente Atrasada'
          );

          const filters: any = {
            SM: ({ idsm }: any) => idsm === Number(searchTerm),
            Cliente: ({ razaosocialcli }: any) =>
              razaosocialcli.toLowerCase().includes(searchTerm.toLowerCase()),
            Embarcador: ({ nomeembarcador }: any) =>
              nomeembarcador.toLowerCase().includes(searchTerm.toLowerCase()),
            Placa: ({ placas }: any) => placas.toLowerCase().includes(searchTerm.toLowerCase()),
            'Próximo ponto': ({ proxponto }: any) =>
              proxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Previsão: ({ previsaoproxponto }: any) =>
              previsaoproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            'Tempo de chegada': ({ tempochegadaproxponto }: any) =>
              tempochegadaproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Motivo: ({ motivoatraso }: any) =>
              motivoatraso.toLowerCase().includes(searchTerm.toLowerCase()),
          };

          if (filtroBusca in filters && searchTerm.trim() !== '') {
            setFiltrado(parciallyDelay.filter(filters[filtroBusca]));
          } else {
            setFiltrado(parciallyDelay);
          }
        } else if (filtro === 'Em Atraso' || filtro === 'Atrasada') {
          const delay = objetos.sm.filter(
            ({ statusviagemsm }: { statusviagemsm: string }) => statusviagemsm === 'Em Atraso'
          );

          const filters: any = {
            SM: ({ idsm }: any) => idsm === Number(searchTerm),
            Cliente: ({ razaosocialcli }: any) =>
              razaosocialcli.toLowerCase().includes(searchTerm.toLowerCase()),
            Embarcador: ({ nomeembarcador }: any) =>
              nomeembarcador.toLowerCase().includes(searchTerm.toLowerCase()),
            Placa: ({ placas }: any) => placas.toLowerCase().includes(searchTerm.toLowerCase()),
            'Próximo ponto': ({ proxponto }: any) =>
              proxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Previsão: ({ previsaoproxponto }: any) =>
              previsaoproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            'Tempo de chegada': ({ tempochegadaproxponto }: any) =>
              tempochegadaproxponto.toLowerCase().includes(searchTerm.toLowerCase()),
            Motivo: ({ motivoatraso }: any) =>
              motivoatraso.toLowerCase().includes(searchTerm.toLowerCase()),
          };

          if (filtroBusca in filters && searchTerm.trim() !== '') {
            setFiltrado(delay.filter(filters[filtroBusca]));
          } else {
            setFiltrado(delay);
          }
        } else if (filtro === 'Excesso de Velocidade') {
          const speed = objetos.violation_speed.map((speed: any) => {
            const isoDate = new Date(speed.data).toISOString();

            return {
              ...speed,
              data: format(subHours(new Date(isoDate), 3), 'dd/MM/yyyy HH:mm:ss'),
            };
          });

          if (filtroBusca === 'Placa' && searchTerm.trim() !== '') {
            setFiltrado(
              speed.filter(({ placa }: { placa: string }) =>
                placa.toLowerCase().includes(searchTerm.toLowerCase())
              )
            );
            return;
          }

          if (filtroBusca === 'Localizacao' && searchTerm.trim() !== '') {
            setFiltrado(
              speed.filter(({ localizacao }: { localizacao: string }) =>
                localizacao.toLowerCase().includes(searchTerm.toLowerCase())
              )
            );
            return;
          }

          if (filtroBusca === 'Tecnologia' && searchTerm.trim() !== '') {
            setFiltrado(
              speed.filter(({ tecnologia }: { tecnologia: string }) =>
                tecnologia.toLowerCase().includes(searchTerm.toLowerCase())
              )
            );
            return;
          }

          if (filtroBusca === 'Cliente' && searchTerm.trim() !== '') {
            setFiltrado(
              speed.filter(({ razao_social }: { razao_social: string }) =>
                razao_social.toLowerCase().includes(searchTerm.toLowerCase())
              )
            );
            return;
          }

          setFiltrado(speed);
        } else if (filtro === 'Violação de Temperatura') {
          const temperature = objetos.violation_temperature.map((tmp: any) => {
            const isoDate = new Date(tmp.data).toISOString();

            return {
              ...tmp,
              data: format(subHours(new Date(isoDate), 3), 'dd/MM/yyyy HH:mm:ss'),
            };
          });

          if (filtroBusca === 'Placa' && searchTerm.trim() !== '') {
            setFiltrado(
              temperature.filter(({ placa }: { placa: string }) =>
                placa.toLowerCase().includes(searchTerm.toLowerCase())
              )
            );
            return;
          }

          if (filtroBusca === 'Localizacao' && searchTerm.trim() !== '') {
            setFiltrado(
              temperature.filter(({ localizacao }: { localizacao: string }) =>
                localizacao.toLowerCase().includes(searchTerm.toLowerCase())
              )
            );
            return;
          }

          if (filtroBusca === 'Tecnologia' && searchTerm.trim() !== '') {
            setFiltrado(
              temperature.filter(({ tecnologia }: { tecnologia: string }) =>
                tecnologia.toLowerCase().includes(searchTerm.toLowerCase())
              )
            );
            return;
          }

          if (filtroBusca === 'Cliente' && searchTerm.trim() !== '') {
            setFiltrado(
              temperature.filter(({ razao_social }: { razao_social: string }) =>
                razao_social.toLowerCase().includes(searchTerm.toLowerCase())
              )
            );
            return;
          }

          setFiltrado(temperature);
        }

        // //Filtro distancia
        // filtrado = filtrado?.filter(
        //   (each: any) =>
        //     each.diantanciaproxponto >= valueRange[0] && each.diantanciaproxponto <= valueRange[1]
        // );
      } else {
      }
    };
    handleFiltro();
  }, [filtro, objetos, valueRange, filtroBusca, searchTerm]);

  const handleRange = () => {
    // Filtro distancia KM
    const filtered = filtrado?.filter(
      (each: any) =>
        each.diantanciaproxponto > valueRange[0] && each.diantanciaproxponto < valueRange[1]
    );
    setFiltrado(filtered);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="whitecard">
        <div className="titulo-container">
          <h2 className="titulo">Painel LSC</h2>
          <h6 className="subtitulo">{'Operacional > Painel LSC'}</h6>
        </div>
        <div className="alertas">
          {valuesCards &&
            valuesCards.map((each: ValuesCardsType, index) => {
              if (each.show === true) {
                return (
                  <CardInformativo
                    key={index}
                    name={each.nome}
                    data={each.value}
                    onClick={() => {
                      setFiltro(each.nome);
                    }}
                    icon={each.icon}
                    color={each.color}
                  />
                );
              }
            })}
        </div>
        <div className="content-container">
          <div className="painel">
            <div className="filtros">
              <Form.Group className="row-modal" style={{ maxWidth: '160px' }}>
                <Form.Control
                  id="viagemEscolta"
                  name="viagemEscolta"
                  placeholder=""
                  onChange={(e) => {
                    setSearchTerm('');
                    setFiltroBusca(e.target.value);
                  }}
                  as="select"
                >
                  {filtro === 'Excesso de Velocidade' || filtro === 'Violação de Temperatura' ? (
                    <>
                      <option value="Todos">Todos</option>
                      <option value="Placa">Placa</option>
                      <option value="Localizacao">Localização</option>
                      <option value="Tecnologia">Tecnologia</option>
                      <option value="Cliente">Cliente</option>
                    </>
                  ) : (
                    <>
                      <option value="Todos">Todos</option>
                      <option value="SM">SM</option>
                      <option value="Cliente">Cliente</option>
                      <option value="Embarcador">Embarcador</option>
                      <option value="Placa">Placa</option>
                      <option value="Próximo ponto">Próximo ponto</option>
                      <option value="Previsão">Previsão</option>
                      <option value="Tempo de chegada">Tempo de chegada</option>
                      <option value="Motivo">Motivo</option>
                    </>
                  )}
                </Form.Control>
              </Form.Group>
              <Search
                disabled={!filtrado}
                setLoading={setLoading}
                styles={{ marginTop: '-15px' }}
                setSearch={setSearchTerm}
                setSearchValue={setSearch}
                search={searchTerm}
                placeholder={filtroBusca}
              />
              {/* <Form.Group className="row-modal" id="range">
                <Slider
                  value={valueRange}
                  onChange={(e: any) => {
                    setValueRange(e.value);
                  }}
                  range
                  max={rangeDistancia.max}
                  min={rangeDistancia.min}
                  className="p-slider-handle"
                  disabled={sliderDisabled}
                />
              </Form.Group>
              <Form.Group className="row-modal" id="km">
                {isFinite(valueRange[0]) ? valueRange[0] : 0}km a{' '}
                {isFinite(valueRange[1]) ? valueRange[1] : 0}km
              </Form.Group> */}
            </div>

            <div className="table-responsive mt-4" style={{ flex: 1 }}>
              <table className="table" id="lsc">
                <thead className="thead">
                  <tr>
                    {(filtro === 'Violação de Temperatura' && (
                      <>
                        <th>Cliente</th>
                        <th>Tecnologia</th>
                        <th>Data da ocorrência</th>
                        <th>Placa</th>
                        <th>Temperatura no momento da ocorrência</th>
                        <th>Temperatura mínima permitida pro veículo</th>
                        <th>Temperatura máxima permitida pro veículo</th>
                        <th>Localização da ocorrência</th>
                      </>
                    )) ||
                      (filtro === 'Excesso de Velocidade' && (
                        <>
                          <th>Cliente</th>
                          <th>Tecnologia</th>
                          <th>Data da ocorrência</th>
                          <th>Placa</th>
                          <th>Velocidade no momento da ocorrência</th>
                          <th>Velocidade máxima permitida pro veículo</th>
                          <th>Localização da ocorrência</th>
                        </>
                      )) || (
                        <>
                          <th>SM</th>
                          <th>Cliente</th>
                          <th>Embarcador</th>
                          <th>Placa</th>
                          <th>Próximo ponto</th>
                          <th>Previsão chegada próx. ponto</th>
                          <th>Distância p/ próx. ponto</th>
                          <th>Tempo chegada próx. ponto</th>
                          <th>Status</th>
                          <th>Tratamento</th>
                          <th>Motivo do atraso</th>
                          <th>Tratamento</th>
                        </>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {currentData ? (
                    currentData.map((each: any, index: any) => {
                      if (filtro === 'Excesso de Velocidade') {
                        return (
                          <>
                            <tr onClick={() => setExpandedRowIndex(index)}>
                              <td>{each.razao_social}</td>
                              <td>{each.tecnologia}</td>
                              <td>{each.data}</td>
                              <td>{each.placa}</td>
                              <td>{each.velocidade}</td>
                              <td>{each.velocidadeMaxima}</td>
                              <td>{each.localizacao}</td>

                              <td>
                                <Button2
                                  id="button-treatment"
                                  onClick={() => {
                                    setShowModal(true);
                                  }}
                                >
                                  Tratar
                                </Button2>

                                <ModalLSC
                                  showModal={showModal && expandedRowIndex === index}
                                  setShowModal={setShowModal}
                                  data={each}
                                  fetchData={fetchData}
                                  role="speed"
                                />
                              </td>
                            </tr>
                          </>
                        );
                      }

                      if (filtro === 'Violação de Temperatura') {
                        return (
                          <>
                            <tr>
                              <td>{each.razao_social}</td>
                              <td>{each.tecnologia}</td>
                              <td>{each.data}</td>
                              <td>{each.placa}</td>
                              <td>{each.temperatura}</td>
                              <td>{each.temperaturaMinima}</td>
                              <td>{each.temperaturaMaxima}</td>
                              <td>{each.localizacao}</td>

                              <td>
                                <Button2
                                  id="button-treatment"
                                  onClick={() => {
                                    if (canEdit) {
                                      setShowModal(true);
                                    } else {
                                      history.push('/acessonegado');
                                    }
                                  }}
                                >
                                  Tratar
                                </Button2>

                                <ModalLSC
                                  showModal={showModal && expandedRowIndex === index}
                                  setShowModal={setShowModal}
                                  data={each}
                                  fetchData={fetchData}
                                  role="temperature"
                                />
                              </td>
                            </tr>
                          </>
                        );
                      }

                      return (
                        <>
                          <GridExpansivel
                            key={index}
                            each={each}
                            fetchData={fetchData}
                            canEdit={canEdit}
                          />
                        </>
                      );
                    })
                  ) : (
                    <Spinner />
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <Pagination
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
            totalPosts={filtrado?.length || 0}
            paginate={paginate}
            currentPage={page.toString()}
          />
        </div>
      </div>
    </div>
  );
}
