/* eslint-disable */
import './styles.css';
import { ImCancelCircle } from 'react-icons/im';
import { AiOutlineCheck } from 'react-icons/ai';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useEffect, useState } from 'react';
import { IoPencilSharp } from 'react-icons/io5';

type LineProps = {
  each: any;
  setOpenModal: (openModal: boolean) => void;
  setIdDelete: (idDelete: number) => void;
  setIdAtual: (idEdit: number) => void;
  handlePut: (model: any) => Promise<boolean>;
  idAtual: number;
};

export const Linha = ({
  each,
  setOpenModal,
  setIdDelete,
  setIdAtual,
  handlePut,
  idAtual,
}: LineProps) => {
  const [disabled, setDisabled] = useState(true);
  const [model, setModel] = useState<any>(each);

  const handleEdit = (id: number) => {
    setDisabled(false);
    setIdAtual(id);
  };

  const handleCancel = () => {
    setDisabled(true);
    setIdAtual(0);
  };

  useEffect(() => {
    setModel(each);
  }, [each, disabled, idAtual]);

  useEffect(() => {
    const listener = (event: globalThis.KeyboardEvent) => {
      if (event.keyCode === 27) {
        handleCancel();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [model]);

  return (
    <div>
      <div
        className="lineGrid2"
        style={{ filter: idAtual != model.id ? '' : 'drop-shadow(0 0 0.5rem #d8d8d8)' }}
      >
        <input
          disabled={idAtual != model.id}
          value={model.nome}
          onChange={(e: any) => {
            setModel({
              ...model,
              nome: e.target.value,
            });
          }}
          type="text"
          maxLength={64}
          onKeyPress={async (e: any) => {
            if (e.key === 'Enter') {
              if ((await handlePut(model)) === true) {
                setIdAtual(0);
              }
            }
            if (e.key === 'Escape') {
              handleCancel();
            }
          }}
        />
        <input
          disabled={idAtual != model.id}
          value={model.descricao}
          onChange={(e: any) => {
            setModel({
              ...model,
              descricao: e.target.value,
            });
          }}
          type="text"
          maxLength={64}
          onKeyPress={async (e: any) => {
            if (e.key === 'Enter') {
              if ((await handlePut(model)) === true) {
                setIdAtual(0);
              }
            }
            if (e.key === 'Escape') {
              handleCancel();
            }
          }}
        />
        {!disabled && idAtual === model.id && idAtual != 0 ? (
          <ImCancelCircle size="40px" id="icon" onClick={handleCancel} />
        ) : (
          <IoPencilSharp
            size={40}
            color="#9F9F9F"
            onClick={() => handleEdit(model.id)}
            style={{ marginRight: '20px', cursor: 'pointer' }}
          />
        )}
        {!disabled && idAtual === model.id && idAtual != 0 ? (
          <AiOutlineCheck
            size="40px"
            id="icon"
            onClick={async () => {
              if ((await handlePut(model)) === true) {
                setIdAtual(0);
              }
            }}
          />
        ) : (
          <RiDeleteBin5Line
            size="40px"
            id="icon"
            style={{ color: '#9F9F9F' }}
            onClick={() => {
              setOpenModal(true);
              if (model.id != undefined) {
                setIdDelete(model.id);
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

type NewLineProps = {
  setNewLine: (newLine: boolean) => void;
  handlePost: (nome: string) => void;
};

export const NovaLinha = ({ setNewLine, handlePost }: NewLineProps) => {
  const [disabled, setDisabled] = useState(false);
  const [nome, setNome] = useState('');

  const handleCancel = () => {
    setDisabled(true);
    setNewLine(false);
  };

  useEffect(() => {
    const listener = (event: globalThis.KeyboardEvent) => {
      if (event.keyCode === 27) {
        handleCancel();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [nome]);

  return (
    <div className="lineGrid2" style={{ filter: 'drop-shadow(0 0 0.5rem #d8d8d8)' }}>
      <input
        placeholder="Novo registro"
        type="text"
        value={nome}
        maxLength={64}
        onChange={(e: any) => {
          setNome(e.target.value);
        }}
        onKeyPress={(e: any) => {
          if (e.key === 'Enter') {
            handlePost(nome);
          }
          if (e.key === 'Escape') {
            handleCancel();
          }
        }}
      />
      <ImCancelCircle size="18px" id="icon" onClick={handleCancel} />
      <AiOutlineCheck
        size="20px"
        id="icon"
        onClick={() => {
          handlePost(nome);
        }}
      />
    </div>
  );
};
