import React, { ReactNode, createContext, useContext, useState } from 'react';
import { isAllowedByPermission } from '../services/cookieService';

interface ChildrenProps {
  children: ReactNode;
}

interface PermissionContextProps {
  rolePermission: (permission: any, type: string) => void;
  doesHavePermission: boolean;
}

export const RolePermissionContext = createContext({} as PermissionContextProps);

export const RolePermissionProvider = ({ children }: ChildrenProps) => {
  const [doesHavePermission, setDoesHavePermission] = useState(false);

  const rolePermission = (permission: number[], type: string) => {
    setDoesHavePermission(isAllowedByPermission(permission, type));
  };

  return (
    <RolePermissionContext.Provider value={{ rolePermission, doesHavePermission }}>
      {children}
    </RolePermissionContext.Provider>
  );
};

export const useRolePermission = () => {
  const context = useContext(RolePermissionContext);
  return context;
};
