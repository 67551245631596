/* eslint-disable*/

import React, { useState, useEffect, useCallback, FC, useContext } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Spinner } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import SolicitacaoMonitoramento from '../../components/SolicitacaoMonitoramento';
import { UsuarioType } from '../../types';
import { SMEmAndamentoType } from '../../api/model/Veiculos/SMEmAndamentoType';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import {
  BauDTO,
  Dispositivo,
  DispositivoDTO,
  IdsForDispositivosDTO,
} from '../../Types/AssistenciaTecnica';
import { PreCancelamentoType } from '../IniciarSMPendente/index';
import api from '../../config/axiosMaquina';
import { isDateLessThanBaseDate } from '../../util/Validacoes/Validacoes';
import { useToast } from '../../hooks/Toast';
import { PAGE } from '../../constants/Paginacao';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { monitoringRoute } from '../../services/monitoringRoute';

type Props = {
  canEdit?: boolean;
};

const FinalizarSMEmAndamento: FC = () => {
  const { ADMIN, TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();

  const history = useHistory();
  const { id }: any = useParams();
  const [smData, setSmData] = useState<SMEmAndamentoType>();
  const [motivoFinalizacao, setMotivoFinalizacao] = useState<number>(0);
  const [solicitanteFinalizacao, setMotivoSolicitanteFinalizacao] = useState<string>('');
  const [cliente, setCliente] = useState('');
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isDialogVisibleCadOk, setIsDialogVisibleCadOk] = useState(false);
  const [isDialogVisibleMsgEmail, setIsDialogVisibleMsgEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [usuario, setUsuario] = useState<UsuarioType | undefined>();
  const [dadosCancelamento, setDadosCancelamento] = useState<PreCancelamentoType>({
    idUsuario: 0,
    motivoCancelamento: 0,
    idSm: 0,
    dataCancelamento: '',
    nomeUsuario: '',
    solicitanteCancelamento: '',
  });

  const usuarioLogado = getCookieSessionData().usuarioVO.usuarioId;
  const { addToast } = useToast();

  useEffect(() => {
    rolePermission(
      [
        {
          ADMIN: [ADMIN.OPERACIONAL.PAINEL_OPERACIONAL, ADMIN.CONSULTA_RELATORIO.SM_LANCADA],
          TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.SM_LANCADA],
        },
      ],
      'editar'
    );
  }, [ADMIN, TRANSPORTADOR, rolePermission]);

  useEffect(() => {
    const usuarioData = getCookieSessionData().usuarioVO;
    setUsuario(usuarioData);
  }, []);
  console.log('TESTE smData', smData);
  useEffect(() => {
    const cancelaSolicitacaoGetByID = async () => {
      console.log(id);
      try {
        const response = await axios.get(`/solicitacao-monitoramento/getDadosCancelamento/${id}`);
        console.log(response.data);
        setDadosCancelamento(response.data);
      } catch (err: any) {
        console.log(err.response);
      }
    };
    cancelaSolicitacaoGetByID();
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/solicitacao-monitoramento/listardadossmandamento?solicitacaoMonitoramento=${id}`
        );

        const getRoutes: any = await monitoringRoute(response.data.id);

        setSmData({
          ...response.data,
          ...getRoutes.data[0],
        });

        console.log(response.data);
        setCliente(response.data.clienteId);
        console.log('SOLICITACAO MONITORAMENTO: ', response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  console.log(cliente);

  const MOTIVOS = [
    { id: 1, motivo: 'Viagem Concluída' },
    { id: 2, motivo: 'Veículo no Destino Correto' },
    { id: 3, motivo: 'Alteração de Dados por Solicitação do Cliente' },
    { id: 4, motivo: 'Espelhamento Retirado' },
    { id: 5, motivo: 'Sinistrado' },
    { id: 6, motivo: 'Problema Mecânico/Transbordo' },
  ];

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    if (!smData) {
      return;
    }

    try {
      const motivo = MOTIVOS.find((each) => each.id === motivoFinalizacao);
      if (!motivo) {
        return;
      }

      const params: { [key: string]: any } = {
        idUsuario: usuarioLogado,
        motivo: motivo.id,
      };

      if (motivoFinalizacao === 3) {
        params.solicitanteFinalizacao = solicitanteFinalizacao;
      }

      const response = await axios.put(
        `/solicitacao-monitoramento/atualizarDadosSmAndamento/${smData.id}`,
        null,
        { params }
      );

      setIsDialogVisible(true);
      localStorage.setItem('smFinalizada', smData.id.toString());
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      console.log(err.response);
      if (err.response.data.message?.includes('Houve erro ao enviar o email de conclusão de SM')) {
        setIsDialogVisibleMsgEmail(true);
        localStorage.setItem('smFinalizada', smData.id.toString());
      } else {
        addToast({
          title: 'Erro',
          description: 'Erro ao finalizar SM.',
          type: 'error',
        });
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação de Monitoramento</h2>
          <h6 className="subtitulo">{'SM > Visualização'}</h6>
        </div>
        <Dialog
          header="Sua SM foi finalizada com sucesso!"
          footer={
            <Button
              label="Ok"
              onClick={() => {
                window.close();
                history.push(`/admin/painel-operacional/em-andamento/${PAGE}`);
              }}
            />
          }
          visible={isDialogVisible}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            window.close();
            history.push(`/admin/painel-operacional/em-andamento/${PAGE}`);
          }}
        />
        <Dialog
          header="Não foi possível enviar email de finalização de SM. Verifique o email cadastrado."
          footer={
            <Button
              onClick={() => {
                window.close();
                history.push(`/admin/painel-operacional/em-andamento/${PAGE}`);
              }}
            >
              OK
            </Button>
          }
          visible={isDialogVisibleMsgEmail}
          style={{ width: '50vw' }}
          modal
          onHide={() => history.push(`/admin/painel-operacional/em-andamento/${PAGE}`)}
        />
        {loading ? (
          <>
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
            <p style={{ textAlign: 'center', marginTop: '-40px' }}>
              Aguarde enquanto a alteração está sendo concluída...
            </p>
          </>
        ) : (
          <>
            <div className="content-container">
              <div className="painel">
                {smData ? (
                  <SolicitacaoMonitoramento
                    smData={smData && smData}
                    isAdmin={isAllowedByRole(['admin'])}
                    usuarioLogado={usuario}
                    onSubmit={handleSubmit}
                    motivoFinalizacazao={motivoFinalizacao}
                    setMotivoFinalizacao={setMotivoFinalizacao}
                    setMotivoSolicitanteFinalizacao={setMotivoSolicitanteFinalizacao}
                    motivos={MOTIVOS}
                    editValorDescricaoCarga={false}
                    loading={loading}
                    dadosCancelamento={dadosCancelamento}
                    canEdit={canEdit}
                  />
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FinalizarSMEmAndamento;
