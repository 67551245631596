/* eslint-disable */
import React from 'react';
import { Progressao } from '../../pages/ProgressaoViagens/ProgressaoViagens';
import orderBy from 'lodash/orderBy';
import { StepperPointTravelProgress } from '../StepperPointTravelProgress';
import { StepperLineTravelProgress } from '../StepperLineTravelProgress';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as SucessIcon } from '../../assets/success.svg';
import { ReactComponent as WarningIcon } from '../../assets/warning.svg';
import { ReactComponent as DangerIcon } from '../../assets/danger.svg';
import { obterDataHora } from '../../util/date';

type Ponto = {
  dataChegadaPlanejada: string;
  dataReplanejada: string;
  distanciaProxPonto: number;
  dtHrCalculadaChegadaPonto: string;
  dtHrCalculadaProxPonto: string;
  dtHrChegadaPonto: string;
  dtHrInicioRealSM: string;
  dtHrInicioSM: string;
  dtHrPlanejadaViagemPonto: string;
  dtHrUltimoEvento: string;
  enderecoPonto: string;
  hintPonto: string;
  idLsc: number;
  idPlanoViagem: number;
  idPontoPlanejamentoSmo: number;
  idPontoSmo: number;
  idPontoViagem: number;
  idPontoViagemReplanejada: number;
  idSm: number;
  latitudePonto: number;
  longitudePonto: number;
  nomePonto: string;
  numSeqPonto: number;
  origemDestinoPonto: string;
  percPercorridoPonto: number;
  previsao: string;
  previsaoComparadas: string;
  remessa: any;
  statusConcluidoPonto: boolean;
  statusPonto: string;
  tempoProxPonto: number;
};

export const choosePontoBackgroundColor = (statusPonto: string) => {
  if (statusPonto === 'B') return '#bfffbd';
  if (statusPonto === 'A') return '#ffabb4';
  if (statusPonto === 'D') return '#ffdaa6';
  return '#FFFFFF';
};

export const chooseLinhaBackgroundColor = (statusPonto: string) => {
  if (statusPonto === 'B') return '#63c960';
  if (statusPonto === 'A') return '#c44a57';
  if (statusPonto === 'D') return '#e8ab53';
  return '#FFFFFF';
};

type PropType = {
  viagem: Progressao;
};

export const BarraViagem = ({ viagem }: PropType) => {
  const { monitSmProgresViagPontoVO } = viagem;

  const ordenado = orderBy(monitSmProgresViagPontoVO, 'numSeqPonto');

  const getHintPonto = (ponto: Ponto) => {
    if (ponto.origemDestinoPonto === 'O') {
      return (
        <div className="d-flex flex-column align-items-start">
          <span>{ponto.nomePonto}</span>
          <span>Previsão de Saída: {obterDataHora(ponto.dtHrInicioSM, true)}</span>
          {ponto.dtHrInicioRealSM !== null && (
            <span>Saída: {obterDataHora(ponto.dtHrInicioRealSM, true)}</span>
          )}
        </div>
      );
    }

    return (
      <div className="d-flex flex-column align-items-start">
        <span>{ponto.nomePonto} </span>
        <span>
          {ponto.dtHrPlanejadaViagemPonto === null
            ? ''
            : `Agendada: ${obterDataHora(ponto.dtHrPlanejadaViagemPonto, true)} `}
        </span>
        <span>
          {ponto.dtHrInicioRealSM !== null &&
            (ponto.dtHrCalculadaProxPonto === null
              ? ''
              : `Previsão de Chegada: ${obterDataHora(ponto.dtHrCalculadaProxPonto, true)}`)}
        </span>
        <span>
          {ponto.dtHrChegadaPonto === null
            ? ''
            : `Chegada: ${obterDataHora(ponto.dtHrChegadaPonto, true)}`}
        </span>
      </div>
    );
  };

  const iconProgresstatus = (ponto: Ponto) => {
    switch (ponto.statusPonto) {
      case 'A':
        return <DangerIcon className="icon" />;
      case 'B':
        return <SucessIcon className="icon" />;
      case 'D':
        return <WarningIcon className="icon" />;
      default:
        return null;
    }
  };

  return (
    <>
      {ordenado && (
        <>
          <tr>
            <td colSpan={12}>
              <tr>
                <span
                  style={{ fontSize: '12px' }}
                  className="progressao-body-texto"
                >{`Nº SM: ${viagem.idSm} `}</span>
                <span
                  style={{ fontSize: '12px' }}
                  className="progressao-body-texto"
                >{`Placas: ${viagem.placas}`}</span>
                <span
                  style={{ fontSize: '12px' }}
                  className="progressao-body-texto"
                >{`Percorrido: ${viagem.percorrido}`}</span>
                {viagem.percPercorrido === 100 ? null : (
                  <span
                    style={{ fontSize: '12px' }}
                    className="progressao-body-texto"
                  >{`Próximo ponto: ${viagem.proximoPonto ? viagem.proximoPonto.split("(")[0].trim() : ""}`}</span>
                )}
              </tr>
              <tr className="timeline w-75">
                {ordenado?.map((ponto: any, index: number) => {
                  const isLastPonto = index + 1 === ordenado?.length;
                  if (isLastPonto) {
                    return null;
                  }
                  return (
                    <>
                      <StepperPointTravelProgress ponto={ponto} index={index} />
                      <div className="progresso-linha pointer">
                        <StepperLineTravelProgress ponto={ponto} />
                      </div>
                    </>
                  );
                })}
                <OverlayTrigger
                  key={Number(viagem.idveiculo)}
                  overlay={
                    <Tooltip className="tooltipTravel" id={viagem.idveiculo.toString()}>
                      {getHintPonto(
                        viagem.monitSmProgresViagPontoVO[
                          viagem.monitSmProgresViagPontoVO.length - 1
                        ]
                      )}
                    </Tooltip>
                  }
                >
                  {viagem.monitSmProgresViagPontoVO[viagem.monitSmProgresViagPontoVO.length - 1]
                    .statusConcluidoPonto ? (
                    <div className="progresso-ponto">
                      {iconProgresstatus(
                        viagem.monitSmProgresViagPontoVO[
                          viagem.monitSmProgresViagPontoVO.length - 2
                        ]
                      )}
                      <div className="progress-name-container">
                        <span style={{ opacity: 0.5 }}>
                          {
                            viagem.monitSmProgresViagPontoVO[
                              viagem.monitSmProgresViagPontoVO.length - 1
                            ].nomePonto
                          }
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="progresso-ponto"
                      style={{
                        backgroundColor:
                          viagem.monitSmProgresViagPontoVO[
                            viagem.monitSmProgresViagPontoVO.length - 1
                          ].percPercorridoPonto === 0
                            ? '#fff'
                            : choosePontoBackgroundColor(
                                viagem.monitSmProgresViagPontoVO[
                                  viagem.monitSmProgresViagPontoVO.length - 1
                                ].statusPonto
                              ),
                        border: '2px dashed #ddd',
                        opacity: 0.3,
                      }}
                    >
                      <span>{viagem.monitSmProgresViagPontoVO.length}</span>
                      <div className="progress-name-container">
                        <span>
                          {
                            viagem.monitSmProgresViagPontoVO[
                              viagem.monitSmProgresViagPontoVO.length - 1
                            ].nomePonto
                          }
                        </span>
                      </div>
                    </div>
                  )}
                </OverlayTrigger>
              </tr>
            </td>
          </tr>
        </>
      )}
    </>
  );
};
