import React, { useState, useEffect, memo, ChangeEvent, MouseEvent } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { moneyToNumber } from '../../util/Validacoes/Validacoes';
import './styles.css';

type Props = {
  canEdit?: boolean;
};

export const ContainerGerencial = memo(({ canEdit }: Props) => {
  const history = useHistory();
  const [emailAvisoCadastroOuAlteracaoClientes, setEmailAvisoCadastroOuAlteracaoClientes] =
    useState('');
  const [emailAvisoServicoInoperante, setEmailAvisoServicoInoperante] = useState('');
  const [emailAlteracaoFrota, setEmailAlteracaoFrota] = useState('');
  const [emailAvisoViagensEmAndamento, setEmailAvisoViagensEmAndamento] = useState('');
  const [ipAcessoGr, setIpAcessoGr] = useState('');
  const [pisoCargasEspeciais, setPisoCargasEspeciais] = useState(0);
  const { addToast } = useToast();

  const pegarGerencial = async () => {
    const response = await axios.get('/parametro-gerencial');
    const gerencial = response.data;

    setEmailAlteracaoFrota(gerencial.emailAlteracaoFrota);
    setEmailAvisoCadastroOuAlteracaoClientes(gerencial.emailAvisoCadastroOuAlteracaoClientes);
    setEmailAvisoServicoInoperante(gerencial.emailAvisoServicoInoperante);
    setEmailAvisoViagensEmAndamento(gerencial.emailAvisoViagensEmAndamento);
    setPisoCargasEspeciais(gerencial.pisoCargasEspeciais);
    setIpAcessoGr(gerencial.ip);
  };

  const atualizar = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    const data = {
      pisoCargasEspeciais,
      emailAlteracaoFrota,
      emailAvisoViagensEmAndamento,
      emailAvisoServicoInoperante,
      emailAvisoCadastroOuAlteracaoClientes,
      ip: ipAcessoGr ? ipAcessoGr.replaceAll('\n', '') : null,
    };
    try {
      await axios.post(`/parametro-gerencial/cadastrar`, data);
      addToast({
        title: 'Sucesso!',
        description: 'Os parametros foram atualizados com sucesso!',
        type: 'success',
      });
    } catch (error: any) {
      addToast({
        title: 'Erro',
        description: 'Houve algum problema ao atualizar os parametros.',
        type: 'error',
      });
    }
  };
  useEffect(() => {
    pegarGerencial();
  }, []);
  return (
    <>
      <Form>
        <Form.Group as={Row}>
          <Form.Label>Piso para cargas especiais </Form.Label>
          <Form.Control
            value={
              !pisoCargasEspeciais
                ? ''
                : Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
                    pisoCargasEspeciais
                  )
            }
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPisoCargasEspeciais(e.target.value ? moneyToNumber(e.target.value.toString()) : 0);
            }}
            id="pisoCargasEspeciais1"
            name="pisoCargasEspeciais1"
            maxLength={19}
          />
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label>
            E-mail para aviso de Viagens em Andamento: separador para os email é &quot; ; &quot;
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Ex:. email@email.com ; email2@email2.com"
            style={{ resize: 'none', height: '5rem' }}
            rows={3}
            value={emailAvisoViagensEmAndamento}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmailAvisoViagensEmAndamento(e.target.value);
            }}
            id="emailAvisoViagensEmAndamento"
            name="emailAvisoViagensEmAndamento"
            maxLength={256}
          />
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label>E-mail de aviso de Cadastro / Alteração de clientes: </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Ex:. email@email.com ; email2@email2.com"
            style={{ resize: 'none', height: '5rem' }}
            rows={3}
            value={emailAvisoCadastroOuAlteracaoClientes}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmailAvisoCadastroOuAlteracaoClientes(e.target.value);
            }}
            id="emailAvisoCadastroOuAlteracaoClientes"
            name="emailAvisoCadastroOuAlteracaoClientes"
            maxLength={256}
          />
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label>E-mail de aviso de Serviço Inoperante: </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Ex:. email@email.com ; email2@email2.com"
            style={{ resize: 'none', height: '5rem' }}
            rows={3}
            value={emailAvisoServicoInoperante}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmailAvisoServicoInoperante(e.target.value);
            }}
            id="emailAvisoServicoInoperante"
            name="emailAvisoServicoInoperante"
            maxLength={256}
          />
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label>E-mail alteração de frota: </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Ex:. email@email.com ; email2@email2.com"
            style={{ resize: 'none', height: '5rem' }}
            rows={3}
            value={emailAlteracaoFrota}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmailAlteracaoFrota(e.target.value);
            }}
            id="emailAvisoServicoInoperante"
            name="emailAvisoServicoInoperante"
            maxLength={256}
          />
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label>
            Controle de Ip acesso ao GR: (Caso esse campo esteja vazio, todos os IPs serão
            permitidos.) separador para os email é &quot; ; &quot;
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Ex:. 192.168.123.123"
            style={{ resize: 'none', height: '5rem' }}
            rows={3}
            value={ipAcessoGr}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setIpAcessoGr(e.target.value);
            }}
            id="ipAcessoGr"
            name="ipAcessoGr"
            maxLength={256}
          />
        </Form.Group>
      </Form>

      <Form.Row className="container-buttons justify-content-end">
        <Form.Group as={Col} md={2}>
          {canEdit ? (
            <Button onClick={atualizar} type="submit" className="btn-enviar">
              Enviar
            </Button>
          ) : (
            <Button
              onClick={() => history.push('/acessonegado')}
              type="button"
              className="btn-enviar"
            >
              Enviar
            </Button>
          )}
        </Form.Group>
      </Form.Row>
    </>
  );
});
