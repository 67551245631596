/* eslint-disable */

import { useEffect, useState, useCallback } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { IoAddCircleOutline, IoMailUnread, IoRadioButtonOnOutline } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
import { Accordion, Form } from 'react-bootstrap';
import GoogleMaps from '../../components/GoogleMaps/GoogleMaps';
import instanceMaquina from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { ActiveSM, PositionTruck } from '../../types';
import './styles.css';
import { Progressao } from '../ProgressaoViagens/ProgressaoViagens';
import { MapaFilter } from './Filter';
import { handleRota } from '../../util/getPontosLista';
import { Tela } from '../../components/Tela';
import { PercentageDiv, TableData, TableHead, TableRow } from './styles';
import { soNumeros } from '../../util/Validacoes/Validacoes';
import { obterDataHora } from '../../util/date';
import { isBefore, isEqual } from 'date-fns';
import { matchSmId, vehicleStatusTravel } from '../../util/transitTime';
import { handleListActiveSM } from '../../services/transitTime';

export type SMMapa = {
  bau: string;
  desMotivoFinalizacaoSm: string;
  statusAtraso?: boolean;
  dtCadastroSm: string;
  dtFimPlanejadoSm: string;
  dtFimRealSm: string;
  dtHrUltimaPosicao: string;
  dtInicioPlanejadoSm: string;
  dtInicioRealSm: string;
  hrFimPlanejadoSm: string;
  hrFimRealSm: string;
  hrInicioPlanejadoSm: string;
  hrInicioRealSm: string;
  idCliente: number;
  idSm: number;
  latitudeAtual: number;
  statusInicializacao?: boolean;
  velocidade: string;
  temperatura: string;
  ignicao: string;
  statusSm?: string;
  listMonitoramentoSmMapaPontoVO: {
    distancia: string;
    horario: string;
    idSm: number;
    latitude: number;
    longitude: number;
    ponto: string;
    remessa: string;
    previsao: string;
    previsaocomparadas: string;
    raioAproximacao: number;
    dtHrPlanejadaViagemPonto: string;
    dtHrChegada: string;
    dtHrIncioRealSm: string;
    dtHrPrevisaoComParadas: string;
    dtHrPrevisao: string;
  }[];

  localizacao: string;
  longitudeAtual: number;
  nomeTransportador: string;
  nomeTecnologiaRastreamento: string;
  operacao: string;
  placas: string;
  possuiRotaOuPonto: string;
  progressao: string;
  razaoSocialEmbarcador: string;
  rota: string;
  statusFinalizacaoSm: boolean;
  statusViagem: string;
  tecnologia: string;
};

type ListMonitoramentoSmMapaPontoVO = {
  distancia: string;
  horario: string;
  idSm: number;
  latitude: number;
  longitude: number;
  ponto: string;
  remessa: string;
  previsao: string;
  previsaocomparadas: string;
  raioAproximacao: number;
  dtHrPlanejadaViagemPonto: string;
  dtHrChegada: string;
  dtHrIncioRealSm: string;
  dtHrPrevisaoComParadas: string;
  dtHrPrevisao: string;
};

type ControleType = { velocidade: number; temperatura: number };

type LatLng = {
  lat: any;
  lng: any;
};

const Mapa = () => {
  const location = useLocation();
  const cnpjEmbarcador = getCookieSessionData().usuarioVO.cnpj;
  const isEmbarcador = getCookieSessionData().usuarioVO.isEmbarcador
  const { usuarioId, clienteId } = getCookieSessionData().usuarioVO;
  const { usuarioVO } = getCookieSessionData();
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filtroBusca, setFiltroBusca] = useState('Todos');
  const [smFiltrada, setSmFiltrada] = useState<SMMapa[]>([]);
  const [filter, setFilter] = useState(
    location.pathname === '/mapa-operacao' || !usuarioVO.isEmbarcador ? true : false
  );
  const [sm, setSm] = useState<SMMapa[]>([]);
  const [veiculosMapa, setVeiculosMapa] = useState<any[]>([]);
  const [options, setOptions] = useState<SMMapa[]>([]);
  const [filtrado, setFiltrado] = useState<SMMapa[]>([]);
  const [message, setMessage] = useState<string>('');
  const [positionsTruck, setPostitionsTruck] = useState<PositionTruck[]>([]);
  const [atrasados, setAtrasados] = useState<Progressao[]>([]);
  const [parametroLogistico, setParametroLogistico] = useState<{
    tempoAtualizacaoDadosEstaticosEmMinutos: number;
    raioDeAproximacaoVeiculosEmMetros: number;
    tempoAtualizacaoMapaEmMinutos: number;
  }>();
  const [isDialogAtualizandoOK, setIsDialogAtualizandoOK] = useState(false);
  const [dataProgressao, setDataProgressao] = useState<Progressao[]>([]);
  const [goToMap, setGoToMap] = useState(false);
  const [clickLatitude, setClickLatitude] = useState(0);
  const [clickLongitude, setclickLongitude] = useState(0);
  const [flagProgressaoViagem, setFlagProgressaoViagem] = useState<number>();
  const [flagControles, setFlagControles] = useState<ControleType>({
    velocidade: 0,
    temperatura: 0,
  });
  const [map, setMap] = useState<google.maps.Map>();
  const [selectedMarker, setSelectedMarker] = useState<LatLng>();
  const [center, setCenter] = useState<LatLng | undefined>({ lat: 0, lng: 0 });
  const [smActive, setSMActive] = useState<ActiveSM[]>([]);

  useEffect(() => {
    instanceMaquina
      .get('parametro-logistico')
      .then((response: any) => {
        setParametroLogistico(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getServicosContratados = async () => {
    try {
      const { data } = await instanceMaquina.get(
        `/cliente/getServicosContratadosCliente?cliente=${clienteId}`
      );
      setFlagProgressaoViagem(data.cscNuCronogramaViagens);
      setFlagControles({
        velocidade: data.controleVelocidade,
        temperatura: data.controleTemperatura,
      });
    } catch (err: any) {
      setLoading(false);
      console.error(err);
    }
  };

  const getMapaData = useCallback(async () => {
    setLoading(true);
    try {
      const responseSm = await instanceMaquina.get(
        '/solicitacao-monitoramento/monitoramento-sm-mapa-mestre-detalhe',
        {
          params: {
            idUsuario: usuarioId,
            cnpjEmbarcador: usuarioVO.isEmbarcador ? usuarioVO.cnpj : null,
          },
        }
      );

      const smListActive: number[] = responseSm.data
        .map((each: any) => each.idSm)
        .filter((id: number | null) => id !== null);
      if (smListActive.length > 0) {
        const SMListID = await handleListActiveSM(smListActive);
        setSMActive(SMListID);
      }

      const response = await instanceMaquina.get(
        'solicitacao-monitoramento/monitoramento-sm-progressao-viagem-mestre-detalhe',
        {
          params: {
            idUsuario: usuarioId,
            ...((isEmbarcador && cnpjEmbarcador) && { cnpjEmbarcador })
          },
        }
      );
      const { data } = await instanceMaquina.get('/veiculo/consulta-gerenciar-veiculo');
      const veiculos = data.filter((d: any) => d.mapa);
      setVeiculosMapa(veiculos);

      setAtrasados(response.data);
      setDataProgressao(response.data);
      if (!responseSm.data.length) {
        setMessage('Não há dados para o usuário');
      } else {
        let veiculosMapa: SMMapa[] = responseSm.data.filter((d: any) =>
          veiculos.find((v: any) => d.placas.includes(v.placaVeiculo))
        );
        setOptions(veiculosMapa);
        let array = veiculosMapa.map((each: SMMapa) => {
          let atraso = false;
          each.listMonitoramentoSmMapaPontoVO.forEach((ponto, index) => {
            const status = handleIsAtrasada(ponto, index);
            if (status?.includes('Atrasada')) {
              atraso = true;
              each.statusSm = 'A';
            }
            if (status?.includes('No horário')) {
              atraso = false;
              each.statusSm = 'B';
            }
          });
          return {
            ...each,
            statusAtraso: atraso,
          };
        });
        setSm(array);
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [atrasados, usuarioId]);

  useEffect(() => {
    if (smFiltrada.length && !filter) {
      setSm(smFiltrada);
    }
  }, [smFiltrada.length, filter]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!parametroLogistico) {
      getServicosContratados();
      return;
    }
    if (!sm.length) {
      getMapaData();
    }
    if (parametroLogistico.tempoAtualizacaoMapaEmMinutos && sm.length) {
      const milisegundosParaAtualizacao =
        parametroLogistico.tempoAtualizacaoMapaEmMinutos * 1000 * 60;
      timer = setTimeout(() => {
        setIsDialogAtualizandoOK(true);
        getMapaData();
      }, milisegundosParaAtualizacao);
      setIsDialogAtualizandoOK(false);
    }
    return () => clearTimeout(timer);
  }, [parametroLogistico, sm]);

  useEffect(() => {
    if (searchTerm.length) {
      search();
    } else {
      setFiltrado(sm);
    }
  }, [searchTerm, sm]);

  const search = () => {
    let filtered: any[] = [];
    if (!sm.length) {
      return;
    }
    if (filtroBusca === 'Todos' || searchTerm.trim() === '') {
      filtered = sm.filter(
        (each: any) =>
          each.placas?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (each.dtHrUltimaPosicao &&
            obterDataHora(each.dtHrUltimaPosicao, true)?.includes(searchTerm)) ||
          each.statusViagem?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.bau?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.nomeTecnologiaRastreamento?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.nomeTecnologia?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.operacao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          (usuarioVO.isEmbarcador
            ? each.nomeTransportador?.toLowerCase().includes(searchTerm.toLowerCase())
            : each.razaoSocialEmbarcador?.toLowerCase().includes(searchTerm.toLowerCase())) ||
          each.localizacao?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.rota?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Placa') {
      filtered = filtrado.filter((each: SMMapa) =>
        each.placas?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Posição') {
      filtered = filtrado.filter(
        (each: SMMapa) =>
          each.dtHrUltimaPosicao &&
          obterDataHora(each.dtHrUltimaPosicao, true)?.includes(searchTerm)
      );
    } else if (filtroBusca === 'Status') {
      filtered = filtrado.filter((each: SMMapa) =>
        each.statusViagem?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Baú') {
      filtered = filtrado.filter((each: SMMapa) =>
        each.bau?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Tecnologia') {
      filtered = filtrado.filter(
        (each: any) =>
          each.nomeTecnologiaRastreamento?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          each.nomeTecnologia?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Operação') {
      filtered = filtrado.filter((each: SMMapa) =>
        each.operacao?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Embarcador') {
      filtered = filtrado.filter((each: SMMapa) =>
        each.razaoSocialEmbarcador?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Transportador') {
      filtered = filtrado.filter((each: SMMapa) =>
        each.nomeTransportador?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Localização') {
      filtered = filtrado.filter((each: SMMapa) =>
        each.localizacao?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else if (filtroBusca === 'Rota') {
      filtered = filtrado.filter((each: SMMapa) =>
        each.rota?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFiltrado(filtered);
  };

  useEffect(() => {
    let positionsTruck: PositionTruck[] = [];

    sm.map((sm) => {
      if (!sm.latitudeAtual || !sm.longitudeAtual) {
        return;
      }
      const proxPonto = dataProgressao
        .filter((fil) => fil.idSm === sm.idSm)
        .map((each) => each.nomProxPonto);

      const truckLocation = {
        lat: sm.latitudeAtual,
        lng: sm.longitudeAtual,
        isTruck: true,
        idSm: sm.idSm,
        inicio: sm.dtInicioRealSm,
        transportador: sm.nomeTransportador,
        statusIgnicao: true,
        proximoPonto: proxPonto[0],
        placas: sm.placas,
      };
      positionsTruck.push(truckLocation);
    });

    setPostitionsTruck(positionsTruck);
  }, [sm]);

  const handleClickSm = (each: SMMapa) => {
    window.open(
      `/finalizar-sm-emandamento/${each.idSm}`,
      'janela',
      'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
    );
  };

  function renderAtrasado(each: SMMapa) {
    return (
      <button
        onClick={() => clickGoToMap(each.latitudeAtual, each.longitudeAtual)}
        title={each.latitudeAtual ? `${each.latitudeAtual}, ${each.longitudeAtual}` : ''}
        style={{
          backgroundColor: 'transparent',
          borderColor: 'transparent',
        }}
      >
        <IoRadioButtonOnOutline size={18} color={vehicleStatusTravel(each)} />
      </button>
    );
  }

  const positionOnClick = {
    lat: clickLatitude,
    lng: clickLongitude,
  };

  const clickGoToMap = (latitude: number, longitude: number) => {
    setGoToMap(true);
    setClickLatitude(latitude);
    setclickLongitude(longitude);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    if (map) {
      setCenter(positionOnClick);
      map.setZoom(14);
    }
  };

  const renderEmbarcadorTransportador = (data: any) => {
    if (usuarioVO.isEmbarcador) {
      return !data.nomeTransportador
        ? ''
        : data.nomeTransportador.length > 16
        ? `${data.nomeTransportador.slice(0, 16)}...`
        : data.nomeTransportador;
    } else {
      return !data.razaoSocialEmbarcador
        ? ''
        : data.razaoSocialEmbarcador.length > 16
        ? `${data.razaoSocialEmbarcador.slice(0, 16)}...`
        : data.razaoSocialEmbarcador;
    }
  };

  const calcularDistanciaPercorrida = (data: ListMonitoramentoSmMapaPontoVO[]) => {
    const distanciaPercorrida = data.reduce((acc: number, curr: ListMonitoramentoSmMapaPontoVO) => {
      if (curr.distancia) {
        const distancia = Math.round(Number(soNumeros(curr.distancia.split('.')[0])));
        return acc + distancia;
      } else {
        return acc;
      }
    }, 0);
    if (distanciaPercorrida > 0) {
      return `${distanciaPercorrida} km`;
    }
    return '';
  };

  const handleFilter = () => {
    setFilter(false);
  };

  const isSameOrBefore = (date1: string, date2: string) => {
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
    return isBefore(firstDate, secondDate) || isEqual(firstDate, secondDate);
  };

  const handleIsAtrasada = (sm: ListMonitoramentoSmMapaPontoVO, index: number) => {
    if (index === 0) {
      if (!sm.dtHrIncioRealSm || !sm.dtHrPlanejadaViagemPonto) {
        return null;
      }
      if (isSameOrBefore(sm.dtHrIncioRealSm, sm.dtHrPlanejadaViagemPonto)) {
        return ' (No horário)';
      }
      return ' (Atrasada)';
    }
    if (index > 0) {
      if (!sm.dtHrChegada || !sm.dtHrPlanejadaViagemPonto) {
        return null;
      }
      if (isSameOrBefore(sm.dtHrChegada, sm.dtHrPlanejadaViagemPonto)) {
        return ' (No horário)';
      }
      return ' (Atrasada)';
    }
  };

  // modifying the original list of vehicles to add the statusSm property
  matchSmId(filtrado, smActive);

  return (
    <Tela nome="Mapa" caminho="Rastreamento > Mapa">
      <Dialog
        header="Atualizando..."
        visible={isDialogAtualizandoOK}
        style={{ width: '20vw' }}
        modal
        onHide={() => {
          setIsDialogAtualizandoOK(false);
        }}
      />

      {filter ? (
        <MapaFilter
          data={options}
          setSmFiltrada={setSmFiltrada}
          goToMap={handleFilter}
          loading={loading}
          veiculosMapa={veiculosMapa}
        />
      ) : (
        <>
          {(!usuarioVO.isEmbarcador || location.pathname === '/mapa-operacao') && (
            <div className="return-btn">
              <Button
                color="white"
                onClick={() => {
                  setFilter(true);
                }}
                size={12}
              >
                Voltar
              </Button>
            </div>
          )}
          {sm && (
            <GoogleMaps
              map={map!}
              setMap={setMap}
              selectedMarker={selectedMarker!}
              setSelectedMarker={setSelectedMarker}
              positions={positionsTruck}
              raio={parametroLogistico?.raioDeAproximacaoVeiculosEmMetros || undefined}
              goToMap={goToMap}
              goLatitude={clickLatitude}
              goLongitude={clickLongitude}
              center={center!}
              setCenter={setCenter}
            />
          )}

          <div
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Form.Group className="row-modal" style={{ maxWidth: '160px', marginRight: 20 }}>
              <Form.Control
                id="viagemEscolta"
                name="viagemEscolta"
                placeholder=""
                onChange={(e) => {
                  setFiltroBusca(e.target.value);
                }}
                as="select"
                disabled={!sm}
              >
                <option value="Todos">Todos</option>
                <option value="Placa">Placa</option>
                <option value="Posição">Posição</option>
                <option value="Status">Status</option>
                <option value="Baú">Baú</option>
                <option value="Tecnologia">Tecnologia</option>
                <option value="Operação">Operação</option>
                <option value={usuarioVO.isEmbarcador ? 'Transportador' : 'Embarcador'}>
                  {usuarioVO.isEmbarcador ? 'Transportador' : 'Embarcador'}
                </option>
                <option value="Localização">Localização</option>
                <option value="Rota">Rota</option>
              </Form.Control>
            </Form.Group>
            <span className="p-input-icon-left">
              <input
                type="text"
                placeholder={filtroBusca}
                className="p-inputtext p-component"
                id="search-input"
                style={{ width: '340px', marginBottom: '17px', height: '38px' }}
                value={searchTerm}
                disabled={loading}
                onChange={(e: any) => setSearchTerm(e.target.value)}
              />
              <i className="pi pi-search" style={{ marginTop: '-16px' }}></i>
            </span>
          </div>

          <Accordion>
            <table
              className="table"
              style={{ display: 'block', overflowX: 'auto', whiteSpace: 'nowrap', height: 420 }}
            >
              <thead className="thead">
                <TableRow isDialogAtualizandoOK={isDialogAtualizandoOK}>
                  <TableHead></TableHead>
                  <TableHead></TableHead>
                  <TableHead>Placa</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Tecnologia</TableHead>
                  <TableHead>Posição</TableHead>
                  <TableHead>{usuarioVO.isEmbarcador ? 'Transportador' : 'Embarcador'}</TableHead>
                  <TableHead>Localização</TableHead>
                  <TableHead>Baú</TableHead>
                  <TableHead>Ignição</TableHead>
                  {flagControles.velocidade ? <TableHead>km/h</TableHead> : null}
                  <TableHead title="KM Percorrido">KM Per...</TableHead>
                  {flagControles.temperatura ? <TableHead>ºC</TableHead> : null}
                  <TableHead>Oper</TableHead>
                  <TableHead>Rota</TableHead>
                  {flagProgressaoViagem === 1 ? <TableHead>Progressão</TableHead> : null}
                  <TableHead>SM</TableHead>
                </TableRow>
              </thead>
              <tbody style={{ overflowY: 'auto' }}>
                {filtrado.map((each, index) => {
                  return (
                    <>
                      <tr key={index}>
                        <TableData>
                          {each.idSm &&
                          each.listMonitoramentoSmMapaPontoVO.length &&
                          flagProgressaoViagem === 1 &&
                          each.possuiRotaOuPonto === 'PONTO' ? (
                            <>
                              <Accordion.Toggle
                                style={{
                                  backgroundColor: 'transparent',
                                  borderColor: 'transparent',
                                  padding: 0,
                                }}
                                eventKey={each.idSm.toString()}
                              >
                                <IoAddCircleOutline
                                  size={18}
                                  color="black"
                                  title="Visualizar Pontos"
                                />
                              </Accordion.Toggle>
                            </>
                          ) : null}
                        </TableData>
                        <TableData>
                          {each.statusViagem === '' || each.statusViagem === 'Não iniciada' ? (
                            <button
                              onClick={() => clickGoToMap(each.latitudeAtual, each.longitudeAtual)}
                              title={`${each.latitudeAtual}, ${each.longitudeAtual}`}
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                padding: 0,
                              }}
                            >
                              <IoRadioButtonOnOutline size={18} color="#9F9F9F" />
                            </button>
                          ) : (
                            renderAtrasado(each)
                          )}
                        </TableData>
                        {/* <TableData style={{ fontSize: '13px' }}>{each.idSm}</TableData> */}
                        <TableData title={each.placas}>
                          {each.placas.length > 9 ? `${each.placas.slice(0, 7)}...` : each.placas}
                        </TableData>

                        <TableData>{each.statusViagem}</TableData>
                        <TableData>{each.nomeTecnologiaRastreamento || each.tecnologia}</TableData>
                        <TableData>{obterDataHora(each.dtHrUltimaPosicao, true)}</TableData>
                        <TableData
                          title={
                            usuarioVO.isEmbarcador
                              ? each.nomeTransportador
                              : each.razaoSocialEmbarcador
                          }
                        >
                          {renderEmbarcadorTransportador(each)}
                        </TableData>
                        <TableData style={{ textAlign: 'left' }} title={each.localizacao}>
                          {each.localizacao === null
                            ? ''
                            : each.localizacao.length > 35
                            ? `${each.localizacao.slice(0, 35)}...`
                            : each.localizacao}
                        </TableData>
                        <TableData>
                          {each.bau === null || each.bau === 'N/I' ? 'N/I' : each.bau}
                        </TableData>
                        <TableData>
                          {each.ignicao === null || each.ignicao === 'N/I' ? 'N/I' : each.ignicao}
                        </TableData>
                        {flagControles.velocidade ? (
                          <TableData style={{ textAlign: 'center' }}>
                            {each.velocidade || '-'}
                          </TableData>
                        ) : null}
                        <TableData>
                          {calcularDistanciaPercorrida(each.listMonitoramentoSmMapaPontoVO)}
                        </TableData>
                        {flagControles.temperatura ? (
                          <TableData style={{ textAlign: 'center' }}>
                            {each.temperatura || '-'}
                          </TableData>
                        ) : null}

                        <TableData>{each.operacao}</TableData>

                        <TableData
                          style={{ textAlign: 'left' }}
                          title={handleRota(each.listMonitoramentoSmMapaPontoVO, each.rota)}
                        >
                          {each.rota === null
                            ? ''
                            : handleRota(each.listMonitoramentoSmMapaPontoVO, each.rota).length >
                                25 && each.idSm
                            ? `${handleRota(each.listMonitoramentoSmMapaPontoVO, each.rota).slice(
                                0,
                                25
                              )}...`
                            : handleRota(each.listMonitoramentoSmMapaPontoVO, each.rota)}
                        </TableData>
                        {flagProgressaoViagem === 1 ? (
                          <TableData>
                            <PercentageDiv
                              color={vehicleStatusTravel(each)}
                              percentage={Math.round(Number(each.progressao))}
                            >
                              <div className="percentage-wrapper">
                                {each.statusViagem === '' ||
                                each.statusViagem === 'Não iniciada' ? (
                                  <></>
                                ) : (
                                  <div className="text-div">
                                    <p>
                                      {each.progressao ? Math.round(Number(each.progressao)) : 0}%
                                    </p>
                                  </div>
                                )}
                              </div>
                            </PercentageDiv>
                          </TableData>
                        ) : (
                          <></>
                        )}
                        <TableData>
                          {each.idSm ? (
                            <Button
                              tooltip={`Id: ${each.idSm}\nVisualizar SM`}
                              tooltipOptions={{ position: 'bottom' }}
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                padding: 0,
                                paddingRight: 5,
                                marginBottom: 2,
                              }}
                              onClick={() => handleClickSm(each)}
                            >
                              <IoMailUnread size={18} color="#9F9F9F" />
                            </Button>
                          ) : null}
                        </TableData>
                      </tr>
                      <tr>
                        <td colSpan={16} style={{ padding: '0px', borderBottomColor: 'white' }}>
                          {each.listMonitoramentoSmMapaPontoVO.map((parada, i) => {
                            const deliveryTime = handleIsAtrasada(parada, i);
                            return (
                              <Accordion.Collapse eventKey={parada.idSm.toString()} key={i}>
                                <div
                                  style={{
                                    marginLeft: 20,
                                    width: '100%',
                                    display: 'flex',
                                    flex: 1,
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span>{parada.ponto} </span>
                                  <ul>
                                    {parada.remessa === '' ? null : (
                                      <li>Remessa: {parada.remessa}</li>
                                    )}
                                    {i > 0 && parada.distancia && (
                                      <li>
                                        Distância:{' '}
                                        {parada.distancia === ' Km' ? null : parada.distancia}
                                      </li>
                                    )}
                                    {parada.dtHrPlanejadaViagemPonto ? (
                                      <li>
                                        Agendamento:{' '}
                                        {obterDataHora(parada.dtHrPlanejadaViagemPonto, true)}{' '}
                                        {i === 0 && parada.dtHrIncioRealSm
                                          ? `Saída: ${obterDataHora(parada.dtHrIncioRealSm, true)}`
                                          : i > 0 && parada.dtHrChegada
                                          ? `Chegada: ${obterDataHora(parada.dtHrChegada, true)}`
                                          : null}
                                        {handleIsAtrasada(parada, i)}
                                      </li>
                                    ) : null}
                                    {(deliveryTime && deliveryTime === ' (Atrasada)') ||
                                    deliveryTime === ' (No horário)' ? null : (
                                      <>
                                        {each.statusInicializacao ? (
                                          <>
                                            {parada.dtHrPrevisao === null ? null : (
                                              <li>
                                                Previsão: {obterDataHora(parada.dtHrPrevisao, true)}{' '}
                                              </li>
                                            )}

                                            {parada.dtHrPrevisaoComParadas === null ? null : (
                                              <li>
                                                Previsão c/ Paradas:{' '}
                                                {obterDataHora(parada.dtHrPrevisaoComParadas, true)}
                                              </li>
                                            )}
                                          </>
                                        ) : null}
                                      </>
                                    )}
                                  </ul>
                                </div>
                              </Accordion.Collapse>
                            );
                          })}
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </Accordion>
        </>
      )}
      {!loading && message && (
        <div style={{ margin: '10px auto' }}>
          <strong>{message}</strong>
        </div>
      )}
    </Tela>
  );
};

export default Mapa;
