/* eslint-disable */
import axios from 'axios';

export const handleListVehicleControl = async ({
  dataInicial,
  dataFim,
  idCliente,
  tipoControle,
  veiculos,
}: {
  dataInicial: string;
  dataFim: string;
  veiculos: string | null;
  idCliente: any;
  tipoControle: string;
}): Promise<any> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehicle-control-accident/`, {
      params: {
        dataInicial,
        dataFim,
        idCliente,
        tipoControle,
        veiculos,
      },
    });
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data.message;
    }
    return error;
  }
};
