/* eslint-disable */
import axios from 'axios';
import { ActiveSM } from '../types';

interface SMCreatePointsProps {
  sm: number;
}

export const handleCreatePointsSM = async (data: unknown): Promise<SMCreatePointsProps> => {
  try {
    const response = await axios.post<SMCreatePointsProps>(
      `${process.env.REACT_APP_API_URL}/create-points-sm/`,

      data
    );

    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data.message;
    }
    return error;
  }
};

export const handleGenerateMatrixSMPrevision = async (smId: number) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/generate-matrix-previsions-sm/?sm=${smId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data.message;
    }
    return error;
  }
};

export const handleListActiveSM = async (idList: number[]): Promise<ActiveSM[]> => {
  try {
    const response = await axios.get<ActiveSM[]>(
      `${process.env.REACT_APP_API_URL}/status-sm/?sm=${idList}`
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data.message;
    }
    return error;
  }
};
