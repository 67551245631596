/* eslint-disable */
import { Button } from 'primereact/button';
import { FC, useEffect, useState } from 'react';
import { Col, Form, Spinner } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import instanceMaquina from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { Dropdown } from 'primereact/dropdown';
import './styles.css';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import GeneratePDF from './generatePDF';
import { Tela } from '../../components/Tela';
import { obterData, obterDataHora, obterHora, toZoned } from '../../util/date';
import { isAfter, format } from 'date-fns';

type DataType = {
  ajudante1: string;
  ajudante2: string;
  condutor1: string;
  condutor2: string;
  dtFim: string;
  dtFimReal: string;
  dtHrAgendamento: string;
  dtHrChegada: string;
  dtHrPrevisaoSistema: string;
  dtHrSaida: string;
  dtInicio: string;
  dtInicioReal: string;
  embarcador: string;
  hrFim: string;
  hrFimReal: string;
  hrInicio: string;
  hrInicioReal: string;
  idSm: number;
  idTransportador: number;
  nuRemessa: string;
  numeroBau: string | number;
  placaCarreta1: string;
  placaCarreta2: string;
  placaVeiculo: string;
  placas: string;
  ponto: string;
  seqPonto: number;
  statusPonto: number;
  statusponto: string;
  transportador: string;
  ufPonto: string;
};

type RemessaType = {
  remessa: string;
}[];

type ExcelGeneration = {
  ySteps?: number;
  columns: {
    title: string;
    style?: any;
    width?: {
      wpx?: number;
    };
  }[];

  data: { value: any }[][];
};

const AcompanhamentoRemessa: FC = () => {
  const { clienteRazaoSocial, clienteId } = getCookieSessionData().usuarioVO;
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[]>([]);
  const [data, setData] = useState<DataType[]>();
  const [remessa, setRemessa] = useState();
  const [remessaArr, setRemessaArr] = useState<RemessaType[]>();
  const [choosenStartDate, setChoosenStartDate] = useState<string | undefined>();
  const [choosenEndDate, setChoosenEndDate] = useState<string | undefined>();
  const history = useHistory();
  const { page }: any = useParams();
  const [itensPerPage, setItensPerPage] = useState('10');
  const [loading, setLoading] = useState(false);

  const paginate = (pageNumber: number) => {
    history.push(`/consultas-relatorios/acompanhamento-remessas/${pageNumber}`);
  };

  const limpaObjeto = (value: any) => {
    if (value.remessa != '' || value.remessa === null || value.remessa === undefined) {
      return value;
    }
  };

  const periodo = isAfter(new Date(String(choosenEndDate)), new Date(String(choosenStartDate)))
    ? `Período: ${obterData(choosenStartDate)} à ${obterData(choosenEndDate)}`
    : 'Período: ';

  async function teste() {
    const response = await instanceMaquina.get(
      `/solicitacao-monitoramento-plano-viagem/lista-remessas?keyword=`
    );
    setRemessaArr(response.data.filter(limpaObjeto));
  }

  useEffect(() => {
    teste();
  }, [remessa]);

  const dateNow = () => {
    const now = new Date();
    const dateNow = new Date(now.getTime());
    const dateNowFormated =
      dateNow.getFullYear().toString() +
      '-' +
      (dateNow.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      dateNow.getDate().toString().padStart(2, '0');
    return dateNowFormated;
  };

  const onRemessaChange = (e: { value: any }) => {
    setRemessa(e.value);
  };

  function separar(base: any, max: any) {
    const pages = Math.ceil(base.length / max);
    let arr: any[] = [];
    for (let i = 0; i < pages; i++) {
      const start = i * max;
      const end = start + max;
      const data = base.slice(start, end);
      arr = [...arr, data];
    }
    return arr;
  }

  const renderDateTime = (date: string, timeOrDate?: string) => {
    if (!date) {
      return '';
    }
    if (timeOrDate === 'time') {
      return obterHora(date);
    }
    if (timeOrDate === 'date') {
      return obterData(date);
    }
    return obterDataHora(date);
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    const fetchData = async () => {
      setLoading(true);
      const data = {
        idCliente: clienteId,
        dataInicial: choosenStartDate,
        dataFinal: choosenEndDate,
        remessa: remessa,
      };
      try {
        const response = await instanceMaquina.get(`solicitacao-monitoramento/relatorio-remessa`, {
          params: data,
        });
        setData(orderBy(response.data, ['idSm', 'seqponto'], ['desc']));
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  };

  const getUfByEstado = (estado: string) => {
    switch (estado) {
      case 'Acre':
        return 'AC';
      case 'Alagoas':
        return 'AL';
      case 'Amapá':
        return 'AP';
      case 'Amazonas':
        return 'AM';
      case 'Bahia':
        return 'BA';
      case 'Ceará':
        return 'CE';
      case 'Distrito Federal':
        return 'DF';
      case 'Espírito Santo':
        return 'ES';
      case 'Goiás':
        return 'GO';
      case 'Maranhão':
        return 'MA';
      case 'Mato Grosso':
        return 'MT';
      case 'Mato Grosso do Sul':
        return 'MS';
      case 'Minas Gerais':
        return 'MG';
      case 'Pará':
        return 'PA';
      case 'Paraíba':
        return 'PB';
      case 'Paraná':
        return 'PR';
      case 'Pernambuco':
        return 'PE';
      case 'Piauí':
        return 'PI';
      case 'Rio de Janeiro':
        return 'RJ';
      case 'Rio Grande do Norte':
        return 'RN';
      case 'Rio Grande do Sul':
        return 'RS';
      case 'Rondônia':
        return 'RO';
      case 'Roraima':
        return 'RR';
      case 'Santa Catarina':
        return 'SC';
      case 'São Paulo':
        return 'SP';
      case 'Sergipe':
        return 'SE';
      case 'Tocantins':
        return 'TO';
      default:
        return '//';
    }
  };

  const renderPonto = (ponto: string, seq: number) => {
    if (!ponto && !seq) {
      return '';
    }
    return `${seq} - ${ponto}`;
  };

  const dataStyles = (size: string) => {
    return {
      font: { sz: size },
      alignment: { vertical: 'top' },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
    };
  };

  const titleStyle = () => {
    return {
      fill: {
        fgColor: { rgb: 'CCCCCC' },
      },
      font: { sz: '11' },
      alignment: { wrapText: false },
      border: {
        top: { style: 'thin', color: { rgb: '000000' } },
        bottom: { style: 'thin', color: { rgb: '000000' } },
        left: { style: 'thin', color: { rgb: '000000' } },
        right: { style: 'thin', color: { rgb: '000000' } },
      },
    };
  };

  useEffect(() => {
    if (!data?.length) {
      return;
    }

    setExcelGeneration([
      {
        columns: [
          {
            title: 'Acompanhamento de Remessas',
            style: {
              font: { sz: '26', bold: true },
              alignment: { wrapText: false },
            },
            width: { wpx: 10 },
          },
        ],
        data: [],
      },
      {
        columns: [
          {
            title: `Embarcador: ${clienteRazaoSocial}`,
            style: {
              font: { sz: '12' },
              alignment: { wrapText: false },
            },
            width: { wpx: 400 },
          },
        ],
        data: [],
      },
      {
        columns: [
          {
            title:
              choosenStartDate && choosenEndDate
                ? `Período: ${obterData(choosenStartDate)} à ${obterData(choosenEndDate)}`
                : 'Período: ',
            style: {
              font: { sz: '11' },
              alignment: { wrapText: false },
            },
            width: { wpx: 400 },
          },
        ],
        data: [],
      },
      {
        ySteps: 0,

        columns: [
          { title: 'Ponto', style: titleStyle(), width: { wpx: 180 } },
          { title: 'Nº Remessa', style: titleStyle(), width: { wpx: 200 } },
          { title: 'Agendamento/Data', style: titleStyle(), width: { wpx: 180 } },
          { title: 'Agendamento/Hora', style: titleStyle(), width: { wpx: 180 } },
          { title: 'Previsão Sistema/Data', style: titleStyle(), width: { wpx: 200 } },
          { title: 'Previsão Sistema/Hora', style: titleStyle(), width: { wpx: 200 } },
          { title: 'Chegada/Data', style: titleStyle(), width: { wpx: 80 } },
          { title: 'Chegada/Hora', style: titleStyle(), width: { wpx: 80 } },
          { title: 'Saída/Data', style: titleStyle(), width: { wpx: 80 } },
          { title: 'Saída/Hora', style: titleStyle(), width: { wpx: 80 } },
          { title: 'Status', style: titleStyle(), width: { wpx: 100 } },
          { title: 'Transportador', style: titleStyle(), width: { wpx: 300 } },
          { title: 'Veículo', style: titleStyle(), width: { wpx: 200 } },
          { title: 'Carreta(1)', style: titleStyle(), width: { wpx: 200 } },
          { title: 'Carreta(2)', style: titleStyle(), width: { wpx: 200 } },
          { title: 'Baú', style: titleStyle(), width: { wpx: 200 } },
          { title: 'S.M.', style: titleStyle(), width: { wpx: 80 } },
          { title: 'Condutor(1).', style: titleStyle(), width: { wpx: 250 } },
          { title: 'Condutor(2).', style: titleStyle(), width: { wpx: 250 } },
          { title: 'Ajudante(1).', style: titleStyle(), width: { wpx: 250 } },
          { title: 'Ajudante(2).', style: titleStyle(), width: { wpx: 250 } },
          { title: 'UF Ponto.', style: titleStyle(), width: { wpx: 80 } },
        ],
        data: data?.map((each) => [
          { value: renderPonto(each.ponto, each.seqPonto) || '//', style: dataStyles('11') },
          { value: each.nuRemessa || '//', style: dataStyles('11') },
          { value: renderDateTime(each.dtHrAgendamento, 'date') || '//', style: dataStyles('11') },
          { value: renderDateTime(each.dtHrAgendamento, 'time') || '//', style: dataStyles('11') },
          {
            value: renderDateTime(each.dtHrPrevisaoSistema, 'date') || '//',
            style: dataStyles('11'),
          },
          {
            value: renderDateTime(each.dtHrPrevisaoSistema, 'time') || '//',
            style: dataStyles('11'),
          },
          {
            value: renderDateTime(each.dtHrChegada, 'date') || '//',
            style: dataStyles('11'),
          },
          {
            value: renderDateTime(each.dtHrChegada, 'time') || '//',
            style: dataStyles('11'),
          },
          {
            value: renderDateTime(each.dtHrSaida, 'date') || '//',
            style: dataStyles('11'),
          },
          {
            value: renderDateTime(each.dtHrSaida, 'time') || '//',
            style: dataStyles('11'),
          },
          { value: each.statusPonto || '//', style: dataStyles('11') },
          { value: each.transportador || '//', style: dataStyles('11') },
          { value: each.placaVeiculo || '//', style: dataStyles('11') },
          { value: each.placaCarreta1 || '//', style: dataStyles('11') },
          { value: each.placaCarreta2 || '//', style: dataStyles('11') },
          { value: each.numeroBau || '//', style: dataStyles('11') },
          { value: (each.idSm && each.idSm.toString()) || '//', style: dataStyles('11') },
          { value: each.condutor1 || '//', style: dataStyles('11') },
          { value: each.condutor2 || '//', style: dataStyles('11') },
          { value: each.ajudante1 || '//', style: dataStyles('11') },
          { value: each.ajudante2 || '//', style: dataStyles('11') },
          { value: getUfByEstado(each.ufPonto) || '//', style: dataStyles('11') },
        ]),
      },
    ]);
  }, [data]);

  return (
    <Tela
      nome="Acompanhamento de Remessas"
      caminho="Operacional > Consultas e relatórios > Acompanhamento de Remessas"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={data ? data.length : 0}
      paginate={paginate}
      currentPage={page}
      excelGeneration={excelGeneration}
      filename="Acompanhamento de Remessas"
      pdf
      onClickPdf={() => {
        // Objeto para armazenar os grupos
        const grupos: any = {};

        // Percorre o array e agrupa os objetos pelo idSm
        data?.forEach((obj: any) => {
          const idSm = obj.idSm;
          if (grupos[idSm]) {
            grupos[idSm].push(obj);
          } else {
            grupos[idSm] = [obj];
          }
        });

        // Agora você tem os objetos divididos em grupos pelo idSm
        const sms = Object.values(grupos).map((sm: any) => {
          return {
            transportador: sm[0].transportador,
            sm: sm[0].idSm,
            placas: sm[0].placas,
            inicioViagem: `${format(new Date(sm[0].dtInicio), 'dd/MM/yyyy')} ${format(
              new Date(sm[0].hrInicio),
              'HH:mm'
            )}`,
            data: sm.map((ponto: any, index: any) => [
              //#
              index + 1,
              // PONTO
              `Ponto ${ponto.seqPonto}`,
              // Nº Remessa
              ponto.nuRemessa,

              // Agendamento - DATA
              format(new Date(ponto.dtHrAgendamento), 'dd/MM/yyyy'),
              // Agendamento - HORA
              format(new Date(ponto.dtHrAgendamento), 'HH:mm'),

              // Previsão Sistema - DATA
              ponto.dtHrPrevisaoSistema
                ? format(new Date(ponto.dtHrPrevisaoSistema), 'dd/MM/yyyy')
                : '',
              // Previsão Sistema - HORA
              ponto.dtHrPrevisaoSistema ? format(new Date(ponto.dtHrPrevisaoSistema), 'HH:mm') : '',
              // Chegada - DATA
              ponto.dtHrChegada ? format(new Date(ponto.dtHrChegada), 'dd/MM/yyyy') : '',
              // Chegada - HORA
              ponto.dtHrChegada ? format(new Date(ponto.dtHrChegada), 'HH:mm') : '',
              // Saída - DATA
              ponto.dtHrSaida ? format(new Date(ponto.dtHrSaida), 'dd/MM/yyyy') : '',
              // Saída - HORA
              ponto.dtHrSaida ? format(new Date(ponto.dtHrSaida), 'HH:mm') : '',
              ponto.statusPonto,
            ]),
          };
        });

        const result = {
          embarcador: Object.values<any>(grupos)[0][0].embarcador,
          periodo,
          sms: sms,
        };

        return GeneratePDF(result, periodo);
      }}
      disabledExport={!data}
    >
      <div style={{ overflow: 'hidden' }}>
        <Form onSubmit={onSubmit}>
          <Form.Row>
            <div style={{ display: 'flex', alignItems: 'end' }}>
              <Form.Group as={Col}>
                <Form.Label className="requiredField">Data Início</Form.Label>
                <Form.Control
                  onChange={(event: any) => setChoosenStartDate(event.target.value)}
                  id="dateStart"
                  name="dateStart"
                  type="date"
                  max={dateNow()}
                />
              </Form.Group>

              <Form.Group as={Col}>
                <Form.Label className="requiredField">Data Término</Form.Label>
                <Form.Control
                  onChange={(event: any) => setChoosenEndDate(event.target.value)}
                  id="dateEnd"
                  name="dateEnd"
                  type="date"
                  disabled={!choosenStartDate}
                  min={choosenStartDate}
                  max={dateNow()}
                />
              </Form.Group>
              <Form.Group as={Col}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Form.Label>Nº Remessa</Form.Label>
                  <Dropdown
                    value={remessa}
                    options={uniqBy(remessaArr, 'remessa')}
                    onChange={onRemessaChange}
                    optionValue="remessa"
                    optionLabel="remessa"
                    filter
                    showClear
                    filterBy="remessa"
                    placeholder="Nº Remessa"
                  />
                </div>
              </Form.Group>
              <Form.Group as={Col} md={2}>
                <Button
                  className="btn-enviar"
                  type="submit"
                  disabled={!choosenStartDate || !choosenEndDate}
                >
                  Filtrar
                </Button>
              </Form.Group>
            </div>
          </Form.Row>
        </Form>
      </div>
      <div className="table-responsive" style={{ flex: 1 }}>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <table className="table">
            <thead className="thead">
              <tr>
                <th>S.M.</th>
                <th>Ponto</th>
                <th>Nº Remessa</th>
                <th>Agendamento</th>
                <th>Previsão</th>
                <th>Chegada</th>
                <th>Saída</th>
                <th>Status</th>
                <th>Transportador</th>
              </tr>
            </thead>
            {!choosenStartDate || !choosenEndDate ? (
              <tr>
                <th
                  style={{
                    textAlign: 'center',
                    marginTop: 25,
                    color: 'red',
                  }}
                  colSpan={12}
                >
                  Selecione um intervalo de tempo para fazer a pesquisa.
                </th>
              </tr>
            ) : !data?.length ? (
              <tr>
                <th
                  style={{
                    textAlign: 'center',
                    color: 'black',
                    fontSize: 18,
                  }}
                  colSpan={12}
                >
                  Não há dados com os filtros selecionados
                </th>
              </tr>
            ) : (
              <tbody>
                {data &&
                  data
                    .slice(
                      parseInt(itensPerPage, 10) * page - parseInt(itensPerPage, 10),
                      parseInt(itensPerPage, 10) * page
                    )
                    .map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.idSm}</td>
                          <td>
                            {data.seqPonto} - {data.ponto}
                          </td>
                          <td>{data.nuRemessa}</td>
                          <td>{renderDateTime(data.dtHrAgendamento)}</td>
                          <td>{renderDateTime(data.dtHrPrevisaoSistema)}</td>
                          <td>{renderDateTime(data.dtHrChegada)}</td>
                          <td>{renderDateTime(data.dtHrSaida)}</td>
                          <td>{data.statusPonto}</td>
                          <td>{data.transportador}</td>
                        </tr>
                      );
                    })}
              </tbody>
            )}
          </table>
        )}
      </div>
    </Tela>
  );
};

export default AcompanhamentoRemessa;
