/* eslint-disable */
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import axios from '../../../config/axiosMaquina';
import { UsuariosType, CorretorasType } from '../../../api/model/UsuariosType';
import { getCookieSessionData } from '../../../services/cookieService';
import { Tela } from '../../../components/Tela';
import Search from '../../../components/Search/Search';
import { Spinner } from '../../../components/Spinner';
import { ButtonTooltip } from '../../../components/ButtonTooltipo';
import { DefaultImage } from '../../../components/DefaultImageFilter';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

const ListagemUsuariosCorretoras = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [corretora, setCorretora] = useState<CorretorasType>();
  const [listaCorretoras, setListaCorretoras] = useState<CorretorasType[]>([]);
  const { page }: any = useParams();
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [data, setData] = useState<UsuariosType[]>([]);
  const [dataSearch, setDataSearch] = useState<UsuariosType[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const { corretoraId, role } = getCookieSessionData().usuarioVO;

  const paginate = (pageNumber: number) => {
    history.push(`/listar-usuarios-corretoras/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.USUARIO.CORRETORA] }], 'inserir');
    rolePermission([{ ADMIN: [ADMIN.USUARIO.CORRETORA] }], 'editar');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const fetchDataPlaca = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/corretora`);
        setListaCorretoras(response.data);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchDataPlaca();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (role === 'admin') {
        if (!corretora) return;
        setLoadingSearch(true);
        try {
          const response = await axios.get(`/usuario/corretora`, {
            params: {
              corretora,
            },
          });
          setData(response.data);
          setLoadingSearch(false);
          setSearchValue('');
        } catch (err: any) {
          console.log(err);
          setLoadingSearch(false);
        }
      } else {
        setLoadingSearch(true);
        try {
          const response = await axios.get(`/usuario/corretora`, {
            params: {
              corretora: corretoraId,
            },
          });
          setData(response.data);
          setSearchValue('');
          setLoadingSearch(false);
        } catch (err: any) {
          console.log(err);
          setLoadingSearch(false);
        }
      }
    };
    fetchData();
  }, [corretora]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      const s = searchValue.toLowerCase();
      const filteredData = data.filter(
        (each: UsuariosType) =>
          each.nome.toLowerCase().includes(s) ||
          each.login.toLowerCase().includes(s) ||
          each.corretoraId.toString().includes(s)
      );
      setDataSearch(filteredData);
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Usuários"
      caminho="Usuários > Usuários Corretora"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      {role === 'admin' && (
        <div className="header-associar-container">
          <div className="header-associar-field">
            <span>Corretora</span>
            <Dropdown
              value={corretora}
              options={listaCorretoras}
              onChange={(e) => setCorretora(e.value)}
              optionLabel="corTxNome"
              optionValue="corCdId"
              filter
              filterBy="corTxNome"
              placeholder="Selecione uma opção"
              className="p-dropdown"
              showClear
              style={{ width: '120%' }}
            />
          </div>
        </div>
      )}
      <Search
        setLoading={setLoadingSearch}
        setSearch={setSearchTerm}
        setSearchValue={setSearchValue}
        search={searchTerm}
        linkToAdd={`/cadastro-usuario-corretoras/${role === 'admin' ? corretora : corretoraId}`}
        createDisabled={!canInsert}
        buttonDisabled={!corretora && role === 'admin'}
        disabled={!corretora && role === 'admin'}
      />
      {loadingSearch ? (
        <Spinner />
      ) : (corretora || role === 'corretora') && currentData.length ? (
        <div className="table-responsive">
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Nome Usuário</th>
                <th>Código da Corretora</th>
                <th>Login</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {currentData &&
                currentData.map((each) => (
                  <tr key={each.corretoraId} className="dispositivos-table">
                    <td>{each.nome}</td>
                    <td>{each.corretoraId}</td>
                    <td>{each.login}</td>
                    <td>
                      <ButtonTooltip
                        type="Editar"
                        onClick={() => {
                          if (canEdit) {
                            history.push(
                              `/atualizacao-usuario-corretoras/${each.usuCdId}/${each.corretoraId}/${each.login}`
                            );
                          } else {
                            history.push('/acessonegado');
                          }
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      ) : (
        <DefaultImage />
      )}
    </Tela>
  );
};

export default ListagemUsuariosCorretoras;
