/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useHistory, useParams } from 'react-router-dom';
import './styles.css';
import Search from '../../components/Search/Search';
import instanceMaquina from '../../config/axiosMaquina';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Click } from '../../assets/Click';
import { BsArrowUp } from 'react-icons/bs';
import { DefaultImage } from '../../components/DefaultImageFilter';

type Data = {
  tecnologia: string;
  placa: string;
  numeroEquipamento: string | undefined;
};

const VeiculosRastreamento = () => {
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const [data, setData] = useState<Data[]>();
  const [tecnologia, setTecnologia] = useState<{ terCdId: number; terTxNome: string }>();
  const [filteredData, setFilteredData] = useState<Data[]>();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [tecnologiaArr, setTecnologiaArr] = useState<{ terCdId: number; terTxNome: string }[]>();
  const history = useHistory();
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();

  const paginate = (pageNumber: number) => {
    history.push(`/listar/rastreamento/${pageNumber}`);
  };

  useEffect(() => {
    const getTecnologias = async () => {
      setLoading(true);
      const response = await instanceMaquina.get('/tecnologia-rastreamento');
      const listaAtivos = response.data.filter((fil: any) => fil.terBlAtivo === true);
      setTecnologiaArr(listaAtivos);
      setLoading(false);
    };
    getTecnologias();
  }, []);

  useEffect(() => {
    if (!tecnologia?.terCdId) {
      return;
    } else {
      const getData = async () => {
        setLoading(true);
        const response = await instanceMaquina.get(
          `/veiculo/consulta-gerenciar-veiculo?idTecnologia=${tecnologia?.terCdId}`
        );
        setData(response.data);
        history.push(`/listar/rastreamento/1`);
        setLoading(false);
      };
      getData();
    }
  }, [tecnologia?.terCdId]);

  useEffect(() => {
    if (!data) {
      return;
    }
    if (searchValue) {
      setFilteredData(
        data.filter(
          (each) =>
            each.placa?.toLowerCase().includes(searchValue.toLowerCase()) ||
            (each.numeroEquipamento &&
              each.numeroEquipamento?.toString().toLowerCase().includes(searchValue.toLowerCase()))
        )
      );
    } else {
      setFilteredData(data);
    }
  }, [searchValue, data]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setExcelGeneration([
      {
        xSteps: 2,
        ySteps: 0,
        columns: [
          {
            title: 'Rastreamento de Veículos',
            widthPx: '100%',
            style: { font: { sz: '24', bold: true } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          { title: 'Tecnologia', style: { font: { bold: true } } },
          { title: 'Placa', style: { font: { bold: true } } },
          { title: 'Nº Equipamento', style: { font: { bold: true } } },
        ],
        data: data.map((each) => [
          { value: each.tecnologia },
          { value: each.placa },
          { value: each.numeroEquipamento === '' ? '' : each.numeroEquipamento },
        ]),
      },
    ]);
  }, [data]);

  return (
    <Tela
      nome="Rastreamento"
      caminho="Cadastro > Veículos > Rastreamento"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={data ? data.length : 0}
      paginate={paginate}
      currentPage={page}
      excelGeneration={excelGeneration}
      filename="Rastreamento"
    >
      <div className="header-associar-container">
        <div className="header-associar-field">
          <span>Tecnologia</span>
          <Dropdown
            value={tecnologia}
            options={tecnologiaArr}
            onChange={(e) => setTecnologia(e.value)}
            optionLabel="terTxNome"
            filter
            filterBy="terTxNome"
            placeholder="Tecnologia"
            className="p-dropdown"
          />
        </div>
      </div>
      <Search
        disabled={!tecnologia}
        setLoading={setLoading}
        setSearch={setSearch}
        setSearchValue={setSearchValue}
        search={search}
      />
      <div className="table-responsive" style={{ flex: 1 }}>
        {loading ? (
          <Spinner />
        ) : (
          <table className="table" id="table-to-xls">
            <thead className="thead">
              <tr>
                <th>Tecnologia</th>
                <th>Placa</th>
                <th>Nº Equipamento</th>
              </tr>
            </thead>
            {!tecnologia ? (
              <tr>
                <th colSpan={3}>
                  <DefaultImage />
                </th>
              </tr>
            ) : (
              <tbody>
                {filteredData &&
                  filteredData
                    .slice(
                      parseInt(itensPerPage, 10) * page - parseInt(itensPerPage, 10),
                      parseInt(itensPerPage, 10) * page
                    )
                    .map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.tecnologia}</td>
                          <td>{data.placa}</td>
                          <td>{data.numeroEquipamento}</td>
                        </tr>
                      );
                    })}
              </tbody>
            )}
          </table>
        )}
      </div>
    </Tela>
  );
};

export default VeiculosRastreamento;
