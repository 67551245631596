import React, { useState, useCallback, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import axiosRequest from 'axios';
import axios from '../../config/axiosMaquina';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { Tela } from '../../components/Tela';
import { pesquisar } from '../../util/format';
import { getCookieSessionData } from '../../services/cookieService';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

export const ConsultarPerfisUsuarios = () => {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const { page }: any = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const titles = ['+id', 'Perfil'];
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const { usuarioId, clienteId, role } = getCookieSessionData().usuarioVO;
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: number) => {
    history.push(`/perfis-usuarios/listar/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
          EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
          ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
        },
      ],
      'inserir'
    );
  }, [ADMIN, rolePermission, TRANSPORTADOR, EMBARCADOR]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axiosRequest.get(
        `${process.env.REACT_APP_API_URL}/profile-menu/?page=1&page_size=9999&idCliente=30`,
        {
          params: {
            page: 1,
            page_size: 9999,
            ...(clienteId && { idCliente: clienteId }),
          },
        }
      );

      const arr = res.data.results.map((each: any) => {
        return [each.mep_cd_id, each.mep_tx_nome_menu];
      });

      setData(arr);
      setLoading(false);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar-perfis-usuarios/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Perfis de Usuários"
      caminho="Usuários > Perfis de Usuários"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      linkToAdd={`/cadastro-perfis-usuarios/${clienteId}`}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/edicao-perfis-usuarios/${id}`)}
        />
      )}
    </Tela>
  );
};
