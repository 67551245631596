import { obterData, obterDataHora } from '../../util/date';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';

// Função para validar datas do problema que ocorreu no dia 21/11/2024
const isValidDate = (date: any): boolean => {
  if (!date) return false;
  const parsedDate = new Date(date);
  // eslint-disable-next-line no-restricted-globals
  return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
};

export const RelatorioXLSLancado = (state: any, setExcelGenerationUser: any) => {
  const total = state.listagem.length;

  const excel = [
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Checklist Lançados',
          widthPx: 3000,
          style: {
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: `Periodo: ${
            state.model.dataInicio ? obterData(state.model.dataInicio).toString() : '/ /'
          } e ${state.model.dataFim ? obterData(state.model.dataFim).toString() : '/ /'}`,
          width: { wpx: 2000 },
          style: { font: { sz: '12' }, alignment: { horizontal: 'center' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        { title: 'Checklist n°' },
        { title: 'Empresa' },
        { title: 'Placa' },
        { title: 'Descrição' },
        { title: 'Tipo Frota' },
        { title: 'Contrato' },
        { title: 'Data/Hora Realização' },
        { title: 'Status' },
        { title: 'Usuário' },
        { title: 'Embarcador' },
        { title: 'CD Origem' },
        { title: 'Data/Hora/Usuário Lançamento' },
      ],
      data: state.listagem.map((each: any) => [
        { value: each.idChecklist ?? '' },
        { value: each.nomeClienteTransportador ?? '' },
        { value: each.placaVeiculo ?? '' },
        { value: each.modeloCarreta || each.modeloVeiculo || each.modeloBau || '' },
        {
          value:
            getTipoFrota(each.tipoFrotaCarreta) ||
            getTipoFrota(each.tipoFrotaVeiculo) ||
            getTipoFrota(each.tipoFrotaBau) ||
            '',
        },
        { value: each.tipoFaturamento ?? '' },
        { value: isValidDate(each.dataInclusao) ? obterDataHora(each.dataInclusao) : '' },
        {
          value:
            each.checklistValidade && each.checklistValidade.includes('OK')
              ? 'Checklist Válido'
              : 'Checklist Inválido',
        },
        { value: each.usuarioLancamento ?? '' },
        { value: each.nomeClienteEmbarcador ?? '' },
        { value: each.cdOrigem ?? '' },
        {
          value: `${
            isValidDate(each.dataInclusao)
              ? `${obterDataHora(each.dataInclusao)} ${each.usuarioLancamento ?? ''}`
              : ''
          } ${
            isValidDate(each.usuarioLancamento)
              ? `${obterDataHora(each.usuarioLancamento)} ${each.usuarioLancamento ?? ''}`
              : ''
          }`,
        },
      ]),
    },
  ];

  setExcelGenerationUser(excel);
};

export default RelatorioXLSLancado;
