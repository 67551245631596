import React from 'react';
import { Spinner as SpinnerBootstrap } from 'react-bootstrap';

export const Spinner = () => {
  return (
    <SpinnerBootstrap
      animation="border"
      variant="warning"
      style={{
        display: 'flex',
        marginLeft: '47.5%',
        marginTop: '2%',
        marginBottom: '2%',
      }}
    />
  );
};
