// utils.ts
export const renderTemperaturaMinMax = (temperaturaMin: number, temperaturaMax: number) => {
  if (temperaturaMin && temperaturaMax) {
    return `${temperaturaMin} °C / ${temperaturaMax} °C`;
  }
  if (temperaturaMin && !temperaturaMax) {
    return `${temperaturaMin}°C / -`;
  }
  if (!temperaturaMin && temperaturaMax) {
    return `- / ${temperaturaMax}°C`;
  }
  return '-';
};

export const renderVelocidadeMax = (velocidadeMax: number) => {
  if (velocidadeMax) {
    return `${velocidadeMax} Km/h`;
  }
  return '-';
};
