import styled from 'styled-components';

export const CardContainer = styled.div`
  outline: 1px solid #dedede;
  border-radius: 10px;
  height: 84px;
  width: 10rem;
  margin: 10px;
  padding: 5px;
  padding-top: 1;
  margin-bottom: 0;
  background-color: #ffffff;

  &:hover {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }

  .cardPosition {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  .cardAdjustment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 8px;
  }
`;

export const CardTitle = styled.p`
  font-size: 10px;
  word-wrap: break-word;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 0;
  line-height: 9.8px;
`;

export const CardQty = styled.p`
  font-size: 22px;
  margin: 0;
`;
