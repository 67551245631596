import { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import Upload from '../Upload';
import { FileProvider } from '../../context/files';
import FileList from '../FileList';

type Props = {
  show: boolean;
  setShow: (show: boolean) => void;
  foto: string;
  setFoto: (foto: string) => void;
  setFailedImage: (failedImage: boolean) => void;
  name: string;
};

const Content = styled.div`
  width: 100%;
  margin-top: 10px;
  background: #fff;
  border-radius: 4px;
`;

export function ModalUpload({ show, setShow, foto, setFoto, setFailedImage, name }: Props) {
  const [fotoNova, setFotoNova] = useState('');
  const [error, setError] = useState(false);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Atualizar foto de perfil</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Content>
          <FileProvider foto={fotoNova} setFoto={setFotoNova} name={name} setError={setError}>
            <Upload />
            <FileList />
          </FileProvider>
        </Content>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          style={{ width: '120px', height: '50px' }}
          onClick={() => {
            // handleDelete();
            setShow(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="primary"
          style={{ width: '120px', height: '50px' }}
          onClick={() => {
            setFoto(fotoNova);
            setFailedImage(false);
            setShow(false);
          }}
          disabled={error}
        >
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
