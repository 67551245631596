export const maskTelefone = (value: string): string => {
  const rawValue = value.replace(/[^0-9]+/g, '');

  if (rawValue.length === 0) {
    return '';
  }
  if (rawValue.length < 2) {
    return `(${rawValue}`;
  }
  if (rawValue.length > 2 && rawValue.length < 6) {
    return `(${rawValue.slice(0, 2)})${rawValue.slice(2, rawValue.length)}`;
  }
  return (
    `(${rawValue.slice(0, 2)})` +
    ` ${rawValue.slice(2, rawValue.length > 10 ? 7 : 6)}-${rawValue.slice(
      rawValue.length > 10 ? 7 : 6,
      rawValue.length > 10 ? 11 : 10
    )}`
  );
};

export const unmaskTelefone = (value: string): string => {
  return value.replace(/[^0-9]+/g, '');
};
