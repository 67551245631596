import { PainelLSCType } from '../Types/PainelLSCTypes';
import { ActiveSM } from '../types';

type SMMapa = {
  bau: string;
  desMotivoFinalizacaoSm: string;
  statusAtraso?: boolean;
  dtCadastroSm: string;
  dtFimPlanejadoSm: string;
  dtFimRealSm: string;
  dtHrUltimaPosicao: string;
  dtInicioPlanejadoSm: string;
  dtInicioRealSm: string;
  hrFimPlanejadoSm: string;
  hrFimRealSm: string;
  hrInicioPlanejadoSm: string;
  hrInicioRealSm: string;
  idCliente: number;
  idSm: number;
  idsm?: number;
  latitudeAtual: number;
  statusInicializacao?: boolean;
  velocidade: string;
  temperatura: string;
  ignicao: string;
  statusSm?: string;
  listMonitoramentoSmMapaPontoVO: {
    distancia: string;
    horario: string;
    idSm: number;
    latitude: number;
    longitude: number;
    ponto: string;
    remessa: string;
    previsao: string;
    previsaocomparadas: string;
    raioAproximacao: number;
    dtHrPlanejadaViagemPonto: string;
    dtHrChegada: string;
    dtHrIncioRealSm: string;
    dtHrPrevisaoComParadas: string;
    dtHrPrevisao: string;
  }[];

  localizacao: string;
  longitudeAtual: number;
  nomeTransportador: string;
  nomeTecnologiaRastreamento: string;
  operacao: string;
  placas: string;
  possuiRotaOuPonto: string;
  progressao: string;
  razaoSocialEmbarcador: string;
  rota: string;
  statusFinalizacaoSm: boolean;
  statusViagem: string;
  tecnologia: string;
};

// function used to determine the vehicle travel status color (if the vehicle is late or not)

export const vehicleStatusTravel = (sm: SMMapa): string => {
  const inTime = '#63c960'; // green
  const veryLate = '#c44a57'; // red
  const littleLate = '#e8ab53'; // orange
  const disabled = '#FFFFFF'; // grey

  if (sm.statusSm === 'B') return inTime;
  if (sm.statusSm === 'A') return veryLate;
  if (sm.statusSm === 'D') return littleLate;
  return disabled;
};

/*
*** modifying the original list of vehicles to add the statusSm property
1 - smList is a request that returns a list of Sm's but doesn't have statusSm property (depends on user's role)
2 - activeSmList is a request that returns a list of Sm's with statusSm property (always have statusSm property)
3 - This for loop is comparing the SM ID and returning statusSm flag's property
4 - The solution is to get the property and use to check in what point the vehicle is at
*/
export const matchSmId = (
  smList: SMMapa[] | PainelLSCType[],
  activeSmList: ActiveSM[]
): SMMapa[] | PainelLSCType[] => {
  smList.forEach((vehicleFiltered) => {
    const vehicleMatch = activeSmList.find(
      (vehicleStatus) =>
        vehicleStatus.id === vehicleFiltered.idSm || vehicleStatus.id === vehicleFiltered.idsm
    );

    if (vehicleMatch) {
      // eslint-disable-next-line no-param-reassign
      vehicleFiltered.statusSm = vehicleMatch.status_sm;
    }
  });

  return smList;
};
