/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { Spinner } from 'react-bootstrap';
import api from '../../config/axiosMaquina';
import Pagination from '../../components/Pagination/Pagination';
import { CorType } from '../../Types/MarcaCorTypes';
import { ModalDelete } from '../../components/ModalDelete';
import { CorRow } from './editableGrid';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { ImCancelCircle } from 'react-icons/im';
import { AiOutlineCheck } from 'react-icons/ai';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { Tela } from '../../components/Tela';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

const ConsultarCor: FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const [idEdit, setIdEdit] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [newLine, setNewLine] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [jaAberto, setJaAberto] = useState(false);
  const [cores, setCores] = useState<CorType[]>([]);
  const [searchResults, setSearchResults] = useState<CorType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [nome, setNome] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [itensPerPage, setItensPerPage] = useState('10');

  const paginate = (pageNumber: number) => {
    history.push(`/listar/cor/${pageNumber}`);
  };
  const { page }: any = useParams();

  const [mensagem, setMensagem] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.COR]}], 'inserir');
    rolePermission([{ADMIN: [ADMIN.CADASTRO.COR]}], 'editar');
    rolePermission([{ADMIN: [ADMIN.CADASTRO.COR]}], 'excluir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const loadCount = async () => {
      setLoading(true);
      console.log(page - 1, itensPerPage);
      try {
        const response = await api.get(`/cor/count`);
        console.log(response.data);
        setLoading(false);
        setTotalItems(response.data);
      } catch (err: any) {
        setLoading(false);
        console.log(err);
      }
    };
    loadCount();
  }, []);

  useEffect(() => {
    const loadDadosCores = async () => {
      setLoading(true);
      console.log(page - 1, itensPerPage);
      try {
        const response = await api.get(`/cor`, {
          params: {
            qtdRegistro: parseInt(itensPerPage),
            pagina: page - 1,
          },
        });
        console.log(response.data);
        setLoading(false);
        setCores(response.data);
      } catch (err: any) {
        setLoading(false);
        console.log(err);
      }
    };
    loadDadosCores();
  }, [page, itensPerPage]);

  useEffect(() => {
    const search = async () => {
      if (searchTerm.trim() && searchTerm) {
        history.push(`/listar/cor/1`);
        try {
          const response = await api.get(`/cor/search?keyword=${searchTerm}`);
          setSearchResults(response.data);
        } catch (err) {
          console.error(err);
        }
      }
    };
    search();
  }, [searchTerm]);

  const handleDelete = async () => {
    try {
      const response = await api.put(`/cor/delete-logico/${idDelete}`);
      console.log(response.data);
      setOpenModal(false);
      setIsDialogCadOK(true);
      setMensagem('Cor excluída com sucesso!');
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir cor.',
        type: 'error',
      });
    }
  };

  async function handleSubmit() {
    setDisabled(false);
    if (nome.trim() != '') {
      setLoading(true);
      try {
        const data = {
          corTxNome: nome,
          corBlAtivo: true,
        };
        await api.post(`/cor`, data);
        setMensagem('Cor cadastrada com sucesso!');
        setIsDialogCadOK(true);
      } catch (err: any) {
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar cor.',
          type: 'error',
        });
      } finally {
        setLoading(false);
      }
    } else {
      addToast({
        title: 'Erro',
        description: 'Verifique os campos obrigatórios.',
        type: 'error',
      });
    }
  }

  const handleCancel = () => {
    setDisabled(true);
    setNewLine(false);
    setNome('');
  };

  return (
    <Tela nome="Cor" caminho="Cadastro > Veículos > Cor" loading={loading} setLoading={setLoading}>
      <ModalDelete
        show={openModal}
        onHide={() => setOpenModal(false)}
        handleDelete={handleDelete}
        handleClose={() => setOpenModal(false)}
        message="Deseja excluir este registro?"
      />
      <Dialog
        header={mensagem}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
          window.location.reload();
        }}
      />
      <div className="search-create mb-4" style={{ width: '100%' }}>
        <div
          className="btn-create"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (canInsert) {
              setNewLine(true);
              setIdEdit(0);
            } else {
              history.push('/acessonegado');
            }
          }}
        >
          <i
            style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
            className="pi pi-plus"
          ></i>
        </div>
        <span className="p-input-icon-left">
          <input
            type="text"
            placeholder="Pesquise"
            className="p-inputtext p-component"
            id="search-input"
            style={{ width: '240px' }}
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
          <i className="pi pi-search"></i>
        </span>
      </div>
      <div className="content-container" style={{ width: '100%' }}>
        <div className="painel">
          <div className="table-responsive" style={{ flex: 1 }}>
            <table className="table">
              <thead className="titleLine">
                <tr>
                  <th id="th">
                    Nome<span className="txt-obrigatorio"> *</span>
                  </th>
                </tr>
              </thead>
              {newLine && idEdit === 0 ? (
                <div className="lineGrid2" style={{ filter: 'drop-shadow(0 0 0.5rem #d8d8d8)' }}>
                  <input
                    placeholder="Nova cor"
                    type="text"
                    value={nome}
                    maxLength={64}
                    onChange={(e: any) => {
                      setNome(e.target.value);
                    }}
                  />
                  <ButtonTooltip onClick={handleCancel} type="Cancelar" />
                  <ButtonTooltip onClick={handleSubmit} type="Enviar" />
                </div>
              ) : null}
              <tbody>
                {searchTerm.trim() === ''
                  ? cores.map((cor) => {
                      return (
                        <div>
                          <CorRow
                            cor={cor}
                            setOpenModal={setOpenModal}
                            setCloseModal={setOpenModal}
                            setIdDelete={setIdDelete}
                            setIdEdit={setIdEdit}
                            idAtual={idEdit}
                            canEdit={canEdit}
                            canDelete={canDelete}
                          />
                        </div>
                      );
                    })
                  : searchResults.map((cor) => {
                      return (
                        <div>
                          <CorRow
                            cor={cor}
                            setOpenModal={setOpenModal}
                            setCloseModal={setOpenModal}
                            setIdDelete={setIdDelete}
                            setIdEdit={setIdEdit}
                            idAtual={idEdit}
                            canEdit={canEdit}
                            canDelete={canDelete}
                          />
                        </div>
                      );
                    })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        totalPosts={searchTerm.trim() ? searchResults.length : totalItems}
        paginate={paginate}
        currentPage={page.toString()}
        itensPerPage={itensPerPage}
        setItensPerPage={setItensPerPage}
      />
    </Tela>
  );
};

export default ConsultarCor;
