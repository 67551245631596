/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Form } from 'react-bootstrap';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import '../ConsultarPesquisasLancadas/styles.css';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { toZoned } from '../../util/date';

type ListagemConsulta = {
  idCliente: number;
  listaDispositivo: [
    {
      descricao: string;
      situacao: boolean;
    }
  ];
  numeroSat: number;
  placaCarreta: string;
  placaVeiculo: string;
  razaoSocialCliente: string;
  responsavel: string;
  usuarioFinalizacao: string;
  usuarioLancamento: string;
};

export type FiltroResumoSat = {
  dataFim: string;
  dataInicio: string;
  idCliente: number;
  razaoSocialCliente: string | null;
  numeroBau: string | null;
  placaCarreta: string | null;
  placaVeiculo: string | null;
  tipoFrota: string;
};

const ResumoSat = () => {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);

  const [empresa, setEmpresa] = useState('');
  const listaTipos = [
    { value: '1', nome: 'Próprio' },
    { value: '2', nome: 'Agregado' },
    { value: '3', nome: 'Terceiro' },
  ];
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [tipo, setTipo] = useState('');
  const [model, setModel] = useState<FiltroResumoSat>({} as FiltroResumoSat);
  const cliente = getCookieSessionData().usuarioVO.clienteId;
  const [dataResults, setDataResults] = useState<ListagemConsulta[]>();
  const { addToast } = useToast();
  const history = useHistory();
  const [isPlacaRegisteredVeiculo, setIsPlacaRegisteredVeiculo] = useState(true);
  const [isPlacaRegisteredCarreta, setIsPlacaRegisteredCarreta] = useState(true);
  const [isNumeroRegisteredBau, setIsNumeroRegisteredBau] = useState(true);
  const [dataFimError, setDataFimError] = useState('');

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaClientes(listaAtivos);
        console.log(listaAtivos);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    console.log('useeffect model: ', model);
  }, [model]);

  const buscar = async (event: any) => {
    event.preventDefault();

    if (!model.idCliente && isAllowedByRole(['admin'])) {
      addToast({
        title: 'Erro',
        description: 'Preencha os dados obrigatórios!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    if (model.placaVeiculo) {
      if (isPlacaRegisteredVeiculo === false) {
        addToast({
          title: 'Erro',
          description: 'Placa de veículo não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    if (model.placaCarreta) {
      if (isPlacaRegisteredCarreta === false) {
        addToast({
          title: 'Erro',
          description: 'Placa de carreta não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    if (model.numeroBau) {
      if (isNumeroRegisteredBau === false) {
        addToast({
          title: 'Erro',
          description: 'Número de baú não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }
    if (isAllowedByRole(['user'])) {
      try {
        const response = await api.get(`/solicitacao-assistencia-tecnica/consulta-gerenciar-sat`, {
          params: {
            ...model,
            idCliente: cliente,
          },
        });
        console.log(response);
        if (response.status === 200) {
          setDataResults(response.data);
          if (response.data.length === 0) {
            console.log(response);
            addToast({
              title: 'Erro',
              description: 'Não existe resultado para esse filtro!',
              type: 'error',
            });
            event.stopPropagation();
            return;
          } else {
            history.push({
              pathname: '/listagem-resumo-sat',
              state: { listagem: response.data, model: model },
            });
            return;
          }
        }
      } catch (err: any) {
        console.log(err.response);
      }
    } else {
      try {
        const response = await api.get(`/solicitacao-assistencia-tecnica/consulta-gerenciar-sat`, {
          params: {
            ...model,
          },
        });
        console.log(response);
        if (response.status === 200) {
          setDataResults(response.data);
          if (response.data.length === 0) {
            console.log(response);
            addToast({
              title: 'Erro',
              description: 'Não existe resultado para esse filtro!',
              type: 'error',
            });
            event.stopPropagation();
            return;
          } else {
            history.push({
              pathname: '/listagem-resumo-sat',
              state: { listagem: response.data, model: model },
            });
            return;
          }
        }
      } catch (err: any) {
        console.log(err.response);
      }
    }
  };

  const verifyIfPlacaIsRegisteredVeiculo = async (value: string) => {
    if (model.idCliente) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-numequipamento-cliente-veiculo?idCliente=${model.idCliente}&placaVeiculo=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegisteredVeiculo(true);
        } else {
          setIsPlacaRegisteredVeiculo(false);
        }
        console.log(response.data);
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const verifyIfPlacaIsRegisteredCarreta = async (value: string) => {
    if (model.idCliente) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-cliente-carreta?idCliente=${model.idCliente}&placaCarreta=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegisteredCarreta(true);
        } else {
          setIsPlacaRegisteredCarreta(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const verifyIfNumeroIsRegisteredBau = async (value: string) => {
    if (model.idCliente) {
      try {
        const response = await axios.get(
          `/bau-intercambiavel/verificar-bau-cliente-numero-bau?idCliente=${model.idCliente}&numeroBau=${value}`
        );
        if (response.data === true) {
          setIsNumeroRegisteredBau(true);
        } else {
          setIsNumeroRegisteredBau(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Relatório SAT</h2>
          <h6 className="subtitulo">{'Consultas/Relatórios > Assistência Técnica'}</h6>
        </div>

        <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
          {isAllowedByRole(['admin']) ? (
            <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
              <Form.Label className="requiredField">Empresa</Form.Label>

              <Dropdown
                style={{ width: '100.4%' }}
                value={empresa}
                options={listaClientes}
                placeholder="Selecione"
                onChange={(e: any) => {
                  listaClientes.map((each) => {
                    if (each.id === e.target.value) {
                      setModel({
                        ...model,
                        idCliente: e.target.value,
                        razaoSocialCliente: each.razaoSocial,
                      });
                    }
                  });

                  setEmpresa(e.target.value);
                }}
                optionLabel="razaoSocial"
                optionValue="id"
                showClear
                filter
                className="multiselect-custom"
                id="empresa"
                required
              />
            </Form.Group>
          ) : null}

          <Form.Row>
            <Form.Group className="row-modal">
              <Form.Label>Lançadas entre</Form.Label>
              <Form.Control
                onChange={(e) => {
                  e.target.value.length > 10 ? null : setDataInicio(e.target.value);
                  if (e.target.value !== '') {
                    setModel({
                      ...model,
                      dataInicio: toZoned(e.target.value),
                    });
                  }
                }}
                onBlur={() => {
                  if (dataFim && new Date(dataFim) < new Date(dataInicio)) {
                    setDataFimError('Data final não pode ser menor que a data inicial.');
                  } else {
                    setDataFimError('');
                  }
                }}
                value={dataInicio}
                type="date"
                id="date"
                name="date"
                placeholder=""
              />
              <Form.Control.Feedback type="invalid">
                Campo Data Início não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Text style={{ marginLeft: '2px', marginRight: '2px', marginTop: 29 }}>
              e
            </Form.Text>
            <Form.Group className="row-modal" style={{ marginTop: 29 }}>
              {/* <Form.Label>Data Fim</Form.Label> */}
              <Form.Control
                onChange={(e) => {
                  e.target.value.length > 10 ? null : setDataFim(e.target.value);
                  if (e.target.value !== '') {
                    setModel({
                      ...model,
                      dataFim: toZoned(e.target.value, false),
                    });
                  }
                }}
                onBlur={() => {
                  if (dataInicio && new Date(dataFim) < new Date(dataInicio)) {
                    setDataFimError('Data final não pode ser menor que a data inicial.');
                  } else {
                    setDataFimError('');
                  }
                }}
                value={dataFim}
                type="date"
                id="dataFim"
                name="dataFim"
                placeholder=""
              />
              {dataFimError && (
                <p style={{ fontSize: 14, color: 'red', marginTop: 5, position: 'absolute' }}>
                  {dataFimError}
                </p>
              )}
            </Form.Group>
          </Form.Row>

          <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
            <Form.Label>Tipo</Form.Label>

            <Dropdown
              style={{ width: '100.4%' }}
              value={tipo}
              options={listaTipos}
              placeholder="Selecione"
              onChange={(e: any) => {
                setModel({
                  ...model,
                  tipoFrota: e.target.value,
                });
                setTipo(e.target.value);
              }}
              optionLabel="nome"
              optionValue="value"
              showClear
              className="multiselect-custom"
              id="tipo"
            />
          </Form.Group>

          {/* Validação se a placa existe no sistema, não é dinamico, é no post  */}

          <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
            <Form.Label>Veículo</Form.Label>

            <Form.Control
              onChange={(e) => {
                if (e.target.value === '') {
                  setModel({
                    ...model,
                    placaVeiculo: null,
                  });
                } else {
                  setModel({
                    ...model,
                    placaVeiculo: e.target.value,
                  });
                }
              }}
              onBlur={() => {
                if (!model.placaVeiculo) {
                  return;
                }
                verifyIfPlacaIsRegisteredVeiculo(model.placaVeiculo);
              }}
              value={model?.placaVeiculo ? model?.placaVeiculo : ''}
              type="text"
              id="placaVeiculo"
              name="placaVeiculo"
              placeholder=""
              style={{ width: '100%' }}
            />
          </Form.Group>

          {/* Validação se a placa Carreta existe no sistema, não é dinamico, é no post  */}

          <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
            <Form.Label>Carreta</Form.Label>

            <Form.Control
              onChange={(e) => {
                if (e.target.value === '') {
                  setModel({
                    ...model,
                    placaCarreta: null,
                  });
                } else {
                  setModel({
                    ...model,
                    placaCarreta: e.target.value,
                  });
                }
              }}
              onBlur={() => {
                if (!model.placaCarreta) {
                  return;
                }
                verifyIfPlacaIsRegisteredCarreta(model.placaCarreta);
              }}
              value={model?.placaCarreta ? model?.placaCarreta : ''}
              type="text"
              id="placaCarreta"
              name="placaCarreta"
              placeholder=""
              style={{ width: '100%' }}
            />
          </Form.Group>

          {/* Validação se a placa existe no sistema, não é dinamico, é no post  */}

          <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
            <Form.Label>Baú</Form.Label>

            <Form.Control
              onChange={(e) => {
                if (e.target.value === '') {
                  setModel({
                    ...model,
                    numeroBau: null,
                  });
                } else {
                  setModel({
                    ...model,
                    numeroBau: e.target.value,
                  });
                }
              }}
              onBlur={() => {
                if (!model.numeroBau) {
                  return;
                }
                verifyIfNumeroIsRegisteredBau(model.numeroBau);
              }}
              value={model?.numeroBau ? model?.numeroBau : ''}
              type="text"
              id="numeroBau"
              name="numeroBau"
              placeholder=""
              style={{ width: '100%' }}
            />
          </Form.Group>

          <ButtonsForm />
        </Form>
      </div>
    </div>
  );
};

export default ResumoSat;
