/* eslint-disable */

import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { profileMenuGr } from '../../services/profileMenu';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import Search from '../../components/Search/Search';
import { filterItems2 } from '../../util/format';

type Props = {
  canInsert?: boolean;
};

function UsuariosClientes() {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;

  const { rolePermission } = useRolePermission();
  const [searchValue, setSearchValue] = useState('');
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const { role, usuarioId } = getCookieSessionData().usuarioVO;
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [canInsert, setCanInsert] = useState(false);

  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: number) => {
    history.push(`/listar-perfis-usuarios-gr/${pageNumber}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        setData([]);

        profileMenuGr().then((res: any) => {
          setData(res.data.filter((profile: any) => !profile.client_id));
        });

        setCanInsert(true);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [role]);

  useEffect(() => {
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO],
          ADMIN: [ADMIN.USUARIO.CLIENTE],
          EMBARCADOR: [EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO],
        },
      ],
      'inserir'
    );
  }, [ADMIN, EMBARCADOR, TRANSPORTADOR, rolePermission]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      setDataSearch(filterItems2(data, searchValue));
    }
  }, [data, searchValue]);

  return (
    <Tela
      nome="Usuário"
      caminho="Usuários > Inclusão/Alteração(Perfis Usuários GR)"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      <Search
        setLoading={setLoadingSearch}
        setSearch={setSearchTerm}
        setSearchValue={setSearchValue}
        search={searchTerm}
        linkToAdd="/cadastro-perfis-usuarios-gr"
        createDisabled={false}
        buttonDisabled={false}
      />

      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <table className="table">
          <thead className="thead">
            <tr>
              <th>Nome</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {currentData &&
              currentData.map((each: any, index) => {
                return (
                  <tr key={index}>
                    <td>{each.mep_tx_nome_menu}</td>
                    <td align="left">
                      <ButtonTooltip
                        type="Editar"
                        onClick={() => history.push(`/edicao-perfis-usuarios-gr/${each.mep_cd_id}`)}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </Tela>
  );
}

export default UsuariosClientes;
