/* eslint-disable */

import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import checaCPF from '../../util/checaCpf';
import { cpfMask } from '../../util/Validacoes/Validacoes';
import '../ConsultarPesquisasLancadas/styles.css';
import { Tela } from '../../components/Tela';

export type FiltroAjudante = {
  cpfAjudante: string | null;
  idCliente: number;
  nomeAjudante: string | null;
  pagina: number;
  qtdRegistros: number;
  tipo: string | null;
};

const ConsultarAjudante = () => {
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const listaTipos = [
    { value: 'Agregado', nome: 'Agregado' },
    { value: 'Autonomo', nome: 'Autônomo' },
    { value: 'Funcionario', nome: 'Funcionário' },
  ];

  const [empresa, setEmpresa] = useState('');
  const [tipo, setTipo] = useState('');
  const [cpf, setCpf] = useState('');
  const [model, setModel] = useState<FiltroAjudante>({} as FiltroAjudante);
  const { addToast } = useToast();
  const history = useHistory();
  const [isCpfValid, setIsCpfValid] = useState(true);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaClientes(listaAtivos);
      });
    };
    loadData();
  }, []);

  const buscar = async (event: any) => {
    event.preventDefault();
    if (!isCpfValid && cpf.length > 0) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    try {
      const response = await api.get(`/ajudante-funcionario/consulta-ajudante-gerenciar`, {
        params: {
          ...model,
        },
      });
      if (response.status === 200) {
        if (response.data.length === 0) {
          addToast({
            title: 'Erro',
            description: 'Não existe resultado para esse filtro!',
            type: 'error',
          });
          event.stopPropagation();
          return;
        }
        history.push({
          pathname: '/admin/listar/ajudantes',
          state: { listagem: response.data, model },
        });
        return;
      }
    } catch (err: any) {
      console.log(err.response);
    }
  };

  return (
    <Tela
      nome="Ajudantes"
      caminho="Cadastro > Gerenciamento de Clientes > Ajudante Funcionário(Não Motorista)"
    >
      <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
        <Form.Group className="row-modal">
          <Form.Label>Cliente</Form.Label>

          <Dropdown
            style={{ width: '100.4%' }}
            value={empresa}
            options={listaClientes}
            placeholder="Selecione"
            onChange={(e: any) => {
              setModel({
                ...model,
                idCliente: e.target.value,
              });

              setEmpresa(e.target.value);
            }}
            optionLabel="razaoSocial"
            optionValue="id"
            showClear
            filter
            className="multiselect-custom"
            id="empresa"
          />
        </Form.Group>

        <Form.Row className="row-container-modal">
          <Form.Group className="row-modal">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              onChange={(e) => {
                if (e.target.value === '') {
                  setModel({
                    ...model,
                    nomeAjudante: null,
                  });
                } else {
                  setModel({
                    ...model,
                    nomeAjudante: e.target.value,
                  });
                }
              }}
              value={model?.nomeAjudante ? model?.nomeAjudante : ''}
              type="text"
              id="nome"
              name="nome"
              placeholder=""
              style={{ width: '100%' }}
            />
          </Form.Group>
        </Form.Row>

        <Form.Group>
          <Form.Label>CPF</Form.Label>
          <Form.Control
            value={cpfMask(cpf)}
            isInvalid={!isCpfValid && cpf.length > 0}
            id="cpf"
            name="cpf"
            placeholder=""
            maxLength={14}
            onChange={(event: any) => {
              if (event.target.value === '') {
                setModel({
                  ...model,
                  cpfAjudante: null,
                });
                setCpf(cpfMask(event.target.value));
              } else if (checaCPF(event.target.value) === false) {
                setCpf(cpfMask(event.target.value));
                setIsCpfValid(false);
              } else {
                setCpf(cpfMask(event.target.value));
                setIsCpfValid(true);
                setModel({
                  ...model,
                  cpfAjudante: event.target.value,
                });
              }
            }}
          />
          {!isCpfValid && cpf.length > 0 && (
            <p style={{ fontSize: 14, color: 'red', marginTop: 5 }}>Cpf inválido.</p>
          )}
        </Form.Group>

        <Form.Group className="row-modal">
          <Form.Label>Tipo</Form.Label>

          <Dropdown
            style={{ width: '100.4%' }}
            value={tipo}
            options={listaTipos}
            placeholder="Selecione"
            onChange={(e: any) => {
              setModel({
                ...model,
                tipo: e.target.value,
              });
              setTipo(e.target.value);
            }}
            optionLabel="nome"
            optionValue="value"
            showClear
            className="multiselect-custom"
            id="tipo"
          />
        </Form.Group>

        <ButtonsForm />
      </Form>
    </Tela>
  );
};

export default ConsultarAjudante;
