import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useToast } from '../../hooks/Toast';
import api from '../../config/axiosMaquina';
import { Tela } from '../../components/Tela';
import { PrimaryButton } from '../../components/ButtonsForm/styles';

export type MacroJornadaType = {
  id: number;
  nome: string;
};

export const ParametrosJornada = () => {
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [mensagen, setMensagen] = useState('');
  const [data, setData] = useState<MacroJornadaType[]>([]);
  const [model, setModel] = useState<MacroJornadaType>();
  const [inicioJornada, setInicioJornada] = useState('');

  const handleSubmit = () => {
    console.log('');
  };

  const macros: MacroJornadaType[] = [
    { id: 1, nome: '501' },
    { id: 2, nome: '502' },
    { id: 3, nome: '503' },
    { id: 4, nome: '504' },
  ];

  const updateModel = (e: any) => {
    console.log(e.target.value);
    if (!model) return;
    setModel({
      ...model,
      [e.target.name]: e.target.value,
    });
  };

  const fetchData = async () => {
    try {
      const response = await api.get('/jornada-de-trabalho');
      setData(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  return (
    <Tela nome="Macros Jornada de Trabalho" caminho="Parâmetros > Macros Jornada de Trabalho">
      <Dialog
        header="Parâmetro cadastrado com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          fetchData();
        }}
      />
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Início de jornada</Form.Label>
          <Form.Control
            value={inicioJornada}
            placeholder=""
            onChange={(e) => setInicioJornada(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Fim de jornada</Form.Label>
          <Form.Control
            value={inicioJornada}
            placeholder=""
            onChange={(e) => setInicioJornada(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Início de espera</Form.Label>
          <Form.Control
            value={inicioJornada}
            placeholder=""
            onChange={(e) => setInicioJornada(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Fim de espera</Form.Label>
          <Form.Control
            value={inicioJornada}
            placeholder=""
            onChange={(e) => setInicioJornada(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Fim repouso</Form.Label>
          <Form.Control
            value={inicioJornada}
            placeholder=""
            onChange={(e) => setInicioJornada(e.target.value)}
          />
        </Form.Group>

        <Form.Row className="container-buttons justify-content-end">
          <PrimaryButton type="submit">Salvar</PrimaryButton>
        </Form.Row>
      </Form>
    </Tela>
  );
};
