/* eslint-disable */
import React, { useState, useEffect, memo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import axios from '../../../config/axiosMaquina';
import { useToast } from '../../../hooks/Toast';
import ModalGrupoVeiculo from '../../GrupoVeiculo/Modal';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

type TipoVeiculoType = {
  vtgCdId: {
    vtgCdId: number;
  };
  vtpTxNome: string;
  vtpCdIntegracao: any;
};

type GrupoVeiculoType = {
  vtgCdId: number;
  vtgTxNome: string;
};

const FormTipoVeiculo: React.FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();

  const [show, setShow] = useState(false);
  const [grupoVeiculos, setGrupoVeiculos] = useState<GrupoVeiculoType[]>([]);
  const [model, setModel] = useState<TipoVeiculoType>({
    vtgCdId: {
      vtgCdId: 0,
    },
    vtpTxNome: '',
    vtpCdIntegracao: null
  });
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { vtpTxNome, vtgCdId, vtpCdIntegracao } = model;

  const fetchData = async () => {
    const response = await axios.get('/grupo-tipo-veiculo/grid');
    setGrupoVeiculos(response.data);
    if (id) {
      try {
        const { data } = await axios.get(`/tipo-veiculo/veiculotipo/${id}`);
        setModel(data);
      } catch (err: any) {
        let errorMessage = 'Ocorreu um erro';
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        console.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.TIPO_VEICULO]}], 'editar');
    rolePermission([{ADMIN: [ADMIN.CADASTRO.TIPO_VEICULO]}], 'excluir');
    rolePermission([{ADMIN: [ADMIN.CADASTRO.GRUPO_VEICULO]}], 'inserir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!vtpTxNome || !vtgCdId.vtgCdId || !vtpCdIntegracao) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    if (id) {
      try {
        await axios.post(`/tipo-veiculo/atualizar?idTipoVeiculo=${id}`, {
          ...model,
          vtgCdId: vtgCdId.vtgCdId,
        });
        setIsDialogCadOK(true);
      } catch (e: any) {
        if (e.response.status === 400) {
          addToast({
            title: 'Erro',
            description: e.response.data.details,
            type: 'error',
          });
          return
        }

        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      }
    } else {
      try {
        await axios.post('/tipo-veiculo/veiculotipo', { ...model, vtpBlAtivo: true });
        setIsDialogCadOK(true);
      } catch (e: any){

        if (e.response.status === 400) {
          addToast({
            title: 'Erro',
            description: e.response.data.details,
            type: 'error',
          });
          return
        }

        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleDelete = async (id: number) => {
    try {
      await axios.post(`/tipo-veiculo/delete-logico?idTipoVeiculo=${id}`);
      setExclusao((prev) => ({ ...prev, ok: true }));
      history.push('/listar/tipos-de-veiculo/1');
    } catch (err: any) {
      let errorMessage = 'Ocorreu um erro';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setExclusao((prev) => ({ ...prev, erro: true, mensagem: errorMessage }));
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={`Tipo de Veículo ${id ? 'alterado' : 'cadastrado'} com sucesso!`}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/tipos-de-veiculo/1');
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro &quot;{vtpTxNome}&quot;?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Tipo de Veículo &quot;{vtpTxNome}&quot; excluído com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Tipos de Veículos</h2>
          <h6 className="subtitulo">{`Cadastro > Veículos > Tipos de Veículos > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
        <div className="painel">
          <Form className="form-sm" noValidate validated={isFormInvalid} onSubmit={handleSubmit}>
            <Form.Row style={{ alignItems: 'center' }}>

            <Form.Group as={Col} sm={12} md={5}>
                <Form.Label className="requiredField">Código Integração</Form.Label>
                <Form.Control
                  value={vtpCdIntegracao}
                  onChange={(e: any) => {
                    if (e.target.value > 0) {

                      setModel({ ...model, vtpCdIntegracao: parseInt(e.target.value) });
                    }
                  }}
                  name="vtpCdIntegracao"
                  type="number"
                  required
                  min={1}
                />
              </Form.Group>

              <Form.Group as={Col} sm={12} md={5}>
                <Form.Label className="requiredField">Nome</Form.Label>
                <Form.Control
                  value={vtpTxNome}
                  onChange={(e) => {
                    setModel({ ...model, vtpTxNome: e.target.value });
                  }}
                  name="vtpTxNome"
                  type="text"
                  required
                  maxLength={64}
                  minLength={1}
                />
              </Form.Group>

              <Form.Group as={Col} sm={12} md={5}>
                <Form.Label className="requiredField">Grupo de veículos</Form.Label>
                <Form.Control
                  value={vtgCdId?.vtgCdId}
                  onChange={(e) => {
                    setModel({
                      ...model,
                      vtgCdId: { ...vtgCdId, vtgCdId: +e.target.value },
                    });
                  }}
                  as="select"
                  required
                >
                  <option value="">Selecione uma opção</option>
                  {grupoVeiculos.map((t) => (
                    <option value={t.vtgCdId} key={t.vtgCdId}>
                      {t.vtgTxNome}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <ModalGrupoVeiculo canInsert={canInsert} />
            </Form.Row>
            <div style={{ width: '90%', paddingRight: 20, paddingTop: 50 }}>
              <ButtonsForm canEdit={id && canEdit} />
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default FormTipoVeiculo;
