/* eslint-disable */
import './styles.css';

const ButtonPrint = () => {
  return (
    <button
      onClick={() => {
        window.print();
      }}
      className="btn-print"
    >
      Imprimir
    </button>
  );
};

export default ButtonPrint;
