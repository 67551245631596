/* eslint-disable */

import React, { useEffect, useState } from 'react';
import {
  ResumoCnhType,
  ResumoCondutorType,
  ResumoVeiculoType,
} from '../../Types/ResumoCondutorType';
import './styles.css';
import api from '../../config/axiosMaquina';
import CnhData from './cnh';
import VeiculoData from './veiculo';
import CondutorData from './condutor'

export function ResumoProcessual(props: any) {
  const [processos, setProcessos] = useState<ResumoCondutorType[]>();
  const [processosErrors, setProcessosErrors] = useState([])
  const [dadosCNH, setDadosCNH] = useState<any>();
  const [cnhErrors, setCnhErrors] = useState([])
  const [dadosVeiculo, setDadosVeiculo] = useState<any>();
  const [dadosRoubosFurtos, setDadosRoubosFurtos] = useState<ResumoVeiculoType[]>();

  useEffect(() => {
    const handleVeiculo = async () => {
      if (props.numLiberacao && props.tipo === 'PLACA') {
        try {
          const response = await api.get(
            `/pesquisa/consulta-veiculo?idPesquisa=${props.numLiberacao}&itemPesquisado=${props.item.itemPesquisado}`
          );
          setDadosVeiculo(response.data[0]);
          setDadosRoubosFurtos(response.data);
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    handleVeiculo();
  }, [props.numLiberacao, props.tipo]);

  useEffect(() => {
    const handleCnh = async () => {
      if (props.numLiberacao && props.tipo === 'CNH') {
        try {
          const response = await api.get(`/pesquisa/cnh?idPesquisa=${props.numLiberacao}`);

          if(response.data.details) {
            setCnhErrors(response.data.details)
            setDadosCNH([])
            return
          }

          setDadosCNH(response.data[0]);
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    handleCnh();
  }, [props.numLiberacao, props.tipo]);

  useEffect(() => {
    const handleCpf = async () => {
      if (props.numLiberacao && props.tipo === 'CPF') {
        try {
          const response = await api.get(`/pesquisa/processo?idPesquisa=${props.numLiberacao}`);
          if(response.data.details) {
            setProcessosErrors(response.data.details)
            setProcessos([])
            return
          }

          if (response.data.length > 0) {
            setProcessos(response.data);
          }
        } catch (error: any) {
          console.log(error);
        }
      }
    };
    handleCpf();
  }, [props.numLiberacao, props.tipo]);

  return (
    <div className="resumo">
      {processos || processosErrors.length > 0 ? <CondutorData processos={processos} processosErrors={processosErrors}/>
       : dadosCNH || cnhErrors.length > 0 ?
        <CnhData
          dadosCNH={dadosCNH} cnhErrors={cnhErrors}
        />
        : dadosVeiculo ? (
        <VeiculoData
          dadosVeiculo={dadosVeiculo}
          dadosRoubosFurtos={dadosRoubosFurtos}
        />
      ) : (
        'NÃO HÁ DADOS DISPONÍVEIS'
      )}
    </div>
  );
}
