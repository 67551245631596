/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
import { ListagemCliente, ListagemEmbarcador } from '../../api/Types/listagemResumoTypes';
import { getCookieSessionData, isAllowedByRole } from '../../services/cookieService';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import '../ConsultarPesquisasLancadas/styles.css';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { toZoned } from '../../util/date';

type ListagemConsulta = {
  checklistValidade: string;
  dataInclusao: any;
  dataValidade: any;
  idChecklist: Number;
  idClienteEmbarcador: Number;
  idClienteTransportador: Number;
  listaDispositivo: [
    {
      idChecklist: Number;
      idDispositivo: Number;
      nomeDispositivo: string;
      situacaoDispositivo: string;
      tipoDispositivo: string;
    }
  ];
  marcaBau: string;
  marcaCarreta: string;
  marcaVeiculo: string;
  modeloBau: string;
  modeloCarreta: string;
  modeloVeiculo: string;
  numeroBau: string;
  placaCarreta: string;
  placaVeiculo: string;
  responsavel: string;
  telefoneResponsavel: string;
  tipoFrotaCarreta: string;
  tipoFrotaVeiculo: string;
  usuarioLancamento: string;
};

export type FiltroResumoChecklist = {
  dataFim: string | null;
  dataInicio: string | null;
  idClienteEmbarcador: number;
  nomeClienteTransportador: string | null;
  idClienteTransportador: number;
  numeroBau: string | null;
  placaCarreta: string | null;
  placaVeiculo: string | null;
  statusNaoVencida: string | null;
  statusVencida: string | null;
  tipoFrota: string | null;
};

const FiltroChecklistDetalhado = () => {
  const [listaTransportador, setListaTransportador] = useState<ListagemCliente[]>([]);
  const [listaEmbarcador, setListaEmbarcador] = useState<ListagemEmbarcador[]>([]);

  const [embarcador, setEmbarcador] = useState('');
  const [transportador, setTransportador] = useState('');
  const listaTipos = [
    { value: '1', nome: 'Próprio' },
    { value: '2', nome: 'Agregado' },
    { value: '3', nome: 'Terceiro' },
  ];
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [tipo, setTipo] = useState('');
  const [model, setModel] = useState<FiltroResumoChecklist>({} as FiltroResumoChecklist);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [dataResults, setDataResults] = useState<ListagemConsulta[]>();
  const { addToast } = useToast();
  const history = useHistory();
  const [isPlacaRegisteredVeiculo, setIsPlacaRegisteredVeiculo] = useState(true);
  const [isPlacaRegisteredCarreta, setIsPlacaRegisteredCarreta] = useState(true);
  const [isNumeroRegisteredBau, setIsNumeroRegisteredBau] = useState(true);
  const [dataFimError, setDataFimError] = useState('');

  const [loading, setLoading] = useState(false);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    const loadData = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        const listaAtivos = response.data.filter((fil: any) => fil.ativo === true);
        setListaTransportador(listaAtivos);
      });
    };
    loadData();
  }, []);

  useEffect(() => {
    const loadDadosEmbarcadorIdRazaoSocial = async () => {
      try {
        const response = await axios.get(`/embarcador/listar-todos`);
        const listaAtivos = response.data.filter((fil: any) => fil.statusAtivo === true);
        setListaEmbarcador(listaAtivos);
      } catch (err: any) {
        console.error(err);
      }
    };

    loadDadosEmbarcadorIdRazaoSocial();
  }, []);

  const buscar = async (event: any) => {
    event.preventDefault();

    // if (!model.idClienteTransportador && isAllowedByRole(['admin'])) {
    //   addToast({
    //     title: 'Erro',
    //     description: 'Preencha os dados obrigatórios!',
    //     type: 'error',
    //   });
    //   setIsFormInvalid(true);
    //   event.stopPropagation();
    //   return;
    // }

    if (model.placaVeiculo) {
      if (isPlacaRegisteredVeiculo === false) {
        addToast({
          title: 'Erro',
          description: 'Placa de veículo não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    if (model.placaCarreta) {
      if (isPlacaRegisteredCarreta === false) {
        addToast({
          title: 'Erro',
          description: 'Placa de carreta não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }

    if (model.numeroBau) {
      if (isNumeroRegisteredBau === false) {
        addToast({
          title: 'Erro',
          description: 'Número de baú não existe no sistema!',
          type: 'error',
        });
        event.stopPropagation();
        return;
      }
    }
    if (isAllowedByRole(['user'])) {
      setLoading(true);
      try {
        const response = await api.get(`/checklist-veiculo/consulta-gerenciar-checklist`, {
          params: {
            ...model,
            idClienteTransportador: clienteId,
            filtrarCarreta: true,
          },
        });
        if (response.status === 200) {
          setLoading(false);
          setDataResults(response.data);
          if (response.data.length === 0) {
            addToast({
              title: 'Erro',
              description: 'Não existe resultado para esse filtro!',
              type: 'error',
            });
            event.stopPropagation();
            return;
          } else {
            history.push({
              pathname: '/checklist/resumo-checklist',
              state: { listagem: response.data, model: model, type: 'detalhado' },
            });
            return;
          }
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err.response);
      }
    } else {
      try {
        setLoading(true);
        const response = await api.get(`/checklist-veiculo/consulta-gerenciar-checklist`, {
          params: {
            ...model,
            filtrarCarreta: true,
          },
        });
        if (response.status === 200) {
          setLoading(false);
          setDataResults(response.data);
          if (response.data.length === 0) {
            addToast({
              title: 'Erro',
              description: 'Não existe resultado para esse filtro!',
              type: 'error',
            });
            event.stopPropagation();
            return;
          } else {
            console.log(response.data);
            console.log(model);

            history.push({
              pathname: '/checklist/resumo-checklist',
              state: { listagem: response.data, model: model, type: 'detalhado' },
            });
            return;
          }
        }
      } catch (err: any) {
        setLoading(false);
        console.log(err.response);
      }
    }
  };

  const verifyIfPlacaIsRegisteredVeiculo = async (value: string) => {
    if (model.idClienteEmbarcador) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-numequipamento-cliente-veiculo?idCliente=${model.idClienteEmbarcador}&placaVeiculo=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegisteredVeiculo(true);
        } else {
          setIsPlacaRegisteredVeiculo(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const verifyIfPlacaIsRegisteredCarreta = async (value: string) => {
    if (model.idClienteEmbarcador) {
      try {
        const response = await axios.get(
          `/veiculo/verificar-placa-cliente-carreta?idCliente=${model.idClienteEmbarcador}&placaCarreta=${value}`
        );
        if (response.data === true) {
          setIsPlacaRegisteredCarreta(true);
        } else {
          setIsPlacaRegisteredCarreta(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  const verifyIfNumeroIsRegisteredBau = async (value: string) => {
    if (model.idClienteEmbarcador) {
      try {
        const response = await axios.get(
          `/bau-intercambiavel/verificar-bau-cliente-numero-bau?idCliente=${model.idClienteEmbarcador}&numeroBau=${value}`
        );
        if (response.data === true) {
          setIsNumeroRegisteredBau(true);
        } else {
          setIsNumeroRegisteredBau(false);
        }
      } catch (err: any) {
        console.log(err);
      }
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <div className="titulo-container">
              <h2 className="titulo">Relatório Checklist Detalhado</h2>
              <h6 className="subtitulo">{'Consultas e Relatórios > Checklist > Detalhado'}</h6>
            </div>

            <Form noValidate validated={isFormInvalid} onSubmit={buscar}>
              {isAllowedByRole(['admin']) ? (
                <>
                  <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
                    <Form.Label>Embarcador</Form.Label>

                    <Dropdown
                      style={{ width: '100.4%' }}
                      value={embarcador}
                      options={listaEmbarcador}
                      placeholder="Selecione"
                      onChange={(e: any) => {
                        setModel({
                          ...model,
                          idClienteEmbarcador: e.target.value,
                        });

                        setEmbarcador(e.target.value);
                      }}
                      optionLabel="razaoSocial"
                      optionValue="idEmbarcador"
                      showClear
                      filter
                      className="multiselect-custom"
                      id="embarcador"
                    />
                  </Form.Group>

                  <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
                    <Form.Label>Transportador</Form.Label>

                    <Dropdown
                      style={{ width: '100.4%' }}
                      value={transportador}
                      options={listaTransportador}
                      placeholder="Selecione"
                      onChange={(e: any) => {
                        listaTransportador.map((each) => {
                          if (each.id === e.target.value) {
                            setModel({
                              ...model,
                              idClienteTransportador: e.target.value,
                              nomeClienteTransportador: each.razaoSocial,
                            });
                          }
                        });
                        setTransportador(e.target.value);
                      }}
                      optionLabel="razaoSocial"
                      optionValue="id"
                      showClear
                      filter
                      className="multiselect-custom"
                      id="transportador"
                      required
                    />
                  </Form.Group>
                </>
              ) : null}
              <Form.Label className="SMLancadasTitle">Busca por período</Form.Label>
              <Form.Row>
                <Form.Group className="row-modal">
                  <Form.Label>Data Início</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      e.target.value.length > 10 ? null : setDataInicio(e.target.value);
                      if (e.target.value !== '') {
                        setModel({
                          ...model,
                          dataInicio: toZoned(e.target.value),
                        });
                      }
                    }}
                    onBlur={() => {
                      if (dataFim && new Date(dataFim) < new Date(dataInicio)) {
                        setDataFimError('Data final não pode ser menor que a data inicial.');
                      } else {
                        setDataFimError('');
                      }
                    }}
                    value={dataInicio}
                    type="date"
                    id="date"
                    name="date"
                    placeholder=""
                  />
                  <Form.Control.Feedback type="invalid">
                    Campo Data Início não pode ser nulo.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="row-modal">
                  <Form.Label>Data Fim</Form.Label>
                  <Form.Control
                    onChange={(e) => {
                      e.target.value.length > 10 ? null : setDataFim(e.target.value);
                      if (e.target.value !== '') {
                        setModel({
                          ...model,
                          dataFim: toZoned(e.target.value, false),
                        });
                      }
                    }}
                    onBlur={() => {
                      if (dataInicio && new Date(dataFim) < new Date(dataInicio)) {
                        setDataFimError('Data final não pode ser menor que a data inicial.');
                      } else {
                        setDataFimError('');
                      }
                    }}
                    value={dataFim}
                    type="date"
                    id="dataFim"
                    name="dataFim"
                    placeholder=""
                  />
                  {dataFimError && (
                    <p style={{ fontSize: 14, color: 'red', marginTop: 5, position: 'absolute' }}>
                      {dataFimError}
                    </p>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Label className="SMLancadasTitle">Busca por tipo</Form.Label>

              <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
                <Form.Label>Tipo</Form.Label>

                <Dropdown
                  style={{ width: '100.4%' }}
                  value={tipo}
                  options={listaTipos}
                  placeholder="Selecione"
                  onChange={(e: any) => {
                    setModel({
                      ...model,
                      tipoFrota: e.target.value,
                    });
                    setTipo(e.target.value);
                  }}
                  optionLabel="nome"
                  optionValue="value"
                  showClear
                  className="multiselect-custom"
                  id="tipoFrota"
                />
              </Form.Group>

              {/* Validação se a placa existe no sistema, não é dinamico, é no post  */}
              <Form.Label className="SMLancadasTitle">Busca por veículo</Form.Label>

              <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
                <Form.Label>Veículo</Form.Label>

                <Form.Control
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setModel({
                        ...model,
                        placaVeiculo: null,
                      });
                    } else {
                      setModel({
                        ...model,
                        placaVeiculo: e.target.value,
                      });
                    }
                  }}
                  onBlur={() => {
                    if (!model.placaVeiculo) {
                      return;
                    }
                    verifyIfPlacaIsRegisteredVeiculo(model.placaVeiculo);
                  }}
                  value={model?.placaVeiculo ? model?.placaVeiculo : ''}
                  type="text"
                  id="placaVeiculo"
                  name="placaVeiculo"
                  placeholder=""
                />
              </Form.Group>

              {/* Validação se a placa Carreta existe no sistema, não é dinamico, é no post  */}
              <Form.Label className="SMLancadasTitle">Busca por carreta</Form.Label>

              <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
                <Form.Label>Carreta</Form.Label>

                <Form.Control
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setModel({
                        ...model,
                        placaCarreta: null,
                      });
                    } else {
                      setModel({
                        ...model,
                        placaCarreta: e.target.value,
                      });
                    }
                  }}
                  onBlur={() => {
                    if (!model.placaCarreta) {
                      return;
                    }
                    verifyIfPlacaIsRegisteredCarreta(model.placaCarreta);
                  }}
                  value={model?.placaCarreta ? model?.placaCarreta : ''}
                  type="text"
                  id="placaCarreta"
                  name="placaCarreta"
                  placeholder=""
                />
              </Form.Group>

              {/* Validação se a placa existe no sistema, não é dinamico, é no post  */}
              <Form.Label className="SMLancadasTitle">Busca por baú</Form.Label>

              <Form.Group className="row-modal" style={{ marginLeft: '-2px' }}>
                <Form.Label>Baú</Form.Label>

                <Form.Control
                  onChange={(e) => {
                    if (e.target.value === '') {
                      setModel({
                        ...model,
                        numeroBau: null,
                      });
                    } else {
                      setModel({
                        ...model,
                        numeroBau: e.target.value,
                      });
                    }
                  }}
                  onBlur={() => {
                    if (!model.numeroBau) {
                      return;
                    }
                    verifyIfNumeroIsRegisteredBau(model.numeroBau);
                  }}
                  value={model?.numeroBau ? model?.numeroBau : ''}
                  type="text"
                  id="numeroBau"
                  name="numeroBau"
                  placeholder=""
                />
              </Form.Group>

              <ButtonsForm />
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default FiltroChecklistDetalhado;
