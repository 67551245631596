import styled from 'styled-components';

export const MapContainer = styled.div`
  height: 360px;
  width: 25%;
  min-width: 326px;
  @media (max-width: 1280px) {
    width: 100%;
  }
`;
