/* eslint-disable */
import React, { FC, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Col, Form } from 'react-bootstrap';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { Spinner } from 'react-bootstrap';
import api from '../../config/axiosMaquina';
import Pagination from '../../components/Pagination/Pagination';
import { MarcaType } from '../../Types/MarcaCorTypes';
import { ModalDelete } from '../../components/ModalDelete';
import { MarcaRow } from './editableGrid';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { ImCancelCircle } from 'react-icons/im';
import { AiOutlineCheck } from 'react-icons/ai';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { Tela } from '../../components/Tela';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

const ConsultarMarca: FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const [idAtual, setIdAtual] = useState('');
  const [idEdit, setIdEdit] = useState(0);
  const [newLine, setNewLine] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [jaAberto, setJaAberto] = useState(false);
  const [marcas, setMarcas] = useState<MarcaType[]>([]);
  const [searchResults, setSearchResults] = useState<MarcaType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [nome, setNome] = useState('');
  const [codIntegracao, setCodIntegracao] = useState<number | null>(null);

  const [totalItems, setTotalItems] = useState(0);
  const [itensPerPage, setItensPerPage] = useState('10');
  const paginate = (pageNumber: number) => {
    history.push(`/listar/veiculo-marcas/${pageNumber}`);
  };
  const { page }: any = useParams();

  const [mensagem, setMensagem] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.MARCA] }], 'inserir');
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.MARCA] }], 'editar');
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.MARCA] }], 'excluir');
  }, []);

  useEffect(() => {
    const loadCount = async () => {
      setLoading(true);
      console.log(page - 1, itensPerPage);
      try {
        const response = await api.get(`/marca-veiculo/veiculomarca/count`);
        console.log(response.data);
        setLoading(false);
        setTotalItems(response.data);
      } catch (err: any) {
        setLoading(false);
        console.log(err);
      }
    };
    loadCount();
  }, []);

  useEffect(() => {
    const loadDadosMarcas = async () => {
      setLoading(true);
      console.log(page - 1, itensPerPage);
      try {
        const response = await api.get(`/marca-veiculo`, {
          params: {
            qtdRegistros: parseInt(itensPerPage),
            pagina: page - 1,
          },
        });
        console.log(response.data);
        setLoading(false);
        setMarcas(response.data);
      } catch (err: any) {
        setLoading(false);
        console.log(err);
      }
    };
    loadDadosMarcas();
  }, [page, itensPerPage]);

  useEffect(() => {
    const search = async () => {
      if (searchTerm.trim() && searchTerm) {
        history.push(`/listar/veiculo-marcas/1`);
        try {
          const response = await api.get(`/marca-veiculo/search?keyword=${searchTerm}`);
          setSearchResults(response.data);
        } catch (err) {
          console.error(err);
        }
      }
    };
    search();
  }, [searchTerm]);

  const handleDelete = async () => {
    try {
      const response = await api.put(`/marca-veiculo/delete-logico/${idDelete}`);
      console.log(response.data);
      setOpenModal(false);
      setIsDialogCadOK(true);
      setMensagem('Marca excluída com sucesso!');
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Erro ao excluir marca.',
        type: 'error',
      });
    }
  };

  async function handleSubmit() {
    setDisabled(false);
    if (nome.trim() != '') {
      try {
        const data = {
          vmaTxNome: nome,
          vmaCodIntegracao: Number(codIntegracao),
          vmaBlAtivo: true,
        };
        await api.post(`/marca-veiculo/veiculomarca`, data);
        setMensagem('Marca cadastrada com sucesso!');
        setIsDialogCadOK(true);
      } catch (err: any) {
        console.log(err);
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar marca.',
          type: 'error',
        });
      }
    } else {
      addToast({
        title: 'Erro',
        description: 'Verifique os campos obrigatórios.',
        type: 'error',
      });
    }
  }

  const handleCancel = () => {
    setDisabled(true);
    setNewLine(false);
    setNome('');
  };

  return (
    <Tela
      nome="Marca"
      caminho="Cadastro > Veículos > Marca"
      loading={loading}
      setLoading={setLoading}
    >
      <ModalDelete
        show={openModal}
        onHide={() => setOpenModal(false)}
        handleDelete={handleDelete}
        handleClose={() => setOpenModal(false)}
        message="Deseja excluir este registro?"
      />
      <Dialog
        header={mensagem}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
          window.location.reload();
        }}
      />
      <div className="search-create mb-4" style={{ width: '100%' }}>
        <div
          className="btn-create"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if (canInsert) {
              setNewLine(true);
              setIdEdit(0);
            } else {
              history.push('/acessonegado');
            }
          }}
        >
          <i
            style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
            className="pi pi-plus"
          ></i>
        </div>
        <span className="p-input-icon-left">
          <input
            type="text"
            placeholder="Pesquise"
            className="p-inputtext p-component"
            id="search-input"
            style={{ width: '240px' }}
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
          />
          <i className="pi pi-search"></i>
        </span>
      </div>

      <div className="table-responsive" style={{ flex: 1 }}>
        <table className="table">
          <thead className="thead">
            <tr>
              <th>
                Nome<span className="txt-obrigatorio"> *</span>
              </th>

              <th>
                Cód.Integração
                <span className="txt-obrigatorio"> *</span>
              </th>

              <th>Ações</th>
            </tr>
          </thead>
          {newLine && idEdit === 0 ? (
            <tr>
              <td>
                <input
                  placeholder="Nova marca"
                  type="text"
                  value={nome}
                  maxLength={64}
                  onChange={(e: any) => {
                    setNome(e.target.value);
                  }}
                />
              </td>
              <td>
                <input
                  placeholder="Cód.Integração"
                  type="number"
                  value={codIntegracao || ''} // Usa '' quando for null ou undefined
                  maxLength={64}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    setCodIntegracao(value === '' ? null : parseInt(value, 10)); // Corrige o tipo do setState
                  }}
                  min={1}
                />
              </td>
              <td>
                <ButtonTooltip onClick={handleCancel} type="Cancelar" />
                <ButtonTooltip onClick={handleSubmit} type="Enviar" />
              </td>
            </tr>
          ) : null}

          <tbody>
            {searchTerm.trim() === ''
              ? marcas.map((marca) => {
                  return (
                    <>
                      <MarcaRow
                        marca={marca}
                        setOpenModal={setOpenModal}
                        setCloseModal={setOpenModal}
                        setIdDelete={setIdDelete}
                        setIdEdit={setIdEdit}
                        idAtual={idEdit}
                        canEdit={canEdit}
                        canDelete={canDelete}
                      />
                    </>
                  );
                })
              : searchResults.map((marca) => {
                  return (
                    <>
                      <MarcaRow
                        marca={marca}
                        setOpenModal={setOpenModal}
                        setCloseModal={setOpenModal}
                        setIdDelete={setIdDelete}
                        setIdEdit={setIdEdit}
                        idAtual={idEdit}
                        canEdit={canEdit}
                        canDelete={canDelete}
                      />
                    </>
                  );
                })}
          </tbody>
        </table>
      </div>
      <Pagination
        totalPosts={searchTerm.trim() ? searchResults.length : totalItems}
        paginate={paginate}
        currentPage={page.toString()}
        itensPerPage={itensPerPage}
        setItensPerPage={setItensPerPage}
      />
    </Tela>
  );
};

export default ConsultarMarca;
