/* eslint-disable */
import Cookies from 'universal-cookie';
import { ClienteParticularidadesDTO } from '../Types/ParticularidadesType';

const cookies = new Cookies();

export const saveCookieParticularidadesData = (particularidades: ClienteParticularidadesDTO) => {
    cookies.set(
      'userParticularidades',
      {
        flagBloqueioSATNaSM: particularidades.flagBloqueioSATNaSM,
        flagCritiarAnaliseFrotaPropriaNaSM: particularidades.flagCritiarAnaliseFrotaPropriaNaSM,
        flagCriticarAnaliseAgregadoNaSM: particularidades.flagCriticarAnaliseAgregadoNaSM,
        flagCriticarAnaliseCondutorAgregadoNaSM: particularidades.flagCriticarAnaliseCondutorAgregadoNaSM,
        flagCriticarAnaliseCondutorAutonomoNaSM: particularidades.flagCriticarAnaliseCondutorAutonomoNaSM,
        flagCriticarAnaliseCondutorFuncionarioNaSM: particularidades.flagCriticarAnaliseCondutorFuncionarioNaSM,
        flagCriticarAnaliseNaoMotoristaAgregadoNaSM: particularidades.flagCriticarAnaliseNaoMotoristaAgregadoNaSM,
        flagCriticarAnaliseNaoMotoristaAutonomoNaSM: particularidades.flagCriticarAnaliseNaoMotoristaAutonomoNaSM,
        flagCriticarAnaliseNaoMotoristaNaSM: particularidades.flagCriticarAnaliseNaoMotoristaNaSM,
        flagCriticarAnaliseTerceiroNaSM: particularidades.flagCriticarAnaliseTerceiroNaSM,
        flagDadosEnderecoObrigatorio: particularidades.flagDadosEnderecoObrigatorio,
        flagExecutarRotaInternacional: particularidades.flagExecutarRotaInternacional,
        flagHabilitaPesquisasConsultasVigentes: particularidades.flagHabilitaPesquisasConsultasVigentes,
        flagHabilitarPesquisaAnttRntrc: particularidades.flagHabilitarPesquisaAnttRntrc,
        flagIncluirCdCadPonto: particularidades.flagIncluirCdCadPonto,
        flagIncluirRegionalCadPonto: particularidades.flagIncluirRegionalCadPonto,
        flagPermitirEdicao: particularidades.flagPermitirEdicao,
        flagPermitirPesquisaOutraEmpresa: particularidades.flagPermitirPesquisaOutraEmpresa,
        flagSolicitarFrotaNaSM: particularidades.flagSolicitarFrotaNaSM,
        flagSolicitarNumeroIdentificadorNaSM: particularidades.flagSolicitarNumeroIdentificadorNaSM,
        flagSolicitarNumeroRemessaNaSM: particularidades.flagSolicitarNumeroRemessaNaSM,
        flagSolicitarOperacaoNaSM: particularidades.flagSolicitarOperacaoNaSM,
        flagUtilizaApolicePropria: particularidades.flagUtilizaApolicePropria,
        flagUtilizaRankingAjudanteNaSM: particularidades.flagUtilizaRankingAjudanteNaSM,
        flagUtilizaRankingBauNaSM: particularidades.flagUtilizaRankingBauNaSM,
        flagUtilizaRankingCarretaNaSM: particularidades.flagUtilizaRankingCarretaNaSM,
        flagUtilizaRankingCondutorNaSM: particularidades.flagUtilizaRankingCondutorNaSM,
        flagUtilizaRankingVeiculoNaSM: particularidades.flagUtilizaRankingVeiculoNaSM,
        flagValidarChecklistPendente: particularidades.flagValidarChecklistPendente,
        id: particularidades.id,
        idCliente: particularidades.idCliente,
        isEmbarcador: particularidades.isEmbarcador,
        numeroDiasDeVeiculoAgregado: particularidades.numeroDiasDeVeiculoAgregado,
        numeroDiasDeVeiculoFrotaPropria: particularidades.numeroDiasDeVeiculoFrotaPropria,
        numeroDiasVeiculoFrotaEspecial: particularidades.numeroDiasVeiculoFrotaEspecial,
        numeroDiasVeiculoTerceiro: particularidades.numeroDiasVeiculoTerceiro,
        validadeAnaliseCondutorAgregado: particularidades.validadeAnaliseCondutorAgregado,
        validadeAnaliseCondutorAutonomo: particularidades.validadeAnaliseCondutorAutonomo,
        validadeAnaliseCondutorFuncionario: particularidades.validadeAnaliseCondutorFuncionario,
        validadeAnaliseNaoMotoristaAgregado: particularidades.validadeAnaliseNaoMotoristaAgregado,
        validadeAnaliseNaoMotoristaAutonomo: particularidades.validadeAnaliseNaoMotoristaAutonomo,
        validadeAnaliseNaoMotoristaFuncionario: particularidades.validadeAnaliseNaoMotoristaFuncionario,
        validadeAnaliseVeiculoAgregado: particularidades.validadeAnaliseVeiculoAgregado,
        validadeAnaliseVeiculoFrotaPropria: particularidades.validadeAnaliseVeiculoFrotaPropria,
        validadeAnaliseVeiculoTerceiro: particularidades.validadeAnaliseVeiculoTerceiro,
      },
      { path: '/' }
    );
  };

  export const getCookieParticularidadesData = (): ClienteParticularidadesDTO => {
    const particularidadesCookieData = cookies.get('userParticularidades') ?? '{}';
    return particularidadesCookieData;
  };