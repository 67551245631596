/* eslint-disable */

import React, { useState, useEffect } from 'react';
import { Tree } from 'primereact/tree';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Form, Col, Modal, Button, Spinner } from 'react-bootstrap';
import { orderBy, uniqBy } from 'lodash';
import { Dialog } from 'primereact/dialog';
import newAxios from 'axios';
import axiosRequest from 'axios';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import api from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { soNumeros } from '../../util/Validacoes/Validacoes';
import { dataConverter } from '../../util/date';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import axios from '../../config/axiosMaquina';

type UsuarioPerfilType = {
  nome: string;
  ativo: boolean;
  id?: number;
};

type FuncionalidadeType = {
  consulta: boolean;
  dataCadastroAssociacao: string | Date;
  delete: boolean;
  idMenuGrupo: number;
  idUsuCadastroAssociacao: number;
  insert: boolean;
  update: boolean;
};

export const CadastroPerfisUsuarios = () => {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const [perfil, setPerfil] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingChecagem, setLoadingChecagem] = useState(false);
  const [show, setShow] = useState(false);
  const [header, setHeader] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [fullData, setFullData] = useState<any[]>([]);
  const [fullLista, setFullLista] = useState<any[]>([]);
  const [load, setLoad] = useState(false);
  const location = useLocation();
  const [selected, setSelected] = useState<any>();
  const [usuarioPerfil, setUsuarioPerfil] = useState<UsuarioPerfilType>({
    nome: '',
    ativo: true,
  });

  const { addToast } = useToast();
  const { id }: any = useParams();
  const { role, usuarioId, isEmbarcador, clienteId } = getCookieSessionData().usuarioVO;
  const history = useHistory();

  const [expandedKeys, setExpandedKeys] = useState({});
  const [nomesIguais, setNomesIguais] = useState(false);

  const [roleCustomer, setRoleCustomer] = useState('user');

  useEffect(() => {
    if (!id) return;

    const checkCustomer = async () => {
      const response = await axios.get(`/cliente/getIdRazaoSocial`);

      const cliente = response.data.find(
        ({ id: idCustomer }: { id: number }) => idCustomer === Number(id)
      );

      if (!cliente) {
        setRoleCustomer('user');
        return;
      }

      setRoleCustomer(cliente.clienteEmbarcador ? 'embarcador' : 'user');
    };

    const state = history.location.state as { customerRole: string };

    if (state) {
      setRoleCustomer(state.customerRole);
      return;
    }

    checkCustomer();
  }, [id]);

  useEffect(() => {
    setPerfil(role);
  }, [role]);

  const expandNode = (node: any, _expandedKeys: any) => {
    if (node.children && node.children.length) {
      _expandedKeys[node.key] = true;

      for (const child of node.children) {
        expandNode(child, _expandedKeys);
      }
    }
  };

  const expandAll = (d: any) => {
    const _expandedKeys = {};
    for (const node of d) {
      expandNode(node, _expandedKeys);
    }

    setExpandedKeys(_expandedKeys);
  };

  // SE FOR EDIÇÃO
  const findById = async () => {
    setLoading(true);
    try {
      const response = await axiosRequest.get(`${process.env.REACT_APP_API_URL}/profile-menu/${id}`);

      setUsuarioPerfil({
        ...response.data,

        listaMenuPerfilFuncionalidade: response.data.functionalities,
        nome: response.data.profile_name,
        role,
      });

      const list = response.data.functionalities;
      let newSelected = {};
      for (let i = 0; i < list.length; i += 1) {
        const idConsulta = `${list[i].idMenuGrupo}:Consulta`;
        const idDelete = `${list[i].idMenuGrupo}:Exclusao`;
        const idUpdate = `${list[i].idMenuGrupo}:Edicao`;
        const idInsert = `${list[i].idMenuGrupo}:Inclusao`;
        const childrenPreenchido = list.filter((e: any) => e.idPai === list[i].idMenuGrupo);
        const childrenOriginal = fullData.filter((f: any) => f.idPai === list[i].idMenuGrupo);
        const childrenPrenChecked = childrenPreenchido.filter(
          (g: any) => g.insert && g.read && g.update && g.delete
        );
        const checked =
          childrenOriginal.length > 0 &&
          childrenPreenchido.length > 0 &&
          childrenOriginal.length === childrenPrenChecked.length;
        const partialChecked =
          childrenOriginal.length > 0 &&
          childrenPreenchido.length > 0 &&
          !(childrenOriginal.length === childrenPrenChecked.length);

        if (list[i]) {
          if (childrenOriginal.length > 0) {
            newSelected = {
              ...newSelected,
              [list[i].idMenuGrupo]: {
                checked,
                partialChecked,
              },
            };
          } else if (list[i].insert && list[i].update && list[i].delete && list[i].read) {
            newSelected = {
              ...newSelected,
              [list[i].idMenuGrupo]: {
                checked: true,
                partialChecked: false,
              },
            };
          } else if (list[i].insert || list[i].update || list[i].delete || list[i].read) {
            newSelected = {
              ...newSelected,
              [list[i].idMenuGrupo]: {
                checked: false,
                partialChecked: true,
              },
            };
          } else {
            newSelected = {
              ...newSelected,
              [list[i].idMenuGrupo]: {
                checked: false,
                partialChecked: false,
              },
            };
          }
          newSelected = {
            ...newSelected,
            [idDelete]: {
              checked: list[i].delete,
              partialChecked:
                !list[i].delete && !list[i].update && !list[i].insert && !list[i].read,
            },
            [idUpdate]: {
              checked: list[i].update,
              partialChecked:
                !list[i].delete && !list[i].update && !list[i].insert && !list[i].read,
            },
            [idInsert]: {
              checked: list[i].insert,
              partialChecked:
                !list[i].delete && !list[i].update && !list[i].insert && !list[i].read,
            },
            [idConsulta]: {
              checked: list[i].read,
              partialChecked:
                !list[i].delete && !list[i].update && !list[i].insert && !list[i].read,
            },
          };
        }
      }

      setSelected(newSelected);
      setLoading(false);
    } catch (error: any) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
          EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
          ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
        },
      ],
      'editar'
    );
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
          EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
          ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
        },
      ],
      'excluir'
    );
  }, [TRANSPORTADOR, EMBARCADOR, ADMIN, rolePermission]);

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.includes('cadastro')) return;

    if (id && fullData.length) {
      findById();
    }
  }, [id, fullData, perfil]);

  const confereNome = async (nome: string) => {
    setLoadingChecagem(true);
    try {
      const response = await api.get(
        `/usuario/consulta-existencia-menu-perfil?idCliente=${
          role === 'admin' && !id ? '' : id || clienteId
        }&nomeMenuPerfil=${nome}&role=${role}&profileType=customer`
      );
      setNomesIguais(response.data);
    } catch (error: any) {
      console.log(error);
    } finally {
      setLoadingChecagem(false);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (nomesIguais) {
      addToast({
        title: 'Erro',
        description: 'Nome de Perfil já existe',
        type: 'error',
      });
      return;
    }
    setLoading(true);
    // FORMATA ESTRUTURA DO ARRAY PARA ENVIAR NO ENDPOINT
    const listaMenu: FuncionalidadeType[] = [];
    if (selected) {
      const keys = Object.entries(selected);
      const array = keys.map((each: any) => {
        return { id: each[0], value: each[1].checked };
      });
      for (let i = 0; i < array.length; i += 1) {
        if (
          !array[i].id.includes(':') &&
          array.find((each: any) => each.id.includes(`${array[i].id}:`))
        ) {
          listaMenu.push({
            dataCadastroAssociacao: dataConverter(new Date().toISOString()),
            delete: array.find((each: any) => each.id === `${array[i].id}:Exclusao`)
              ? array.find((each: any) => each.id === `${array[i].id}:Exclusao`)?.value
              : false,
            insert: array.find((each: any) => each.id === `${array[i].id}:Inclusao`)
              ? array.find((each: any) => each.id === `${array[i].id}:Inclusao`)?.value
              : false,
            consulta: array.find((each: any) => each.id === `${array[i].id}:Consulta`)
              ? array.find((each: any) => each.id === `${array[i].id}:Consulta`)?.value
              : false,
            idMenuGrupo: parseInt(array[i].id, 10),
            update: array.find((each: any) => each.id === `${array[i].id}:Edicao`)
              ? array.find((each: any) => each.id === `${array[i].id}:Edicao`)?.value
              : false,
            idUsuCadastroAssociacao: usuarioId,
            // isEmbarcador: usuarioPerfil.role === 'embarcador',
          });
        } else if (
          !listaMenu.find(
            (f: FuncionalidadeType) => f.idMenuGrupo === parseInt(soNumeros(array[i].id), 10)
          )
        ) {
          listaMenu.push({
            dataCadastroAssociacao: dataConverter(new Date().toISOString()),
            delete: false,
            insert: false,
            consulta: false,
            idMenuGrupo: parseInt(soNumeros(array[i].id), 10),
            update: false,
            idUsuCadastroAssociacao: usuarioId,
          });
        }
      }
    }
    const d = {
      ativo: true,
      dataCadastro: dataConverter(new Date().toISOString()),
      listaMenuPerfilFuncionalidade: listaMenu,
      idUsuarioCadastro: usuarioId,
      nomeMenu: usuarioPerfil.nome,
      idCliente: Number(id),
    };

    try {
      let response: any = {};
      if (location.pathname.includes('edicao')) {
        response = await newAxios.put(
          `${process.env.REACT_APP_API_URL}/profile-menu/update/?id=${id}`,
          d
        );
        setHeader('Editado com sucesso!');
        setLoading(false);
      } else {
        response = await newAxios.post(`${process.env.REACT_APP_API_URL}/profile-menu/create/`, d);
        setHeader('Cadastrado com sucesso!');
        setLoading(false);
      }
      setIsDialogCadOK(true);
    } catch (error) {
      setLoading(false);

      addToast({
        title: 'Erro',
        description: `Erro ao ${id ? 'Editar' : 'Cadastrar'}`,
        type: 'error',
      });
      console.log(error);
    }
  };

  const converteArray = (dataL: any) => {
    // FORMATA ESTRUTURA DO ARRAY PARA USAR NA ÁRVORE
    const array: any[] = [];
    dataL.forEach((each: any) => {
      const filhos = dataL.filter((e: any) => e.idPai === each.id);
      if (!each.idPai) {
        if (filhos.length) {
          array.push({
            key: each.id,
            label: each.nomeMenu,
            children: filhos.map((e: any) => {
              const netos = dataL.filter((f: any) => f.idPai === e.id);
              if (netos.length) {
                return {
                  key: e.id,
                  label: e.nomeMenu,
                  children: netos.map((g: any) => {
                    const bisnetos = dataL.filter((f: any) => f.idPai === g.id);
                    if (bisnetos.length) {
                      return {
                        key: g.id,
                        label: g.nomeMenu,
                        children: bisnetos.map((h: any) => {
                          return {
                            key: h.id,
                            label: h.nomeMenu,
                            children: [
                              { key: `${h.id}:Consulta`, label: 'Consulta' },
                              { key: `${h.id}:Inclusao`, label: 'Inclusão' },
                              { key: `${h.id}:Edicao`, label: 'Edição' },
                              { key: `${h.id}:Exclusao`, label: 'Exclusão' },
                            ],
                          };
                        }),
                      };
                    }
                    return {
                      key: g.id,
                      label: g.nomeMenu,
                      children: [
                        { key: `${g.id}:Consulta`, label: 'Consulta' },
                        { key: `${g.id}:Inclusao`, label: 'Inclusão' },
                        { key: `${g.id}:Edicao`, label: 'Edição' },
                        { key: `${g.id}:Exclusao`, label: 'Exclusão' },
                      ],
                    };
                  }),
                };
              }
              return {
                key: e.id,
                label: e.nomeMenu,
                children: [
                  { key: `${e.id}:Consulta`, label: 'Consulta' },
                  { key: `${e.id}:Inclusao`, label: 'Inclusão' },
                  { key: `${e.id}:Edicao`, label: 'Edição' },
                  { key: `${e.id}:Exclusao`, label: 'Exclusão' },
                ],
              };
            }),
          });
        } else {
          array.push({
            key: each.id,
            label: each.nomeMenu,
            children: [
              { key: `${each.id}:Consulta`, label: 'Consulta' },
              { key: `${each.id}:Inclusao`, label: 'Inclusão' },
              { key: `${each.id}:Edicao`, label: 'Edição' },
              { key: `${each.id}:Exclusao`, label: 'Exclusão' },
            ],
          });
        }
      }
    });
    return array;
  };

  const handleData = async () => {
    try {
      setLoad(true);
      const response = await api.get('/menu-grupo/list-com-embarcador');
      const comEmbarcador = response.data.map((each: any) => {
        return {
          ...each,
          roleUsuarioPermitido: each.isEmbarcador ? 'embarcador' : each.roleUsuarioPermitido,
        };
      });

      setFullLista(uniqBy(comEmbarcador, 'id'));
      setLoad(false);
    } catch (error: any) {
      setLoad(false);
      console.log(error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  useEffect(() => {
    if (fullLista.length) {
      let listaPorRole: any[] = [];


      listaPorRole = orderBy(
        fullLista.filter((each: any) => each.roleUsuarioPermitido === roleCustomer),
        'id'
      );

      setPerfil(perfil);

      setFullData(listaPorRole);
      setData(converteArray(listaPorRole));
      if (id) {
        expandAll(converteArray(listaPorRole));
      }
    }
  }, [fullLista, perfil]);

  const handleDelete = async () => {
    try {
      await axiosRequest.put(`${process.env.REACT_APP_API_URL}/profile-menu/delete/?id=${id}`);
      setShow(false);

      setIsDialogDeleteOK(true);
    } catch (error: any) {
      console.log(error);
    }
  };

  const [isDialogDeleteOK, setIsDialogDeleteOK] = useState(false);

  return (
    <>
      <Dialog
        header="Deletado com sucesso!"
        footer={
          <Button
            onClick={() => {
              history.push('/listar-perfis-usuarios/1');
              setIsDialogDeleteOK(false);
            }}
          >
            Ok
          </Button>
        }
        visible={isDialogDeleteOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogDeleteOK(false);
        }}
      />

      <Dialog
        header={header}
        footer={
          <Button
            onClick={() => {
              setIsDialogCadOK(false);
            }}
          >
            Ok
          </Button>
        }
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro {` "${usuarioPerfil.nome}" `}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => setShow(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={handleDelete}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
      <Tela loading={loading} setLoading={setLoading} onClickDelete={setShow} canDelete={canDelete}>
        <Form onSubmit={handleSubmit}>
          <Form.Row>
            <Form.Group as={Col} className="ml-4 mt-3">
              <Form.Label className="required">Nome do Perfil</Form.Label>
              <Form.Control
                value={usuarioPerfil.nome}
                onChange={(e: any) => {
                  setUsuarioPerfil({ ...usuarioPerfil, nome: e.target.value });
                  confereNome(e.target.value);
                }}
                type="text"
                maxLength={80}
                required
              />
              {nomesIguais && (
                <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                  Nome de Perfil já existe
                </p>
              )}
            </Form.Group>
          </Form.Row>

          {load ? (
            <div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginTop: 40,
                }}
              >
                <Spinner animation="border" variant="warning" />
                <p style={{ marginTop: 10 }}>Carregando funcionalidades</p>
              </div>
            </div>
          ) : (
            <>
              {data && (
                <Tree
                  expandedKeys={expandedKeys}
                  onToggle={(e) => setExpandedKeys(e.value)}
                  style={{ marginLeft: '23px', marginTop: '30px' }}
                  value={data}
                  selectionMode="checkbox"
                  selectionKeys={selected}
                  onSelectionChange={(e) => {
                    setSelected(e.value);
                  }}
                />
              )}
            </>
          )}

          <ButtonsForm canEdit={canEdit} disabled={loadingChecagem} />
        </Form>
      </Tela>
    </>
  );
};
