/* eslint-disable */
import React, { useState, useEffect, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Form, Col, Button, Navbar, InputGroup } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { InfracoesType, PenalidadesType } from '../../api/model/NaoConformidadeType';
import { getCookieSessionData } from '../../services/cookieService';
import { useToast } from '../../hooks/Toast';

function CadastroNaoConformidade() {
  const history = useHistory();
  const { addToast } = useToast();
  const [isValidNatureza, setIsValidNatureza] = useState(true);
  const [isValidCausa, setIsValidCausa] = useState(true);
  const [causaRaiz, setCausaRaiz] = useState('');
  const [naturezaEvento, setNaturezaEvento] = useState('');
  const [processoMacro, setProcessoMacro] = useState('');
  const [penalidadeId, setPenalidadeId] = useState('');
  const [infracaoId, setInfracaoId] = useState('');

  const [penalidades, setPenalidades] = useState<PenalidadesType[]>([]);
  const [infracoes, setInfracoes] = useState<InfracoesType[]>([]);

  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/penalidade-nao-conformidade`);
        setPenalidades(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/infracao-nao-conformidade`);
        setInfracoes(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  const handleRegister = async (event: any) => {
    event.preventDefault();

    if (!penalidadeId || !naturezaEvento || !infracaoId) {
      addToast({
        title: 'Erro!',
        description: 'Verifique os dados preenchidos.',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    const data = {
      causaRaiz: causaRaiz,
      naturezaEvento: naturezaEvento,
      processoMacro: processoMacro,
      infracaoId: infracaoId,
      penalidadeId: penalidadeId,
      ativo: true,
      penalidadeDescricao: null,
      listPenalidade: null,
      infracaoDescricao: null,
      listInfracao: null,
      clienteId: clienteId,
      listCliente: null,
    };
    try {
      await axios.post('/nao-conformidade', data);
      // alert('Cadastro efetuado com sucesso.');
      addToast({
        title: 'Sucesso!',
        description: 'Cadastro efetuado com sucesso.',
        type: 'success',
      });
      setTimeout(function () {
        history.push('/listar-naoconformidades/1');
      }, 1500);
    } catch {
      // const alerta = alert('Erro ao cadastrar');
      addToast({
        title: 'Erro!',
        description: 'Erro ao cadastrar.',
        type: 'error',
      });
      return;
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card" style={{ backgroundColor: '#f2f2f2', borderColor: '#f2f2f2' }}>
        <Container fluid style={{ marginTop: '0px' }}>
          <Navbar style={{ width: '100%', marginTop: '20px' }}>
            <h2 className="titulo">Não Conformidades</h2>
            <h6 className="subtitulo">{'Cadastro > Não Conformidade'}</h6>
          </Navbar>

          <Form onSubmit={handleRegister} noValidate validated={isFormInvalid}>
            <Form.Group
              as={Col}
              controlId="formGridAddress1"
              style={{ marginLeft: '-14px', marginTop: '6%' }}
            >
              <Form.Label className="required">Natureza do Evento</Form.Label>
              <Form.Control
                value={naturezaEvento}
                onChange={(event) => {
                  setNaturezaEvento(event.target.value);
                }}
                type="text"
                maxLength={64}
                required
                style={{ width: '102%' }}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '-13px' }}>
              <Form.Label>Causa Raiz</Form.Label>

              <Form.Control
                value={causaRaiz}
                onChange={(event) => {
                  setCausaRaiz(event.target.value);
                }}
                type="text"
                maxLength={64}
                minLength={3}
                style={{ width: '102%' }}
              />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridAddress1" style={{ marginLeft: '-14px' }}>
              <Form.Label>Processo Macro</Form.Label>

              <Form.Control
                value={processoMacro}
                onChange={(event) => {
                  setProcessoMacro(event.target.value);
                }}
                type="text"
                maxLength={32}
                style={{ width: '102%' }}
              />
            </Form.Group>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label className="required">Penalidade</Form.Label>

                <Form.Control
                  value={penalidadeId}
                  onChange={(event) => {
                    setPenalidadeId(event.target.value);
                  }}
                  required
                  as="select"
                >
                  <option value="">Selecione uma opção</option>
                  {penalidades.map((penalidade) => (
                    <option value={penalidade.id} key={penalidade.id}>
                      {penalidade.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridAddress1">
                <Form.Label className="required">Infração</Form.Label>

                <Form.Control
                  value={infracaoId}
                  onChange={(event) => {
                    setInfracaoId(event.target.value);
                  }}
                  required
                  as="select"
                >
                  <option value="">Selecione uma opção</option>
                  {infracoes.map((infracao) => (
                    <option value={infracao.id} key={infracao.id}>
                      {infracao.descricao}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <ButtonsForm />
          </Form>
        </Container>
      </div>
    </div>
  );
}

export default CadastroNaoConformidade;
