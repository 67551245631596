import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { cpfMask, maskCpnj } from '../../util/Validacoes/Validacoes';
import { Tela } from '../../components/Tela';
import { filterItems2 } from '../../util/format';
import Table from '../../components/TableComponent';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

type ProprietarioType = {
  id: number;
  razaoSocial: string;
  tipo: string;
  cnpjCpf: string;
};

const ListagemProprietario = () => {
  const { ADMIN, TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const history = useHistory();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = searchTerm.trim() !== '' ? dataSearch : data;
  const paginate = (pageNumber: number) => {
    history.push(`/cliente/listagem/proprietario/${pageNumber}`);
  };
  const headers = {
    id: 'Id',
    tipo: 'Tipo de Pessoa',
    cnpjCpf: 'CPF / CNPJ',
    razaoSocial: 'Nome / Razão Social',
  };

  useEffect(() => {
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PROPRIETARIO],
          ADMIN: [ADMIN.CADASTRO.PROPRIETARIO],
        },
      ],
      'inserir'
    );
  }, [ADMIN, TRANSPORTADOR, rolePermission]);

  const getProprietariosGrid = async ({ idCliente, pagina, qtdRegistros, search }: any) => {
    if (search === '' || !search) {
      setLoading(true);
    }
    try {
      setData([]);

      const response = await axios.get(`/proprietario/cliente/${idCliente}`, {
        params: {
          pagina,
          qtdRegistros,
          keyword: search && search.trim() !== '' ? search : null,
        },
      });

      const countResponse = await axios.get(`/proprietario/count/cliente-proprietario`, {
        params: {
          idCliente,
          keyword: search && search.trim() !== '' ? search : null,
        },
      });

      setCount(countResponse.data);
      const array = response.data.map((each: ProprietarioType) => {
        return {
          id: each.id,
          tipo:
            each.tipo === 'f' || each.tipo === 'F'
              ? 'Física'
              : each.tipo === 'J' || each.tipo === 'j'
              ? 'Jurídica'
              : '',
          cnpjCpf:
            each.cnpjCpf === null
              ? ''
              : each.cnpjCpf.length === 11
              ? cpfMask(each.cnpjCpf)
              : maskCpnj(each.cnpjCpf),
          razaoSocial: each.razaoSocial,
        };
      });
      setData(array);
    } catch (err: any) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProprietariosGrid({ idCliente: clienteId, pagina: page, qtdRegistros: itensPerPage });
  }, [page, itensPerPage, clienteId]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/cliente/listagem/proprietario/1');
      getProprietariosGrid({
        idCliente: clienteId,
        pagina: 1,
        qtdRegistros: itensPerPage,
        search: searchValue,
      });
      // setDataSearch(filterItems2(data, searchValue));
    } else {
      getProprietariosGrid({
        idCliente: clienteId,
        pagina: 1,
        qtdRegistros: itensPerPage,
        search: searchValue,
      });
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Proprietários"
      caminho="Cadastro > Proprietários"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      linkToAdd="/cadastro-proprietario"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm ? dataSearch.length : count}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      <Table
        items={data}
        headers={headers}
        hasId
        handleEdit={(id: any) => history.push(`/editar-proprietario/${id}`)}
      />
    </Tela>
  );
};

export default ListagemProprietario;
