/* eslint-disable */
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { IoMdCloudUpload } from 'react-icons/io';
import { DropContainer, UploadMessage } from './styles';
import { useToast } from '../../../hooks/Toast';

function Upload({ setDocument }: any) {
  const { addToast } = useToast();
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    multiple: false,

    maxFiles: 1,
    onDrop: (onDropAcceptedFiles) => {
      if (onDropAcceptedFiles.length) {
        if (onDropAcceptedFiles[0].name.endsWith('.xml')) {
          setDocument(onDropAcceptedFiles[0]);
        } else {
          addToast({
            title: 'Erro',
            description: ' Só é possivel fazer o upload de um arquivo no formato: XML',
            type: 'error',
          });
        }
      }
    },
  });

  const renderDragMessage = useCallback(() => {
    if (!isDragActive) {
      return (
        <UploadMessage>
          Clique ou arraste seu arquivo até aqui ...
          <IoMdCloudUpload size={60} />
          Tipo de arquivo permitido: .xml
        </UploadMessage>
      );
    }

    return <UploadMessage type="success">Solte seu arquivo aqui</UploadMessage>;
  }, [isDragActive, isDragReject]);
  return (
    <DropContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {renderDragMessage()}
    </DropContainer>
  );
}

export default Upload;
