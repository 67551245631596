/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosMaquina';
import { Link, useHistory, useParams } from 'react-router-dom';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Pagination from '../../components/Pagination/Pagination';
import { Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { obterDataHora } from '../../util/date';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

export type SatGridType = {
  dataFinalizacao: string;
  dataInclusao: string;
  dataUltimaAlteracao: string;
  idSat: number;
  marcaVeiculo: string;
  modeloVeiculo: string;
  numeroBau: string;
  placaVeiculo: string;
  tipoFrota: string;
  transportador: string;
  modeloBau: string;
  placaCarreta: string;
  marcaCarreta: string;
  tipoFrotaCarreta: string;
  modeloCarreta: string;
  tipoBau: string;
};

function GridSat() {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const [loading, setLoading] = useState(false);
  const [listaSat, setListaSat] = useState<SatGridType[]>([]);
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<SatGridType[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [totalPosts, setTotalPosts] = useState(0);
  const { page }: any = useParams();

  const paginate = (pageNumber: number) => {
    history.push(`/admin/listagem-sat/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ASSISTENCIA_TECNICA]}], 'inserir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const pageInt = parseInt(page, 10);
    if (pageInt <= 0 || !pageInt) {
      history.goBack();
    }
  }, [page]);

  useEffect(() => {
    const fetchQtdPosts = async () => {
      const response = await axios.get(`/solicitacao-assistencia-tecnica/grid-sat`);
      setTotalPosts(response.data.length);
      console.log(response.data.length);
    };
    fetchQtdPosts();
  }, []);

  const loadTipo = (tipo: string) => {
    if (tipo === '1') {
      return 'Próprio';
    }
    if (tipo === '2') {
      return 'Agregado';
    }
    if (tipo === '3') {
      return 'Terceiro';
    }
    if (tipo === '') {
      return '';
    }
  };

  const loadLista = async () => {
    if (!page || !itensPerPage) return;
    setLoading(true);
    const response = await axios.get('/solicitacao-assistencia-tecnica/grid-sat', {
      params: {
        pagina: page - 1,
        qtdRegistros: itensPerPage,
      },
    });
    setListaSat(response.data);
    setLoading(false);
    console.log(response.data);
  };

  useEffect(() => {
    loadLista();
  }, [page, itensPerPage]);

  // search do back
  useEffect(() => {
    const search = async () => {
      if (searchTerm.trim() != '') {
        const response = await axios.get(
          `/solicitacao-assistencia-tecnica/search-grid-sat?keyword=${searchTerm}&pagina=${
            page - 1
          }&qtdRegistros=${itensPerPage}`
        );
        setSearchResults(response.data);
      }
    };
    search();
  }, [searchTerm]);

  function satVisualizar(id: number) {
    history.push(`/cliente/operacional/visualizarsat/${id}`);
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Solicitação Assistência Técnica</h2>
          <h6 className="subtitulo">
            {'Operacional > Solicitações > Solicitação Assistência Técnica'}
          </h6>
        </div>

        <div className="search-create mb-4">
          {canInsert ? (
            <Link to="/cliente/operacional/cadastrosat" className="btn-create">
              <i
                style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                className="pi pi-plus"
              ></i>
            </Link>
          ) : (
            <div
              className="disabled-create-button"
              aria-hidden="true"
              onClick={() => history.push('/acessonegado')}
            >
              <i
                style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                className="pi pi-plus"
              />
            </div>
          )}
          <span className="p-input-icon-left">
            <input
              type="text"
              placeholder="Pesquise"
              className="p-inputtext p-component"
              id="search-input"
              style={{ width: '240px' }}
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            <i className="pi pi-search"></i>
          </span>
        </div>

        <div className="painel" style={{ width: '100%' }}>
          <div className="table-responsive" style={{ width: '100%' }}>
            {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>ID SAT</th>
                    <th>Transportador</th>
                    <th>Nº Placa / Baú</th>
                    <th>Marca / Modelo</th>
                    <th>Tipo Frota</th>
                    <th>Data de Lançamento</th>
                    <th>Data de Finalização</th>
                    <th></th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {searchTerm.trim() !== ''
                    ? searchResults.map((each) => (
                        <tr>
                          <td>{each.idSat}</td>
                          <td>{each.transportador}</td>
                          <td>
                            {each.numeroBau !== null
                              ? each.numeroBau
                              : each.placaVeiculo !== null
                              ? each.placaVeiculo
                              : each.placaCarreta}
                          </td>
                          <td>
                            {each.marcaVeiculo !== null
                              ? `${each.marcaVeiculo} / ${each.modeloVeiculo}`
                              : each.modeloBau !== null
                              ? each.modeloBau
                              : each.marcaCarreta !== null
                              ? `${each.marcaCarreta} / ${each.modeloCarreta}`
                              : ''}
                          </td>
                          <td>
                            {each.tipoFrota !== null
                              ? loadTipo(each.tipoFrota)
                              : each.tipoFrotaCarreta !== null
                              ? loadTipo(each.tipoFrotaCarreta)
                              : each.tipoBau !== null
                              ? loadTipo(each.tipoBau)
                              : ''}
                          </td>

                          <td>
                            {each.dataInclusao === null
                              ? ''
                              : obterDataHora(each.dataInclusao, true)}
                          </td>
                          <td>
                            {each.dataFinalizacao === null
                              ? ''
                              : obterDataHora(each.dataFinalizacao, true)}
                          </td>
                          <td>
                            <ButtonTooltip
                              onClick={() => satVisualizar(each.idSat)}
                              type="Visualizar"
                            />
                          </td>
                        </tr>
                      ))
                    : listaSat.map((each) => (
                        <tr>
                          <td>{each.idSat}</td>
                          <td>{each.transportador}</td>
                          <td>
                            {each.numeroBau !== null
                              ? each.numeroBau
                              : each.placaVeiculo !== null
                              ? each.placaVeiculo
                              : each.placaCarreta}
                          </td>
                          <td>
                            {each.marcaVeiculo !== null
                              ? `${each.marcaVeiculo} / ${each.modeloVeiculo}`
                              : each.modeloBau !== null
                              ? each.modeloBau
                              : each.marcaCarreta !== null
                              ? `${each.marcaCarreta} / ${each.modeloCarreta}`
                              : ''}
                          </td>
                          <td>
                            {each.tipoFrota !== null
                              ? loadTipo(each.tipoFrota)
                              : each.tipoFrotaCarreta !== null
                              ? loadTipo(each.tipoFrotaCarreta)
                              : each.tipoBau !== null
                              ? loadTipo(each.tipoBau)
                              : ''}
                          </td>

                          <td>
                            {each.dataInclusao === null
                              ? ''
                              : obterDataHora(each.dataInclusao, true)}
                          </td>
                          <td>
                            {each.dataFinalizacao === null
                              ? ''
                              : obterDataHora(each.dataFinalizacao, true)}
                          </td>
                          <td>
                            <Button
                              tooltip="Visualizar SAT"
                              tooltipOptions={{ position: 'bottom' }}
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                padding: 0,
                                paddingRight: 5,
                                marginBottom: 2,
                              }}
                            >
                              <i
                                title="Visualizar SAT"
                                key={each.idSat}
                                onClick={() => satVisualizar(each.idSat)}
                                className="pi pi-eye"
                                style={{ color: 'black' }}
                              ></i>
                            </Button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            )}

            <Pagination
              itensPerPage={itensPerPage}
              setItensPerPage={setItensPerPage}
              totalPosts={searchTerm ? searchResults.length : totalPosts}
              paginate={paginate}
              currentPage={page}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default GridSat;
