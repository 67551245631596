/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '../../hooks/Toast';
import { ButtonTooltip } from '../ButtonTooltipo';
import './styles.css';

type PropsType = {
  titles: any[];
  data: any[][];
  setData: (data: any[][]) => void;
  excluir: (i: number) => void;
  editar: (data: any[]) => void;
  criar: (data: any[]) => void;
  disabledColumns?: number[];
  requiredColumns?: number[];
  novaLinha: boolean;
  setNovaLinha: (novaLinha: boolean) => void;
};

export const GridEditavelComponent = ({
  titles,
  data,
  setData,
  excluir,
  editar,
  criar,
  disabledColumns,
  requiredColumns,
  novaLinha,
  setNovaLinha,
}: PropsType) => {
  const [listDisabled, setListDisabled] = useState<boolean[]>([]);
  const [newLine, setNewLine] = useState<any[]>([]);
  const { addToast } = useToast();

  const preparaArray = (data2: string[][]) => {
    let array: any = [];
    data2.map((p: any, index2: number) => {
      return array.push(true);
    });
    setListDisabled(array);
  };

  useEffect(() => {
    if (!listDisabled.length) {
      preparaArray(data);
    }
  }, [data]);

  const onChange = useCallback((e: any, i: number, position: number) => {
    const array = data.map((p: any, index2: number) => {
      if (index2 === position) {
        p.map((each: any, index: number) => {
          if (i === index) {
            p[i] = e.target.value;
          }
        });
      }
      return p;
    });
    setData(array);
  }, []);

  const handleEdit = (e: any, i: number) => {
    e.preventDefault();
    const array = listDisabled.map((p: any, index2: number) => {
      return i === index2 ? p[i] === false : true;
    });
    setListDisabled(array);
  };

  const handleDelete = (e: any, i: number) => {
    e.preventDefault();
    excluir(i);
  };

  const handleCancel = (e: any, i: number) => {
    e.preventDefault();
    const array = listDisabled.map((p: any, index2: number) => {
      return true;
    });
    setListDisabled(array);
  };

  const handleSubmit = (e: any, i: number) => {
    e.preventDefault();
    editar(data[i]);
  };

  // gera corpo da nova linha
  const newLineFunction = () => {
    const array = new Array(data[0].length).fill('');
    setNewLine(array);
  };

  useEffect(() => {
    if (novaLinha) {
      newLineFunction();
    } else {
      handleCancelAdd();
    }
  }, [novaLinha]);

  const onChangeAdd = useCallback((newL: any[], e: any, i: number) => {
    const array = newL.map((each: any, index: number) => {
      if (i === index) {
        return e.target.value;
      }
      return each;
    });
    setNewLine(array);
  }, []);

  const handleCancelAdd = () => {
    setNewLine([]);
    setNovaLinha(false);
  };

  const handleSubmitAdd = (e: any) => {
    e.preventDefault();
    if (isNull(newLine)) {
      addToast({
        title: 'Erro',
        description: 'Preencha os campos obrigatórios.',
        type: 'error',
      });
      e.stopPropagation();
      return;
    }
    criar(newLine);
  };

  const isNull = (object: any[]) => {
    const nonNull = object.filter(
      (each: any, index: number) =>
        each.trim().length === 0 && requiredColumns?.find((e: any) => e === index)
    );
    return !!nonNull.length;
  };

  return (
    <table className="table">
      <thead className="thead">
        <tr>
          {titles.map((title: string, i: number) => (
            <>
              <th>
                {title}
                {!disabledColumns?.find((e: any) => e === i) &&
                !!requiredColumns?.find((e: any) => e === i) ? (
                  <span className="txt-obrigatorio"> *</span>
                ) : null}
              </th>
            </>
          ))}
          <th> </th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        <tr className="teste">
          {novaLinha &&
            newLine &&
            newLine.map((each: string, i: number) => (
              <td>
                <input
                  className="inputGrid"
                  value={each}
                  onChange={(e: any) => onChangeAdd(newLine, e, i)}
                  required={
                    !disabledColumns?.find((e: any) => e === i) &&
                    !!requiredColumns?.find((e: any) => e === i)
                  }
                />
              </td>
            ))}
          {novaLinha && (
            <>
              <td>
                <ButtonTooltip type="Cancelar" onClick={handleCancelAdd} />
              </td>
              <td>
                <ButtonTooltip type="Enviar" onClick={handleSubmitAdd} />
              </td>
            </>
          )}
        </tr>
        {data.map((d: string[], position: number) => (
          <tr className="teste">
            {d.map((each: string, i: number) => (
              <td>
                <input
                  className="inputGrid"
                  value={each}
                  onChange={(e: any) => onChange(e, i, position)}
                  disabled={listDisabled[position] || disabledColumns?.includes(i)}
                />
              </td>
            ))}
            {listDisabled[position] ? (
              <>
                <td>
                  <ButtonTooltip type="Editar" onClick={(e: any) => handleEdit(e, position)} />
                </td>
                <td>
                  <ButtonTooltip type="Excluir" onClick={(e: any) => handleDelete(e, position)} />
                </td>
              </>
            ) : (
              <>
                <td>
                  <ButtonTooltip type="Cancelar" onClick={(e: any) => handleCancel(e, position)} />
                </td>
                <td>
                  <ButtonTooltip type="Enviar" onClick={(e: any) => handleSubmit(e, position)} />
                </td>
              </>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
