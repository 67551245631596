/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import {
  IoMailUnread,
  IoCheckmarkSharp,
  IoPencilSharp,
  IoCheckbox,
  IoCloseSharp,
  IoMailOpenSharp,
  IoWarning,
  IoAddCircleOutline,
} from 'react-icons/io5';
import { PendentesObj } from '../../types';
import './styles.css';
import instanceMaquina from '../../config/axiosMaquina';
import { useHistory } from 'react-router-dom';
import ModalCancelamento from './ModalCancelamento';
import { Dialog } from 'primereact/dialog';
import { obterDataHora } from '../../util/date';

type Props = {
  data: PendentesObj[];
  atualizaPosCancelamento: boolean;
  setAtualizaPosCancelamento: (atualizaPosCancelamento: boolean) => void;
  currentTab: string;
  canInsertNaoConformidade?: boolean;
  canDelete?: boolean;
  canEditSat?: boolean;
  canEditNaoConformidade?: boolean;
  canInsertSat?: boolean;
  isDialogAtualizandoOK?: boolean;
  setIsDialogAtualizandoOK?: (isDialogAtualizandoOK: boolean) => void;
};

const Table: React.FC<Props> = ({
  data,
  currentTab,
  canInsertNaoConformidade,
  canEditSat,
  canDelete,
  canEditNaoConformidade,
  canInsertSat,
  isDialogAtualizandoOK,
  setIsDialogAtualizandoOK,
  atualizaPosCancelamento,
  setAtualizaPosCancelamento,
}) => {
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [show, setShow] = useState(false);
  const [pisoCargaOperacional, setPisoCargaOperacional] = useState<number>(0);
  const [pisoCargaGerencial, setPisoCargaGerencial] = useState<number>(0);
  const [visualizados, setVisualizados] = useState<number[]>([]);
  const history = useHistory();

  const handleVisualizar = async (id: number, visualizado: boolean) => {
    const foundItem = data?.find((each) => each.id === id);
    if (!foundItem) {
      return;
    }

    // const dataDispositivos = {
    //   idCheck: foundItem.idCheck,
    //   idCheckBau: foundItem.idCheckBau,
    //   idSat: foundItem.idSat,
    //   idBauSat: foundItem.idSatBau,
    //   idCarSat: foundItem.idSatCar,
    //   idCarSat2: foundItem.idSatCar2,
    //   idCarCheck: foundItem.idCheckCar,
    //   idCar2Check: foundItem.idCheckCar2,
    //   veiculoPlaca: foundItem.placa,
    //   veiculoId: foundItem.idVeiculo,
    //   carretaPlaca: foundItem.placaCarreta,
    //   carretaId: foundItem.carretaId,
    //   carreta2Placa: foundItem.placaCarreta2,
    //   carreta2Id: foundItem.carreta2Id,
    //   bauNumero: foundItem.numeroBau,
    //   bauId: foundItem.bauId,
    //   clienteId: foundItem.idCliente,
    //   dataInicializacaoSM: foundItem.dataInicioReal
    //     ? foundItem.dataInicioReal
    //     : foundItem.dataInicio,
    // };

    try {
      if (foundItem.situacao === 'PENDENTE') {
        // history.push({
        //   pathname: `/sm/iniciar/${id}`,
        //   state: { ...dataDispositivos },
        // });
        window.open(
          `/sm/iniciar/${id}`,
          'janela',
          'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
        );
      } else {
        // history.push({
        //   pathname: `/finalizar-sm-emandamento/${id}`,
        //   state: { ...dataDispositivos },
        // });
        window.open(
          `/finalizar-sm-emandamento/${id}`,
          'janela',
          'width=750, height=800, top=100, left=699, scrollbars=yes, status=no, toolbar=no, location=no, directories=no, menubar=no, resizable=no, fullscreen=no'
        );
      }

      if (visualizado) {
        return;
      }
      const arr = visualizados;
      arr.push(foundItem.id);
      setVisualizados(arr);

      await instanceMaquina.get('/solicitacao-monitoramento/visto', {
        params: {
          idSolicitacaoMonitoramento: id,
        },
      });
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleGoToCadastrarSat = (id: number) => {
    const foundItem = data?.find((each) => each.id === id);

    if (!foundItem) {
      return;
    }
    history.push({
      pathname: `/monitoramento/cadastro-sat/${id}`,
      state: {
        veiculoPlaca: foundItem.placa,
        veiculoId: foundItem.idVeiculo,
        carretaPlaca: foundItem.placaCarreta,
        carretaId: foundItem.carretaId,
        carreta2Placa: foundItem.placaCarreta2,
        carreta2Id: foundItem.carreta2Id,
        bauNumero: foundItem.numeroBau,
        bauId: foundItem.bauId,
        clienteId: foundItem.idCliente,
        tab: currentTab,
        idSATVeiculo: foundItem.idSat,
        idSATBau: foundItem.idSatBau,
        idSATCarreta: foundItem.idSatCar,
        idSATCarreta2: foundItem.idSatCar2,
        dataInicializacaoSM: foundItem.dataInicioReal
          ? foundItem.dataInicioReal
          : foundItem.dataInicio,
      },
    });
  };

  const handleGoToLancarNaoConformidade = (id: number, idCliente: number, empresa: string) => {
    history.push({
      pathname: `/lancar-nao-conformidade/${id}/${idCliente}/${empresa}`,
      state: { tab: currentTab },
    });
  };

  const handleParametros = async () => {
    try {
      const response = await instanceMaquina.get('/parametro-operacional');
      setPisoCargaOperacional(response.data.pisoCargasEspeciais);
      const response2 = await instanceMaquina.get('/parametro-gerencial');
      setPisoCargaGerencial(response2.data.pisoCargasEspeciais);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleParametros();
  }, []);

  const handleColor = (valorCar: number, cancelamento: boolean) => {
    if (pisoCargaGerencial && pisoCargaOperacional) {
      if (cancelamento !== null) {
        return 'success-row';
      } else if (valorCar > pisoCargaGerencial) {
        return 'alert-row';
      } else if (valorCar > pisoCargaOperacional) {
        return 'attention-row';
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  return (
    <>
      <Dialog
        header="Atualizando..."
        visible={isDialogAtualizandoOK}
        style={{ width: '20vw' }}
        modal
        onHide={() => {
          if (setIsDialogAtualizandoOK) {
            setIsDialogAtualizandoOK(false);
          }
        }}
      />
      <ModalCancelamento
        show={show}
        setShow={setShow}
        idItem={selectedItem}
        atualizaPosCancelamento={atualizaPosCancelamento}
        setAtualizaPosCancelamento={setAtualizaPosCancelamento}
      />{' '}
      <div className="table-responsive" style={{ flex: 1 }}>
        <div className="painel">
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Nº S.M</th>
                <th>Empresa</th>
                <th>Origem</th>
                <th>Destino</th>
                <th>Placa</th>
                <th>Tecnologia</th>
                <th>Valor Carga</th>
                {currentTab === 'PENDENTE' || currentTab === 'EM_ANDAMENTO' ? (
                  <th>Escolta/Isca</th>
                ) : null}
                {currentTab === 'PAMCARY' ? null : currentTab === 'EM_ANDAMENTO' ? (
                  <th>Início Real</th>
                ) : (
                  <th>Previsão</th>
                )}
                {currentTab !== 'PAMCARY' ? <th>Inclusão</th> : null}
                {currentTab === 'ALTO_VALOR' || currentTab === 'PAMCARY' ? <th>Situação</th> : null}
                <th></th>
              </tr>
            </thead>
            {!data || !data[0] ? null : (
              <tbody>
                {data &&
                  data.map((each) => (
                    <tr
                      key={each.id}
                      style={{
                        fontWeight:
                          !each.visualizado && !visualizados.find((e: any) => e === each.id)
                            ? 'bold'
                            : '',
                      }}
                      className={handleColor(each.valorCarga, each.cancelamento)}
                    >
                      <td>{each.id}</td>
                      <td>{each.empresa}</td>
                      <td>{each.origem}</td>
                      <td>{each.destino}</td>
                      <td>{each.placa}</td>
                      <td>{each.tecnologia}</td>
                      <td>
                        {each.valorCarga?.toLocaleString('pt-br', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                      </td>
                      {currentTab === 'PENDENTE' || currentTab === 'EM_ANDAMENTO' ? (
                        <td
                          title={
                            (each.isca === true && each.escolta === false
                              ? `Nº Isca: ${each.iscaEquipamento}`
                              : '') ||
                            (each.escolta === true && each.isca === false
                              ? `Veículo Escolta: ${each.veiculoEscolta}`
                              : '') ||
                            (each.escolta === true && each.isca === true
                              ? `Veículo Escolta: ${each.veiculoEscolta}\nNº Isca: ${each.iscaEquipamento}`
                              : '')
                          }
                        >
                          {each.escolta === true && each.isca === false ? 'Escolta' : ''}
                          {each.isca === true && each.escolta === false ? ' Isca' : ''}
                          {each.isca === true && each.escolta === true ? ' Escolta / Isca' : ''}
                        </td>
                      ) : null}
                      {currentTab !== 'PAMCARY' && currentTab !== 'EM_ANDAMENTO' ? (
                        <td>{obterDataHora(each.previsao, true)}</td>
                      ) : null}
                      {currentTab === 'EM_ANDAMENTO' ? (
                        <td>{obterDataHora(each.dataInicioReal, true)}</td>
                      ) : null}
                      {currentTab !== 'PAMCARY' ? (
                        <td>{obterDataHora(each.inclusao, true)}</td>
                      ) : null}
                      {currentTab === 'ALTO_VALOR' || currentTab === 'PAMCARY' ? (
                        <td>
                          <span className="situacao">
                            {each.situacao === 'EM_ANDAMENTO'
                              ? 'Em andamento'
                              : each.situacao.toLowerCase()}
                          </span>
                        </td>
                      ) : null}
                      <td id="icons-container">
                        {currentTab !== 'PENDENTE' ? null : (
                          <Button
                            tooltip={each.hintChecklist}
                            tooltipOptions={{ position: 'bottom' }}
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              padding: 0,
                              paddingRight: 5,
                              marginBottom: 2,
                            }}
                          >
                            <IoCheckmarkSharp size={22} color={each.hintColor} />
                          </Button>
                        )}
                        <Button
                          tooltip="Visualizar SM"
                          tooltipOptions={{ position: 'bottom' }}
                          style={{
                            backgroundColor: 'transparent',
                            borderColor: 'transparent',
                            padding: 0,
                            paddingRight: 5,
                            marginBottom: 2,
                          }}
                          onClick={() => {
                            handleVisualizar(each.id, each.visualizado);
                          }}
                        >
                          {!each.visualizado && !visualizados.find((e: any) => e === each.id) ? (
                            <IoMailUnread size={22} color="#9F9F9F" />
                          ) : (
                            <IoMailOpenSharp size={22} color="#9F9F9F" />
                          )}
                        </Button>
                        {currentTab === 'PAMCARY' || currentTab === 'ALTO_VALOR' ? null : (
                          <Button
                            tooltip="Editar SAT"
                            tooltipOptions={{ position: 'bottom' }}
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              padding: 0,
                              paddingRight: 5,
                              marginBottom: 2,
                            }}
                            onClick={() => {
                              if (canEditSat || canInsertSat) {
                                handleGoToCadastrarSat(each.id);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          >
                            {each.idSat || each.idSatBau || each.idSatCar || each.idSatCar2 ? (
                              <IoWarning size={22} color="#ffc800" />
                            ) : (
                              <IoPencilSharp size={22} color="#9F9F9F" />
                            )}
                          </Button>
                        )}
                        {currentTab === 'PAMCARY' || currentTab === 'ALTO_VALOR' ? null : (
                          <Button
                            tooltip="Não conformidades"
                            tooltipOptions={{ position: 'bottom' }}
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              padding: 0,
                              paddingRight: 5,
                              marginBottom: 2,
                            }}
                            onClick={() => {
                              if (canInsertNaoConformidade || canEditNaoConformidade) {
                                handleGoToLancarNaoConformidade(
                                  each.id,
                                  each.idCliente,
                                  each.empresa
                                );
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          >
                            <IoCheckbox size={22} color="#9F9F9F" />
                          </Button>
                        )}
                        {currentTab !== 'PENDENTE' ? null : (
                          <Button
                            tooltip="Cancelar SM"
                            tooltipOptions={{ position: 'bottom' }}
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              padding: 0,
                              paddingRight: 5,
                              marginBottom: 2,
                            }}
                            onClick={() => {
                              if (canDelete) {
                                setSelectedItem(each.id);
                                setShow(true);
                              } else {
                                history.push('/acessonegado');
                              }
                            }}
                          >
                            <IoCloseSharp size={22} color="#9F9F9F" />
                          </Button>
                        )}

                        {currentTab === 'EM_ANDAMENTO' ? (
                          <Button
                            tooltip="Procedimentos do Cliente"
                            tooltipOptions={{ position: 'bottom' }}
                            style={{
                              backgroundColor: 'transparent',
                              borderColor: 'transparent',
                              padding: 0,
                              paddingRight: 5,
                              marginBottom: 2,
                            }}
                          >
                            <IoAddCircleOutline size={22} color="#9F9F9F" />
                          </Button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </>
  );
};

export default Table;
