/* eslint-disable */

import { useState, useEffect } from 'react';
import { IoFunnelOutline } from 'react-icons/io5';
import { useHistory, useLocation } from 'react-router-dom';
import BootstrapButton from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import axios from '../../../../config/axiosMaquina';
import Search from '../../../../components/Search/Search';
import { getCookieSessionData } from '../../../../services/cookieService';
import { useToast } from '../../../../hooks/Toast';
import { pesquisar } from '../../../../util/format';
import { Tela } from '../../../../components/Tela';
import { Spinner } from '../../../../components/Spinner';
import { Tabela } from '../../../../components/Tabela';
import { obterData, obterDataHora, obterHora, toZoned } from '../../../../util/date';
import { PERMISSION } from '../../../../constants/Permissionamento';
import { useRolePermission } from '../../../../context/RolePermissionAuth';

type PesquisaData = {
  clienteId: number;
  dtOcorrenciaInicial: string;
  dtOcorrenciaFinal: string;
  placaVeiculo: string;
  cpfCondutor: string;
  idSM: number;
  observacao: string;
  naoConformidadeId: number;
  situacao: string;
  nomeLiberador: string;
  tipoOperacao: string;
  ufIbgeId: number;
  usuarioId: number;
  prontaResposta: string;
  id: number;
  origemViagem: string;
  destinoViagem: string;
  clienteRazaoSocial: string;
  veiculoPlaca: string;
  condutorCpf: string;
  ufSigla: string;
  naoConformidadeNaturezaEvento: string;
  naoConformidadeInfracao: string;
  naoConformidadePenalidade: string;
  naoConformidadeQtdePontos: string;
  pagina: number;
  qtdRegistros: number;
};

type NCLancada = {
  ativo?: any;
  dataOcorrencia: any;
  smId: any;
  clienteId: number;
  placaVeiculo: string;
  cpfCondutor: string;
  cnpjEmbarcador?: string;
  nomeCondutor: string;
  descricaoObs: string;
  naoConformidadeId: number;
  situacao: string;
  nomeLiberador: string;
  tipoOperacao: string;
  ufIbgeId: number;
  usuarioId: number;
  prontaResposta: string;
  id: number;
  origemRota: string;
  destinoRota: string;
  clienteRazaoSocial: string;
  veiculoPlaca: string;
  condutorCpf: string;
  ufSigla: string;
  naoConformidadeNaturezaEvento: string;
  naoConformidadeInfracao: string;
  naoConformidadePenalidade: string;
  naoConformidadeQtdePontos: string;
  valorCargaSm: number;
  horaOcorrencia: string;
  embarcadorDonoCarga: string;
  origemViagem: string;
  destinoViagem: string;
  carretaPlaca: string;
  carreta2Placa: string;
};

type ExcelGeneration = {
  ySteps: number;
  columns: { title: string; widthPx?: any; style?: any }[];
  data: { value: any }[][];
};

const GridConsultaNaoConformidades = () => {
  const { ADMIN, TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canDelete } = useRolePermission();

  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dadosPesquisa, setDadosPesquisa] = useState<PesquisaData>({} as PesquisaData);
  const [id, setId] = useState<number | undefined>();
  const [idSM, setIdSM] = useState<number | undefined>();
  const [searchValue, setSearchValue] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const [currentPage, setCurrentPage] = useState(1);
  const roleLogado = getCookieSessionData().usuarioVO.role;
  const { isEmbarcador, usuarioId, cnpj } = getCookieSessionData().usuarioVO;
  const { addToast } = useToast();
  const history = useHistory();
  const location: { state: PesquisaData } = useLocation();
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();
  const [show, setShow] = useState(false);
  const [showDialogOk, setShowDialogOk] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dataXls, setDataXls] = useState<NCLancada[] | undefined>();
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const indexOfLastPost = currentPage * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [searchTerm, setSearchTerm] = useState('');
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const titlesTransportador = [
    '+id',
    'N° SM',
    'EMBARCADOR',
    'VEÍCULO',
    'CONDUTOR',
    'NÃO CONFORMIDADE',
    'OCORRÊNCIA',
  ];
  const titlesEmbarcador = [
    '+id',
    'N° SM',
    'EMBARCADOR',
    'VEÍCULO',
    'CONDUTOR',
    'NÃO CONFORMIDADE',
    'OCORRÊNCIA',
    'PENALIDADE',
    'INFRAÇÃO',
    'PONTOS',
  ];
  const titlesCorretoraSeguradora = [
    '+id',
    'N° SM',
    'PLACA',
    'CONDUTOR',
    'EMPRESA',
    'EMBARCADOR',
    'NÃO CONFORMIDADE',
    'OBSERVAÇÕES',
    'DATA OCORRÊNCIA',
  ];
  const titlesAdmin = [
    '+id',
    'N° SM',
    'PLACA',
    'CONDUTOR',
    'EMPRESA',
    'EMBARCADOR',
    'NÃO CONFORMIDADE',
    'OBSERVAÇÕES',
    'DATA OCORRÊNCIA',
  ];
  const paginate = (pageNumber: number) => {
    // history.push(`/nao-conformidade/consulta/grid/${pageNumber}`);
    setCurrentPage(pageNumber);
  };

  const converteState = () => {
    const dataPesquisa: PesquisaData = {} as PesquisaData;

    if (location.state && roleLogado === 'user' && isEmbarcador === false) {
      if (location.state.dtOcorrenciaInicial) {
        dataPesquisa.dtOcorrenciaInicial = location.state.dtOcorrenciaInicial;
      }
      if (location.state.dtOcorrenciaFinal) {
        dataPesquisa.dtOcorrenciaFinal = location.state.dtOcorrenciaFinal;
      }
      if (location.state.placaVeiculo) {
        dataPesquisa.placaVeiculo = location.state.placaVeiculo;
      }
      if (location.state.cpfCondutor) {
        dataPesquisa.cpfCondutor = location.state.cpfCondutor;
      }
      if (location.state.idSM) {
        dataPesquisa.idSM = location.state.idSM;
      }
    }

    if (
      (location.state && roleLogado === 'admin') ||
      roleLogado === 'corretora' ||
      roleLogado === 'seguradora'
    ) {
      if (location.state.clienteId) {
        dataPesquisa.clienteId = location.state.clienteId;
      }
      if (location.state.dtOcorrenciaInicial) {
        dataPesquisa.dtOcorrenciaInicial = location.state.dtOcorrenciaInicial;
      }
      if (location.state.dtOcorrenciaFinal) {
        dataPesquisa.dtOcorrenciaFinal = location.state.dtOcorrenciaFinal;
      }
      if (location.state.placaVeiculo) {
        dataPesquisa.placaVeiculo = location.state.placaVeiculo;
      }
      if (location.state.idSM) {
        dataPesquisa.idSM = location.state.idSM;
      }

      if (location.state.observacao) {
        dataPesquisa.observacao = location.state.observacao;
      }
    }

    if (location.state && roleLogado === 'user' && isEmbarcador === true) {
      if (location.state.clienteId) {
        dataPesquisa.clienteId = location.state.clienteId;
      }
      if (location.state.naoConformidadeId) {
        dataPesquisa.naoConformidadeId = location.state.naoConformidadeId;
      }
      if (location.state.dtOcorrenciaInicial) {
        dataPesquisa.dtOcorrenciaInicial = location.state.dtOcorrenciaInicial;
      }
      if (location.state.dtOcorrenciaFinal) {
        dataPesquisa.dtOcorrenciaFinal = location.state.dtOcorrenciaFinal;
      }
      if (location.state.placaVeiculo) {
        dataPesquisa.placaVeiculo = location.state.placaVeiculo;
      }
      if (location.state.idSM) {
        dataPesquisa.idSM = location.state.idSM;
      }

      if (location.state.situacao) {
        dataPesquisa.situacao = location.state.situacao;
      }
    }

    setDadosPesquisa(dataPesquisa);
    return dataPesquisa;
  };

  useEffect(() => {
    rolePermission(
      [
        {
          ADMIN: [ADMIN.CONSULTA_RELATORIO.NAO_CONFORMIDADE],
          TRANSPORTADOR: [TRANSPORTADOR.OPERACIONAL.CONSULTA_E_RELATORIO.NAO_CONFORMIDADE],
        },
      ],
      'excluir'
    );
  }, [ADMIN, TRANSPORTADOR, rolePermission]);

  useEffect(() => {
    handleRequest(converteState());
  }, [location]);

  const handleRequest = async (request: any) => {
    setLoading(true);
    let response: any;
    try {
      if (roleLogado === 'user' && !isEmbarcador) {
        response = await axios.post(
          `/nao-conformidade-lancamento/consulta-relatorio-lancamento-nao-conformidade-cliente`,
          { ...request, idUsuario: usuarioId }
        );
      }
      if (roleLogado === 'user' && isEmbarcador) {
        response = await axios.post(
          `/nao-conformidade-lancamento/consulta-relatorio-lancamento-nao-conformidade-embarcador`,
          {
            ...request,
            cnpjEmbarcador: cnpj,
          }
        );
      }
      if (roleLogado === 'admin' || roleLogado === 'corretora' || roleLogado === 'seguradora') {
        response = await axios.post(
          `/nao-conformidade-lancamento/consulta-relatorio-lancamento-nao-conformidade`,
          { ...request }
        );
      }
      if (response.data.length) {
        let array: any[];
        setDataXls(response.data);
        // transportador
        if (roleLogado === 'user' && !isEmbarcador) {
          array = response.data
            .filter((each: NCLancada) => each.ativo)
            .map((each: NCLancada) => {
              return [
                each.id,
                each.smId,
                each.embarcadorDonoCarga ? each.embarcadorDonoCarga : '',
                [each.veiculoPlaca, each.carretaPlaca, each.carreta2Placa]
                  .filter((placa) => placa !== '')
                  .join(' '),
                each.nomeCondutor ? each.nomeCondutor : '',
                // each.clienteRazaoSocial ? each.clienteRazaoSocial : '',
                each.naoConformidadeNaturezaEvento ? each.naoConformidadeNaturezaEvento : '',
                // each.descricaoObs ? each.descricaoObs : '',
                each.dataOcorrencia && each.horaOcorrencia
                  ? `${obterData(each.dataOcorrencia)} ${obterHora(each.horaOcorrencia)}`
                  : '',
              ];
            });
          setData(array);
        }
        // embarcador
        if (roleLogado === 'user' && isEmbarcador) {
          array = response.data
            .filter((each: NCLancada) => each.ativo)
            .map((each: NCLancada) => {
              return [
                each.id,
                each.smId,
                each.embarcadorDonoCarga ? each.embarcadorDonoCarga : '',
                [each.veiculoPlaca, each.carretaPlaca, each.carreta2Placa]
                  .filter((placa) => placa !== '')
                  .join(' '),
                each.nomeCondutor ? each.nomeCondutor : '',
                // each.clienteRazaoSocial ? each.clienteRazaoSocial : '',
                each.naoConformidadeNaturezaEvento ? each.naoConformidadeNaturezaEvento : '',
                // each.descricaoObs ? each.descricaoObs : '',
                each.dataOcorrencia && each.horaOcorrencia
                  ? `${obterData(each.dataOcorrencia)} ${obterHora(each.horaOcorrencia)}`
                  : '',
                each.naoConformidadePenalidade ? each.naoConformidadePenalidade : '',
                each.naoConformidadeInfracao ? each.naoConformidadeInfracao : '',
                each.naoConformidadeQtdePontos ? each.naoConformidadeQtdePontos : '',
              ];
            });
          setData(array);
        }
        // admin
        if (roleLogado === 'admin') {
          array = response.data
            .filter((each: any) => each.ativo)
            .map((each: NCLancada) => {
              return [
                each.id,
                each.smId,
                [each.veiculoPlaca, each.carretaPlaca, each.carreta2Placa]
                  .filter((placa) => placa !== '')
                  .join(' '),
                each.nomeCondutor ? each.nomeCondutor : '',
                each.clienteRazaoSocial ? each.clienteRazaoSocial : '',
                each.embarcadorDonoCarga ? each.embarcadorDonoCarga : '',
                each.naoConformidadeNaturezaEvento ? each.naoConformidadeNaturezaEvento : '',
                each.descricaoObs ? each.descricaoObs : '',
                each.dataOcorrencia && each.horaOcorrencia
                  ? `${obterData(each.dataOcorrencia)} ${obterHora(each.horaOcorrencia)}`
                  : '',
              ];
            });
          setData(array);
        }
        // corretora e seguradora
        if (roleLogado === 'corretora' || roleLogado === 'seguradora') {
          array = response.data
            .filter((each: any) => each.ativo)
            .map((each: NCLancada) => {
              return [
                each.id,
                each.smId,
                [each.veiculoPlaca, each.carretaPlaca, each.carreta2Placa]
                  .filter((placa) => placa !== '')
                  .join(' '),
                each.nomeCondutor ? each.nomeCondutor : '',
                each.clienteRazaoSocial ? each.clienteRazaoSocial : '',
                each.embarcadorDonoCarga ? each.embarcadorDonoCarga : '',
                each.naoConformidadeNaturezaEvento ? each.naoConformidadeNaturezaEvento : '',
                each.descricaoObs ? each.descricaoObs : '',
                each.dataOcorrencia && each.horaOcorrencia
                  ? `${obterData(each.dataOcorrencia)} ${obterHora(each.horaOcorrencia)}`
                  : '',
              ];
            });
          setData(array);
        }
      } else {
        addToast({
          title: 'Info',
          description: 'Não foi possível encontrar SM utilizando este filtro',
          type: 'info',
        });
      }
    } catch (error: any) {
      addToast({
        title: 'Info',
        description: 'Erro ao consultar não conformidades.',
        type: 'info',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!dataXls) {
      return;
    }

    if (roleLogado === 'admin' || roleLogado === 'corretora' || roleLogado === 'seguradora') {

      setExcelGeneration([
        {
          ySteps: 0,
          columns: [
            {
              title: 'Consulta Não Conformidades',
              style: { font: { sz: '24', bold: true } },
            },
          ],
          data: [],
        },
        {
          ySteps: 0,
          columns: [],
          data: [
            [
              {
                value: `Período: ${
                  dadosPesquisa.dtOcorrenciaInicial
                    ? `${obterData(dadosPesquisa.dtOcorrenciaInicial)}à${obterData(
                        dadosPesquisa.dtOcorrenciaFinal
                      )}`
                    : '/ / a / /'
                }`,
              },
            ],
          ],
        },
        {
          ySteps: 0,
          columns: [
            { title: 'Viagem' },
            { title: 'Cliente' },
            { title: 'Embarcador' },
            { title: 'Veículo' },
            { title: 'CPF Condutor' },
            { title: 'Nome Condutor' },
            { title: 'Origem' },
            { title: 'Destino' },
            { title: 'Não Conformidade' },
            { title: 'Observações' },
            { title: 'Data Ocorrencia' },
            { title: 'Valor Carga' },
          ],
          data: dataXls.map((each: NCLancada) => [
            { value: each.smId != null ? each.smId : '' },
            { value: each.clienteRazaoSocial != null ? each.clienteRazaoSocial : '' },
            { value: each.embarcadorDonoCarga != null ? each.embarcadorDonoCarga : '' },
            { value: each.veiculoPlaca != null ? each.veiculoPlaca : '' },
            { value: each.cpfCondutor != null ? each.cpfCondutor : '' },
            { value: each.nomeCondutor != null ? each.nomeCondutor : '' },
            {
              value: each.origemViagem ? each.origemViagem : each.origemRota ? each.origemRota : '',
            },
            {
              value: each.destinoViagem ? each.destinoViagem : each.destinoRota ? each.destinoRota : '',
            },
            {
              value:
                each.naoConformidadeNaturezaEvento != null
                  ? each.naoConformidadeNaturezaEvento
                  : '',
            },
            { value: each.descricaoObs != null ? each.descricaoObs : '' },
            { value: each.dataOcorrencia != null ? obterDataHora(each.dataOcorrencia, true) : '' },
            {
              value:
                each.valorCargaSm != null
                  ? Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(each.valorCargaSm)
                  : '',
            }, // valor da carga
          ]),
        },
      ]);
    }

    if (roleLogado === 'user' && isEmbarcador === false) {
      setExcelGeneration([
        {
          ySteps: 0,
          columns: [
            {
              title: 'Consulta Não Conformidades',
              style: { font: { sz: '24', bold: true } },
            },
          ],
          data: [],
        },
        {
          ySteps: 0,
          columns: [],
          data: [
            [
              {
                value: `Período: ${
                  dadosPesquisa.dtOcorrenciaInicial
                    ? `${obterData(dadosPesquisa.dtOcorrenciaInicial)}à${obterData(
                        dadosPesquisa.dtOcorrenciaFinal
                      )}`
                    : '/ / a / /'
                }`,
              },
            ],
          ],
        },
        {
          ySteps: 0,
          columns: [
            { title: 'Viagem' },
            { title: 'Cliente' },
            { title: 'Embarcador' },
            { title: 'Veículo' },
            { title: 'CPF Condutor' },
            { title: 'Nome Condutor' },
            { title: 'Origem' },
            { title: 'Destino' },
            { title: 'Não Conformidade' },
            { title: 'Data Ocorrencia' },
            { title: 'Penalidade' },
            { title: 'Infração' },
            { title: 'Pontos' },
          ],
          data: dataXls.map((each: NCLancada) => [
            { value: each.smId != null ? each.smId : '' },
            { value: each.clienteRazaoSocial != null ? each.clienteRazaoSocial : '' },
            { value: each.embarcadorDonoCarga != null ? each.embarcadorDonoCarga : '' },
            { value: each.veiculoPlaca != null ? each.veiculoPlaca : '' },
            { value: each.cpfCondutor != null ? each.cpfCondutor : '' },
            { value: each.nomeCondutor != null ? each.nomeCondutor : '' },
            {
              value: each.origemViagem ? each.origemViagem : each.origemRota ? each.origemRota : '',
            },
            {
              value: each.destinoViagem
                ? each.destinoViagem
                : each.destinoRota
                ? each.destinoRota
                : '',
            },
            {
              value:
                each.naoConformidadeNaturezaEvento != null
                  ? each.naoConformidadeNaturezaEvento
                  : '',
            },
            {
              value:
                each.dataOcorrencia != null
                  ? `${obterData(each.dataOcorrencia)} ${obterHora(each.dataOcorrencia)}`
                  : '',
            },
            {
              value:
                each.cnpjEmbarcador === cnpj
                  ? each.naoConformidadePenalidade != null
                    ? each.naoConformidadePenalidade
                    : ''
                  : '',
            },
            {
              value:
                each.cnpjEmbarcador === cnpj
                  ? each.naoConformidadeInfracao != null
                    ? each.naoConformidadeInfracao
                    : ''
                  : '',
            },
            {
              value:
                each.cnpjEmbarcador === cnpj
                  ? each.naoConformidadeQtdePontos != null
                    ? each.naoConformidadeQtdePontos
                    : ''
                  : '',
            },
          ]),
        },
      ]);
    }

    if (roleLogado === 'user' && isEmbarcador === true) {
      setExcelGeneration([
        {
          ySteps: 0,
          columns: [
            {
              title: 'Consulta Não Conformidades',
              style: { font: { sz: '24', bold: true } },
            },
          ],
          data: [],
        },
        {
          ySteps: 0,
          columns: [],
          data: [
            [
              {
                value: `Período: ${
                  dadosPesquisa.dtOcorrenciaInicial
                    ? `${obterData(dadosPesquisa.dtOcorrenciaInicial)}à${obterData(
                        dadosPesquisa.dtOcorrenciaFinal
                      )}`
                    : '/ / a / /'
                }`,
              },
            ],
          ],
        },
        {
          ySteps: 0,
          columns: [
            { title: 'Data Ocorrência' },
            { title: 'Hora Ocorrência' },
            { title: 'Transportador' },
            { title: 'Nº SM' },
            { title: 'Operação' },
            { title: 'Origem' },
            { title: 'Destino' },
            { title: 'Veículo' },
            { title: 'Nome Condutor' },
            { title: 'UF' },
            { title: 'Natureza do Evento' },
            { title: 'Causa Raiz' },
            { title: 'Processo Macro' },
            { title: 'Descrição da Ocorrência' },
            { title: 'Pronta Resposta' },
            { title: 'Solicitante do Acionamento' },
            { title: 'Liberado por' },
            { title: 'Operador GS' },
            { title: 'Penalidade' },
            { title: 'Infração' },
            { title: 'Pontos' },
          ],
          data: dataXls.map((each: NCLancada) => [
            { value: each.dataOcorrencia != null ? each.dataOcorrencia?.split(' ')[0] : '' },
            { value: each.dataOcorrencia != null ? each.dataOcorrencia?.split(' ')[1] : '' },
            { value: each.clienteRazaoSocial != null ? each.clienteRazaoSocial : '' },
            { value: each.smId != null ? each.smId : '' },
            { value: each.tipoOperacao != null ? each.tipoOperacao : '' },
            {
              value: each.origemViagem ? each.origemViagem : each.origemRota ? each.origemRota : '',
            },
            {
              value: each.destinoViagem ? each.destinoViagem : each.destinoRota ? each.destinoRota : '',
            },
            { value: each.veiculoPlaca != null ? each.veiculoPlaca : '' },
            { value: each.nomeCondutor != null ? each.nomeCondutor : '' },
            { value: each.ufSigla != null ? each.ufSigla : '' },
            {
              value:
                each.naoConformidadeNaturezaEvento != null
                  ? each.naoConformidadeNaturezaEvento
                  : '',
            },
            { value: '' },
            { value: '' },
            { value: each.descricaoObs != null ? each.descricaoObs : '' },
            { value: each.prontaResposta != null ? each.prontaResposta : '' },
            { value: '' },
            { value: each.nomeLiberador != null ? each.nomeLiberador : '' },
            { value: each.usuarioId != null ? each.usuarioId : '' },
            { value: each.naoConformidadePenalidade != null ? each.naoConformidadePenalidade : '' },
            { value: each.naoConformidadeInfracao != null ? each.naoConformidadeInfracao : '' },
            { value: each.naoConformidadeQtdePontos != null ? each.naoConformidadeQtdePontos : '' },
          ]),
        },
      ]);
    }
  }, [dataXls]);

  const deleteNaoConformidade = async (id: any) => {
    try {
      await axios.put(`/nao-conformidade-lancamento/delete-logico?id=${id}`);
    } catch (err: any) {
      console.log(err);
      addToast({
        title: 'Erro',
        description: 'Houve algum erro!',
        type: 'error',
      });
    } finally {
      setShow(false);
      setShowDialogOk(true);
      handleRequest(dadosPesquisa);
    }
  };

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Não Conformidades"
      caminho="Consultas e Relatórios > SM > Não Conformidades"
      loading={loading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={currentPage}
      filename="RelatorioNaoConformidades"
      excelGeneration={excelGeneration}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            enforceFocus
            show={show}
            onHide={() => setShow(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
              Deseja realmente excluir esta Não Conformidade?
            </Modal.Body>
            <Modal.Footer>
              <BootstrapButton
                variant="secondary"
                style={{ width: '120px', height: '50px' }}
                onClick={() => setShow(false)}
              >
                Cancelar
              </BootstrapButton>
              <BootstrapButton
                style={{ color: '#000', width: '120px', height: '50px' }}
                variant="primary"
                onClick={() => {
                  deleteNaoConformidade(id);
                }}
              >
                Excluir
              </BootstrapButton>
            </Modal.Footer>
          </Modal>

          <Dialog
            header="Não conformidade excluída com sucesso!"
            footer={
              <BootstrapButton
                onClick={() => {
                  setShowDialogOk(false);
                }}
              >
                Ok
              </BootstrapButton>
            }
            visible={showDialogOk}
            style={{ width: '50vw' }}
            modal
            onHide={() => {
              setShowDialogOk(false);
            }}
          />

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <IoFunnelOutline
              className="filter-icon"
              size={21}
              onClick={() => history.push('/nao-conformidade/consulta')}
            />

            <Search
              // disabled={!currentData.length}
              setLoading={setLoadingSearch}
              setSearch={setSearchTerm}
              setSearchValue={setSearchValue}
              search={searchTerm}
            />
          </div>

          {loadingSearch ? (
            <Spinner />
          ) : (
            <Tabela
              data={currentData}
              titles={
                roleLogado === 'admin'
                  ? titlesAdmin
                  : roleLogado === 'corretora' || roleLogado === 'seguradora'
                  ? titlesCorretoraSeguradora
                  : isEmbarcador
                  ? titlesEmbarcador
                  : titlesTransportador
              }
              onClickExcluirNc={(id: any) => {
                if (canDelete) {
                  setId(id);
                  setIdSM(currentData.find((each: any[]) => each[0] === id)[1]);
                  setShow(true);
                } else {
                  history.push('/acessonegado');
                }
              }}
              canDelete={roleLogado === 'admin'}
            />
          )}
        </>
      )}
    </Tela>
  );
};

export default GridConsultaNaoConformidades;
