/* eslint-disable */
import { memo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Upload from './Upload/index';
import FileList from './FileList';

type Props = {
  setDocument: any;
  document: any;
  setRefreshList: any
};

const ModalVelocidadeVeiculo = ({ document, setDocument , setRefreshList}: Props) => {
  const [show, setShow] = useState<boolean>(false);

  const handleClose = () => {
    setDocument('')
    setShow(false)
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        type="button"
        style={{
          background: '#ffda53',
          borderColor: 'none',
          border: 'none',
          padding: '10px',
          width: 200,
          marginBottom: 50,
          fontSize: 13,
          borderRadius: 5
        }}
        onClick={() => {
          handleShow();
        }}
      >
        Enviar XML
      </button>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}

      >
        <Modal.Header style={{ alignSelf: 'center' }}>
          <h2 className="titulo">Enviar XML</h2>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center', width: '90%' }}>
          <Upload setDocument={setDocument} />
          <FileList document={document} setDocument={setDocument} setRefreshList={setRefreshList}  setShow={setShow}/>


        </Modal.Body>
      </Modal>
    </>
  );
};

export default memo(ModalVelocidadeVeiculo);
