/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import axios from '../../config/axiosMaquina';
import { useHistory, useParams } from 'react-router-dom';
import { FaturamentoGerenciadorType } from '../../api/model/FaturamentoGerenciadorType';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { ButtonTooltip } from '../../components/ButtonTooltipo';
import { obterDataHora } from '../../util/date';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

function FaturamentoGerenciador() {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();

  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogRecusarOK, setIsDialogRecusarOK] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchResults, setSearchResults] = useState<FaturamentoGerenciadorType[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const [totalRegistros, setTotalRegistros] = useState(0);


  const paginate = (pageNumber: number) => {
    history.push(`/listar/faturamento-frota/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.OPERACIONAL.FATURAMENTO_FROTA]}], 'editar');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const pageInt = parseInt(page, 10);
    if (pageInt <= 0 || !pageInt) {
      history.goBack();
    }
  }, [page]);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingSearch(true);
      try {

        Promise.all([
          axios.get(`/veiculo-faturamento`, {
            params: {
              pagina: page - 1,
              qtdRegistros: itensPerPage,
              ...(searchTerm && { keyword: searchTerm })
            },
          }),
          axios.get(`/veiculo-faturamento/gerenciador/count`, {
            params: {
              ...(searchTerm && { keyword: searchTerm })
            }
          })
        ])
          .then(([response, responseCount]) => {
            setSearchResults(response.data);
            setTotalRegistros(responseCount.data);
          })
          .catch(error => {
            // Trate os erros aqui
            console.error('Erro ao buscar dados:', error);
          });


      } catch (err: any) {
        console.log(err);
      } finally {
        setLoadingSearch(false);
      }
    };
    fetchData();
  }, [page, itensPerPage, searchTerm]);

  useEffect(() => {
    if (!searchTerm.length) return

    paginate(1)

  }, [searchTerm])

  //funcao de aceitar
  const handleAceitar = async (idFat: number, idVeiculo: number, faturarComo: string) => {
    const data = {
      status: 'true',
    };

    try {
      await axios.put(`veiculo-faturamento/veiculo/${idVeiculo}`, {
        tipoFaturamento: faturarComo,
      });
      await axios.put(`/veiculo-faturamento/gerenciador/${idFat}`, data); // endpoint aceitar
      setIsDialogCadOK(true);
    } catch (error: any) {
      console.log(error);
      addToast({
        title: 'Erro',
        description: 'Erro ao aceitar faturamento!',
        type: 'error',
      });
      return;
    }
  };

  //funcao de recusar
  const handleRecusar = async (idFat: number) => {
    const data = {
      status: 'false',
    };

    try {
      await axios.put(`/veiculo-faturamento/gerenciador/${idFat}`, data); // endpoint recusar
      setIsDialogRecusarOK(true);
    } catch {
      addToast({
        title: 'Erro',
        description: 'Erro ao recusar faturamento!',
        type: 'error',
      });
      return;
    }
  };

  return (
    <Tela
      nome="Faturamento/Frota"
      caminho="Operacional > Faturamento/Frota"
      loading={!searchResults}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearch}
      search={searchTerm}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={totalRegistros}
      paginate={paginate!}
      currentPage={page}
    >
      <Dialog
        header="Faturamento aceito com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          window.location.reload();
        }}
      />
      <Dialog
        header="Faturamento recusado com sucesso!"
        footer={<Button onClick={() => setIsDialogRecusarOK(false)}>Ok</Button>}
        visible={isDialogRecusarOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          window.location.reload();
        }}
      />
      <div className="table-responsive" style={{ flex: 1 }}>
        {loadingSearch ? (
          <Spinner />
        ) : (
          <table className="table">
            <thead className="thead">
              <tr>
                <th>Empresa</th>
                <th>Placa</th>
                <th>Tecnologia</th>
                <th>Faturado como</th>
                <th>Alterar para</th>
                <th>Motivo</th>
                <th>Solicitado por</th>
                <th>Status</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((lf) => (
                <tr>
                  <td>{lf.empresa}</td>
                  <td>{lf.placa}</td>
                  <td>{lf.tecnologiaRastreamento}</td>
                  <td>{lf.tipoFaturamentoAtual}</td>
                  <td>{lf.faturarComo}</td>
                  <td>{lf.motivo}</td>
                  {/* <td>{lf.nomeSolicitante}</td> */}

                  <td>
                    {lf.nomeSolicitante === null ? (lf.nomeSolicitante = '') : lf.nomeSolicitante}
                    <br />

                    {obterDataHora(lf.dataCadastroSolicitacao, true)}
                  </td>

                  <td>{lf.status == null ? 'Pendente' : 'Em andamento'}</td>

                  <td>
                    <ButtonTooltip
                      type="Aceitar"
                      onClick={() => {
                        if (canEdit) {
                          handleAceitar(lf.idFat, lf.idVeiculo, lf.faturarComo);
                        } else {
                          history.push('/acessonegado');
                        }
                      }}
                    />
                  </td>
                  <td>
                    <ButtonTooltip
                      type="Recusar"
                      onClick={() => {
                        if (canEdit) {
                          handleRecusar(lf.idFat);
                        } else {
                          history.push('/acessonegado');
                        }
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </Tela>
  );
}

export default FaturamentoGerenciador;
