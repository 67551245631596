/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import { Form, Col, Row, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { getCookieSessionData } from '../../services/cookieService';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import { obterDataAtual, nowToZoned, toZoned } from '../../util/date';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

export const Reciclagem = () => {
  const { EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nomeCli, setNomeCli] = useState('');
  const [transportador, setTransportador] = useState([]);
  const [condutores, setCondutores] = useState<any>([]);
  const [veiculos, setVeiculos] = useState<any>([]);
  const [transportadorValue, setTransportadorValue] = useState<any>();
  const [condutorId, setCondutorId] = useState<number>();
  const [veiculoValue, setVeiculoValue] = useState('');
  const [data, setData] = useState(obterDataAtual(false));
  const [formInvalid, setFormInvalid] = useState(false);
  const razaoSocialEmbarcador = getCookieSessionData().usuarioVO.clienteRazaoSocial;
  const cnpj = getCookieSessionData().usuarioVO.cnpj;

  const { addToast } = useToast();
  const history = useHistory();

  const usuarioId = getCookieSessionData().usuarioVO.usuarioId;

  function back() {
    history.goBack();
  }

  useEffect(() => {
    rolePermission([{EMBARCADOR: [EMBARCADOR.OPERACIONAL.RECICLAGEM]}], 'inserir');
  }, [EMBARCADOR, rolePermission]);

  const handleVisibility = (filt: string) => {
    if (filt != null) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setTransportadorValue(filt);
  };

  const getTransportador = useCallback(async () => {
    try {
      setNomeCli(razaoSocialEmbarcador!);
      const res = await axios.get(`/embarcador/embarcador-rel-cliente-por-cnpj?cnpj=${cnpj}`);
      setTransportador(res.data);
    } catch (error: any) {
      console.log(error);
    }
  }, [setTransportador, transportadorValue]);

  const getVeiculo = useCallback(async () => {
    try {
      const response = await axios.get(
        `/veiculo/listarVeiculosPorCliente?cliente=${transportadorValue}`
      );
      const recebeVeiculo = response.data;
      setVeiculos(recebeVeiculo);
    } catch (error: any) {
      console.log(error);
    }
  }, [setVeiculos, condutorId, transportadorValue]);

  const getCondutor = useCallback(async () => {
    try {
      const response = await axios.get(`/condutor/listarCondutorNome`, {
        params: {
          cliente: transportadorValue,
        },
      });
      const recebeInfoCondutor = response.data;
      setCondutores(recebeInfoCondutor);
      return;
    } catch (error: any) {
      console.log(error);
    }
  }, [transportadorValue]);

  const cancelar = () => {
    setVisible(false);
    setTransportadorValue('');
    setNomeCli('');
    setVeiculoValue('');
    setData('');
  };
  const handleReciclagem = async (e: any) => {
    e.preventDefault();

    if (!data) {
      setFormInvalid(true);
      e.preventDefault();
      return;
    }

    const params = {
      dataCadastro: nowToZoned(),
      dataTreinamento: data,
      idCliente: transportadorValue,
      idCondutor: condutorId,
      idUsuario: usuarioId,
      idVeiculo: parseInt(veiculoValue, 10),
    };

    if (!condutorId && !veiculoValue) {
      addToast({
        title: 'Erro',
        description: 'Condutor ou veículo obrigatório.',
        type: 'error',
      });
      setFormInvalid(true);
      e.stopPropagation();
      return;
    }

    try {
      await axios.post('/reciclagem', params);
      setIsDialogCadOK(true);
    } catch (error: any) {
      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar, verifique os dados e tente novamente.',
        type: 'error',
      });
    }
  };

  useEffect(() => {
    getTransportador();
    if (!transportadorValue) {
      return;
    }
    getCondutor();
    getVeiculo();
  }, [transportadorValue]);


  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'row' }}>
        <MenuLateral />

        <Dialog
          header="Reciclagem cadastrada com sucesso!"
          footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
          visible={isDialogCadOK}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            history.push('/cliente/listar-reciclagem/1');
          }}
        />

        <div className="card">
          <div className="titulo-container">
            <h2 className="titulo">Reciclagem</h2>
            <h6 className="subtitulo">
              {'Módulo Cliente > Operacional > Solicitações > Reciclagem'}
            </h6>
          </div>

          <div className="content-container" style={{ width: '100%' }}>
            <div className="painel">
              {visible ? (
                <></>
              ) : (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className="required">Transportador:</Form.Label>
                    <Form.Control
                      onChange={(e: any) => handleVisibility(e.target.value)}
                      as="select"
                      required
                    >
                      <option value="">Selecione</option>
                      {transportador &&
                        transportador.map((trasnp: any) => (
                          <option key={trasnp.idCliente} value={trasnp.idCliente}>
                            {trasnp.razaoSocial}
                          </option>
                        ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              )}

              {visible ? (
                <>
                  <Form
                    noValidate
                    validated={formInvalid}
                    onSubmit={handleReciclagem}
                    // className="form-sm"
                  >
                    <Form.Row>
                      <Form.Group as={Col}>
                        <Form.Label>Transportador</Form.Label>
                        <Form.Control
                          defaultValue={transportadorValue}
                          name="codigo"
                          placeholder="Código"
                          disabled
                        />
                      </Form.Group>

                      <Form.Group as={Col} style={{ marginTop: 30 }}>
                        <Form.Control
                          defaultValue={nomeCli}
                          id="nome"
                          name="nome"
                          placeholder="Nome"
                          disabled
                        />
                      </Form.Group>
                    </Form.Row>

                    <hr />
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Condutor:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          onChange={(e: any) => setCondutorId(+e.target.value)}
                          as="select"
                          required={!veiculoValue}
                        >
                          <option value="">Selecione</option>
                          {condutores &&
                            condutores.map((condutor: any) => (
                              <option key={condutor.id} value={condutor.id}>
                                {condutor.nome}
                              </option>
                            ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3">
                        Veículo:
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          value={veiculoValue}
                          onChange={(e: any) => setVeiculoValue(e.target.value.toString())}
                          as="select"
                          required={!condutorId}
                        >
                          <option value="">Selecione</option>
                          {veiculos &&
                            veiculos.map((veiculo: any) => (
                              <option key={veiculo.id} value={veiculo.id}>
                                {veiculo.placa}
                              </option>
                            ))}
                        </Form.Control>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="3" className="requiredField">
                        Data do Treinamento:
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          value={data}
                          onChange={(e: any) => setData(e.target.value)}
                          id="dataInicio"
                          name="dataInicio"
                          max={obterDataAtual(false)}
                          type="date"
                          required
                        />
                      </Col>
                    </Form.Group>
                    <ButtonsForm canInsert={canInsert} />
                  </Form>
                </>
              ) : (
                <>
                  <Form.Group as={Col} md={2} className="ml-auto mr-5 mt-4">
                    <Button
                      className="btn-cancelar"
                      onClick={() => {
                        back();
                      }}
                      style={{
                        borderColor: '#0B141F',
                        borderWidth: '2px',
                        backgroundColor: '#FFF',
                        color: '#0B141F',
                      }}
                    >
                      Cancelar
                    </Button>
                  </Form.Group>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
