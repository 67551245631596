import './styles.css';
import { useState, useEffect } from 'react';
import { ButtonTooltip } from '../ButtonTooltipo';
import { FullScreen } from './fullScreen';
import Upload from '../Upload';
import FileList from '../FileList';
import { Content } from '../../pages/Condutor/CadastroCondutor/styles';
import { FileProvider } from '../../context/files';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import { ModalUpload } from '../ModalUpload';
import { ModalDelete } from '../ModalDelete';
import { soNumeros } from '../../util/Validacoes/Validacoes';

type Props = {
  children?: any;
  cadastro?: boolean;
  foto: string;
  setFoto: (foto: string) => void;
  name: string;
};

export const ProfilePicture = ({ children, cadastro, foto, setFoto, name }: Props) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [failedImage, setFailedImage] = useState(false);
  const api = process.env.REACT_APP_API_URL;
  const { addToast } = useToast();
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [fotoOriginal, setFotoOriginal] = useState('');

  const testImage = async () => {
    if (cadastro || !foto) {
      return;
    }
    try {
      await axios.get(`/foto/downloadFile/{file}?file=${foto}`);
      setFailedImage(false);
    } catch (error: any) {
      setFailedImage(true);
      addToast({
        title: 'Erro',
        description: `Não foi possível encontrar imagem "${foto}"`,
        type: 'error',
      });
    }
  };

  const deleteImage = async () => {
    try {
      axios.post(`foto/deleteFile?file=${foto}`);
      setFoto('');
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFotoOriginal(foto);
  }, []);

  useEffect(() => {
    testImage();
  }, [foto]);

  return (
    <>
      <ModalUpload
        show={showModalUpload}
        setShow={setShowModalUpload}
        setFailedImage={setFailedImage}
        foto={foto}
        setFoto={setFoto}
        name={soNumeros(name)}
      />
      <ModalDelete
        show={showModalDelete}
        onHide={() => setShowModalDelete(false)}
        handleDelete={() => {
          deleteImage();
          setShowModalDelete(false);
        }}
        handleClose={() => setShowModalDelete(false)}
        message="Deseja excluir esta foto de perfil?"
      />
      <div className="containerPerfil">
        <div className="column1">{children}</div>
        <div className="containerProfile">
          {cadastro ? (
            <Content>
              <FileProvider foto={foto} setFoto={setFoto} name={soNumeros(name)}>
                <Upload />
                <FileList />
              </FileProvider>
            </Content>
          ) : (
            <>
              <img
                className="img-default"
                src={
                  foto && !failedImage
                    ? `${api}/foto/downloadFile/{file}?file=${foto}`
                    : 'https://i.stack.imgur.com/l60Hf.png'
                }
                alt={foto}
                onClick={(e: any) => {
                  if (foto && !failedImage) {
                    setFullScreen(true);
                  }
                }}
                onKeyDown={() => setFullScreen(true)}
                role="presentation"
                style={{ cursor: foto && !failedImage ? 'pointer' : 'default' }}
              />
              <div className="column-container">
                <ButtonTooltip
                  type="Editar"
                  onClick={() => {
                    // setFoto('');
                    setShowModalUpload(true);
                  }}
                />
                {foto && (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label
                  <a href={`${api}/foto/downloadFile/{file}?file=${foto}`}>
                    <ButtonTooltip type="Baixar" onClick={() => null} />
                  </a>
                )}
                {foto && <ButtonTooltip type="Excluir" onClick={() => setShowModalDelete(true)} />}
              </div>
            </>
          )}

          <FullScreen linkImage={foto} show={fullScreen} setShow={setFullScreen} />
        </div>
      </div>
    </>
  );
};
