/* eslint-disable */

export const handleRota = (listMonitoramentoSmMapaPontoVO: any, rota: string) => {
  let _rota = '';
  if (!listMonitoramentoSmMapaPontoVO.length) {
    return (_rota = rota);
  }
  listMonitoramentoSmMapaPontoVO.forEach((d: any, index: number) => {
    if (d.ponto.includes(': ')) {
      if (index === 0) {
        _rota += d.ponto.split(': ')[1];
      } else {
        _rota += `/${d.ponto.split(': ')[1]}`;
      }
    } else {
      return (_rota = rota);
    }
  });
  return (rota = _rota);
};
