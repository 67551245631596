/* eslint-disable */

import { FC, useEffect, useState } from 'react';
import { Form, Button, Modal, Col } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import axiosRequest from 'axios';
import api from '../../config/axiosMaquina';
import { MotivoType } from '../../Types/MotivoType';

type ModalProps = {
  showModal: boolean;
  setShowModal: (bool: boolean) => void;
  data: any;
  fetchData: () => void;
  role: string;
};

export type ComentariosType = {
  ativo: boolean;
  comentario: string;
  dataCadastro: string;
  dataCadastroUltimaAlteracao: string;
  idLsc: number;
  idLscTratamentoComentario: number;
  idUsuario: number;
  idUsuarioUltimaAlteracao: number;
  nomeUsuario: string;
  role: string;
};

const ModalLSC: FC<ModalProps> = ({ showModal, setShowModal, data, fetchData, role }) => {
  const [mensagem, setMensagem] = useState('');
  const [mensagemErro, setMensagemErro] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isDialogCadOK2, setIsDialogCadOK2] = useState(false);
  const [motivos, setMotivos] = useState<MotivoType[]>([]);
  const [motivoId, setMotivoId] = useState<number>();
  const [comment, setComment] = useState('');

  const loadMotivos = async () => {
    try {
      const response = await api.get(`/motivo/grid`);
      setMotivos(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (showModal) {
      setMotivoId(0);
      loadMotivos();
    }
  }, [data, showModal]);

  const handlePut = async () => {
    if (motivoId && comment) {
      try {
        const d = {
          id: data?.id,
          treatment: comment,
          motivoId,
          role,
        };

        await axiosRequest.patch(`${process.env.REACT_APP_API_URL}/lsc-treatment/`, d);

        setMensagem('Tratamento salvo com sucesso!');
        setIsDialogCadOK(true);

        fetchData();
      } catch (error) {}
    } else {
      setMensagemErro('Verifique os campos obrigatórios!');

      setIsDialogCadOK2(true);
    }
  };

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={showModal}
      onHide={() => {
        setShowModal(false);
        setMotivoId(0);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Informar tratamento</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Dialog
          header={mensagem}
          footer={
            <Button
              onClick={() => {
                setIsDialogCadOK(false);
                setShowModal(false);
              }}
            >
              Ok
            </Button>
          }
          visible={isDialogCadOK}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK(false);
            setShowModal(false);
          }}
        />
        <Dialog
          header={mensagemErro}
          footer={
            <Button
              onClick={() => {
                setIsDialogCadOK2(false);
              }}
            >
              Ok
            </Button>
          }
          visible={isDialogCadOK2}
          style={{ width: '50vw' }}
          modal
          onHide={() => {
            setIsDialogCadOK2(false);
          }}
        />
        <Form>
          <Form.Row>
            <Form.Group className="row-modal">
              <Form.Label className="requiredField">Motivo da ocorrência</Form.Label>
              <Form.Control
                value={motivoId}
                onChange={(e) => {
                  setMotivoId(parseInt(e.target.value));
                }}
                as="select"
              >
                <option value="0">Selecione</option>
                {motivos &&
                  motivos.map((each: any) => (
                    <option value={each.motCdId}>{each.descricao}</option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Label className="requiredField" column sm="3">
              Tratativa
            </Form.Label>

            <Form.Control
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              id="comment"
              name="comment"
              placeholder=""
              as="textarea"
            />
          </Form.Row>
        </Form>
      </Modal.Body>

      <Form.Row className="container-buttons justify-content-end">
        <Form.Group as={Col} md={2}>
          <Button
            className="btn-cancelar"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancelar
          </Button>
        </Form.Group>
        <Form.Group as={Col} md={2}>
          <Button
            className="btn-enviar"
            onClick={() => {
              handlePut();
            }}
          >
            Salvar Tratamento
          </Button>
        </Form.Group>
      </Form.Row>
    </Modal>
  );
};
export default ModalLSC;
