import { GridViolacao } from '../..';
import { ExportExcelAmbos } from './ExcelAmbos';
import { ExportExcelTemperatura } from './ExcelTemperatura';
import { ExportExcelVelocidade } from './ExcelVelocidade';

interface PropsExportExcel {
  tipoControle: number;
  data: GridViolacao[];
}

export const ExportExcel = ({ tipoControle, data }: PropsExportExcel) => {
  return (
    <>
      {tipoControle === 0 && <ExportExcelTemperatura data={data} tipoControle={tipoControle} />}
      {tipoControle === 1 && <ExportExcelVelocidade data={data} tipoControle={tipoControle} />}
      {tipoControle === 2 && <ExportExcelAmbos data={data} tipoControle={tipoControle} />}
    </>
  );
};
