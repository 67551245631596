/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Spinner, Button, Modal } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import axios from '../../../config/axiosMaquina';

import { VeiculoTypeGrid } from '../../../Types/Veiculos/VeiculoTypeGrid';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';

import Pagination from '../../Home/Pagination/Pagination';
import { getCookieSessionData } from '../../../services/cookieService';
import ModalTemperaturaVeiculo from './modal';
import { useToast } from '../../../hooks/Toast';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import { listTemperature } from '../../../services/temperature';
import LscModal from './lscModal';
import ReactExport from 'react-data-export';

const TemperaturaPorVeiculo: React.FC = () => {
  const [lastPage, setLastPage] = useState(0);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [refresh, setRefresh] = useState(0);
  const [refreshitensPerPage, setRefreshitensPerPage] = useState(false);
  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const [refreshList, setRefreshList] = useState(false);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const { addToast } = useToast();
  const [veiculos, setVeiculos] = useState<VeiculoTypeGrid[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [searchResults, setSearchResults] = useState<VeiculoTypeGrid[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const [dialogVincularVeiculo, setDialogVincularVeiculo] = useState<boolean>(false);
  const [dialogVincularVeiculoPlaca, setDialogVincularVeiculoPlaca] = useState<string>('');
  const [dialogVincularVeiculoID, setDialogVincularVeiculoID] = useState<number>(0);
  const [loadingLink, setLoadingLink] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState('');
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  useEffect(() => setSearchTerm(null), []);

  useEffect(() => {
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CONTROLE_LOGISTICO.CONTROLE_DE_TEMPERATURA] }],
      'editar'
    );
  }, [TRANSPORTADOR, rolePermission]);

  const handleLink = (id: number, placa: string) => {
    setLoadingLink(true);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/update-vehicle-temperature-monitoring/`, [
        {
          cliente: clienteId,
          id,
          monitoring: false,
        },
      ])
      .then((res) => {
        setLoadingLink(false);

        setDialogVincularVeiculo(false);
        setDialogVincularVeiculoPlaca('');
        setDialogVincularVeiculoID(0);
        setRefreshList(true);

        setShowResponseMessage(res.data.detail);
      })
      .catch((e) => {
        console.log('');
      });
  };

  const loadVeiculos = async ({ page, clientId, pageSize, placa }: any) => {
    setLoading(true);
    if (!clientId) {
      return;
    }
    try {
      const response: any = await listTemperature({
        page,
        clienteId: clientId,
        temperature: true,
        pageSize,
        ...(placa && { placa }),
      });

      setTotalItems(response.data.count);

      setLastPage(Math.ceil(response.data.count / Number(itensPerPage)));

      setVeiculos(response.data.results.filter((each: VeiculoTypeGrid) => each.ativo));
      setRefreshList(false);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setRefreshList(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!refreshList) return;

    const getDados = async () => {
      setLoading(true);

      await loadVeiculos({
        page: currentPage,
        clientId: clienteId,
        pageSize: Number(itensPerPage),
      });
    };
    getDados();
  }, [refreshList]);

  useEffect(() => {
    if (!refresh) return;

    const getDados = async () => {
      setLoading(true);

      await loadVeiculos({
        page: refresh,
        clientId: clienteId,
        pageSize: Number(itensPerPage),
      });
    };
    getDados();
  }, [refresh]);

  useEffect(() => {
    if (!refreshitensPerPage) return;

    const getDados = async () => {
      setLoading(true);

      await loadVeiculos({
        page: currentPage,
        clientId: clienteId,
        pageSize: Number(itensPerPage),
      });

      setRefreshitensPerPage(false);
    };
    getDados();
  }, [refreshitensPerPage]);

  useEffect(() => {
    if (clienteId) {
      loadVeiculos({
        page: currentPage,
        clientId: clienteId,
        pageSize: Number(itensPerPage),
      });
    }
  }, [clienteId]);

  useEffect(() => {
    if (searchTerm === null) return;

    const debounceTimeout = setTimeout(() => {
      const fetchData = async () => {
        if (searchTerm?.trim() === '') {
          setLoading(true);

          await loadVeiculos({
            page: 1,
            clientId: clienteId,
            pageSize: Number(itensPerPage),
          });

          return;
        }
        setLoading(true);

        await loadVeiculos({
          page: 1,
          clientId: clienteId,
          pageSize: Number(itensPerPage),
          placa: searchTerm,
        });
      };

      fetchData();
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  const { ExcelFile, ExcelSheet, ExcelColumn } = ReactExport;

  const generateExcelData = (data: any[]) => {
    return data.map((item) => ({
      placa: item.placa,
      veiculo_marca_nome: item.veiculo_marca_nome,
      modelo: item.modelo,
      veiculo_tipo_nome: item.veiculo_tipo_nome,
      temperatura_minima_veiculo: item.temperatura_minima_veiculo || '',
      temperatura_maxima_veiculo: item.temperatura_maxima_veiculo || '',
    }));
  };

  console.log(veiculos);

  const ExportExcel = ({ veiculos }: any) => {
    const transformedData = generateExcelData(veiculos);

    return (
      <ExcelFile element={<Button>Exportar Excel</Button>} filename="controle-temperatura-veiculos">
        <ExcelSheet data={transformedData} name="Veiculos">
          <ExcelColumn label="Placa" value="placa" />
          <ExcelColumn label="Marca do Veículo" value="veiculo_marca_nome" />
          <ExcelColumn label="Tipo do Veículo" value="veiculo_tipo_nome" />
          <ExcelColumn label="Modelo" value="modelo" />
          <ExcelColumn label="Temperatura Mínima" value="temperatura_minima_veiculo" />
          <ExcelColumn label="Temperatura Máxima" value="temperatura_maxima_veiculo" />
        </ExcelSheet>
      </ExcelFile>
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={!!showResponseMessage.length}
        onHide={() => {
          setShowResponseMessage('');
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Sucesso!</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }}>{showResponseMessage}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowResponseMessage('');
            }}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <Dialog
        header={`Tem certeza que deseja desvincular o veículo (${dialogVincularVeiculoPlaca})?`}
        footer={
          <>
            <Button
              style={{
                background: '#df5252',
                color: '#fff',
                border: 'none',
                height: '40px',
              }}
              onClick={() => {
                setDialogVincularVeiculo(false);
                setDialogVincularVeiculoPlaca('');
                setDialogVincularVeiculoID(0);
              }}
            >
              Não
            </Button>
            <Button
              style={{
                background: '#8f52df',
                color: '#fff',
                border: 'none',
                height: '40px',
              }}
              onClick={() => {
                handleLink(dialogVincularVeiculoID, dialogVincularVeiculoPlaca);
              }}
            >
              {loadingLink ? 'Desvinculando...' : 'Sim'}
            </Button>
          </>
        }
        visible={dialogVincularVeiculo}
        style={{ width: '50vw' }}
        modal
        closable={false}
        onHide={() => {
          setDialogVincularVeiculo(false);
        }}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Controle de Temperatura</h2>
          <h6 className="subtitulo">{'Controle Logístico > Controle de Temperatura'}</h6>
        </div>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LscModal clienteId={clienteId} setRefreshList={setRefreshList} />

          <div className="search-create mb-4" style={{ width: '100%', marginTop: 25 }}>
            <span className="p-input-icon-left">
              <input
                type="text"
                placeholder="Pesquise pela placa"
                className="p-inputtext p-component"
                id="search-input"
                style={{ width: '240px' }}
                value={searchTerm}
                onChange={(e: any) => setSearchTerm(e.target.value)}
              />
              <i className="pi pi-search" />
            </span>
          </div>

        </div>


        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <ExportExcel veiculos={veiculos} />
        </div>



        <div style={{ width: '100%' }}>

          <div className="painel">
            <div className="table-responsive" style={{ flex: 1 }}>
              {loading ? (
                <Spinner
                  animation="border"
                  variant="warning"
                  style={{
                    display: 'flex',
                    marginLeft: '47.5%',
                    marginTop: '5%',
                    marginBottom: '5%',
                  }}
                />
              ) : (
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th>Placa</th>
                      <th>Marca</th>
                      <th>Modelo</th>
                      <th>Tipo</th>
                      <th>Temperatura Mínima</th>
                      <th>Temperatura Máxima</th>
                      <th> </th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {veiculos.length > 0 &&
                      veiculos.map((veiculo: any) => {
                        return (
                          <tr key={veiculo.id}>
                            <td>{veiculo.placa === null ? '' : veiculo.placa}</td>
                            <td>{veiculo.veiculo_marca_nome}</td>
                            <td>{veiculo.modelo}</td>
                            <td>{veiculo.veiculo_tipo_nome}</td>
                            <td align="center">
                              {veiculo.temperatura_minima_veiculo &&
                                `${veiculo.temperatura_minima_veiculo}ºC`}
                            </td>
                            <td align="center">
                              {veiculo.temperatura_maxima_veiculo &&
                                `${veiculo.temperatura_maxima_veiculo}ºC`}
                            </td>

                            <td>
                              <Button
                                onClick={() => {
                                  setDialogVincularVeiculo(true);
                                  setDialogVincularVeiculoPlaca(veiculo.placa);
                                  setDialogVincularVeiculoID(veiculo.id);
                                }}
                                style={{
                                  background: '#df5252',
                                  color: '#fff',
                                  border: 'none',
                                  height: '40px',
                                }}
                              >
                                DESVINCULAR
                              </Button>
                            </td>

                            <td>
                              <div className="icons">
                                <ModalTemperaturaVeiculo
                                  veiculo={veiculo}
                                  clienteId={clienteId}
                                  canEdit={canEdit}
                                  setRefreshList={setRefreshList}
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              )}
              {veiculos.length === 0 && !loading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <strong>Não existem veículos refrigerados para serem listados</strong>
                </div>
              )}
            </div>
          </div>
        </div>

        <Pagination
            lastPage={lastPage}
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
            totalPosts={totalItems}
            paginate={paginate}
            currentPage={String(currentPage)}
            setRefresh={setRefresh}
            setData={setVeiculos}
            setRefreshitensPerPage={setRefreshitensPerPage}
          />
      </div>
    </div>
  );
};

export default TemperaturaPorVeiculo;
