import React from 'react';
import Check from '../../assets/check.svg';
import UnCheck from '../../assets/uncheck.svg';

type StatusDminerProps = {
  dminerInactive: boolean;
};

export const StatusDminer: React.FC<StatusDminerProps> = ({ dminerInactive }) => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '1.5rem',
        borderRadius: '12px',
        boxShadow: '0px 0px 5px 1px rgba(0,0,0,0.2)',
        padding: '0.5rem',
        width: 'fit-content',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.8rem',
        }}
      >
        <strong>DMINER</strong>
        {dminerInactive ? (
          <p
            style={{
              color: 'red',
              marginBottom: '0',
              fontSize: '14px',
            }}
          >
            OFFLINE
          </p>
        ) : (
          <p
            style={{
              color: 'green',
              fontSize: '14px',
              marginBottom: '0',
            }}
          >
            ONLINE
          </p>
        )}
      </div>
      {dminerInactive ? (
        <img
          src={UnCheck}
          alt="Logo"
          style={{
            objectFit: 'contain',
            width: '2rem',
            marginBottom: '1rem',
          }}
        />
      ) : (
        <img
          style={{
            objectFit: 'contain',
            width: '2rem',
            marginBottom: '1rem',
          }}
          src={Check}
          alt="Logo"
        />
      )}
    </div>
  );
};
