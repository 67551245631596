/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosMaquina';
import { AjudanteType } from '../../api/model/AjudanteType';
import { useHistory, useParams } from 'react-router-dom';
import mascaraCpf from '../../util/mascaraCpf';
import './styles.css';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { filterItems2 } from '../../util/format';
import Table from '../../components/TableComponent';
import { toCurrency } from '../../util/Validacoes/Validacoes';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

function Ajudantes() {
  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const headers = {
    id: 'Id',
    cpf: 'CPF',
    nome: 'Nome',
    tipo: 'Tipo',
    sexo: 'Sexo',
    rg: 'RG',
    nomeCidade: 'Cidade',
    nomeUfEndereco: 'UF',
    nomePai: 'Pai',
    nomeMae: 'Mae',
    tempoCadastro: 'Tempo de Operação',
    qtdeViagens: 'Qt de Viagens',
    valorMedioContratado: 'Valor Médio Transportado',
  };

  const paginate = (pageNumber: number) => {
    history.push(`/ajudantes/listar/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.AJUDANTE]}], 'inserir');
  }, [TRANSPORTADOR, rolePermission]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get('/ajudante-funcionario/cliente', {
        params: {
          idCliente: clienteId,
        },
      });
      const array = response.data.map((each: AjudanteType) => {
        return {
          id: each.id,
          cpf: mascaraCpf(each.cpf),
          nome: each.nome,
          tipo: getAjudanteTipo(each.tipo),
          sexo: each.sexo ? each.sexo.toUpperCase() : null,
          rg: each.rg,
          nomeCidade: each.nomeCidade,
          nomeUfEndereco: each.nomeUfEndereco,
          nomePai: each.nomePai,
          nomeMae: each.nomeMae,
          tempoCadastro: each.tempoCadastro,
          qtdeViagens: each.qtdeViagens,
          valorMedioTransportado: each.valorMedioTransportado
            ? toCurrency(each.valorMedioTransportado)
            : null,
        };
      });
      setData(array);
      setLoading(false);
    };
    fetchData();
  }, [itensPerPage, page]);

  const getAjudanteTipo = (tipo: string) => {
    if (tipo === '1') {
      return 'Agregado';
    } else if (tipo === '2') {
      return 'Autônomo';
    } else if (tipo === '3') {
      return 'Funcionário';
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/ajudantes/listar/1');
      setDataSearch(filterItems2(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Ajudantes"
      caminho="Cadastro > Ajudantes"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      linkToAdd="/ajudantes/cadastrar"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Table
          items={currentData}
          headers={headers}
          handleEdit={(id: any) => history.push(`/ajudantes/editar/${id}`)}
        />
      )}
    </Tela>
  );
}

export default Ajudantes;
