/* eslint-disable */
import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Tela } from '../Tela';

const AcessoNegado: React.FC = () => {
  const [show, setShow] = useState(true);

  const history = useHistory();

  return (
    <Tela>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={() => history.goBack()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Você não possui permissão para utilizar essa funcionalidade
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>
    </Tela>
  );
};

export default AcessoNegado;
