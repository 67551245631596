import { Form } from 'react-bootstrap';

type PropsType = {
  title?: string;
  onClick: (event: any) => void;
  style?: any;
  removerPonto?: boolean;
  adicionarPonto?: boolean;
  check?: boolean;
};

export const AddButton = ({
  onClick,
  title,
  style,
  removerPonto,
  adicionarPonto,
  check,
}: PropsType) => {
  return (
    <span
      style={{
        margin: '7px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {title && <Form.Label>{title}</Form.Label>}
      {adicionarPonto && (
        <i
          style={{
            fontSize: '1.2em',
            marginLeft: '15px',
            marginTop: '-10px',
            cursor: 'pointer',
            ...style,
          }}
          aria-hidden="true"
          className="pi pi-plus-circle icone_adicionar"
          onClick={onClick}
          onKeyDown={onClick}
        />
      )}
      {removerPonto && (
        <i
          title="Remover Ponto"
          aria-hidden="true"
          onClick={onClick}
          onKeyDown={onClick}
          className="pi pi-minus-circle icone_adicionar"
          style={{
            color: 'red',
            fontSize: '1.2em',
            margin: '0 auto',
            cursor: 'pointer',
            ...style,
          }}
        />
      )}
      {check && (
        <i
          title="Confirmar"
          className="pi pi-check-circle"
          aria-hidden="true"
          onClick={onClick}
          onKeyDown={onClick}
          style={{
            color: '#FFDA53',
            fontSize: '1.2em',
            borderColor: 'white',
            cursor: 'pointer',
            ...style,
          }}
        />
      )}
    </span>
  );
};
