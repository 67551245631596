/* eslint-disable */

import { useState, ChangeEvent, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Col, Spinner } from 'react-bootstrap';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import Switch from 'react-bootstrap/esm/Switch';
import { Dropdown } from 'primereact/dropdown';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import Input from '../Input';
import Select from '../Select';
import { useToast } from '../../hooks/Toast';
import veiculoValidation from './veiculoValidation';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';
import { ErrosDataPesquisa } from '../../pages/EnviarPesquisa';
import { nowToZoned } from '../../util/date';

type VeiculosPlaca = {
  id: number;
  placa: string;
};

type Ufs = {
  id: number;
  sigla: string;
};

type VeiculosTipos = {
  vtpCdId: number;
  vtpTxNome: string;
};

type Proprietario = {
  id: number;
  razaoSocial: string;
};

type Veiculo = {
  anttRntrc: string;
  atualizacaoAutomatica: boolean;
  chassi: string;
  bloqueado: boolean;
  emplacamentoUf: number;
  id: number;
  placa: string;
  proprietarioId: number;
  renavam: string;
  veiculoTipo: number;
  veiculoTipoNome: string;
  tipoFrota?: string | null;
  modelo: string;
};

type InputErrors = {
  veiculo: string;
  carreta1: string;
  carreta2: string;
  placa: string;
  modelo: string;
  proprietarioId: string;
  renavam: string;
  chassi: string;
  veiculoTipo: string;
  anttRntrc: string;
  emplacamentoUf: string;
  atualizacaoAutomatica: string;
};

export const ContainerPesquisarVeiculos = forwardRef((props?: any, ref?: any) => {
  const [loading, setLoading] = useState(false)

  const [dataErros, setDataErros] = useState<ErrosDataPesquisa | undefined>({
    mensagemErro: '',
    listaErros: [],
  });
  const [vehicleType, setVehicleType] = useState('')
  const [isDialogCadErro, setIsDialogCadErro] = useState(false);
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;

  const [listaVeiculos, setListaVeiculos] = useState<VeiculosPlaca[]>([]);
  const [listaCarretas, setListaCarretas] = useState<VeiculosPlaca[]>([]);
  const [listaUfs, setListaUfs] = useState<Ufs[]>([]);
  const [listaProprietarios, setListaProprietarios] = useState<Proprietario[]>([]);
  const [idPesquisaEnviada, setIdPesquisaEnviada] = useState();
  const [inProgressVehicle, setInProgressVehicle] = useState(false)
  const [inProgressTruck, setInProgressTruck] = useState(false)
  const [inProgressTruck2, setInProgressTruck2] = useState(false)

  const [veiculo, setVeiculo] = useState<Veiculo | undefined>();
  const [carreta1, setCarreta1] = useState<Veiculo | undefined>();
  const [carreta2, setCarreta2] = useState<Veiculo | undefined>();
  const [tipoPesquisa, setTipoPesquisa] = useState('');
  const [itensPesquisasEnviadas, setItensPesquisasEnviadas] = useState<string[]>([]);
  const [itensVigentes, setItensVigentes] = useState<string[]>([]);
  const [dialogConsultaOuPesquisa, setDialogConsultaOuPesquisa] = useState(false);

  const [veiculoInputErrors, setVeiculoInputErrors] = useState({} as InputErrors);
  const [carreta1InputErrors, setCarreta1InputErrors] = useState({} as InputErrors);
  const [carreta2InputErrors, setCarreta2InputErrors] = useState({} as InputErrors);
  const [flagHabilitarPesquisaAnttRntrc, setFlagHabilitarPesquisaAnttRntrc] = useState(false);

  const { addToast } = useToast();
  const { tabAtual } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    api
      .get(`/cliente/getParticularidadesCliente?cliente=${clienteId}`)
      .then((res: any) => {
        setFlagHabilitarPesquisaAnttRntrc(res.data.flagHabilitarPesquisaAnttRntrc);
      })
      .catch(() => {
        setFlagHabilitarPesquisaAnttRntrc(false);
      });
  }, [clienteId]);

  useEffect(() => {
    axios
      .all([
        api.get('/uf/ListarIdSiglaEstadoPais'),
        api.get(`/proprietario/cliente/${clienteId}?qtdRegistros=99999`),
        api.get(`/veiculo/listarIdEPlaca`, {
          params: {
            cliente: clienteId,
            grupoMacroVeículo: 1,
          },
        }),
        api.get(`/veiculo/listarIdEPlaca`, {
          params: {
            cliente: clienteId,
            grupoMacroVeículo: 2,
          },
        }),
      ])
      .then(
        axios.spread(
          (
            ufSiglasResponse,
            proprietariosResponse,
            idsEPlacasVeiculosResponse,
            idsEPlacasCarretasResponse
          ) => {
            setListaUfs(ufSiglasResponse.data);
            setListaProprietarios(proprietariosResponse.data);
            setListaVeiculos(idsEPlacasVeiculosResponse.data);
            setListaCarretas(idsEPlacasCarretasResponse.data);
          }
        )
      )
      .catch((err) => {
        console.log(err);
      });
  }, [clienteId, veiculo]);

  const toggleSwitchAtualizacaoAutomatica = (type: string) => {
    switch (type) {
      case 'veiculo':
        if (!veiculo) {
          return;
        }
        setVeiculo({
          ...veiculo,
          atualizacaoAutomatica: !veiculo.atualizacaoAutomatica,
        });
        break;
      case 'carreta1':
        if (!carreta1) {
          return;
        }
        setCarreta1({
          ...carreta1,
          atualizacaoAutomatica: !carreta1.atualizacaoAutomatica,
        });
        break;
      case 'carreta2':
        if (!carreta2) {
          return;
        }
        setCarreta2({
          ...carreta2,
          atualizacaoAutomatica: !carreta2.atualizacaoAutomatica,
        });
        break;
      default:
        break;
    }
  };

  function updateVeiculo(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!veiculo) {
      return;
    }

    if (e.target.name === 'anttRntrc' && e.target.value.length) {
      setVeiculo({
        ...veiculo,
        [e.target.name]: e.target.value.trim(),
      });
      return
    }

    setVeiculo({
      ...veiculo,
      [e.target.name]: e.target.value,
    });
  }

  function updateCarreta1(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!carreta1) {
      return;
    }

    if (e.target.name === 'anttRntrc' && e.target.value.length) {
      setCarreta1({
        ...carreta1,
        [e.target.name]: e.target.value.trim(),
      });
      return
    }

    setCarreta1({
      ...carreta1,
      [e.target.name]: e.target.value,
    });
  }

  function updateCarreta2(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!carreta2) {
      return;
    }


    if (e.target.name === 'anttRntrc' && e.target.value.length) {
      setCarreta2({
        ...carreta2,
        [e.target.name]: e.target.value.trim(),
      });
      return
    }


    setCarreta2({
      ...carreta2,
      [e.target.name]: e.target.value,
    });
  }

  const loadDadosVeiculo = async (idVeiculo: number) => {
    if (!idVeiculo) {
      if (tabAtual !== 'condutor-veiculo-carreta') {
        history.replace(`/enviar-pesquisa/veiculo`);
      }
      setVeiculo(undefined);
      return;
    }

    if (tabAtual !== 'condutor-veiculo-carreta') {
      history.replace(`/enviar-pesquisa/veiculo/${idVeiculo}`);
    }
    setDialogHelperData(true)
    try {
      const response = await api.get(`/veiculo/${idVeiculo}`);

      setVeiculo({
        ...response.data,
        atualizarPesquisaAutomaticamente:
          response.data.atualizarPesquisaAutomaticamente === null
            ? false
            : response.data.atualizarPesquisaAutomaticamente,
      });
      if (response.data.statusCritica === 'CRITICA') {
        if (response.data.statusValidadePesquisa === 'SEM PESQUISA') {
          addToast({ title: 'Atencão:', description: 'A Carreta não possui pesquisa' });
        } else if (response.data.statusValidadePesquisa === 'VENCIDO') {
          addToast({ title: 'Atencão:', description: 'A Carreta está pesquisa vencida' });
        }
      }

      const checkValidity = await api.get('/pesquisa/verificar-vigencia', {
        params: {
          dataInicio: nowToZoned(),
          idCliente: clienteId,
          idVeiculo: idVeiculo,
        },
      });

      if (checkValidity.data === "Pesquisa em andamento") {
        setInProgressVehicle(true)
      }

      if (checkValidity.data === 'Nao pode enviar') {
        const vigentes = [];

        vigentes.push(
          response.data?.placa != undefined
            ? `${response.data?.placa} ${response.data?.modelo}`
            : ''
        );

        setVehicleType('veiculo')

        setItensVigentes(vigentes);

        setDialogConsultaOuPesquisa(true);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setDialogHelperData(false)
    }
  };

  const loadDadosCarreta1 = async (idVeiculo: number) => {
    if (!idVeiculo) {
      if (tabAtual !== 'condutor-veiculo-carreta') {
        history.replace(`/enviar-pesquisa/carreta`);
      }
      setCarreta1(undefined);
      setCarreta2(undefined);
      return;
    }

    if (tabAtual !== 'condutor-veiculo-carreta') {
      history.replace(`/enviar-pesquisa/carreta/${idVeiculo}`);
    }
    setDialogHelperData(true)
    if (carreta2 && idVeiculo === carreta2.id) {
      setCarreta2(undefined);
      return;
    }
    try {
      const response = await api.get(`/veiculo/${idVeiculo}`);

      setCarreta1(response.data);

      if (response.data.statusCritica === 'CRITICA') {
        if (response.data.statusValidadePesquisa === 'SEM PESQUISA') {
          addToast({ title: 'Atencão:', description: 'A Carreta não possui pesquisa' });
        } else if (response.data.statusValidadePesquisa === 'VENCIDO') {
          addToast({ title: 'Atencão:', description: 'A Carreta está pesquisa vencida' });
        }
      }

      const checkValidity = await api.get('/pesquisa/verificar-vigencia', {
        params: {
          dataInicio: nowToZoned(),
          idCliente: clienteId,
          idCarreta: idVeiculo,
        },
      });

      if (checkValidity.data === "Pesquisa em andamento") {
        setInProgressTruck(true)
      }


      if (checkValidity.data === 'Nao pode enviar') {
        const vigentes = [];

        vigentes.push(
          response.data?.placa != undefined
            ? `${response.data?.placa} ${response.data?.modelo}`
            : ''
        );

        setVehicleType('carreta1')

        setItensVigentes(vigentes);

        setDialogConsultaOuPesquisa(true);
      }
    } catch (err: any) {
      console.log(err);
    } finally {
      setDialogHelperData(false)
    }
  };

  const loadDadosCarreta2 = async (idVeiculo: number) => {
    if (!idVeiculo) {
      setCarreta2(undefined);
      return;
    }
    try {
      const response = await api.get(`/veiculo/${idVeiculo}`);

      setCarreta2(response.data);

      if (response.data.statusCritica === 'CRITICA') {
        if (response.data.statusValidadePesquisa === 'SEM PESQUISA') {
          addToast({ title: 'Atencão:', description: 'A Carreta não possui pesquisa' });
        } else if (response.data.statusValidadePesquisa === 'VENCIDO') {
          addToast({ title: 'Atencão:', description: 'A Carreta está pesquisa vencida' });
        }
      }

      const checkValidity = await api.get('/pesquisa/verificar-vigencia', {
        params: {
          dataInicio: nowToZoned(),
          idCliente: clienteId,
          idCarreta: idVeiculo,
        },
      });

      if (checkValidity.data === "Pesquisa em andamento") {
        setInProgressTruck2(true)
      }


      if (checkValidity.data === 'Nao pode enviar') {
        const vigentes = [];

        vigentes.push(
          response.data?.placa != undefined
            ? `${response.data?.placa} ${response.data?.modelo}`
            : ''
        );

        setVehicleType('carreta2')

        setItensVigentes(vigentes);

        setDialogConsultaOuPesquisa(true);
      }

    } catch (err: any) {
      console.log(err);
    }
  };

  const formattedDataVeiculo = {
    anttRntrc: veiculo?.anttRntrc,
    atualizacaoAutomatica: veiculo?.atualizacaoAutomatica,
    chassi: veiculo?.chassi,
    bloqueado: veiculo?.bloqueado,
    emplacamentoUf: veiculo?.emplacamentoUf,
    id: veiculo?.id,
    placa: veiculo?.placa,
    proprietarioId: veiculo?.proprietarioId,
    renavam: veiculo?.renavam,
    tipoEnum: veiculo?.tipoFrota && getTipoFrota(veiculo?.tipoFrota),
    veiculoTipo: veiculo?.veiculoTipo,
    modelo: veiculo?.modelo,
  };

  const formattedDataCarreta1 = {
    anttRntrc: carreta1?.anttRntrc,
    atualizacaoAutomatica: carreta1?.atualizacaoAutomatica,
    chassi: carreta1?.chassi,
    emplacamentoUf: carreta1?.emplacamentoUf,
    id: carreta1?.id,
    placa: carreta1?.placa,
    proprietarioId: carreta1?.proprietarioId,
    renavam: carreta1?.renavam,
    tipoEnum: carreta1?.tipoFrota && getTipoFrota(carreta1?.tipoFrota),
    veiculoTipo: carreta1?.veiculoTipo,
  };

  const formattedDataCarreta2 = {
    anttRntrc: carreta2?.anttRntrc,
    atualizacaoAutomatica: carreta2?.atualizacaoAutomatica,
    chassi: carreta2?.chassi,
    emplacamentoUf: carreta2?.emplacamentoUf,
    id: carreta2?.id,
    placa: carreta2?.placa,
    proprietarioId: carreta2?.proprietarioId,
    renavam: carreta2?.renavam,
    tipoEnum: carreta2?.tipoFrota && getTipoFrota(carreta2?.tipoFrota),
    veiculoTipo: carreta2?.veiculoTipo,
  };

  useImperativeHandle(
    ref,
    () => ({
      validation() {
        const isVeiculoValid = veiculoValidation({
          veiculo,
          setVeiculoInputErrors,
          flagHabilitarPesquisaAnttRntrc,
        });
        const isCarreta1Valid = veiculoValidation({
          veiculo: carreta1,
          setVeiculoInputErrors: setCarreta1InputErrors,
          flagHabilitarPesquisaAnttRntrc,
        });
        const isCarreta2Valid = veiculoValidation({
          veiculo: carreta2,
          setVeiculoInputErrors: setCarreta2InputErrors,
          flagHabilitarPesquisaAnttRntrc,
        });
        if (!isVeiculoValid || !isCarreta1Valid || !isCarreta2Valid || inProgressTruck || inProgressTruck2 || inProgressVehicle) {
          return false;
        }
        return true;
      },
      veiculo: veiculo?.id ? formattedDataVeiculo : null,
      carreta1: carreta1 ? formattedDataCarreta1 : null,
      carreta2: carreta2 ? formattedDataCarreta2 : null,
      reset() {
        setVeiculo(undefined)
        setCarreta1(undefined)
        setCarreta2(undefined)
        setVeiculoInputErrors({} as InputErrors)
        setCarreta1InputErrors({} as InputErrors)
        setCarreta2InputErrors({} as InputErrors)
      }
    }),
    [addToast, carreta1, carreta2, veiculo, inProgressTruck, inProgressTruck2, inProgressVehicle]
  );

  useEffect(() => {
    if (!veiculo) {
      setVeiculoInputErrors({} as InputErrors);
    }
    if (!carreta1) {
      setCarreta1InputErrors({} as InputErrors);
    }
    if (!carreta2) {
      setCarreta2InputErrors({} as InputErrors);
    }
  }, [carreta1, carreta2, veiculo]);


  const handleSubmit = async (event: any, novaPesquisa?: boolean, tipoPesquisa = 'Pesquisa') => {
    event.preventDefault();


    if (vehicleType === 'veiculo') {
      const itensEnviados = [];

      itensEnviados.push(
        veiculo?.placa != undefined
          ? `${veiculo?.placa} ${veiculo?.modelo}`
          : ''
      );
      setItensPesquisasEnviadas(itensEnviados);


      const formattedDataVeiculo = {
        anttRntrc: veiculo?.anttRntrc,
        atualizacaoAutomatica: veiculo?.atualizacaoAutomatica,
        chassi: veiculo?.chassi,
        bloqueado: veiculo?.bloqueado,
        emplacamentoUf: veiculo?.emplacamentoUf,
        id: veiculo?.id,
        placa: veiculo?.placa,
        proprietarioId: veiculo?.proprietarioId,
        renavam: veiculo?.renavam,
        tipoEnum: veiculo?.tipoFrota && getTipoFrota(veiculo?.tipoFrota),
        veiculoTipo: veiculo?.veiculoTipo,
        modelo: veiculo?.modelo,
      };

      handleRequest(
        {
          idCliente: clienteId,
          idUsuario: usuarioId,
          veiculo: formattedDataVeiculo,
          cadastrarPesquisaNova: novaPesquisa || false,
        },
        'este veículo',
        tipoPesquisa
      );

      return
    }


    if (vehicleType === 'carreta2') {
      const itensEnviados = [];

      itensEnviados.push(
        carreta2?.placa != undefined
          ? `${carreta2?.placa} ${carreta2?.modelo}`
          : ''
      );
      setItensPesquisasEnviadas(itensEnviados);


      const formattedDataCarreta2 = {
        anttRntrc: carreta2?.anttRntrc,
        atualizacaoAutomatica: carreta2?.atualizacaoAutomatica,
        chassi: carreta2?.chassi,
        emplacamentoUf: carreta2?.emplacamentoUf,
        id: carreta2?.id,
        placa: carreta2?.placa,
        proprietarioId: carreta2?.proprietarioId,
        renavam: carreta2?.renavam,
        tipoEnum: carreta2?.tipoFrota && getTipoFrota(carreta2?.tipoFrota),
        veiculoTipo: carreta2?.veiculoTipo,
      };

      handleRequest(
        {
          idCliente: clienteId,
          idUsuario: usuarioId,
          carreta1: formattedDataCarreta2,
          cadastrarPesquisaNova: novaPesquisa || false,
        },
        'esta carreta',
        tipoPesquisa
      );

      return
    }

    if (vehicleType === 'carreta1') {
      const itensEnviados = [];

      itensEnviados.push(
        carreta1?.placa != undefined
          ? `${carreta1?.placa} ${carreta1?.modelo}`
          : ''
      );
      setItensPesquisasEnviadas(itensEnviados);


      const formattedDataCarreta1 = {
        anttRntrc: carreta1?.anttRntrc,
        atualizacaoAutomatica: carreta1?.atualizacaoAutomatica,
        chassi: carreta1?.chassi,
        emplacamentoUf: carreta1?.emplacamentoUf,
        id: carreta1?.id,
        placa: carreta1?.placa,
        proprietarioId: carreta1?.proprietarioId,
        renavam: carreta1?.renavam,
        tipoEnum: carreta1?.tipoFrota && getTipoFrota(carreta1?.tipoFrota),
        veiculoTipo: carreta1?.veiculoTipo,
      };

      handleRequest(
        {
          idCliente: clienteId,
          idUsuario: usuarioId,
          carreta1: formattedDataCarreta1,
          cadastrarPesquisaNova: novaPesquisa || false,
        },
        'esta carreta',
        tipoPesquisa
      );

      return
    }


  };

  const handleRequest = async (data: any, message: string, tipoPesquisa: string = 'Pesquisa') => {
    setLoading(true);

    try {
      const response = await api.post('/pesquisa/integrada', {
        ...data,
        pesquisaManual: false,
        tipoPesquisa
      });


      if (response.data.statusCode !== 200 ) {
        setLoading(false);
        setIsDialogCadErro(true);

        setDataErros({
          mensagemErro: response.data.message,
          listaErros: response.data.dminerErrors
        });
      } else {
        if (
          response.data.mensagem?.includes('Consulta') ||
          response.data.mensagemAjudante?.includes('Consulta') ||
          response.data.mensagemCondutor?.includes('Consulta') ||
          response.data.mensagemVeiculo?.includes('Consulta') ||
          response.data.mensagemCarreta?.includes('Consulta') ||
          response.data.mensagemCarreta2?.includes('Consulta')
        ) {
          setTipoPesquisa('Consulta');
        } else {
          setTipoPesquisa('Pesquisa');
        }

        setIdPesquisaEnviada(response.data.solicitationCode);
        setIsDialogCadOK(true);
      }
    } catch (error: any) {
      if (
        error.response.data.message?.includes('processamento') ||
        error.response.data.message?.includes('Constam resultados')
      ) {
        setIsDialogCadErro(true);
        setDataErros({
          mensagemErro: `já existe uma pesquisa em andamento para ${message}`,
          listaErros: null as any,
        });
      } else {
        const message = error.response.data.message?.split('[')[2].split(']')[0];
        const msg = message.split(',')[1].split('=')[1];

        addToast({
          title: 'Erro!',
          description: error.response.data.message.includes('DminerErroPesquisaVO')
            ? `Atenção: "${msg}"`
            : error.response.data.message,
          type: 'error',
        });
      }
    } finally {
      if (vehicleType === 'veiculo') {
        setVeiculo(undefined)

        if(props.habilitarPesquisaIntegrada) {
          history.replace('/enviar-pesquisa/condutor-veiculo-carreta');
        } else {
          history.replace('/enviar-pesquisa/veiculo');
        }
      }

      if (vehicleType === 'carreta1') {
        setCarreta1(undefined)

        if(props.habilitarPesquisaIntegrada) {
          history.replace('/enviar-pesquisa/condutor-veiculo-carreta');
        } else {
          history.replace('/enviar-pesquisa/carreta');
        }
      }
      setDialogConsultaOuPesquisa(false);
      setLoading(false);
      return;
    }
  };

  const [dialogHelperData, setDialogHelperData] = useState(false)

  return (
    <>
    <Dialog
        header={
          <>
          <p>Aguarde enquanto buscamos os dados...</p>
            <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
          </>
        }
        closable={false}

        visible={dialogHelperData}
        modal
        onHide={() => {
          setDialogHelperData(false);
        }}
      />


      <Dialog
        header={
          <>
            <p>{`Não foi possível enviar pesquisa. `}</p>
            {dataErros?.listaErros ? (
              <>
                {dataErros.listaErros.map((each: any) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        closable={false}
        footer={
          <>
            <Button
              onClick={() => {
                setIsDialogCadErro(false);
              }}
            >
              OK
            </Button>
          </>
        }
        visible={isDialogCadErro}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
        }}
      />

      <Dialog
        header={
          <>
            <p>{tipoPesquisa} enviada com sucesso para:</p>
            {itensPesquisasEnviadas ? (
              <>
                {itensPesquisasEnviadas.map((each: string) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Número: {idPesquisaEnviada}</p>
            <Button
            type='button'
              onClick={() => {
                setIsDialogCadOK(false);
                history.go(0)
              }}
            >
              OK
            </Button>
          </div>
        }
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
          history.go(0)
        }}
      />

      <Dialog
        header={
          <div>
            <p>Já existe pesquisa vigente para:</p>
            <br />
            {itensVigentes.map((each: string) => (
              <li>{each}</li>
            ))}
            <br />
          </div>
        }
        closable={false}
        footer={
          <>
            {props.habilitarPesquisaVigente ? (
              <>
               {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <>
               <p>O que deseja fazer?</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    type="button"
                    style={{ color: 'black', fontSize: '12px', background: '#FFDA53', borderColor: '#FFDA53' }}
                    onClick={(e: any) => {
                      handleSubmit(e, true, 'Pesquisa');

                    }}
                  >
                    Nova Pesquisa
                  </Button>
                  <Button
                    type="button"
                    style={{ color: 'black', fontSize: '12px', background: '#FFDA53', borderColor: '#FFDA53 '}}
                    onClick={(e: any) => {
                      handleSubmit(e, false, 'Consulta');
                    }}
                  >
                    Consulta
                  </Button>
                  <Button
                    type="button"
                    style={{
                      background: 'white',
                      border: '1px solid black',
                      color: 'black',
                      fontSize: '12px',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      setDialogConsultaOuPesquisa(false);
                    }}
                  >
                    Cancelar
                  </Button>
                </div>
              </>
            ) }


              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                 type="button"
                  style={{
                    background: 'white',
                    border: '1px solid black',
                    color: 'black',
                    fontSize: '11px',
                  }}
                  onClick={() => {
                    setDialogConsultaOuPesquisa(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </>
        }
        visible={dialogConsultaOuPesquisa}
        style={{ width: '40vw' }}
        modal
        onHide={() => {
          setDialogConsultaOuPesquisa(false);
        }}
      />

      <Form>
        {tabAtual === 'veiculo' ? (
          <>
            <h2 className="titulo">Dados da Pesquisa de Veículos</h2>
            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <div className="containerLimpar">
                  <Form.Label>Placa:</Form.Label>
                  <p
                    className="limparButton"
                    onClick={() => {
                      history.replace(`/enviar-pesquisa/veiculo`);
                      setVeiculo(undefined);
                    }}
                  >
                    Limpar
                  </p>
                </div>
                <Dropdown
                  name="placa"
                  value={veiculo ? veiculo.id : ''}
                  options={listaVeiculos}
                  onChange={(e) => {
                    setInProgressVehicle(false)
                    loadDadosVeiculo(parseInt(e.target.value, 10));
                  }}
                  onBlur={() =>
                    veiculoValidation({
                      veiculo,
                      setVeiculoInputErrors,
                      flagHabilitarPesquisaAnttRntrc,
                    })
                  }
                  optionLabel="placa"
                  optionValue="id"
                  filter
                  filterBy="placa"
                  placeholder="Selecione"
                  className="p-dropdown"
                  showClear
                  style={{
                    width: '100%',
                    borderColor: inProgressVehicle ? '#ff0000' : '#ced4da',
                  }}
                />
                <p style={{ fontSize: '14px', color: '#ff0000' }}>{inProgressVehicle ? 'Veículo possui pesquisa em andamento' : ''}</p>

              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Input
                disabled={!veiculo}
                required
                value={veiculo ? veiculo.renavam : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                id="renavam"
                name="renavam"
                textInputTitle="Renavam nº:"
                error={veiculoInputErrors.renavam}
                minLength={8}
                maxLength={11}
              />
              <Input
                disabled={!veiculo}
                required
                value={veiculo ? veiculo.chassi : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 17) {
                    return;
                  }
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                id="chassi"
                name="chassi"
                textInputTitle="Chassi nº:"
                error={veiculoInputErrors.chassi}
                maxLength={17}
              />
              <Select
                required
                disabled={!veiculo}
                name="emplacamentoUf"
                value={veiculo ? veiculo.emplacamentoUf : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="UF Emplacamento:"
                error={veiculoInputErrors.emplacamentoUf}
              >
                <option value="0">Selecione</option>
                {listaUfs.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.sigla}
                  </option>
                ))}
              </Select>
            </Form.Row>
            <Form.Row>
              <Select
                disabled={!veiculo}
                name="proprietarioId"
                required
                value={veiculo ? veiculo.proprietarioId : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="Proprietário:"
                error={veiculoInputErrors.proprietarioId}
              >
                <option value="0">Selecione</option>
                {listaProprietarios.map((each) => (
                  <option value={each.id} key={each.id}>
                    {each.razaoSocial}
                  </option>
                ))}
              </Select>
              <Input
                disabled={!veiculo}
                required={flagHabilitarPesquisaAnttRntrc}
                value={veiculo ? veiculo.anttRntrc : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateVeiculo(e);
                }}
                id="anttRntrc"
                name="anttRntrc"
                textInputTitle="ANTT/RNTRC nº:"
                error={veiculoInputErrors.anttRntrc}
                maxLength={9}
              />
              <Select
                disabled={!veiculo}
                name="tipoFrota"
                required
                value={veiculo?.tipoFrota ? veiculo.tipoFrota : '0'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="Tipo:"
                error={veiculoInputErrors.veiculoTipo}
              >
                <option value="0">Selecione</option>
                <option value="1">PRÓPRIO</option>
                <option value="2">AGREGADO</option>
                <option value="3">TERCEIRO</option>
              </Select>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicCheckbox">
                <Switch
                  disabled={!veiculo}
                  value={veiculo?.atualizacaoAutomatica ? 1 : 0}
                  checked={veiculo ? veiculo?.atualizacaoAutomatica : false}
                  onChange={() => toggleSwitchAtualizacaoAutomatica('veiculo')}
                  name="atualizacaoAutomaticaVeiculo"
                  id="pesquisaVeiculo"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />
              </Form.Group>
            </Form.Row>
          </>
        ) : null}

        {tabAtual === 'carreta' ? (
          <>
            <h2 className="titulo">Dados da Pesquisa de Carreta</h2>
            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <div className="containerLimpar">
                  <Form.Label>Placa Carreta:</Form.Label>
                  <p
                    className="limparButton"
                    onClick={() => {
                      history.replace(`/enviar-pesquisa/carreta`);
                      setCarreta1(undefined);
                      setCarreta2(undefined);
                    }}
                  >
                    Limpar
                  </p>
                </div>
                <Dropdown
                  name="placa"
                  value={carreta1 ? carreta1.id : ''}
                  options={listaCarretas}
                  onChange={(e) => {
                    setInProgressTruck(false)
                    loadDadosCarreta1(parseInt(e.target.value, 10));
                  }}
                  onBlur={() =>
                    veiculoValidation({
                      veiculo: carreta1,
                      setVeiculoInputErrors: setCarreta1InputErrors,
                      flagHabilitarPesquisaAnttRntrc,
                    })
                  }
                  optionLabel="placa"
                  optionValue="id"
                  filter
                  filterBy="placa"
                  placeholder="Selecione"
                  className="p-dropdown"
                  showClear
                  style={{
                    width: '100%',
                    borderColor: inProgressTruck ? '#ff0000' : '#ced4da',
                  }}
                />
                <p style={{ fontSize: '14px', color: '#ff0000' }}>{inProgressTruck ? 'Carreta possui pesquisa em andamento' : ''}</p>

              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Input
                disabled={!carreta1}
                required
                value={carreta1 ? carreta1.renavam : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                id="renavam"
                name="renavam"
                textInputTitle="Renavam nº:"
                error={carreta1InputErrors.renavam}
                minLength={8}
                maxLength={11}
              />

              <Input
                disabled={!carreta1}
                required
                value={carreta1 ? carreta1.chassi : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 17) {
                    return;
                  }
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                id="chassi"
                name="chassi"
                textInputTitle="Chassi nº:"
                error={carreta1InputErrors.chassi}
                maxLength={17}
              />

              <Select
                required
                name="emplacamentoUf"
                disabled={!carreta1}
                value={carreta1 ? carreta1.emplacamentoUf : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  if (carreta1) {
                    setCarreta1({
                      ...carreta1,
                      emplacamentoUf: parseInt(e.target.value, 10),
                    });
                  }
                }}
                textInputTitle="UF Emplacamento:"
              >
                <option value="0">Selecione</option>
                {listaUfs.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.sigla}
                  </option>
                ))}
              </Select>
            </Form.Row>
            <Form.Row>
              <Select
                disabled={!carreta1}
                name="proprietarioId"
                required
                value={carreta1 ? carreta1.proprietarioId : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="Proprietário:"
                error={carreta1InputErrors.proprietarioId}
              >
                <option value="0">Selecione</option>
                {listaProprietarios.map((each) => (
                  <option value={each.id} key={each.id}>
                    {each.razaoSocial}
                  </option>
                ))}
              </Select>
              <Input
                disabled={!carreta1}
                required={flagHabilitarPesquisaAnttRntrc}
                value={carreta1 ? carreta1.anttRntrc : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateCarreta1(e);
                }}
                id="anttRntrc"
                name="anttRntrc"
                textInputTitle="ANTT/RNTRC nº:"
                error={carreta1InputErrors.anttRntrc}
                maxLength={9}
              />
              <Select
                disabled={!carreta1}
                required
                value={carreta1?.tipoFrota ? carreta1.tipoFrota : '0'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="Tipo:"
                error={carreta1InputErrors.veiculoTipo}
                name="tipoFrota"
              >
                <option value="0">Selecione</option>
                <option value="1">PRÓPRIO</option>
                <option value="2">AGREGADO</option>
                <option value="3">TERCEIRO</option>
              </Select>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formBasicCheckbox">
                <Switch
                  disabled={!carreta1}
                  value={carreta1?.atualizacaoAutomatica ? 1 : 0}
                  checked={carreta1 ? carreta1.atualizacaoAutomatica : false}
                  onChange={() => toggleSwitchAtualizacaoAutomatica('carreta1')}
                  name="atualizacaoAutomatica"
                  id="atualizacaoAutomaticaCarreta1"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />
              </Form.Group>
            </Form.Row>
          </>
        ) : null}

        {tabAtual === 'condutor-veiculo-carreta' ? (
          <>
            <h2 className="titulo">Dados da Pesquisa de Veículos</h2>
            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <div className="containerLimpar">
                  <Form.Label>Placa:</Form.Label>
                  <p
                    className="limparButton"
                    onClick={() => {
                      setVeiculo(undefined);
                    }}
                  >
                    Limpar
                  </p>
                </div>
                <Dropdown
                  name="placa"
                  value={veiculo ? veiculo.id : ''}
                  options={listaVeiculos}
                  onChange={(e) => {
                    setInProgressVehicle(false)
                    loadDadosVeiculo(parseInt(e.target.value, 10));
                  }}
                  onBlur={() =>
                    veiculoValidation({
                      veiculo,
                      setVeiculoInputErrors,
                      flagHabilitarPesquisaAnttRntrc,
                    })
                  }
                  optionLabel="placa"
                  optionValue="id"
                  filter
                  filterBy="placa"
                  placeholder="Selecione"
                  className="p-dropdown"
                  showClear
                  style={{
                    width: '100%',
                    borderColor: inProgressVehicle ? '#ff0000' : '#ced4da',
                  }}
                />
                <p style={{ fontSize: '14px', color: '#ff0000' }}>{inProgressVehicle ? 'Veículo possui pesquisa em andamento' : ''}</p>


              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Input
                disabled={!veiculo}
                required
                value={veiculo ? veiculo.renavam : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                id="renavam"
                name="renavam"
                textInputTitle="Renavam nº:"
                error={veiculoInputErrors.renavam}
                minLength={8}
                maxLength={11}
              />
              <Input
                disabled={!veiculo}
                required
                value={veiculo ? veiculo.chassi : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 17) {
                    return;
                  }
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                id="chassi"
                name="chassi"
                textInputTitle="Chassi nº:"
                error={veiculoInputErrors.chassi}
                maxLength={17}
              />
              <Select
                required
                disabled={!veiculo}
                name="emplacamentoUf"
                value={veiculo ? veiculo.emplacamentoUf : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="UF Emplacamento:"
                error={veiculoInputErrors.emplacamentoUf}
              >
                <option value="0">Selecione</option>
                {listaUfs.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.sigla}
                  </option>
                ))}
              </Select>
            </Form.Row>
            <Form.Row>
              <Select
                disabled={!veiculo}
                name="proprietarioId"
                required
                value={veiculo ? veiculo.proprietarioId : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="Proprietário:"
                error={veiculoInputErrors.proprietarioId}
              >
                <option value="0">Selecione</option>
                {listaProprietarios.map((each) => (
                  <option value={each.id} key={each.id}>
                    {each.razaoSocial}
                  </option>
                ))}
              </Select>
              <Input
                disabled={!veiculo}
                required={flagHabilitarPesquisaAnttRntrc}
                value={veiculo ? veiculo.anttRntrc : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateVeiculo(e);
                }}
                id="anttRntrc"
                name="anttRntrc"
                textInputTitle="ANTT/RNTRC nº:"
                error={veiculoInputErrors.anttRntrc}
                maxLength={9}
              />
              <Select
                disabled={!veiculo}
                name="tipoFrota"
                required
                value={veiculo?.tipoFrota ? veiculo.tipoFrota : '0'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateVeiculo(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo,
                    setVeiculoInputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="Tipo:"
                error={veiculoInputErrors.veiculoTipo}
              >
                <option value="0">Selecione</option>
                <option value="1">PRÓPRIO</option>
                <option value="2">AGREGADO</option>
                <option value="3">TERCEIRO</option>
              </Select>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicCheckbox">
                <Switch
                  disabled={!veiculo}
                  value={veiculo?.atualizacaoAutomatica ? 1 : 0}
                  checked={veiculo ? veiculo?.atualizacaoAutomatica : false}
                  onChange={() => toggleSwitchAtualizacaoAutomatica('veiculo')}
                  name="atualizacaoAutomaticaVeiculo"
                  id="pesquisaVeiculo"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} sm={12}>
                <div className="containerLimpar">
                  <Form.Label>Placa Carreta (1):</Form.Label>
                  <p
                    className="limparButton"
                    onClick={() => {
                      setCarreta1(undefined);
                      setCarreta2(undefined);
                    }}
                  >
                    Limpar
                  </p>
                </div>
                <Dropdown
                  name="placa"
                  value={carreta1 ? carreta1.id : ''}
                  options={listaCarretas}
                  onChange={(e) => {
                    setInProgressTruck(false)
                    loadDadosCarreta1(parseInt(e.target.value, 10));
                  }}
                  onBlur={() =>
                    veiculoValidation({
                      veiculo: carreta1,
                      setVeiculoInputErrors: setCarreta1InputErrors,
                      flagHabilitarPesquisaAnttRntrc,
                    })
                  }
                  optionLabel="placa"
                  optionValue="id"
                  filter
                  filterBy="placa"
                  placeholder="Selecione"
                  className="p-dropdown"
                  showClear
                  style={{
                    width: '100%',
                    borderColor: inProgressTruck ? '#ff0000' : '#ced4da',
                  }}
                />
                <p style={{ fontSize: '14px', color: '#ff0000' }}>{inProgressTruck ? 'Carreta possui pesquisa em andamento' : ''}</p>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Input
                disabled={!carreta1}
                required
                value={carreta1 ? carreta1.renavam : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                id="renavam"
                name="renavam"
                textInputTitle="Renavam nº:"
                error={carreta1InputErrors.renavam}
                minLength={8}
                maxLength={11}
              />

              <Input
                disabled={!carreta1}
                required
                value={carreta1 ? carreta1.chassi : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.value.length > 17) {
                    return;
                  }
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                id="chassi"
                name="chassi"
                textInputTitle="Chassi nº:"
                error={carreta1InputErrors.chassi}
                maxLength={17}
              />

              <Select
                required
                name="emplacamentoUf"
                disabled={!carreta1}
                value={carreta1 ? carreta1.emplacamentoUf : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  if (carreta1) {
                    setCarreta1({
                      ...carreta1,
                      emplacamentoUf: parseInt(e.target.value, 10),
                    });
                  }
                }}
                textInputTitle="UF Emplacamento:"
              >
                <option value="0">Selecione</option>
                {listaUfs.map((i) => (
                  <option value={i.id} key={i.id}>
                    {i.sigla}
                  </option>
                ))}
              </Select>
            </Form.Row>
            <Form.Row>
              <Select
                disabled={!carreta1}
                name="proprietarioId"
                required
                value={carreta1 ? carreta1.proprietarioId : ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="Proprietário:"
                error={carreta1InputErrors.proprietarioId}
              >
                <option value="0">Selecione</option>
                {listaProprietarios.map((each) => (
                  <option value={each.id} key={each.id}>
                    {each.razaoSocial}
                  </option>
                ))}
              </Select>
              <Input
                disabled={!carreta1}
                required={flagHabilitarPesquisaAnttRntrc}
                value={carreta1 ? carreta1.anttRntrc : ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  updateCarreta1(e);
                }}
                id="anttRntrc"
                name="anttRntrc"
                textInputTitle="ANTT/RNTRC nº:"
                error={carreta1InputErrors.anttRntrc}
                maxLength={9}
              />
              <Select
                disabled={!carreta1}
                required
                value={carreta1?.tipoFrota ? carreta1.tipoFrota : '0'}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                  updateCarreta1(e);
                }}
                onBlur={() =>
                  veiculoValidation({
                    veiculo: carreta1,
                    setVeiculoInputErrors: setCarreta1InputErrors,
                    flagHabilitarPesquisaAnttRntrc,
                  })
                }
                textInputTitle="Tipo:"
                error={carreta1InputErrors.veiculoTipo}
                name="tipoFrota"
              >
                <option value="0">Selecione</option>
                <option value="1">PRÓPRIO</option>
                <option value="2">AGREGADO</option>
                <option value="3">TERCEIRO</option>
              </Select>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formBasicCheckbox">
                <Switch
                  disabled={!carreta1}
                  value={carreta1?.atualizacaoAutomatica ? 1 : 0}
                  checked={carreta1 ? carreta1.atualizacaoAutomatica : false}
                  onChange={() => toggleSwitchAtualizacaoAutomatica('carreta1')}
                  name="atualizacaoAutomatica"
                  id="atualizacaoAutomaticaCarreta1"
                  type="switch"
                  label="Atualizar pesquisa automaticamente"
                />
              </Form.Group>
            </Form.Row>
            {carreta1 && listaCarretas.filter((each) => each.id !== carreta1?.id).length ? (
              <>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label>Placa Carreta (2):</Form.Label>
                    <Dropdown
                      name="placa"
                      value={carreta2 ? carreta2.id : ''}
                      options={listaCarretas.filter((each) => each.id !== carreta1?.id)}
                      onChange={(e) => {
                        setInProgressTruck2(false)
                        loadDadosCarreta2(parseInt(e.target.value, 10));
                      }}
                      onBlur={() =>
                        veiculoValidation({
                          veiculo: carreta2,
                          setVeiculoInputErrors: setCarreta2InputErrors,
                          flagHabilitarPesquisaAnttRntrc,
                        })
                      }
                      optionLabel="placa"
                      optionValue="id"
                      filter
                      filterBy="placa"
                      placeholder="Selecione"
                      className="p-dropdown"
                      showClear
                      style={{
                        width: '100%',
                        borderColor: inProgressTruck2 ? '#ff0000' : '#ced4da',
                      }}
                    />
                    <p style={{ fontSize: '14px', color: '#ff0000' }}>{inProgressTruck2 ? 'Carreta possui pesquisa em andamento' : ''}</p>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Input
                    disabled={!carreta2}
                    required
                    value={carreta2 ? carreta2.renavam : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                        flagHabilitarPesquisaAnttRntrc,
                      })
                    }
                    id="renavam"
                    name="renavam"
                    textInputTitle="Renavam nº:"
                    error={carreta2InputErrors.renavam}
                    maxLength={11}
                  />
                  <Input
                    disabled={!carreta2}
                    required
                    value={carreta2 ? carreta2.chassi : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length > 17) {
                        return;
                      }
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                        flagHabilitarPesquisaAnttRntrc,
                      })
                    }
                    id="chassi"
                    name="chassi"
                    textInputTitle="Chassi nº:"
                    error={carreta2InputErrors.chassi}
                    maxLength={17}
                  />
                  <Select
                    required
                    disabled={!carreta2}
                    value={carreta2 ? carreta2.emplacamentoUf : ''}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      if (carreta2) {
                        setCarreta2({
                          ...carreta2,
                          emplacamentoUf: parseInt(e.target.value, 10),
                        });
                      }
                    }}
                    textInputTitle="UF Emplacamento:"
                  >
                    <option value="0">Selecione</option>
                    {listaUfs.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.sigla}
                      </option>
                    ))}
                  </Select>
                </Form.Row>
                <Form.Row>
                  <Select
                    disabled={!carreta2}
                    name="proprietarioId"
                    required
                    value={carreta2 ? carreta2.proprietarioId : ''}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                        flagHabilitarPesquisaAnttRntrc,
                      })
                    }
                    textInputTitle="Proprietário:"
                    error={carreta2InputErrors.proprietarioId}
                  >
                    <option value="0">Selecione</option>
                    {listaProprietarios.map((each) => (
                      <option value={each.id} key={each.id}>
                        {each.razaoSocial}
                      </option>
                    ))}
                  </Select>

                  <Input
                    disabled={!carreta2}
                    required={flagHabilitarPesquisaAnttRntrc}
                    value={carreta2 ? carreta2.anttRntrc : ''}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      updateCarreta2(e);
                    }}
                    id="anttRntrc"
                    name="anttRntrc"
                    textInputTitle="ANTT/RNTRC nº:"
                    error={carreta2InputErrors.anttRntrc}
                    maxLength={9}
                  />
                  <Select
                    disabled={!carreta2}
                    required
                    value={carreta2?.tipoFrota ? carreta2.tipoFrota : '0'}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      updateCarreta2(e);
                    }}
                    onBlur={() =>
                      veiculoValidation({
                        veiculo: carreta2,
                        setVeiculoInputErrors: setCarreta2InputErrors,
                        flagHabilitarPesquisaAnttRntrc,
                      })
                    }
                    textInputTitle="Tipo:"
                    error={carreta2InputErrors.veiculoTipo}
                    name="tipoFrota"
                  >
                    <option value="0">Selecione</option>
                    <option value="1">PRÓPRIO</option>
                    <option value="2">AGREGADO</option>
                    <option value="3">TERCEIRO</option>
                  </Select>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} controlId="formBasicCheckbox">
                    <Switch
                      disabled={!carreta2}
                      value={carreta2?.atualizacaoAutomatica ? 1 : 0}
                      checked={carreta2 ? carreta2.atualizacaoAutomatica : false}
                      onChange={() => toggleSwitchAtualizacaoAutomatica('carreta2')}
                      name="atualizacaoAutomatica"
                      id="atualizacaoAutomaticaCarreta2"
                      type="switch"
                      label="Atualizar pesquisa automaticamente"
                    />
                  </Form.Group>
                </Form.Row>
              </>
            ) : null}
          </>
        ) : null}
      </Form>


    </>
  );
});
