/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/control-has-associated-label */
/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Modal } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';
import { FiltroCarreta } from './ConsultarCarreta';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import { cpfMask, maskCpnj } from '../../util/Validacoes/Validacoes';
import getTipoBau from '../../util/Validacoes/getTipoBau';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

type ListagemConsulta = {
  atualizacao: boolean;
  contrato: string;
  empresa: string;
  idCarreta: number;
  idCliente: number;
  placaCarreta: string;
  tipoFrota: string;
  proprietario: string;
  cnpjProprietario: string;
  marca: string;
  tipoCarreta: string;
  renavam: string;
  chassi: string;
  anoFabricacao: string;
  anoModelo: string;
  cidadeEmplacamento: string;
  ufEmplacamento: string;
  bloqueado: boolean;
  mapa: boolean;
};

const ListagemConsultaCarreta = () => {
  const { rolePermission, doesHavePermission: canDelete } = useRolePermission();
  const { ADMIN } = PERMISSION;

  const history = useHistory();
  const [placaCarreta, setPlacaCarreta] = useState<string | undefined>();
  const [idCarreta, setIdCarreta] = useState<number | undefined>();
  const [searchTerm, setSearchTerm] = useState('');
  const location: { state: { listagem: ListagemConsulta[]; model: FiltroCarreta } } = useLocation();
  const { state } = location;
  const [show, setShow] = useState(false);
  const [exclusaoOK, setExclusaoOK] = useState(false);
  const [exclusaoErro, setExclusaoErro] = useState(false);
  const [edicaoAcessada, setEdicaoAcessada] = useState(false);
  const [mensagemErro, setMensagemErro] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const [data, setData] = useState<any[]>([]);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const titles = [
    '+id',
    'Cliente',
    'Placa',
    'Tipo Contrato',
    'Tipo Frota',
    'Bloqueado',
    'Mapa',
    'Atualização Automática',
  ];

  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>([
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Carretas',
          widthPx: 1060,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        { title: 'Empresa', width: { wch: 15 } },
        { title: 'Placa', width: { wch: 15 } },
        { title: 'Proprietário', width: { wch: 30 } },
        { title: 'Doc Proprietário', width: { wch: 20 } },
        { title: 'Marca', width: { wch: 15 } },
        { title: 'Descrição', width: { wch: 30 } },
        { title: 'Tipo Frota', width: { wch: 10 } },
        { title: 'Renavam nº', width: { wch: 15 } },
        { title: 'Chassi nº', width: { wch: 20 } },
        { title: 'Ano Fabricação', width: { wch: 15 } },
        { title: 'Ano Modelo', width: { wch: 13 } },
        { title: 'Cidade / UF', width: { wch: 15 } },
        { title: 'Contrato', width: { wch: 15 } },
        { title: 'Atualização Automática', width: { wch: 22 } },
      ],
      data: state.listagem.map((each) => [
        { value: each.empresa ? each.empresa : '' },
        { value: each.placaCarreta ? each.placaCarreta : '' },
        { value: each.proprietario ? each.proprietario : '' },
        {
          value: each.cnpjProprietario
            ? each.cnpjProprietario.length === 11
              ? cpfMask(each.cnpjProprietario)
              : maskCpnj(each.cnpjProprietario)
            : '',
        },
        { value: each.marca ? each.marca : '' },
        { value: each.tipoCarreta ? each.tipoCarreta : '' },
        { value: each.tipoFrota ? getTipoBau(each.tipoFrota) : '' },
        { value: each.renavam ? each.renavam : '' },
        { value: each.chassi ? each.chassi : '' },
        { value: each.anoFabricacao === '0' || !each.anoFabricacao ? '' : each.anoFabricacao },
        { value: each.anoModelo === '0' || !each.anoModelo ? '' : each.anoModelo },
        {
          value:
            each.cidadeEmplacamento && each.ufEmplacamento
              ? `${each.cidadeEmplacamento ? each.cidadeEmplacamento : ''} / ${
                  each.ufEmplacamento ? each.ufEmplacamento : ''
                }`
              : each.cidadeEmplacamento
              ? each.cidadeEmplacamento
              : each.ufEmplacamento
              ? each.ufEmplacamento
              : '',
        },
        { value: each.contrato ? each.contrato : '' },
        { value: each.atualizacao === true ? 'Sim' : 'Não' },
      ]),
    },
  ]);

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.CARRETA]}], 'excluir');
  }, [ADMIN]);

  const handleObject = (listagem: any[]) => {
    const array = listagem.map((each: ListagemConsulta) => {
      return [
        each.idCarreta,
        each.empresa,
        each.placaCarreta,
        each.contrato === '' ? 'NÃO INFORMADO' : each.contrato,
        getTipoFrota(each.tipoFrota),
        each.bloqueado ? 'Sim' : 'Não',
        each.mapa ? 'Sim' : 'Não',
        each.atualizacao ? 'Sim' : 'Não',
      ];
    });
    return array;
  };

  const handleDelete = async (id: number | undefined) => {
    try {
      const response = await axios.delete(`/veiculo/${id}`);
      console.log(response);
      setExclusaoOK(true);
    } catch (err: any) {
      console.log(err.response);
      setExclusaoErro(true);
      setMensagemErro(err.response.data.message);
    }
  };

  useEffect(() => {
    setData(state.listagem);
    const carreta = state.listagem
      ?.filter((vei) => vei.idCarreta === idCarreta)
      .find((each) => each.placaCarreta);
    console.log(carreta);
    setPlacaCarreta(carreta?.placaCarreta);
  }, [state, idCarreta]);

  useEffect(() => {
    const reloadDados = async () => {
      try {
        const response = await axios.get(`/veiculo/consulta-gerenciar-carreta`, {
          params: {
            ...state.model,
          },
        });
        setData(response.data);
        setDataTabela(handleObject(response.data));
      } catch (err: any) {
        console.log(err.response);
      }
    };
    reloadDados();
  }, [edicaoAcessada]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(dataTabela, searchTerm));
    }
  }, [searchTerm]);

  return (
    <Tela
      nome="Carreta"
      caminho="Cadastro > Gerenciamento de Clientes > Carreta"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchTerm}
      search={searchTerm}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={currentPage.toString()}
      excelGeneration={excelGeneration}
      filename="Carretas"
    >
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          {!exclusaoOK && !exclusaoErro && (
            <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
          )}
          {exclusaoOK && <Modal.Title id="contained-modal-title-vcenter">Sucesso!</Modal.Title>}
        </Modal.Header>

        {!exclusaoOK && !exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro: {`${placaCarreta}`}?
          </Modal.Body>
        )}

        {exclusaoOK && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Registro excluído com sucesso.
          </Modal.Body>
        )}

        {exclusaoErro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{`${mensagemErro}`}</Modal.Body>
        )}

        {!exclusaoErro && !exclusaoErro && !exclusaoOK && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '2%' }}
              onClick={() => setShow(false)}
              label="Não"
            />
            <Button
              style={{ width: '120px', height: '50px' }}
              variant="secondary"
              onClick={() => handleDelete(idCarreta)}
              label="Sim"
            />
          </Modal.Footer>
        )}
        {exclusaoOK && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px', marginRight: '2%' }}
              onClick={() => {
                window.location.reload();
              }}
              label="OK"
            />
          </Modal.Footer>
        )}
      </Modal>
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          left
          onClickEditar={(id: any) => {
            setEdicaoAcessada(true);
            history.push({
              pathname: `/cadastro/carretas/atualizar/true/${id}`,
              state: data.find((each: ListagemConsulta) => each.idCarreta === id).idCliente,
            });
          }}
          onClickExcluir={(id: any) => {
            if (canDelete) {
              setExclusaoErro(false);
              setExclusaoOK(false);
              setShow(true);
              setIdCarreta(id);
            } else {
              history.push('/acessonegado');
            }
          }}
        />
      )}
    </Tela>
  );
};

export default ListagemConsultaCarreta;
