/* eslint-disable */

import React, { useState, useRef, useContext, useCallback, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { RadioButton } from 'primereact/radiobutton';
import Logo from '../../assets/logo_melhorresol.png';
import { ReactComponent as MapImage } from '../../assets/undraw_map_1r69.svg';
import './styles.css';
import { authContext } from '../../context/AuthContext';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/esm/Modal';
import Banner from '../../assets/banner-login.png';
import { getCookieSessionData } from '../../services/cookieService';
import { login as loginService } from '../../services/login';
import { useToast } from '../../hooks/Toast';

import { doLogin, saveCookieSessionData } from '../../services/cookieService';
import { noArroba } from '../../util/Validacoes/Validacoes';
import { Spinner } from 'react-bootstrap';

const LoginParceiros: React.FC = () => {
  // const { auth, setAuth } = useContext(authContext);
  const [login, setLogin] = useState<string>('');
  const [codigo, setCodigo] = useState<number | null>(null);
  const [senha, setSenha] = useState<string>('');
  const [role, setRole] = useState<string>('corretora');
  const [notShow, setNotShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const messages = useRef<Toast>();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { addToast } = useToast();

  const handleLogin = useCallback(async () => {
    setLoading(false);

    const loginResponse = await loginService({
      codigo,
      login,
      role,
      senha,
    });

    if (typeof loginResponse === 'string') {
      addToast({
        title: 'Erro',
        description: loginResponse,
        type: 'error',
      });

      setLoading(false);

      return;
    }

    if (
      (loginResponse.userSession.usuarioVO.role === 'seguradora' ||
        loginResponse.userSession.usuarioVO.role === 'corretora') &&
      typeof codigo !== 'number'
    ) {
      return;
    }

    saveCookieSessionData(loginResponse.userSession);

    doLogin();

    handleShow();

    setLoading(false);
  }, [login, senha, role, codigo]);

  useEffect(() => {
    const listener = (event: globalThis.KeyboardEvent) => {
      // if ( event.code === 'Enter' ) {
      // if ( event.code === 'Enter' || ((key===13) && (e.location===3)) ) {
      if (event.keyCode === 13) {
        handleLogin();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [login, senha, role, codigo, handleLogin]);

  return (
    <div className="row screen-container m-0">
      <Modal
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Usuário ou Senha incorretos!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Voltar
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        style={{ width: '70%', margin: '0 auto' }}
        className="col-12 col-lg-6 d-flex flex-column align-items-center justify-content-center pb-3 screen-left-container"
      >
        <img src={Logo} className="logo-image" />
        <div className="container-title">
          <h1 className="login-title">Seja bem-vindo ao Golden LOG!</h1>
          <div className="line" />
        </div>

        <Toast
          ref={(el) => {
            if (el) messages.current = el;
          }}
        />

        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignSelf: 'center',
              marginLeft: '20.5%',
              marginRight: '20%',
              marginTop: '10%',
            }}
          />
        ) : (
          <div className="w-100">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'center',
                marginLeft: '20%',
                marginRight: '20%',
                marginTop: '1rem',
              }}
            >
              <div
                style={{
                  marginInline: '5%',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor="cb1" className="role-text">
                  Corretora
                </label>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <RadioButton
                    inputId="cb1"
                    name="tipoLogin"
                    value="corretora"
                    onChange={(e) => {
                      setRole(e.value);
                      setNotShow(false);
                    }}
                    checked={role === 'corretora'}
                  />
                </div>
              </div>
              <div
                style={{
                  marginInline: '5%',
                  flexDirection: 'column',
                }}
              >
                <label htmlFor="cb1" className="role-text">
                  Seguradora
                </label>
                <div style={{ display: 'flex', flex: '1', justifyContent: 'center' }}>
                  <RadioButton
                    inputId="cb1"
                    name="tipoLogin"
                    value="seguradora"
                    onChange={(e) => {
                      setRole(e.value);
                      setNotShow(false);
                    }}
                    checked={role === 'seguradora'}
                  />
                </div>
              </div>
            </div>
            {notShow === true ? (
              <></>
            ) : (
              <>
                <label htmlFor="codigo" className="codigo-text">
                  Código:
                </label>
                <div>
                  <InputText
                    style={{
                      background: '#FFFFFF0F',
                      color: '#fff',
                      marginLeft: '25%',
                    }}
                    className="codigo-box"
                    id="codigo-login"
                    type="number"
                    disabled={role !== 'admin' ? false : true}
                    value={codigo === null ? '' : codigo}
                    onChange={(e) => setCodigo(e.currentTarget.valueAsNumber)}
                  />
                </div>
              </>
            )}

            <label htmlFor="user" className="user-text">
              Usuário:
            </label>
            <div>
              <InputText
                style={{
                  background: '#FFFFFF0F',
                  color: '#fff',
                  marginLeft: '25%',
                }}
                className="user-box"
                id="login"
                value={login}
                keyfilter={/^[^@!]+$/}
                onChange={(e) => setLogin(noArroba(e.currentTarget.value))}
              />
            </div>

            <label htmlFor="password" className="password-text">
              Senha:
            </label>
            <div>
              <Password
                style={{
                  background: '#FFFFFF0F',
                  color: '#fff',
                  marginLeft: '25%',
                }}
                className="password-box"
                id="password"
                value={senha}
                feedback={false}
                onChange={(e) => setSenha(e.currentTarget.value)}
              />
            </div>

            {/* <div className="forgotten">
              <Link to="/forgotten" style={{ color: '#fff' }}>
                Esqueci minha senha
              </Link>
            </div> */}

            <Button
              type="submit"
              style={{
                background: '#FFC800',
                color: '#0B141F',
                fontWeight: 'bold',
                marginTop: '1.6rem',
                marginLeft: '30%',
              }}
              label="Entrar"
              className="login-button"
              onClick={() => handleLogin()}
            />
          </div>
        )}
      </div>

      <div
        style={{ justifyContent: 'space-between' }}
        className="col-12 col-lg-6 screen-right-container d-flex flex-column align-items-center "
      >
        <div className="tracking-system-container m-0">
          <h1>Sistema de Gestão de GR e Logística</h1>
        </div>
        <div className="info-subtitle">
          <p>
            O sistema GoldenLog possui diversas ferramentas que visam trazer maior segurança,
            visibilidade e otimização operacional com recursos como: controle de velocidade,
            temperatura, fadiga do motorista, transit-time, entregas, dentre outros!
          </p>
        </div>

        <div className="advantages-container">
          <a className="link-banner" href="http://www.gservice.com.br/" target="_blank">
            <img src={Banner} className="logo-image-banner" alt="Banner" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default LoginParceiros;
