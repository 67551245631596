/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import api from '../../config/axiosMaquina';

// import { isAllowedByRole, isAuthenticated, Role } from '../../services/authService';
import {
  isAllowedByRole,
  isAuthenticated,
  Role,
  isAllowedByPermission,
  getCookieSessionData,
} from '../../services/cookieService';

type Props = {
  children?: React.ReactNode;
  path: string;
  exact?: true;
  component?: any;
  allowedRoutes: Role[];
  permission?: any;
  servicoContratado?: string;
};

const PrivateRoute = ({ children, path, allowedRoutes, permission, servicoContratado }: Props) => {
  const [servicosContratados, setServicosContratados] = useState();
  const { clienteId } = getCookieSessionData().usuarioVO || {};

  const getServicosContratadosCliente = async (clienteId: number) => {
    try {
      if (!isAllowedByRole(['admin'])) {
        const response = await api.get(
          `/cliente/getServicosContratadosCliente?cliente=${clienteId}`
        );
        setServicosContratados(response.data);
      }
    } catch (e) {
      console.error({
        e,
      });
    }
  };

  useEffect(() => {
    getServicosContratadosCliente(clienteId);
  }, [clienteId]);

  return (
    <Route
      path={path}
      render={({ location }) => {
        if (!isAuthenticated()) {



          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          );
        }
        if (isAuthenticated() && !isAllowedByRole(allowedRoutes)) {
          return <Redirect to={{ pathname: '/home' }} />;
        }


        if (permission && isAllowedByPermission(permission, 'consultar') === false) {
          return <Redirect to={{ pathname: '/acessonegado' }} />;
        }

        if (
          servicoContratado &&
          servicosContratados &&
          servicosContratados[servicoContratado] !== 1
        ) {
          return <Redirect to={{ pathname: '/erroservico' }} />;
        }
        return children;
      }}
    />
  );
};

export default PrivateRoute;
