/* eslint-disable */
import axios from '../../config/axiosMaquina';
import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { ListagemCliente } from '../../api/Types/listagemResumoTypes';
import api from '../../config/axiosMaquina';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import './styles.css';
import { obterDataHora, toZoned } from '../../util/date';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { orderBy } from 'lodash';
import { GerenteType } from '../../Types/Gerente';
import { IDataPesquisaTratada, IModelPesquisaTratada } from '../../Types/pesquisaTratada';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import { ExcelGeneration } from '../ListagemSMLancadas/ListagemSMLancadas';
import { FormataStringData2 } from '../../util/Validacoes/Validacoes';
import * as ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { StatusDminer } from '../../components/StatusDminer';
import { getUltimaRespostaDminer } from '../../api/dminerUltimaRespostaAPI';

const ConsultarPesquisasTratadas = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const [listaClientes, setListaClientes] = useState<ListagemCliente[]>([]);
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [model, setModel] = useState<IModelPesquisaTratada>({
    dataInicio: '',
    dataFim: '',
    empresa: 0,
    usuario: 0,
    autorizadoPor: 0,
  });
  const [loading, setLoading] = useState(false);
  const [empresaInvalid, setEmpresaInvalid] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [listaGerente, setListaGerente] = useState<GerenteType[]>([]);
  const [listaUsuario, setListaUsuario] = useState<GerenteType[]>([]);
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>([]);

  const defaultModel = useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.CONSULTA_RELATORIO.ANALISE_PERFIL] }], 'editar');
    rolePermission([{ ADMIN: [ADMIN.CONSULTA_RELATORIO.ANALISE_PERFIL] }], 'excluir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const loadDataRazaoSocial = () => {
      axios.get('/cliente/getIdRazaoSocial').then((response: any) => {
        setListaClientes(response.data);
      });
    };

    const loadDataUsuarios = async () => {
      const response = await axios.get(`/usuario/gerenciador`);
      const array = response.data;
      setListaUsuario(array);
      console.log('---', array);
    };

    const loadDataGerentes = async () => {
      const response = await api.get('/gerente/listarGerentes');
      setListaGerente(orderBy(response.data, 'nome'));
    };

    loadDataRazaoSocial();
    loadDataGerentes();
    loadDataUsuarios();
  }, []);

  const submit = async (e: any) => {
    e.preventDefault();

    try {
      const filteredModel = Object.fromEntries(
        Object.entries(model).filter(([_, value]) => value !== 0)
      );
      const response = await api.get<IDataPesquisaTratada[]>('/pesquisa/detalhadas', {
        params: filteredModel,
      });

      console.log('API Response:', response); // Log the full response to check its structure

      // Check if the response data is an array
      const searchData = response.data; // Ensure you're accessing the right part of the response
      if (Array.isArray(searchData)) {
        XlsxGeneration(searchData); // Pass the array directly to XlsxGeneration
      } else {
        console.error('Response data is not an array:', searchData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const XlsxGeneration = async (search: any) => {
    console.log(search);

    const workbook = new ExcelJS.Workbook();

    searchTable(search, workbook);

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      FileSaver.saveAs(blob, 'pesquisas_tratadas.xlsx');
    });
  };

  const searchTable = (data: IDataPesquisaTratada[], workbook: any) => {
    const worksheet = workbook.addWorksheet('PESQUISAS_TRATADAS');
    console.log(data);

    worksheet.columns = [
      { header: 'Data/Hora', key: 'dataAtualizacao', width: 30 },
      { header: 'Empresa', key: 'empresa', width: 30 },
      { header: 'Número', key: 'numero', width: 30 },
      { header: 'Documento', key: 'documento', width: 30 },
      { header: 'Tipo', key: 'tipo', width: 30 },
      { header: 'Situação', key: 'situacao', width: 30 },
      { header: 'Motivo', key: 'motivo', width: 30 },
      { header: 'Contato Jurídico', key: 'contatoJuridico', width: 30 },
      { header: 'Autorização', key: 'autorizacao', width: 30 },
      { header: 'Usuário', key: 'usuario', width: 30 },
    ];

    worksheet.eachRow({ includeEmpty: true }, (row: any) => {
      row.height = 25;
    });

    data.forEach((pesquisa: IDataPesquisaTratada) => {
      worksheet.addRow({
        dataAtualizacao: pesquisa.dataAtualizacao
          ? obterDataHora(pesquisa.dataAtualizacao, true)
          : '',
        empresa: pesquisa.empresa || '',
        numero: pesquisa.numero || '',
        documento: pesquisa.documento || '',
        tipo: pesquisa.tipo || '',
        situacao: pesquisa.situacao || '',
        motivo: pesquisa.motivo || '',
        contatoJuridico: pesquisa.contatoJuridico ? 'SIM' : 'NÃO',
        autorizacao: pesquisa.autorizacao || '',
        usuario: pesquisa.usuario || '',
      });
    });
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Consultar Pesquisas Tratadas</h2>
          <h6 className="subtitulo">{'Consultas/Relatórios > Pesquisas Tratadas'}</h6>
        </div>

        <Form noValidate validated={isFormInvalid} onSubmit={submit}>
          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>
                <span className="txt-obrigatorio">*</span>
                Data Início
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  e.target.value.length > 10 ? null : setDataInicio(e.target.value);
                  if (e.target.value !== '') {
                    setModel({
                      ...model,
                      dataInicio: toZoned(e.target.value),
                    });
                  }
                }}
                value={dataInicio}
                type="date"
                id="date"
                name="date"
                placeholder=""
              />
              <Form.Control.Feedback type="invalid">
                Campo Data Início não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="row-modal">
              <Form.Label>
                <span className="txt-obrigatorio">*</span>
                Data Fim
              </Form.Label>
              <Form.Control
                onChange={(e) => {
                  e.target.value.length > 10 ? null : setDataFim(e.target.value);
                  if (e.target.value !== '') {
                    setModel({
                      ...model,
                      dataFim: toZoned(e.target.value, false),
                    });
                  }
                }}
                value={dataFim}
                type="date"
                id="dataFim"
                name="dataFim"
                placeholder=""
              />
              <Form.Control.Feedback type="invalid">
                Campo Data Fim não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row className="row-container-modal">
            <Form.Group className="row-modal">
              <Form.Label>Empresas</Form.Label>

              <Dropdown
                style={{ width: '100%', borderColor: empresaInvalid ? 'red' : '#c9c9c9' }}
                value={model.empresa}
                options={listaClientes}
                placeholder="Selecione"
                onChange={(e: any) => {
                  setModel({
                    ...model,
                    empresa: e.target.value,
                  });
                }}
                optionLabel="razaoSocial"
                optionValue="id"
                filter
                showClear
                className="multiselect-custom"
                name="empresa"
                id="empresa"
              />
            </Form.Group>
            <Form.Group className="row-modal">
              <Form.Label>Usuário</Form.Label>

              <Dropdown
                style={{ width: '100%', borderColor: empresaInvalid ? 'red' : '#c9c9c9' }}
                value={model.usuario}
                options={listaUsuario}
                placeholder="Selecione"
                onChange={(e: any) => {
                  setModel({
                    ...model,
                    usuario: e.target.value,
                  });
                }}
                optionLabel="nome"
                optionValue="usuCdId"
                filter
                showClear
                className="multiselect-custom"
                name="empresa"
                id="empresa"
              />
            </Form.Group>
          </Form.Row>

          <Form.Group className="row-modal">
            <Form.Label>Autorizado por:</Form.Label>

            <Dropdown
              style={{ width: '100%', borderColor: empresaInvalid ? 'red' : '#c9c9c9' }}
              value={model.autorizadoPor}
              options={listaGerente}
              placeholder="Selecione"
              onChange={(e: any) => {
                setModel({
                  ...model,
                  autorizadoPor: e.target.value,
                });
              }}
              optionLabel="nome"
              optionValue="id"
              filter
              showClear
              className="multiselect-custom"
              name="empresa"
              id="empresa"
            />
          </Form.Group>

          <ButtonsForm confirmButton="Gerar XLS" />
        </Form>
      </div>
    </div>
  );
};

export default ConsultarPesquisasTratadas;
