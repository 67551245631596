/* eslint-disable */

import { Dropdown } from 'primereact/dropdown';
import { useState, ChangeEvent, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Form, Col, Button, Spinner } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import api from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import {
  cpfMask,
  maskCep,
  soLetras,
  soNumeros,
  semCaracterEspecial,
} from '../../util/Validacoes/Validacoes';
import { getFullDate } from '../../util/format';
import { TIPOS_CONDUTOR_AJUDANTE } from '../../constants/Condutor';
import validateFields, { validaDataNascimentoField } from './ajudanteValidation';
import { useToast } from '../../hooks/Toast';
import {
  dataMaxParaMaioridadeFormatada,
  dataMinParaDataNascimentoFormatada,
  nowToZoned,
  toZoned,
} from '../../util/date';
import Input from '../Input';
import Select from '../Select';
import { ErrosDataPesquisa } from '../../pages/EnviarPesquisa';

type Funcionario = {
  atualizarPesquisa: boolean;
  bairro: string;
  bloqueado: boolean;
  cep: string;
  cidade: number;
  cpf: string;
  dataNascimento: string;
  id: number;
  logradouro: string;
  logradouroComplemento: string;
  logradouroNumero: number;
  nome: string;
  nomeCidade: string;
  nomeMae: string;
  nomePai: string;
  nomeUfEmissor: string;
  nomeUfEndereco: string;
  rg: string;
  sexo: string;
  tipo: string;
  idUfEmissor: number;
  idUfEndereco: number;
  idUfNascimento: number;
};

type Ufs = {
  id: number;
  sigla: string;
};

type FuncionarioNome = {
  id: number;
  nome: string;
};

type InputError = {
  funcionario: string;
  nome: string;
  tipo: string;
  dataNascimento: string;
  rg: string;
  idUfEmissorRg: string;
  cep: string;
  logradouro: string;
  logradouroNumero: string;
  logradouroComplemento: string;
  bairro: string;
  cidade: string;
  uf: string;
  nomePai: string;
  nomeMae: string;
};

type Municipios = {
  id: number;
  nomeMunicipio: string;
};

export const ContainerPesquisarAjudante = forwardRef((props?: any, ref?: any) => {
  const [loading, setLoading] = useState(false)
  const [inProgress, setInProgress] = useState(false)
  const [funcionario, setFuncionario] = useState<Funcionario | undefined>();
  const [listaFuncionarios, setlistaFuncionarios] = useState<FuncionarioNome[]>([]);
  const [listaUfs, setListaUfs] = useState<Ufs[]>([]);
  const [inputErrors, setInputErrors] = useState({} as InputError);
  const { addToast } = useToast();
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [listaMunicipios, setListaMunicipios] = useState<Municipios[]>([]);
  const [dialogConsultaOuPesquisa, setDialogConsultaOuPesquisa] = useState(false);
  const [itensVigentes, setItensVigentes] = useState<string[]>([]);
  const [viaCepUf, setViaCepUf] = useState('');
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [cepMudou, setCepMudou] = useState(false);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [isDialogCadErro, setIsDialogCadErro] = useState(false);
  const [tipoPesquisa, setTipoPesquisa] = useState('');
  const [idPesquisaEnviada, setIdPesquisaEnviada] = useState();
  const [itensPesquisasEnviadas, setItensPesquisasEnviadas] = useState<string[]>([]);

  const [dataErros, setDataErros] = useState<ErrosDataPesquisa | undefined>({
    mensagemErro: '',
    listaErros: [],
  });

  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;

  const tipoAjudante = TIPOS_CONDUTOR_AJUDANTE.find(
    (each) => each.id?.toString() === funcionario?.tipo
  )?.name;

  const formattedData = {
    nomeMae: funcionario?.nomeMae,
    nomePai: funcionario?.nomePai,
    nome: funcionario?.nome,
    logradouro: funcionario?.logradouro,
    dataNascimento: funcionario?.dataNascimento,
    cpf: funcionario?.cpf,
    sexo: funcionario?.sexo,
    bloqueado: funcionario?.bloqueado,
    cidade: funcionario?.nomeCidade,
    cep: funcionario?.cep,
    bairro: funcionario?.bairro,
    cidadeId: funcionario?.cidade,
    atualizarAutomaticamente: funcionario?.atualizarPesquisa,
    idAjudante: funcionario?.id,
    tipo: tipoAjudante,
    numRG: funcionario?.rg,
    siglaUfEmissorRG: funcionario?.nomeUfEmissor,
    numeroEndereco: funcionario?.logradouroNumero,
    complemento: funcionario?.logradouroComplemento,
    siglaUFEndereco: funcionario?.nomeUfEndereco,
    ufEnderecoId: funcionario?.idUfEndereco,
    idUfEmissor: funcionario?.idUfEmissor,
  };

  useImperativeHandle(
    ref,
    () => ({
      validation() {
        if (!funcionario) {
          setInputErrors({ ...inputErrors, funcionario: 'Selecione um ajudante/funcionário' });
          return false;
        }
        setInputErrors({ ...inputErrors, funcionario: '' });
        const isValid = validateFields({ funcionario, setInputErrors });

        if (!isValid || inProgress) {
          return false;
        }

        return true;
      },
      ajudante: formattedData,
      reset() {
        setFuncionario(undefined)
        setInputErrors({} as InputError)
      }
    }),
    [addToast, funcionario, inputErrors, inProgress]
  );

  const toggleSwitchAtualizacaoAutomatica = () => {
    if (!funcionario) {
      return;
    }
    setFuncionario({
      ...funcionario,
      atualizarPesquisa: !funcionario.atualizarPesquisa,
    });
  };

  const loadDadosMunicipioPorUf = async (idUf: number) => {
    if (!idUf) {
      return;
    }
    try {
      const response = await api.get(`/municipio-ibge/listarPorUf/${idUf}`);
      setListaMunicipios(response.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    axios
      .all([
        api.get(`/ajudante-funcionario/listarAjudanteNome?cliente=${clienteId}`),
        api.get('/uf/ListarIdSiglaEstadoPais/1'),
      ])
      .then(
        axios.spread((funcionariosResponse, ufSiglasResponse) => {
          setlistaFuncionarios(funcionariosResponse.data);
          setListaUfs(ufSiglasResponse.data);
        })
      )
      .catch((err) => {
        console.log(err);
      });
  }, [clienteId]);

  function updateModel(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
    if (!funcionario) {
      return;
    }

    let { value } = e.target;

    switch (e.target.name) {
      case 'nome':
        value = soLetras(e.target.value);
        break;
      case 'logradouroNumero':
        value = soNumeros(e.target.value);
        break;
      default:
        value = e.target.value;
        break;
    }

    setFuncionario({
      ...funcionario,
      [e.target.name]: e.target.value,
    });
  }

  const loadDadosFuncionario = async (idFuncionario: number) => {


    if (!idFuncionario) {
      history.replace(`/enviar-pesquisa/ajudante`);
      setFuncionario(undefined);
      return;
    }

    setDialogHelperData(true)

    try {
      const response = await api.get(`/ajudante-funcionario/${idFuncionario}`);
      history.replace(`/enviar-pesquisa/ajudante/${idFuncionario}`);

      setFuncionario({
        ...response.data,
        dataNascimento: response.data.dataNascimento ? getFullDate(response.data.dataNascimento) : null,
        atualizarPesquisaAutomaticamente:
          response.data.atualizarPesquisaAutomaticamente === null
            ? false
            : response.data.atualizarPesquisaAutomaticamente,
      });

      if (response.data.idUfEndereco) {
        loadDadosMunicipioPorUf(response.data.idUfEndereco);
      }

      const checkValidity = await api.get('/pesquisa/verificar-vigencia', {
        params: {
          dataInicio: nowToZoned(),
          idCliente: clienteId,
          idAjudante: idFuncionario,
        },
      });

      if (checkValidity.data === "Pesquisa em andamento") {
        setInProgress(true)
      }

      if (checkValidity.data === 'Nao pode enviar') {
        const vigentes = [];

        vigentes.push(
          response.data?.nome != undefined ? `${response.data?.nome} ${response.data?.cpf}` : ''
        );

        setItensVigentes(vigentes);

        setDialogConsultaOuPesquisa(true);
      }
    } catch (err: any) {
      console.log(err);
    } finally  {
      setDialogHelperData(false)
    }
  };

  useEffect(() => {
    if (!funcionario) {
      setInputErrors({} as InputError);
    }
  }, [funcionario]);

  const buscarCep = (value: string) => {
    if (cepMudou) {
      setError(false);
      fetch(`https://viacep.com.br/ws/${value}/json`)
        .then((res) => res.json())
        .then((data) => {
          if (data.erro) {
            setError(true);
            return;
          }

          setViaCepUf(data.uf);
          setViaCepCidade(data.localidade);
          const codIbgeFloat = data.ibge / 100000;
          const codIbgeFormatado = Math.trunc(codIbgeFloat);
          setViaCepIbgeCod(codIbgeFormatado);
          if (funcionario) {
            setFuncionario({
              ...funcionario,
              cep: data.cep,
              logradouro: data.logradouro,
              bairro: data.bairro,
              cidade: data.ibge,
              idUfEndereco: codIbgeFormatado,
              nomeCidade: data.localidade,
            });
          }
        })

        .catch((erro) => {
          setError(true);
        });
    }
  };

  const handleSubmit = async (event: any, novaPesquisa?: boolean, tipoPesquisa = 'Pesquisa') => {
    event.preventDefault();

    const itensEnviados = [];

    itensEnviados.push(
      funcionario?.nome != undefined ? `${funcionario?.nome} ${funcionario?.cpf}` : ''
    );

    setItensPesquisasEnviadas(itensEnviados);

    const tipoAjudante = TIPOS_CONDUTOR_AJUDANTE.find(
      (each) => each.id?.toString() === funcionario?.tipo
    )?.name;

    const formattedData = {
      nomeMae: funcionario?.nomeMae,
      nomePai: funcionario?.nomePai,
      nome: funcionario?.nome,
      logradouro: funcionario?.logradouro,
      dataNascimento: funcionario?.dataNascimento,
      cpf: funcionario?.cpf,
      sexo: funcionario?.sexo,
      bloqueado: funcionario?.bloqueado,
      cidade: funcionario?.nomeCidade,
      cep: funcionario?.cep,
      bairro: funcionario?.bairro,
      cidadeId: funcionario?.cidade,
      atualizarAutomaticamente: funcionario?.atualizarPesquisa,
      idAjudante: funcionario?.id,
      tipo: tipoAjudante,
      numRG: funcionario?.rg,
      siglaUfEmissorRG: funcionario?.nomeUfEmissor,
      numeroEndereco: funcionario?.logradouroNumero,
      complemento: funcionario?.logradouroComplemento,
      siglaUFEndereco: funcionario?.nomeUfEndereco,
      ufEnderecoId: funcionario?.idUfEndereco,
      idUfEmissor: funcionario?.idUfEmissor,
    };

    handleRequest(
      {
        ajudante: formattedData,
        idCliente: clienteId,
        idUsuario: usuarioId,
        cadastrarPesquisaNova: novaPesquisa || false,
      },
      'este ajudante',
      tipoPesquisa
    );
  };



  const handleRequest = async (data: any, message: string, tipoPesquisa = 'Pesquisa') => {
    setLoading(true);

    try {
      const response = await api.post('/pesquisa/integrada', {
        ...data,
        pesquisaManual: false,
        tipoPesquisa,
      });

      if (response.data.statusCode !== 200) {
        setLoading(false);
        setIsDialogCadErro(true);

        setDataErros({
          mensagemErro: response.data.message,
          listaErros: response.data.dminerErrors,
        });
      } else {
        if (
          response.data.mensagem?.includes('Consulta') ||
          response.data.mensagemAjudante?.includes('Consulta') ||
          response.data.mensagemCondutor?.includes('Consulta') ||
          response.data.mensagemVeiculo?.includes('Consulta') ||
          response.data.mensagemCarreta?.includes('Consulta') ||
          response.data.mensagemCarreta2?.includes('Consulta')
        ) {
          setTipoPesquisa('Consulta');
        } else {
          setTipoPesquisa('Pesquisa');
        }

        setIdPesquisaEnviada(response.data.solicitationCode);
        setIsDialogCadOK(true);
      }
    } catch (error: any) {
      if (
        error.response.data.message?.includes('processamento') ||
        error.response.data.message?.includes('Constam resultados')
      ) {
        setIsDialogCadErro(true);
        setDataErros({
          mensagemErro: `já existe uma pesquisa em andamento para ${message}`,
          listaErros: null as any,
        });
      } else {
        const message = error.response.data.message?.split('[')[2].split(']')[0];
        const msg = message.split(',')[1].split('=')[1];

        addToast({
          title: 'Erro!',
          description: error.response.data.message.includes('DminerErroPesquisaVO')
            ? `Atenção: "${msg}"`
            : error.response.data.message,
          type: 'error',
        });
      }
    } finally {
      setFuncionario(undefined);

      history.replace('/enviar-pesquisa/ajudante');
      setDialogConsultaOuPesquisa(false);
      setLoading(false);
      return;
    }
  };

  const [dialogHelperData, setDialogHelperData] = useState(false)

  return (
    <>
     <Dialog
        header={
          <>
          <p>Aguarde enquanto buscamos os dados...</p>
            <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
          </>
        }
        closable={false}

        visible={dialogHelperData}
        modal
        onHide={() => {
          setDialogHelperData(false);
        }}
      />


      <Dialog
        header={
          <>
            <p>{`Não foi possível enviar pesquisa. `}</p>
            {dataErros?.listaErros ? (
              <>
                {dataErros.listaErros.map((each) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        closable={false}
        footer={
          <>
            <Button
              onClick={() => {
                setIsDialogCadErro(false);
              }}
            >
              OK
            </Button>
          </>
        }
        visible={isDialogCadErro}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
        }}
      />

      <Dialog
        header={
          <>
            <p>{tipoPesquisa} enviada com sucesso para:</p>
            {itensPesquisasEnviadas ? (
              <>
                {itensPesquisasEnviadas.map((each: string) => (
                  <li style={{ fontSize: '15px' }}>{each}</li>
                ))}
              </>
            ) : null}
          </>
        }
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p>Número: {idPesquisaEnviada}</p>
            <Button
            type="button"
              onClick={() => {
                setIsDialogCadOK(false);
                history.go(0)
              }}
            >
              OK
            </Button>
          </div>
        }
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadOK(false);
          history.go(0)
        }}
      />

      <Dialog
        header={
          <div>
            <p>Já existe pesquisa vigente para:</p>
            <br />
            {itensVigentes.map((each: string) => (
              <li>{each}</li>
            ))}
            <br />
          </div>
        }
        closable={false}
        footer={
          <>
            {props.habilitarPesquisaVigente ? (
              <>
              {loading ? (
                <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
              ): (
                <>
                <p>O que deseja fazer?</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                <Button
                style={{ color: 'black', fontSize: '12px' }}
                onClick={(e: any) => {
                handleSubmit(e, true, 'Pesquisa');

                }}
                >
                Nova Pesquisa
                </Button>
                <Button
                style={{ color: 'black', fontSize: '12px' }}
                onClick={(e: any) => {
                handleSubmit(e, false, 'Consulta');
                }}
                >
                Consulta
                </Button>
                <Button
                style={{
                background: 'white',
                border: '1px solid black',
                color: 'black',
                fontSize: '12px',
                }}
                onClick={() => {
                setDialogConsultaOuPesquisa(false);
                }}
                >
                Cancelar
                </Button>

                </div>
                </>
              )}

              </>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  style={{
                    background: 'white',
                    border: '1px solid black',
                    color: 'black',
                    fontSize: '11px',
                  }}
                  onClick={() => {
                    setDialogConsultaOuPesquisa(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            )}
          </>
        }
        visible={dialogConsultaOuPesquisa}
        style={{ width: '40vw' }}
        modal
        onHide={() => {
          setDialogConsultaOuPesquisa(false);
        }}
      />

<Form>
          <h2 className="titulo">Dados da Pesquisa Ajudante/Funcionário</h2>
          <Form.Row>
            <Form.Group as={Col} sm={12}>
              <div className="containerLimpar">
                <Form.Label>Ajudante/Funcionário:</Form.Label>
                <p
                  className="limparButton"
                  onClick={() => {
                    history.replace(`/enviar-pesquisa/ajudante`);
                    setFuncionario(undefined);
                  }}
                >
                  Limpar
                </p>
              </div>
              <Dropdown
                value={funcionario ? funcionario.id : ''}
                options={listaFuncionarios}
                onChange={(e) => {
                  setInProgress(false)
                  loadDadosFuncionario(parseInt(e.target.value, 10));
                }}
                optionLabel="nome"
                optionValue="id"
                onBlur={() => validateFields({ funcionario, setInputErrors })}
                filter
                filterBy="nome"
                placeholder="Selecione"
                className="p-dropdown"
                showClear
                style={{
                  width: '100%',
                  borderColor: inputErrors.funcionario || inProgress ? '#ff0000' : '#ced4da',
                }}
              />

              <p style={{ fontSize: '14px', color: '#ff0000' }}>{inProgress ? 'Ajudante/Funcionário possui pesquisa em andamento' : ''}</p>


            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Input
              disabled
              required
              value={funcionario ? cpfMask(funcionario.cpf) : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateModel(e);
              }}
              id="cpf"
              name="cpf"
              textInputTitle="CPF:"
            />
            <Input
              disabled={!funcionario}
              required
              value={funcionario ? funcionario.nome : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (funcionario) {
                  setFuncionario({
                    ...funcionario,
                    nome: soLetras(semCaracterEspecial(e.target.value)),
                  });
                }
              }}
              id="nome-funcionario"
              name="nome"
              textInputTitle="Nome:"
              error={inputErrors.nome}
              maxLength={256}
            />
            <Select
              disabled={!funcionario}
              name="tipo"
              required
              value={funcionario ? funcionario.tipo : ''}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                updateModel(e);
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              textInputTitle="Tipo:"
              error={inputErrors.tipo}
            >
              <option value="0">Selecione</option>
              {TIPOS_CONDUTOR_AJUDANTE.map((t) => (
                <option value={t.id} key={t.id}>
                  {t.displayName}
                </option>
              ))}
            </Select>
          </Form.Row>
          <Form.Row>
            <Input
              value={funcionario ? funcionario.dataNascimento : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateModel(e);
              }}
              onBlur={() => validaDataNascimentoField({ funcionario, setInputErrors, inputErrors })}
              id="dataNascimento"
              name="dataNascimento"
              type="date"
              max={dataMaxParaMaioridadeFormatada()}
              min={dataMinParaDataNascimentoFormatada()}
              required
              disabled={!funcionario}
              textInputTitle="Nascimento:"
              error={inputErrors.dataNascimento}
              maxLength={10}
            />
            <Input
              disabled={!funcionario}
              required
              value={funcionario ? funcionario.rg : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateModel(e);
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              id="rg"
              name="rg"
              textInputTitle="RG nº:"
              error={inputErrors.rg}
              maxLength={15}
            />
            <Select
              disabled={!funcionario}
              required
              value={funcionario ? funcionario.idUfEmissor : ''}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                updateModel(e);
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              textInputTitle="UF Emissor:"
              error={inputErrors.idUfEmissorRg}
              name="idUfEmissor"
              id="idUfEmissor"
            >
              <option value="0">Selecione</option>
              {listaUfs.map((i) => (
                <option value={i.id} key={i.id}>
                  {i.sigla}
                </option>
              ))}
            </Select>
          </Form.Row>

          <Form.Row>
            <Input
              disabled={!funcionario}
              required
              value={funcionario && funcionario.cep ? maskCep(funcionario.cep) : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateModel(e);
                setCepMudou(true);
                if (e.target.value.length === 9) {
                  buscarCep(e.target.value);
                }
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              id="cep-funcionario"
              name="cep"
              textInputTitle="CEP"
              error={inputErrors.cep}
              maxLength={9}
            />

            <Input
              disabled={!funcionario}
              required
              value={funcionario ? funcionario.logradouro : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateModel(e);
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              id="logradouro-funcionario"
              name="logradouro"
              textInputTitle="Logradouro:"
              error={inputErrors.logradouro}
              maxLength={56}
            />
            <Input
              disabled={!funcionario}
              required
              type="number"
              value={funcionario ? funcionario.logradouroNumero : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (e.target.value.length <= 4) {
                  updateModel(e);
                }
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              id="logradouroNumero"
              name="logradouroNumero"
              textInputTitle="Número:"
              error={inputErrors.logradouroNumero}
              maxLength={8}
            />
          </Form.Row>
          <Form.Row>
            <Input
              disabled={!funcionario}
              value={funcionario ? funcionario.logradouroComplemento : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateModel(e);
              }}
              id="logradouroComplemento"
              name="logradouroComplemento"
              textInputTitle="Complemento:"
              error={inputErrors.logradouroComplemento}
              maxLength={56}
            />
            <Input
              disabled={!funcionario}
              required
              value={funcionario ? funcionario.bairro : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                updateModel(e);
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              id="bairro"
              name="bairro"
              textInputTitle="Bairro:"
              error={inputErrors.bairro}
              maxLength={56}
            />
            <Select
              name="cidade"
              disabled={!funcionario}
              required
              value={funcionario ? funcionario.cidade : ''}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                updateModel(e);
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              textInputTitle="Cidade:"
              error={inputErrors.cidade}
            >
              <option value="0">Selecione</option>
              {listaMunicipios.map((i) => (
                <option value={i.id} key={i.id}>
                  {i.nomeMunicipio}
                </option>
              ))}
              {viaCepCidade && <option value={funcionario?.cidade}>{viaCepCidade}</option>}
            </Select>

            <Select
              disabled={!funcionario}
              name="idUfEndereco"
              id="idUfEndereco"
              required
              value={funcionario ? funcionario.idUfEndereco : ''}
              onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                updateModel(e);
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              textInputTitle="UF:"
              error={inputErrors.uf}
            >
              <option value="0">Selecione</option>
              {listaUfs.map((i) => (
                <option value={i.id} key={i.id}>
                  {i.sigla}
                </option>
              ))}
            </Select>
          </Form.Row>
          <Form.Row>
            <Input
              disabled={!funcionario}
              required
              value={funcionario ? funcionario.nomePai : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (funcionario) {
                  setFuncionario({
                    ...funcionario,
                    nomePai: soLetras(semCaracterEspecial(e.target.value)),
                  });
                }
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              id="nomePai"
              name="nomePai"
              textInputTitle="Nome Pai:"
              error={inputErrors.nomePai}
              maxLength={256}
            />
            <Input
              disabled={!funcionario}
              required
              value={funcionario ? funcionario.nomeMae : ''}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                if (funcionario) {
                  setFuncionario({
                    ...funcionario,
                    nomeMae: soLetras(semCaracterEspecial(e.target.value)),
                  });
                }
              }}
              onBlur={() => validateFields({ funcionario, setInputErrors })}
              id="nomeMae"
              name="nomeMae"
              textInputTitle="Nome Mãe:"
              error={inputErrors.nomeMae}
              maxLength={256}
            />
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="formBasicCheckbox">
              <Switch
                disabled={!funcionario}
                value={funcionario?.atualizarPesquisa ? 1 : 0}
                checked={funcionario?.atualizarPesquisa}
                onChange={toggleSwitchAtualizacaoAutomatica}
                name="atualizacaoAutomatica"
                id="pesquisa"
                type="switch"
                label="Atualizar pesquisa automaticamente"
              />
            </Form.Group>
          </Form.Row>
        </Form>
    </>
  );
});
