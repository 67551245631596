/* eslint-disable */
import { useHistory } from 'react-router';

export const itemsSeguradora = () => {
  const useNavigation = (path: string) => {
    const history = useHistory();
    return () => {
      history.push(path);
    };
  };

  const itemsSeguradora = [
    {
      label: 'Home',
      command: useNavigation('/home'),
    },

    {
      label: 'Consultar SM Lançadas',
      command: useNavigation('/consultar-sm-lancadas'),
    },
    {
      label: 'Relatório de Não Conformidades',
      command: useNavigation('/nao-conformidade/consulta'),
    },
    {
      label: 'Usuarios',
      command: useNavigation('/listar-usuarios-seguradoras/1'),
    },
    {
      label: 'Fazer Logout',
      icon: 'pi pi-fw pi-user-minus',
      command: useNavigation('/logout'),
    },
  ];

  return itemsSeguradora;
};
