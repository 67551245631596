/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Dialog } from 'primereact/dialog';
import { useToast } from '../../hooks/Toast';
import axios from '../../config/axiosMaquina';
import { useHistory, useParams } from 'react-router-dom';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import Pagination from '../../components/Pagination/Pagination';
import { Form, Col, Button, Row, Modal, Spinner } from 'react-bootstrap';
import { getCookieSessionData } from '../../services/cookieService';
import { FaturamentoClienteType } from '../../api/model/FaturamentoGerenciadorType';
import { dataConverter, obterData, obterDataHora } from '../../util/date';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { PERMISSION } from '../../constants/Permissionamento';

export const FaturamentoFrotaPage = () => {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canInsert } = useRolePermission();

  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const { addToast } = useToast();
  const [listaFaturamento, setListaFaturamento] = useState<FaturamentoClienteType[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);

  const [veiculo, setVeiculo] = useState<any>();
  const [faturarVeiculoComo, setFaturarVeiculoComo] = useState('');
  const [motivo, setMotivo] = useState('');
  const [placa, setPlaca] = useState('');
  const [modelo, setModelo] = useState('');
  const [nomeSolicitante, setNomeSolicitante] = useState('');
  const [dataSolicitacao, setDataSolicitacao] = useState('');
  const [qtdeDiasUltimaSolicitacao, setQtdeDiasUltimaSolicitacao] = useState();
  const history = useHistory();
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const [totalRegistros, setTotalRegistros] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [historicosPerPage, setHistoricosPerPage] = useState(10);
  const indexOfLastPost = currentPage * historicosPerPage;
  const indexOfFirstPost = indexOfLastPost - historicosPerPage;
  const currentFaturamento = searchTerm && searchResults?.slice(indexOfFirstPost, indexOfLastPost);
  const [isDialogVisibleMsgEmail, setIsDialogVisibleMsgEmail] = useState(false);
  const [isDialogCadEmail, setIsDialogCadEmail] = useState(false);

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const faturar = [
    'MENSALISTA',
    'VIAGEM AVULSA',
    'NÃO RASTREADO',
    // 'NÃO INFORMADO',
    'DESATIVADO',
  ];

  const handleClose = () => {
    setShow(false);
    setShow2(false);
    setFaturarVeiculoComo('');
    setMotivo('');
    setNomeSolicitante('');
    setModelo('');
    setPlaca('');
  };

  const handleShow = (idVeiculo: number, qtdeDiasUltimaSolicitacao: any, dataSolicitacao: any) => {
    setQtdeDiasUltimaSolicitacao(qtdeDiasUltimaSolicitacao);
    const dataIni = new Date(dataSolicitacao);
    const dataF = new Date();
    var timeDiff = Math.abs(dataF.getTime() - dataIni.getTime());
    var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

    if (qtdeDiasUltimaSolicitacao === null || diffDays >= 30) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`/veiculo/${idVeiculo}`);
          setLoading(false);
          setVeiculo(response.data);
          setPlaca(response.data.placa);
          setModelo(response.data.modelo);
        } catch (err: any) {
          console.log(err);
        }
      };
      fetchData();
      setShow(true);
    } else {
      setShow2(true);
      setDataSolicitacao(dataSolicitacao);
    }
  };

  const paginate = (pageNumber: number) => {
    history.push(`/cliente/faturamentofrota/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.FATURAMENTO_FROTA] }], 'editar');
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.FATURAMENTO_FROTA] }], 'inserir');
  }, [TRANSPORTADOR]);

  useEffect(() => {
    const pageInt = parseInt(page, 10);
    if (pageInt <= 0 || !pageInt) {
      history.goBack();
    }
  }, [page]);

  useEffect(() => {
    let isMounted = true; // Track whether the component is mounted

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/veiculo-faturamento/listarDadosGridVeiculoFaturamento`, {
          params: {
            idCliente: clienteId,
            pagina: page - 1,
            qtdRegistros: itensPerPage,
          },
        });

        if (isMounted) {
          // Only update state if the component is still mounted
          response.data = response.data.sort(
            (a: FaturamentoClienteType, b: FaturamentoClienteType) => {
              return b.idVeiculo - a.idVeiculo;
            }
          );
          setListaFaturamento(response.data);
          setLoading(false);
        }
      } catch (err: any) {
        if (isMounted) {
          console.log(err);
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false; // Set to false when the component unmounts
    };
  }, [page, itensPerPage, isDialogCadOK]);

  const [loadingFaturamento, setloadingFaturamento] = useState(false);

  const handleSalvarFaturamentoFrota = async (e: any) => {
    setloadingFaturamento(true);

    e.preventDefault();
    const data = {
      placa: placa,
      faturarComo: faturarVeiculoComo,
      motivo: motivo,
      nomeSolicitante: nomeSolicitante,
      idCliente: clienteId,
      idVeiculo: veiculo.id,
      dataCadastroSolicitacao: dataConverter(new Date().toISOString()),
      status: null,
      tecnologiaRastreamento: veiculo.tecnologiaRastreamento,
      tipoFaturamentoAtual: 'NAO INFORMADO',
      empresa: veiculo.empresa,
      idUsuarioLogado: usuarioId,
    };

    try {
      const response = await axios.post(`/veiculo-faturamento`, data);
      handleClose();
      setIsDialogCadOK(true);

      // if (response.data?.includes('email')) {
      //   setIsDialogCadEmail(true);
      // } else {
      //   setIsDialogCadOK(true);
      // }
    } catch (err: any) {
      console.log(err.response.data.message);
      if (
        err.response.data.message ===
        'Houve erro ao enviar o email de alteração de frota: Illegal address'
      ) {
        handleClose();
        setIsDialogCadEmail(true);
        return;
      }
      if (
        err.response.data.message ===
        'Não é permitida a inclusão de uma nova solicitação com menos de 30 dias que a anterior.'
      ) {
        handleClose();
        addToast({
          title: 'Erro',
          description:
            'Não é permitida a inclusão de uma nova solicitação com menos de 30 dias que a anterior.',
          type: 'error',
        });
        return;
      }

      addToast({
        title: 'Erro',
        description: 'Erro ao cadastrar!',
        type: 'error',
      });
      return;
    } finally {
      setloadingFaturamento(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/veiculo-faturamento/listarDadosGridVeiculoFaturamento/count/${clienteId}`
        );
        setTotalRegistros(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  // Busca por todos os campos do grid

  useEffect(() => {
    const fetchData = async () => {
      if (!searchTerm) {
        return;
      }
      const response = await axios.get(
        `/veiculo-faturamento/listarDadosGridVeiculoFaturamento/search`,
        {
          params: {
            idCliente: clienteId,
            keyword: searchTerm,
          },
        }
      );
      response.data = response.data.sort(function (
        a: FaturamentoClienteType,
        b: FaturamentoClienteType
      ) {
        return b.idVeiculo - a.idVeiculo;
      });
      //console.log(response.data)
      setSearchResults(removeDuplicados(response.data));
    };
    fetchData();
    if (!searchTerm) {
      history.push('/cliente/faturamentofrota/1');
    }
  }, [searchTerm]);

  const removeDuplicados = (objeto: any) => {
    let objetoTratado = [
      ...new Map(objeto.map((obj: any) => [JSON.stringify(obj.placa), obj])).values(),
    ];
    return objetoTratado;
  };

  const handleSearch = (event: any) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Dialog
        header="Solicitação de alteração no faturamento enviada com sucesso!"
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {}}
      />

      <Dialog
        header="Solicitação de alteração no faturamento enviada com sucesso!"
        footer={
          <Button
            onClick={() => {
              setIsDialogCadEmail(false);
              setIsDialogVisibleMsgEmail(true);
            }}
          >
            Ok
          </Button>
        }
        visible={isDialogCadEmail}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          setIsDialogCadEmail(false);
          setIsDialogVisibleMsgEmail(true);
        }}
      />

      <Dialog
        header="Não foi possível enviar email de alteração no faturamento. Verifique o email cadastrado."
        footer={
          <Button
            onClick={() => {
              setIsDialogVisibleMsgEmail(false);
            }}
          >
            OK
          </Button>
        }
        visible={isDialogVisibleMsgEmail}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleMsgEmail(false)}
      />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Faturamento/Frota</h2>
          <h6 className="subtitulo">{'Faturamento/Frota'}</h6>
        </div>

        <div className="search-create mb-4">
          <span className="p-input-icon-left">
            <input
              type="text"
              placeholder="Pesquise"
              className="p-inputtext p-component"
              id="search-input"
              style={{ width: '240px' }}
              value={searchTerm}
              onChange={handleSearch}
            />
            <i className="pi pi-search"></i>
          </span>
        </div>
        {show2 === true && (
          <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            enforceFocus
            show={show2}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Última alteração de frota realizada em{' '}
                {dataSolicitacao ? obterData(dataSolicitacao) : ''}. Não é possível realizar nova
                requisição
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Form.Row>
                  <Form.Group as={Col} md={2}>
                    <Button
                      variant="secondary"
                      style={{ width: '120px', height: '50px' }}
                      onClick={handleClose}
                    >
                      Ok
                    </Button>
                  </Form.Group>
                </Form.Row>
              </div>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
          </Modal>
        )}
        {show === true && (
          <Modal
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            enforceFocus
            show={show}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Solicitação de alteração do faturamento
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={(e: any) => handleSalvarFaturamentoFrota(e)}>
                <div style={{ display: 'flex' }}>
                  <Form.Group as={Col} md={4}>
                    <Form.Label sm={4}>Placa nº</Form.Label>
                    <Form.Control
                      value={placa}
                      id="placa"
                      name="placa"
                      style={{ marginRight: 12 }}
                      disabled
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={12}>
                    <Form.Label />
                    <Form.Control
                      value={modelo}
                      id="modelo"
                      name="modelo"
                      disabled
                      style={{ marginTop: 8, width: '52%' }}
                    />
                  </Form.Group>
                </div>

                <hr />

                <div>
                  <Form.Group as={Row}>
                    <Form.Label
                      className="required"
                      style={{
                        marginLeft: '16px',
                        marginTop: '5px',
                      }}
                      column
                      sm="3"
                    >
                      Faturar Veículo como:
                    </Form.Label>
                    <Col md={5}>
                      <Form.Control
                        value={faturarVeiculoComo}
                        onChange={(e: any) => setFaturarVeiculoComo(e.target.value.toString())}
                        as="select"
                        required
                        style={{ width: '80%' }}
                      >
                        <option value="">selecione</option>
                        {faturar &&
                          faturar.map((faturar: any) => (
                            <option key={faturar} value={faturar}>
                              {faturar}
                            </option>
                          ))}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      className="required"
                      style={{
                        marginLeft: '16px',
                        marginTop: '25px',
                      }}
                      column
                      sm="3"
                    >
                      Motivo:
                    </Form.Label>
                    <Col md={7}>
                      <Form.Control
                        as="textarea"
                        style={{ resize: 'none', width: '26vw', height: '4rem' }}
                        rows={3}
                        value={motivo}
                        onChange={(e: any) => {
                          setMotivo(e.target.value);
                        }}
                        id="motivo"
                        name="motivo"
                        required
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row}>
                    <Form.Label
                      className="required"
                      style={{
                        marginLeft: '16px',
                        marginTop: '5px',
                      }}
                      column
                      sm="3"
                    >
                      Nome do Solicitante:
                    </Form.Label>
                    <Col md={7}>
                      <Form.Control
                        value={nomeSolicitante}
                        onChange={(e: any) => setNomeSolicitante(e.target.value.toString())}
                        type="text"
                        required={true}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                </div>
                <hr />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Form.Row>
                    <Form.Group as={Col} md={2}>
                      <Button
                        type="submit"
                        variant="primary"
                        style={{ width: '120px', height: '50px' }}
                        onChange={handleSalvarFaturamentoFrota}
                      >
                        {loadingFaturamento ? 'Alterando...' : 'Confirmar'}
                      </Button>
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} md={2}>
                      <Button
                        variant="secondary"
                        style={{ width: '120px', height: '50px' }}
                        onClick={handleClose}
                      >
                        Cancelar
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
          </Modal>
        )}

        <div className="painel" style={{ width: '100%' }}>
          {loading || !listaFaturamento ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            <div className="table-responsive" style={{ width: '100%' }}>
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th>Placa</th>
                    <th>Veículo</th>
                    <th>Tecnologia</th>
                    <th>Faturado como</th>
                    <th>Alterar para</th>
                    <th>Solicitado por</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {searchTerm && currentFaturamento
                    ? currentFaturamento.map((lf, index) => (
                        <tr key={index}>
                          <td>{lf.placa}</td>
                          <td>{lf.modelo}</td>
                          <td>{lf.tecnologiaRastreamento.toUpperCase()}</td>
                          <td>{lf.faturadoComo.toUpperCase() || 'NÃO INFORMADO'}</td>
                          <td>
                            {lf.alterarPara === null || lf.status != null
                              ? ''
                              : lf.alterarPara.toUpperCase()}
                          </td>
                          <td>
                            {lf.nomeSolicitante === null
                              ? (lf.nomeSolicitante = '')
                              : lf.nomeSolicitante}
                            <br />
                            {lf.dataSolicitacao === null
                              ? ''
                              : obterDataHora(lf.dataSolicitacao, true)}
                          </td>
                          {lf.status === true && <td>Autorizado</td>}
                          {lf.status === false && <td>Não Autorizado</td>}
                          {lf.status === null && lf.alterarPara ? (
                            <td>Pendente</td>
                          ) : <td></td> && (lf.status === true || lf.status === false) ? (
                            ''
                          ) : (
                            <td></td>
                          )}
                          <td>
                            <button
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                width: 20,
                                height: 20,
                                padding: 0,
                                marginRight: 15,
                                marginBottom: 2,
                              }}
                            >
                              <i
                                title="Editar Faturamento"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (canEdit || canInsert) {
                                    handleShow(
                                      lf.idVeiculo,
                                      lf.qtdeDiasUltimaSolicitacao,
                                      lf.dataSolicitacao
                                    );
                                  } else {
                                    history.push('/acessonegado');
                                  }
                                }}
                                className="pi pi-pencil"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    : listaFaturamento.map((lf, index) => (
                        <tr key={index}>
                          <td>{lf.placa}</td>
                          <td>{lf.modelo}</td>
                          <td>{lf.tecnologiaRastreamento.toUpperCase()}</td>
                          <td>
                            {lf.status === true
                              ? lf.alterarPara.toUpperCase()
                              : lf.faturadoComo.toUpperCase() || 'NÃO INFORMADO'}
                          </td>
                          <td>
                            {lf.alterarPara === null || lf.status != null
                              ? ''
                              : lf.alterarPara.toUpperCase()}
                          </td>
                          <td>
                            {lf.nomeSolicitante === null
                              ? (lf.nomeSolicitante = '')
                              : lf.nomeSolicitante}
                            <br />
                            {lf.dataSolicitacao === null
                              ? ''
                              : obterDataHora(lf.dataSolicitacao, true)}
                          </td>
                          {lf.status === true && <td>Autorizado</td>}
                          {lf.status === false && <td>Não Autorizado</td>}
                          {lf.status === null && lf.alterarPara ? (
                            <td>Pendente</td>
                          ) : <td></td> && (lf.status === true || lf.status === false) ? (
                            ''
                          ) : (
                            <td></td>
                          )}
                          <td>
                            <button
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                width: 20,
                                height: 20,
                                padding: 0,
                                marginRight: 15,
                                marginBottom: 2,
                              }}
                            >
                              <i
                                title="Editar Faturamento"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  if (canEdit || canInsert) {
                                    handleShow(
                                      lf.idVeiculo,
                                      lf.qtdeDiasUltimaSolicitacao,
                                      lf.dataSolicitacao
                                    );
                                  } else {
                                    history.push('/acessonegado');
                                  }
                                }}
                                className="pi pi-pencil"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          )}
          <Pagination
            itensPerPage={itensPerPage}
            setItensPerPage={setItensPerPage}
            totalPosts={
              searchTerm ? currentFaturamento.length : totalRegistros ? totalRegistros : 0
            }
            paginate={paginate}
            currentPage={page}
          />
        </div>
      </div>
    </div>
  );
};
