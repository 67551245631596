/* eslint-disable */

import axios from 'axios';

export const createUser = async (body: any) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/create/`, body);

    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error;
    }
    return Error('A problem has occurred');
  }
};

export const updateUser = async (id: number, body: any) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/user/edit/${id}`, body);

    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error;
    }
    return Error('A problem has occurred');
  }
};

export const deleteUser = async ({ idUsuario }: { idUsuario: number }) => {
  try {
    const response = await axios.put(`${process.env.REACT_APP_API_URL}/user/delete/`, {
      idUsuario: Number(idUsuario),
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error;
    }
    return Error('A problem has occurred');
  }
};
