import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './styles.css';

type Props = {
  setLoading: (loading: boolean) => void;
  // setSearchValue atualizará o valor searchValue, este deverá ser  usado para fazer a pesquisa.
  setSearchValue: (searchAPI: string) => void;
  setSearch: (search: string) => void;
  search: string;
  styles?: any;
  disabled?: boolean;
  createDisabled?: boolean;
  linkToAdd?: string;
  placeholder?: string;
  onClick?: any;
  buttonDisabled?: boolean;
};

const Search: FC<Props> = ({
  setLoading,
  setSearchValue,
  search,
  setSearch,
  styles,
  disabled,
  linkToAdd,
  placeholder,
  onClick,
  createDisabled,
  buttonDisabled,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let timeout: any = 0;
  const history = useHistory();

  const onInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const duration = 1000;
    setLoading(true);
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchValue(e.target.value);
      setLoading(false);
    }, duration);
  };

  return (
    <div className="search-create" style={styles}>
      {createDisabled && !buttonDisabled && (
        <div
          className="create-button"
          aria-hidden="true"
          onClick={() => history.push('/acessonegado')}
        >
          <i
            style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
            className="pi pi-plus"
          />
        </div>
      )}

      {linkToAdd && !createDisabled && !buttonDisabled && (
        <Link to={linkToAdd} style={{ textDecoration: 'none' }} className="create-button">
          <i
            style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
            className="pi pi-plus"
          />
        </Link>
      )}

      {onClick && !createDisabled && !buttonDisabled && (
        <div onClick={onClick} className="create-button" aria-hidden="true">
          <i
            style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
            className="pi pi-plus"
          />
        </div>
      )}
      <span className="p-input-icon-left">
        <input
          disabled={disabled}
          type="text"
          placeholder={placeholder || 'Pesquise'}
          className="p-inputtext p-component"
          id="search-input"
          value={search}
          onChange={(e) => {
            onInput(e);
            setSearch(e.target.value);
          }}
        />
        <i className="pi pi-search" aria-label="search-icon" />
      </span>
    </div>
  );
};

export default Search;
