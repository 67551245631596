/* eslint-disable */
// @ts-ignore
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ReactExport from 'react-data-export';

import GeneratePDFSat from './GeneratePDFSat';
import { isAllowedByRole } from '../../services/cookieService';

import RelatorioXLSAdmin from './RelatorioXLSAdmin';
import RelatorioXLSCliente from './RelatorioXLSCliente';

import { FiltroResumoSat } from './ResumoSat';
import { Tela } from '../../components/Tela';
import { obterDataHora } from '../../util/date';

type ListagemConsulta = {
  ativo: boolean;
  idCliente: number;
  listaDispositivo: {
    descricao: string;
    situacao: boolean;
  }[];
  numeroBau: string;
  numeroSat: number;
  placaCarreta: string;
  placaVeiculo: string;
  razaoSocialCliente: string;
  responsavel: string;
  usuarioFinalizacao: string;
  usuarioLancamento: string;
  dataFinalizacao: string;
  dataInclusao: string;
  tipoFrotaCarreta: string;
  tipoFrotaVeiculo: string;
};

type ExcelGeneration2 = {
  ySteps?: number;
  xSteps?: number;
  columns: { title: any | undefined; widthPx?: any; style?: any; width?: any }[];
  data: { value: any | undefined; style?: any }[][];
};

const ListagemResumoSat = () => {
  const history = useHistory();
  const [data, setData] = useState<ListagemConsulta[] | undefined>();
  const [dataModel, setDataModel] = useState<FiltroResumoSat | undefined>();
  const [searchResults, setSearchResults] = useState<ListagemConsulta[] | undefined>();

  const [searchTerm, setSearchTerm] = useState('');
  const location: {
    state: { listagem: ListagemConsulta[]; model: FiltroResumoSat };
  } = useLocation();
  const { state } = location;

  const [totalSearchResults, setTotalSearchResults] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * Number(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - Number(itensPerPage);
  const currentVeiculos = searchTerm
    ? searchResults?.slice(indexOfFirstPost, indexOfLastPost)
    : state?.listagem.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const [excelFinalizado, setExcelFinalizado] = useState<any>([]);

  const [excelGenerationCliente, setExcelGenerationCliente] = useState<
    ExcelGeneration2[] | undefined
  >();
  const [excelGenerationAdmin, setExcelGenerationAdmin] = useState<
    ExcelGeneration2[] | undefined
  >();

  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;

  function separar(base: any, max: any) {
    let res = [];
    for (let i = 0; i < max; i++) {
      res.push(base.slice(i, i + 1)[0]);
    }
    return res;
  }

  useEffect(() => {
    if (isAllowedByRole(['user'])) {
      RelatorioXLSCliente(state, setExcelGenerationCliente);
    } else {
      RelatorioXLSAdmin(state, setExcelGenerationAdmin);
    }
  }, [state]);

  useEffect(() => {
    setData(state.listagem);
    setDataModel(state.model);
  }, []);

  return (
    // <div style={{ display: 'flex', justifyContent: 'row' }}>
    //   <MenuLateral />
    //   <div className="card">
    //     <div className="titulo-container">
    //       <h2 className="titulo">Relatório SAT</h2>
    //       <h6 className="subtitulo">{'Consultas/Relatórios > Assistência Técnica'}</h6>
    //     </div>

    <Tela
      nome="Relatório SAT"
      caminho="Consultas/Relatórios > Assistência Técnica"
      filename="SATLancadas"
      excelGeneration={isAllowedByRole(['user']) ? excelGenerationCliente : excelGenerationAdmin}
      pdf
      onClickPdf={() => GeneratePDFSat(separar(data, data?.length), dataModel, data?.length)}
      totalPosts={searchTerm ? totalSearchResults : state ? state.listagem.length : 0}
      paginate={paginate}
      currentPage={currentPage.toString()}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      onClickVoltar={() => history.push('/consultar-sat')}
    >
      <div className="content-container">
        <div className="painel">
          <div className="table-responsive" style={{ flex: 1 }}>
            {isAllowedByRole(['admin']) ? (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th style={{ width: 50 }}>Nº SAT</th>
                    <th style={{ width: 70 }}>Placa / Baú</th>
                    <th style={{ width: 100 }}>Tipo Veículo</th>
                    <th style={{ width: 150 }}>Dispositivo</th>
                    <th style={{ width: 150 }}>Transportador</th>
                    <th style={{ width: 150 }}>Responsável</th>
                    {/* <th style={{ width: 150 }}>Situação</th> */}
                    {/* <th style={{ width: 150 }}>Tipo Frota</th> */}
                    <th style={{ width: 150 }}>Data Lançamento</th>
                    <th style={{ width: 150 }}>Data Finalização</th>
                  </tr>
                </thead>
                <tbody>
                  {currentVeiculos &&
                    currentVeiculos.map((each, index) => (
                      <tr key={index}>
                        <td>{each.numeroSat}</td>
                        <td>
                          {each.placaVeiculo
                            ? each.placaVeiculo
                            : each.placaCarreta
                            ? each.placaCarreta
                            : each.numeroBau
                            ? each.numeroBau
                            : ''}
                        </td>
                        <td>
                          {`${
                            each.placaVeiculo != null && each.placaCarreta === null
                              ? 'Veículo'
                              : each.placaCarreta
                              ? 'Carreta'
                              : each.numeroBau
                              ? 'Baú'
                              : ''
                          }`}
                        </td>
                        <td>
                          {each.listaDispositivo[0]
                            ? each.listaDispositivo.length === 1 ||
                              each.listaDispositivo[each.listaDispositivo.length + 1]
                              ? each.listaDispositivo.map((each) => `${each.descricao} `)
                              : each.listaDispositivo.map((each) => `${each.descricao}, `)
                            : ''}
                        </td>
                        <td>{each.razaoSocialCliente ? each.razaoSocialCliente : ''}</td>
                        <td>{each.responsavel ? each.responsavel : ''}</td>
                        {/* <td>
                         {each.ativo === true ? 'OK' : 'Com defeito'}
                        </td> */}
                        <td>{`${
                          each.dataInclusao ? obterDataHora(each.dataInclusao, true) : ''
                        }`}</td>
                        <td>{`${
                          each.dataFinalizacao ? obterDataHora(each.dataFinalizacao, true) : ''
                        }  `}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            ) : (
              <table className="table">
                <thead className="thead">
                  <tr>
                    <th style={{ width: 50 }}>Nº SAT</th>
                    <th style={{ width: 70 }}>Placa / Baú</th>
                    <th style={{ width: 100 }}>Tipo Veículo</th>
                    <th style={{ width: 150 }}>Dispositivo</th>
                    <th style={{ width: 150 }}>Responsável</th>
                    {/* <th style={{ width: 150 }}>Tipo Frota</th> */}
                    <th style={{ width: 150 }}>Data Lançamento</th>
                    <th style={{ width: 150 }}>Data Finalização</th>
                  </tr>
                </thead>
                <tbody>
                  {currentVeiculos &&
                    currentVeiculos.map((each, index) => (
                      <tr>
                        <td>{each.numeroSat}</td>
                        <td>
                          {each.placaVeiculo
                            ? each.placaVeiculo
                            : each.placaCarreta
                            ? each.placaCarreta
                            : each.numeroBau
                            ? each.numeroBau
                            : ''}
                        </td>
                        <td>
                          {`${
                            each.placaVeiculo != null && each.placaCarreta === null
                              ? 'Veículo'
                              : each.placaCarreta
                              ? 'Carreta'
                              : each.numeroBau
                              ? 'Baú'
                              : ''
                          }`}
                        </td>
                        <td>
                          {each.listaDispositivo[0]
                            ? each.listaDispositivo.length === 1 ||
                              each.listaDispositivo[each.listaDispositivo.length + 1]
                              ? each.listaDispositivo.map((each) => `${each.descricao} `)
                              : each.listaDispositivo.map((each) => `${each.descricao}, `)
                            : ''}
                        </td>
                        <td>{each.responsavel ? each.responsavel : ''}</td>

                        <td>{`${
                          each.dataInclusao ? obterDataHora(each.dataInclusao, true) : ''
                        }`}</td>
                        <td>{`${
                          each.dataFinalizacao ? obterDataHora(each.dataFinalizacao, true) : ''
                        }  `}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Tela>
  );
};

export default ListagemResumoSat;
