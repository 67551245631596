import React from 'react';
import { Button, Modal } from 'react-bootstrap';

type Props = {
  linkImage?: string;
  show: boolean;
  setShow: (show: boolean) => void;
};

export const FullScreen = ({ linkImage, show, setShow }: Props) => {
  const api = process.env.REACT_APP_API_URL;

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Body>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            style={{ width: '60%', height: '60%' }}
            src={`${api}/foto/downloadFile/{file}?file=${linkImage}`}
            alt={linkImage}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => setShow(false)}
          style={{ width: '120px', height: '50px' }}
        >
          Voltar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
