import { ProgressBar } from 'primereact/progressbar';

type Travel = {
  dataChegadaPlanejada: string;
  dataReplanejada: string;
  distanciaProxPonto: number;
  dtHrCalculadaChegadaPonto: string;
  dtHrCalculadaProxPonto: string;
  dtHrChegadaPonto: string;
  dtHrInicioRealSM: string;
  dtHrInicioSM: string;
  dtHrPlanejadaViagemPonto: string;
  dtHrUltimoEvento: string;
  enderecoPonto: string;
  hintPonto: string;
  idLsc: number;
  idPlanoViagem: number;
  idPontoPlanejamentoSmo: number;
  idPontoSmo: number;
  idPontoViagem: number;
  idPontoViagemReplanejada: number;
  idSm: number;
  latitudePonto: number;
  longitudePonto: number;
  nomePonto: string;
  numSeqPonto: number;
  origemDestinoPonto: string;
  percPercorridoPonto: number;
  previsao: string;
  previsaoComparadas: string;
  remessa: any;
  statusConcluidoPonto: boolean;
  statusPonto: string;
  tempoProxPonto: number;
};

type Point = {
  ponto: Travel;
};

export const StepperLineTravelProgress = ({ ponto }: Point) => {
  const chooseLinhaBackgroundColor = (statusPonto: string) => {
    switch (statusPonto) {
      case 'B':
        return '#63c960';
      case 'A':
        return '#c44a57';
      case 'D':
        return '#e8ab53';
      default:
        return '#FFFFFF';
    }
  };
  return (
    <div className="progresso-linha pointer">
      {!ponto.statusConcluidoPonto ? (
        <ProgressBar
          mode="indeterminate"
          color="#ffffff4e"
          style={{
            width: `${ponto.percPercorridoPonto}%`,
            height: '100%',
            background: chooseLinhaBackgroundColor(ponto.statusPonto),
          }}
        />
      ) : (
        <div
          className="progresso-linha-colored"
          style={{
            width: `${ponto.percPercorridoPonto}%`,
            height: '100%',
            backgroundColor: chooseLinhaBackgroundColor(ponto.statusPonto),
          }}
        />
      )}
    </div>
  );
};
