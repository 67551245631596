/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable */
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { parse, set } from 'date-fns';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import api from '../../../config/axiosMaquina';
import { getCookieSessionData } from '../../../services/cookieService';
import { useToast } from '../../../hooks/Toast';
import ListagemHistoricoPesquisas, { FilterHistory } from '../ListagemHistoricoPesquisas';
import RangeDate from '../../../components/RangeDate';


export type ListagemHistoricoPesquisa = {
  dataLancamento: string;
  empresa: string;
  idCliente?: number;
  itens: {
    dataRetorno: string;
    descricao: string;
    idPesquisaEntidade: number;
    itemPesquisado: string;
    tipo: string;
    validade: string;
    validadePesquisaEntidade: string;
  }[];
  numLiberacao: number;
  operacao: string;
  resultado: string;
  usuario: string;
  validadeAnaliseCondutorAutonomo: number;
  validadeAnaliseNaoMotoristaAgregado: number;
  validadeAnaliseNaoMotoristaAutonomo: number;
  validadeAnaliseNaoMotoristaFuncionario: number;
  validadeAnaliseVeiculoTerceiro: number;
};

export type Pesquisa = {
  condutorCPF: string;
  dataFim: string;
  dataIni: string;
  idCliente: number;
  nomeCondutor: string;
  numeroLiberacao: number;
  placaVeiculo: string;
  tipo: string;
  tipoBusca: string;
  ajudanteCPF?: string;
  nomeAjudante?: string;
};

export const HistoricoPesquisas: FC = () => {
  const [rangeDate, setRangeDate] = useState<any>([]);
  const { addToast } = useToast();
  const [dataResults, setDataResults] = useState<ListagemHistoricoPesquisa[]>();
  const [filter, setFilter] = useState<FilterHistory>();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [totalItems, setTotalItems] = useState(10);
  const [typeSearch, setTypeSearch] = useState('todos');
  const { clienteId } = getCookieSessionData().usuarioVO;
  const [lastPage, setLastPage] = useState(0);
  const [refresh, setRefresh] = useState(0);
  const [refreshitensPerPage, setRefreshitensPerPage] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearch = ({ clienteId, pagina, type, dataIni, dataFim, keyword }: any) => {
    const dataTodos = {
      idCliente: clienteId,
      pagina,
      qtdRegistros: Number(10),
      ...(type && type !== 'todos' && { tipoPesquisa: type }),
      ...(dataIni && { dataIni }),
      ...(dataFim && { dataFim }),
      ...(keyword && { keyword }),
    };

    setCurrentPage(pagina);

    setLoading(true);

    setFilter(dataTodos);

    api
      .post(`/pesquisa/historico?idCliente=${clienteId}`, dataTodos)
      .then((response) => {
        setLoading(false);
        setDataResults(response.data.items);
        setTotalItems(response.data.count);
        setLastPage(response.data.pages);
      })
      .catch((e) => {
        console.log(e);
        addToast({
          title: 'Erro',
          description: 'Erro na comunicação com o servidor.',
          type: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
        setRefreshitensPerPage(false);
        setRefresh(0);
      });
  };

  useEffect(() => {
    handleSearch({ clienteId, pagina: 1 });
  }, [clienteId]);

  useEffect(() => {
    if (!refresh) return;

    let dataIni;
    let dataFim;

    if (rangeDate.length) {
      dataIni = set(parse(rangeDate[0], 'dd/MM/yyyy', new Date()), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      });

      dataFim = set(parse(rangeDate[1], 'dd/MM/yyyy', new Date()), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      });
    }

    handleSearch({
      clienteId,
      pagina: refresh,
      type: typeSearch,
      dataIni,
      dataFim,
      keyword: searchTerm,
    });
  }, [refresh]);

  useEffect(() => {
    if (!refreshitensPerPage) return;

    let dataIni;
    let dataFim;

    if (rangeDate.length) {
      dataIni = set(parse(rangeDate[0], 'dd/MM/yyyy', new Date()), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      });

      dataFim = set(parse(rangeDate[1], 'dd/MM/yyyy', new Date()), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      });
    }
    handleSearch({ clienteId, pagina: 1, type: typeSearch, dataIni, dataFim, keyword: searchTerm });
  }, [refreshitensPerPage]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const fetchData = async () => {
        let dataIni;
        let dataFim;

        if (rangeDate.length) {
          dataIni = set(parse(rangeDate[0], 'dd/MM/yyyy', new Date()), {
            hours: 0,
            minutes: 0,
            seconds: 0,
          });

          dataFim = set(parse(rangeDate[1], 'dd/MM/yyyy', new Date()), {
            hours: 23,
            minutes: 59,
            seconds: 59,
          });
        }
        handleSearch({
          clienteId,
          pagina: 1,
          type: typeSearch,
          dataIni,
          dataFim,
          keyword: searchTerm,
        });
      };

      fetchData();
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [typeSearch, rangeDate, searchTerm]);

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Histórico de pesquisas lançadas</h2>
          <h6 className="subtitulo">{'Análise de Perfil > Histórico de Pesquisas'}</h6>
        </div>

        <div className="search-create mb-4" style={{ width: '100%' }}>
          <span className="p-input-icon-left">
            <select
              value={typeSearch}
              onChange={(e) => setTypeSearch(e.target.value)}
              name="cars"
              style={{
                width: '240px',
                padding: '0.7rem 0.5rem',
                marginRight: '10px',
                border: '1px solid #d9d0d0',
                background: '#ffffff',
              }}
            >
              <option value="todos">Todos</option>
              <option value="Pesquisa Cadastral Integrada">Integrada</option>
              <option value="Pesquisa Cadastral Veículo">Veículo</option>
              <option value="Pesquisa Cadastral Condutor">Condutor</option>
              <option value="Pesquisa Cadastral Ajudante/Funcionário">Ajudante</option>
            </select>
          </span>

          <RangeDate setValue={setRangeDate} />

          <span className="p-input-icon-left">
            <input
              type="text"
              placeholder="Pesquise"
              className="p-inputtext p-component"
              id="search-input"
              style={{ width: '240px' }}
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            <i className="pi pi-search" />
          </span>
        </div>

        {dataResults && !loading ? (
          <>
            <ListagemHistoricoPesquisas filters={filter} />
          </>
        ) : loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
