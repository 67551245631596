import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import mascaraCpf from '../../../util/marcaraCPF';
import axios from '../../../config/axiosMaquina';
import './styles.css';
import { CondutorTypesGrid } from '../../../api/Types/condutorTypesGrid';
import { getCookieSessionData } from '../../../services/cookieService';
import { Tela } from '../../../components/Tela';
import { filterItems2 } from '../../../util/format';
import Table from '../../../components/TableComponent';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

// FALTA PAGINAR PELO FRONT

const ListagemCondutor = () => {
  const { TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const headers = {
    id: 'Id',
    cpf: 'CPF',
    nome: 'Nome',
    sexo: 'Sexo',
    rg: 'RG',
    nomeCidade: 'Cidade',
    nomeUf: 'UF',
    quantidadeViagem: 'Quantidade de Viagens',
    valorMedioTransportado: 'Valor Médio Transportado',
    tempoCadastro: 'Tempo de Cadastro',
  };
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const { clienteId } = getCookieSessionData().usuarioVO;
  const history = useHistory();

  const paginate = (pageNumber: number) => {
    history.push(`/condutores/listar/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CONDUTOR] }], 'inserir');
  }, [TRANSPORTADOR, rolePermission]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await axios.get('/condutor/listarDadosGrid', {
        params: {
          idCliente: clienteId,
        },
      });
      const array = response.data.map((each: CondutorTypesGrid) => {
        return {
          id: each.id,
          cpf: mascaraCpf(each.cpf),
          nome: each.nome,
          sexo: each.sexo,
          rg: each.rg,
          nomeCidade: each.nomeCidade,
          nomeUf: each.nomeUf,
          quantidadeViagem: each.quantidadeViagem,
          valorMedioTransportado: each.valorMedioTransportado
            ? each.valorMedioTransportado.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })
            : '',
          tempoCadastro: each.tempoCadastro,
        };
      });
      setData(array);
      setLoading(false);
    };
    fetchData();
  }, [clienteId]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/condutores/listar/1');
      setDataSearch(filterItems2(data, searchValue));
    }
  }, [data, history, searchValue]);

  return (
    <Tela
      nome="Condutor"
      caminho="Cadastro > Condutor"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/cadastro-condutor"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      <Table
        items={currentData}
        headers={headers}
        handleEdit={(id: any) => {
          history.push(`/condutores/editar/${id}`);
        }}
      />
    </Tela>
  );
};

export default ListagemCondutor;
