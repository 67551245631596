/* eslint-disable */
import React, { ChangeEvent, FC, useState, useEffect } from 'react';
import { ListaPontos } from '../../api/Types/agendarViagensType';
import axios from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { soNumeros } from '../../util/Validacoes/Validacoes';
import { isHourLessThanBaseHour, isDateLessThanBaseDate } from '../../util/Validacoes/Validacoes';
import './styles.css';
import { decodedTextSpanIntersectsWith } from 'typescript';
import { maskCep } from '../../util/Validacoes/Validacoes';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Form,
  Col,
  Row,
  Button,
  Alert,
  Navbar,
  FormControl,
  Modal,
} from 'react-bootstrap';
import { UfIbgeType } from '../../api/model/UfIbgeType';
import { MunicipiosType } from '../../api/model/MunicipiosType';
import { ParadaType } from '../../api/model/ParadaType';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import './styles.css';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';

type Props = {
  show: boolean;
  setShow: (bool: boolean) => void;
  onPress: () => void;
  idParada: any;
  setIdParada: (paradaId: any) => void;
  sequencia: number;
  setSequencia: (sequencia: number) => void;
  motivo: string;
  setMotivo: (motivo: string) => void;
  local: string;
  setLocal: (local: string) => void;
  cep: string;
  setCep: (cep: string) => void;
  logradouro: string;
  setLogradouro: (logradouro: string) => void;
  logradouroNumero: number;
  setLogradouroNumero: (logradouroNumero: number) => void;
  logradouroComplemento: string;
  setLogradouroComplemento: (logradouroComplemento: string) => void;
  bairro: string;
  setBairro: (bairro: string) => void;
  idCodigoIbge: number;
  setIdCodigoIbge: (idCodigoIbge: number) => void;
  cidade: string;
  setCidade: (cidade: string) => void;
  rotaIdParada: number;
  setRotaIdParada: (rotaIdParada: number) => void;
};

const ModalAddParada: FC<Props> = ({
  cidade,
  setCidade,
  idCodigoIbge,
  setIdCodigoIbge,
  bairro,
  setBairro,
  logradouroComplemento,
  setLogradouroComplemento,
  logradouroNumero,
  setLogradouroNumero,
  logradouro,
  setLogradouro,
  cep,
  setCep,
  local,
  setLocal,
  motivo,
  setMotivo,
  sequencia,
  setSequencia,
  idParada,
  show,
  setShow,
  onPress,
  rotaIdParada,
}) => {
  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const history = useHistory();

  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [paradas, setParadas] = useState<ParadaType[]>([]);
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [viaCepUf, setViaCepUf] = useState('');
  const [viaCepCidade, setViaCepCidade] = useState('');
  const [viaCepIbgeCod, setViaCepIbgeCod] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    const loadEstadosPorPais = async () => {
      if (show) {
        try {
          const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/1`);
          setUfs(response.data);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    loadEstadosPorPais();
  }, [show]);

  const loadMunicipiosPorEstado = async (estado: any) => {
    try {
      const response = await axios.get(`/municipio-ibge/listarPorUf/${estado}`);
      setMunicipios(response.data);
    } catch (err: any) {
      console.log(err.response);
    }
  };

  useEffect(() => {
    const buscarCep = () => {
      if (show) {
        if (cep.length < 9) {
          setCidade('');
          setLogradouro('');
          setBairro('');
          setIdCodigoIbge(0);
          setViaCepUf('');
          return;
        }
        setError(false);
        fetch(`https://viacep.com.br/ws/${cep}/json`)
          .then((res) => res.json())
          .then((data) => {
            if (data.erro) {
              setError(true);
              return;
            }
            setLogradouro(data.logradouro);
            setBairro(data.bairro);
            setViaCepCidade(data.localidade);
            setViaCepUf(data.uf);
            //setCep(data.cep);
            const unidadeId = ufs
              .filter((uf) => uf.sigla === data.uf)
              .map((filteredUfId) => filteredUfId.id);
            setCidade(data.localidade);
            const codIbgeFloat = data.ibge / 100000;
            const codIbgeFormatado = Math.trunc(codIbgeFloat);
            setViaCepIbgeCod(codIbgeFormatado);
            let listaMunPorEstado = municipios
              .filter((municipio) => municipio.idCodigoIbge === viaCepIbgeCod)
              .map((filteredMunicipio) => filteredMunicipio.nomeMunicipio);
            setIdCodigoIbge(codIbgeFormatado);
          })
          .catch((erro) => {
            setError(true);
          });
      }
    };
    buscarCep();
  }, [cep, show]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (show) {
  //       try {
  //         const response = await axios.get('/parada');
  //         setParadas(response.data);
  //       } catch (err) {
  //         console.error(err);
  //       }
  //     }
  //   };
  //   fetchData();
  // }, [show]);

  return (
    <Modal
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      animation
      show={show}
      onHide={() => {
        setShow(false);
        setIsFormInvalid(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Inclusão de Paradas</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate validated={isFormInvalid}>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Sequência
              </Form.Label>
              <Form.Control
                value={sequencia}
                id="sequencia"
                name="sequencia"
                type="text"
                placeholder=""
                required
                style={{ width: '15%' }}
                disabled
              />
              <Form.Control.Feedback type="invalid">
                Campo Sequência não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Motivo</Form.Label>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setMotivo(e.target.value);
                }}
                value={motivo}
                as="select"
                id="motivo"
                name="motivo"
                placeholder=""
                style={{ width: '100%' }}
              >
                <option value="">Selecione uma opção</option>
                <option value="1">Alimentação</option>
                <option value="2">Descanso</option>
                <option value="3">Entrega</option>
                <option value="4">Coleta</option>
                <option value="5">Fiscalização</option>
                <option value="9">Outros</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Local
              </Form.Label>

              <Form.Control
                value={local}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLocal(e.target.value);
                }}
                id="local"
                name="local"
                type="text"
                placeholder=""
                required
                style={{ width: '100%' }}
              />

              <Form.Control.Feedback type="invalid">
                Campo Local não pode ser nulo.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>CEP</Form.Label>
              <Form.Control
                value={cep}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setCep(maskCep(e.target.value));
                }}
                id="cep"
                name="cep"
                type="text"
                placeholder=""
                style={{ width: '100%' }}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Endereço</Form.Label>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouro(e.target.value);
                }}
                value={logradouro}
                type="text"
                id="logradouro"
                name="logradouro"
                placeholder=""
                style={{ width: '100%' }}
              ></Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Número</Form.Label>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouroNumero(e.target.value ? parseInt(e.target.value, 10) : 0);
                }}
                value={logradouroNumero === 0 ? '' : logradouroNumero}
                type="text"
                id="logradouroNumero"
                name="logradouroNumero"
                placeholder=""
                style={{ width: '100%' }}
              ></Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Complemento</Form.Label>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLogradouroComplemento(e.target.value);
                }}
                value={logradouroComplemento}
                type="text"
                id="logradouroComplemento"
                name="logradouroComplemento"
                placeholder=""
                style={{ width: '100%' }}
              ></Form.Control>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setBairro(e.target.value);
                }}
                value={bairro}
                type="text"
                id="bairro"
                name="bairro"
                placeholder=""
                style={{ width: '100%' }}
              ></Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>
                <span className="txt-obrigatorio">*</span>UF
              </Form.Label>
              <Form.Control
                value={idCodigoIbge === 0 ? '' : idCodigoIbge}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setIdCodigoIbge(e.target.value ? parseInt(e.target.value, 10) : 0);
                  loadMunicipiosPorEstado(e.target.value);
                }}
                id="idCodigoIbge"
                name="idCodigoIbge"
                as="select"
                placeholder=""
                style={{ width: '100%' }}
                required
              >
                <option></option>
                {ufs.map((uf) => (
                  <option value={uf.id} key={uf.id}>
                    {uf.sigla}
                  </option>
                ))}
                <option value={idCodigoIbge} key={idCodigoIbge}>
                  {viaCepUf}
                </option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>
                <span className="txt-obrigatorio">*</span>Município
              </Form.Label>
              <Form.Control
                onChange={(event: any) => {
                  setCidade(event.target.value);
                }}
                value={cidade}
                as="select"
                id="cidade"
                name="cidade"
                placeholder=""
                style={{ width: '100%' }}
                disabled={!idCodigoIbge}
                required
              >
                <option value="">Selecione um municipio</option>

                {viaCepCidade && (
                  <option key={cidade} value={cidade}>
                    {viaCepCidade}
                  </option>
                )}

                {viaCepCidade &&
                  municipios
                    .filter((municipio) => municipio.idCodigoIbge == viaCepIbgeCod)
                    .map((filteredMunicipio) => (
                      <option
                        key={filteredMunicipio.nomeMunicipio}
                        value={filteredMunicipio.nomeMunicipio}
                      >
                        {filteredMunicipio.nomeMunicipio}
                      </option>
                    ))}

                {municipios.map((filteredMunicipio) => (
                  <option
                    key={filteredMunicipio.nomeMunicipio}
                    value={filteredMunicipio.nomeMunicipio}
                  >
                    {filteredMunicipio.nomeMunicipio}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button
          style={{ color: '#000' }}
          variant="primary"
          onClick={(e: any) => {
            e.preventDefault();
            if (!sequencia || !local || !idCodigoIbge || !cidade) {
              setIsFormInvalid(true);
              e.stopPropagation();
              return;
            } else {
              setIsFormInvalid(false);
              onPress();
              setShow(false);
            }
          }}
        >
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddParada;
