import { obterData, obterDataHora } from '../../util/date';
import getTipoFrota from '../../util/Validacoes/getTipoFrota';

// Função para validar datas do problema que ocorreu no dia 21/11/2024
const isValidDate = (date: any): boolean => {
  if (!date) return false;
  const parsedDate = new Date(date);
  // eslint-disable-next-line no-restricted-globals
  return parsedDate instanceof Date && !isNaN(parsedDate.getTime());
};

export const RelatorioChecklistConsolidade = (state: any, setExcelGenerationUser: any) => {
  const total = state.listagem.length;
  console.log(state);

  const excel = [
    {
      xSteps: 2,
      ySteps: 0,
      columns: [
        {
          title: 'Consolidado Checklist',
          widthPx: 3000,
          style: {
            font: { sz: '24', bold: true },
          },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 0,
      columns: [
        {
          title: `Periodo: ${
            state.model.dataInicio ? obterData(state.model.dataInicio).toString() : '/ /'
          } e ${state.model.dataFim ? obterData(state.model.dataFim).toString() : '/ /'}`,
          width: { wpx: 2000 },
          style: { font: { sz: '12' }, alignment: { horizontal: 'center' } },
        },
      ],
      data: [],
    },
    {
      xSteps: 0,
      ySteps: 1,
      columns: [
        { title: 'Veículo' }, //
        { title: 'Status' }, //
        { title: 'Data Realização' }, //
        { title: 'Validade' },
        { title: 'Responsável' }, //
        { title: 'Operador' }, //
        { title: 'Dispositivo(s) pendente(s) de manutenção' },
      ],
      data: state.listagem.map((each: any) => [
        { value: each.placaVeiculo ?? '' },
        {
          value: (() => {
            const checklist = each.checklistValidade || '';

            if (checklist.includes('dispositivos')) {
              return 'Checklist inválido';
            }
            if (checklist.includes('SM')) {
              return 'Checklist inválido';
            }
            if (checklist.includes('OK')) {
              return 'Checklist válido';
            }
            if (checklist.includes('Vencido') && !checklist.includes('SM')) {
              return 'Checklist vencido';
            }
            return 'Status Indefinido';
          })(),
        },
        { value: isValidDate(each.dataInclusao) ? obterDataHora(each.dataInclusao) : '' },
        {
          value: each.checklistValidade.includes('EMBARQUE')
            ? 'No embarque'
            : isValidDate(each.dataValidade)
            ? obterData(each.dataValidade)
            : '',
        },
        { value: each.responsavel ?? '' },
        { value: each.usuarioLancamento ?? '' },
        {
          value: each?.listaDispositivo?.filter((disp: any) => disp?.situacaoDispositivo === '3')
            ?.length
            ? each?.listaDispositivo
                .filter((disp: any) => disp?.situacaoDispositivo === '3')
                .map((disp: any) => disp.nomeDispositivo)
                .join(', ')
            : '',
        },
      ]),
    },
  ];

  setExcelGenerationUser(excel);
};
