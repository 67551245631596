/* eslint-disable */
import axios from 'axios';

interface MonitoringRouteProps {
  id: string;
}

export const monitoringRoute = async (ids: string) => {
  try {
    const response = await axios.get<MonitoringRouteProps>(
      `${process.env.REACT_APP_API_URL}/request-monitoring-route/?id=${ids}`
    );

    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error;
    }
    return Error('A problem has occurred');
  }
};
