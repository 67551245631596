/* eslint-disable */
import axios from '../../../config/axiosMaquina';
import React, { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { Form, Col, Modal, Spinner } from 'react-bootstrap';
import { VeiculoCadastro } from '../../../../src/Types/Veiculos/VeiculoCadastroType';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { ProprietarioList } from '../../../../src/Types/Veiculos/ProprietarioType';
import { Cor } from '../../../../src/Types/Veiculos/CorType';
import { VeiculoMarca } from '../../../../src/Types/Veiculos/VeiculoMarcaType';
import { VeiculoTipo, CarretaTipo } from '../../../../src/Types/Veiculos/VeiculoTipoType';
import { TecnologiaRastreamento } from '../../../../src/Types/Veiculos/TecnologiaRastreamentoType';
import { TipoComunicacaoIdNome } from '../../../../src/api/model/TipoComunicacaoIdNomeType';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import { useToast } from '../../../hooks/Toast';
import './styles.css';
import Switch from 'react-bootstrap/esm/Switch';
import { UfIbgeType } from '../../../api/model/UfIbgeType';
import { MunicipiosTypes } from '../../../api/Types/municipiosTypes';
import { MunicipiosType } from '../../../api/model/MunicipiosType';
import { PaisType } from '../../../api/model/PaisType';
import { validarPlaca, validaNumero, soNumeros } from '../../../util/Validacoes/Validacoes';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { VeiculoTypeGrid } from '../../../Types/Veiculos/VeiculoTypeGrid';
import { getCookieSessionData, isAllowedByRole } from '../../../services/cookieService';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { dataConverter, obterDataHora } from '../../../util/date';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import { log } from 'console';
import { P } from '../../ValidacaoCnh/styles';

function compare(a: any, b: any) {
  if (a.razaoSocial.toLowerCase() < b.razaoSocial.toLowerCase()) return -1;
  if (a.razaoSocial.toLowerCase() > b.razaoSocial.toLowerCase()) return 1;
  return 0;
}

const CadastroVeiculo: React.FC = () => {
  const { ADMIN, TRANSPORTADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const clienteId = getCookieSessionData().usuarioVO.clienteId;
  const { role } = getCookieSessionData().usuarioVO;
  const [flagHabilitarPesquisaAnttRntrc, setFlagHabilitarPesquisaAnttRntrc] = useState(false);
  const [cores, setCores] = useState<Cor[]>([]);
  const [marca, setMarca] = useState<VeiculoMarca[]>([]);
  const [veiTipo, setVeiTipo] = useState<VeiculoTipo[]>([]);
  const [tipoCarreta, setTipoCarreta] = useState<CarretaTipo[]>([]);
  const [veiTer, setVeiTer] = useState<TecnologiaRastreamento[]>([]);
  const [ufs, setUfs] = useState<UfIbgeType[]>([]);
  const [municipios, setMunicipios] = useState<MunicipiosType[]>([]);
  const [pais, setPais] = useState<PaisType[]>([]);
  const [municipioFilter, setMunicipioFilter] = useState<MunicipiosType[] | null>(null);
  const [tipoComunicacao, setTipoComunicacao] = useState<TipoComunicacaoIdNome[]>([]);
  const [proprietario, setProprietario] = useState<ProprietarioList[]>([]);
  const [mapa, setMapa] = useState(true);
  const [veiculosCliente, setVeiculosCliente] = useState<VeiculoTypeGrid[]>([]);
  const [carretasCliente, setCarretasCliente] = useState<VeiculoTypeGrid[]>([]);
  const [municipiosPorEstado, setMunicipiosPorEstado] = useState<MunicipiosTypes[]>([]);
  const [ufSigla, setUfSigla] = useState<UfIbgeType[]>([]);
  const [numRastExists, setNumRastExists] = useState(false);

  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [errorEmailCadOk, setErrorEmailCadOk] = useState(false);
  const [isDialogVisibleMsgEmail, setIsDialogVisibleMsgEmail] = useState(false);

  const [isPlacaValid, setIsPlacaValid] = useState(false);
  const [previousPlaca, setPreviousPlaca] = useState('');
  const [isPlacaRegistered, setIsPlacaRegistered] = useState(false);
  const [isValidAnoFabricacao, setIsValidAnoFabricacao] = useState(true);
  const [isValidAnoModelo, setIsValidAnoModelo] = useState(true);

  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [technologyFound, setTechnologyFound] = useState<TecnologiaRastreamento>(
    {} as TecnologiaRastreamento
  );

  const DATA_ATUAL = dataConverter(new Date().toISOString());
  const DATA_ATUAL_ISO = new Date();
  const ANO_ATUAL = new Date().getFullYear() + 1;

  const { addToast } = useToast();

  const history = useHistory();
  const { id, fromSMCadastro, isCarreta }: any = useParams();

  const [model, setModel] = useState<VeiculoCadastro>({
    id: 0,
    placa: '',
    modelo: '',
    proprietarioId: 0,
    veiculoMarca: 0,
    renavam: '',
    chassi: '',
    cor: 0,
    veiculoTipo: 0,
    tipoFrota: '',
    frota: '',
    anttRntrc: 0,
    cubagem: 0,
    modeloAno: 0,
    fabricacaoAno: 0,
    tecnologiaRastreamento: undefined,
    comunicacao: null,
    numeroEquipamento: '',
    emplacamentoPais: null,
    emplacamentoCidade: null,
    emplacamentoUf: null,
    tipoFaturamento: '',
    obs: '',
    bloqueado: false,
    carroceriaTipo: 0,
    dataUltimaAlteracao: dataConverter(new Date().toISOString()),
    qtdeViagens: 0,
    cadastro: dataConverter(new Date().toISOString()),
    valorMedioTransportado: 0,
    tempoCadastro: '',
    mapa: false,
    atualizacaoAutomatica: false,
    ativo: true,
    veiculoMarcaNome: '',
    veiculoTipoNome: '',
    tecnologiaRastreamentoNome: '',
  });

  useEffect(() => {
    axios
      .get(`/cliente/getParticularidadesCliente?cliente=${clienteId}`)
      .then((res: any) => {
        setFlagHabilitarPesquisaAnttRntrc(res.data.flagHabilitarPesquisaAnttRntrc);
      })
      .catch(() => {
        setFlagHabilitarPesquisaAnttRntrc(false);
      });
  }, [clienteId]);

  useEffect(() => {
    rolePermission(
      [{ ADMIN: [ADMIN.CADASTRO.VEICULO], TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.VEICULO] }],
      'editar'
    );
    rolePermission([{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CARRETA] }], 'editar');
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CARRETA], ADMIN: [ADMIN.CADASTRO.CARRETA] }],
      'editar'
    );

    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.CARRETA], ADMIN: ADMIN.CADASTRO.CARRETA }],
      'excluir'
    );
    rolePermission(
      [{ ADMIN: [ADMIN.CADASTRO.VEICULO], TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.VEICULO] }],
      'excluir'
    );
  }, []);

  useEffect(() => {
    if (id) findVei(id);
    loadProprietarios();
    loadCor();
    loadVeiculoMarca();
    loadVeiculoTipo();
    loadVeiculoTr();
  }, [id]);

  useEffect(() => {
    const loadVeiculos = async () => {
      if (isCarreta) {
        try {
          const response = await axios.get(
            '/veiculo/listarTodosVeiculosPorCliente?cliente=' + clienteId
          );
          setCarretasCliente(response.data);
        } catch (err: any) {
          console.log(err);
        }
      } else {
        try {
          const response = await axios.get(
            '/veiculo/listarTodosVeiculosPorCliente?cliente=' + clienteId
          );
          setVeiculosCliente(response.data);
        } catch (err: any) {
          console.log(err);
        }
      }
    };
    loadVeiculos();
  }, []);

  useEffect(() => {
    const loadEstadosPorPais = async () => {
      if (model.emplacamentoPais) {
        try {
          const response = await axios.get(`/uf/ListarIdSiglaEstadoPais/${model.emplacamentoPais}`);
          setUfSigla(response.data);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    loadEstadosPorPais();
  }, [model.emplacamentoPais]);

  useEffect(() => {
    const loadMunicipiosPorEstado = async () => {
      if (model.emplacamentoUf) {
        try {
          const response = await axios.get(`/municipio-ibge/listarPorUf/${model.emplacamentoUf}`);
          setMunicipiosPorEstado(response.data);
        } catch (err: any) {
          console.log(err.response);
        }
      }
    };
    loadMunicipiosPorEstado();
  }, [model.emplacamentoUf]);

  const verifyIfPlacaIsRegistered = (value: string) => {
    if (isCarreta) {
      if (value.toUpperCase() === previousPlaca.toUpperCase()) {
        setIsPlacaRegistered(false);
        return false;
      } else if (carretasCliente.map((each) => each.placa).includes(value.toUpperCase())) {
        setIsPlacaRegistered(true);
        return true;
      } else {
        setIsPlacaRegistered(false);
        return false;
      }
    } else {
      if (value.toUpperCase() === previousPlaca.toUpperCase()) {
        setIsPlacaRegistered(false);
        return false;
      } else if (veiculosCliente.map((each) => each.placa).includes(value.toUpperCase())) {
        setIsPlacaRegistered(true);
        return true;
      } else {
        setIsPlacaRegistered(false);
        return false;
      }
    }
  };

  useEffect(() => {
    if (!model.tecnologiaRastreamento) {
      setModel({ ...model, numeroEquipamento: '' });
      setNumRastExists(false);
      return;
    }
    if (!model.numeroEquipamento.length) {
      setNumRastExists(false);
    } else {
      verifyIfNumeroEquipExists(model.numeroEquipamento);
    }
  }, [model.tecnologiaRastreamento, model.numeroEquipamento]);

  const verifyIfNumeroEquipExists = useCallback(
    (value: string) => {
      const filtered = veiculosCliente.filter(
        (each) => each.tecnologiaRastreamento === model.tecnologiaRastreamento
      );
      if (filtered.find((each) => each.numeroEquipamento === value)) {
        setNumRastExists(true);
      } else {
        setNumRastExists(false);
      }
    },
    [model.tecnologiaRastreamento, veiculosCliente]
  );

  const getVeiculoPorPlaca = useCallback(async () => {
    try {
      const response = await axios.get(
        `/veiculo/veiculo-por-placa-nao-cliente?idCliente=${clienteId}&placa=${model.placa}`
      );
      if (!verifyIfPlacaIsRegistered(model.placa) && !id) {
        setModel(response.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [clienteId, model.placa, isPlacaRegistered]);

  useEffect(() => {
    if (isPlacaValid) {
      getVeiculoPorPlaca();
    }
  }, [isPlacaValid, isPlacaRegistered]);

  const handlePlacaFocusOut = (placa: string) => {
    if (placa) {
      if (placa.length < 7) setIsPlacaValid(false);

      if (validarPlaca(placa)) {
        setIsPlacaValid(true);
      } else {
        setIsPlacaValid(false);
      }

      verifyIfPlacaIsRegistered(placa);
    }
  };

  const location: { state: number } = useLocation();
  const { state } = location;

  async function loadProprietarios() {
    if (!clienteId) {
      const response = await axios.get(`/proprietario/cliente/${state}?qtdRegistros=99999`);
      const sorted = response.data.sort(compare);
      setProprietario(sorted);
    } else {
      const response = await axios.get(`/proprietario/cliente/${clienteId}?qtdRegistros=99999`);
      const sorted = response.data.sort(compare);
      setProprietario(sorted);
    }
  }

  async function loadCor() {
    const response = await axios.get('/cor');
    setCores(response.data);
  }

  async function loadVeiculoMarca() {
    const response = await axios.get('/marca-veiculo');
    setMarca(response.data);
  }

  async function loadVeiculoTipo() {
    const response = await axios.get('/tipo-veiculo');
    setVeiTipo(response.data);
  }

  async function loadVeiculoTr() {
    const response = await axios.get('/tecnologia-rastreamento');
    setVeiTer(response.data);
  }

  useEffect(() => {
    const loadUf = async () => {
      const response = await axios.get('/uf');
      setUfs(response.data);
    };
    loadUf();
  }, []);

  useEffect(() => {
    const loadMunicipio = async () => {
      const response = await axios.get('/municipio-ibge');
      setMunicipios(response.data);
    };
    loadMunicipio();
  }, []);

  useEffect(() => {
    const loadPais = async () => {
      const response = await axios.get('/pais');
      setPais(response.data.filter((each: PaisType) => each.paisSigla !== 'USA'));
    };
    loadPais();
  }, []);

  useEffect(() => {
    const loadCarretaTipo = async () => {
      const response = await axios.get('/tipo-veiculo/carreta?sm=true');
      setTipoCarreta(response.data);
    };
    loadCarretaTipo();
  }, []);

  useEffect(() => {
    if (!model.emplacamentoUf || !municipios) {
      return;
    }
    const estado = ufs.filter((each) => each.id === model.emplacamentoUf);

    if (estado.length) {
      const municipio = municipios.filter(
        (each) => each.codigoMunicipioIbge === estado[0].codigoIbge
      );
      setMunicipioFilter(municipio);
    }
  }, [model, municipios]);

  useEffect(() => {
    if (!model.tecnologiaRastreamento) {
      return;
    }
    const loadDadosTecnologiaComunicacaoPorTecnologia = async () => {
      try {
        const response = await axios.get(
          `/tipo-comunicacao/listarIdNomePorTecnologia?tecnologia=${model.tecnologiaRastreamento}`
        );
        setTipoComunicacao(response.data);
      } catch (err: any) {
        console.log(err);
      }
    };
    loadDadosTecnologiaComunicacaoPorTecnologia();
  }, [model.tecnologiaRastreamento]);

  function handleTecnologia(tecnologia: ChangeEvent<HTMLInputElement>) {
    setModel((prevState) => {
      return {
        ...prevState,
        comunicacao: null,
      };
    });
    const technologyFound = veiTer.find(
      (each) => each.terCdId === parseInt(tecnologia.target.value)
    );
    if (!technologyFound) return;
    setTechnologyFound(technologyFound as TecnologiaRastreamento);
    setModel((prevState) => {
      return {
        ...prevState,
        [tecnologia.target.name]: parseInt(tecnologia.target.value),
      };
    });
  }

  function updateModel(e: ChangeEvent<HTMLInputElement>) {
    setModel((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }

  const [loadingCadastro, setLoadingCadastro] = useState(false);
  const [anttError, setAnttError] = useState('');

  useEffect(() => console.log({ anttError }), [anttError]);

  async function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();

    setLoadingCadastro(true);

    // if (
    //   flagHabilitarPesquisaAnttRntrc &&
    //   (!model.anttRntrc || !model.anttRntrc.toString().length)
    // ) {
    //   addToast({
    //     title: 'Erro!',
    //     description: 'Verifique os dados preenchidos de ANTT | RNTRC',
    //     type: 'error',
    //   });
    //   setIsFormInvalid(true);
    //   setLoadingCadastro(false);
    //   e.stopPropagation();
    //   return;
    // }

    if (model.anttRntrc && model.anttRntrc.toString().length < 9) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o número de caracteres do ANTT | RNTRC',
        type: 'error',
      });
      setIsFormInvalid(true);
      setLoadingCadastro(false);
      e.stopPropagation();
      return;
    }

    if (model.anttRntrc && model.anttRntrc.toString().length === 9) {
      const anttSplit = model.anttRntrc.toString().split('');
      const anttValidacao = anttSplit.every((char) => char === model.anttRntrc?.toString()[0]);

      if (anttValidacao) {
        addToast({
          title: 'Erro!',
          description: 'Os caracteres de ANTT | RNTRC não podem ser sequência de números iguais.',
          type: 'error',
        });
        setIsFormInvalid(true);
        setLoadingCadastro(false);
        e.stopPropagation();
        return;
      }
    }

    if (model.placa.length < 7) {
      addToast({
        title: 'Erro!',
        description: 'Verifique o número de caracteres da placa',
        type: 'error',
      });
      setIsFormInvalid(true);
      setLoadingCadastro(false);
      e.stopPropagation();
      return;
    }
    if (isPlacaRegistered) {
      addToast({
        title: 'Erro!',
        description: 'Esta placa já está registrada.',
        type: 'error',
      });
      setLoadingCadastro(false);

      return;
    }

    if (isCarreta) {
      if (flagDadosEnderecoObrigatorio === false) {
        console.log('aqui');

        if (model.renavam.length !== 11) {
          addToast({
            title: 'Erro!',
            description: 'Renavan não tem 11 dígitos.',
            type: 'error',
          });
          return;
        }

        if (model.chassi.length !== 17) {
          addToast({
            title: 'Erro!',
            description: 'Chassi não tem 17 dígitos.',
            type: 'error',
          });
          return;
        }

        if (
          !model.proprietarioId ||
          !model.placa ||
          !model.renavam ||
          !model.emplacamentoPais ||
          !model.emplacamentoUf ||
          !model.emplacamentoCidade ||
          !model.veiculoMarca ||
          !model.modelo ||
          !model.veiculoTipo ||
          !model.tipoFrota ||
          !isPlacaValid
        ) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setIsFormInvalid(true);
          setLoadingCadastro(false);

          e.stopPropagation();
          return;
        }
        if (!isValidAnoModelo || !isValidAnoFabricacao) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados de "ANO MODELO" e "ANO FABRICAÇÃO".',
            type: 'error',
          });
          setIsFormInvalid(true);
          setLoadingCadastro(false);

          e.stopPropagation();
          return;
        } else {
          try {
            if (isPlacaRegistered) {
              setLoadingCadastro(false);
              throw new Error('Placa já registrada.');
            }

            if (id !== undefined) {
              setLoadingCadastro(false);
              await axios.put(`/veiculo/${id}`, model);
            } else {
              setLoadingCadastro(false);
              await axios.post('/veiculo', model);
            }
            setLoadingCadastro(false);
            setIsDialogVisibleCadEfetuado(true);
            if (fromSMCadastro) {
              window.close();
            }
          } catch (err: any) {
            console.log(err.response.data);

            if (err.response.data.detail.includes('Veículo já cadastrado')) {
              setLoadingCadastro(false);
              addToast({
                title: 'Erro!',
                description: 'Veículo já cadastrado.',
                type: 'error',
              });
              return;
            }

            if (err.response.data.message.includes('email')) {
              setLoadingCadastro(false);

              setErrorEmailCadOk(true);
              return;
            }
            addToast({
              title: 'Erro!',
              description: 'Erro ao cadastrar!',
              type: 'error',
            });
            setLoadingCadastro(false);

            return;
          }
        }
      }

      if (flagDadosEnderecoObrigatorio === true) {
        console.log('aqui');

        if (model.renavam.length !== 11) {
          addToast({
            title: 'Erro!',
            description: 'Renavan não tem 11 dígitos.',
            type: 'error',
          });

          return;
        }

        if (model.chassi.length !== 17) {
          addToast({
            title: 'Erro!',
            description: 'Chassi não tem 17 dígitos.',
            type: 'error',
          });

          return;
        }

        if (
          !model.proprietarioId ||
          !model.placa ||
          !model.renavam ||
          !model.cor ||
          !model.veiculoMarca ||
          !model.modelo ||
          !model.veiculoTipo ||
          !model.tipoFrota ||
          !model.modeloAno ||
          !model.fabricacaoAno ||
          !model.emplacamentoPais ||
          !model.emplacamentoUf ||
          !model.emplacamentoCidade ||
          !isValidAnoModelo ||
          !isValidAnoFabricacao ||
          !isPlacaValid
        ) {
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setIsFormInvalid(true);
          setLoadingCadastro(false);
          e.stopPropagation();
          return;
        } else {
          setLoadingCadastro(true);
          try {
            if (isPlacaRegistered) {
              setLoadingCadastro(false);
              throw new Error('Placa já registrada.');
            }

            if (id !== undefined) {
              await axios.put(`/veiculo/${id}`, model);
            } else {
              await axios.post('/veiculo', model);
            }
            setLoadingCadastro(false);
            setIsDialogVisibleCadEfetuado(true);
            if (fromSMCadastro) {
              window.close();
            }
          } catch (err: any) {
            console.log('aqui');

            if (err.response.data.message.includes('email')) {
              setLoadingCadastro(false);
              setErrorEmailCadOk(true);
              return;
            }
            addToast({
              title: 'Erro!',
              description: 'Verifique os dados preenchidos.',
              type: 'error',
            });
            setLoadingCadastro(false);

            return;
          }
        }
      }
    } else {
      if (
        !id &&
        technologyFound.terTxNome !== 'TELEMONITORAMENTO' &&
        technologyFound.terTxNome !== 'NÃO RASTREADO' &&
        model.numeroEquipamento === ''
      ) {
        setLoadingCadastro(false);
        setIsFormInvalid(true);
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados de "TECNOLOGIA RASTREAMENTO".',
          type: 'error',
        });
        return;
      }

      if (model.renavam.length !== 11) {
        addToast({
          title: 'Erro!',
          description: 'Renavan não tem 11 dígitos.',
          type: 'error',
        });
        return;
      }

      if (model.chassi.length !== 17) {
        addToast({
          title: 'Erro!',
          description: 'Chassi não tem 17 dígitos.',
          type: 'error',
        });
        return;
      }

      if (
        !model.proprietarioId ||
        !model.placa ||
        !model.renavam ||
        !model.veiculoMarca ||
        !model.modelo ||
        !model.veiculoTipo ||
        !model.tipoFrota ||
        !model.tecnologiaRastreamento ||
        (!model.comunicacao &&
          Object.keys(technologyFound).length &&
          technologyFound.terTxNome !== 'TELEMONITORAMENTO' &&
          technologyFound.terTxNome !== 'NÃO RASTREADO') ||
        !isPlacaValid ||
        !model.emplacamentoPais ||
        !model.emplacamentoUf ||
        !model.emplacamentoCidade
      ) {
        console.log(isPlacaValid);

        console.log('model', model);

        addToast({
          title: 'Erro!',
          description: 'Verifique os dados preenchidos.',
          type: 'error',
        });
        setIsFormInvalid(true);
        setLoadingCadastro(false);
        e.stopPropagation();
        return;
      }
      if (!isValidAnoModelo || !isValidAnoFabricacao) {
        addToast({
          title: 'Erro!',
          description: 'Verifique os dados de "ANO MODELO" e "ANO FABRICAÇÃO".',
          type: 'error',
        });
        setIsFormInvalid(true);
        e.stopPropagation();
        return;
      } else {
        setLoadingCadastro(true);
        try {
          if (isPlacaRegistered) {
            throw new Error('Placa já registrada.');
          }

          if (id !== undefined) {
            console.log('--/--');

            await axios.put(`/veiculo/${id}`, model);
          } else {
            await axios.post('/veiculo', model);
          }
          setLoadingCadastro(false);
          setIsDialogVisibleCadEfetuado(true);
          if (fromSMCadastro) {
            window.close();
          }
        } catch (err: any) {
          if (err.response.data.message.includes('email')) {
            setLoadingCadastro(false);
            setErrorEmailCadOk(true);
            return;
          }
          addToast({
            title: 'Erro!',
            description: 'Verifique os dados preenchidos.',
            type: 'error',
          });
          setLoadingCadastro(false);
          return;
        }
      }
    }
  }

  const toggleSwitchMapa = () => {
    setMapa((previousState) => !previousState);
    setModel({
      ...model,
      mapa: !mapa,
    });
  };

  const toggleSwitchAtualizacaoAutomatica = () => {
    setModel({
      ...model,
      atualizacaoAutomatica: !model.atualizacaoAutomatica,
    });
  };

  async function findVei(id: number) {
    const response = await axios.get(`veiculo/${id}`);

    setModel({
      ...response.data,
      comunicacao: response.data.comunicacao,
      // comunicacao: parseInt(response.data.comunicacao),
      tempoCadastro: '',
      cadastro: dataConverter(response.data.cadastro),
      dataUltimaAlteracao: dataConverter(new Date().toISOString()),
    });
    setPreviousPlaca(response.data.placa);
    setIsPlacaValid(true);
  }

  const [flagDadosEnderecoObrigatorio, setFlagDadosEnderecoObrigatorio] = useState(false);
  useEffect(() => {
    const getParticularidades = async () => {
      if (clienteId === null) {
        try {
          const response = await axios.get(`/cliente/getParticularidadesCliente?cliente=${state}`);
          setFlagDadosEnderecoObrigatorio(response.data.flagDadosEnderecoObrigatorio);
        } catch (err: any) {
          console.log(err);
        }
      } else {
        try {
          const response = await axios.get(
            `/cliente/getParticularidadesCliente?cliente=${clienteId}`
          );
          setFlagDadosEnderecoObrigatorio(response.data.flagDadosEnderecoObrigatorio);
        } catch (err: any) {
          console.log(err);
        }
      }
    };
    getParticularidades();
  }, [clienteId, state]);

  const [isDialogVisibleRecuperaDados, setIsDialogVisibleRecuperaDados] = useState(false);

  async function handlePlacaInativa(placa: string) {
    if (!placa.length) return;

    const response = await axios.get(`/veiculo/reutilizarVeiculoPorClientePlaca`, {
      params: {
        idCliente: clienteId,
        placa,
      },
    });

    if (response.data) {
      setIsDialogVisibleRecuperaDados(true);
    }
  }

  async function handleRecuperarPorPlaca(placa: string) {
    const response = await axios.get(`/veiculo/reutilizarVeiculoPorClientePlaca`, {
      params: {
        idCliente: clienteId,
        placa,
      },
    });

    if (isCarreta) {
      const novoCaminho = '/cadastro/carretas/atualizar/true/' + response.data.id;

      history.push(novoCaminho);
    } else {
      history.push(`atualizarveiculo/${response.data.id}`);
    }

    setModel({
      ...response.data,
      ativo: true,
    });
  }

  const [errorChecklistPending, setErrorChecklistPending] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function deleteFunc(id: number) {
    try {
      setShow(false);
      if (errorChecklistPending) {
        await axios.delete(`/veiculo/${id}`, {
          params: { ValidarStatus: false },
        });
      } else {
        await axios.delete(`/veiculo/${id}`);
      }
      addToast({
        title: 'Sucesso!',
        description: 'Veículo desativado com sucesso.',
        type: 'success',
      });

      if (isCarreta) {
        history.push('/cadastro/carretas/1');
        return;
      }
      setErrorChecklistPending('');

      handleClose();
      history.push('/cadastro/veiculo');
    } catch (err: any) {
      console.log(err.response.data);
      if (typeof err.response.data === 'string') {
        setErrorChecklistPending(err.response.data);
        setShow(true);
      }
      addToast({
        title: 'Erro!',
        description: 'Erro ao Desativar Cadastro.',
        type: 'error',
      });
    }
  }

  function validaAnoModelo(modeloAno: any) {
    if (
      (modeloAno != undefined && modeloAno != 0 && modeloAno < 1960) ||
      (modeloAno != undefined && modeloAno != 0 && modeloAno > ANO_ATUAL)
    ) {
      setIsValidAnoModelo(false);
    } else {
      setIsValidAnoModelo(true);
    }
  }

  function validaAnoFabricacao(fabricacaoAno: any) {
    if (
      (fabricacaoAno != undefined && fabricacaoAno != 0 && fabricacaoAno < 1960) ||
      (fabricacaoAno != undefined && fabricacaoAno != 0 && fabricacaoAno > ANO_ATUAL)
    ) {
      setIsValidAnoFabricacao(false);
    } else {
      setIsValidAnoFabricacao(true);
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          {errorChecklistPending ? (
            <>
              <p>
                Deseja realmente excluir o registro de placa "{model.placa}" mesmo com essa(s)
                pendência(s)?
              </p>
              <p
                style={{
                  color: '#7c7c7c',
                }}
              >
                {errorChecklistPending}
              </p>
            </>
          ) : (
            <p>Deseja realmente excluir o registro de placa "{model.placa}"?</p>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'nowrap',
          }}
        >
          <Button
            style={{ backgroundColor: '#928f96', borderColor: 'transparent' }}
            onClick={handleClose}
          >
            Cancelar
          </Button>
          <Button
            style={{
              backgroundColor: '#FFDA53',
              borderColor: 'transparent',
              color: '#000',
            }}
            onClick={() => deleteFunc(model.id)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      {id ? (
        <Dialog
          header={
            isCarreta ? 'Carreta foi editada com sucesso!' : 'Veículo foi editado com sucesso!'
          }
          footer={
            <>
              <Button label="OK" onClick={() => setIsDialogVisibleCadEfetuado(false)} />
            </>
          }
          visible={isDialogVisibleCadEfetuado}
          style={{ width: '50vw' }}
          modal
          onHide={() => history.goBack()}
        />
      ) : (
        <Dialog
          header={
            isCarreta
              ? 'Carreta foi cadastrada com sucesso!'
              : 'Veículo foi cadastrado com sucesso!'
          }
          footer={
            <>
              <Button label="OK" onClick={() => setIsDialogVisibleCadEfetuado(false)} />
            </>
          }
          visible={isDialogVisibleCadEfetuado}
          style={{ width: '50vw' }}
          modal
          onHide={() => history.goBack()}
        />
      )}

      <Dialog
        header="Cadastro realizado com sucesso!"
        footer={<Button onClick={() => setIsDialogVisibleMsgEmail(true)}>OK</Button>}
        visible={errorEmailCadOk}
        style={{ width: '50vw' }}
        modal
        onHide={() => setErrorEmailCadOk(false)}
      />

      <Dialog
        header="Não foi possível enviar o email. Verifique o email cadastrado."
        footer={
          <Button
            onClick={() => {
              setIsDialogVisibleMsgEmail(false);
              history.goBack();
            }}
          >
            OK
          </Button>
        }
        visible={isDialogVisibleMsgEmail}
        style={{ width: '50vw' }}
        modal
        onHide={() => setIsDialogVisibleMsgEmail(false)}
      />

      <div className="card">
        {isCarreta ? (
          <div className="titulo-container">
            <h2 className="titulo">Carretas</h2>
            <h6 className="subtitulo">{'Cadastro > Carretas'}</h6>
            {id && (
              <button
                className="btn-desativar"
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  position: 'absolute',
                  right: '2%',
                }}
                onClick={() => {
                  canDelete ? handleShow() : history.push('/acessonegado');
                }}
              >
                <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash"></i>
              </button>
            )}
          </div>
        ) : (
          <div className="titulo-container">
            <h2 className="titulo">Veículos</h2>
            <h6 className="subtitulo">{'Cadastro > Veiculos'}</h6>
            {id && (
              <button
                className="btn-desativar"
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'transparent',
                  position: 'absolute',
                  right: '2%',
                }}
                onClick={() => {
                  canDelete ? handleShow() : history.push('/acessonegado');
                }}
              >
                <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash"></i>
              </button>
            )}
          </div>
        )}

        <h1 className="info-obrigatoria">*Informações obrigatórias</h1>

        <Dialog
          header="Este veículo foi excluído anteriormente. Tem certeza que deseja reativar o cadastro dele?"
          footer={
            <>
              <Button label="Não" onClick={() => setIsDialogVisibleRecuperaDados(false)} />
              <Button
                label="Sim"
                onClick={() => {
                  setIsDialogVisibleRecuperaDados(false);
                  handleRecuperarPorPlaca(model.placa);
                }}
              />
            </>
          }
          visible={isDialogVisibleRecuperaDados}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleRecuperaDados(false)}
        />

        <div className="painel">
          <Form
            className="form-sm"
            onSubmit={onSubmit}
            noValidate
            validated={isFormInvalid}
            style={{ marginTop: '0px' }}
          >
            {isAllowedByRole(['admin']) && (
              <Form>
                <Form.Row style={{ width: '50%' }}>
                  <Form.Group as={Col}>
                    <Form.Label>FATURAR COMO</Form.Label>
                    <Form.Control
                      as="select"
                      name="faturarComo"
                      placeholder="faturarComo"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setModel({
                          ...model,
                          tipoFaturamento: e.target.value,
                        });
                      }}
                      value={model.tipoFaturamento.toUpperCase()}
                      style={{ width: '50%', height: '50%', fontSize: '12px' }}
                    >
                      <option value="NAO INFORMADO">Não Informado</option>
                      <option value="MENSALISTA">Mensalista</option>
                      <option value="VIAGEM AVULSA">Viagem avulsa</option>
                      <option value="NAO RASTREADO">Não Rastreado</option>
                      <option value="DESABILITADO">Desabilitado</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group className="mt-4">
                    <Form.Check
                      checked={model.bloqueado}
                      onChange={(event) => {
                        setModel({
                          ...model,
                          bloqueado: !model.bloqueado,
                        });
                      }}
                      name="bloqueado"
                      type="switch"
                      id="custom-switch2"
                      label={isCarreta ? 'Carreta Bloqueada' : 'Veículo Bloqueado'}
                    />
                  </Form.Group>
                </Form.Row>
              </Form>
            )}

            <Form.Group className="group-swicth">
              <Switch
                value={Number(model.atualizacaoAutomatica)}
                checked={model.atualizacaoAutomatica}
                onChange={toggleSwitchAtualizacaoAutomatica}
                name="atualizacaoAutomatica"
                id="pesquisa"
                type="switch"
                label="Atualizar pesquisa automaticamente"
              />
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="require">PROPRIETÁRIO</Form.Label>
                <Form.Control
                  as="select"
                  name="proprietarioId"
                  placeholder="Proprietario"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: parseInt(e.target.value),
                    });
                  }}
                  required
                  value={model.proprietarioId}
                >
                  <option value="">Selecione uma opção</option>
                  {proprietario.map((proprietario, index) => (
                    <option key={index} value={proprietario.id}>
                      {proprietario.razaoSocial}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridPlaca">
                <Form.Label className="require">PLACA</Form.Label>
                <Form.Control
                  value={model.placa}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (validarPlaca(e.target.value) || e.target.value.length == 0) {
                      updateModel(e);
                    }

                    if (e.target.value.length === 7) {
                      if (validarPlaca(e.target.value) === true) {
                        updateModel(e);
                        setIsPlacaValid(true);
                      }
                    }
                  }}
                  onBlur={() => {
                    handlePlacaFocusOut(model.placa);
                    handlePlacaInativa(model.placa);
                  }}
                  maxLength={7}
                  minLength={7}
                  pattern="[A-Z]{3}[0-9][0-9A-Z][0-9]{2}"
                  name="placa"
                  type="text"
                  isInvalid={isPlacaRegistered}
                  placeholder="LLLNNNN ou LLLNLNN"
                  required
                  title="Formato do campo deve ser LLLNNNN ou LLLNLNN "
                  disabled={id}
                />
                {!id && isPlacaRegistered && model.placa.length > 0 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Esta placa já foi registrada.
                  </p>
                )}
                {!isPlacaValid && model.placa.length > 0 && model.placa.length < 7 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Esta placa é inválida.
                  </p>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridRenavam">
                <Form.Label className="require">RENAVAM</Form.Label>
                <Form.Control
                  value={model.renavam}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    if (validaNumero(e.target.value) || e.target.value.length == 0) {
                      updateModel(e);
                    }
                  }}
                  name="renavam"
                  type="text"
                  minLength={11}
                  maxLength={11}
                  pattern="[0-9]+$"
                  required
                />{' '}
                {model.renavam.length < 11 && model.renavam.length > 0 && (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Renavam inválido, deve conter 11 dígitos.
                  </p>
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridChassi">
                <Form.Label className="require">CHASSI</Form.Label>
                <Form.Control
                  value={model.chassi}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  name="chassi"
                  type="text"
                  placeholder=""
                  maxLength={17}
                  minLength={17}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  Chassi deve ter 17 dígitos (números/letras)
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="formGridCor" required>
                <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                  COR
                </Form.Label>
                <Form.Control
                  as="select"
                  placeholder="Cor"
                  name="cor"
                  value={model.cor}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: parseInt(e.target.value),
                    });
                  }}
                  required={flagDadosEnderecoObrigatorio}
                >
                  <option value="">Selecione uma opção</option>
                  {cores.map((cor, index) => (
                    <option aria-required={true} key={index} value={cor.id}>
                      {cor.corNome}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridMarca">
                <Form.Label className="require">MARCA</Form.Label>
                <Form.Control
                  as="select"
                  name="veiculoMarca"
                  value={model.veiculoMarca}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    marca.map((marca) => {
                      if (marca.vmaCdId === parseInt(e.target.value)) {
                        setModel({
                          ...model,
                          veiculoMarcaNome: marca.vmaTxNome,
                        });
                      }
                    });
                    setModel({
                      ...model,
                      [e.target.name]: parseInt(e.target.value),
                    });
                  }}
                  required
                >
                  <option value="">Selecione uma opção</option>
                  {marca.map((marca, index) => (
                    <option key={index} value={marca.vmaCdId}>
                      {marca.vmaTxNome}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridModelo">
                <Form.Label className="require">MODELO</Form.Label>
                <Form.Control
                  value={model.modelo}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  name="modelo"
                  maxLength={64}
                  type="text"
                  placeholder=""
                  required
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              {isCarreta ? (
                <Form.Group as={Col} controlId="formGridTipo">
                  <Form.Label className="require">TIPO DE VEICULO</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Tipo de Veiculo"
                    name="veiculoTipo"
                    value={model.veiculoTipo}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      tipoCarreta.map((tipoCarreta) => {
                        if (tipoCarreta.vtpCdId === parseInt(e.target.value)) {
                          setModel({
                            ...model,
                            veiculoTipoNome: tipoCarreta.vtpTxNome,
                          });
                        }
                      });
                      setModel({
                        ...model,
                        [e.target.name]: parseInt(e.target.value),
                      });
                    }}
                    required
                  >
                    <option value="">Selecione uma opção</option>
                    {tipoCarreta.map((carreta, index) => (
                      <option key={index} value={carreta.vtpCdId}>
                        {carreta.vtpTxNome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              ) : (
                <Form.Group as={Col} controlId="formGridTipo">
                  <Form.Label className="require">TIPO DE VEICULO</Form.Label>
                  <Form.Control
                    as="select"
                    placeholder="Tipo de Veiculo"
                    name="veiculoTipo"
                    value={model.veiculoTipo}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      veiTipo.map((tipo) => {
                        if (tipo.vtpCdId === parseInt(e.target.value)) {
                          setModel({
                            ...model,
                            veiculoTipoNome: tipo.vtpTxNome,
                          });
                        }
                      });
                      setModel({
                        ...model,
                        [e.target.name]: parseInt(e.target.value),
                      });
                    }}
                    required
                  >
                    <option value="">Selecione uma opção</option>
                    {veiTipo.map((veiculo, index) => (
                      <option key={index} value={veiculo.vtpCdId}>
                        {veiculo.vtpTxNome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}

              <Form.Group as={Col} controlId="formGridFrota">
                <Form.Label className="require">TIPO FROTA</Form.Label>
                <Form.Control
                  value={model.tipoFrota}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  name="tipoFrota"
                  as="select"
                  placeholder=""
                  required
                >
                  <option value="">Selecione uma opção</option>
                  <option value="1">PRÓPRIO</option>
                  <option value="2">AGREGADO</option>
                  <option value="3">TERCEIRO</option>
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridAntt">
                <Form.Label>ANTT \ RNTRC</Form.Label>
                <Form.Control
                  value={model.anttRntrc === 0 ? '' : model.anttRntrc}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setAnttError('');

                    if (validaNumero(e.target.value) || e.target.value.length == 0) {
                      updateModel(e);
                    }
                  }}
                  onBlur={() => {
                    // if(!model.anttRntrc || !model.anttRntrc.toString().length) {
                    //   setAnttError('    ')
                    //   return
                    // }

                    if (model.anttRntrc && model.anttRntrc.toString().length < 9) {
                      setAnttError('O campo precisa ter 9 digitos.');
                      return;
                    }

                    if (model.anttRntrc && model.anttRntrc.toString().length === 9) {
                      const anttSplit = model.anttRntrc.toString().split('');
                      const anttValidacao = anttSplit.every(
                        (char) => char === model.anttRntrc?.toString()[0]
                      );

                      if (anttValidacao) {
                        setAnttError('O campo não pode conter digitos repetidos.');
                        return;
                      }
                    }
                  }}
                  name="anttRntrc"
                  type="text"
                  placeholder=""
                  maxLength={9}
                  pattern="^(?!(\d)\1{8}$)\d{9}$"
                  title="Digite apenas numeros"
                  // isInvalid={anttError.length ? true : false} // Anderson pediu para deixar igual ao GR, dito isso esse campo não é obrigatório
                  // required={flagHabilitarPesquisaAnttRntrc}
                />
                {anttError.length ? (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>{anttError}</p>
                ) : (
                  ''
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCubagem">
                <Form.Label>CUBAGEM</Form.Label>
                <Form.Control
                  value={model.cubagem === 0 ? '' : model.cubagem}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: soNumeros(e.target.value),
                    });
                  }}
                  name="cubagem"
                  maxLength={4}
                  minLength={1}
                  type="text"
                  placeholder=""
                  title="Este campo aceita apenas numeros"
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridAno" required>
                <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                  ANO MODELO
                </Form.Label>
                <Form.Control
                  value={model.modeloAno === 0 ? undefined : model.modeloAno}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]:
                        e.target.value.length === 0 ? undefined : soNumeros(e.target.value),
                    });
                  }}
                  onBlur={() => validaAnoModelo(model.modeloAno)}
                  placeholder=""
                  data-mask="0000"
                  maxLength={4}
                  minLength={4}
                  name="modeloAno"
                  pattern="[0-9]+$"
                  type="text"
                  required={flagDadosEnderecoObrigatorio}
                  title="Este campo aceita apenas numeros"
                />{' '}
                {!isValidAnoModelo ? (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Ano inválido, favor escolher um ano entre 1960 e {ANO_ATUAL}.
                  </p>
                ) : (
                  ''
                )}
              </Form.Group>

              <Form.Group as={Col} controlId="formGridFabricacao">
                <Form.Label className={flagDadosEnderecoObrigatorio === true ? 'require' : ''}>
                  ANO FABRICAÇÃO
                </Form.Label>
                <Form.Control
                  value={model.fabricacaoAno === 0 ? undefined : model.fabricacaoAno}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: e.target.value.length === 0 ? 0 : soNumeros(e.target.value),
                    });
                  }}
                  onBlur={() => validaAnoFabricacao(model.fabricacaoAno)}
                  placeholder=""
                  data-mask="0000"
                  min={1960}
                  max={ANO_ATUAL}
                  maxLength={4}
                  minLength={4}
                  name="fabricacaoAno"
                  pattern="[0-9]+$"
                  type="text"
                  required={flagDadosEnderecoObrigatorio}
                  title="Este campo aceita apenas numeros"
                />{' '}
                {!isValidAnoFabricacao ? (
                  <p style={{ fontSize: '11px', color: 'red', marginTop: '5px' }}>
                    Ano inválido, favor escolher um ano entre 1960 e {ANO_ATUAL}.
                  </p>
                ) : (
                  ''
                )}
              </Form.Group>
            </Form.Row>

            {isCarreta ? (
              <></>
            ) : (
              <Form.Row>
                <Form.Group as={Col} controlId="formGridTecnologia">
                  <Form.Label className="require">TECNOLOGIA RASTREAMENTO</Form.Label>
                  <Form.Control
                    value={model.tecnologiaRastreamento!}
                    as="select"
                    name="tecnologiaRastreamento"
                    // disabled={role === 'user' && id} // Remoção do disabled a pedido do Anderson
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleTecnologia(e)}
                    required
                  >
                    <option value="">Selecione uma opção</option>
                    {veiTer.map((veiTer, index) => (
                      <option key={index} value={veiTer.terCdId}>
                        {veiTer.terTxNome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridComunicacao">
                  <Form.Label
                    className={
                      (id && model.tecnologiaRastreamentoNome === 'TELEMONITORAMENTO') ||
                      model.tecnologiaRastreamentoNome === 'NÃO RASTREADO' ||
                      technologyFound.terTxNome === 'TELEMONITORAMENTO' ||
                      technologyFound.terTxNome === 'NÃO RASTREADO'
                        ? ''
                        : 'required'
                    }
                  >
                    TIPO COMUNICAÇÃO
                  </Form.Label>
                  <Form.Control
                    value={model.comunicacao ? model.comunicacao! : ''}
                    as="select"
                    // disabled={role === 'user' && id} // Remoção do disabled a pedido do Anderson
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setModel((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    name="comunicacao"
                    required={
                      (id &&
                        model.tecnologiaRastreamentoNome !== 'TELEMONITORAMENTO' &&
                        model.tecnologiaRastreamentoNome !== 'NÃO RASTREADO') ||
                      (technologyFound.terTxNome !== 'TELEMONITORAMENTO' &&
                        technologyFound.terTxNome !== 'NÃO RASTREADO')
                    }
                  >
                    <option value="">Selecione uma opção {console.log({ tipoComunicacao })}</option>

                    {tipoComunicacao.map((ttc, index) => (
                      <option key={index} value={ttc.nome}>
                        {ttc.nome}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group
                  as={Col}
                  controlId="formGridEqpto"
                  style={numRastExists ? { marginBottom: 0 } : null}
                >
                  <Form.Label
                    className={
                      (id && model.tecnologiaRastreamentoNome === 'TELEMONITORAMENTO') ||
                      model.tecnologiaRastreamentoNome === 'NÃO RASTREADO' ||
                      technologyFound.terTxNome === 'TELEMONITORAMENTO' ||
                      technologyFound.terTxNome === 'NÃO RASTREADO'
                        ? ''
                        : 'required'
                    }
                  >
                    Nº EQUIPAMENTO
                  </Form.Label>
                  <Form.Control
                    value={model.numeroEquipamento}
                    // disabled={(role === 'user' && id) || !model.tecnologiaRastreamento} // Remoção do disabled (modificação) a pedido do Anderson
                    disabled={!model.tecnologiaRastreamento}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setModel({
                        ...model,
                        [e.target.name]: soNumeros(e.target.value),
                      });
                    }}
                    name="numeroEquipamento"
                    required={
                      (id &&
                        model.tecnologiaRastreamentoNome !== 'TELEMONITORAMENTO' &&
                        model.tecnologiaRastreamentoNome !== 'NÃO RASTREADO') ||
                      (technologyFound.terTxNome !== 'TELEMONITORAMENTO' &&
                        technologyFound.terTxNome !== 'NÃO RASTREADO')
                    }
                    type="text"
                    maxLength={32}
                    placeholder=""
                    title="Este campo aceita apenas numeros"
                  />
                  {numRastExists && !id && (
                    <p
                      style={{
                        fontSize: '11px',
                        color: 'red',
                        marginTop: '5px',
                        marginBottom: 0,
                      }}
                    >
                      Este número de equipamento já foi registrado para essa tecnologia.
                    </p>
                  )}
                </Form.Group>
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="require">PAÍS EMPLACAMENTO</Form.Label>
                <Form.Control
                  value={!model.emplacamentoPais ? '' : model.emplacamentoPais}
                  as="select"
                  name="emplacamentoPais"
                  required
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel({
                      ...model,
                      [e.target.name]: parseInt(e.target.value ?? null),
                    });
                  }}
                >
                  <option value="">Selecione uma opção</option>
                  {pais.map((pai, index) => (
                    <option key={index} value={pai.id}>
                      {pai.paisDescricao}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridUF">
                <Form.Label className="require">UF EMPLACAMENTO</Form.Label>
                <Form.Control
                  as="select"
                  name="emplacamentoUf"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setModel((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: parseInt(e.target.value, 10 ?? null),
                      };
                    });
                  }}
                  required
                  value={!model.emplacamentoUf ? '' : model.emplacamentoUf}
                >
                  <option value="">Selecione uma opção</option>
                  {ufSigla.map((uf, index) => (
                    <option key={index} value={uf.id}>
                      {uf.sigla}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} controlId="formGridCidade">
                <Form.Label className="require">CIDADE EMPLACAMENTO</Form.Label>
                <Form.Control
                  as="select"
                  disabled={municipioFilter ? false : true}
                  name="emplacamentoCidade"
                  value={!model.emplacamentoCidade ? '' : model.emplacamentoCidade}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setModel((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: parseInt(e.target.value, 10 ?? null),
                      };
                    })
                  }
                  required
                >
                  <option value="">Selecione uma opção</option>
                  {municipiosPorEstado.map((municipio, index) => (
                    <option key={index} value={municipio.id}>
                      {municipio.nomeMunicipio}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId="formGridObs">
                <Form.Label>OBSERVAÇÕES</Form.Label>
                <Form.Control
                  value={model.obs}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  name="obs"
                  maxLength={256}
                  as="textarea"
                  placeholder="Observação"
                />
              </Form.Group>

              <Form.Group as={Col} controlId="formGridData">
                <Form.Label>DATA DE CADASTRO</Form.Label>
                <Form.Control
                  value={model.cadastro ? obterDataHora(model.cadastro) : obterDataHora(DATA_ATUAL)}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => updateModel(e)}
                  name="cadastro"
                  type="text"
                  disabled
                />
              </Form.Group>
            </Form.Row>

            <Form.Group className="group-swicth">
              <Switch
                checked={model.mapa}
                onChange={toggleSwitchMapa}
                value={Number(mapa)}
                name="mapa"
                id="mapa"
                type="switch"
                label="Exibir no mapa"
              />
            </Form.Group>

            {loadingCadastro ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <ButtonsForm canEdit={canEdit} />
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CadastroVeiculo;
