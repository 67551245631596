export const OPTIONS_LEGAL_CONTACT = [
  {
    label: 'Sim',
    value: true,
  },
  {
    label: 'Não',
    value: false,
  },
];
