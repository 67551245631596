/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/Toast';
import api from '../../../config/axiosMaquina';
import { Tela } from '../../../components/Tela';
import { PrimaryButton } from '../../../components/ButtonsForm/styles';
import { ButtonTooltip } from '../../../components/ButtonTooltipo';
import { cpfMask } from '../../../util/Validacoes/Validacoes';

export const MacrosJornada = () => {
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const { addToast } = useToast();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = (searchTerm.trim().length ? searchResults : data)?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );

  const paginate = (pageNumber: number) => {
    history.push(`/listar/macros-jornada/${pageNumber}`);
  };

  const fetchData = async () => {
    try {
      const response = await api.get('/jornada-de-trabalho');
      setData(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const d = [];
    for (let i = 0; i < 15; i += 1) {
      d.push({
        id: i + 1,
        cpf: '06430990770',
        nome: 'Brayan Cataldo',
        diasContinuos: i + 1,
        inicioJornada: '2022-01-25T12:25:47.391Z',
        tempoRefeicao: '08:01:37',
        tempoRepouso: '05:26:37',
        tempoDescanso: '04:14:37',
        tempoEspera: '05:31:13',
        finalJornada: '2022-01-25T12:25:47.391Z',
      });
    }
    setData(d);
  }, []);

  const returnColor = (time: string, limit: number, limitMinutes?: number) => {
    if (!time) return {};
    const hour = parseInt(time.split(':')[0], 10);
    const minutes = parseInt(time.split(':')[1].split(':')[0], 10);
    console.log(hour, minutes);
    if (limitMinutes) {
      if (hour > limit || (hour === limit && minutes >= limitMinutes)) {
        return { color: 'red' };
      }
    } else if (hour >= limit) {
      return { color: 'red' };
    }
    return { color: 'green' };
  };

  const handleEditar = (each: any) => {
    history.push({ pathname: `/editar/macros-jornada/${each.id}`, state: each });
  };

  return (
    <Tela
      nome="Controle de Jornada"
      caminho="Rastreamento > Controle de Jornada"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      linkToAdd="/cliente/presm/agendar-viagens"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm ? searchResults?.length : data?.length}
      paginate={paginate}
      currentPage={page}
    >
      <div className="table-responsive" style={{ flex: 1 }}>
        <table className="table">
          <thead className="thead">
            <tr>
              <th>CPF</th>
              <th>Nome</th>
              <th>Dias Contínuos</th>
              <th>Início da Jornada</th>
              <th>Tempo de Refeição</th>
              <th>Tempo de Repouso</th>
              <th>Tempo de Descanso</th>
              <th>Tempo de Espera</th>
              <th>Final da Jornada</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {currentData &&
              currentData.map((each: any) => (
                <tr>
                  <td>{cpfMask(each.cpf)}</td>
                  <td>{each.nome}</td>
                  <td>{each.diasContinuos}</td>
                  <td>{each.inicioJornada}</td>
                  <td style={returnColor(each.tempoRefeicao, 8)}>{each.tempoRefeicao}</td>
                  <td style={returnColor(each.tempoRepouso, 5, 30)}>{each.tempoRepouso}</td>
                  <td>{each.tempoDescanso}</td>
                  <td style={returnColor(each.tempoEspera, 11)}>{each.tempoEspera}</td>
                  <td>{each.finalJornada}</td>
                  <td>
                    <ButtonTooltip onClick={() => handleEditar(each)} type="Editar" />
                    <ButtonTooltip onClick={() => null} type="Visualizar" />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Tela>
  );
};
