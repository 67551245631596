/* eslint-disable */
import React, { useState, useEffect } from 'react';
import MenuLateral from '../../../../components/MenuLateral/MenuLateral';
import { Modal, Spinner } from 'react-bootstrap';
import Pagination from '../GridPesquisaCadastral/Pagination/Pagination'
import { Button } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import instanceMaquina from '../../../../config/axiosMaquina';
import { useToast } from '../../../../hooks/Toast';
import api from '../../../../config/axiosMaquina';
import { handleResultado } from '../../../../util/validaResultadoPesquisa';
import { PERMISSION } from '../../../../constants/Permissionamento';
import { useRolePermission } from '../../../../context/RolePermissionAuth';

const GridPesquisaCadastral = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const [totalItems, setTotalItems] = useState(10)
  const [refresh, setRefresh] = useState(0);
  const [refreshitensPerPage, setRefreshitensPerPage] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [registros, setRegistros] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [idRegistro, setIdRegistro] = useState<number>(0);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastPost = currentPage * parseInt(itensPerPage);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage);

  const [show, setShow] = useState<boolean>(false);
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);
  const { addToast } = useToast();
  const history = useHistory();

  const handleRequest = async ({ pagina, keyword}: any) => {
    setLoading(true);
    setCurrentPage(pagina)
    try {
      const response = await api.get('/pesquisa/lista-pesquisa-manual', {
        params: {
          pagina,
          qtdRegistros: Number(itensPerPage),
          ...(keyword && { keyword })
        }
      });
      setRegistros(response.data.items);
      setTotalItems(response.data.count);
      setLastPage(response.data.pages)
    } catch (error: any) {
      console.log(error);
    } finally {
      setRefresh(0)
      setRefreshitensPerPage(false)
    }
    setLoading(false);
  };

  useEffect(() => {
    rolePermission(
      [{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.LANCAMENTO] }],
      'excluir'
    );
    rolePermission(
      [{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.LANCAMENTO] }],
      'inserir'
    );
    rolePermission(
      [{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.ANALISE_PERFIL.LANCAMENTO] }],
      'editar'
    );
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    handleRequest({ pagina: 1 });
  }, []);

  useEffect(() => {
    if (!refresh) return;

    handleRequest({  pagina: refresh, keyword: searchTerm })
  }, [refresh]);

  useEffect(() => {
    if (!refreshitensPerPage) return;
    handleRequest({ pagina: 1, keyword: searchTerm })
  }, [refreshitensPerPage]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      const fetchData = async () => {
        handleRequest({  pagina: 1, keyword: searchTerm })
      };

      fetchData();
    }, 1000);

    // eslint-disable-next-line consistent-return
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [searchTerm]);

  async function handleDelete(pesquisa: number) {
    setLoadingDelete(true);
    try {
      await instanceMaquina.put(`/pesquisa/delete-logico-pesquisa/${pesquisa}`);
      addToast({
        title: '',
        description: 'Pesquisa excluida com sucesso!',
        type: 'success',
      });
      setLoadingDelete(false);
      setShow(false);
      handleRequest({ pagina: 1 });
    } catch (alert) {
      setLoadingDelete(false);
      setShow(false);
      addToast({
        title: 'Erro',
        description: 'Erro ao Excluir Pesquisa',
        type: 'error',
      });
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          {loadingDelete ? (
            <Spinner
              animation="border"
              variant="warning"
              style={{
                display: 'flex',
                marginLeft: '47.5%',
                marginTop: '5%',
                marginBottom: '5%',
              }}
            />
          ) : (
            `Deseja realmente excluir esta pesquisa?`
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => setShow(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(idRegistro)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Painel Pesquisa Manual</h2>
          <h6 className="subtitulo">{'Análise Perfil > Lançamento'}</h6>
        </div>
        <div className="search-create mb-4">
          {canInsert ? (
            <Link to="/analise-perfil/pesquisa" className="btn-create">
              <i
                style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                className="pi pi-plus"
              ></i>
            </Link>
          ) : (
            <Link to="/acessonegado" className="btn-create">
              <i
                style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                className="pi pi-plus"
              ></i>
            </Link>
          )}
          <span className="p-input-icon-left">
            <input
              type="text"
              placeholder="Pesquise"
              className="p-inputtext p-component"
              id="search-input"
              style={{ width: '240px' }}
              value={searchTerm}
              onChange={(e: any) => setSearchTerm(e.target.value)}
            />
            <i className="pi pi-search"></i>
          </span>
        </div>
        <div className="painel">
          <div className="table-responsive" style={{ flex: 1 }}>
            {loading ? (
              <Spinner
                animation="border"
                variant="warning"
                style={{
                  display: 'flex',
                  marginLeft: '47.5%',
                  marginTop: '5%',
                  marginBottom: '5%',
                }}
              />
            ) : (
              <>
                <table className="table">
                  <thead className="thead">
                    <tr>
                      <th>Número</th>
                      <th>Número Manual</th>
                      <th>Transportador</th>
                      <th>Item Pesquisado</th>
                      <th>Situação</th>
                      <th>Data Avaliação</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {registros &&
                      registros.map((each: any, index: any) => (
                        <tr>
                          <td>{each.numLiberacao}</td>
                          <td>
                            {each.itens[0]?.codigoPesquisaDminerCnh ||
                              each.itens[0]?.codigoPesquisaDminerProcesso ||
                              each.itens[0]?.codigoPesquisaDminerVeiculo}
                          </td>
                          <td style={{ width: '15%' }}>{each.empresa}</td>
                          {each.operacao.includes('Integrada') ? (
                            <td
                              style={{
                                fontSize: each.itens[3] ? '11px' : each.itens[2] ? '12px' : '',
                              }}
                            >
                              {each.itens[0] ? (
                                <>
                                  {each.itens[0].itemPesquisado}
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                              {each.itens[1] ? (
                                <>
                                  {each.itens[1].itemPesquisado}
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                              {each.itens[2] ? (
                                <>
                                  {each.itens[2].itemPesquisado}
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                              {each.itens[3] ? (
                                <>
                                  {each.itens[3].itemPesquisado}
                                  <br />
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                          ) : (
                            <td>{each.itens[0].itemPesquisado}</td>
                          )}
                          <td>{handleResultado(each.itens)}</td>
                          <td>
                            {each.dataLancamento
                              ? `${new Date(each.dataLancamento).toLocaleDateString('pt-br', {
                                  day: '2-digit',
                                  month: '2-digit',
                                  year: 'numeric',
                                })}
                        `
                              : ''}
                          </td>
                          <td></td>
                          <td style={{ width: '5%' }}>
                            <button
                              title="Editar Pesquisa"
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                                marginBottom: 2,
                                color: '#9F9F9F',
                              }}
                            >
                              <i
                                key={each.numLiberacao}
                                onClick={() => {
                                  if (canEdit) {
                                    history.push({
                                      pathname: `/analise-perfil/pesquisa/${
                                        each.itens[0].codigoPesquisaDminerProcesso
                                          ? each.itens[0].codigoPesquisaDminerProcesso
                                          : each.itens[0].codigoPesquisaDminerVeiculo
                                      }`,
                                      state: each,
                                    });
                                  } else {
                                    history.push('/acessonegado');
                                  }
                                }}
                                className="pi pi-pencil"
                              ></i>
                            </button>
                          </td>
                          <td>
                            <button
                              style={{
                                backgroundColor: 'transparent',
                                borderColor: 'transparent',
                              }}
                            >
                              <i
                                onClick={() => {
                                  if (canDelete) {
                                    setIdRegistro(each.numLiberacao);
                                    setShow(true);
                                  } else {
                                    history.push('/acessonegado');
                                  }
                                }}
                                title="Excluir pesquisa"
                                style={{ color: 'red', fontSize: '1em' }}
                                className="pi pi-trash"
                              ></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>


                <Pagination
                  lastPage={lastPage}
                  itensPerPage={itensPerPage}
                  setItensPerPage={setItensPerPage}
                  totalPosts={totalItems}
                  paginate={paginate}
                  currentPage={String(currentPage)}
                  setRefresh={setRefresh}
                  setData={setRegistros}
                  setRefreshitensPerPage={setRefreshitensPerPage}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default GridPesquisaCadastral;
