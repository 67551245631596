import React, { FC, useState } from 'react';
import { Form, Col } from 'react-bootstrap';
import { useToast } from '../../../hooks/Toast';
import { toCurrency, toDecimal } from '../../../util/Validacoes/Validacoes';
import {
  EmbarcadorPiso,
  TypeConditional,
  TypeSecurityParameters,
} from '../../../api/model/EmbarcadorDadosSMType';

export interface ObjectTypeShipper {
  criticarMarcasIguais: boolean;
  valorLimiteCarga: number;
  pisos: EmbarcadorPiso[];
}

interface LimiteCargaProps {
  type: string;
  disabled: boolean;
  pisos: EmbarcadorPiso[];
  setPisos: (pisos: EmbarcadorPiso[]) => void;
  valorLimiteCarga: number;
  setValorLimiteCarga: (valor: number) => void;
  criticarMarcasIguais: boolean;
  setCriticarMarcasIguais: (value: boolean) => void;
}

const conditionalOptions = [
  { value: 'E', name: 'E' },
  { value: 'OU', name: 'OU' },
];

const securityParameters = [
  { value: 'ESCOLTA', name: 'Escolta' },
  { value: 'SEGUNDA_ESCOLTA', name: 'Segunda Escolta' },
  { value: 'ISCA', name: 'Isca' },
  { value: 'SEGUNDA_ISCA', name: 'Segunda Isca' },
  { value: 'IMOBILIZIADOR_ELETRONICO', name: 'Imobilizador Eletrônico' },
  { value: 'TRAVA_INTELIGENTE', name: 'Trava Inteligente' },
];

const LimiteCarga: FC<LimiteCargaProps> = ({
  disabled = false,
  type,
  pisos,
  setPisos,
  valorLimiteCarga,
  setValorLimiteCarga,
  criticarMarcasIguais,
  setCriticarMarcasIguais,
}) => {
  const [validationErrors, setValidationErrors] = useState<boolean[]>(Array(10).fill(false));
  const { addToast } = useToast();

  const validatePisos = (updatedPisos: EmbarcadorPiso[]) => {
    const newErrors = Array(updatedPisos.length).fill(false);
    let hasError = false;

    updatedPisos.forEach((piso, index, array) => {
      if (piso.valorPiso > valorLimiteCarga) {
        newErrors[index] = true;
        hasError = true;
        addToast({
          title: 'Erro',
          description: 'Erro, o valor do piso não deve exceder o limite de carga',
          type: 'error',
        });
      }
      if (index > 0 && piso.valorPiso !== 0) {
        if (piso.valorPiso <= array[index - 1].valorPiso) {
          newErrors[index] = true;
          hasError = true;
          addToast({
            title: 'Erro',
            description: 'Erro, o valor deve ser crescente',
            type: 'error',
          });
        }
      }
      if (piso.primeiraCondicao && !piso.segundoValor) {
        newErrors[index] = true;
        hasError = true;
        addToast({
          title: 'Erro',
          description: 'Erro, o segundo valor é obrigatório se a primeira condição for preenchida',
          type: 'error',
        });
      }
      if (piso.segundaCondicao && !piso.terceiroValor) {
        newErrors[index] = true;
        hasError = true;
        addToast({
          title: 'Erro',
          description: 'Erro, o terceiro valor é obrigatório se a segunda condição for preenchida',
          type: 'error',
        });
      }
      if (piso.terceiraCondicao && !piso.quartoValor) {
        newErrors[index] = true;
        hasError = true;
        addToast({
          title: 'Erro',
          description: 'Erro, o quarto valor é obrigatório se a terceira condição for preenchida',
          type: 'error',
        });
      }
    });

    setValidationErrors(newErrors);
  };

  const handleChangePiso = (index: number, field: keyof EmbarcadorPiso, value: any) => {
    const updatedPisos = [...pisos];
    const updatedPiso = { ...updatedPisos[index], [field]: value };

    if (field === 'primeiroValor' && value === '') {
      updatedPiso.primeiraCondicao = '';
      updatedPiso.segundoValor = '';
      updatedPiso.segundaCondicao = '';
      updatedPiso.terceiroValor = '';
      updatedPiso.terceiraCondicao = '';
      updatedPiso.quartoValor = '';
    } else if (field === 'primeiraCondicao' && value === '') {
      updatedPiso.segundoValor = '';
      updatedPiso.segundaCondicao = '';
      updatedPiso.terceiroValor = '';
      updatedPiso.terceiraCondicao = '';
      updatedPiso.quartoValor = '';
    } else if (field === 'segundoValor' && value === '') {
      updatedPiso.segundaCondicao = '';
      updatedPiso.terceiroValor = '';
      updatedPiso.terceiraCondicao = '';
      updatedPiso.quartoValor = '';
    } else if (field === 'segundaCondicao' && value === '') {
      updatedPiso.terceiroValor = '';
      updatedPiso.terceiraCondicao = '';
      updatedPiso.quartoValor = '';
    } else if (field === 'terceiroValor' && value === '') {
      updatedPiso.terceiraCondicao = '';
      updatedPiso.quartoValor = '';
    } else if (field === 'terceiraCondicao' && value === '') {
      updatedPiso.quartoValor = '';
    }

    updatedPisos[index] = updatedPiso;
    setPisos(updatedPisos);
    validatePisos(updatedPisos);
  };

  const getDisabledOptions = (
    selectedValues: TypeSecurityParameters[],
    currentValue: TypeSecurityParameters
  ) => {
    return securityParameters.map((parameter) => {
      let disable =
        selectedValues.includes(parameter.value as TypeSecurityParameters) &&
        parameter.value !== currentValue;
      if (parameter.value === 'SEGUNDA_ESCOLTA' && !selectedValues.includes('ESCOLTA')) {
        disable = true;
      }
      if (parameter.value === 'SEGUNDA_ISCA' && !selectedValues.includes('ISCA')) {
        disable = true;
      }
      return {
        ...parameter,
        disable,
      };
    });
  };

  return (
    <div>
      <h3 style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </h3>
      <Form.Row>
        <Form.Group as={Col} style={{ display: 'flex', flexDirection: 'row' }}>
          <Form.Label style={{ width: '110px' }}>Limite de Carga</Form.Label>
          <Form.Control
            type="text"
            value={toCurrency(valorLimiteCarga)}
            onChange={(e: any) => setValorLimiteCarga(Number(toDecimal(e.target.value)))}
            style={{ width: '15%' }}
            maxLength={21}
            disabled={disabled}
          />
          <Form.Check
            disabled={disabled}
            checked={criticarMarcasIguais}
            onChange={(e: any) => setCriticarMarcasIguais(e.target.checked)}
            type="switch"
            id={`switch_${type}`}
            label="Criticar marcas iguais"
            style={{ marginLeft: '1rem' }}
          />
        </Form.Group>
      </Form.Row>
      {Array.from({ length: 10 }).map((_, index) => {
        const piso = pisos[index] || {
          valorPiso: 0,
          primeiroValor: '',
          primeiraCondicao: '',
          segundoValor: '',
          segundaCondicao: '',
          terceiroValor: '',
          terceiraCondicao: '',
          quartoValor: '',
        };
        const selectedValues = [
          piso.primeiroValor,
          piso.segundoValor,
          piso.terceiroValor,
          piso.quartoValor,
        ].filter((value) => value !== '') as TypeSecurityParameters[];
        return (
          <div key={index}>
            <Form.Row>
              <Form.Group
                as={Col}
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '1rem' }}
              >
                <Form.Label style={{ marginRight: '20px' }}>Piso:</Form.Label>
                <Form.Group as={Col} style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
                  <Form.Control
                    type="text"
                    value={toCurrency(piso.valorPiso)}
                    onChange={(e: any) =>
                      handleChangePiso(index, 'valorPiso', Number(toDecimal(e.target.value)))
                    }
                    id={`valorPiso_${index}`}
                    maxLength={21}
                    isInvalid={validationErrors[index]}
                    disabled={disabled}
                  />
                  <Form.Control
                    value={piso.primeiroValor}
                    disabled={piso.valorPiso === 0 || disabled}
                    onChange={(e: any) =>
                      handleChangePiso(
                        index,
                        'primeiroValor',
                        e.target.value as TypeSecurityParameters
                      )
                    }
                    id={`primeiroValor_${index}`}
                    as="select"
                  >
                    <option value="">-</option>
                    {getDisabledOptions(selectedValues, piso.primeiroValor).map((parameter) => (
                      <option
                        key={parameter.value}
                        value={parameter.value}
                        disabled={parameter.disable}
                      >
                        {parameter.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control
                    value={piso.primeiraCondicao}
                    onChange={(e: any) =>
                      handleChangePiso(index, 'primeiraCondicao', e.target.value as TypeConditional)
                    }
                    id={`primeiraCondicao_${index}`}
                    as="select"
                    disabled={!piso.primeiroValor || disabled}
                  >
                    <option value="">-</option>
                    {conditionalOptions.map((cond) => (
                      <option key={cond.value} value={cond.value}>
                        {cond.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control
                    value={piso.segundoValor}
                    onChange={(e: any) =>
                      handleChangePiso(
                        index,
                        'segundoValor',
                        e.target.value as TypeSecurityParameters
                      )
                    }
                    id={`segundoValor_${index}`}
                    as="select"
                    disabled={!piso.primeiroValor || !piso.primeiraCondicao || disabled}
                  >
                    <option value="">-</option>
                    {getDisabledOptions(
                      [...selectedValues, piso.primeiroValor],
                      piso.segundoValor
                    ).map((parameter) => (
                      <option
                        key={parameter.value}
                        value={parameter.value}
                        disabled={parameter.disable || disabled}
                      >
                        {parameter.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control
                    value={piso.segundaCondicao}
                    onChange={(e: any) =>
                      handleChangePiso(index, 'segundaCondicao', e.target.value as TypeConditional)
                    }
                    id={`segundaCondicao_${index}`}
                    as="select"
                    disabled={!piso.segundoValor || disabled}
                  >
                    <option value="">-</option>
                    {conditionalOptions.map((cond) => (
                      <option key={cond.value} value={cond.value}>
                        {cond.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control
                    value={piso.terceiroValor}
                    onChange={(e: any) =>
                      handleChangePiso(
                        index,
                        'terceiroValor',
                        e.target.value as TypeSecurityParameters
                      )
                    }
                    id={`terceiroValor_${index}`}
                    as="select"
                    disabled={!piso.segundaCondicao || disabled}
                  >
                    <option value="">-</option>
                    {getDisabledOptions(
                      [...selectedValues, piso.segundoValor],
                      piso.terceiroValor
                    ).map((parameter) => (
                      <option
                        key={parameter.value}
                        value={parameter.value}
                        disabled={parameter.disable}
                      >
                        {parameter.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control
                    value={piso.terceiraCondicao}
                    onChange={(e: any) =>
                      handleChangePiso(index, 'terceiraCondicao', e.target.value as TypeConditional)
                    }
                    id={`terceiraCondicao_${index}`}
                    as="select"
                    disabled={!piso.terceiroValor || disabled}
                  >
                    <option value="">-</option>
                    {conditionalOptions.map((cond) => (
                      <option key={cond.value} value={cond.value}>
                        {cond.name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control
                    value={piso.quartoValor}
                    onChange={(e: any) =>
                      handleChangePiso(
                        index,
                        'quartoValor',
                        e.target.value as TypeSecurityParameters
                      )
                    }
                    id={`quartoValor_${index}`}
                    as="select"
                    disabled={!piso.terceiraCondicao || disabled}
                  >
                    <option value="">-</option>
                    {getDisabledOptions(
                      [...selectedValues, piso.terceiroValor],
                      piso.quartoValor
                    ).map((parameter) => (
                      <option
                        key={parameter.value}
                        value={parameter.value}
                        disabled={parameter.disable}
                      >
                        {parameter.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Form.Group>
            </Form.Row>
          </div>
        );
      })}
    </div>
  );
};

export default LimiteCarga;
