/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from '../../config/axiosMaquina';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { BlackListType } from '../../api/model/BlackListType';
import { cpfMask } from '../../util/Validacoes/Validacoes';
import { useToast } from '../../hooks/Toast';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { Tela } from '../../components/Tela';
import { pesquisar } from '../../util/format';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

function BlackListSeguradoras() {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const { addToast } = useToast();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [idBlacklist, setIdBlacklist] = useState<number>();
  const [nomeBkl, setNomeBkl] = useState<string>();
  const { page }: any = useParams();
  const [data, setData] = useState<BlackListType[]>([]);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData = searchValue
    ? dataSearch?.slice(indexOfFirstPost, indexOfLastPost)
    : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const titles = ['+id', 'CPF', 'Nome', 'Motivo'];

  const paginate = (pageNumber: number) => {
    history.push(`/admin/listar/blacklist-seguradora/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.BLACKLIST_SEGURADORA]}], 'inserir');
    rolePermission([{ADMIN: [ADMIN.CADASTRO.BLACKLIST_SEGURADORA]}], 'excluir');
  }, [ADMIN, rolePermission]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/blacklist/listar`);
      setData(response.data);
      const array = response.data.map((each: BlackListType) => {
        return [each.id, cpfMask(each.cpf), each.nome, each.motivo];
      });
      setDataTabela(array);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (id: any) => {
    try {
      await axios.delete(`/blacklist/${id}`);
      setShow(false);
      fetchData();
    } catch {
      addToast({
        title: 'Erro!',
        description: 'Erro ao excluir.',
        type: 'error',
      });
      return;
    }
  };

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/admin/listar/blacklist-seguradora/1');
      setDataSearch(pesquisar(dataTabela, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="BlackList Seguradora"
      caminho="Cadastro > Gerenciamento de Clientes > BlackList Seguradora"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchValue}
      search={search}
      linkToAdd="/cadastro-blacklistseguradora"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchValue ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickExcluir={(id: any) => {
            if (canDelete) {
              setIdBlacklist(id);
              setShow(true);
              setNomeBkl(data.find((e: any) => e.id === id)?.nome);
            } else {
              history.push('/acessonegado');
            }
          }}
        />
      )}

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
          Deseja realmente excluir o registro "{`${nomeBkl}`}"?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            style={{ width: '120px', height: '50px' }}
            onClick={() => setShow(false)}
          >
            Cancelar
          </Button>
          <Button
            style={{ color: '#000', width: '120px', height: '50px' }}
            variant="primary"
            onClick={() => handleDelete(idBlacklist)}
          >
            Excluir
          </Button>
        </Modal.Footer>
      </Modal>
    </Tela>
  );
}

export default BlackListSeguradoras;
