/* eslint-disable */
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import sortBy from 'lodash/sortBy';
import axios from '../../config/axiosMaquina';
import { UsuariosType, ClientesType } from '../../api/model/UsuariosType';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { filterItems2 } from '../../util/format';
import Search from '../../components/Search/Search';
import Table from '../../components/TableComponent';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

type Props = {
  canInsert?: boolean;
};

function UsuariosClientes() {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;

  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const [cliente, setCliente] = useState<number>();
  const [listaClientes, setListaClientes] = useState<ClientesType[]>([]);
  const { clienteId, role } = getCookieSessionData().usuarioVO;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const headers = {
    id: 'Id',
    usuCdId: 'Código do Cliente',
    clienteId: 'Código do Usuário',
    login: 'Login',
    nome: 'Nome',
  };

  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: number) => {
    history.push(`/listar-usuarios-clientes/${pageNumber}`);
  };

  const converteArray = (dt: any[]) => {
    return dt.map((each: UsuariosType) => {
      return {
        id: each.usuCdId,
        clienteId: each.clienteId,
        usuCdId: each.usuCdId,
        login: each.login,
        nome: each.nome,
      };
    });
  };

  useEffect(() => {
    const fetchDataPlaca = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/cliente/getIdRazaoSocial`);
        setListaClientes(response.data);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchDataPlaca();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (role === 'admin' && cliente) {
        try {
          setLoading(true);
          const response = await axios.get(`/usuario/cliente`, {
            params: {
              cliente,
            },
          });
          setData(converteArray(sortBy(response.data, 'usuCdId')));
        } catch (err: any) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);
          const response = await axios.get(`/usuario/cliente`, {
            params: {
              cliente: clienteId,
            },
          });
          setData(converteArray(sortBy(response.data, 'usuCdId')));
          setLoading(false);
        } catch (err: any) {
          console.log(err);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [cliente, clienteId, role]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      setDataSearch(filterItems2(data, searchValue));
    }
  }, [data, searchValue]);

  useEffect(() => {
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.USUARIO.INCLUSAO_ALTERACAO],
          ADMIN: [ADMIN.USUARIO.CLIENTE],
          EMBARCADOR: [EMBARCADOR.USUARIO.INCLUSAO_ALTERACAO],
        },
      ],
      'inserir'
    );
  }, [ADMIN, EMBARCADOR, TRANSPORTADOR, rolePermission]);

  return (
    <Tela
      nome="Usuário"
      caminho="Usuários > Inclusão/Alteração(Usuários Clientes)"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      {role === 'admin' ? (
        <div className="header-associar-container">
          <div className="header-associar-field">
            <span>Cliente</span>
            <Dropdown
              value={cliente}
              options={listaClientes}
              onChange={(e) => {
                setCliente(e.target.value);
              }}
              optionLabel="razaoSocial"
              optionValue="id"
              filter
              filterBy="razaoSocial"
              placeholder="Selecione uma opção"
              className="p-dropdown"
              showClear
              style={{ width: '120%' }}
            />
          </div>
        </div>
      ) : (
        <Search
          setLoading={setLoadingSearch}
          setSearch={setSearchTerm}
          setSearchValue={setSearchValue}
          search={searchTerm}
          linkToAdd={`/cadastro-usuario-cliente/${clienteId}`}
          createDisabled={!canInsert}
        />
      )}
      {cliente && (
        <Search
          setLoading={setLoadingSearch}
          setSearch={setSearchTerm}
          setSearchValue={setSearchValue}
          search={searchTerm}
          linkToAdd={`/cadastro-usuario-cliente/${cliente}`}
          createDisabled={!canInsert}
        />
      )}
      <Table
        items={currentData}
        loading={loading}
        headers={headers}
        handleEdit={(id: any) => {
          history.push(`/editar-usuario-cliente/${id}/${role === 'admin' ? cliente : clienteId}`);
        }}
      />
    </Tela>
  );
}

export default UsuariosClientes;
