/* eslint-disable */

import React, { useState, useEffect, ChangeEvent } from 'react';
import { Form, Col, DropdownButton, Dropdown as DropDownBootstrap } from 'react-bootstrap';
import { Button } from 'primereact/button';

import { Dropdown } from 'primereact/dropdown';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
//@ts-ignore
import ReactExport from 'react-data-export';
import GeneratePDF from './generatePDF';
import axios from '../../config/axiosMaquina';
import { DataMaxima, FormataStringData2 } from '../../util/Validacoes/Validacoes';
import { useToast } from '../../hooks/Toast';
import { Spinner } from '../../components/Spinner';
import { FilterBtn } from '../PrevencaoAcidentes/styles';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { toZoned } from '../../util/date';

const TempoParado = () => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const history = useHistory();

  const [placas, setPlacas] = useState();
  const [loading, setLoading] = useState(false);
  const [tempoParado, setTempoParado] = useState<any[]>([]);
  const [placaSelecionada, setPlacaSelecionada] = useState<{ id: number; placa: string }>();
  const [choosenStartDate, setChoosenStartDate] = useState<string>();
  const [choosenEndDate, setChoosenEndDate] = useState<string>();
  const { addToast } = useToast();

  const [selecionado, setSelecionado] = useState(false);

  //Apenas para testes - trocar para o endpoint correto quando o mesmo estiver pronto.
  useEffect(() => {
    const getPlacas = async () => {
      axios.get(`/veiculo/listarTodasPlacas`).then((response) => {
        setPlacas(response.data);
      });
    };
    getPlacas();
  }, []);

  const convertMinToHour = (min: string) => {
    const _min = Number(min.split(' ')[0]);
    const hour = Math.floor(_min / 60);
    const minutos = _min % 60;
    if (hour === 0 && minutos > 0) {
      return `${minutos} minuto(s)`;
    }
    if (hour > 0 && minutos === 0) {
      return `${hour} hora(s)`;
    }
    if (hour === 0 && minutos === 0) {
      return '-';
    }
    return `${hour} hora(s) e ${minutos} minuto(s)`;
  };

  const diffDateLessThanThirtyDays = (startDate: string, endDate: string) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diff = end.getTime() - start.getTime();
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays <= 30;
  };

  const fetchData = async () => {
    history.push(`/rastreamento/tempo-parado`);

    if (choosenEndDate && choosenStartDate && placaSelecionada) {
      setLoading(true);
      axios
        .get(
          `/veiculo/tempo-parado?dataFim=${toZoned(choosenEndDate, false)}&dataInicio=${toZoned(
            choosenStartDate
          )}&idVeiculo=${placaSelecionada.id}`
        )
        .then((response: any) => {
          const data = response.data.map((item: any) => {
            return {
              ...item,
              tempoParado: convertMinToHour(item.tempoParado),
              tempoViagem: convertMinToHour(item.tempoViagem),
            };
          });
          setTempoParado(data);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Preencha todos os campos',
      });
    }
  };

  const periodo =
    choosenStartDate && choosenEndDate
      ? `Período: ${FormataStringData2(choosenStartDate?.split('T')[0])} à ${FormataStringData2(
          choosenEndDate?.split('T')[0]
        )}`
      : 'Período: ';

  const borders = {
    top: { style: 'thin' },
    bottom: { style: 'thin' },
    left: { style: 'thin' },
    right: { style: 'thin' },
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Tempo Parado',
          widthPx: 800,
          style: {
            font: { sz: '24', bold: true },
            alignment: { horizontal: 'center' },
          },
        },
      ],
      data: [],
    },
    {
      columns: [
        {
          title: `Veículo: ${placaSelecionada?.placa}`,
          widthPx: 800,
          style: {
            font: { sz: '16', bold: true },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
          },
        },
      ],
      data: [],
    },
    {
      columns: [
        {
          title: periodo,
          widthPx: 800,
          style: {
            font: { sz: '16', bold: true },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
          },
        },
      ],
      data: [],
    },
    {
      columns: [
        {
          title: 'Referência',
          width: { wch: 40 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              vertical: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Tempo Parado',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
        {
          title: 'Tempo Viagem',
          width: { wch: 25 },
          style: {
            font: { sz: '14', bold: true },
            fill: { fgColor: { rgb: 'FFD3D3D3' } },
            alignment: {
              horizontal: 'bottom',
              wrapText: true,
            },
            border: borders,
          },
        },
      ],
      data: tempoParado.map((each) => [
        { value: each.dataTempoParada },
        {
          value: each.tempoParado,
        },
        {
          value: each.tempoViagem,
        },
      ]),
    },
  ];

  function separar(base: any, max: any) {
    const pages = Math.ceil(base.length / max);
    let arr: any[] = [];
    for (let i = 0; i < pages; i++) {
      const start = i * max;
      const end = start + max;
      const data = base.slice(start, end);
      arr = [...arr, data];
    }
    return arr;
  }

  const isSelected = (event: any) => {
    if (event.target.value != null) {
      setSelecionado(true);
    } else {
      setSelecionado(false);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />

      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Tempo Parado</h2>
          <h6 className="subtitulo">{'Consultas/Relatórios > Rastreamento > Tempo Parado'}</h6>
        </div>
        <div className="painel">
          <div className="header-associar-container">
            <div className="header-associar-field">
              <Form>
                <div className="header-associar-field">
                  <Form.Label className="requiredField">VEÍCULO</Form.Label>
                  <Dropdown
                    style={{ marginBottom: '15px' }}
                    value={placaSelecionada}
                    options={placas}
                    optionLabel="placa"
                    filter
                    showClear
                    filterBy=""
                    placeholder="Selecione"
                    onChange={(e: any) => {
                      setPlacaSelecionada(e.target.value);
                      isSelected(e);
                    }}
                  />
                </div>
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className="requiredField">Data Início</Form.Label>
                    <Form.Control
                      onChange={(e: any) => setChoosenStartDate(e.target.value)}
                      type="date"
                    />
                  </Form.Group>
                  <Form.Group as={Col}>
                    <Form.Label className="requiredField">Data Fim</Form.Label>
                    <Form.Control
                      onChange={(e: any) => setChoosenEndDate(e.target.value)}
                      type="date"
                      min={choosenStartDate}
                    />
                  </Form.Group>
                  <div className="header-associar-field button-field">
                    <FilterBtn style={{ marginTop: 28 }} type="button" onClick={fetchData}>
                      Filtrar
                    </FilterBtn>
                  </div>
                </Form.Row>
              </Form>
            </div>
          </div>
        </div>

        {loading && <Spinner />}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DropdownButton
            id="dropdown-item-button"
            variant="#FFDA53"
            style={{ background: '#FFDA53', borderRadius: '4px' }}
            title="Exportar"
            disabled={!tempoParado.length}
          >
            <ExcelFile
              element={<DropDownBootstrap.Item eventKey="1">XLS</DropDownBootstrap.Item>}
              filename="relatorio_tempo_parado"
            >
              <ExcelSheet dataSet={multiDataSet} name="Relatório Tempo Parado" />
            </ExcelFile>
            <DropDownBootstrap.Item
              eventKey="2"
              as="button"
              onClick={() =>
                GeneratePDF(separar(tempoParado, 20), placaSelecionada?.placa, periodo)
              }
            >
              PDF
            </DropDownBootstrap.Item>
          </DropdownButton>
          {/* <>
              <ExcelFile
                element={
                  <Button disabled={!tempoParado.length || loading}>Exportar para XLS</Button>
                }
                filename="relatorio_tempo_parado"
              >
                <ExcelSheet dataSet={multiDataSet} name="Relatório Tempo Parado">
                  <ExcelColumn label="Referência" value="dataReferencia" />
                  <ExcelColumn label="Tempo Parado" value="tempoparadafrom" />
                </ExcelSheet>
              </ExcelFile>

              <Button
                style={{ marginLeft: '23px' }}
                disabled={!tempoParado.length || loading}
                onClick={() =>
                  GeneratePDF(separar(tempoParado, 20), placaSelecionada?.placa, periodo)
                }
              >
                Exportar para PDF
              </Button>
            </> */}
        </div>
      </div>
    </div>
  );
};

export default TempoParado;
