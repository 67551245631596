/* eslint-disable */
import axios from 'axios';

interface HistoryPositionProps {
  date_start: string;
  date_end: string;
  licence_plate: string;
  page_size: number;
  page: number;
}

interface HistoryPositionResults {
  etc_bl_status_ignicao: boolean;
  etc_cd_id: number;
  etc_cd_id_evento_legado: string;
  etc_dt_cadastro: string;
  etc_dt_hr_evento: string;
  etc_nu_latitude: number;
  etc_nu_longitude: number;
  etc_nu_odometro: number;
  etc_nu_temperatura: null;
  etc_nu_velocidade: number;
  etc_ts_dthr_evento: null;
  etc_tx_bau: null;
  etc_tx_logradouro: string;
  etc_tx_municipio: string;
  etc_tx_numero_equipamento: string;
  etc_tx_rodovia: string;
  etc_tx_uf: string;
  etr_cd_id: number;
  id_client: number;
  ter_cd_id: number;
  etc_nu_km_percorrido: number;
}

interface HistoryPosition {
  count: number;
  results: HistoryPositionResults[];
}

export const listHistoryPosition = async ({
  date_start,
  date_end,
  licence_plate,
  page_size,
  page,
}: HistoryPositionProps): Promise<HistoryPosition> => {
  try {
    const response = await axios.get<HistoryPosition>(
      `${process.env.REACT_APP_API_URL}/relatorio-historico-posicao-cliente/?date_start=${date_start}&date_end=${date_end}&vei_tx_placa=${licence_plate}&page_size=${page_size}&page=${page}`
    );
    return response.data;
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data.message;
    }
    return error;
  }
};
