/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { UsuarioGerenciadorType } from '../../api/model/UsuarioGerenciadorType';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

function UsuariosGerenciadores() {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const [loading, setLoading] = useState(false);
  const titles = ['+id', 'Código', 'Login', 'Nome', 'Perfil'];
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/listar-usuarios-gerenciadores/${pageNumber}`);
  };

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.USUARIO.GERENCIADOR]}], 'inserir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/usuario/gerenciador`);
        const array = response.data
          .sort(function (a: any, b: any) {
            return a.usuCdId - b.usuCdId;
          })
          .map((each: UsuarioGerenciadorType) => {
            return [each.usuCdId, each.usuCdId, each.login, each.nome, each.nomeMenuPerfil];
          });
        setData(array);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchData();
  }, [page, itensPerPage]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar-usuarios-gerenciadores/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Usuário"
      caminho="Usuários > Usuários Gerenciadores"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      search={searchTerm}
      linkToAdd="/cadastro-usuario-gerenciador"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/editar-usuario-gerenciador/${id}`)}
        />
      )}
    </Tela>
  );
}

export default UsuariosGerenciadores;
