/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../config/axiosMaquina';
import { Tela } from '../../../components/Tela';
import { Spinner } from '../../../components/Spinner';
import { Tabela } from '../../../components/Tabela';
import { pesquisar } from '../../../util/format';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';

type TipoVeiculoType = {
  vtpCdId: number;
  veiculoGrupo: string;
  vtpTxNome: string;
  vtpBlAtivo: boolean;
  vtpCdIntegracao: number;
};

const ListagemTipoVeiculos: React.FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const { page }: any = useParams();
  const [fullData, setFullData] = useState<TipoVeiculoType[]>([]);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchValue.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);
  const titles = ['+id', 'Cod. Integração', 'Nome', 'Grupo de Veículos'];
  const paginate = (pageNumber: number) => {
    history.push(`/listar/tipos-de-veiculo/${pageNumber}`);
  };

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Tipos de veículos',
          widthPx: 1200,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      columns: [],
      data: [],
    },
    {
      columns: [
        { title: 'Cod. Integração', width: { wch: 30 } },
        { title: 'Nome', width: { wch: 30 } },
        { title: 'Grupo de veículos', width: { wch: 25 } },
        { title: 'Status', width: { wch: 15 } },
      ],
      data: fullData?.map((each) => [
        { value: each.vtpCdIntegracao ? each.vtpCdIntegracao : '' },
        { value: each.vtpTxNome ? each.vtpTxNome : '' },
        { value: each.veiculoGrupo ? each.veiculoGrupo : '' },
        { value: each.vtpBlAtivo ? 'ATIVO' : 'INATIVO' },
      ]),
    },
  ];

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.TIPO_VEICULO]}], 'inserir');
  }, [ADMIN, rolePermission]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/tipo-veiculo/grid-tipo-veiculo`);
      setFullData(response.data);
      const array = response.data.map((each: TipoVeiculoType) => {
        return [each.vtpCdId, each.vtpCdIntegracao, each.vtpTxNome, each.veiculoGrupo];
      });
      setDataTabela(array);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/listar/tipos-de-veiculo/1');
      setDataSearch(pesquisar(dataTabela, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Tipos de veículos"
      caminho="Cadastro > Veículos > Tipos de Veículos"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchValue}
      search={search}
      linkToAdd="/tipo-de-veiculo/cadastrar"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchValue.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate!}
      currentPage={page}
      excelGeneration={multiDataSet}
      filename="Tipos de Veiculos"
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/tipo-de-veiculo/editar/${id}`)}
        />
      )}
    </Tela>
  );
};

export default ListagemTipoVeiculos;
