import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../config/axiosMaquina';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela';
import { pesquisar } from '../../util/format';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

const Pontos = () => {
  const { TRANSPORTADOR, EMBARCADOR } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();
  const { clienteId } = getCookieSessionData().usuarioVO;
  const history = useHistory();
  const titles = ['id', 'Descrição', 'Raio de Aproximação', 'Latitude', 'Longitude'];
  const [loading, setLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [raioDefault, setRaioDefault] = useState('');
  const [data, setData] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page }: any = useParams();
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/pontos/${pageNumber}`);
  };

  const converteArray = (dt: any[]) => {
    const array = dt.map((each: any) => {
      return [
        each.id,
        each.descricao,
        each.raioAproximacao === null || each.raioAproximacao === ''
          ? raioDefault
          : each.raioAproximacao,
        each.latitude,
        each.longitude,
      ];
    });
    return array;
  };

  const getRaioDefault = async () => {
    try {
      const response = await axios.get(`/parametro-logistico`);
      setRaioDefault(response.data.raioDeAproximacaoVeiculosEmMetros);
    } catch (err: any) {
      console.log(err);
    }
  };

  useEffect(() => {
    rolePermission(
      [{ TRANSPORTADOR: [TRANSPORTADOR.CADASTRO.PONTO], EMBARCADOR: [EMBARCADOR.CADASTRO.PONTO] }],
      'inserir'
    );
  }, [EMBARCADOR, rolePermission, TRANSPORTADOR]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`/ponto/cliente`, {
        params: {
          clienteId,
        },
      });
      setData(converteArray(response.data));
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getRaioDefault();
    fetchData();
  }, []);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/pontos/1');
      setDataSearch(pesquisar(data, searchValue));
    }
  }, [searchValue]);

  return (
    <Tela
      nome="Pontos"
      caminho="Cadastro > Pontos"
      loading={loading}
      setLoading={setLoading}
      linkToAdd="/cadastrar-pontos"
      search={searchTerm}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchValue}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentData}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/editar-pontos/${id}`)}
        />
      )}
    </Tela>
  );
};

export default Pontos;
