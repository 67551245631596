/* eslint-disable */

import axios from 'axios';

export const listTemperature = async ({
  page,
  clienteId,
  temperature,
  pageSize,
  placa,
}: {
  page: number;
  clienteId: number;
  temperature: boolean;
  pageSize: number;
  placa?: string;
}) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/vehicle-control-temperature`, {
      params: {
        page_size: pageSize,
        cliente: clienteId,
        page,
        temperature,
        ...(placa && { placa: placa.trim() }),
      },
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      throw error;
    }
    return Error('A problem has occurred');
  }
};
