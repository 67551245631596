/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Button, Modal, Spinner } from 'react-bootstrap';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import ButtonsForm from '../../components/ButtonsForm/ButtonsForm';
import MenuLateral from '../../components/MenuLateral/MenuLateral';
import axios from '../../config/axiosMaquina';
import { useToast } from '../../hooks/Toast';
import ModalTipoDispositivo from './ModalTipoDispositivo';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

type DispositivoType = {
  id?: number;
  idTipoDispositivo: number;
  listaGrupoVeiculo?: number[];
  nome: string;
  descricao: string;
  ativo?: boolean;
  ordem?: number;
};

type GrupoVeiculoType = {
  vtgCdId: number;
  vgmCdId: {
    vgmCdId: number;
    vgmTxNome: string;
  };
  vtgTxNome: string;
  vtgBlAtivo: boolean;
};

type Props = {
  canEdit?: boolean;
  canDelete?: boolean;
};

const DispositivoForm: React.FC<Props> = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canDelete } = useRolePermission();

  const history = useHistory();
  const { addToast } = useToast();
  const { id }: any = useParams();

  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [grupoVeiculoId, setGrupoVeiculoId] = useState<any>([]);
  const [grupoVeiculosList, setGrupoVeiculosList] = useState<GrupoVeiculoType[]>([]);
  const [tiposList, setTiposList] = useState<any[]>([]);
  const [model, setModel] = useState<DispositivoType>({
    id: 0,
    idTipoDispositivo: 0,
    listaGrupoVeiculo: grupoVeiculoId.map((gv: any) => gv.code),
    nome: '',
    descricao: '',
    ativo: true,
    ordem: 0,
  });
  const [exclusao, setExclusao] = useState({ ok: false, erro: false, mensagem: '' });
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { nome, descricao, idTipoDispositivo, listaGrupoVeiculo } = model;

  useEffect(() => {
    rolePermission([{ADMIN: [ADMIN.CADASTRO.DISPOSITIVO]}], 'editar');
    rolePermission([{ADMIN: [ADMIN.CADASTRO.DISPOSITIVO]}], 'excluir');
  }, [ADMIN, rolePermission]);

  const fetchData = async () => {
    if (id) {
      setLoading(true);
      try {
        const { data } = await axios.get(`/dispositivo/dispositivo/${id}`);
        setModel(data);
      } catch (err: any) {
        let errorMessage = 'Ocorreu um erro';
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        console.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  const fetchOther = async () => {
    if (!grupoVeiculosList.length && !tiposList.length) {
      setLoading(true);
      try {
        const response = await axios.get('/grupo-tipo-veiculo/grid');
        setGrupoVeiculosList(response.data);
        const { data } = await axios.get('/dispositivo-tipo/grid');
        setTiposList(data);
      } catch (err) {
        let errorMessage = 'Ocorreu um erro';
        if (err instanceof Error) {
          errorMessage = err.message;
        }
        console.error(errorMessage);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (!grupoVeiculosList.length) {
      fetchOther();
    } else {
      const arr: any[] = [];
      listaGrupoVeiculo?.map((d: any) =>
        grupoVeiculosList
          .filter((r: any) => r.vtgCdId === d)
          .map((gv: any) => arr.push({ name: gv.vtgTxNome, code: gv.vtgCdId }))
      );
      setGrupoVeiculoId(arr);
    }
  }, [grupoVeiculosList, listaGrupoVeiculo]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!nome || !idTipoDispositivo) {
      addToast({
        title: 'Erro',
        description: 'Verifique os dados preenchidos!',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }
    const array: any[] = [];
    grupoVeiculoId.map((t: any) => array.push(t.code));
    const newData = { ...model, listaGrupoVeiculo: array };
    if (id) {
      try {
        await axios.put(`/dispositivo/atualizar?idDispositivo=${id}`, newData);
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao editar!',
          type: 'error',
        });
      }
    } else {
      try {
        await axios.post('/dispositivo/dispositivo', newData);
        setIsDialogCadOK(true);
      } catch {
        addToast({
          title: 'Erro',
          description: 'Erro ao cadastrar!',
          type: 'error',
        });
      }
    }
  };

  // eslint-disable-next-line no-shadow
  const handleDelete = async (id: number) => {
    try {
      await axios.put(`/dispositivo/delete-logico?idDispositivo=${id}`);
      setExclusao((prev) => ({ ...prev, ok: true }));
      setTimeout(() => {
        history.push('/listar/dispositivos/1');
      }, 2000);
    } catch (err: any) {
      let errorMessage = 'Ocorreu um erro';
      if (err instanceof Error) {
        errorMessage = err.message;
      }
      setExclusao((prev) => ({ ...prev, erro: true, mensagem: errorMessage }));
    }
  };

  const select = grupoVeiculosList.map((tipo) => ({ name: tipo.vtgTxNome, code: tipo.vtgCdId }));

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <Dialog
        header={`Dispositivo ${id ? 'alterado' : 'cadastrado'} com sucesso!`}
        footer={<Button onClick={() => setIsDialogCadOK(false)}>Ok</Button>}
        visible={isDialogCadOK}
        style={{ width: '50vw' }}
        modal
        onHide={() => {
          history.push('/listar/dispositivos/1');
        }}
      />
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
        </Modal.Header>
        {!exclusao.ok && !exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Deseja realmente excluir o registro &quot;{nome}&quot;?
          </Modal.Body>
        )}

        {exclusao.ok && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
            Dispositivo &quot;{nome}&quot; excluído com sucesso.
          </Modal.Body>
        )}

        {exclusao.erro && (
          <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>{exclusao.mensagem}</Modal.Body>
        )}

        {!exclusao.erro && (
          <Modal.Footer>
            <Button
              variant="secondary"
              style={{ width: '120px', height: '50px' }}
              onClick={handleClose}
              disabled={exclusao.ok}
            >
              Não
            </Button>
            <Button
              style={{ color: '#000', width: '120px', height: '50px' }}
              variant="primary"
              onClick={() => handleDelete(id)}
              disabled={exclusao.ok}
            >
              Sim
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <div className="card">
        <div className="titulo-container" style={{ width: '100%', marginBottom: '-20px' }}>
          <h2 className="titulo">Dispositivos</h2>
          <h6 className="subtitulo">{`Cadastro > Rastreadores > Dispositivos > ${
            id ? 'Editar' : 'Cadastrar'
          }`}</h6>
          {id && (
            <button
              className="btn-desativar"
              style={{
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                position: 'absolute',
                right: '2%',
              }}
              type="button"
              onClick={() => {
                if (canDelete) {
                  setExclusao((prev) => ({ ...prev, erro: false, ok: false }));
                  handleShow();
                } else {
                  history.push('/acessonegado');
                }
              }}
            >
              <i style={{ color: 'red', fontSize: '1.5em' }} className="pi pi-trash" />
            </button>
          )}
        </div>
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <div className="painel">
            <h1 className="info-obrigatorio">*Informações obrigatórias</h1>
            <Form className="form-sm" noValidate validated={isFormInvalid} onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} sm={12} md={12} lg={6}>
                  <Form.Label className="requiredField">Nome</Form.Label>
                  <Form.Control
                    value={nome}
                    onChange={(e) => {
                      setModel({ ...model, nome: e.target.value });
                    }}
                    name="nome"
                    type="text"
                    required
                    maxLength={64}
                    minLength={1}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={12} lg={6}>
                  <Form.Label>Descrição</Form.Label>
                  <Form.Control
                    value={descricao}
                    onChange={(e) => {
                      setModel({ ...model, descricao: e.target.value });
                    }}
                    name="descricao"
                    type="text"
                    minLength={1}
                  />
                </Form.Group>
                <Form.Group as={Col} sm={12} md={12} lg={6}>
                  <Form.Label className="requiredField">Tipo de Dispositivo</Form.Label>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Form.Control
                      value={idTipoDispositivo}
                      onChange={(e) => {
                        setModel({
                          ...model,
                          idTipoDispositivo: +e.target.value,
                        });
                      }}
                      as="select"
                      required
                    >
                      <option value="">Selecione uma opção</option>
                      {tiposList.map((t) => (
                        <option value={t.id} key={t.id}>
                          {t.nome}
                        </option>
                      ))}
                    </Form.Control>
                    <ModalTipoDispositivo />
                  </div>
                </Form.Group>
                <Form.Group
                  as={Col}
                  sm={12}
                  md={12}
                  lg={6}
                  style={{ display: 'flex', flexDirection: 'column' }}
                >
                  <Form.Label>Grupo de veículos</Form.Label>
                  <MultiSelect
                    style={{
                      maxWidth: '100vh',
                      lineHeight: 1.4,
                      borderColor: grupoVeiculosList.length <= 0 && isFormInvalid ? '#dc3545' : '',
                    }}
                    display="chip"
                    optionLabel="name"
                    placeholder="Selecione uma ou mais opções"
                    value={grupoVeiculoId}
                    options={select}
                    onChange={(e) => setGrupoVeiculoId(e.target.value)}
                    filter
                  />
                </Form.Group>
              </Form.Row>
              <ButtonsForm canEdit={canEdit} />
            </Form>
          </div>
        )}
      </div>
    </div>
  );
};

export default DispositivoForm;
