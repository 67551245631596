/* eslint-disable */
import { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { Spinner } from 'react-bootstrap';
import axios from '../../../config/axiosMaquina';
import { ImCancelCircle } from 'react-icons/im';
import { AiOutlineCheck } from 'react-icons/ai';
import api from '../../../config/axiosMaquina';
import { Linha } from '../../../components/GridEditavel2';
import { ModalDelete } from '../../../components/ModalDelete';
import { ContainerButtons, SecundaryButton } from '../../../components/ButtonsForm/styles';

const ModalCadastro = () => {
  const [show, setShow] = useState<boolean>(false);
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [loading, setLoading] = useState(false);
  const [idEdit, setIdEdit] = useState(0);
  const history = useHistory();
  const [newLine, setNewLine] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [isDialogCadOK, setIsDialogCadOK] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<any>();
  const [idDelete, setIdDelete] = useState(0);
  const [atualizar, setAtualizar] = useState(0);

  useEffect(() => {
    if (show) {
      setLoading(true);
      const loadData = async () => {
        try {
          const response = await api.get('/dispositivo-tipo/grid');
          setLoading(false);
          setData(response.data);
        } catch (err: any) {
          setLoading(false);
          console.log(err);
        }
      };
      loadData();
    }
  }, [atualizar, show]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = async () => {
    try {
      const response = await api.put(
        `/dispositivo-tipo/delete-logico?idDispositivoTipo=${idDelete}`
      );
      setOpenModal(false);
      setMensagem('Excluído com sucesso!');
      setIsDialogCadOK(true);
    } catch (error: any) {
      console.log(error);
      setMensagem('Erro ao excluir.');
      setIsDialogCadOK(true);
    }
  };

  async function handleSubmit() {
    setDisabled(false);
    if (nome.trim() != '') {
      try {
        const data = {
          dptBlAtivo: true,
          dptNuOrdem: 0,
          dptTxDescricao: descricao,
          dptTxNome: nome,
        };
        console.log(data);
        await api.post(`/dispositivo-tipo/save`, data);
        setMensagem('Cadastrado com sucesso!');
        setIsDialogCadOK(true);
        setNewLine(false);
        setNome('');
        setDescricao('');
      } catch (err: any) {
        console.log(err);
        setMensagem('Erro ao cadastrar.');
        setIsDialogCadOK(true);
      }
    } else {
      setMensagem('Verifique os campos obrigatórios.');
      setIsDialogCadOK(true);
    }
  }

  const handleCancel = () => {
    setDisabled(true);
    setNewLine(false);
    setNome('');
    setDescricao('');
  };

  async function handlePut(model: any) {
    if (model.nome.trim() != '') {
      try {
        const data = {
          dptBlAtivo: true,
          dptCdId: model.id,
          dptNuOrdem: 0,
          dptTxDescricao: model.descricao,
          dptTxNome: model.nome,
        };
        await api.put(`/dispositivo-tipo/atualizar?idDispositivoTipo=${model.id}`, data);
        setMensagem('Editado com sucesso!');
        setIsDialogCadOK(true);
        return true;
      } catch (err: any) {
        console.log(err);
        setMensagem('Erro ao editar.');
        setIsDialogCadOK(true);
        return false;
      }
    } else {
      setMensagem('Verifique os dados preenchidos!');
      setIsDialogCadOK(true);
    }
    return false;
  }

  return (
    <>
      <i
        style={{
          fontSize: '1.2em',
          marginTop: '0px',
          marginLeft: '8px',
          marginRight: '25px',
          cursor: 'pointer',
        }}
        className="pi pi-plus-circle icone_adicionar"
        onClick={handleShow}
      />
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        enforceFocus
        animation
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Tipo de dispositivo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalDelete
            show={openModal}
            onHide={() => setOpenModal(false)}
            handleDelete={handleDelete}
            handleClose={() => setOpenModal(false)}
            message="Deseja excluir este registro?"
          />
          <Dialog
            header={mensagem}
            footer={
              <Button
                onClick={() => {
                  setIsDialogCadOK(false);
                  setAtualizar(atualizar + 1);
                }}
              >
                Ok
              </Button>
            }
            visible={isDialogCadOK}
            style={{ width: '50vw' }}
            modal
            onHide={() => {
              setIsDialogCadOK(false);
              setAtualizar(atualizar + 1);
            }}
          />
          <div className="search-create mb-4" style={{ width: '100%' }}>
            <div
              className="btn-create"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setNewLine(true);
                setIdEdit(0);
              }}
            >
              <i
                style={{ fontSize: '1em', color: '#000', textDecoration: 'none' }}
                className="pi pi-plus"
              ></i>
            </div>
          </div>
          <div className="content-container" style={{ width: '100%' }}>
            <div className="painel">
              <div className="table-responsive" style={{ flex: 1 }}>
                <table className="table">
                  <thead className="titleLine">
                    <tr>
                      <th>Nome</th>
                      <th>Descrição</th>
                    </tr>
                  </thead>
                </table>
                {newLine && idEdit === 0 ? (
                  <div className="lineGrid2" style={{ filter: 'drop-shadow(0 0 0.5rem #d8d8d8)' }}>
                    <input
                      placeholder="Nome"
                      type="text"
                      value={nome}
                      maxLength={64}
                      onChange={(e: any) => {
                        setNome(e.target.value);
                      }}
                    />
                    <input
                      placeholder="Descrição"
                      type="text"
                      value={descricao}
                      maxLength={64}
                      onChange={(e: any) => {
                        setDescricao(e.target.value);
                      }}
                    />
                    <ImCancelCircle size="35px" id="icon" onClick={handleCancel} />
                    <AiOutlineCheck size="40px" id="icon" onClick={handleSubmit} />
                  </div>
                ) : null}
                {loading ? (
                  <Spinner
                    animation="border"
                    variant="warning"
                    style={{
                      display: 'flex',
                      marginLeft: '47.5%',
                      marginTop: '5%',
                      marginBottom: '5%',
                    }}
                  />
                ) : (
                  <div>
                    {data?.map((each: any, index: number) => {
                      return (
                        <Linha
                          key={index}
                          each={each}
                          handlePut={handlePut}
                          setOpenModal={setOpenModal}
                          setIdDelete={setIdDelete}
                          setIdAtual={setIdEdit}
                          idAtual={idEdit}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Form.Row className="container-buttons justify-content-end">
          <ContainerButtons>
            <SecundaryButton type="button" onClick={handleClose}>
              Cancelar
            </SecundaryButton>
          </ContainerButtons>
        </Form.Row>
      </Modal>
    </>
  );
};

export default ModalCadastro;
