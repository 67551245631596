/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Col, Spinner } from 'react-bootstrap';
import axios from '../../../config/axiosMaquina';
import MenuLateral from '../../../components/MenuLateral/MenuLateral';
import ButtonsForm from '../../../components/ButtonsForm/ButtonsForm';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { useToast } from '../../../hooks/Toast';
import { ClienteNomeType } from '../../../api/model/ListaReciclagemType';
import { ChecklistCadType } from '../../../api/model/ChecklistType';
import { VeiculoIdPlaca } from '../../../api/model/VeiculoIdPlacaType';
import { VeiculoDadosSM } from '../../../api/model/VeiculoDadosSMType';
import { CarretaIdPlaca } from '../../../api/model/CarretaIdPlacaType';
import { CarretaDadosSM } from '../../../api/model/CarretaDadosSMType';
import { BauIntercambiavelDadosSM } from '../../../api/model/BauIntercambiavelDadosSMType';
import { BauIdNumero } from '../../../api/model/BauIdNumeroType';
import {
  maskTel,
  FormataStringData2,
  FormataStringData4,
} from '../../../util/Validacoes/Validacoes';
import { CentroDistribuicaoTypes } from '../../../api/Types/portariaGridTypes';
import uniqBy from 'lodash/uniqBy';
import {
  dataHoraConverter,
  obterData,
  obterDataAtual,
  obterHora,
  toZoned,
} from '../../../util/date';
import { PERMISSION } from '../../../constants/Permissionamento';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import { getCookieSessionData } from '../../../services/cookieService';

type DispositivoData = {
  dispositivoId: number;
  dispositivoNome: string;
  situacaoDispositivo: string;
};

type VeiculoDataDisp = {
  id: number;
  idSat: number;
  nomeTipoFrota: string;
  modeloVeiculo: string;
  numeroEquipamento: string;
  placaVeiculo: string;
  tecnologiaVeiculo: string;
  tipoComunicacaoVeiculo: string;
  listaSatDispositivos: DispositivoData[];
};

type BauDataDisp = {
  id: number;
  idBau: number;
  numeroBau: string;
  idCliente: number;
  modeloBau: string;
  idSat: number;
  dataInclusaoSat: string;
  nomeResponsavel: string;
  listaSatDispositivos: DispositivoData[];
};

type TransportadorIdNomeType = {
  id: number;
  idCliente: number;
  razaoSocial: string;
  clienteEmbarcador: boolean;
};

function CadastroChecklist() {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canEdit } = useRolePermission();
  const { doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const { id }: any = useParams();
  const { addToast } = useToast();
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [loadClienteEmbarcador, setLoadClienteEmbarcador] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listaClienteIdRazaoSocial, setListaClienteIdRazaoSocial] = useState<ClienteNomeType[]>([]);
  const [cliente, setCliente] = useState();
  const [veiculoID, setVeiculoID] = useState();
  const [carretaID, setCarretaID] = useState();
  const [bauID, setBauID] = useState();
  const [transportadorID, setTransportadorID] = useState();
  const [clienteTransportadorID, setClienteTransportadorID] = useState<number>();
  const [cdOrigemID, setCdOrigemID] = useState();
  const [veiculoIdPlaca, setVeiculoIdPlaca] = useState<VeiculoIdPlaca[]>([]);
  const [veiculoData, setVeiculoData] = useState<VeiculoDadosSM>();
  const [carretaIdPlaca, setCarretaIdPlaca] = useState<CarretaIdPlaca[]>([]);
  const [bauIdNumero, setBauIdNumero] = useState<BauIdNumero[]>([]);
  const [transportadorIdNome, setTransportadorIdNome] = useState<TransportadorIdNomeType[]>([]);
  const [carretaData, setCarretaData] = useState<CarretaDadosSM>();
  const [bauData, setBauData] = useState<BauIntercambiavelDadosSM>();
  const [veiculoSelecionado, setVeiculoSelecionado] = useState(false);
  const [carretaSelecionado, setCarretaSelecionado] = useState(false);
  const [bauSelecionado, setBauSelecionado] = useState(false);
  const [pontoSelecionado, setPontoSelecionado] = useState(false);
  const [transportadorSelecionado, setTransportadorSelecionado] = useState(false);
  const [situacaoSelecionada, setSituacaoSelecionada] = useState(false);
  const [listPonto, setListPonto] = useState<CentroDistribuicaoTypes[]>([]);
  const [listaDispositivosById, setListaDispositivosById] = useState<DispositivoData[]>([]);
  const [veiculoDataDisp, setVeiculoDataDisp] = useState<VeiculoDataDisp>({} as VeiculoDataDisp);
  const [bauDataDisp, setBauDataDisp] = useState({} as BauDataDisp);
  const { clienteId, usuarioId } = getCookieSessionData().usuarioVO;

  const [msgCavaloMecanico, setMsgCavaloMecanico] = useState(false);

  const [isDialogVisibleCadEfetuado, setIsDialogVisibleCadEfetuado] = useState(false);
  const [isDialogVisibleMsgEmail, setIsDialogVisibleMsgEmail] = useState(false);
  const [isDialogVisibleCadOk, setIsDialogVisibleCadOk] = useState(false);
  const [isDialogVisibleAusenciaDispositivos, setIsDialogVisibleAusenciaDispositivos] =
    useState(false);

  const [clienteCnpj, setClienteCnpj] = useState('');

  const [listaSituacao, setListaSituacao] = useState([
    { value: '1', situacao: 'Não instalado' },
    { value: '2', situacao: 'OK' },
    { value: '3', situacao: 'Defeito' },
  ]);
  const [situacaoID, setSituacaoID] = useState<string[]>([]);

  const [updateModelOnData, setUpdateModelOnData] = useState(false);
  const [updateModelOnHora, setUpdateModelOnHora] = useState(false);

  const dataAtual = obterDataAtual();
  const horaAtual = obterHora();
  //const dataAtualIso = new Date().toISOString(); // passar qd nao mudar a data e a hora

  const [model, setModel] = useState<ChecklistCadType>({
    timeOnly: horaAtual,
    dateOnly: obterData(dataAtual),
    ativo: true,
    dataHoraInclusao: '',
    dataValidade: null,
    idBauIntercambiavel: null,
    idCarreta: null,
    idCliente: null,
    idClienteTransportador: null,
    idSeguradora: null,
    idEmbarcador: null,
    idPonto: null,
    idVeiculo: null,
    idUsuario: null,
    nomeResponsavel: '',
    telResponsavel: '',
    listaDispositivos: [
      {
        idDispositivo: null,
        situacaoDispositivo: '',
      },
    ],
  });

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO] }], 'editar');
    rolePermission([{ ADMIN: [ADMIN.OPERACIONAL.SOLICITACAO.CHECKLIST_VEICULO] }], 'inserir');
  }, [ADMIN, rolePermission]);

  const handleGoBack = useCallback(() => {
    history.goBack();
  }, []);

  const handleClienteEmbarcador = (selectedOption: React.ChangeEvent<HTMLInputElement>) => {
    setLoadClienteEmbarcador(selectedOption.target.value === 'Sim');
  };

  const loadListaDispositivos = (id: number, value: string) => {
    const lista = model.listaDispositivos.map((item) => {
      if (item.idDispositivo === id) {
        item.situacaoDispositivo = value;
      }
      return item;
    });
    if (!lista.some((item) => item.idDispositivo === id)) {
      lista.push({
        idDispositivo: id,
        situacaoDispositivo: value,
      });
    }
    setModel((prevState) => ({
      ...prevState,
      listaDispositivos: lista.filter((item) => item.idDispositivo),
    }));
  };

  const findTransportadorId = () => {
    setClienteTransportadorID(
      transportadorIdNome.find((t: any) => t.idEmbarcador === model.idEmbarcador)?.id
    );
  };

  useEffect(() => {
    transportadorID && findTransportadorId();
  }, [transportadorID]);

  const handlePontos = async (idCli: any) => {
    try {
      const response = await axios.get(
        `/solicitacao-monitoramento-plano-viagem/listarPontosPorCliente?idCliente=${idCli}`
      );

      setListPonto(response.data);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    const loadDadosChecklist = async () => {
      setLoading(true);
      if (!id) {
        return;
      } else {
        try {
          const response = await axios.get(`/checklist-veiculo/${id}`);
          if (response.data.idEmbarcador) {
            setClienteTransportadorID(response.data.idClienteTransportador);
          } else {
            setClienteTransportadorID(response.data.idCliente);
          }
          const listaDispById = [...response.data.listDispositivo];
          handlePontos(response.data.idCliente);
          setModel({
            timeOnly: response.data.dataHoraInclusao,
            dateOnly: response.data.dataHoraInclusao,
            ativo: true,
            dataHoraInclusao: response.data.dataHoraInclusao,
            dataValidade: response.data.dataValidade,
            idBauIntercambiavel: response.data.idBauIntercambiavel,
            idCarreta: response.data.idCarreta,
            idCliente: response.data.idCliente,
            idSeguradora: response.data.idSeguradora,
            idEmbarcador: response.data.idEmbarcador,
            idPonto: response.data.idPonto,
            idVeiculo: response.data.idVeiculo,
            nomeResponsavel: response.data.nomeResponsavel,
            telResponsavel: response.data.telResponsavel,
            listaDispositivos: listaDispById,
          });
          setCliente(response.data.idCliente);
          setLoadClienteEmbarcador(response.data.clienteEmbarcado);
          setLoading(false);
        } catch (err: any) {
          setLoading(false);
        }
      }
    };
    loadDadosChecklist();
  }, []);

  useEffect(() => {
    const loadDadosClienteIdRazaoSocial = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`/cliente/getIdRazaoSocial`);
        setListaClienteIdRazaoSocial(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };
    loadDadosClienteIdRazaoSocial();
  }, [setListaClienteIdRazaoSocial, setClienteCnpj]);

  useEffect(() => {
    const loadVeiculos = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/veiculo/listarIdEPlaca?cliente=${clienteTransportadorID}&grupoMacroVeículo=1`
        );
        setVeiculoIdPlaca(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };
    (clienteTransportadorID || model.idCliente) && loadVeiculos();
  }, [clienteTransportadorID, model.idCliente]);

  useEffect(() => {
    const loadClienteById = async () => {
      try {
        const response = await axios.get(`/cliente/${cliente}`);
        setClienteCnpj(response.data.cnpj);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };
    cliente && loadClienteById();
  }, [cliente]);

  useEffect(() => {
    const loadTransportador = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/checklist-veiculo/listar-cliente-rel-embarcador-cnpj?cnpj=${clienteCnpj}`
        );
        setTransportadorIdNome(response.data.filter((d: any) => d.id !== cliente));
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };
    clienteCnpj && loadTransportador();
  }, [clienteCnpj]);

  useEffect(() => {
    const loadCarretas = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/veiculo/listarIdEPlaca?cliente=${clienteTransportadorID}&grupoMacroVeículo=2`
        );
        setCarretaIdPlaca(response.data);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    };
    (clienteTransportadorID || model.idCliente) && loadCarretas();
  }, [clienteTransportadorID, model.idCliente]);

  useEffect(() => {
    const loadBau = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `/bau-intercambiavel/listaIdENumero?cliente=${clienteTransportadorID}`
        );
        setBauIdNumero(response.data);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };
    (clienteTransportadorID || model.idCliente) && loadBau();
  }, [clienteTransportadorID, model.idCliente]);

  useEffect(() => {
    const loadVeiculos = async () => {
      setLoading(true);
      if (id && model.idVeiculo) {
        try {
          const response = await axios.get(
            `/veiculo/listarDadosVeiculoParaSM?idCliente=${clienteTransportadorID}&veiculo=${model.idVeiculo}`
          );
          setVeiculoData(response.data);
          setLoading(false);
        } catch (err: any) {
          setLoading(false);
        }
      } else {
        if (veiculoID) {
          try {
            const response = await axios.get(
              `/veiculo/listarDadosVeiculoParaSM?idCliente=${clienteTransportadorID}&veiculo=${veiculoID}`
            );
            setVeiculoData(response.data);
            setLoading(false);

            if (response.data.tipoVeiculo.includes('CAVALO MECANICO')) {
              setMsgCavaloMecanico(true);
            } else {
              setMsgCavaloMecanico(false);
            }
          } catch (err: any) {
            setLoading(false);
          }
        }
      }
    };
    clienteTransportadorID && loadVeiculos();
  }, [veiculoID, model.idVeiculo, clienteTransportadorID]);

  useEffect(() => {
    const loadCarreta = async () => {
      setLoading(true);
      if (id && model.idCarreta) {
        try {
          const response = await axios.get(
            `/veiculo/listarDadosVeiculoParaSM?idCliente=${clienteTransportadorID}&veiculo=${model.idCarreta}`
          );
          setCarretaData(response.data);
          setLoading(false);
        } catch (err: any) {
          setLoading(false);
        }
      } else {
        try {
          const response = await axios.get(
            `/veiculo/listarDadosVeiculoParaSM?idCliente=${clienteTransportadorID}&veiculo=${carretaID}`
          );
          setCarretaData(response.data);
          setLoading(false);
        } catch (err: any) {
          setLoading(false);
        }
      }
    };

    clienteTransportadorID && loadCarreta();
  }, [carretaID, model.idCarreta, clienteTransportadorID]);

  const loadVeiculoDataDisp = useCallback(
    async (value: string) => {
      if (clienteTransportadorID) {
        try {
          const responseVeiculo = await axios.get(
            `/veiculo/listarDadosVeiculoParaSAT?idCliente=${clienteTransportadorID}&veiculo=${value}`
          );
          setVeiculoDataDisp(responseVeiculo.data);
        } catch (err: any) {
          console.log(err);
        }
      }
    },
    [clienteTransportadorID]
  );

  const loadBauDataDisp = useCallback(async (value: string) => {
    try {
      const responseBau = await axios.get('bau-intercambiavel/listarDadosBauParaSAT', {
        params: {
          bau: value,
        },
      });
      setBauDataDisp(responseBau.data);
    } catch (err: any) {
      console.log(err);
    }
  }, []);

  const handleRegister = async (event: any) => {
    event.preventDefault();

    if (loadClienteEmbarcador === true) {
      if (!model.idPonto || !model.idEmbarcador) {
        addToast({
          title: 'Erro',
          description: 'Verifique o preenchimento dos campos obrigatórios',
          type: 'error',
        });
        setIsFormInvalid(true);
        event.stopPropagation();
        return;
      }
    }

    if (
      !model.idCliente ||
      !model.timeOnly ||
      !model.dateOnly ||
      !model.nomeResponsavel ||
      !model.telResponsavel
    ) {
      addToast({
        title: 'Erro',
        description: 'Verifique o preenchimento dos campos obrigatórios',
        type: 'error',
      });
      setIsFormInvalid(true);
      event.stopPropagation();
      return;
    }

    if (!model.idCarreta && !model.idVeiculo && !model.idBauIntercambiavel) {
      addToast({
        title: 'Erro',
        description: 'Selecione um Veículo, uma Carreta ou um Baú.',
        type: 'error',
      });
      event.stopPropagation();
      return;
    }

    const dispArrayId = model.listaDispositivos;
    if (veiculoSelecionado || carretaSelecionado) {
      const dispArrayExistenteId = veiculoDataDisp?.listaSatDispositivos?.map((dispositivo) => ({
        idDispositivo: dispositivo.dispositivoId,
        situacaoDispositivo: '1',
      }));
      dispArrayId.push(...dispArrayExistenteId);
    }

    if (bauSelecionado) {
      const dispArrayExistenteId = bauDataDisp.listaSatDispositivos.map((dispositivo) => ({
        idDispositivo: dispositivo.dispositivoId,
        situacaoDispositivo: '1',
      }));
      dispArrayId.push(...dispArrayExistenteId);
    }

    //  TO-DO: INSERIR ID DO USUARIO QUE LANÇOU O CHECKLIST
    const data = {
      ...model,
      idClienteTransportador: loadClienteEmbarcador ? clienteTransportadorID : null,
      telResponsavel: model.telResponsavel.replace('(', '').replace(')', '').replace('-', ''),
      dataHoraInclusao: toZoned(`${obterData(model.dateOnly, 'en')} ${model.timeOnly}`),
      listaDispositivos: uniqBy(dispArrayId, 'idDispositivo'),
      idUsuario: usuarioId,
    };

    if (data.listaDispositivos === undefined) {
      event.stopPropagation();
      return;
    } else {
      if (data.listaDispositivos.length === 0) {
        setIsDialogVisibleAusenciaDispositivos(true);
        event.stopPropagation();
        return;
      }
    }

    try {
      setLoading(true);
      await axios.post('/checklist-veiculo/save-checklist-veiculo-dispositivo', data);
      setLoading(false);
      setIsDialogVisibleCadEfetuado(true);
    } catch (err: any) {
      if (err.response.data.message !== '') {
        setIsDialogVisibleCadOk(true);
        setLoading(false);
      }
      console.log(err.response);
    }
  };

  const renderListagemDispositivosVeiculo = () => (
    <>
      <Form.Label className="mt-5">Dispositivos Vinculados</Form.Label>
      <table className="table">
        <thead className="thead">
          <tr>
            {/* <th>Código</th> */}
            <th>Descrição</th>
            <th>Situação</th>
          </tr>
        </thead>
        <tbody>
          {veiculoDataDisp &&
            veiculoDataDisp.listaSatDispositivos &&
            veiculoDataDisp.listaSatDispositivos.map((dispositivo, index) => (
              <tr>
                <td style={{ width: 300 }}>{dispositivo.dispositivoNome}</td>
                <td key={index}>
                  <Dropdown
                    value={situacaoID[index]}
                    key={index}
                    options={listaSituacao}
                    onChange={(e) => {
                      loadListaDispositivos(dispositivo.dispositivoId, e.target.value);

                      const situacoes = situacaoID;
                      situacaoID[index] = e.target.value;
                      setSituacaoID(situacoes);
                      setSituacaoSelecionada(true);
                    }}
                    optionLabel="situacao"
                    optionValue="value"
                    filterBy="situacao"
                    placeholder="Não Instalado"
                    className="p-dropdown"
                    style={{ width: '50%' }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );

  const renderListagemDispositivosBau = () => (
    <>
      <Form.Label className="mt-5">Dispositivos Vinculados</Form.Label>
      <table className="table mt-5">
        <thead className="thead">
          <tr>
            <th>Descrição</th>
            <th>Situação</th>
          </tr>
        </thead>
        <tbody>
          {bauDataDisp &&
            bauDataDisp.listaSatDispositivos &&
            bauDataDisp.listaSatDispositivos.map((dispositivo: any, index) => (
              <tr>
                <td style={{ width: 300 }}>{dispositivo.dispositivoNome}</td>
                <td>
                  <Dropdown
                    value={situacaoID[index]}
                    key={dispositivo.dispositivoId}
                    options={listaSituacao}
                    onChange={(e) => {
                      loadListaDispositivos(dispositivo.dispositivoId, e.target.value);

                      const situacoes = situacaoID;
                      situacaoID[index] = e.target.value;
                      setSituacaoID(situacoes);
                      setSituacaoSelecionada(true);
                    }}
                    optionLabel="situacao"
                    optionValue="value"
                    filterBy="situacao"
                    placeholder="Não Instalado"
                    className="p-dropdown"
                    style={{ width: '50%' }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'row' }}>
      <MenuLateral />
      <div className="card">
        <div className="titulo-container">
          <h2 className="titulo">Checklist Veículo</h2>
          <h6 className="subtitulo">{'Cadastro > Checklist Veículo'}</h6>
        </div>

        <Dialog
          header="ATENÇÃO! Checklist não cadastrado. A placa selecionada não possui dispositivos cadastrados."
          footer={<Button onClick={() => setIsDialogVisibleAusenciaDispositivos(false)}>OK</Button>}
          visible={isDialogVisibleAusenciaDispositivos}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleAusenciaDispositivos(false)}
        />

        <Dialog
          header="Checklist cadastrado com sucesso."
          footer={<Button onClick={() => setIsDialogVisibleMsgEmail(true)}>OK</Button>}
          visible={isDialogVisibleCadOk}
          style={{ width: '50vw' }}
          modal
          onHide={() => setIsDialogVisibleMsgEmail(true)}
        />

        <Dialog
          header="Não foi possível enviar email de Checklist. Verifique o email cadastrado."
          footer={
            <Button
              onClick={() => {
                setIsDialogVisibleMsgEmail(false);
              }}
            >
              OK
            </Button>
          }
          visible={isDialogVisibleMsgEmail}
          style={{ width: '50vw' }}
          modal
          onHide={() => history.push('/cliente/operacional/listar-checklist/1')}
        />

        <Dialog
          header="Checklist foi cadastrado com sucesso!"
          footer={
            <>
              <Button
                label="OK"
                onClick={() => history.push('/cliente/operacional/listar-checklist/1')}
              />
            </>
          }
          visible={isDialogVisibleCadEfetuado}
          style={{ width: '50vw' }}
          modal
          onHide={() => history.push('/cliente/operacional/listar-checklist/1')}
        />
        {loading ? (
          <Spinner
            animation="border"
            variant="warning"
            style={{
              display: 'flex',
              marginLeft: '47.5%',
              marginTop: '5%',
              marginBottom: '5%',
            }}
          />
        ) : (
          <>
            <Form noValidate validated={isFormInvalid} onSubmit={handleRegister}>
              <h6 style={{ color: 'red', fontSize: 12, marginBottom: '25px' }}>
                * Campos Obrigatórios
              </h6>

              {!id && (
                <Form.Row>
                  <Form.Group as={Col}>
                    <Form.Label className="requiredField">Cliente Embarcador?</Form.Label>
                    <Form.Control
                      as="select"
                      value={loadClienteEmbarcador ? 'Sim' : 'Não'}
                      onChange={handleClienteEmbarcador}
                      style={{ width: '50%', height: 43 }}
                      required
                      disabled={id}
                    >
                      <option>Não</option>
                      <option>Sim</option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              )}

              <div className="header-associar-field">
                {loadClienteEmbarcador ? (
                  <Form.Label className="requiredField">Embarcador</Form.Label>
                ) : (
                  <Form.Label className="requiredField">Transportador</Form.Label>
                )}
                <Dropdown
                  value={id ? model.idCliente : cliente}
                  options={
                    id
                      ? listaClienteIdRazaoSocial
                      : loadClienteEmbarcador
                      ? listaClienteIdRazaoSocial.filter((fil) => fil.clienteEmbarcador === true)
                      : listaClienteIdRazaoSocial.filter((fil) => fil.clienteEmbarcador === false)
                  }
                  onChange={(e) => {
                    setCliente(e.target.value);
                    handlePontos(e.target.value);
                    setModel({
                      ...model,
                      idCliente: e.target.value,
                    });
                    setVeiculoSelecionado(false);
                    setCarretaSelecionado(false);
                    setBauSelecionado(false);
                    setTransportadorSelecionado(false);
                    setPontoSelecionado(false);
                    setClienteTransportadorID(e.target.value);
                  }}
                  optionLabel="razaoSocial"
                  optionValue="id"
                  filter
                  filterBy="razaoSocial"
                  placeholder="Selecione uma opção"
                  className="p-dropdown"
                  showClear
                  style={
                    isFormInvalid && !cliente
                      ? { borderColor: '#dc3545', width: '50%' }
                      : cliente && isFormInvalid
                      ? { borderColor: '#28a745', width: '50%' }
                      : { width: '50%' }
                  }
                  disabled={id}
                  required
                />
              </div>

              {loadClienteEmbarcador && (
                <>
                  <div className="header-associar-field">
                    <Form.Label className="requiredField mt-3">CD Origem</Form.Label>
                    {/* Trazer pelo id */}
                    <Dropdown
                      value={id ? model.idPonto : !pontoSelecionado ? '' : cdOrigemID}
                      options={listPonto}
                      onChange={(e) => {
                        setCdOrigemID(e.target.value);
                        setPontoSelecionado(true);
                        setModel({
                          ...model,
                          idPonto: parseInt(e.target.value, 10),
                        });
                      }}
                      optionLabel="nomePonto"
                      optionValue="idPonto"
                      filter
                      filterBy="nomePonto"
                      placeholder="Selecione uma opção"
                      className="p-dropdown"
                      style={
                        isFormInvalid && !pontoSelecionado
                          ? { borderColor: '#dc3545', width: '50%' }
                          : pontoSelecionado && isFormInvalid
                          ? { borderColor: '#28a745', width: '50%' }
                          : { width: '50%' }
                      }
                      disabled={id}
                      required
                    />
                  </div>

                  <div className="header-associar-field">
                    <Form.Label className="requiredField mt-3">Transportador</Form.Label>

                    <Dropdown
                      value={
                        id ? model.idEmbarcador : !transportadorSelecionado ? '' : transportadorID
                      }
                      options={transportadorIdNome}
                      onChange={(e) => {
                        setTransportadorID(e.target.value);
                        setTransportadorSelecionado(true);
                        setModel({
                          ...model,
                          idEmbarcador: parseInt(e.target.value, 10),
                        });
                      }}
                      optionLabel="razaoSocial"
                      optionValue="idEmbarcador"
                      filter
                      filterBy="razaoSocial"
                      placeholder="Selecione uma opção"
                      className="p-dropdown"
                      style={
                        isFormInvalid && !transportadorSelecionado
                          ? { borderColor: '#dc3545', width: '50%' }
                          : transportadorSelecionado && isFormInvalid
                          ? { borderColor: '#28a745', width: '50%' }
                          : { width: '50%' }
                      }
                      disabled={id}
                      required
                    />
                  </div>
                </>
              )}

              <Form.Row>
                <Form.Group as={Col} style={{ marginTop: 20, marginBottom: 20 }}>
                  <hr />
                </Form.Group>
              </Form.Row>

              {carretaSelecionado ||
              bauSelecionado ||
              (id && model.idCarreta) ||
              (id && model.idBauIntercambiavel) ? null : (
                <div className="header-associar-field">
                  <Form.Label>Veículo</Form.Label>

                  <Dropdown
                    value={id ? model.idVeiculo : !veiculoSelecionado ? '' : veiculoID}
                    options={veiculoIdPlaca}
                    onChange={(e) => {
                      setVeiculoID(e.target.value);
                      setModel({
                        ...model,
                        idVeiculo: e.target.value,
                      });
                      setVeiculoSelecionado(true);
                      loadVeiculoDataDisp(e.target.value);
                    }}
                    optionLabel="placa"
                    optionValue="id"
                    filter
                    filterBy="placa"
                    placeholder="Selecione uma opção"
                    className="p-dropdown"
                    style={{ width: '50%' }}
                    disabled={id}
                  />
                </div>
              )}

              {(veiculoSelecionado || id) && veiculoData && (
                <Form.Row>
                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta" className="mt-3">
                    <Form.Label>Tipo Veículo</Form.Label>
                    <Form.Control value={veiculoData.tipoVeiculo} disabled></Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta" className="mt-3">
                    <Form.Label>Tipo Frota</Form.Label>
                    <Form.Control value={veiculoData.nomeTipoFrota} disabled></Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta" className="mt-3">
                    <Form.Label>Tecnologia</Form.Label>
                    <Form.Control value={veiculoData.tecnologia} disabled></Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta" className="mt-3">
                    <Form.Label>Comunicação</Form.Label>
                    <Form.Control value={veiculoData.meioComunicacao} disabled></Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta">
                    <Form.Label>Nº Equipamento</Form.Label>
                    <Form.Control value={veiculoData.numeroEquipamento} disabled></Form.Control>
                  </Form.Group>
                </Form.Row>
              )}

              {veiculoSelecionado ||
              bauSelecionado ||
              (id && model.idVeiculo) ||
              (id && model.idBauIntercambiavel) ? null : (
                <div className="header-associar-field">
                  <Form.Label className=" mt-3">Carreta</Form.Label>

                  <Dropdown
                    value={id ? model.idCarreta : !carretaSelecionado ? '' : carretaID}
                    options={carretaIdPlaca}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        idCarreta: e.target.value,
                      });
                      setCarretaID(e.target.value);
                      setCarretaSelecionado(true);
                      loadVeiculoDataDisp(e.target.value);
                    }}
                    optionLabel="placa"
                    optionValue="id"
                    filter
                    filterBy="placa"
                    placeholder="Selecione uma opção"
                    className="p-dropdown"
                    style={{ width: '50%' }}
                    disabled={id}
                  />
                </div>
              )}

              {(carretaSelecionado || id) && carretaData && (
                <Form.Row>
                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta" className="mt-3">
                    <Form.Label>Tipo Veículo</Form.Label>
                    <Form.Control value={carretaData.tipoGeralCarreta} disabled></Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={3} controlId="formGridAdicaoEscolta" className="mt-3">
                    <Form.Label>Tipo Frota</Form.Label>
                    <Form.Control value={carretaData.nomeTipoFrota} disabled></Form.Control>
                  </Form.Group>
                </Form.Row>
              )}

              {veiculoSelecionado ||
              carretaSelecionado ||
              (id && model.idVeiculo) ||
              (id && model.idCarreta) ? null : (
                <div className="header-associar-field">
                  <Form.Label className=" mt-3">Baú</Form.Label>

                  <Dropdown
                    value={id ? model.idBauIntercambiavel : !bauSelecionado ? '' : bauID}
                    options={bauIdNumero}
                    onChange={(e) => {
                      setBauID(e.target.value);
                      setModel({
                        ...model,
                        idBauIntercambiavel: e.target.value,
                      });
                      setBauSelecionado(true);
                      loadBauDataDisp(e.target.value);
                    }}
                    optionLabel="numero"
                    optionValue="id"
                    filter
                    filterBy="numero"
                    placeholder="Selecione uma opção"
                    className="p-dropdown"
                    style={{ width: '50%' }}
                    disabled={id}
                  />
                </div>
              )}

              {msgCavaloMecanico ? (
                <Form.Row className="justify-content-md-center">
                  <Form.Group controlId="formGridAlertaChecklist">
                    <Form.Label className="txt_alerta">
                      ATENÇÃO! Este veículo é um cavalo mecânico. Verificar junto ao cliente se há
                      carretas atreladas a este cavalo.
                    </Form.Label>
                  </Form.Group>
                </Form.Row>
              ) : null}

              <Form.Row>
                <Form.Group as={Col} style={{ marginTop: 20, marginBottom: 20 }}>
                  <hr />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} className="requiredField">
                  <Form.Label>Data</Form.Label>
                  <Form.Control
                    value={
                      id
                        ? model.dataHoraInclusao
                          ? obterData(model.dataHoraInclusao)
                          : ''
                        : !updateModelOnData
                        ? dataAtual
                        : model.dateOnly
                    }
                    onChange={(e: any) => {
                      setModel({
                        ...model,
                        dateOnly: e.target.value,
                      });
                      setUpdateModelOnData(true);
                    }}
                    id="data"
                    name="dataHoraInclusao"
                    type={id ? 'text' : 'date'}
                    required
                    disabled={id}
                  />
                </Form.Group>
                <Form.Group as={Col} className="requiredField">
                  <Form.Label>Hora</Form.Label>
                  <Form.Control
                    value={
                      id
                        ? model.dataHoraInclusao
                          ? obterHora(model.dataHoraInclusao)
                          : ''
                        : !updateModelOnHora
                        ? horaAtual
                        : model.timeOnly.toString()
                    }
                    onChange={(e) => {
                      setModel({
                        ...model,
                        timeOnly: e.target.value,
                      });
                      setUpdateModelOnHora(true);
                    }}
                    id="hora"
                    name="horaOcorrencia"
                    type={'time'}
                    required
                    disabled={id}
                  />
                </Form.Group>

                <Form.Group as={Col} className="requiredField">
                  <Form.Label>Responsável</Form.Label>
                  <Form.Control
                    value={model.nomeResponsavel}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        nomeResponsavel: e.target.value,
                      });
                    }}
                    id="nomeResponsavel"
                    name="nomeResponsavel"
                    type="text"
                    required
                    disabled={id}
                  />
                </Form.Group>

                <Form.Group as={Col} className="requiredField">
                  <Form.Label>Telefone</Form.Label>
                  <Form.Control
                    value={maskTel(model.telResponsavel)}
                    onChange={(e) => {
                      setModel({
                        ...model,
                        telResponsavel: e.target.value,
                      });
                    }}
                    id="telResponsavel"
                    name="telResponsavel"
                    type="text"
                    required
                    disabled={id}
                    maxLength={14}
                  />
                </Form.Group>
              </Form.Row>

              {bauSelecionado && renderListagemDispositivosBau()}
              {veiculoSelecionado || carretaSelecionado
                ? renderListagemDispositivosVeiculo()
                : null}

              {id && model.listaDispositivos && (
                <>
                  <Form.Label className="mt-5">Dispositivos Vinculados</Form.Label>
                  <table className="table mt-3">
                    <thead className="thead">
                      <tr>
                        {/* <th>Código</th> */}
                        <th>Descrição</th>
                        <th>Situação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {model.listaDispositivos
                        .filter((fil) => fil.situacaoDispositivo !== '1')
                        .map((dispositivo: any, index) => (
                          <tr>
                            {/* <td style={{ width: 65 }}>{dispositivo.dispositivoId}</td> */}
                            <td style={{ width: 300 }}>{dispositivo.nomeDispositivo}</td>
                            {dispositivo.situacaoDispositivo === '1' && (
                              <td style={{ width: 300 }}>Não Instalado</td>
                            )}
                            {dispositivo.situacaoDispositivo === '2' && (
                              <td style={{ width: 300 }}>OK</td>
                            )}
                            {dispositivo.situacaoDispositivo === '3' && (
                              <td style={{ width: 300 }}>Defeito</td>
                            )}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </>
              )}

              <Form.Row>
                <Form.Group as={Col} style={{ marginTop: 20, marginBottom: 20 }}>
                  <hr />
                </Form.Group>
              </Form.Row>

              {id ? (
                <Form.Row className="container-buttons justify-content-end">
                  <Form.Group as={Col} md={2}>
                    <Button
                      className="btn-cancelar"
                      label="Cancelar"
                      onClick={handleGoBack}
                      type="button"
                      style={{
                        width: ' 175px',
                        height: '50px',
                        border: '2px solid #0B141F',
                        background: ' #FFF ',
                        color: '#0B141F',
                        borderRadius: '5px',
                        opacity: 1,
                        fontWeight: 400,
                      }}
                    ></Button>
                  </Form.Group>
                </Form.Row>
              ) : (
                <ButtonsForm canInsert={(canEdit && id) || (canInsert && !id)} />
              )}
            </Form>
          </>
        )}
      </div>
    </div>
  );
}

export default CadastroChecklist;
