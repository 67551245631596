/* eslint-disable */
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useToast } from '../../../hooks/Toast';
import { getCookieSessionData } from '../../../services/cookieService';

const TabGerarSenha = ({ idCliente }: { idCliente: any }) => {
  const usuario = getCookieSessionData().usuarioVO.usuarioId;

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [password, setPassword] = useState('');
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    if (!refresh) return;

    axios
      .get(`${process.env.REACT_APP_API_URL}/client/get-integration-password/`, {
        params: {
          idCliente: Number(idCliente),
        },
      })
      .then((res) => {
        setPassword(res.data.password);
      })
      .catch((e) => {
        setPassword('');
      })
      .finally(() => setRefresh(false));
  }, [refresh]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/client/get-integration-password/`, {
        params: {
          idCliente: Number(idCliente),
        },
      })
      .then((res) => {
        setPassword(res.data.password);
      })
      .catch((e) => {
        setPassword('');
      });
  }, [idCliente]);

  const handlePassword = () => {
    setLoading(true);

    axios
      .post(`${process.env.REACT_APP_API_URL}/client/create-integration-password/`, {
        idCliente: Number(idCliente),
        idUsuario: usuario || null
      })
      .then((res) => {
        setRefresh(true);
      })
      .catch((e) => {
        addToast({
          title: 'Erro',
          description: 'Ocorreu um problema ao gerar a senha',
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {password !== '' && (
          <>
            <div style={{ marginRight: '10px' }}>Senha atual:</div>
            <div
              style={{
                marginRight: '10px',
                background: '#d8d7d6',
                borderColor: 'none',
                border: 'none',
                padding: '10px',
                width: 150,
                fontSize: 13,
                borderRadius: 5,
              }}
            >
              <strong>{password}</strong>
            </div>
          </>
        )}

        <button
          onClick={handlePassword}
          type="button"
          style={{
            background: '#ffda53',
            borderColor: 'none',
            border: 'none',
            padding: '10px',
            width: password ? 200 : 150,
            fontSize: 13,
            borderRadius: 5,
          }}
        >
          {loading ? (
            <>
              <Spinner
                animation="border"
                style={{
                  display: 'flex',
                  marginLeft: '40%',
                  color: '#000',
                }}
              />
            </>
          ) : (
            <>{password ? ' Gerar nova senha (WS)' : ' Gerar senha (WS)'}</>
          )}
        </button>
      </div>
    </>
  );
};

export default TabGerarSenha;
