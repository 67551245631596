/* eslint-disable */

import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import sortBy from 'lodash/sortBy';
import { Spinner } from 'react-bootstrap';
import axios from '../../config/axiosMaquina';
import { UsuariosType, ClientesType } from '../../api/model/UsuariosType';
import { getCookieSessionData } from '../../services/cookieService';
import { Tela } from '../../components/Tela';
import { filterItems2 } from '../../util/format';
import Search from '../../components/Search/Search';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';
import { profileMenu } from '../../services/profileMenu';
import { ButtonTooltip } from '../../components/ButtonTooltipo';

type Props = {
  canInsert?: boolean;
};

function UsuariosClientes() {
  const { ADMIN, TRANSPORTADOR, EMBARCADOR } = PERMISSION;

  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [itensPerPage, setItensPerPage] = useState('10');
  const [customerRole, setCustomerRole] = useState('user');
  const { page }: any = useParams();
  const [cliente, setCliente] = useState<number>();
  const [listaClientes, setListaClientes] = useState<ClientesType[]>([]);
  const { clienteId, role, usuarioId } = getCookieSessionData().usuarioVO;
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const indexOfLastPost = page * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const headers = {
    id: 'Id',
    usuCdId: 'Código do Cliente',
    clienteId: 'Código do Usuário',
    login: 'Login',
    nome: 'Nome',
  };

  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const currentData =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: number) => {
    history.push(`/listar-perfis-usuarios/${pageNumber}`);
  };

  const converteArray = (dt: any[]) => {
    return dt.map((each: UsuariosType) => {
      return {
        id: each.usuCdId,
        clienteId: each.clienteId,
        usuCdId: each.usuCdId,
        login: each.login,
        nome: each.nome,
      };
    });
  };

  useEffect(() => {
    const fetchDataPlaca = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`/cliente/getIdRazaoSocial`);
        setListaClientes(response.data);
      } catch (err: any) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    fetchDataPlaca();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (role === 'admin' && cliente) {
        try {
          setLoading(true);

          setData([]);

          profileMenu(cliente).then((res: any) => {
            setData(res.data);
          });
        } catch (err: any) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        try {
          setLoading(true);

          setData([]);

          profileMenu(clienteId).then((res: any) => {
            setData(res.data);
          });

          setLoading(false);
        } catch (err: any) {
          console.log(err);
          setLoading(false);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [cliente, clienteId, role]);

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      setDataSearch(filterItems2(data, searchValue));
    }
  }, [data, searchValue]);

  useEffect(() => {
    rolePermission(
      [
        {
          TRANSPORTADOR: [TRANSPORTADOR.USUARIO.PERFIS_DE_USUARIOS],
          EMBARCADOR: [EMBARCADOR.USUARIO.PERFIL_DE_USUARIO],
          ADMIN: [ADMIN.USUARIO.PERFIL_USUARIO],
        },
      ],
      'inserir'
    );
  }, [ADMIN, EMBARCADOR, TRANSPORTADOR, rolePermission]);

  return (
    <Tela
      nome="Usuário"
      caminho="Usuários > Inclusão/Alteração(Perfis Usuários)"
      loading={loading}
      setLoading={setLoading}
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate}
      currentPage={page}
    >
      {role === 'admin' ? (
        <div className="header-associar-container">
          <div className="header-associar-field">
            <span>Cliente</span>
            <Dropdown
              value={cliente}
              options={listaClientes}
              onChange={(e) => {
                const getRole: any = listaClientes.find(
                  ({ id }: { id: number }) => id === e.target.value
                );

                setCustomerRole(getRole.clienteEmbarcador ? 'embarcador' : 'user');

                setCliente(e.target.value);
              }}
              optionLabel="razaoSocial"
              optionValue="id"
              filter
              filterBy="razaoSocial"
              placeholder="Selecione uma opção"
              className="p-dropdown"
              showClear
              style={{ width: '120%' }}
            />
          </div>
        </div>
      ) : (
        <Search
          setLoading={setLoadingSearch}
          setSearch={setSearchTerm}
          setSearchValue={setSearchValue}
          search={searchTerm}
          linkToAdd={
            cliente
              ? `/cadastro-perfis-usuarios/${cliente}`
              : clienteId
              ? `/cadastro-perfis-usuarios/${clienteId}`
              : '/cadastro-perfis-usuarios/'
          }
          createDisabled={!canInsert}
        />
      )}
      {cliente && (
        <Search
          setLoading={setLoadingSearch}
          setSearch={setSearchTerm}
          setSearchValue={setSearchValue}
          search={searchTerm}
          linkToAdd={cliente ? `/cadastro-perfis-usuarios/${cliente}` : '/cadastro-perfis-usuarios'}
          createDisabled={!canInsert}
        />
      )}
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          style={{
            display: 'flex',
            marginLeft: '47.5%',
            marginTop: '5%',
            marginBottom: '5%',
          }}
        />
      ) : (
        <table className="table">
          <thead className="thead">
            <tr>
              <th>Nome</th>

              <th />
            </tr>
          </thead>
          <tbody>
            {currentData &&
              currentData.map((each: any, index) => {
                return (
                  <tr key={index}>
                    <td>{each.mep_tx_nome_menu}</td>
                    <td align="left">
                      <ButtonTooltip
                        type="Editar"
                        onClick={() => {
                          if (role === 'user' && clienteId) {
                            const getRole: any = listaClientes.find(
                              ({ id }: { id: number }) => id === clienteId
                            );

                            history.push({
                              pathname: `/edicao-perfis-usuarios/${each.mep_cd_id}`,
                              state: {
                                customerRole: getRole.clienteEmbarcador ? 'embarcador' : 'user',
                              },
                            });

                            return;
                          }

                          history.push({
                            pathname: `/edicao-perfis-usuarios/${each.mep_cd_id}`,
                            state: {
                              customerRole,
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </Tela>
  );
}

export default UsuariosClientes;
