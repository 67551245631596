/* eslint-disable */

import React from 'react';

export default function Condutor({
  processos,
  processosErrors,
}: {
  processos: any;
  processosErrors: any;
}) {
  return (
    <>

      <ul style={{ listStyleType: 'none' }}>
        <>
          {processosErrors && processosErrors.length > 0 && (
            <>
              {processosErrors.map((each: any) => {
                return (
                  <li style={{ padding: '5px', borderBottom: '1px solid #b5aeae' }}>
                    <p>{each}</p>
                  </li>
                );
              })}
            </>
          )}

          {processos &&
            processos.length > 0 &&
            processos.map((each: any) => {
              return (
                <li style={{ padding: '5px', borderBottom: '1px solid #b5aeae' }}>
                  <br />
                  <p>Dados Processo</p>
                  <br />
                  <div>
                    <p>Processo: {each.processo}</p>
                    <p>UF: {each.uf}</p>
                    <p>Classificação: {each.classificacao}</p>
                    <p>Situação: {each.situacao}</p>
                    <p>Comarca: {each.comarca}</p>
                    <p>Vara: {each.vara}</p>
                    <p>Classificação da Parte: {each.classificacao_parte}</p>
                    <p>Situação da Parte: {each.situacao_parte}</p>
                    {each.data_processo && <p>Data do Processo: {each.data_processo}</p>}
                    <p>Classe: {each.classe}</p>
                    {each.assuntos && <p>Assuntos: {each.assuntos}</p>}
                    {each.artigos && <p>Artigos: {each.artigos}</p>}
                  </div>
                </li>
              );
            })}
        </>
      </ul>
    </>
  );
}
