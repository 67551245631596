import * as React from 'react';

const SvgComponent = (props: any) => (
  <svg id="a" xmlns="http://www.w3.org/2000/svg" width={1006.92} height={625.04} {...props}>
    <defs>
      <style>{'.d{stroke:#1d1d1b;stroke-miterlimit:10;stroke-width:.69px}'}</style>
    </defs>
    <path
      className="svg-elem-1"
      d="M634.13 93.15a5.07 5.07 0 0 0-3.56-4.63L510.08 50.75a22.213 22.213 0 0 0-13.26 0L376.33 88.52a5.083 5.083 0 0 0-3.56 4.63l-6.17 144.23c-.34 7.83.68 15.66 3.13 23.1 8.1 24.52 35.41 71.67 127.56 110.73 3.91 1.66 8.39 1.66 12.3 0 92.15-39.06 119.46-86.21 127.56-110.73 2.46-7.45 3.47-15.27 3.14-23.1l-6.17-144.23Z"
      style={{
        stroke: '#1d1d1b',
        strokeMiterlimit: 10,
        fill: '#fff',
      }}
    />
    <path
      className="svg-elem-2"
      d="M617.98 107.73a4.45 4.45 0 0 0-3.12-4.06l-105.59-33.1a19.357 19.357 0 0 0-11.62 0l-105.59 33.1a4.46 4.46 0 0 0-3.12 4.06l-5.41 126.41c-.29 6.87.59 13.72 2.75 20.25 7.1 21.49 31.03 62.81 111.79 97.04 3.43 1.45 7.35 1.45 10.78 0 80.76-34.23 104.7-75.56 111.79-97.04 2.15-6.53 3.04-13.38 2.75-20.25l-5.41-126.41Z"
      style={{
        fill: '#191718',
        stroke: '#1d1d1b',
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="svg-elem-3"
      d="M604.63 192.98H445.4v-40.91l49.73-18.78c2.88-1.09 6.04-1.12 8.94-.09l53.02 18.86v20.46h46.67l-2.14-50.02a3.82 3.82 0 0 0-2.67-3.48l-90.51-28.37a16.592 16.592 0 0 0-9.96 0l-90.51 28.37c-1.54.48-2.6 1.87-2.67 3.48l-4.64 108.35c-.25 5.88.51 11.76 2.35 17.35 6.08 18.42 26.6 53.84 95.82 83.18a11.94 11.94 0 0 0 9.24 0c69.22-29.34 89.74-64.76 95.82-83.18 1.85-5.59 2.61-11.47 2.35-17.35l-1.62-37.87Zm-99.72 92.96h-16.66l8.33-34.34c-3.96-2.68-6.56-7.21-6.56-12.34 0-8.22 6.67-14.89 14.89-14.89s14.89 6.67 14.89 14.89c0 5.14-2.6 9.67-6.56 12.34l8.33 34.34h-16.66Z"
      style={{
        fill: '#e1af3c',
        stroke: '#1d1d1b',
        strokeMiterlimit: 10,
      }}
    />
    <path
      className="d svg-elem-4"
      d="M493.75 494.09c-4.42 0-8.65-.03-12.88.02-1.27.01-1.79-.76-2.3-1.71-6.89-12.96-13.8-25.9-20.7-38.86-.48-.9-.89-1.83-1.77-2.7v43.09h-12.19v-61c2.41-.02 4.99-.04 7.57-.05h8.21c7.58 13.68 16.03 26.91 22.15 41.92v-41.68h11.91v60.99Z"
    />
    <path
      className="d svg-elem-5"
      d="M332.74 433.59c10.22-1.12 20.27-1.89 30.31-.08 14.24 2.57 22.67 12.15 23.14 26.59.14 4.19-.33 8.53-1.34 12.59-2.21 8.89-7.7 15.22-16.31 18.68-9.7 3.9-24.34 4.19-35.6 2.29-.75-2.36-.98-55.32-.19-60.07Zm13.72 50.08c.99.09 1.65.2 2.32.2 1.83 0 3.68.09 5.5-.11 9.04-.97 14.76-5.94 16.45-14.9.69-3.65.68-7.55.29-11.26-.49-4.58-2.7-8.5-6.65-11.18-5.48-3.72-11.53-3.75-17.91-2.84v40.08Z"
    />
    <path
      className="d svg-elem-6"
      d="M277.11 462.47c-.11 5.93-.79 11.02-2.9 15.83-4.43 10.08-12.35 15.36-23.09 16.55-4.23.47-8.48.31-12.62-.91-7.2-2.12-12.73-6.46-16.02-13.15-5.43-11.06-5.48-22.41-.13-33.49 3.8-7.88 10.23-12.85 18.9-14.58 6.66-1.33 13.28-1.14 19.55 1.73 9.78 4.48 14.4 12.68 15.95 22.92.29 1.92.29 3.88.38 5.1Zm-14.68 1.38c.03-5.94-1.03-10.42-3.36-14.18-5.67-9.13-17.65-9.12-22.65.35-4.6 8.72-4.64 17.9-.19 26.76 2.38 4.73 6.43 7.34 11.96 7.22 5.42-.11 8.99-3 11.42-7.54 2.1-3.92 2.88-8.18 2.82-12.61Z"
    />
    <path
      className="d svg-elem-7"
      d="M210.33 491.45c-10.32 3.18-20.68 4.55-31.29 2.17-12.57-2.83-20.7-11.81-22.11-24.55-.52-4.7-.33-9.41.91-14.01 2.78-10.38 9.5-17.16 19.5-20.58 9.15-3.13 18.5-2.97 27.82-.55.77.2 1.52.5 2.27.79.31.12.59.33 1.15.65-.9 3.47-1.81 6.97-2.78 10.72-.96-.24-1.7-.38-2.41-.63-5.1-1.74-10.36-2.09-15.64-1.5-9.27 1.03-15.55 7.57-16.28 17.16-.23 3.03 0 6.26.8 9.19 2.41 8.81 9.56 13.64 19.35 13.12 6.1-.33 5.55.41 5.56-5.84v-7.48h-8.68c-.81-2.75-.9-6.7-.31-10.86.39-.05.82-.16 1.25-.16 6.88-.01 13.76 0 20.89 0v32.38Z"
    />
    <path
      className="d svg-elem-8"
      d="M635.2 493.9h-13.54v-60.1c.88-.17 1.85-.45 2.84-.55 9.14-.89 18.29-1.72 27.38.36 7.35 1.67 11.98 6.42 13 13 1.17 7.57-1.81 13.79-8.48 17.7-.39.23-.79.43-1.13.61 0 .32-.08.66.01.72 3.53 2.16 5.42 5.5 6.68 9.28.25.76.45 1.54.66 2.31 1.46 5.41 2.92 10.82 4.49 16.67-4.65.38-9.15.1-14.02.2-.86-2.66-1.75-5.22-2.51-7.83-.73-2.53-1.29-5.11-1.96-7.66-1.8-6.84-4.15-8.62-11.2-8.53-.56 0-1.13.05-2.15.11-.17 4-.05 7.96-.07 11.92-.02 3.87 0 7.74 0 11.79Zm.2-50.62v16.21c4.58.71 8.22.53 10.65-.48 3.65-1.53 5.56-4.75 5.21-8.79-.32-3.7-2.56-6.14-6.42-7.08-3.07-.75-6.13-.69-9.44.15Z"
    />
    <path
      className="d svg-elem-9"
      d="M409.31 467.97v14.57H434c.53 3.93.28 7.53.19 11.34h-38.37c-.72-2.65-1.03-51.75-.37-60.76h37.23v11.19H409.4v12.52h21.87v11.15h-21.96Z"
    />
    <path
      className="d svg-elem-10"
      d="M573.36 432.97h37v11.32h-23.08v12.54h21.89v11.13h-22.05c-.14 4.94-.03 9.57-.06 14.59h24.7v11.36h-38.4v-60.94Z"
    />
    <path
      className="d svg-elem-11"
      d="M850.25 482.57v11.33h-38.5v-60.85h36.88c.7 2.49.77 7.77.07 11.41h-23.01v12.29h21.86v11.21h-21.34c-.83 2.76-.98 9.96-.34 14.61h24.38Z"
    />
    <path
      className="d svg-elem-12"
      d="M710.78 432.96h14.82c-6.88 20.45-13.68 40.68-20.51 60.98h-16.33c-6.46-20.18-12.93-40.35-19.49-60.83h15.28c4.3 15.15 9.1 30.38 12.8 46.66 2.32-8.46 4.35-16.16 6.55-23.81 2.18-7.6 4.53-15.15 6.88-22.99Z"
    />
    <path
      className="d svg-elem-13"
      d="M561.48 434.75c-1.14 4.1-2.12 7.59-3.08 11.02-3.61-.82-6.9-1.8-10.27-2.23-2.22-.28-4.58 0-6.8.45-2.39.49-4.15 2.07-4.52 4.63-.36 2.49 1.03 4.23 3.06 5.31 2.52 1.34 5.2 2.39 7.78 3.63 2.99 1.44 6.09 2.72 8.87 4.5 10.39 6.65 9.38 22.83-1.06 28.98-3.12 1.84-6.47 2.94-10.02 3.44-7.29 1.02-14.42.37-21.33-2.24-.43-.16-.84-.36-1.25-.57-.2-.1-.35-.28-.88-.73.83-3.52 1.71-7.24 2.65-11.2 4.74 2 9.29 3.64 14.17 3.62 2.25 0 4.64-.18 6.72-.95 4.81-1.78 5.49-7.35 1.28-10.32-2.03-1.43-4.45-2.32-6.72-3.4-2.89-1.38-5.95-2.47-8.69-4.09-6.15-3.64-9.37-8.98-8.55-16.34.78-6.99 4.84-11.48 11.07-14.16 6.34-2.74 12.97-2.67 19.62-1.53 2.56.44 5.04 1.36 7.94 2.17Z"
    />
    <path
      className="d svg-elem-14"
      d="M802.07 492.77c-2.75.58-5.6 1.41-8.51 1.76-6.65.81-13.27.66-19.71-1.55-10.56-3.62-16.48-11.15-18.53-21.93-.95-5.02-.81-10.06.32-15.03 3.04-13.38 13.53-22.51 27.3-23.83 5.05-.49 10.08-.39 15.05.71 1.65.37 3.25.95 5.24 1.54-.96 3.77-1.85 7.26-2.76 10.85-2.93-.62-5.59-1.21-8.27-1.72-3.34-.64-6.65-.25-9.87.69-6.67 1.94-10.67 6.51-12.26 13.15-1.05 4.41-.97 8.87.31 13.27 2.06 7.06 7.53 11.62 14.81 12.54 4.98.63 9.81-.12 14.55-1.45 1.29 2.02 2.38 7.01 2.32 11Z"
    />
    <path
      className="d svg-elem-15"
      d="M324.25 482.28v11.23c-2.43.72-33.88.88-37.9.17v-60.61h13.67v49.22h24.23Z"
    />
    <path className="d svg-elem-16" d="M732.05 433.01h13.5v60.86h-13.5v-60.86Z" />
    <path
      className="d svg-elem-17"
      d="M158.09 518.36h6.03c4.15 6.88 8.28 13.72 12.58 20.82v-20.62h4.74v28.78c-.89.09-1.75.18-2.62.28-1.9.22-3.16-.41-4.18-2.2-3.51-6.18-7.21-12.26-10.84-18.38-.21-.36-.45-.71-1.06-1.65v21.97h-4.66v-29.01Z"
    />
    <path
      className="d svg-elem-18"
      d="M708.97 545.64c-2.18.58-4.42 1.57-6.67 1.63-1.67.05-3.67-.75-5.01-1.81-3.79-2.99-4.2-7.25-3.23-11.59.9-4 3.44-6.61 7.61-7.53 2.99-.66 5.39.39 7.78 2.26.82-2.88 2.97-1.82 5.17-1.89v5.89c0 4.37.1 8.73-.04 13.09-.26 7.49-4.04 11.07-11.52 11.15-2.39.02-4.93-.61-7.45-1.92.32-1.1.66-2.25 1.02-3.48 1.72.43 3.13.87 4.57 1.12 5.31.93 8.22-1.85 7.78-6.92Zm.13-8.63c0-.68.04-1.37 0-2.05-.16-2.57-1.47-4.28-3.63-4.78-2.23-.51-4.47.65-5.55 3-1.15 2.52-1.19 5.09.05 7.59 1.06 2.15 3.09 3.08 5.4 2.59 2.21-.47 3.49-1.92 3.72-4.3.07-.68.01-1.37.01-2.05Z"
    />
    <path
      className="d svg-elem-19"
      d="M331.77 546.29v9.78h-5.24v-29.21h4.04l.98 2.33c2.78-2.52 5.86-3.69 9.52-2.64.33.09.67.15.99.28 4.47 1.81 6.78 7.75 5.29 13.53-1.37 5.3-5.51 8.36-10.59 7.62-1.53-.22-2.98-.99-4.97-1.69Zm.08-9.02h-.02c0 .57-.03 1.15 0 1.72.18 2.72 1.61 4.5 3.93 4.91 2.65.47 4.73-.61 5.81-3.15.99-2.31 1.06-4.67.09-7.01-1-2.42-3.11-3.79-5.33-3.46-2.43.36-4.21 2.3-4.47 4.93-.07.68-.01 1.37-.01 2.06Z"
    />
    <path
      className="d svg-elem-20"
      d="M186.36 537.19c.05-6.71 4.72-11.26 11.39-11.12 6.27.13 10.77 4.85 10.64 11.15-.13 6.46-4.8 10.94-11.31 10.87-6.49-.07-10.76-4.41-10.72-10.9Zm5.3.23c.54 1.54.86 3.29 1.73 4.69 1.94 3.13 6.05 3.05 7.95-.09 1.79-2.96 1.82-6.11.33-9.21-.86-1.79-2.32-2.78-4.4-2.74-2.08.04-3.53 1.09-4.34 2.86-.6 1.33-.82 2.83-1.27 4.49Z"
    />
    <path
      className="d svg-elem-21"
      d="M720.56 526.79h5.19c0 3.32-.03 6.5.01 9.68.01 1.25.03 2.53.29 3.75.53 2.51 1.81 3.63 3.88 3.63 2.18 0 4.11-1.66 4.49-4 .15-.9.09-1.83.09-2.75v-10.22h5.31v20.55l-4.05.2c-.31-.88-.58-1.67-.93-2.66-1.99 1.83-4.08 3.13-6.78 3.13-3.78 0-6.39-1.87-7.25-5.54-.36-1.55-.5-3.17-.53-4.77-.07-3.33 0-6.66.02-9.99 0-.22.11-.43.26-1.02Z"
    />
    <path
      className="d svg-elem-22"
      d="M615.65 547.42h-4.17c-.18-.73-.37-1.48-.57-2.29-.31.15-.64.25-.9.44-.65.46-1.23 1.04-1.92 1.44-5.03 2.87-10.79.45-11.46-5.29-.56-4.84-.12-9.79-.12-14.89h5.14c0 2.79-.02 5.6 0 8.42.02 1.6-.03 3.23.25 4.79.47 2.66 1.92 3.89 4.08 3.81 2.18-.08 4.07-1.79 4.35-4.15.17-1.47.07-2.98.07-4.47v-8.4h5.25v20.59Z"
    />
    <path
      className="d svg-elem-23"
      d="M783.87 526.76h4.29c.16.76.3 1.42.46 2.16.29-.08.6-.12.87-.25 1.73-.82 3.38-2 5.2-2.4 3.74-.83 7.34 1.63 8.17 5.59.51 2.44.48 5 .56 7.51.09 2.61.02 5.22.02 8.04h-5.49c0-3.01.01-5.95 0-8.89 0-1.37.07-2.77-.14-4.12-.4-2.58-1.88-3.94-4-3.95-2.36-.01-4.33 1.73-4.55 4.28-.16 1.82-.05 3.66-.06 5.5v7.12h-5.32v-20.57Z"
    />
    <path
      className="d svg-elem-24"
      d="M638.65 547.41c-2.27.22-4.23.82-5.16-.96-2.5.57-4.77 1.43-7.07 1.53-3.06.13-5.32-1.87-6.05-4.64-.71-2.7.29-5.68 2.88-7.1 1.86-1.02 4.03-1.5 6.1-2.07 1.16-.32 2.41-.34 3.66-.5-1.13-4.14-2.82-4.54-10.17-2.64-.31-.9-.62-1.81-.97-2.82.36-.28.59-.57.89-.69 3.26-1.27 6.58-1.93 10.06-.97 2.92.8 4.87 2.6 5.35 5.61.34 2.13.4 4.32.46 6.49.07 2.84.02 5.69.02 8.77Zm-5.62-10.28c-1.8.33-3.41.43-4.87.94-2.24.79-3.06 2.8-2.17 4.64.85 1.76 3 2.21 5.06 1.03 2.44-1.4 2.42-3.64 1.98-6.61Z"
    />
    <path
      className="d svg-elem-25"
      d="M841.05 546.28c-2.22.63-4.24 1.55-6.32 1.73-3.43.3-5.96-1.63-6.79-4.6-.84-3.02.54-6.21 3.65-7.71 1.62-.78 3.46-1.12 5.23-1.53 1.19-.27 2.43-.32 3.68-.46-.97-4.17-2.87-4.64-10.06-2.65-.33-.88-.66-1.79-1-2.69.33-.35.52-.71.8-.82 3.25-1.32 6.56-1.96 10.05-1.03 2.93.78 4.9 2.55 5.4 5.55.36 2.13.42 4.32.47 6.49.08 2.85.02 5.7.02 8.78-2.06.27-4.03 1.05-5.14-1.06Zm-.63-8.87c-1.93-.19-3.82 0-5.54 1.03-1.55.93-2.07 2.63-1.41 4.16.64 1.47 2.29 2.12 4.05 1.58 2.75-.83 3.56-2.67 2.89-6.77Z"
    />
    <path
      className="d svg-elem-26"
      d="M562.9 547.23q-3.73.58-4.95-.87c-2.48.61-4.74 1.51-7.04 1.64-3.13.18-5.28-1.68-6.12-4.52-.85-2.88.14-5.83 2.93-7.36 1.76-.96 3.83-1.41 5.8-1.93 1.18-.31 2.44-.32 3.67-.47-.62-4.15-3.02-4.77-9.92-2.54l-1.16-3.17c4.11-1.99 8.05-2.64 12.14-1.1 3.34 1.26 4.52 4.1 4.62 7.39.12 4.23.03 8.46.03 12.92Zm-5.66-10.19c-2.01.48-3.83.67-5.44 1.37-1.82.79-2.29 2.68-1.46 4.32.76 1.5 2.26 2.02 4.08 1.4 2.63-.88 3.27-2.32 2.81-7.09Z"
    />
    <path
      className="d svg-elem-27"
      d="M772.63 533.68c-1.47-4.2-3.55-4.65-10.23-2.5-.35-.97-.71-1.96-1.15-3.18 4.12-1.98 8.05-2.64 12.14-1.08 3.34 1.27 4.51 4.11 4.61 7.4.12 4.23.03 8.46.03 12.9-2.01.49-3.93 1.21-5.02-.84-2.42.59-4.68 1.49-6.98 1.62-3.13.17-5.28-1.69-6.11-4.53-.84-2.88.16-5.84 2.94-7.35 1.76-.96 3.83-1.4 5.8-1.92 1.18-.31 2.44-.33 3.96-.51Zm-.4 3.72c-1.91-.14-3.8.03-5.5 1.08-1.55.96-2.01 2.6-1.28 4.15.7 1.51 2.25 2.1 4.03 1.54 2.81-.88 3.55-2.62 2.76-6.78Z"
    />
    <path
      className="d svg-elem-28"
      d="M493.46 533.58c-1.36-4.09-3.37-4.51-9.92-2.54-.1-.03-.25-.04-.28-.11-.35-.83-.69-1.68-1.02-2.51.28-.34.45-.72.73-.83 3.02-1.22 6.11-1.96 9.39-1.25 4.14.9 6.27 3.12 6.44 7.33.18 4.45.04 8.92.04 13.57-2 .4-3.99 1.27-4.93-.88-2.48.62-4.63 1.5-6.82 1.64-3.12.19-5.49-1.74-6.22-4.42-.85-3.12.21-6.12 3.14-7.62 1.79-.92 3.87-1.29 5.85-1.81 1.15-.3 2.35-.38 3.59-.58Zm-.22 3.52c-1.75.35-3.35.48-4.81 1.01-2.13.78-2.92 2.88-2.02 4.69.8 1.59 2.81 2.04 4.78 1.06 2.15-1.07 2.65-2.51 2.05-6.75Z"
    />
    <path
      className="d svg-elem-29"
      d="M266.75 547.24q-3.53.58-5.03-.9c-2.35.62-4.48 1.52-6.67 1.67-3.24.23-5.77-1.81-6.46-4.59-.76-3.06.45-6.1 3.36-7.57 1.6-.81 3.44-1.2 5.2-1.63 1.2-.29 2.47-.33 3.7-.47-.13-4.04-1.58-4.45-9.84-2.84l-1.08-2.92c4-1.88 7.85-2.54 11.85-1.21 2.74.91 4.39 2.96 4.67 5.84.25 2.62.24 5.27.29 7.9.04 2.16 0 4.33 0 6.73Zm-5.41-9.4c-2.2-.55-4.08-.4-5.53.44-1.69.97-2.35 2.7-1.66 4.36.64 1.56 2.48 2.16 4.38 1.43 2.41-.93 3.07-2.4 2.82-6.22Z"
    />
    <path
      className="d svg-elem-30"
      d="M285.85 538.7c-.3 5.46 5.71 6.02 12.16 4.66.19.47.46.96.6 1.48.14.53.15 1.09.23 1.8-4.24 1.61-8.34 1.85-12.51.44-.75-.25-1.45-.7-2.1-1.16-4.23-3.01-5.31-9.99-2.32-14.93 2.66-4.41 8.56-6.15 13.26-3.92 3.61 1.71 5.35 5.62 4.77 11.1-4.63.75-9.43-.17-14.1.53Zm8.92-4.06c-.4-3.59-1.75-4.98-4.4-4.9-2.74.08-4.36 1.87-4.34 4.9h8.74Z"
    />
    <path
      className="d svg-elem-31"
      d="M518.2 538.69c.3 4.88 3.76 6.2 12.24 4.64l.89 3.24c-4.47 1.7-8.69 2.03-12.92.35-1.14-.45-2.23-1.23-3.09-2.11-3.21-3.25-3.64-9.51-1.06-13.8 2.35-3.91 6.94-5.65 11.63-4.51 2.94.72 4.9 2.55 5.83 5.3.64 1.89.73 3.97 1.12 6.24-5.14.94-9.95-.08-14.63.65Zm8.93-4.1c-.44-3.69-1.94-5.1-4.76-4.81-2.63.27-4.02 1.93-3.96 5.01 2.16 0 4.33.01 6.5 0 .67 0 1.34-.11 2.23-.19Z"
    />
    <path
      className="d svg-elem-32"
      d="M420.7 538.49h-14.07c-.42 4.86 4.55 6.48 12.35 4.84.23 1.01.47 2.04.74 3.26-4.45 1.7-8.68 2.06-12.88.31-5.42-2.26-7.43-9.49-4.41-15.34 2.6-5.05 9.33-7.02 14.22-4.17 3.23 1.89 4.63 5.47 4.06 11.09Zm-4.93-3.84c-.67-3.7-1.87-4.93-4.49-4.91-2.71.03-4.22 1.58-4.66 4.91h9.14Z"
    />
    <path
      className="d svg-elem-33"
      d="M690.2 538.51h-13.98c-.43 4.64 4.21 6.52 12.37 4.84.23 1.02.45 2.05.72 3.25-4.45 1.7-8.69 2.06-12.89.3-5.33-2.23-7.34-9.14-4.53-15.03 2.47-5.16 8.97-7.23 14.08-4.64 2.75 1.39 5.48 6.15 4.23 11.29Zm-4.92-3.92c-.41-3.44-1.63-4.79-4.25-4.84-2.61-.05-4.19 1.47-4.87 4.84h9.12Z"
    />
    <path
      className="d svg-elem-34"
      d="M370.25 538.52h-13.89c.37 3.52 1.8 5 5.17 5.37 1.25.14 2.53.08 3.78-.02 1.12-.09 2.23-.35 3.53-.56.24 1.06.46 2.03.75 3.32-4.77 1.69-9.45 2.24-13.96-.26-4.68-2.59-6.11-9.72-3.27-14.98 2.54-4.68 8.49-6.61 13.4-4.35 3.88 1.79 5.74 6.24 4.48 11.48Zm-4.7-3.83c-.44-3.51-1.7-4.89-4.28-4.94-2.71-.06-4.4 1.65-4.81 4.94h9.09Z"
    />
    <path
      className="d svg-elem-35"
      d="M445.03 547.43h-5.52v-9.05c0-1.15.06-2.3-.03-3.44-.24-2.88-1.84-4.56-4.19-4.51-2.36.05-4.2 1.8-4.38 4.43-.15 2.17-.05 4.36-.06 6.53v5.98h-5.29v-20.53c.53-.05 1.31-.18 2.08-.2.68-.02 1.36.06 2 .1l.69 2.13c.36-.13.67-.21.95-.35 1.41-.71 2.75-1.69 4.24-2.09 3.9-1.05 7.4.89 8.79 4.65.16.43.34.87.4 1.31.6 5.01.17 10 .32 15.04Z"
    />
    <path
      className="d svg-elem-36"
      d="M825.09 527.26c-.24 1.15-.47 2.22-.74 3.5-3.24-.52-6.36-1.19-8.9 1.32-1.5 1.48-1.98 3.33-1.89 5.35.23 5.04 3.42 6.82 10.99 6.01.17 1.07.34 2.16.54 3.39-2.05.63-3.9 1.19-6.37 1.95 1.17.88 1.74 1.21 2.2 1.67 1.97 1.93 1.47 4.41-1.08 5.49-1.47.63-3.29.51-5.74-.41 0-.93.08-1.67 1.41-1.6 1.98.1 1.99.05 3.45-1.31l-3.33-1.7c.32-.77.67-1.61 1.03-2.49-9.08-3.87-11.53-10.03-6.01-18.48 1.99-3.05 8.65-5.21 14.44-2.68Z"
    />
    <path
      className="d svg-elem-37"
      d="M315.46 536.72c2.36 3.55 4.56 6.87 6.8 10.24-4.53 1.42-5.38 1.12-7.68-2.54-.78-1.25-1.55-2.5-2.58-4.15-.99 1.56-1.84 2.85-2.63 4.17-2.19 3.63-2.58 3.79-7.48 2.65l7.01-10.11c-2.28-3.32-4.52-6.57-6.99-10.16 1.39-.08 2.27-.07 3.14-.19 1.93-.27 3.24.42 4.16 2.19.84 1.61 1.95 3.09 3.12 4.9.95-1.54 1.92-2.8 2.57-4.21 1.04-2.28 2.63-3.3 5.13-2.88.64.11 1.31.09 2.43.16-2.44 3.45-4.66 6.6-7.02 9.93Z"
    />
    <path
      className="d svg-elem-38"
      d="M576.73 546.26c.36-1.14.67-2.1 1.02-3.17 1.34.32 2.54.66 3.76.87.89.15 1.82.2 2.71.12 1.21-.11 2.28-.63 2.42-2.01.14-1.33-.7-2.11-1.84-2.59-.63-.26-1.29-.46-1.91-.74-.94-.43-1.9-.81-2.76-1.37-3.99-2.59-3.74-7.74.45-10.05 3.02-1.67 6.31-1.62 10.41.24-.11.47-.22.99-.36 1.5-.15.53-.35 1.05-.53 1.6-1.59-.24-3.03-.54-4.49-.67-1.32-.11-2.66.21-3.06 1.65-.44 1.56.83 2.2 1.99 2.74 1.35.62 2.76 1.11 4.08 1.79 2.36 1.22 3.55 3.18 3.32 5.88-.23 2.73-1.9 4.31-4.34 5.21-3.35 1.24-7.09.92-10.87-1Z"
    />
    <path
      className="d svg-elem-39"
      d="M666.63 527.52c-.44 1.27-.77 2.21-1.08 3.13-1.74-.24-3.3-.56-4.87-.65-1.2-.07-2.34.35-2.62 1.73-.29 1.41.68 2.07 1.79 2.56 1.36.6 2.75 1.13 4.09 1.78 2.39 1.16 3.74 3.14 3.56 5.77-.19 2.78-1.89 4.6-4.55 5.45-3.53 1.13-6.96.92-10.58-.81.26-1.12.49-2.17.79-3.46 1.24.33 2.18.68 3.16.82 1.12.17 2.27.26 3.4.21 1.22-.06 2.45-.6 2.37-1.96-.05-.84-.87-1.7-1.5-2.4-.34-.39-1.02-.47-1.55-.7-.84-.36-1.69-.7-2.52-1.1-2.34-1.14-3.9-2.88-3.77-5.62.12-2.63 2-4.72 4.86-5.61 2.97-.92 5.83-.65 9 .87Z"
    />
    <path
      className="d svg-elem-40"
      d="m226.02 527.51-1.05 3.1c-1.85-.22-3.53-.62-5.18-.52-.81.05-1.89.87-2.26 1.64-.53 1.08.36 1.93 1.38 2.39 1.14.52 2.34.93 3.48 1.46 3.35 1.55 4.89 3.81 4.52 6.57-.37 2.79-2.39 4.75-5.84 5.51-3.11.69-6.12.39-9.28-1.16l.74-3.45c1.34.33 2.4.71 3.49.84 1.23.14 2.54.26 3.72-.03.71-.17 1.63-1.08 1.72-1.75.1-.69-.57-1.63-1.13-2.24-.42-.46-1.22-.59-1.84-.87-.94-.42-1.9-.8-2.82-1.26-2.23-1.12-3.67-2.87-3.5-5.43.19-2.79 1.87-4.64 4.49-5.53 3.05-1.04 6.05-.85 9.36.74Z"
    />
    <path
      className="d svg-elem-41"
      d="m244.46 527.45-.95 3.13c-1.84-.21-3.5-.48-5.17-.56-1.25-.06-2.46.56-2.4 1.87.03.8.88 1.81 1.64 2.28 1.35.83 2.93 1.27 4.36 1.97 2.41 1.18 3.54 3.19 3.34 5.86-.2 2.72-1.83 4.4-4.3 5.24-3.62 1.22-7.17 1-10.82-.78l.88-3.37c1.55.35 2.85.73 4.18.93.88.13 1.91.25 2.68-.08.84-.35 1.95-1.16 2.04-1.88.09-.73-.83-1.7-1.5-2.37-.44-.44-1.24-.53-1.87-.8-.94-.42-1.91-.8-2.79-1.32-1.98-1.18-3.35-2.82-3.16-5.29.19-2.52 1.6-4.24 3.87-5.23 3.23-1.41 6.43-1.13 9.95.41Z"
    />
    <path
      className="d svg-elem-42"
      d="m466.51 527.4-.68 3.34c-3.34-.55-6.57-1.18-9.11 1.54-1.43 1.53-1.84 3.41-1.69 5.41.38 4.94 3.78 6.77 10.89 5.68l.61 3.09c-5.07 2.59-11.51 1.63-14.66-2.03-3.16-3.67-3.29-9.66-.45-13.76 2.68-3.87 10.17-6.06 15.1-3.28Z"
    />
    <path
      className="d svg-elem-43"
      d="M745.97 526.76h4.29c.12.99.23 1.86.36 2.89.32-.17.64-.24.8-.45 1.55-2.01 3.42-3.33 6.32-2.96.11.79.25 1.55.3 2.31.05.79.01 1.58.01 2.35-5.46.55-6.74 1.93-6.78 7.17-.02 3.06 0 6.13 0 9.35h-5.3v-20.67Z"
    />
    <path
      className="d svg-elem-44"
      d="M380.31 547.38h-4.81v-20.56h4.04c.18.88.36 1.74.65 3.09 1.88-2.3 3.71-4.13 7.01-3.63.07 1.46.15 2.88.21 4.16-.38.29-.55.51-.74.54-4.82.71-5.84 1.85-5.9 6.63-.03 2.97-.05 5.94-.09 8.92 0 .2-.17.41-.37.85Z"
    />
    <path
      className="d svg-elem-45"
      d="M391.41 526.81h5.05v20.53h-4.77c-.68-2.29-.88-15.05-.28-20.53Z"
    />
    <path
      className="d svg-elem-46"
      d="M470.84 526.72h4.74c.7 2.44.88 15.79.25 20.64h-4.99v-20.64Z"
    />
    <path
      className="d svg-elem-47"
      d="M405.63 523.23c1.07-1.61 1.94-3.07 2.97-4.43 1.55-2.06 4.21-2.08 5.75-.04 1.09 1.44 2.02 3 3.04 4.53-3.52 1.62-4.27-1.24-5.75-3.21-1.86 1.2-2.22 5.1-6 3.15Z"
    />
    <path
      className="d svg-elem-48"
      d="M476.54 520.83c-.11 1.52-1.79 2.76-3.49 2.56-1.66-.19-2.7-1.43-2.56-3.04.12-1.48 1.51-2.69 3.04-2.65 1.61.04 3.12 1.61 3.01 3.13Z"
    />
    <path
      className="d svg-elem-49"
      d="M396.81 520.62c.01 1.53-1.2 2.71-2.87 2.78-1.71.08-3.24-1.29-3.2-2.85.04-1.43 1.42-2.78 2.92-2.86 1.68-.09 3.13 1.26 3.14 2.93Z"
    />
    <path
      className="d svg-elem-50"
      d="M529.38 517.79c-1.68 1.95-3.05 3.89-4.79 5.41-.74.64-2.24.4-3.63.59 3.71-7.95 3.95-6.7 8.42-6.01Z"
    />
  </svg>
);

export default SvgComponent;
