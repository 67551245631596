import { FaBoxOpen } from 'react-icons/fa';
import { OutlinedContainer } from './styles';

export const DefaultImage = () => {
  return (
    <OutlinedContainer>
      <FaBoxOpen size={100} />
      <h4>Sem resultados</h4>
    </OutlinedContainer>
  );
};
