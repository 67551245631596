/* eslint-disable */
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export function ModalDelete(props: any) {
    return (
        <Modal
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      enforceFocus
      show={props.show}
      onHide={props.onHide}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Atenção</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: 20, alignSelf: 'center' }}>
        {props.message}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          style={{ width: '120px', height: '50px' }}
          onClick={props.handleClose}
        >
          Cancelar
        </Button>
        <Button
          style={{ color: '#000', width: '120px', height: '50px' }}
          variant="primary"
          onClick={props.handleDelete}
        >
          Excluir
        </Button>
      </Modal.Footer>
    </Modal>
    )
}
