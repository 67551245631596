import { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../config/axiosMaquina';
import { cpfMask, maskCpnj } from '../../../util/Validacoes/Validacoes';
import { Tela } from '../../../components/Tela';
import { filterItems2 } from '../../../util/format';
import Table from '../../../components/TableComponent';
import { useRolePermission } from '../../../context/RolePermissionAuth';
import { PERMISSION } from '../../../constants/Permissionamento';

type Cliente = {
  id: number;
  idLegado: number;
  cnpj: string;
  razaoSocial: string;
  nomeFantasia: string;
  ativo: boolean;
  gerenteConta: string;
  cadastroConsulta: number;
  cronogramaViagem: number;
  smsViagem: number;
  seguradora: string;
  corretora: string;
  vencimento: string;
  monitoramento: number;
  mapaRastreamento: number;
  wsPosicoes: number;
};

type Params = {
  page: string;
};

type Props = {
  canInsert?: boolean;
};

const ClienteGrid: FC<Props> = () => {
  const { ADMIN } = PERMISSION;

  const [data, setData] = useState<Cliente[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [dataTabela, setDataTabela] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [itensPerPage, setItensPerPage] = useState('10');
  const { page } = useParams<Params>();
  const history = useHistory();
  const indexOfLastPost = parseInt(page, 10) * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentData =
    searchValue.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : dataTabela.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    history.push(`/clientes/listar/${pageNumber}`);
  };

  const headers = {
    id: 'Id',
    idLegado: 'Código Legado',
    razaoSocial: 'Razão Social',
    nomeFantasia: 'Nome Fantasia',
    cnpj: 'CNPJ',
  };

  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.CLIENTE] }], 'inserir');
  }, [ADMIN.CADASTRO.CLIENTE, rolePermission]);

  useEffect(() => {
    const getClientesGrid = async () => {
      try {
        const response = await api.get('/cliente/grid-cadastro');

        setData(response.data.filter((fil: any) => fil.ativo === true));
        console.log('---', data);
        const array = response.data
          .filter((fil: any) => fil.ativo === true)
          .map((each: Cliente) => {
            return {
              id: each.id,
              idLegado: each.idLegado,
              razaoSocial: each.razaoSocial,
              nomeFantasia: each.nomeFantasia,
              cnpj: maskCpnj(each.cnpj),
            };
          });
        setDataTabela(array);
      } catch (err: any) {
        console.log(err);
      }
    };
    getClientesGrid();
  }, []);

  const multiDataSet = [
    {
      columns: [
        {
          title: 'Clientes',
          widthPx: 1060,
          style: { font: { sz: '24', bold: true } },
        },
      ],
      data: [],
    },
    {
      columns: [],
      data: [],
    },
    {
      columns: [
        { title: 'Código', width: { wch: 10 } },
        { title: 'CNPJ', width: { wch: 18 } },
        { title: 'Razão Social', width: { wch: 40 } },
        { title: 'Seguradora', width: { wch: 20 } },
        { title: 'Corretora', width: { wch: 20 } },
        { title: 'Gerente da Conta', width: { wch: 15 } },
        { title: 'Situação', width: { wch: 10 } },
        { title: 'Vencimento', width: { wch: 20 } },
        { title: 'Monitoramento Veículos', width: { wch: 20 } },
        { title: 'Cadastro/Consulta', width: { wch: 20 } },
        { title: 'Análise Especial', width: { wch: 20 } },
        { title: 'Mapa Rastreamento', width: { wch: 20 } },
        { title: 'WS Posições', width: { wch: 20 } },
        { title: 'SMS Viagens', width: { wch: 20 } },
      ],

      data: data.map((each) => [
        { value: each.idLegado ? each.idLegado : '' },
        {
          value: each.cnpj
            ? each.cnpj.length === 11
              ? cpfMask(each.cnpj)
              : maskCpnj(each.cnpj)
            : '',
        },
        { value: each.razaoSocial ? each.razaoSocial : '' },
        { value: each.seguradora ? each.seguradora : '' },
        { value: each.corretora ? each.corretora : '' },
        { value: each.gerenteConta ? each.gerenteConta : '' },
        { value: each.ativo === true ? 'ATIVO' : 'INATIVO' },
        { value: each.vencimento === '0' ? '' : each.vencimento || '' },
        {
          value:
            each.monitoramento === 0
              ? 'N'
              : each.monitoramento === 1
              ? 'C'
              : each.monitoramento === 2
              ? 'B'
              : '',
        },
        {
          value:
            each.cadastroConsulta === 0
              ? 'N'
              : each.cadastroConsulta === 1
              ? 'C'
              : each.cadastroConsulta === 2
              ? 'B'
              : '',
        },
        {
          value:
            each.cronogramaViagem === 0
              ? 'N'
              : each.cronogramaViagem === 1
              ? 'C'
              : each.cronogramaViagem === 2
              ? 'B'
              : '',
        },
        {
          value:
            each.mapaRastreamento === 0
              ? 'N'
              : each.mapaRastreamento === 1
              ? 'C'
              : each.mapaRastreamento === 2
              ? 'B'
              : '',
        },
        {
          value:
            each.wsPosicoes === 0
              ? 'N'
              : each.wsPosicoes === 1
              ? 'C'
              : each.wsPosicoes === 2
              ? 'B'
              : '',
        },
        {
          value:
            each.smsViagem === 0
              ? 'N'
              : each.smsViagem === 1
              ? 'C'
              : each.smsViagem === 2
              ? 'B'
              : '',
        },
      ]),
    },
  ];

  useEffect(() => {
    if (searchValue && searchValue.trim() !== '') {
      history.push('/clientes/listar/1');
      setDataSearch(filterItems2(dataTabela, searchValue));
    }
  }, [dataTabela, history, searchValue]);

  console.log('dataSearch', currentData);

  return (
    <Tela
      nome="Clientes"
      caminho="Cadastro > Clientes > Consultas"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearch}
      setSearchValue={setSearchValue}
      search={search}
      linkToAdd="/cadastrar-clientes/dadosPrincipais"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchValue.trim() !== '' ? dataSearch.length : dataTabela.length}
      paginate={paginate}
      currentPage={page ?? 0}
      excelGeneration={multiDataSet}
      filename="Clientes"
      createDisabled={!canInsert}
    >
      <Table
        items={currentData}
        headers={headers}
        handleEdit={(id: any) => history.push(`/editar-cliente/${id}`)}
        left
      />
    </Tela>
  );
};

export default ClienteGrid;
