/* eslint-disable */

import React from 'react';

interface VeiculoType {
  dadosVeiculo: any;
  dadosRoubosFurtos: any;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function Veiculo({ dadosVeiculo, dadosRoubosFurtos }: VeiculoType) {
  const handleValidacaoProprietario = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 1: {
        return 'Confirmado';
      }
      case 0: {
        return 'Divergente do informado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoRenavam = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 0: {
        return 'Divergente do informado';
      }
      case 1: {
        return 'Confirmado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoChassiVeiculo = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 0: {
        return 'Divergente do informado';
      }
      case 1: {
        return 'Confirmado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };

  const handleValidacaoUFVeiculo = (value: string) => {
    const n = parseInt(value);
    switch (n) {
      case -1: {
        return 'Não foi possível a confirmação';
      }
      case 0: {
        return 'Divergente do informado';
      }
      case 1: {
        return 'Confirmado';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };
  const handleValidacaoAnttVeiculo = (n: number) => {
    switch (n) {
      case 20: {
        return 'Resultado encontrado';
      }
      case 19: {
        return 'Resultado não encontrado';
      }
      case 13: {
        return 'Fonte indisponível';
      }
      default: {
        return 'Não foi possível validar ou a fonte não disponibiliza a informação';
      }
    }
  };
  const handleValidacaoRoubo = (n: any) => {
    switch (n) {
      case '1': {
        return 'DECLARACAO';
      }
      case '2': {
        return 'RECUPERACAO';
      }
      case '3': {
        return 'DEVOLUCAO';
      }
      default: {
        return n;
      }
    }
  };

  return (
    <>
      <table cellPadding="5">
        <tbody>
          <tr>
            <td>
              <table cellPadding="5">
                <tbody>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Proprietário: </span>
                    </td>
                    <td>
                      {dadosVeiculo.documento} {dadosVeiculo.nomeProprietario} (
                      {handleValidacaoProprietario(dadosVeiculo.validacaoProprietario)})
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>DADOS VEÍCULO:</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Placa: </span>
                    </td>
                    <td>{dadosVeiculo.placa}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Renavam: </span>
                    </td>
                    <td>
                      {dadosVeiculo.renavam} (
                      {handleValidacaoRenavam(dadosVeiculo.validacaoRenavam)})
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Chassi: </span>
                    </td>
                    <td>
                      {dadosVeiculo.chassi} (
                      {handleValidacaoChassiVeiculo(dadosVeiculo.validacaoChassi)})
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Municipio: </span>
                    </td>
                    <td>{dadosVeiculo.municipio}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Marca/Modelo: </span>
                    </td>
                    <td>{dadosVeiculo.marca}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Ano Fab/Modelo: </span>
                    </td>
                    <td>
                      {dadosVeiculo.anoFabrica} / {dadosVeiculo.anoModelo}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Tipo de Veículo: </span>
                    </td>
                    <td>{dadosVeiculo.tipoVeiculo}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Data Transferência: </span>
                    </td>
                    <td>{dadosVeiculo.dataTransferencia}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Cor: </span>
                    </td>
                    <td>{dadosVeiculo.cor}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Combustível: </span>
                    </td>
                    <td>{dadosVeiculo.combustivel}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Espécie: </span>
                    </td>
                    <td>{dadosVeiculo.especie}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Situação: </span>
                    </td>
                    <td>{dadosVeiculo.situacao}</td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <table cellPadding="5">
                <tbody>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Capacidade Passageiros: </span>
                    </td>
                    <td>{dadosVeiculo.passageiroCapacidade}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Cilindrada: </span>
                    </td>
                    <td>{dadosVeiculo.cilindradaCapacidade}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Capacidade Carga: </span>
                    </td>
                    <td>{dadosVeiculo.cargaCapacidade}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Potência: </span>
                    </td>
                    <td>{dadosVeiculo.pontecy}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>UF:</span>
                    </td>
                    <td>
                      {dadosVeiculo.uf} ({handleValidacaoUFVeiculo(dadosVeiculo.validacaoUf)})
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Remarcação do Chassi: </span>
                    </td>
                    <td>{dadosVeiculo.remarcacaoChassi}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Montagem: </span>
                    </td>
                    <td>{dadosVeiculo.montagem}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Nº Motor: </span>
                    </td>
                    <td>{dadosVeiculo.motor}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Caixa Câmbio: </span>
                    </td>
                    <td>{dadosVeiculo.caixaCambio}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Nº Carroceria: </span>
                    </td>
                    <td>{dadosVeiculo.carroceria}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Nº Eixo: </span>
                    </td>
                    <td>{dadosVeiculo.numeroEixo}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style={{ fontWeight: 'bold' }}>Nº Eixo Auxiliar: </span>
                    </td>
                    <td>{dadosVeiculo.numeroEixoAuxiliar}</td>
                  </tr>
                  <tr />
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />

      <table cellPadding="5" cellSpacing="5">
        <tbody>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>RESTRIÇÕES: </span>
            </td>
            <td />
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Ocorrência Furto Ativa: </span>
            </td>
            <td>{dadosVeiculo.occorenciaFurtoAtiva}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Comunicação de Venda: </span>
            </td>
            <td>{dadosVeiculo.comunicacaoVenda}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Restrição Judicial RENAJUD: </span>
            </td>
            <td>{dadosVeiculo.restricaoRenajud}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Multa(s) RENAINF: </span>
            </td>
            <td>{dadosVeiculo.multaRenainf}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Recall: </span>
            </td>
            <td>{dadosVeiculo.recallRestricao}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Restrição-1: </span>
            </td>
            <td>{dadosVeiculo.restrocao1}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Restrição-2: </span>
            </td>
            <td>{dadosVeiculo.restrocao2}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Restrição-3: </span>
            </td>
            <td>{dadosVeiculo.restrocao3}</td>
          </tr>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>Restrição-4: </span>
            </td>
            <td>{dadosVeiculo.restrocao4}</td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />

      <table cellPadding="5" cellSpacing="5">
        <tbody>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>STATUS DO LICENCIAMENTO: </span>
            </td>
            <td>{dadosVeiculo.licensingStatus}</td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />

      <br />
      <br />

      <table cellPadding="5" cellSpacing="5">
        <tbody>
          <tr>
            <td>
              <span style={{ fontWeight: 'bold' }}>ROUBO E FURTO: </span>
            </td>
            <td>{dadosVeiculo.ocorrencia}</td>
          </tr>
        </tbody>
      </table>

      <br />
      <br />



      {dadosVeiculo.anttCadastro && (
           <table cellPadding="5">
           <tbody>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>RNTRC: </span>
               </td>
               <td>{dadosVeiculo.rntrcNumero}</td>
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Transportador: </span>
               </td>
               <td>{dadosVeiculo.anttTransportador}</td>
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Documento: </span>
               </td>
               <td>{dadosVeiculo.anttDocumento}</td>
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Enquadramento: </span>
               </td>
               <td>{dadosVeiculo.enquadramento}</td>
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Situação: </span>
               </td>
               <td>{dadosVeiculo.anttSituacao}</td>
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Cadastrado Desde: </span>
               </td>
               <td>{dadosVeiculo.anttCadastro}</td>
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Data de Validade: </span>
               </td>
               <td />
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Municipio: </span>
               </td>
               <td>
                 {dadosVeiculo.anttCidade}/{dadosVeiculo.anttUf}
               </td>
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Situação Transportador: </span>
               </td>
               <td>
                 <span>{dadosVeiculo.anttMensagemTransportador}</span>
               </td>
             </tr>
             <tr>
               <td>
                 <span style={{ fontWeight: 'bold' }}>Situação Veículo: </span>
               </td>
               <td>
                 <span>{dadosVeiculo.anttMensagemVeiculo}</span>
               </td>
             </tr>
           </tbody>
         </table>
      )}

      <br />
      <br />

      {dadosVeiculo.infracoes.length > 0 && (
         <table cellPadding="5" width="100%">
         <tbody>
         <tr>
             <td>
               <span style={{ fontWeight: 'bold' }}>INFRAÇÕES: </span>
             </td>
             <td></td>
           </tr>
           {dadosVeiculo.infracoes.map((infracoes: any) => (
            <>
            <tr>
            <td>
            <table cellSpacing="5" cellPadding="5">
              <tbody>
              <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Órgão autuador:</span>
                  </td>
                  <td width="35%">{infracoes.authorBody}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>
                <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Descrição:</span>
                  </td>
                  <td width="35%">{infracoes.description}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>
                <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Hora:</span>
                  </td>
                  <td width="35%">{infracoes.hourInfraction}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>
                <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Data:</span>
                  </td>
                  <td width="35%">{infracoes.infractionDate}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>
                <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Local:</span>
                  </td>
                  <td width="35%">{infracoes.infractionPlace}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>
                <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Valor da Multa:</span>
                  </td>
                  <td width="35%">{infracoes.infractionValue}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>
                <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Complemento do Auto:</span>
                  </td>
                  <td width="35%">{infracoes.complementNoticeNumber}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>
                <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Número do Auto:</span>
                  </td>
                  <td width="35%">{infracoes.infractionNoticeNumber}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>
                <tr>
                  <td width="15%">
                    <span style={{ fontWeight: 'bold' }}>Classificação:</span>
                  </td>
                  <td width="35%">{infracoes.infractionClassification}</td>
                  <td width="15%" />
                  <td width="35%" />
                </tr>

              </tbody>
            </table>
          </td>
        </tr>
        <br />
        </>
           ))}

         </tbody>
       </table>
      )}



      {/* {dadosVeiculo.anttStatus ? (
        <>
          <p>Status ANTT: {handleValidacaoAnttVeiculo(dadosVeiculo.anttStatus)}</p>
        </>
      ) : null}
      {dadosVeiculo.anttCadastro && (
        <>
          <p>Dados ANTT</p>
          <br />
          <p>Situação Veículo: {dadosVeiculo.situacaoVeiculo || ''}</p>
          <p>RNTRC nº: {dadosVeiculo.anttRntrc}</p>
          <p>
            Transportador: {dadosVeiculo.anttDocumento || ''} {dadosVeiculo.anttTransportador || ''}
          </p>
          <p>
            Situação: {dadosVeiculo.anttSituacao || ''} Cidade: {dadosVeiculo.anttCidade || ''}
          </p>
          <div id="row">
            <p>Data Cadastro: {dadosVeiculo.anttCadastro || ''}</p>
            <p>Data Validade: {dadosVeiculo.anttValidade || ''}</p>
          </div>
          <br />{' '}
        </>
      )}
      {dadosVeiculo.anttMensagemVeiculo && dadosVeiculo.anttMensagemVeiculo.includes('NÃO') ? (
        <>
          <p>Situação Transportador:</p>
          <p>{dadosVeiculo.anttMensagemVeiculo}</p>
        </>
      ) : (
        <br />
      )}
      {dadosVeiculo.anttMensagemTransportador &&
      dadosVeiculo.anttMensagemTransportador.includes('NÃO') ? (
        <>
          <p>Situação Transportador:</p>
          <p>{dadosVeiculo.anttMensagemTransportador}</p>
        </>
      ) : (
        <br />
      )} */}

      {/* <br />
      <br />
      {dadosRoubosFurtos &&
      (dadosVeiculo.ocorrencia.includes('VEÍCULO JÁ CONSTOU HISTÓRICO DE ROUBO/FURTO') ||
        dadosVeiculo.ocorrencia.includes('VEICULO INDICA OCORRENCIA DE ROUBO/FURTO') ||
        dadosVeiculo.ocorrencia.includes('VEÍCULO CONSTA HISTÓRICO DE ROUBO/FURTO')) ? (
        <>
          <br />
          <p>Ocorrência(as) ROUBO/FURTO</p>
          <br />
          {dadosRoubosFurtos.map((each: any, index: number) => (
            <>
              <div id="row">
                <p>Ocorrência: {handleValidacaoRoubo(each.rouboFurtoDeclaracao)}</p>
                <p>Em: {each.rouboFurtoDataOcorrencia}</p>
                <p>Boletim n° {each.rouboFurtoBoletim}</p>
              </div>
            </>
          ))}
        </>
      ) : null} */}
      {/* Situação Veículo: O veiculo NÃO esta cadastrado na frota do transportador informado. */}
    </>
  );
}
