import { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { CorretoraType } from '../../Types/CorretoraType';
import api from '../../config/axiosMaquina';
import { Tela } from '../../components/Tela';
import { Spinner } from '../../components/Spinner';
import { Tabela } from '../../components/Tabela/index';
import { pesquisar } from '../../util/format';
import { ExcelGeneration } from '../../Types/ExcelType';
import { PERMISSION } from '../../constants/Permissionamento';
import { useRolePermission } from '../../context/RolePermissionAuth';

const ConsultarCorretora: FC = () => {
  const { ADMIN } = PERMISSION;
  const { rolePermission, doesHavePermission: canInsert } = useRolePermission();

  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [corretoras, setCorretoras] = useState<CorretoraType[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState<any[]>([]);
  const [dataSearch, setDataSearch] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itensPerPage, setItensPerPage] = useState('10');
  const indexOfLastPost = currentPage * parseInt(itensPerPage, 10);
  const indexOfFirstPost = indexOfLastPost - parseInt(itensPerPage, 10);
  const currentCorretoras =
    searchTerm.trim() !== ''
      ? dataSearch.slice(indexOfFirstPost, indexOfLastPost)
      : data.slice(indexOfFirstPost, indexOfLastPost);
  const [excelGeneration, setExcelGeneration] = useState<ExcelGeneration[] | undefined>();
  const titles = ['+id', 'Código', 'Nome', 'Descrição', 'Status'];

  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  function compare(a: any, b: any) {
    if (a.corTxNome.toLowerCase() < b.corTxNome.toLowerCase()) return -1;
    if (a.corTxNome.toLowerCase() > b.corTxNome.toLowerCase()) return 1;
    return 0;
  }

  useEffect(() => {
    rolePermission([{ ADMIN: [ADMIN.CADASTRO.CORRETORA] }], 'inserir');
  }, [ADMIN, rolePermission]);

  useEffect(() => {
    const loadDadosCorretora = async () => {
      try {
        const response = await api.get('/corretora');
        console.log(response.data);
        const array = response.data.sort(compare).map((each: CorretoraType) => {
          return [
            each.corCdId,
            each.corCdId,
            each.corTxNome,
            each.corTxDescricao,
            each.corBlAtivo ? 'Ativo' : 'Inativo',
          ];
        });
        setData(array);
        setCorretoras(response.data.sort(compare));
      } catch (err: any) {
        console.log(err);
      }
    };
    loadDadosCorretora();
  }, []);

  useEffect(() => {
    if (!corretoras) {
      return;
    }

    setExcelGeneration([
      {
        xSteps: 2,
        ySteps: 0,
        columns: [
          {
            title: 'Corretoras',
            widthPx: 1060,
            style: { font: { sz: '24', bold: true } },
          },
        ],
        data: [],
      },
      {
        xSteps: 0,
        ySteps: 1,
        columns: [
          { title: 'Código', width: { wch: 10 } },
          { title: 'Nome', width: { wch: 25 } },
          { title: 'Descrição', width: { wch: 25 } },
        ],
        data: corretoras.map((each) => [
          { value: each.corCdId },
          { value: each.corTxNome },
          { value: each.corTxDescricao },
        ]),
      },
    ]);
  }, [corretoras]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== '') {
      setCurrentPage(1);
      setDataSearch(pesquisar(data, searchTerm));
    }
  }, [searchTerm]);

  return (
    <Tela
      nome="Corretora"
      caminho="Cadastro > Corretora"
      loading={loading}
      setLoading={setLoading}
      setLoadingSearch={setLoadingSearch}
      setSearch={setSearchTerm}
      setSearchValue={setSearchTerm}
      search={searchTerm}
      linkToAdd="/cadastro-corretora"
      itensPerPage={itensPerPage}
      setItensPerPage={setItensPerPage!}
      totalPosts={searchTerm.trim() !== '' ? dataSearch.length : data.length}
      paginate={paginate!}
      currentPage={currentPage.toString()}
      excelGeneration={excelGeneration}
      filename="Corretoras"
      createDisabled={!canInsert}
    >
      {loadingSearch ? (
        <Spinner />
      ) : (
        <Tabela
          data={currentCorretoras}
          titles={titles}
          onClickEditar={(id: any) => history.push(`/edicao-corretora/${id}`)}
        />
      )}
    </Tela>
  );
};

export default ConsultarCorretora;
